import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, message, notification, Upload, UploadProps } from "antd";
import {
  getCoWorkersAPI,
  postUploadsAPI,
  submitDeliverableAPI,
} from "api/users";
import { SelectArrowDown } from "assets/icons/common/selectArrowDown.icon";
import {
  AttachIcon,
  CloseIcon,
  PlusIcon,
  PurpleAttachIcon,
  SuccessIcon,
} from "assets/images/deliver-work";
import axios from "axios";
import PrimaryButton from "components/base/primaryButton";
import { DeleteButton } from "pages/ProfileUser/components/Experiences/experiences.style";
import { FC, ReactElement, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setPageLoading } from "redux/slices";
import { acceptDocument, validateDoc } from "utils/regex";
import { requiredRules, urlRules } from "utils/validatorRules";
import { ISubmitNewDeliverableProps } from "./SubmitNewDeliverable.props";
import {
  AddAnotherFile,
  BaseInput,
  FlexWrap,
  IconInput,
  PTitle,
  StyledFormItem,
  StyledOption,
  StyledSelect,
  SubmitBtn,
  SubmitBtnWrap,
  SuccessBtn,
  SuccessfulWrap,
  TagText,
  Wrap12,
  WrapInput,
  SubmitDeliverModal,
} from "./SubmitNewDeliverable.style";

const SubmitNewDeliverable: FC<ISubmitNewDeliverableProps> = ({
  popupIndex,
  setPopupIndex,
  projectId,
  packageId,
}): ReactElement => {
  const pageParams = useParams();
  const [form] = Form.useForm();
  const [files, setFiles] = useState([
    { file: "", fileName: "", link: "", linkName: "" },
    { file: "", fileName: "", link: "", linkName: "" },
    { file: "", fileName: "", link: "", linkName: "" },
    { file: "", fileName: "", link: "", linkName: "" },
    { file: "", fileName: "", link: "", linkName: "" },
  ]);
  const [select, setSelect] = useState<any>(null);
  const queryClient = useQueryClient();
  const userId = useAppSelector((state) => state.auth.user?._id);
  const navigate = useNavigate();
  const [numberOfInput, setNumberOfInput] = useState([0]);
  const dispatch = useAppDispatch();
  const spinAvatarRef = useRef<any>(false);
  const fileRef = useRef<any>(null);

  const upLoadProps: UploadProps = {
    accept: acceptDocument,
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isImage = validateDoc.test(file.name);
      if (!isImage) {
        message.error("You can only upload Document files!");
        return false;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        const fileTag = file.name.split(".").pop();
        spinAvatarRef.current = true;
        fileRef.current = file;
        postUploads({
          fileName: file.name,
          fileType: `image/${fileTag}`,
        });

        return false;
      }
    },
  };

  const { mutate: postUploads } = useMutation(postUploadsAPI, {
    onSuccess: async (rsUpload: any) => {
      dispatch(setPageLoading(true));
      await axios
        .put(rsUpload.signedRequest, fileRef.current)
        .then((rs: any) => {
          setFiles(
            files.map((item, index) => {
              if (index === select)
                return {
                  ...item,
                  file: rsUpload.url,
                };
              return item;
            })
          );
          spinAvatarRef.current = false;
          dispatch(setPageLoading(false));
        });
    },
  });

  const { mutate: submitDeliverable } = useMutation(submitDeliverableAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        dispatch(setPageLoading(false));
        queryClient.invalidateQueries(["getAllDeliverables"]);
        setPopupIndex(0);
        form.resetFields();
        notification.success({
          description:
            "The project initiator will review, and validate if the submission meets the Minimum Acceptance Criteria.",
          message: "Your request to deliver your work has been recorded.",
        });
      } else {
        dispatch(setPageLoading(false));
        message.error(responseMessage);
      }
    },
  });

  interface ISubmitDeliverableFormValues {
    selectTask: string;
    notes: string;
    coWorkers: string[];
    fileLink: string;
    fileName: string;
    link: string;
    linkName: string;
  }

  const handleSubmit = (values: ISubmitDeliverableFormValues) => {
    try {
      dispatch(setPageLoading(true));
      const fileParams = files
        .filter((item) => item.file !== "")
        .map((fileParam, index) => {
          const fileName: any = (values as any)[`fileName${index}`];
          const fileLink = fileParam.file;
          const link = (values as any)[`link${index}`];
          const linkName = (values as any)[`linkName${index}`];
          return {
            fileName,
            fileLink,
            link,
            linkName,
          };
        });
      const paramsSubmit = fileParams.filter(
        (item) =>
          (item.fileLink !== undefined && item.fileName !== undefined) ||
          (item.link !== undefined && item.linkName !== undefined)
      );
      const submitParams = {
        selectTask: pageParams.packageId || "",
        notes: values.notes,
        coWorkers: values.coWorkers || [],
        files: paramsSubmit.map((item) => ({
          fileName: item?.fileName,
          fileLink: item?.fileLink,
        })),

        postLink: paramsSubmit.map((item) => ({
          link: item?.link,
          linkName: item?.linkName,
        })),
      };
      submitDeliverable({
        params: submitParams,
        projectId: pageParams?.projectId || "",
        packageId: pageParams?.packageId || "",
      });
    } catch (error) {
      console.error(error);
      dispatch(setPageLoading(false));
    }
  };

  const { data: coWorkersRes } = useQuery(
    ["getCoWorkers"],
    () =>
      getCoWorkersAPI({
        projectId: pageParams?.projectId || projectId || "",
        packageId: pageParams?.packageId || packageId || "",
        collabId: userId || "",
      }),
    {
      enabled: popupIndex === 1,
    }
  );
  const coWorkers: any[] | undefined = coWorkersRes?.responseData;

  const handleSelectCoWorker = (value: any) => {
    form.setFieldValue("coWorkers", value);
  };

  return (
    <div>
      {popupIndex === 1 && (
        <SubmitDeliverModal
          width={624}
          visible={popupIndex === 1}
          onCancel={() => setPopupIndex(0)}
          footer={null}
          destroyOnClose
          closeIcon={<CloseIcon callback={() => {}} />}
          centered
          maskStyle={{
            backgroundColor: "rgba(8, 15, 108, 0.6)",
          }}
        >
          <Form form={form} onFinish={handleSubmit}>
            <TagText>Submit a new deliverable</TagText>
            <StyledFormItem name="coWorkers">
              <StyledSelect
                mode="multiple"
                suffixIcon={<SelectArrowDown />}
                placeholder="Select co workers"
                onChange={handleSelectCoWorker}
              >
                {coWorkers?.map((worker: any) => (
                  <StyledOption value={worker?._id}>
                    {worker?.username ?? worker?.emailId.split("@")[0]}
                  </StyledOption>
                ))}
              </StyledSelect>
            </StyledFormItem>
            <StyledFormItem name="notes" rules={[requiredRules]}>
              <BaseInput placeholder="Describe the deliverable" />
            </StyledFormItem>

            <PTitle>Select a file</PTitle>
            <FlexWrap>
              {Array.from(Array(5).keys()).map((file, i) =>
                numberOfInput.includes(i) ? (
                  <div key={i}>
                    <Wrap12>
                      <WrapInput htmlFor="upload">
                        <StyledFormItem
                          name={"file" + i}
                          rules={[requiredRules]}
                        >
                          <div
                            onClick={() => {
                              setSelect(i);
                            }}
                          >
                            <Upload {...upLoadProps}>
                              <IconInput
                                placeholder={
                                  files[i]?.file || "Attach your file"
                                }
                                prefix={<AttachIcon />}
                                value={""}
                              />
                            </Upload>
                          </div>
                        </StyledFormItem>
                      </WrapInput>
                      <StyledFormItem
                        name={"fileName" + i}
                        rules={[requiredRules]}
                      >
                        <BaseInput placeholder="Give a name to your file" />
                      </StyledFormItem>
                    </Wrap12>
                    <Wrap12>
                      <StyledFormItem name={"link" + i} rules={[urlRules]}>
                        <BaseInput placeholder="Submit the link" />
                      </StyledFormItem>
                      <StyledFormItem
                        name={"linkName" + i}
                        dependencies={["link" + i]}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const link = getFieldValue(`link${i}`);
                              if (!link) {
                                return Promise.resolve();
                              }
                              if (!value)
                                return Promise.reject(
                                  new Error("Can be blank!")
                                );
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <BaseInput placeholder="Give a name to your Link" />
                      </StyledFormItem>
                    </Wrap12>
                    {i > 0 && (
                      <DeleteButton
                        style={{
                          marginRight: 0,
                          marginLeft: "auto",
                          marginTop: 10,
                        }}
                        onClick={() => {
                          setNumberOfInput(
                            numberOfInput.filter((item) => item !== i)
                          );
                        }}
                      >
                        Remove file
                      </DeleteButton>
                    )}
                  </div>
                ) : null
              )}
            </FlexWrap>
            <AddAnotherFile
              onClick={() =>
                setNumberOfInput([...numberOfInput, numberOfInput.length])
              }
            >
              <PlusIcon /> Add another file <PurpleAttachIcon />
            </AddAnotherFile>
            <SubmitBtnWrap>
              <PrimaryButton htmlType="submit">
                <SubmitBtn>Submit Deliverable</SubmitBtn>
              </PrimaryButton>
            </SubmitBtnWrap>
          </Form>
        </SubmitDeliverModal>
      )}

      {popupIndex === 2 && (
        <SuccessfulWrap>
          <div>
            <SuccessIcon />
            <div>This package project successfully delivered</div>
            <p>
              I am looking for a reliable web developer to build a simple site
              for me in the next several weeks.
            </p>
            <SuccessBtn
              height="32px"
              width="216px"
              color="rgba(41, 46, 115, 0.82)"
              background="#FFFFFF"
              onClick={() => {
                navigate(
                  `/deliver-work/${pageParams?.projectId}/${pageParams?.packageId}`,
                  {
                    state: {
                      toDeliverTab: true,
                    },
                  }
                );
              }}
            >
              Go Back To Deliverables Page
            </SuccessBtn>
          </div>
        </SuccessfulWrap>
      )}
    </div>
  );
};

export default SubmitNewDeliverable;
