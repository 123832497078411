// import FontFaceObserver from "fontfaceobserver";
import { useLayoutEffect, useState } from "react";

// const azoSansObserver = new FontFaceObserver("DM Sans", {});
// const avenirObserver = new FontFaceObserver("Rubik", {});

// const timeout = process.env.REACT_APP_OVERLAY_TIME_OUT || 5000;

export function useFontObserver() {
  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 500)).finally(() => {
      setLoaded(true);
    });
  }, []);

  return loaded;
}
