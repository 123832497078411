import styled from "styled-components";
import seedBG from "assets/images/png/seed-group-bg.png";
import bg1 from "assets/images/png/bg_1.png";
import PrimaryButton from "components/base/primaryButton";
import DoMoreWithBaked from "./components/DoMoreWithBaked";
import { OutlineButton } from "pages/IDESubmit/IDESubmit.style";

export const Wrapper = styled.div`
  .full-bg {
    position: relative;
    ::before {
      background-image: url(${bg1});
      background-size: cover;
      height: 100%;
      position: absolute;
      top: 0rem;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      z-index: 0;
      content: "";
    }
  }
`;

export const HightLightText = styled.span`
  color: #7e00fd;
`;

export const ProjectList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(376px, 1fr));
  gap: 20px;
`;

export const Container = styled.div`
  overflow: hidden;
`;

export const ContainerWrapper = styled.div`
  max-width: 73.125rem;
  margin: 101px auto;
  display: grid;
  gap: 101px;
`;

export const ProjectWrapper = styled(Container)`
  background: rgba(156, 138, 193, 0.1);
`;

export const ProjectWrapperItem = styled.div<{ bgColor?: string }>`
  background: ${(p) => p.bgColor};
`;

export const SeedBackground = styled.div`
  background-image: url(${seedBG});
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 0rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  z-index: 0;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    width: 777px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 135%;
    letter-spacing: -0.03em;
    color: #080f6c;
    margin-bottom: unset;
    text-align: center;
  }
  & > span {
    width: 766px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 156%;
    text-align: center;
    letter-spacing: -0.5px;
    color: rgba(41, 46, 115, 0.82);
    margin-top: 16px;
  }
  padding: 80px 0px;
`;

export const DoMoreWithReBakedStyled = styled(DoMoreWithBaked)``;

export const WrapButton = styled.div`
  display: flex;
  gap: 20px;
`;

export const PrimaryButtonStyled = styled(PrimaryButton)`
  margin-top: 36px;
  padding: 20px 32px !important;
`;

export const OutlineButtonStyled = styled(OutlineButton)`
  margin-top: 36px;
  padding: 20px 32px !important;
  height: auto;
`;

export const Block = styled.div`
  display: grid;
  gap: 60px;
`;

export const SponsorIcons = styled.div`
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled.div`
  padding: 22px 16px 22px 16px;
  display: flex;
  justify-content: space-between;
  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #080f6c;
  }
  span {
    max-width: 585.4px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 156%;
    color: rgba(41, 46, 115, 0.82);
  }
  background: linear-gradient(
    to bottom,
    rgba(216, 177, 255, 0.26) 0%,
    rgba(237, 221, 255, 0.44) 37%,
    rgba(247, 239, 255, 0.62) 57%,
    rgba(251, 248, 255, 0.8) 78%,
    rgba(255, 254, 255, 0.67) 97%,
    rgba(255, 255, 255, 0.12) 100%
  );
`;

export const ProjectHeader = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 115%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #080f6c;
  background: rgba(156, 138, 193, 0.1);
  padding: 48px 0;
  margin: auto;
`;

export const IntroContainer = styled.div`
  max-width: 73.125rem;
`;
