import { CaretDownOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Checkbox, Image, Input, message, Radio, Select, Tooltip } from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";
import {
  ASSIGNMENT_STATUS,
  COURSE_TYPE,
  IAssignmentRes,
  IParticipationRes,
  LESSON_ASSIGNMENT_CATEGORY,
  SortEnum,
} from "api/course";
import {
  getParticipantsByCourseIdAPI,
  updateParticipantAPI,
  updateStatusAssignmentSingleAPI,
  updateStatusAssignmentsMultipleAPI,
} from "api/course/request";
import { InfoIcon } from "assets/icons/i.icon";
import dayjs from "dayjs";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { setPageLoading } from "redux/slices";
import { ITabContentProps } from "./TabContent.props";
import {
  ActionWrap,
  Approve,
  BaseModalCustom,
  Container,
  ImageWrap,
  Label,
  NFTModal,
  ReasonContent,
  Reject,
  SendReward,
  TabContent,
  TableCustom,
  TabPaneCustom,
  TabsCustom,
} from "./tabContent.style";
import {
  getCourseDataSC,
  getCourseIdFromTx,
  completedCourseSC,
} from "web3/learnToEarnContract";
import NFTList from "../NFTList";
import { handleMessageErrorSC } from "web3/contract";
import { validateImage } from "utils/regex";
import {
  CourseDescription,
  PollWrap,
} from "pages/courseDetailNew/path/path.style";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { WrapFileAndInput } from "../listAssignmentForCreator.style";

const TabContentPage: FC<ITabContentProps> = ({
  course,
  inCourseDetails = false,
  refetchCourse,
}) => {
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  const dispatch = useAppDispatch();

  const { mutateAsync: updateParticipantMutate } =
    useMutation(updateParticipantAPI);
  const actionDefault = useMemo(() => {
    return {
      action: "Action",
      index: -1,
    };
  }, []);

  const [visible, setVisible] = useState<IParticipationRes | null>(null);
  const [NFTIds, setNFTIds] = useState<string[]>([]);
  const [action, setAction] = useState<{
    action: string;
    index: number;
  }>(actionDefault);
  const [participant, setParticipant] = useState<IParticipationRes>();
  const [explain, setExplain] = useState<string>("");
  const [rewardSending, setRewardSending] = useState<boolean>(false);
  const [params, setParams] = useState({
    courseId: course._id!,
    sortByEmail: SortEnum.NONE,
    sortByCompletedDate: SortEnum.NONE,
    page: 1,
    page_size: 10,
    status: [],
  });

  const [result, setResult] = useState<{
    total: number;
    data: IParticipationRes[];
  }>();
  const { mutate: getParticipantsByCourseIdMutate } = useMutation(
    getParticipantsByCourseIdAPI,
    {
      onSuccess: (response) => {
        setResult(response?.responseData);
        setAction(actionDefault);
      },
    }
  );

  useEffect(() => {
    if (course && params) getParticipantsByCourseIdMutate(params);
  }, [course, params, getParticipantsByCourseIdMutate]);

  const handleSendReward = async (record: IParticipationRes, nftIds) => {
    const courseId = await getCourseIdFromTx(course.txHash);
    dispatch(setPageLoading(true));
    if (courseId) {
      completedCourseSC({
        courseId,
        learner: record.user.walletAddress,
        timeStarted: dayjs(record.startDate).unix(),
        timeCompleted: dayjs(record.completedDate).unix(),
        nftIds,
      })
        .then(async (tx) => {
          updateParticipantMutate({
            canClaimReward: true,
            courseId: course._id!,
            participationId: record._id,
            txHash: tx.hash,
          });
          await tx.wait();
          setTimeout(() => {
            setParams((prev) => {
              return { ...prev };
            });
          }, 5000);
          message.success("Update assignments status successfully!");
          setVisible(null);
          setRewardSending(false);
          dispatch(setPageLoading(false));
          if (refetchCourse) refetchCourse();
        })
        .catch((err: any) => {
          dispatch(setPageLoading(false));
          console.log("err", err);
          handleMessageErrorSC(err?.reason);
          setAction(actionDefault);
          setVisible(null);
          setRewardSending(false);
        });
    }
  };

  const handleApprove = async (record: IParticipationRes) => {
    try {
      if (!checkWallet()) return;
      await handleSwitchNetwork(+course.chainId!);

      const courseId = await getCourseIdFromTx(course.txHash);

      if (!courseId) {
        message.error("Your courseId not valid");
        return;
      }
      if (!record.user.walletAddress) {
        message.error("This user does not connect wallet");
        return;
      }
      const { isBonusToken, canMintNFT } = await getCourseDataSC(courseId);

      if (isBonusToken || canMintNFT) {
        handleSendReward(record, []);
      } else {
        setVisible(record);
      }
    } catch (err: any) {
      setLoadingSendReward(false);
      dispatch(setPageLoading(false));
      setAction(actionDefault);
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const canDeposit = (record: IParticipationRes) => {
    const partcipants: IParticipationRes[] = (result as any)?.data;
    const ammountOfRewards = partcipants.reduce(
      (acc, item) => (item.hasClaimedReward ? acc + 1 : acc),
      0
    );

    if (course.rewards.prizeTotal === ammountOfRewards) return false;

    switch (course.type) {
      case COURSE_TYPE.CUSTOM_DAY_PLAN:
        const duration =
          dayjs(record.completedDate).unix() - dayjs(record.startDate).unix();
        return duration < course.range;
      case COURSE_TYPE.START_AND_END_DATE:
        const joinedTime = dayjs(record.startDate).unix();
        const finishTime = dayjs(record.completedDate).unix();
        const startDate = course.startDate;
        const endDate = course.endDate;
        if (!course.noExpire)
          return joinedTime > startDate && finishTime < endDate;
        return joinedTime > startDate;
      default:
        return false;
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <>
          <h1>Name </h1>
          <span style={{ display: "inline-block" }}>(Leaner name)</span>
        </>
      ),
      key: "fullName",
      dataIndex: ["user", "fullName"],
      render: (value, record: IParticipationRes, index) => (
        <>
          <h1>{value ?? record.user.emailId.split("@")[0]}</h1>
          {record.user.username && <span> ({record.user.username})</span>}
        </>
      ),
      width: "15%",
    },
    {
      title: <h1>Email </h1>,
      dataIndex: ["user", "emailId"],
      sorter: true,
      key: "emailColumn",
      width: "18%",
    },
    {
      title: <h1>Completion Date & Time</h1>,
      dataIndex: ["completedDate"],
      sorter: true,
      render: (value) => <>{moment(value).format("YYYY-MM-DD")}</>,
      key: "completeDateColumn",
      width: "18%",
    },
    {
      key: "awarded",
      title: <h1>Awarded</h1>,
      render: (value, record: IParticipationRes) => (
        <Checkbox checked={record.hasClaimedReward}></Checkbox>
      ),
      width: "10%",
    },
    {
      title: <h1>Approved </h1>,
      render: (value, record: IParticipationRes) => (
        <Checkbox
          checked={
            record.learnedActivities.length > 0
              ? record.learnedActivities.length === record.activity_count &&
                record.learnedActivities.every(
                  (item) =>
                    item.assignments.status === ASSIGNMENT_STATUS.APPROVED
                )
              : false
          }
        ></Checkbox>
      ),
      filters: [
        { text: "Approved", value: "APPROVED" },
        { text: "Waiting", value: "WAITING" },
      ],
      key: "approveColumn",
      width: "12%",
    },
    {
      key: "status",
      title: (
        <Tooltip title="Approved - Rejected - Waiting">
          <h1>Status </h1>
          <span style={{ display: "inline-block" }}>(A-R-W)</span>
        </Tooltip>
      ),
      render: (_, record: IParticipationRes) => (
        <>
          {(() => {
            const result: any = record.learnedActivities.reduce(
              (acc: any, item) => {
                let plus: string = "";
                switch (item.assignments.status) {
                  case "APPROVED":
                    plus = "a";
                    break;
                  case "REJECTED":
                    plus = "r";
                    break;
                  case "WAITING":
                    plus = "w";
                    break;
                  default:
                    break;
                }
                acc[plus] = acc[plus] + 1;
                return acc;
              },
              { a: 0, r: 0, w: 0 }
            );
            return `${result.a}-${result.r}-${result.w}`;
          })()}
        </>
      ),
      width: "12%",
    },
    {
      key: "action",
      title: <h1>Action </h1>,
      width: "15%",
      render: (_, record: IParticipationRes, index) => (
        <Select
          suffixIcon={
            action.index === index &&
            (action.action === "approve" ||
              action.action === "reject") ? undefined : (
              <CaretDownOutlined />
            )
          }
          style={{ minWidth: 100, textAlign: "center" }}
          className="button-select"
          defaultValue={actionDefault.action}
          loading={true}
          value={index === action.index ? action.action : actionDefault.action}
          onChange={(value) => {
            setAction({
              action: value,
              index: index,
            });
            switch (value) {
              case "view":
                setParticipant(record);
                setExplain(
                  record.learnedActivities[0].assignments.reasonRejected
                );
                break;
              case "reject":
                updateStatusAssignmentsMultipleMutate({
                  userId: record.user._id,
                  courseId: course._id!,
                  rejectedList: record.learnedActivities.map(
                    (item: IAssignmentRes) => item.activityId
                  ),
                  approvedList: [],
                });
                break;
              case "approve":
                if (canDeposit(record))
                  handleApprove(record).then(() => {
                    setAction(actionDefault);
                  });
                else {
                  updateStatusAssignmentsMultipleMutate({
                    userId: record.user._id,
                    courseId: course._id!,
                    rejectedList: [],
                    approvedList: record.learnedActivities.map(
                      (item: IAssignmentRes) => item.activityId
                    ),
                  });
                }
                break;
              default:
                break;
            }
          }}
          options={[
            {
              value: "view",
              label: "View",
            },
            {
              value: "reject",
              label: "Reject all",
              disabled: record.hasClaimedReward,
            },
            {
              value: "approve",
              label: "Approve all",
              disabled: record.hasClaimedReward,
            },
          ]}
        />
      ),
    },
  ];

  const onChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setParams((prev: any) => {
      return {
        ...prev,
        page:
          JSON.stringify(params.status) !==
          JSON.stringify(filters.approveColumn ?? [])
            ? 1
            : pagination.current,
        sortByEmail:
          (sorter as any).columnKey === "emailColumn"
            ? (sorter as any).order === "ascend"
              ? SortEnum.ASD
              : (sorter as any).order === "descend"
              ? SortEnum.DESC
              : SortEnum.NONE
            : SortEnum.NONE,
        sortByCompletedDate:
          (sorter as any).columnKey === "completeDateColumn"
            ? (sorter as any).order === "ascend"
              ? SortEnum.ASD
              : (sorter as any).order === "descend"
              ? SortEnum.DESC
              : SortEnum.NONE
            : SortEnum.NONE,
        status: filters.approveColumn ?? [],
      };
    });
  };

  const { mutate: updateStatusAssignmentSingleMutate } = useMutation(
    updateStatusAssignmentSingleAPI,
    {
      onSuccess: (res) => {
        const participantRes: IParticipationRes = res.responseData;
        setParams((prev) => {
          return { ...prev };
        });
        if (participant)
          setParticipant((prev: any) => {
            return {
              ...prev,
              learnedActivities: prev?.learnedActivities?.map(
                (learnedActivity: IAssignmentRes, index: number) => {
                  return {
                    ...learnedActivity,
                    assignments:
                      participantRes.learnedActivities[index].assignments,
                  };
                }
              ),
            };
          });
        message.success("Update status assignment successfully!");
      },
    }
  );

  const isSoCloseSendReward = useMemo(() => {
    if (participant && participant.learnedActivities) {
      return (
        participant.learnedActivities.length -
          participant.learnedActivities.reduce((acc, item) => {
            return item.assignments.status === "APPROVED" ? acc + 1 : acc;
          }, 0) ===
        1
      );
    }
    return false;
  }, [participant]);

  const { mutate: updateStatusAssignmentsMultipleMutate } = useMutation(
    updateStatusAssignmentsMultipleAPI,
    {
      onSuccess: () => {
        setParams((prev) => {
          return { ...prev };
        });
        message.success("Update status assignments successfully!");
      },
    }
  );

  const [loadingSendReward, setLoadingSendReward] = useState(false);

  return (
    <Container
      style={
        inCourseDetails ? { padding: "24px 0px" } : { padding: "24px 32px" }
      }
    >
      {!inCourseDetails && (
        <Link
          to={ROUTER_CONSTANTS.COURSE.COURSE_DETAIL.replace(
            ":id",
            course._id ?? ""
          )}
        >
          <h1>{course.title}</h1>
        </Link>
      )}
      <TableCustom
        columns={columns}
        dataSource={result?.data}
        pagination={{
          current: params.page,
          pageSize: params.page_size,
          total: result?.total,
          showSizeChanger: true,
          onShowSizeChange: (_, size) => {
            setParams((prev) => {
              return { ...prev, page_size: size };
            });
          },
          pageSizeOptions: ["1", "5", "10", "20"],
        }}
        rowKey="_id"
        onChange={onChange}
      />
      <BaseModalCustom
        closable
        visible={participant && participant.learnedActivities ? true : false}
        onCancel={() => {
          setAction(actionDefault);
          setParticipant(undefined);
        }}
      >
        {participant && participant.learnedActivities && (
          <TabsCustom
            defaultActiveKey={participant?.learnedActivities[0]?._id}
            onChange={(value) => {
              const activity = participant?.learnedActivities.find(
                (item) => item._id === value
              );
              setExplain(activity?.assignments?.reasonRejected ?? "");
            }}
            username={
              participant?.user.fullName ??
              participant.user.emailId.split("@")[0]
            }
          >
            {participant?.learnedActivities.map((activity) => {
              return activity.activity ? (
                <TabPaneCustom tab={activity.activity.title} key={activity._id}>
                  <TabContent>
                    <ImageWrap>
                      <CourseDescription className="ck-content">
                        <p> Question: </p>
                        <div className="question">
                          {parse(
                            DOMPurify.sanitize(
                              activity.activity.assignments?.question ?? ""
                            )
                          )}
                        </div>
                      </CourseDescription>
                      {/* for poll */}
                      {activity.activity.assignments?.category ===
                        LESSON_ASSIGNMENT_CATEGORY.POLL && (
                        <PollWrap className="options">
                          {activity.activity.assignments.multiValid && (
                            <Checkbox.Group
                              style={{ width: "100%" }}
                              defaultValue={activity.assignments.pollOptions}
                              disabled
                            >
                              {activity.activity.assignments.options.map(
                                (item) => (
                                  <Checkbox
                                    key={item._id}
                                    className="item"
                                    value={item._id}
                                    style={
                                      activity.activity.assignments?.options.some(
                                        (el) => el.valid && el._id === item._id
                                      )
                                        ? { background: "#2EB67D" }
                                        : {}
                                    }
                                  >
                                    {item.value}
                                  </Checkbox>
                                )
                              )}
                            </Checkbox.Group>
                          )}

                          {!activity.activity.assignments.multiValid && (
                            <Radio.Group
                              style={{ width: "100%" }}
                              defaultValue={activity.assignments.pollOptions[0]}
                              disabled
                            >
                              {activity.activity.assignments.options.map(
                                (item) => (
                                  <Radio
                                    key={item._id}
                                    className="item"
                                    value={item._id}
                                    style={
                                      activity.activity.assignments?.options.some(
                                        (el) => el.valid && el._id === item._id
                                      )
                                        ? { background: "#2EB67D" }
                                        : {}
                                    }
                                  >
                                    {item.value}
                                  </Radio>
                                )
                              )}
                            </Radio.Group>
                          )}
                        </PollWrap>
                      )}

                      {/* for file */}
                      {activity.activity.assignments?.category ===
                        LESSON_ASSIGNMENT_CATEGORY.FILE && (
                        <WrapFileAndInput>
                          {validateImage.test(activity.assignments.fileUrl) && (
                            <Image src={activity.assignments.fileUrl} alt="" />
                          )}
                          {!validateImage.test(
                            activity.assignments.fileUrl
                          ) && (
                            <a href={activity.assignments.fileUrl}>
                              {activity.assignments.fileUrl.split("/").pop()}
                            </a>
                          )}
                        </WrapFileAndInput>
                      )}
                      {/* for text */}
                      {activity.activity.assignments?.category ===
                        LESSON_ASSIGNMENT_CATEGORY.TEXT && (
                        <WrapFileAndInput>
                          <Input.TextArea
                            value={activity.assignments.content}
                          />
                        </WrapFileAndInput>
                      )}
                    </ImageWrap>
                    <ReasonContent>
                      <Label>
                        <h1>Explain</h1>
                        <InfoIcon></InfoIcon>
                      </Label>
                      <Input.TextArea
                        value={explain}
                        onChange={(e) => setExplain(e.target.value)}
                      ></Input.TextArea>
                    </ReasonContent>
                    <ActionWrap>
                      <Reject
                        onClick={() => {
                          updateStatusAssignmentSingleMutate({
                            userId: participant.user._id,
                            courseId: course._id!,
                            explain: explain,
                            activityId: activity.activityId,
                            status: ASSIGNMENT_STATUS.REJECTED,
                          });
                        }}
                        disabled={
                          activity.assignments.status === "REJECTED" ||
                          participant.hasClaimedReward
                        }
                      >
                        {activity.assignments.status === "REJECTED"
                          ? "Rejected"
                          : "Reject"}
                      </Reject>
                      {(activity.assignments.status === "APPROVED" ||
                        !isSoCloseSendReward) && (
                        <Approve
                          onClick={() => {
                            updateStatusAssignmentSingleMutate({
                              userId: participant.user._id,
                              courseId: course._id!,
                              explain: explain,
                              activityId: activity.activityId,
                              status: ASSIGNMENT_STATUS.APPROVED,
                            });
                          }}
                          disabled={activity.assignments.status === "APPROVED"}
                        >
                          {activity.assignments.status === "APPROVED"
                            ? "Approved"
                            : "Approve"}
                        </Approve>
                      )}
                      {activity.assignments.status !== "APPROVED" &&
                        isSoCloseSendReward &&
                        canDeposit(participant) && (
                          <SendReward
                            onClick={() => {
                              setLoadingSendReward(true);
                              handleApprove(participant).then((res) => {
                                if (typeof res !== "undefined")
                                  updateStatusAssignmentSingleMutate({
                                    userId: participant.user._id,
                                    courseId: course._id!,
                                    explain: explain,
                                    activityId: activity.activityId,
                                    status: ASSIGNMENT_STATUS.APPROVED,
                                  });
                                setLoadingSendReward(false);
                                setParticipant(undefined);
                              });
                            }}
                            loading={loadingSendReward}
                          >
                            Approve & Send Reward
                          </SendReward>
                        )}

                      {activity.assignments.status !== "APPROVED" &&
                        isSoCloseSendReward &&
                        !canDeposit(participant) && (
                          <SendReward
                            loading={loadingSendReward}
                            onClick={() => {
                              setLoadingSendReward(true);
                              updateStatusAssignmentSingleMutate({
                                userId: participant.user._id,
                                courseId: course._id!,
                                explain: explain,
                                activityId: activity.activityId,
                                status: ASSIGNMENT_STATUS.APPROVED,
                              });
                              setLoadingSendReward(false);
                              setParticipant(undefined);
                            }}
                          >
                            Approve
                          </SendReward>
                        )}
                    </ActionWrap>
                  </TabContent>
                </TabPaneCustom>
              ) : (
                <></>
              );
            })}
          </TabsCustom>
        )}
      </BaseModalCustom>
      <NFTModal
        closable
        title="NFT List"
        visible={!!visible}
        destroyOnClose
        footer={[
          <Reject
            key="back"
            onClick={() => {
              setVisible(null);
              setNFTIds([]);
            }}
          >
            Cancel
          </Reject>,
          <Approve
            key="submit"
            type="primary"
            loading={rewardSending}
            onClick={() => {
              if (visible) {
                setRewardSending(true);
                handleSendReward(visible, NFTIds);
              }
            }}
          >
            Send Reward
          </Approve>,
        ]}
        onCancel={() => {
          setVisible(null);
        }}
        onOk={() => {
          setVisible(null);
        }}
      >
        <NFTList course={course} setNFTIds={setNFTIds} NFTIds={NFTIds} />
      </NFTModal>
    </Container>
  );
};

export default TabContentPage;
