import { Tabs } from "antd";
import OutlinedButton from "components/base/outlinedButton";
import styled from "styled-components";
import FormItem from "antd/es/form/FormItem";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import PrimaryButton from "components/base/primaryButton";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";

export const ProjectActionBtn = styled(OutlinedButton)<{ $cl?: string }>`
  background: transparent;
  border: none;
  width: unset;
  height: unset;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px 16px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${({ $cl }) => $cl || "#e94600"};

  &.ant-btn > span {
    display: flex;

    svg {
      position: relative;
      top: -1px;
    }
  }

  &:hover,
  &:focus {
    background: transparent;
    color: ${({ $cl }) => $cl || "#e94600"};
  }
`;

export const OrgContentWrapper = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: auto;
  position: relative;
  z-index: 1;
`;

export const CreateWorkTaskBtn = styled(PrimaryButton)`
  margin-top: 8px;
  padding: 8px 12px;
`;

export const TotalBudgetNumber = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #080f6c;
`;

export const SponsorText = styled.span`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  color: rgba(41, 46, 115, 0.82);
`;

export const TotalBudgetText = styled(SponsorText)`
  line-height: 125%;
  letter-spacing: -0.02em;
`;

export const SponsorName = styled(SponsorText)`
  font-weight: 500;
  color: #080f6c;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const SponsorWrapper = styled(CommonFlexRow)`
  flex-wrap: wrap;
`;

export const ProjectInfoLeft = styled(CommonFlexColumn)`
  align-items: flex-start;
`;

export const TagItem = styled(CommonFlexRow)<{
  $bg?: string;
  $cl?: string;
}>`
  justify-content: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding: 6px 8px;
  border-radius: 20px;
  text-transform: capitalize;
  background: ${({ $bg }) => $bg || "#49B67A"};
  ${({ $bg }) =>
    $bg !== undefined &&
    `
    border: 1px solid ${$bg};
  `}
  ${({ $cl }) =>
    $cl !== undefined &&
    `
    color: ${$cl};
  `}
`;

export const ProjectTitle = styled.h2`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-bottom: 0;
  word-break: break-all;
`;

export const OrgHeadInfo = styled(CommonFlexRow)`
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;

  > ${CommonFlexColumn}:last-child {
    flex-shrink: 0;
  }
`;

export const OrgHeadCenter = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: auto;
  position: relative;
`;

export const OrgHeadWrapper = styled.div`
  position: relative;
  padding-bottom: 32px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(208, 234, 255, 0.58) 0%,
      rgba(248, 233, 245, 0.75) 40%,
      rgba(246, 244, 241, 0.83) 60%,
      rgba(233, 247, 251, 0.92) 81%,
      rgba(233, 247, 250, 0.92) 100%
    );
  }
`;

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  // padding: 0rem 3.125rem;
  overflow: hidden;

  #head-wrap {
    padding-top: 1.5rem;
  }
`;
export const BackgroundImage = styled.img`
  width: 100%;
  aspect-ratio: 5.85;
  object-fit: fill;
`;
// export const BackgroundAllWorktasks = styled(BackgroundImage)`
//   height: 7.5rem;
// `;
export const AllWorktasks = styled.div`
  width: 100%;
  max-width: 73.125rem;
  margin: auto;
  position: relative;
  padding: 2.25rem 0rem;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 120%;
  color: #080f6c;
  overflow: visible;
`;
export const WorktaskList = styled.div`
  max-width: 73.125rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
export const BtnsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 4px;
  & > div {
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    color: rgba(41, 46, 115, 0.82);
    opacity: 0.8;
  }
`;
export const Desciption = styled.div`
  padding: 40px 30px 0px 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: rgba(41, 46, 115, 0.82);
  width: 56.8%;
  text-align: justify;
  word-wrap: break-word;
  width: 500px;
`;

export const StyledTabs = styled(Tabs)`
  user-select: none;
  padding-left: 30px;
  .ant-tabs-tab-btn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: rgba(41, 46, 115, 0.82);
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #080f6c;
  }
  .ant-tabs-ink-bar {
    background: #080f6c;
  }
`;

export const CollaboratorWrap = styled.div`
  /* padding: 94px 135px 59px; */
  /* display: flex;
  flex-direction: column;
  gap: 34px; */
  width: 100%;
  max-width: 1170px;
  margin: 40px auto 50px;
`;

export const BlockWrap = styled.div`
  padding: 0;
  /* background: rgba(156, 138, 193, 0.04); */
  border: 1px solid rgba(41, 46, 115, 0.12);
  width: 100%;
  margin-top: 20px !important;
`;

export const BlockHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background: rgba(156, 138, 193, 0.08);
  border: 1px solid rgba(41, 46, 115, 0.12);
  & > div:first-child {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: #080f6c;
  }
  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
    width: max-content;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 18px;

      font-weight: 400;
      font-size: 12px;
      line-height: 156%;
      color: rgba(41, 46, 115, 0.82);

      & > span {
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;

        color: rgba(41, 46, 115, 0.82);
      }
    }
  }
`;
export const Name = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  gap: 6px;
  width: 240px;
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-avatar {
    overflow: unset;
    margin-right: 6px;
  }
`;
export const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 24px;
`;

export const Item = styled.div`
  /* height: 89px; */
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background: #ffffff; */
  /* border: 1px solid rgba(156, 138, 193, 0.16); */
  border-radius: 4px;
  border-bottom: 1px solid rgba(41, 46, 115, 0.12);
  align-items: flex-start;
  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 12px;

    img {
      width: 36px;
      height: 36px;
      border-radius: 36px;
      object-fit: cover;
    }
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    color: rgba(41, 46, 115, 0.82);
  }

  & > div:last-child {
    display: flex;
    align-items: center;
    border-left: 1px solid rgba(156, 138, 193, 0.16);

    & > div {
      font-weight: 400;
      font-size: 16px;
      line-height: 125%;
      color: rgba(41, 46, 115, 0.82);
      padding: 0px 36px;
    }
    & > button {
      margin-left: 24px;
    }
    .ant-select {
      width: 184px !important;
    }
  }
`;

export const StyledFormItem = styled(FormItem)`
  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  .ant-form-item-label {
    padding-bottom: 0.625rem;
  }
  .upload-image {
    .ant-upload {
      width: 100%;
      height: unset;
    }
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
    }
  }
  .ant-row {
    display: block;
  }
`;

export const BlockFooter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;

  & > div:first-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    color: rgba(41, 46, 115, 0.82);
  }
`;

export const ObserversWrap = styled(CollaboratorWrap)`
  /* padding: 81px 135px 367px; */
  & > button {
    margin-right: 0;
    margin-left: auto;
  }
`;

export const CollaboratorRequestWrap = styled(CollaboratorWrap)`
  /* padding: 45px 50px 286px; */
  /* gap: 35px; */
  & > span {
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  & > * {
    max-width: 1170px;
    margin: auto;
  }
`;

export const EmptyText = styled.div`
  width: 100%;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: rgba(41, 46, 115, 0.82);
`;

export const SmallText = styled.div`
  color: rgba(41, 46, 115, 0.42);
  font-size: 12px;
`;

export const BigText = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
`;

export const EndContractButton = styled.div`
  cursor: pointer;
  min-width: 142px;
  height: 36px;
  background: #e9460036;
  border-radius: 4px;
  color: #e94600 !important;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  padding: 10px !important;
  display: flex;
  justify-content: center;
`;

export const SendMessageButton = styled(EndContractButton)<{ mr?: string }>`
  background: #49b67a45;
  color: #49b67a !important;
  margin-left: 20px;
  margin-right: ${(p) => p?.mr};
`;

export const PurpleText = styled.span`
  color: #7e00fd;
  font-weight: 600;
`;
export const SecondaryButton = styled(OutlinedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: auto;
  height: 42px;
  background: rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  color: rgba(41, 46, 115, 0.42);
`;

export const ActionPlan = styled.span`
  padding: 0 20px 0 0;
  width: 95%;
  word-break: break-word;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
`;
