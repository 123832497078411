import { Col, Dropdown, message, Row } from "antd";
import { FC, memo, useEffect, useState } from "react";
import clsx from "clsx";
import {
  ButtonLogin,
  ButtonSignup,
  Container,
  LoginContainer,
  LogoContainer,
  MenuItem,
  BellContainer,
  ButtonBell,
} from "./header.style";
import { IHeaderProps } from "./header.props";
import { Logo } from "assets/images/logo";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import DropBoxLogout from "components/modules/dropBoxLogout";
import socket from "../../modules/Socket/Socket";
import { setAuthData, setSocket, setUserInfo } from "redux/slices";
import { BellOutlined } from "@ant-design/icons";
import DropBoxNotification from "components/modules/dropBoxNotification";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  getAllNotifications,
  getProfileInfoAPI,
  updateNotificationStatus,
} from "api/users";
import { INotification } from "components/modules/dropBoxNotification/dropBoxNotification.props";
import { setConversation } from "redux/slices/conversationSlice";
import { END_POINT } from "api/users/endpoints";

const HeaderUser: FC<IHeaderProps> = () => {
  const [skip] = useState(0);
  const [limit] = useState(20);
  const [isRead, setIsRead] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { token, user } = useAppSelector((state) => {
    return state.auth;
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const _id = params.get("_id");
    if (token && _id) {
      const baseUrl = process.env.REACT_APP_API_URI;
      axios
        .get(`${baseUrl}${END_POINT.GET_PROFILE}`, {
          headers: { Authorization: token },
        })
        .then(({ data: res }) => {
          if (res.responseCode === 200) {
            message.success("Login success");
            localStorage.setItem("user", JSON.stringify(res.responseData));
            localStorage.setItem("token", token);
            dispatch(setAuthData({ user: res.responseData, token: token }));
            navigate(ROUTER_CONSTANTS.HOME);
          }
        });
    }
  }, [dispatch, location.search, navigate]);

  const menus = [
    {
      name: "Explore Communities",
      patch: "/",
    },
    {
      name: "Blog",
      patch: "/blog",
    },
    {
      name: "Conversation",
      patch: "/conversation",
    },
  ];

  const { isNewMessage } = useAppSelector((state) => {
    return state.conversation;
  });

  useQuery(["getProfile"], () => getProfileInfoAPI(), {
    onSuccess: async (res) => {
      if (res.responseCode === 200) {
        dispatch(setUserInfo(res.responseData));
      }
    },
    enabled: user !== null,
    refetchOnWindowFocus: false,
  });

  const { data: notiRes, refetch } = useQuery(
    ["getAllNotifications"],
    () => {
      return getAllNotifications({ skip, limit });
    },
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          const newNotification = res.responseData?.records.find(
            (notification: any) => notification.isRead === false
          );
          if (newNotification) setIsRead(false);
          else setIsRead(true);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      enabled: token !== null,
    }
  );

  const notifications: INotification[] = notiRes?.responseData?.records || [];

  const { mutate: updateNotifications } = useMutation(
    updateNotificationStatus,
    {
      onSuccess: (res) => {
        const { responseCode, responseMessage } = res;
        if (responseCode === 200) {
        } else {
          message.error(responseMessage);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
    }
  );

  const handleOnclickNotification = (isRead: boolean) => {
    if (!isRead) {
      updateNotifications({ notificationIds: [] });
      setIsRead(true);
    }
  };

  //Socket start
  useEffect(() => {
    if (!!user?._id) {
      dispatch(setSocket({ socket }));
      let socketConnection = socket.connect();
      socket.removeAllListeners("connect");
      socketConnection.on("connect", function () {
        socket.emit("setSocketId", {
          id: user._id,
          socketId: socketConnection.id,
        });
      });
      socketConnection.on("getNewMessages", function (value: any) {
        if (value && !value.worktask)
          dispatch(setConversation({ isNewMessage: true }));
      });

      socketConnection.on("newUpdateCourseNotify", function () {
        refetch();
      });

      socketConnection.on("newPublishCourseNotify", function () {
        refetch();
      });

      socketConnection.on("getNewUpdateFromProject", function () {
        refetch();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [dispatch, refetch, user?._id]);
  //Socket end

  return (
    <Container>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Menu in left */}
        <Col id="large-item-size-desktop" span={10}>
          {menus.map((item, key) => (
            <MenuItem
              to={item.patch}
              key={key}
              className={clsx(location.pathname === item.patch && "active")}
            >
              {item.name}
              {item.name === "Conversation" && isNewMessage && <span />}
            </MenuItem>
          ))}
        </Col>
        {/* Logo in center */}
        <LogoContainer span={6}>
          <Logo onClick={() => navigate(ROUTER_CONSTANTS.HOME)} />
        </LogoContainer>
        {/* Menu in right */}
        <LoginContainer span={8} style={{ gap: "10px" }}>
          {token ? (
            <DropBoxLogout />
          ) : (
            <>
              <ButtonLogin
                onClick={() => navigate(ROUTER_CONSTANTS.USER.LOGIN)}
              >
                Login
              </ButtonLogin>
              <ButtonSignup
                onClick={() => navigate(ROUTER_CONSTANTS.USER.SIGNUP)}
              >
                Sign Up
              </ButtonSignup>
            </>
          )}
          {token ? (
            <BellContainer span={1}>
              <Dropdown
                placement="bottomRight"
                overlay={<DropBoxNotification notifications={notifications} />}
                onVisibleChange={() => handleOnclickNotification(isRead)}
              >
                <ButtonBell isRead={isRead}>
                  <BellOutlined />
                </ButtonBell>
              </Dropdown>
            </BellContainer>
          ) : (
            <></>
          )}
        </LoginContainer>
      </Row>
    </Container>
  );
};

export default memo(HeaderUser);
