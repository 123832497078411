import {
  AvatarSponsor,
  BackgroundImage,
  BodyWrap,
  Container,
  EndTime,
  Gap,
  Head,
  HeadWrap,
  SponsoredBy,
  Status,
  // Title,
  Wrap,
} from "pages/WorktasksInside/WorktasksInside.style";
import { FC, useEffect } from "react";
import { IReviewWorktaskProps } from "./ReviewWorktask.props";
import ReviewWorktaskBackground from "assets/images/review-worktask/review-worktask-background.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import FlexBox from "components/base/Flexbox";
import { ROUTER_CONSTANTS } from "utils/constant";
import { TitleBlack, TitleColor } from "./ReviewWorktask.style";
import DeliverCollaborators from "pages/DeliverWork/components/DeliverCollaborators";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "redux/hooks";
import { message } from "antd";
import { useGetWorktaskDetailAndCheckIsStranger } from "hooks";
import BackToProjectDetail from "components/modules/backToDetail";

const ReviewWorktask: FC<IReviewWorktaskProps> = () => {
  const userId = useAppSelector((state) => state.auth.user?._id);
  const navigate = useNavigate();
  const param = useParams();
  const { worktaskDetail, isLoading } =
    useGetWorktaskDetailAndCheckIsStranger(param); // wtDetailRes?.responseData;
  const isFinish = worktaskDetail?.finishStatus === "SUCCESS";

  useEffect(() => {
    if (isLoading) return;

    if (userId !== worktaskDetail?.initiator) {
      message.error("You are not the worktask creator!");
      navigate("/");
    }
  }, [isLoading, navigate, userId, worktaskDetail?.initiator]);

  return (
    <Container>
      <Wrap>
        <BackgroundImage
          src={ReviewWorktaskBackground}
          alt="WorktasksInside background"
        />
        <HeadWrap>
          <Head>
            <div>
              <BackToProjectDetail
                linkToGo={ROUTER_CONSTANTS.PROJECT.DETAIL.replace(
                  ":id",
                  param.projectId as string
                )}
                linkText="Back to project detail"
              />
              <TitleBlack>Review Applicants for</TitleBlack>
              <TitleBlack>
                {isLoading ? (
                  <Skeleton width={280} />
                ) : (
                  <>
                    “<TitleColor>{worktaskDetail?.name}</TitleColor>”
                  </>
                )}
              </TitleBlack>

              <Wrap>
                <Status>
                  {isLoading ? (
                    <Skeleton width={90} />
                  ) : (
                    worktaskDetail?.workStatus
                  )}
                </Status>
                {isFinish || (
                  <EndTime>
                    Ends on{" "}
                    {isLoading ? (
                      <Skeleton width={100} style={{ marginLeft: "4px" }} />
                    ) : (
                      dayjs(worktaskDetail?.endDate).format("DD MMM YY")
                    )}
                  </EndTime>
                )}
              </Wrap>
              <Gap />
              <Wrap>
                <SponsoredBy>Sponsored by</SponsoredBy>
                <FlexBox gap="5px">
                  {worktaskDetail?.organizations?.map((org: any, i: number) => (
                    <Link
                      to={ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(
                        ":id",
                        org._id
                      )}
                      title={org?.name}
                      key={i}
                    >
                      <AvatarSponsor src={org?.avatar} alt="Avatar" />
                    </Link>
                  ))}
                </FlexBox>
              </Wrap>
            </div>
          </Head>
        </HeadWrap>
      </Wrap>
      <BodyWrap>
        <DeliverCollaborators
          projectId={param.projectId}
          packageId={param.packageId}
          requestMode
          worktaskDetail={worktaskDetail}
        />
      </BodyWrap>
    </Container>
  );
};
export default ReviewWorktask;
