import { FC, ReactElement, useState } from "react";
import { IWorktasksProps } from "./Worktasks.props";
import OutlinedButton from "components/base/outlinedButton";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "components/base/breadcrumb";
import WorktasksBackground from "assets/images/worktasks/worktasks-background.png";
import AllWorktasksBackground from "assets/images/worktasks/all-worktasks-background.png";
import OrganizationHead from "pages/OrganizationPage/OrganizationHead";
import { FollowingIcon } from "assets/images/worktasks";
import Worktask from "./Worktask";
import {
  Container,
  BackgroundImage,
  AllWorktasksLabel,
  WorktaskList,
} from "./Worktasks.style";

import {
  getAllPackagesOrganization,
  getOrganizationInfoAPI,
  IOrganization,
} from "api/users";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

const Worktasks: FC<IWorktasksProps> = ({
  ...props
}: IWorktasksProps): ReactElement => {
  const param = useParams();
  const [organization, setOrganization] = useState<IOrganization>();
  const [worktasks, setWorktasks] = useState([]);

  const location = useLocation();
  const pathSplit = location.pathname.split("/");
  const items = [
    {
      text: organization?.name || "",
      to: "/" + pathSplit[1],
    },
    {
      text: "Worktasks",
      to: location.pathname,
    },
  ];

  useQuery(
    ["getAllPackagesOrganization"],
    () => getAllPackagesOrganization(param.id || ""),
    {
      onSuccess: (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setWorktasks(res.responseData);
        }
      },
    }
  );

  useQuery(
    ["getOrganizationData"],
    () => getOrganizationInfoAPI(param.id || ""),
    {
      onSuccess: (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setOrganization(res.responseData);
        }
      },
    }
  );

  return (
    <Container>
      <BackgroundImage src={WorktasksBackground} alt="Worktasks background" />
      <Breadcrumb items={items} />
      <OrganizationHead organization={organization}>
        <OutlinedButton
          width="109px"
          height="32px"
          color="rgba(41, 46, 115, 0.82)"
        >
          <FollowingIcon />
          Following
        </OutlinedButton>
      </OrganizationHead>
      <AllWorktasksLabel>
        <BackgroundImage
          src={AllWorktasksBackground}
          alt="All Worktasks background"
        />
        All Worktasks
      </AllWorktasksLabel>
      <WorktaskList>
        {worktasks.map((w: any, i) => (
          <Worktask
            data={w}
            projectId={w.projectId}
            category={""}
            totalBudget={0}
            key={i}
            hideButton={false}
          />
        ))}
      </WorktaskList>
    </Container>
  );
};

export default Worktasks;
