import { FC } from "react";
import { ProjectWrapper, ProjectTitle, ProjectList } from "./allProjects.style";
import { AllProjectsProps } from "../OrganizationPage.props";
import ProjectCardItem from "components/modules/projectCardItem";

const AllProjects: FC<AllProjectsProps> = ({ allProjects }) => {
  return (
    <ProjectWrapper>
      <ProjectTitle>All projects</ProjectTitle>
      <ProjectList>
        {allProjects?.length > 0 &&
          allProjects?.map((val) => {
            return <ProjectCardItem key={val?._id} project={val} />;
          })}
      </ProjectList>
    </ProjectWrapper>
  );
};

export default AllProjects;
