import ResultsNotFound from "components/modules/resultsNotFound";
import { FC, memo, useState } from "react";
import {
  IInitiatorProps,
  // IProject,
  IWorkTaskData,
} from "./initiator.props";
import {
  Container,
  ContainerBodyInitiator,
  PanelCollapse,
  ContainerProjectSummary,
  ProjectSummaryContent,
  ProjectSummaryItem,
  ContentHeading,
  TitleHeading,
  SeeMore,
} from "./initiator.style";
import imgNotFound from "assets/dashboard/result_not_found.png";
import {
  getAllCompletedWorkTasks,
  getAllDeliveredWorkTasks,
  getAllInprogressProjectsInitiatorAPI,
  getAllOpenWorkTasks,
} from "api/users";
import {
  awaitingIcon,
  completedIcon,
  openIcon,
  underApprovalIcon,
} from "assets/dashboard/index";
import { DownIcon } from "assets/icons/down.icon";
import { useQueries } from "@tanstack/react-query";
import Worktask from "pages/Worktasks/Worktask";
// import PrimaryButton from "components/base/primaryButton";

const Initiator: FC<IInitiatorProps> = ({ ...props }: IInitiatorProps) => {
  const [openLimit, setOpenLimit] = useState(2);
  const [inprogressLimit, setInprogressLimit] = useState(2);
  const [completedLimit, setCompletedLimit] = useState(2);
  const [deliveredLimit, setDeliveredLimit] = useState(2);
  // const [workTasks, setWorkTasks] = useState<IWorkTaskDashboardData>({});
  const headerCards = [
    {
      key: 0,
      text: "Open packages",
      limit: openLimit,
    },
    {
      key: 1,
      text: "In Progress",
      limit: inprogressLimit,
    },
    {
      key: 2,
      text: "Delivered",
      limit: deliveredLimit,
    },
    {
      key: 3,
      text: "Completed",
      limit: completedLimit,
    },
  ];

  const allworktasksQueries = useQueries({
    queries: [
      {
        queryKey: ["getAllOpenWorkTasksInitiator", `limit-${openLimit}`],
        queryFn: () =>
          getAllOpenWorkTasks({
            skip: 0,
            limit: openLimit,
          }),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["getAllInProgressInitiator", `limit-${inprogressLimit}`],
        queryFn: () =>
          getAllInprogressProjectsInitiatorAPI({
            skip: 0,
            limit: inprogressLimit,
          }),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["getAllDeliveredInitiator", `limit-${deliveredLimit}`],
        queryFn: () =>
          getAllDeliveredWorkTasks({
            skip: 0,
            limit: deliveredLimit,
          }),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["getAllCompletedInitiator", `limit-${completedLimit}`],
        queryFn: () =>
          getAllCompletedWorkTasks({
            skip: 0,
            limit: completedLimit,
          }),
        refetchOnWindowFocus: false,
      },
    ],
  });

  const workTasks: (IWorkTaskData | undefined)[] = allworktasksQueries.map(
    (wtQuery) => wtQuery.data?.responseData
  );

  // const {
  //   mutate: getOpenWorkTasks,
  //   mutateAsync: getOpenWorkTasksAsync,
  //   isLoading: isOpenWorkTaskLoading,
  // } = useMutation(getAllOpenWorkTasks, {
  //   onError: (err: AxiosError) => {
  //     const data: any = err.response?.data;
  //     message.error(data?.message);
  //   },
  // });

  // const {
  //   mutate: getInprogressWorkTasks,
  //   mutateAsync: getInprogressWorkTasksAsync,
  //   isLoading: isInprogressWorkTaskLoading,
  // } = useMutation(getAllInprogressProjectsInitiatorAPI, {
  //   onError: (err: AxiosError) => {
  //     const data: any = err.response?.data;
  //     message.error(data?.message);
  //   },
  // });

  // const {
  //   mutate: getCompletedWorkTasks,
  //   mutateAsync: getCompletedWorkTasksAsync,
  //   isLoading: isCompletedWorkTaskLoading,
  // } = useMutation(getAllCompletedWorkTasks, {
  //   onError: (err: AxiosError) => {
  //     const data: any = err.response?.data;
  //     message.error(data?.message);
  //   },
  // });

  // const {
  //   mutate: getDeliveredWorkTasks,
  //   mutateAsync: getDeliveredWorkTasksAsync,
  //   isLoading: isDeliveredWorkTaskLoading,
  // } = useMutation(getAllDeliveredWorkTasks, {
  //   onError: (err: AxiosError) => {
  //     const data: any = err.response?.data;
  //     message.error(data?.message);
  //   },
  // });

  // useEffect(() => {
  //   (async () => {
  //     const [open, received, delivered, completed] = await Promise.all([
  //       getOpenWorkTasksAsync({
  //         skip: 0,
  //         limit: 2,
  //       }),
  //       getInprogressWorkTasksAsync({
  //         skip: 0,
  //         limit: 2,
  //       }),
  //       getDeliveredWorkTasksAsync({
  //         skip: 0,
  //         limit: 2,
  //       }),
  //       getCompletedWorkTasksAsync({
  //         skip: 0,
  //         limit: 2,
  //       }),
  //     ]);
  //     setWorkTasks({
  //       ...workTasks,
  //       openWorkTasks: open.responseData,
  //       requestReceivedWorkTasks: received.responseData,
  //       deliveredWorkTasks: delivered.responseData,
  //       completedWorkTasks: completed.responseData,
  //     });
  //   })();
  // }, []);

  // click more button open
  const handleShowMore = async (key: number) => {
    switch (key) {
      case 0:
        // const open = await getOpenWorkTasksAsync({
        //   skip: 0,
        //   limit: openLimit + 2,
        // });
        setOpenLimit(openLimit + 2);
        // setWorkTasks({
        //   ...workTasks,
        //   openWorkTasks: { ...open.responseData },
        // });
        break;
      case 1:
        // const received = await getInprogressWorkTasksAsync({
        //   skip: 0,
        //   limit: inprogressLimit + 2,
        // });
        setInprogressLimit(inprogressLimit + 2);
        // setWorkTasks({
        //   ...workTasks,
        //   requestReceivedWorkTasks: { ...received.responseData },
        // });
        break;
      case 2:
        // const delivered = await getDeliveredWorkTasksAsync({
        //   skip: 0,
        //   limit: deliveredLimit + 2,
        // });
        setDeliveredLimit(deliveredLimit + 2);
        // setWorkTasks({
        //   ...workTasks,
        //   deliveredWorkTasks: { ...delivered.responseData },
        // });
        break;
      case 3:
        // const completed = await getCompletedWorkTasksAsync({
        //   skip: 0,
        //   limit: completedLimit + 2,
        // });
        setCompletedLimit(completedLimit + 2);
        // setWorkTasks({
        //   ...workTasks,
        //   completedWorkTasks: { ...completed.responseData },
        // });
        break;
    }
  };
  const titleHeading = [
    <TitleHeading color="#F57F20">{openIcon()} Open</TitleHeading>,
    <TitleHeading color="#7E00FD">{awaitingIcon()} In Progress</TitleHeading>,
    <TitleHeading color="#00ADFB">{underApprovalIcon()} Deliver</TitleHeading>,
    <TitleHeading color="#1CC98A">{completedIcon()} Completed</TitleHeading>,
  ];

  // console.log("workTasks", workTasks);

  return (
    <Container>
      <ContainerProjectSummary>
        <div>
          <ProjectSummaryContent>
            {workTasks.map((wtCol, id) => (
              <ProjectSummaryItem key={id}>
                {titleHeading[id]}
                <ContentHeading>{wtCol?.packagesCount}</ContentHeading>
              </ProjectSummaryItem>
            ))}
            {/* <ProjectSummaryItem>
              <TitleHeading color="#F57F20">{openIcon()} Open</TitleHeading>
              <ContentHeading>
                {(Object.values(workTasks)[0] as IWorkTaskData)
                  ?.packagesCount || 0}
              </ContentHeading>
            </ProjectSummaryItem>
            <ProjectSummaryItem>
              <TitleHeading color="#7E00FD">
                {awaitingIcon()} In Progress
              </TitleHeading>
              <ContentHeading>
                {(Object.values(workTasks)[1] as IWorkTaskData)
                  ?.packagesCount || 0}
              </ContentHeading>
            </ProjectSummaryItem>
            <ProjectSummaryItem>
              <TitleHeading color="#00ADFB">
                {underApprovalIcon()} Deliver
              </TitleHeading>
              <ContentHeading>
                {(Object.values(workTasks)[2] as IWorkTaskData)
                  ?.packagesCount || 0}
              </ContentHeading>
            </ProjectSummaryItem>
            <ProjectSummaryItem>
              <TitleHeading color="#1CC98A">
                {completedIcon()} Completed
              </TitleHeading>
              <ContentHeading>
                {(Object.values(workTasks)[3] as IWorkTaskData)
                  ?.packagesCount || 0}
              </ContentHeading>
            </ProjectSummaryItem> */}
          </ProjectSummaryContent>
        </div>
      </ContainerProjectSummary>
      <ContainerBodyInitiator expandIconPosition="end">
        {Object.values(workTasks).map(
          (item: IWorkTaskData | undefined, key) => (
            <PanelCollapse
              header={`${headerCards?.[key]?.text} (${
                item?.packagesCount || 0
              })`}
              key={key}
            >
              {(item?.packagesCount || 0) > 0 ? (
                item?.records?.map((project: any) =>
                  project.packages?.map((workTask: any, key: number) => (
                    <Worktask
                      data={workTask}
                      category={project?.category?.name || ""}
                      totalBudget={project?.totalBudget || 0}
                      key={key}
                      projectId={project._id}
                      hideButton={true}
                      isDashboard={true}
                      worktaskOtherInfo={project}
                      projectExpectedUsd={project?.expectedUSD}
                    />
                  ))
                )
              ) : (
                <ResultsNotFound
                  content={{
                    text: `You have no ${
                      // headerCards.find((header) => header.key === key)?.text
                      headerCards?.[key]?.text
                    } packages`,
                  }}
                  img={imgNotFound}
                  title="dashboard"
                />
              )}
              {(item?.packagesCount || 0) > 0 &&
              (item?.packagesCount || 0) > (headerCards?.[key]?.limit || 0) ? (
                <>
                  <SeeMore onClick={() => handleShowMore(key)}>
                    See more <DownIcon />
                  </SeeMore>
                </>
              ) : (
                <></>
              )}
            </PanelCollapse>
          )
        )}
      </ContainerBodyInitiator>
    </Container>
  );
};

export default memo(Initiator);
