import OutlinedButton from "components/base/outlinedButton";
import { StyledSelect } from "pages/CreateProject/createProject.style";
import styled from "styled-components";

export const ObserverSelect = styled(StyledSelect)`
  .ant-select-selection-placeholder {
    color: rgba(8, 10, 37, 0.4);
  }
`;

export const SelectedObserverWrapper = styled.div`
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;

export const AddObserverBtn = styled(OutlinedButton)`
  height: auto;
  width: 100%;
  padding-block: 10px;
  color: #7e00fd;
  font-family: "Rubik";
  font-style: normal;
  gap: 4px;

  svg {
    position: relative;
    top: -1px;
  }

  &.ant-btn:active,
  &.ant-btn:focus,
  &.ant-btn:hover {
    color: #7e00fd;
  }
`;

export const AddObserverWrapper = styled.div`
  .ant-form-inline .ant-form-item:not(:last-child) {
    flex-basis: 42%;
    max-width: 42%;
  }

  .ant-form-inline .ant-form-item:last-child {
    flex-grow: 1;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
