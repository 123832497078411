import { lazy, Suspense } from "react";
import Dashboard from "pages/Dashboard";
import BaseLayout from "components/layout/baseLayout";
import LayoutWithoutFooter from "components/layout/layoutWithoutFooter";
import { ROUTER_CONSTANTS } from "utils/constant";
import Conversation from "pages/Conversation";
import TransactionsAndRewards from "pages/TransactionsAndRewards";
import SupportTicket from "pages/SupportTicket";
import HomePage from "./pages/HomePage";
import OrganizationPage from "./pages/OrganizationPage";
import Worktasks from "pages/Worktasks";
import WorktasksInside from "pages/WorktasksInside";
import ExpressInterest from "pages/ExpressInterest";
import CreateProjectPage from "pages/CreateProject";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ProfileUserInnerPage from "pages/ProfileUser";
import SignUp from "pages/SignUp";
import SignIn from "pages/SignIn";
import PaymentDashboard from "pages/PaymentDashboard";
import PaymentDashboardAnother from "pages/PaymentDashboardAnother";
import ProfilePreview from "pages/ProfilePreview";
import ResetPassword from "pages/ResetPassword";
import Page404 from "pages/Page404";
import CreateOrganization from "pages/CreateOrganization";
import ProjectDetail from "pages/ProjectDetail";
import CollaboratorRequest from "pages/ProjectDetail/components/CollaboratorRequest";
import Feedback from "pages/Feedback";
import ReviewDeliverables from "pages/ReviewDeliverables";
import UpdateProjectPage from "pages/UpdateProject";
import PaymentDashboardObserver from "pages/PaymentDashboardObserver";
import { useAppSelector } from "redux/hooks";
import CreateCoursePage from "pages/CreateCourse";
import DeliverWork from "pages/DeliverWork";
import ReviewWorktask from "pages/ReviewWorktask";
import { PageLoading } from "components/base/PageLoading/PageLoading";
import Bounty from "pages/Bounty";
import AddProjectEmpty from "pages/AddPortfolio/addProjectEmpty";
import AddProjectPage from "pages/AddPortfolio";
import ListAssignmentForCreatorPage from "pages/ListAssignmentForCreator";
import ListCourseForLearnerPage from "pages/ListCourseForLearner";
import FeedbackInfo from "pages/FeedbackInfo";
import IDESubmitPage from "pages/IDESubmit";
import PortfolioPreview from "pages/PorfolioPreview";
import CourseDetailNew from "pages/courseDetailNew";
import DOMPurify from "dompurify";
import { utils } from "ethers";
import Blog from "pages/Blog";
import BlogDetail from "pages/BlogDetail";

const TermsOfService = lazy(() => import("pages/TermsOfService"));
const AboutBuidlfi = lazy(() => import("pages/AboutBuidlfi"));

DOMPurify.setConfig({
  ADD_TAGS: ["iframe"],
});

utils.Logger.setLogLevel(utils.Logger.levels.OFF);

export const AppRoutes = () => {
  const { token } = useAppSelector((state) => state.auth);
  const PrivateWrapper = () => {
    return token ? <Outlet /> : <Navigate to={ROUTER_CONSTANTS.USER.LOGIN} />;
  };
  const NotYetLoginWrapper = () => {
    return !token ? <Outlet /> : <Navigate to={ROUTER_CONSTANTS.HOME} />;
  };

  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        <Route element={<BaseLayout />}>
          <Route path={ROUTER_CONSTANTS.HOME} element={<HomePage />} />
          <Route
            path={ROUTER_CONSTANTS.ORGANIZATION.VIEW}
            element={<OrganizationPage />}
          />
          <Route element={<NotYetLoginWrapper />}>
            <Route path={ROUTER_CONSTANTS.USER.SIGNUP} element={<SignUp />} />
            <Route path={ROUTER_CONSTANTS.USER.LOGIN} element={<SignIn />} />
          </Route>
          <Route
            path={ROUTER_CONSTANTS.PROJECT.DETAIL}
            element={<ProjectDetail />}
          />
          <Route
            path={ROUTER_CONSTANTS.COURSE.COURSE_DETAIL}
            element={<CourseDetailNew />}
          />
          <Route
            path={ROUTER_CONSTANTS.USER.RESET_PASSWORD}
            element={<ResetPassword />}
          />
          <Route path={ROUTER_CONSTANTS.BLOG.LIST_BLOG} element={<Blog />} />
          <Route
            path={ROUTER_CONSTANTS.BLOG.BLOG_DETAIL}
            element={<BlogDetail />}
          />
          <Route
            path={ROUTER_CONSTANTS.ABOUT_BUIDLFI}
            element={<AboutBuidlfi />}
          />
          <Route
            path={ROUTER_CONSTANTS.TERMS_OF_SERVICE}
            element={<TermsOfService />}
          />
        </Route>
        <Route path="/*" element={<Page404 />} />
        <Route element={<PrivateWrapper />}>
          <Route element={<BaseLayout />}>
            <Route
              path={ROUTER_CONSTANTS.USER.PROFILE_PREVIEW}
              element={<ProfilePreview />}
            />
            <Route
              path={ROUTER_CONSTANTS.ORGANIZATION.WORKTASKS}
              element={<Worktasks />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.WORKTASKS_INSIDE}
              element={<WorktasksInside />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.EXPRESS_INTEREST}
              element={<ExpressInterest />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.CREATE_PROJECT}
              element={<CreateProjectPage />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.UPDATE_PROJECT}
              element={<UpdateProjectPage />}
            />
            <Route path={ROUTER_CONSTANTS.DASHBOARD} element={<Dashboard />} />
            <Route
              path={ROUTER_CONSTANTS.TRANSACTIONS_REWARDS}
              element={<TransactionsAndRewards />}
            />
            <Route
              path={ROUTER_CONSTANTS.CONVERSATION}
              element={<Conversation />}
            />
            <Route
              path={ROUTER_CONSTANTS.USER.SUPPORT_TICKET}
              element={<SupportTicket />}
            />
            <Route
              path={ROUTER_CONSTANTS.USER.PAYMENT_DASHBOARD}
              element={<PaymentDashboard />}
            />
            <Route
              path={ROUTER_CONSTANTS.USER.PAYMENT_DASHBOARD_INITIATOR}
              element={<PaymentDashboardAnother />}
            />
            <Route
              path={ROUTER_CONSTANTS.USER.PAYMENT_DASHBOARD_OBSERVER}
              element={<PaymentDashboardObserver />}
            />
            <Route
              path={ROUTER_CONSTANTS.USER.PROFILE_USERINNER}
              element={<ProfileUserInnerPage />}
            />
            {/* <Route
            path={ROUTER_CONSTANTS.PROJECT.BOARD}
            element={<ProjectBoard />}
          /> */}
            <Route
              path={ROUTER_CONSTANTS.ORGANIZATION.CREATE}
              element={<CreateOrganization />}
            />
            <Route
              path={ROUTER_CONSTANTS.ORGANIZATION.EDIT}
              element={<CreateOrganization />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.COLLABORATORS_REQUEST}
              element={<CollaboratorRequest />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.FEEDBACK}
              element={<Feedback />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.FEEDBACK_INFO}
              element={<FeedbackInfo />}
            />
            <Route
              path={ROUTER_CONSTANTS.REVIEW_DELIVERABLE}
              element={<ReviewDeliverables />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.ADD_WORKTASK}
              element={<Bounty />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.UPDATE_WORK_TASK}
              element={<Bounty editMode />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.DELIVER_WORK}
              element={<DeliverWork />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.REVIEW_WORKTASK}
              element={<ReviewWorktask />}
            />
            <Route
              path={ROUTER_CONSTANTS.PROJECT.BOUNTY}
              element={<Bounty />}
            />
            <Route
              path={ROUTER_CONSTANTS.COURSE.CREATE_COURSE}
              element={<CreateCoursePage />}
            />
            <Route
              path={ROUTER_CONSTANTS.USER.PORTFOLIO}
              element={<AddProjectEmpty />}
            />
            <Route
              path={`${ROUTER_CONSTANTS.USER.PORTFOLIO}/:portfolioId`}
              element={<PortfolioPreview />}
            />
            <Route
              path={ROUTER_CONSTANTS.USER.ADD_PORTFOLIO}
              element={<AddProjectPage />}
            />
            <Route
              path={`${ROUTER_CONSTANTS.USER.EDIT_PORTFOLIO}/:portfolioId`}
              element={<AddProjectPage />}
            />
            <Route
              path={ROUTER_CONSTANTS.COURSE.LIST_ASSIGNMENT_FOR_CREATOR}
              element={<ListAssignmentForCreatorPage />}
            />
            <Route
              path={ROUTER_CONSTANTS.COURSE.LIST_COURSE_FOR_LEARNER}
              element={<ListCourseForLearnerPage />}
            />
            <Route
              path={ROUTER_CONSTANTS.COURSE.IDE_SUBMIT}
              element={<IDESubmitPage />}
            />
            <Route path={ROUTER_CONSTANTS.BLOG.LIST_BLOG} element={<Blog />} />
            <Route
              path={ROUTER_CONSTANTS.BLOG.BLOG_DETAIL}
              element={<Blog />}
            />
          </Route>
          <Route element={<LayoutWithoutFooter />}>
            {/* <Route
              path={ROUTER_CONSTANTS.COURSE.COURSE_DETAIL_NEW}
              element={<CourseDetailNew />}
            /> */}
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
