import { useMutation } from "@tanstack/react-query";
import { Form, message } from "antd";
import { createProjectAPI, ICreateProjectFormValues } from "api/users";
import { FC, ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ICreateProjectProps } from "./createProject.props";
import {
  Heading,
  Container,
  CreateProjectTitle,
  ProjectWrapper,
  ProjectStep,
  CenterColumn,
  ConfirmBtn,
  PaymentSuccessModal,
  SuccessHash,
  SuccessText,
} from "./createProject.style";
import {
  approveERC20,
  createProjectSC,
  getERC20Allowance,
  getERC20Decimal,
  handleMessageErrorSC,
} from "web3/contract";
import { setConnectWalletOpen, setPageLoading } from "redux/slices";
import { useEthers, useTokenBalance } from "@usedapp/core";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { BuildProjectBannerIcon } from "assets/images/buildProjectBanner.icon";
import {
  ProjectDetailsIcon,
  PaymentInfoIcon,
  CompleteIcon,
} from "assets/images/createProject";
import ProjectStepOne from "./ProjectStepOne";
import ProjectStepTwo from "./ProjectStepTwo";
import ProjectStepThree from "./ProjectStepThree";
import { UnplugIcon } from "assets/icons/unplug.icon";
import type { RcFile } from "antd/lib/upload";
import { getUrlFromS3 } from "utils/getUrlFromS3";
import { useCheckWalletAndSwitchNetwork } from "hooks";

const CreateProjectPage: FC<ICreateProjectProps> = ({
  ...props
}: ICreateProjectProps): ReactElement => {
  const [form] = Form.useForm();
  const { account } = useEthers();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.auth.user);
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [createProjectParams, setDataToCreateProject] =
    useState<ICreateProjectFormValues>();
  const [createdTxHash, setCreatedTxHash] = useState("");
  const [createdProjectId, setCreatedProjectId] = useState("");
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const tokenBalance = useTokenBalance(
    createProjectParams?.tokenContract,
    account
  );

  const handleChangeStep = (value: number) => {
    setCurrentStep(value);
  };

  const handleGatherData = async (values: ICreateProjectFormValues) => {
    try {
      const fileNameCover: RcFile | null | undefined =
        form.getFieldValue("coverImage");

      if (!fileNameCover) {
        message.error("Please upload your project image");
        return;
      }

      if (!userInfo?.organization) {
        message.error("Please become an organization before proceeding");
        return;
      }

      const finalVal = {
        ...values,
        coverImage: fileNameCover,
        category: form.getFieldValue("category"),
      };
      setDataToCreateProject(finalVal);

      if (!Boolean(account)) {
        dispatch(setConnectWalletOpen(true));
      } else {
        handleChangeStep(1);
      }
    } catch (error) {
      console.error("-------", error);
    }
  };

  const handleSubmit = async () => {
    if (!checkWallet()) return;

    try {
      const blockchainNetwork = createProjectParams?.selectedChainId as number;
      await handleSwitchNetwork(blockchainNetwork);
      console.log("createProjectParams?.isExistingToken", createProjectParams);
      if (createProjectParams?.isExistingToken === 1) {
        const decimal = await getERC20Decimal(
          createProjectParams?.tokenContract
        );

        if (
          tokenBalance &&
          +formatUnits(tokenBalance, decimal) <
            +createProjectParams?.totalBudget
        ) {
          message.error("Total budget is greater than wallet balance");
          return;
        }

        dispatch(setPageLoading(true));
        setLoading(true);

        const allowance = (
          await getERC20Allowance({
            walletAddress: account,
            erc20Address: createProjectParams.tokenContract,
          })
        )?.toString();

        const allowanceEnough =
          +allowance <
          +parseUnits("" + createProjectParams.totalBudget, decimal).toString();

        if (allowance === "0" || allowanceEnough) {
          await approveERC20({
            erc20Address: createProjectParams.tokenContract,
          });
        }

        const scParams = {
          token: createProjectParams.tokenContract || "",
          budget: parseUnits(
            "" + createProjectParams.totalBudget,
            decimal
          ).toString(),
        };
        const { tx, event } = await createProjectSC(scParams);

        setCreatedTxHash(tx.hash);

        const scProjectId = event[0][0];
        const urlFromS3 = await getUrlFromS3(createProjectParams?.coverImage);

        createProject({
          ...createProjectParams,
          coverImage: urlFromS3,
          organizations: createProjectParams.organizations,
          statusApprovedByInitiator: "COMPLETED",
          remainingBudget: createProjectParams.totalBudget,
          scProjectId,
          decimal,
          txHash: tx.hash,
        });
        setLoading(false);
        dispatch(setPageLoading(false));
      } else {
        message.error("Something's wrong!");
      }
    } catch (err: any) {
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
      setLoading(false);
    }
  };

  const { mutate: createProject } = useMutation(createProjectAPI, {
    onSuccess: (res: any) => {
      const { responseCode, responseMessage, responseData } = res;

      if (responseCode === 200) {
        setCreatedProjectId(responseData?._id);
        setOpenPaymentModal(true);
      } else {
        message.error(responseMessage);
      }
    },
  });

  // scroll to top when step changed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentStep]);

  const stepContent = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return (
          <ProjectStepOne
            form={form}
            handleSubmit={handleGatherData}
            loading={loading}
          />
        );

      case 1:
        return (
          <ProjectStepTwo
            projectData={createProjectParams}
            handleChangeStep={handleChangeStep}
            handleSubmit={handleSubmit}
          />
        );

      case 2:
        return <ProjectStepThree createdProjectId={createdProjectId} />;

      default:
        return null;
    }
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
    setCurrentStep(2);
  };

  return (
    <ProjectWrapper>
      <Heading>
        <CreateProjectTitle>Launch a BUILD Project</CreateProjectTitle>
        <BuildProjectBannerIcon />
      </Heading>
      <Container>
        <ProjectStep current={currentStep} labelPlacement="vertical">
          <ProjectStep.Step
            title="Project Details"
            icon={<ProjectDetailsIcon />}
          />
          <ProjectStep.Step
            title="Payment Information"
            icon={<PaymentInfoIcon />}
          />
          <ProjectStep.Step title="Completed" icon={<CompleteIcon />} />
        </ProjectStep>

        {stepContent(currentStep)}

        <PaymentSuccessModal
          visible={openPaymentModal}
          destroyOnClose
          footer={null}
          onCancel={handleClosePaymentModal}
        >
          <CenterColumn space="12px">
            <UnplugIcon />
            <SuccessText>Payment successful</SuccessText>
          </CenterColumn>
          <SuccessHash>
            Your payment has been successfully received.
            <br />
            Transaction Hash:{" "}
            <a
              href={`${createProjectParams?.tokenExplorer}${createdTxHash}`}
              target="_blank"
              rel="noreferrer"
            >{`${createProjectParams?.tokenExplorer}${createdTxHash}`}</a>
          </SuccessHash>
          <ConfirmBtn onClick={handleClosePaymentModal}>OK</ConfirmBtn>
        </PaymentSuccessModal>
      </Container>
    </ProjectWrapper>
  );
};

export default CreateProjectPage;
