import styled from "styled-components";

export const MenuListItem = styled.li<{ highlight?: boolean }>`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #080f6c;
  padding-block: 15px;
  cursor: pointer;
  ${({ highlight }) =>
    highlight === true &&
    `
      background: linear-gradient(270deg, rgba(126, 0, 253, 0.1) 0%, rgba(126, 0, 253, 0) 100%);
      border-right: 1.5px solid #7E00FD;
    `};
`;

export const MenuListWithHighlight = styled.ul`
  position: sticky;
  top: 72px;
  max-width: 330px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
`;

export const MenuListWrapper = styled.div`
  flex-basis: 455px;
  padding-top: 72px;
  border: 1px solid rgba(41, 46, 115, 0.096);
`;
