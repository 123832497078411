import { FC, useState } from "react";
import { IObserversProps } from "./Observers.props";
import {
  BlockWrap,
  ItemWrap,
  ObserversWrap,
  EndContractButton,
  SendMessageButton,
  EmptyText,
} from "../../../ProjectDetail/ProjectDetail.style";

import {
  StyledOption,
  StyledSelect,
  StyledSelectUser,
} from "pages/CreateProject/createProject.style";
// import CommonModal from "components/base/commonModal";
import {
  ModalButton,
  // ModalTitle,
  StyledFormItem,
  AddProjectTitle,
  AddProjectSection,
  AddObserverFormWrapper,
  AddObserverBtn,
  SelectedObserverWrapper,
  ObserverInfoWrapper,
  ObserverName,
  ObserverRole,
  DeleteObserverWrapper,
  ActionBtnsWrapper,
  ObserverItem,
  ActionObserverBtnWrapper,
} from "./Observers.style";
import { Avatar, Form, message, Select } from "antd";
// import { KindSelect } from "pages/ProfileUser/components/BuilderSkill/builderSkill.style";
import FlexBox from "components/base/Flexbox";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getDataMastersAPI,
  updateObserverAPI,
  getObserverListAPI,
  removeObserverAPI,
  addObserversAPI,
  IUserInfo,
  IMaster,
  getListUserWillBecomeObserverAPI,
} from "api/users";
import { useNavigate, useParams } from "react-router-dom";
import {
  addObserversSC,
  handleMessageErrorSC,
  removeObserversSC,
} from "web3/contract";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setPageLoading } from "redux/slices";
import { SendIcon } from "assets/icons/common/copy.icon";
import { AddPersonIcon } from "assets/icons/addPerson.icon";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import { CrossMarkIcon } from "assets/icons/crossMark.icon";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import { ExtractedUserInfo } from "pages/Bounty/components/Observers";
import { BlockchainNetworks } from "utils/constant";
import { useCheckWalletAndSwitchNetwork } from "hooks";

const Observers: FC<IObserversProps> = ({ worktaskDetail, project }) => {
  const [open, setOpen] = useState(false);
  const [selectedObservers, setSelectedObservers] = useState<
    (
      | Partial<
          ExtractedUserInfo & {
            role?: IMaster;
          }
        >
      | undefined
    )[]
  >([]);

  const userId: string = useAppSelector((state) => state.auth.user?._id);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  const param = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { data: userRes } = useQuery(
    [`getListUserWillBecomeObserverAPI-${param?.projectId}`],
    () =>
      getListUserWillBecomeObserverAPI(
        param?.projectId as string,
        param?.packageId as string
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const usersList: ExtractedUserInfo[] =
    (userRes?.responseData as ExtractedUserInfo[]) || [];

  const { data: observerListRes } = useQuery(
    ["getObserverList"],
    () =>
      getObserverListAPI({
        projectId: param.projectId || "",
        packageId: param.packageId || "",
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const observerList: (Pick<
    IUserInfo,
    "_id" | "fullName" | "emailId" | "profilePicture" | "username"
  > & {
    packages: string[];
    role?: IMaster;
  })[] = observerListRes?.responseData?.records || [];

  const { data: masterObserverRes } = useQuery(
    ["getMasterObserverRoles"],
    () => getDataMastersAPI({ type: "Observer Roles" }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const observerRoles: IMaster[] =
    masterObserverRes?.responseData?.values || [];

  const { mutate: updateObserver } = useMutation(updateObserverAPI);

  const { mutate: addObservers } = useMutation(addObserversAPI);

  const { mutate: removeObserver } = useMutation(removeObserverAPI);

  const isProjectCreator = worktaskDetail?.initiator === userId;

  const handleSubmit = async () => {
    if (!checkWallet()) return;
    const observerWallet = selectedObservers?.map(
      (filtered) => filtered?.walletAddress || ""
    );
    const observerId = selectedObservers?.map((ob) => ({
      id: ob?._id || "",
      role: ob?.role?._id || "",
    }));
    try {
      dispatch(setPageLoading(true));

      const projectChainId =
        BlockchainNetworks[project?.blockchainNetwork?.name!];
      await handleSwitchNetwork(projectChainId);

      await addObserversSC({
        projectId: worktaskDetail?.scProjectId || "",
        packageId: worktaskDetail?.scPackageId || "",
        observers: observerWallet,
      });
      addObservers(
        {
          projectId: param?.projectId,
          users: observerId,
          packageId: param?.packageId,
        },
        {
          onSuccess: async (res) => {
            const { responseCode, responseMessage } = res;
            if (responseCode === 200) {
              await queryClient.invalidateQueries(["getMasterObserverRoles"]);
              await queryClient.invalidateQueries([
                `getAllPackagesByProjectId-${param.projectId}`,
              ]);
              await queryClient.invalidateQueries(["getObserverList"]);
              dispatch(setPageLoading(false));
              setSelectedObservers([]);
              setOpen(false);
              message.success(responseMessage);
            } else {
              dispatch(setPageLoading(false));
              message.error(responseMessage);
              setOpen(false);
            }
          },
          onError: () => {
            dispatch(setPageLoading(false));
          },
        }
      );
    } catch (err: any) {
      console.error(err);
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const handleUpdateRole = async (value: any, id: any) => {
    try {
      dispatch(setPageLoading(true));
      const updateData = {
        projectId: param.projectId || "",
        packageId: param.packageId || "",
        observers: [
          {
            id,
            role: value,
          },
        ],
      };
      updateObserver(updateData, {
        onSuccess: async (res) => {
          const { responseCode, responseMessage } = res;
          if (responseCode === 200) {
            await queryClient.invalidateQueries(["getMasterObserverRoles"]);
            await queryClient.invalidateQueries([
              `getAllPackagesByProjectId-${param.projectId}`,
            ]);
            await queryClient.invalidateQueries(["getObserverList"]);
            dispatch(setPageLoading(false));
            message.success(responseMessage);
          } else {
            dispatch(setPageLoading(false));
            message.error(responseMessage);
          }
        },
        onError: () => {
          dispatch(setPageLoading(false));
        },
      });
    } catch (error) {
      dispatch(setPageLoading(false));
    }
  };

  const handleRemoveObserver = async (observer: any) => {
    if (!checkWallet()) return;
    try {
      dispatch(setPageLoading(true));

      const projectChainId =
        BlockchainNetworks[project?.blockchainNetwork?.name!];
      await handleSwitchNetwork(projectChainId);
      await removeObserversSC({
        projectId: worktaskDetail?.scProjectId || "",
        packageId: worktaskDetail?.scPackageId || "",
        observers: [observer.walletAddress],
      });
      removeObserver(
        {
          projectId: param?.projectId,
          packageId: param?.packageId,
          userIds: [observer._id],
        },
        {
          onSuccess: async (res) => {
            const { responseCode, responseMessage } = res;
            if (responseCode === 200) {
              await queryClient.invalidateQueries(["getMasterObserverRoles"]);
              await queryClient.invalidateQueries([
                `getAllPackagesByProjectId-${param.projectId}`,
              ]);
              await queryClient.invalidateQueries(["getObserverList"]);
              dispatch(setPageLoading(false));
              setOpen(false);
              message.success(responseMessage);
            } else {
              dispatch(setPageLoading(false));
              setOpen(false);
              message.error(responseMessage);
            }
          },
          onError: () => {
            dispatch(setPageLoading(false));
          },
        }
      );
    } catch (err: any) {
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const handleAddObserver = (values: { userId?: string; role?: string }) => {
    if (!values?.userId || !values?.role) return;

    const isAlreadyAdded = selectedObservers?.find(
      (item) => item?._id === values?.userId
    );

    if (isAlreadyAdded) {
      message.error("Collaborator already added");
      return;
    }

    const foundUser = usersList?.find((item) => item?._id === values?.userId);
    const foundRole = observerRoles?.find(
      (item: any) => item?._id === values?.role
    );

    // console.log("found", { ...foundUser, name: foundRole?.name });

    setSelectedObservers((prev) => [
      ...prev,
      { ...foundUser, role: foundRole },
    ]);
    form.resetFields();
  };

  const handleDeleteObserver = (selectedId?: string) => {
    setSelectedObservers((prev) =>
      prev?.filter((item) => item?._id !== selectedId)
    );
  };
  return (
    <ObserversWrap>
      <AddProjectTitle>Add Project Observers</AddProjectTitle>
      <AddProjectSection open={open}>
        <AddObserverFormWrapper>
          <Form form={form} onFinish={handleAddObserver} layout="inline">
            <StyledFormItem label="Username" name="userId">
              <StyledSelectUser
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
              >
                {usersList.length > 0 &&
                  usersList
                    .filter((user) =>
                      observerList.length > 0
                        ? observerList.some(
                            (observer) => observer._id !== user._id
                          )
                        : true
                    )
                    ?.map((user) => (
                      <Select.Option key={user?._id} value={user?._id}>
                        {user?.username || user?.emailId.split("@").shift()}
                      </Select.Option>
                    ))}
              </StyledSelectUser>
            </StyledFormItem>
            <StyledFormItem label="Role" name="role">
              <StyledSelect
                placeholder="Select Role"
                // onChange={(value) => form.setFieldValue("role", value)}
              >
                {observerRoles &&
                  observerRoles?.map((item) => (
                    <StyledOption key={item?._id} value={item?._id}>
                      {item?.name}
                    </StyledOption>
                  ))}
              </StyledSelect>
            </StyledFormItem>
            <StyledFormItem>
              <AddObserverBtn htmlType="submit">
                <AddPersonIcon />
                Add Observer
              </AddObserverBtn>
            </StyledFormItem>
          </Form>
          {selectedObservers.length > 0 && (
            <SelectedObserverWrapper>
              {selectedObservers.map((item) => {
                return (
                  <ObserverInfoWrapper key={item?._id}>
                    <Avatar
                      shape="square"
                      src={item?.profilePicture}
                      size={52}
                    />
                    <CommonFlexColumn space="14px">
                      <CommonFlexRow space="14px">
                        <ObserverName>
                          {item?.username || item?.emailId?.split("@").shift()}
                        </ObserverName>
                        <DeleteObserverWrapper
                          onClick={() => handleDeleteObserver(item?._id)}
                        >
                          <CrossMarkIcon />
                        </DeleteObserverWrapper>
                      </CommonFlexRow>
                      <ObserverRole>{item?.role?.name}</ObserverRole>
                    </CommonFlexColumn>
                  </ObserverInfoWrapper>
                );
              })}
            </SelectedObserverWrapper>
          )}
        </AddObserverFormWrapper>
        <ActionBtnsWrapper>
          <OutlinedButton onClick={() => setOpen(false)}>Cancel</OutlinedButton>
          <PrimaryButton
            disabled={selectedObservers.length === 0}
            onClick={handleSubmit}
          >
            Submit
          </PrimaryButton>
        </ActionBtnsWrapper>
      </AddProjectSection>
      <BlockWrap>
        <ItemWrap>
          {isProjectCreator && (
            <ModalButton $open={!open} onClick={() => setOpen(true)}>
              Add an observer
            </ModalButton>
          )}
          {observerList?.length ? (
            observerList.map((observer, id: number) => (
              <ObserverItem key={`observer-${id}`}>
                <FlexBox align="center!important">
                  <Avatar
                    shape="circle"
                    size={36}
                    src={observer.profilePicture}
                  />
                  <span>
                    {observer.username || observer.emailId.split("@").shift()}
                  </span>
                </FlexBox>
                <div>
                  <div>
                    <StyledSelect
                      value={
                        observerRoles.find(
                          (role) => role._id === observer?.role?._id
                        )?._id
                      }
                      onChange={(value: any) =>
                        handleUpdateRole(value, observer._id)
                      }
                      disabled={!isProjectCreator}
                    >
                      {observerRoles &&
                        observerRoles?.map((item) => (
                          <StyledOption key={item?._id} value={item?._id}>
                            {item?.name}
                          </StyledOption>
                        ))}
                    </StyledSelect>
                  </div>
                  {/* <Popconfirm
                    title={
                      <div>
                        <div>Are you sure you want to stop get support?</div>
                        You'll be prompted to provide feedback and make any
                        final payments in the following steps.
                      </div>
                    }
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleRemoveObserver(observer)}
                  >
                    <EndContractButton>Remove Observer X</EndContractButton>
                  </Popconfirm> */}
                  {isProjectCreator && (
                    <ActionObserverBtnWrapper>
                      <EndContractButton
                        onClick={() => handleRemoveObserver(observer)}
                      >
                        Remove Observer X
                      </EndContractButton>

                      <SendMessageButton
                        onClick={() =>
                          navigate("/conversation?id=" + observer?._id)
                        }
                      >
                        Send Message&nbsp;&nbsp; <SendIcon />
                      </SendMessageButton>
                    </ActionObserverBtnWrapper>
                  )}
                </div>
              </ObserverItem>
            ))
          ) : (
            <EmptyText>No observer added</EmptyText>
          )}
        </ItemWrap>
      </BlockWrap>
    </ObserversWrap>
  );
};

export default Observers;
