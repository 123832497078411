import axios from "api/axios";
import {
  ILoginParams,
  ISignUpParams,
  ISendOTPParams,
  IForgotPasswordParams,
  IResetPasswordParams,
} from "./types";

export const END_POINT = {
  LOGIN: "/user/login",
  LOGOUT: "/user/logout",
  GET_USER: "/users/profile",
  SIGNUP: "/user/defaultRegister",
  RESEND_VERIFY_EMAIL: "/user/resendCode",
  SEND_OTP: "/user/verifySecurityCode",
  FORGOT_PASSWORD: "user/forgotPassword",
  RESET_PASSWORD: "user/setNewPassword",
  LOGIN_GITLAB: "user/auth/gitlab",
};

export const resendVerifyEmailAPI = async ({ id }: { id: string }) => {
  return await axios.post(`${END_POINT.RESEND_VERIFY_EMAIL}/${id}`);
};

export const signupAPI = async ({ referralCode, ...rest }: ISignUpParams) => {
  const SIGNUP_ENDPOINT = END_POINT.SIGNUP;
  return await axios({
    url: referralCode
      ? `${SIGNUP_ENDPOINT}?referralCode=${referralCode}`
      : SIGNUP_ENDPOINT,
    method: "POST",
    data: rest,
  });
};

export const loginAPI = async (params: ILoginParams) => {
  return await axios({
    url: END_POINT.LOGIN,
    method: "POST",
    data: params,
  });
};

export const verifyOTPAPI = async ({ id, ...params }: ISendOTPParams) => {
  return await axios({
    url: END_POINT.SEND_OTP + `/${id}`,
    method: "POST",
    data: params,
  });
};

export const logoutAPI = async () => {
  return await axios({
    url: END_POINT.LOGOUT,
    method: "GET",
  });
};

export const getUserAPI = async () => {
  return await axios({
    url: END_POINT.GET_USER,
    method: "GET",
  });
};

export const forgotPasswordAPI = async (params: IForgotPasswordParams) => {
  return await axios({
    url: END_POINT.FORGOT_PASSWORD,
    method: "POST",
    data: params,
  });
};

export const resetPasswordAPI = async ({
  resetToken,
  ...params
}: IResetPasswordParams) => {
  return await axios({
    url: END_POINT.RESET_PASSWORD + `/${resetToken}`,
    method: "POST",
    data: params,
  });
};
