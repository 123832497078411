/* eslint-disable react/style-prop-object */
import { Checkbox, Col, Form, Input, Radio, Row, message } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { LESSON_ASSIGNMENT_CATEGORY } from "api/course";
import TextEditor from "components/base/textEditor";
import { ExpireSwitch } from "pages/CreateCourse/BasicInformation/basicInformation.style";
import { FC, useEffect } from "react";
import { requiredRules, stringRequired } from "utils/validatorRules";
import { IActivityTemplateProps } from "./activityTemplate.props";
import {
  CancelButton,
  CloseButton,
  Hr,
  InputStyled,
  Item,
  Label,
  SaveButton,
  WrapButton,
  WrapContent,
} from "./activityTemplate.style";
import { CloseIcon } from "assets/images/Bounty";
import OutlinedButton from "components/base/outlinedButton";

const ActivityTemplate: FC<IActivityTemplateProps> = ({
  activity,
  handleSubmitModal,
  handleCancel,
}) => {
  const [form] = Form.useForm();

  const content = useWatch("content", form);
  const assignments = useWatch("assignments", form);

  useEffect(() => {
    if (activity) form.setFieldsValue(activity);
  }, [activity, form]);

  const handleSwithMultiValid = () => {
    const curOptions = form.getFieldValue(["assignments", "options"]);
    form.setFieldValue(
      ["assignments", "options"],
      curOptions.map((option) => ({
        ...option,
        valid: false,
      }))
    );
  };

  const handleValidate = () => {
    form.validateFields().then(() => {
      if (assignments.category !== LESSON_ASSIGNMENT_CATEGORY.POLL)
        form.submit();
      else {
        const options = form.getFieldValue(["assignments", "options"]);
        if (!options || options.length <= 1) {
          return message.error("At least 2 options required");
        }
        const atLeastOneValidOption = options.some(
          (option: { valid: any }) => option?.valid ?? false
        );
        if (!atLeastOneValidOption) {
          return message.error("At least one option is true");
        }
        form.submit();
      }
    });
  };
  return (
    <>
      <WrapContent>
        <Form form={form} onFinish={handleSubmitModal}>
          <Item>
            <Label>{"Activity title"}</Label>
            <Form.Item name={"title"} rules={[stringRequired]}>
              <InputStyled />
            </Form.Item>
          </Item>
          <Item>
            <Label>{"Activity content"}</Label>
            <Form.Item
              name={"content"}
              rules={[requiredRules]}
              className="content"
            >
              <TextEditor
                placeholder={"Content"}
                witdh="508px"
                border={"4px"}
                errorMessage={"Content is required"}
                data={content}
                onChange={(_, editor) =>
                  form.setFieldValue("content", editor.getData())
                }
              ></TextEditor>
            </Form.Item>
          </Item>

          <Hr />
          <Label style={{ marginBottom: "14px" }}>
            <strong>{"Assignment: "}</strong>
          </Label>

          <Item>
            <Label>{"Question"}</Label>
            <Form.Item
              name={["assignments", "question"]}
              rules={[requiredRules]}
              className="question"
            >
              <TextEditor
                placeholder={"Content"}
                witdh="508px"
                border={"4px"}
                errorMessage={"Question is required"}
                data={assignments?.question}
                onChange={(_, editor) =>
                  form.setFieldValue(
                    ["assignments", "question"],
                    editor.getData()
                  )
                }
              ></TextEditor>
            </Form.Item>
          </Item>
          <Item
            className="submission"
            style={{ alignItems: "unset", gap: "50px" }}
          >
            <Label style={{ lineHeight: "unset" }}>{"Type"}</Label>
            <Form.Item
              name={["assignments", "category"]}
              rules={[requiredRules]}
            >
              <Radio.Group>
                <Radio value={LESSON_ASSIGNMENT_CATEGORY.FILE}>
                  File submission
                </Radio>
                <Radio value={LESSON_ASSIGNMENT_CATEGORY.TEXT}>
                  Text submission
                </Radio>
                <Radio value={LESSON_ASSIGNMENT_CATEGORY.POLL}>Poll</Radio>
              </Radio.Group>
            </Form.Item>
          </Item>

          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue, setFieldValue }) =>
              getFieldValue(["assignments", "category"]) ===
              LESSON_ASSIGNMENT_CATEGORY.POLL ? (
                <>
                  <Row>
                    <Col span={12}>
                      <Label style={{ marginTop: "16px" }}>
                        {"Poll Options"}
                      </Label>
                    </Col>
                    <Col span={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "60%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Label>{"Multi-Select"}</Label>
                        <Form.Item
                          name={["assignments", "multiValid"]}
                          initialValue={false}
                          valuePropName="checked"
                          style={{ marginTop: "10px" }}
                        >
                          <ExpireSwitch
                            defaultChecked={false}
                            onChange={handleSwithMultiValid}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Form.List name={["assignments", "options"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => {
                          const isCheckboxButton = getFieldValue([
                            "assignments",
                            "multiValid",
                          ]);
                          const curOptions = getFieldValue([
                            "assignments",
                            "options",
                          ]);
                          const id = name;

                          return (
                            <Row
                              key={key}
                              gutter={[16, 16]}
                              style={{ marginBottom: "16px" }}
                            >
                              <Col span={2}>
                                {!isCheckboxButton && (
                                  <Form.Item
                                    {...restField}
                                    name={[name, "valid"]}
                                  >
                                    <input
                                      type="radio"
                                      name={"radio-option"}
                                      id={`${name}`}
                                      checked={curOptions[id]?.valid ?? false}
                                      onChange={() => {
                                        curOptions.forEach(
                                          (value: any, index: number) => {
                                            if (id === index)
                                              curOptions[index] = {
                                                ...value,
                                                valid: true,
                                              };
                                            else
                                              curOptions[index] = {
                                                ...value,
                                                valid: false,
                                              };
                                          }
                                        );
                                        setFieldValue(
                                          ["assignments", "options"],
                                          curOptions
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                )}
                                {isCheckboxButton && (
                                  <Form.Item
                                    {...restField}
                                    name={[name, "valid"]}
                                    valuePropName="checked"
                                  >
                                    <Checkbox
                                      defaultChecked={
                                        curOptions[id]?.valid ?? false
                                      }
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col span={20}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "value"]}
                                  rules={[requiredRules]}
                                >
                                  <Input placeholder={`Option ${name + 1}`} />
                                </Form.Item>
                              </Col>
                              <Col span={2}>
                                <CloseButton
                                  icon={<CloseIcon />}
                                  onClick={() => remove(name)}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                        <OutlinedButton
                          style={{ width: "100%" }}
                          onClick={() => add()}
                        >
                          + Add new option
                        </OutlinedButton>
                      </>
                    )}
                  </Form.List>
                </>
              ) : null
            }
          </Form.Item>
        </Form>
      </WrapContent>
      <WrapButton>
        <CancelButton onClick={() => handleCancel?.()}>Cancel</CancelButton>
        <SaveButton onClick={handleValidate}>Save</SaveButton>
      </WrapButton>
    </>
  );
};

export default ActivityTemplate;
