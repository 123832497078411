import { Button } from "antd";
import styled from "styled-components";

export const RevolvesContainer = styled.div`
  width: 100%;
  background: #ffffff;
  background-size: cover;
  padding: 49px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  position: relative;

  ::after {
    position: absolute;
    height: 4px;
    width: 100%;
    top: 0px;
    background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 50.1%,
        #ffffff 100%
      ),
      radial-gradient(
        100% 445.32% at 0% 100%,
        #7e00fd 0%,
        #b93df3 42.35%,
        #a54bf4 57.95%,
        #00bffb 100%
      );
    content: "";
  }

  ::before {
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: 0px;
    background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 50.1%,
        #ffffff 100%
      ),
      radial-gradient(
        100% 445.32% at 0% 100%,
        #7e00fd 0%,
        #b93df3 42.35%,
        #a54bf4 57.95%,
        #00bffb 100%
      );
    content: "";
  }
`;

export const GroupTitle = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #080f6c;
`;
export const GroupDescription = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 156%;
  text-align: center;
  letter-spacing: -0.5px;
  color: #080f6c;
  max-width: 959px;
  margin: 0 auto;
`;

export const PrimaryButtonStyled = styled(Button)`
  width: 143px;
  height: 56px;
  background: #7e00fd;
  border-radius: 4px;
  color: white;
`;
