import { IBlog } from "api/blog";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import {
  BlogContent,
  BlogImage,
  BlogInfoSection,
  BlogItem,
  PrimaryButtonStyled,
} from "./blog.style";
import Tags from "./tags";
import { Typography } from "antd";
import Skeleton from "react-loading-skeleton";
import { decodeEntities } from "utils/regex";
const { Paragraph } = Typography;

const BlogCard = ({
  blog,
  loading,
  ...props
}: {
  blog: IBlog | undefined;
  loading: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <>
      {loading && (
        <BlogItem>
          <div
            style={{
              width: "100%",
              lineHeight: 1,
            }}
          >
            <Skeleton height="270px" />
          </div>
          <BlogContent style={{ width: "100%" }}>
            <BlogInfoSection>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            </BlogInfoSection>
            <div className="block-content--context">
              <div>
                <Paragraph
                  ellipsis={{ rows: 2 }}
                  style={{ margin: "14px auto" }}
                >
                  <h3>
                    <Skeleton />
                  </h3>
                </Paragraph>
                <Paragraph ellipsis={{ rows: 3 }} className="paragraph">
                  <Skeleton count={3} />
                </Paragraph>
              </div>
            </div>
          </BlogContent>
        </BlogItem>
      )}

      {!loading && !!blog && (
        <BlogItem
          onClick={() =>
            navigate(
              ROUTER_CONSTANTS.BLOG.BLOG_DETAIL.replace(":id", blog._id!)
            )
          }
        >
          <BlogImage src={blog.coverImage} />
          <BlogContent>
            <BlogInfoSection>
              <Tags blog={blog} />
              <div>{blog.estTime} min read</div>
            </BlogInfoSection>
            <div className="block-content--context">
              <div>
                <Paragraph
                  ellipsis={{ rows: 2 }}
                  style={{ margin: "14px auto" }}
                >
                  <h3>{blog.title}</h3>
                </Paragraph>
                <Paragraph
                  ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  className="paragraph"
                >
                  {decodeEntities(blog.description)}
                </Paragraph>
              </div>
              <PrimaryButtonStyled>Read more</PrimaryButtonStyled>
            </div>
          </BlogContent>
        </BlogItem>
      )}
    </>
  );
};

export default BlogCard;
