import { Checkbox, Drawer, Radio, Space } from "antd";
import styled from "styled-components";

export const DraweAtnd = styled(Drawer)`
  span {
    font-family: "Rubik";
  }
  .ant-drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ItemWrap = styled.div`
  padding: 18px 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 115%;
  letter-spacing: -0.03em;
  color: #080f6c;
  border-bottom: 1px solid rgba(41, 46, 115, 0.12);
  display: flex;
  flex-direction: column;
  span {
    color: rgba(41, 46, 115, 0.82);
  }

  & > div:first-child {
    margin-bottom: 4px;
  }
  & > label {
    margin-left: 0 !important;
  }
  .ant-checkbox-group {
    label {
      margin: 0 !important;
      padding-top: 4px;
    }
    display: flex;
    flex-direction: column;
  }
`;

export const ItemWrapStart = styled(ItemWrap)`
  margin-top: 18px;
  padding-top: 0;
`;
export const ItemWrapEnd = styled(ItemWrap)`
  padding: 24px 0px;
  border-bottom: unset;
`;

export const RadioAntd = styled(Radio)``;
export const SpaceAntd = styled(Space)``;
export const CheckBoxAntd = styled(Checkbox)``;
