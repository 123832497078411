import { FC, memo, useEffect, useRef } from "react";
import { IFooterProps } from "./footer.props";
import {
  CopyRightText,
  FooterBottom,
  FooterContainer,
  FooterContent,
  LogoContainer,
  PageList,
  PageListContainer,
  PageListItem,
  SearchEmailContainer,
  TopLabel,
  ProductPresent,
  TextGradient,
  NewsletterFormContainer,
} from "./footer.style";
import { Logo } from "assets/images/logo";
import { scrollToSection } from "utils/scrollToSection";
import { EMAIL_OCTOPUS_ID, ROUTER_CONSTANTS } from "utils/constant";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "components/base/primaryButton";

const FooterUser: FC<IFooterProps> = () => {
  const testRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!testRef.current) return;

    // check for current form injected by the script
    const currentRenderedForm =
      testRef.current.getElementsByClassName("inline-container");

    if (currentRenderedForm.length > 0) return;

    // check for script already injected
    const currentScript = document.getElementById("newsletter-email");

    if (!currentScript) {
      const testScript = document.createElement("script");
      testScript.id = "newsletter-email";
      testScript.src = `https://eocampaign1.com/form/${EMAIL_OCTOPUS_ID}.js`;
      testScript.setAttribute("data-form", EMAIL_OCTOPUS_ID);
      testScript.async = true;

      testRef.current.appendChild(testScript);
    }
  }, []);

  return (
    <FooterContainer>
      <FooterContent>
        <LogoContainer span={6}>
          <Logo style={{ position: "relative", left: "-9px" }} />
          <ProductPresent style={{ marginTop: "18px" }}>
            Buidl<TextGradient>Fi</TextGradient> is a{" "}
            <a
              href={"https://www.rebaked.com/"}
              target="_blank"
              rel="noreferrer"
            >
              RBKD
            </a>
            <br />
            DAO product
          </ProductPresent>
          <PrimaryButton
            height="56px"
            width="157px"
            style={{ marginTop: "36px" }}
          >
            <a href="mailto:team@buidlfi.io">Contact us</a>
          </PrimaryButton>
        </LogoContainer>
        <PageListContainer span={5}>
          <PageList>
            <PageListItem>
              <TopLabel>About us</TopLabel>
            </PageListItem>
            <PageListItem
              onClick={() => navigate(ROUTER_CONSTANTS.ABOUT_BUIDLFI)}
            >
              About BuidlFi
            </PageListItem>
            <PageListItem>Partnerships</PageListItem>
            <PageListItem>Help Center</PageListItem>
            <PageListItem>Privacy policy</PageListItem>
            <PageListItem
              onClick={() => navigate(ROUTER_CONSTANTS.TERMS_OF_SERVICE)}
            >
              Terms and Conditions
            </PageListItem>
          </PageList>
        </PageListContainer>
        <PageListContainer span={6}>
          <PageList>
            <PageListItem>
              <TopLabel>Opportunities</TopLabel>
            </PageListItem>
            <PageListItem onClick={() => scrollToSection("seedSkip")}>
              Browse SEED Projects
            </PageListItem>
            <PageListItem onClick={() => scrollToSection("openBountiesSkip")}>
              Browse Open Bounties
            </PageListItem>
            <PageListItem onClick={() => scrollToSection("learnAndEarnSkip")}>
              Browse Learn 2 Earn Contests
            </PageListItem>
          </PageList>
        </PageListContainer>
        <SearchEmailContainer span={7}>
          <PageList>
            <PageListItem>
              <TopLabel>Our partners:</TopLabel>
            </PageListItem>
            <PageListItem>
              <a
                href={"https://crowdhack.io/"}
                target="_blank"
                rel="noreferrer"
              >
                CrowdHack
              </a>
            </PageListItem>
            <PageListItem>
              <a
                href={"https://datasource.ai/"}
                target="_blank"
                rel="noreferrer"
              >
                DataSource
              </a>
            </PageListItem>
            <PageListItem>
              <a
                href={"https://www.rebaked.com/"}
                target="_blank"
                rel="noreferrer"
              >
                reBaked DAO
              </a>
            </PageListItem>
            <PageListItem>
              <a href={"https://opensoul.io/"} target="_blank" rel="noreferrer">
                OpenSoul
              </a>
            </PageListItem>
          </PageList>
          <NewsletterFormContainer ref={testRef}>
            <TopLabel>Subscribe to Newsletter</TopLabel>
          </NewsletterFormContainer>
        </SearchEmailContainer>
        <FooterBottom>
          <CopyRightText>
            @All rights reserved by <strong>BuidlFi.com</strong> &{" "}
            <a
              href={"https://www.rebaked.com/"}
              target="_blank"
              rel="noreferrer"
            >
              <strong>RBKD DAO</strong>
            </a>{" "}
            | OWNERSHIP ECONOMY LIMITED
          </CopyRightText>
        </FooterBottom>
      </FooterContent>
    </FooterContainer>
  );
};

export default memo(FooterUser);
