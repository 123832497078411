export const Logo = (props) => (
  <svg
    width={135}
    height={65}
    onClick={props.onClick}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 338.1 145.1"
    style={{
      enableBackground: "new 0 0 338.1 145.1",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st0{fill:#102548}.st1{fill:#fff}"}</style>
    <path
      className="st0"
      d="M291.3 99.6H65.6c-12.2 0-22.1-10-22.1-22.1 0-12.2 10-22.1 22.1-22.1h225.7c12.2 0 22.1 10 22.1 22.1 0 12.1-9.9 22.1-22.1 22.1z"
    />
    <path
      className="st1"
      d="M147.9 62.9h11.2c2.9 0 5.2.6 6.8 1.9 1.6 1.3 2.4 3 2.4 5.1 0 1.1-.2 2.1-.6 2.9-.4.9-.9 1.6-1.6 2.3-.7.7-1.5 1.2-2.4 1.7-.9.5-1.9.8-3 1 1.5.5 2.7 1.2 3.8 2.2 1 1 1.5 2.3 1.5 4 0 1.3-.3 2.5-.9 3.6-.6 1.1-1.4 2-2.5 2.8-1.1.8-2.4 1.4-4 1.8s-3.3.7-5.3.7h-13.8l8.4-30zm6 27c2.7 0 4.8-.5 6.4-1.6 1.6-1.1 2.4-2.5 2.4-4.4 0-.8-.2-1.4-.5-2-.3-.6-.8-1.1-1.4-1.4-.6-.4-1.3-.7-2.2-.9-.8-.2-1.8-.3-2.8-.3h-9.1l-2.8 10.6h10zm2.2-13.6c2.7 0 4.8-.5 6.4-1.6s2.4-2.5 2.4-4.3c0-1.4-.5-2.5-1.6-3.3-1.1-.7-2.6-1.1-4.5-1.1h-8.4l-2.7 10.4h8.4zM173.6 91.5c-1.3-1.2-1.9-2.9-1.9-5 0-.5.1-1.1.2-1.7.1-.6.2-1.2.4-1.8l3.3-12.3h3.3l-3.4 12.5c-.1.4-.2.8-.2 1.2 0 .5-.1.9-.1 1.2 0 1.5.4 2.6 1.3 3.4.9.8 2.1 1.2 3.6 1.2.9 0 1.7-.2 2.5-.5.8-.4 1.6-.8 2.3-1.5s1.3-1.4 1.9-2.3c.5-.9 1-1.8 1.2-2.9l3.3-12.4h3.3l-5.9 22.1h-3.3l1-3.6c-1 1.1-2.1 2.1-3.4 2.9-1.2.8-2.7 1.2-4.4 1.2-2 .1-3.7-.5-5-1.7zM202.4 70.7h3.3l-5.9 22.1h-3.3l5.9-22.1zm2-8.4h3.8l-1 3.6h-3.8l1-3.6zM214.1 92.7c-1.1-.5-2-1.1-2.8-1.9-.8-.8-1.4-1.8-1.8-3-.4-1.2-.7-2.4-.7-3.8 0-2 .3-3.8 1-5.5s1.6-3.1 2.8-4.3c1.2-1.2 2.5-2.2 4-2.9 1.5-.7 3-1 4.7-1 1.1 0 2.2.1 3 .4.9.3 1.7.7 2.4 1.2s1.3 1.1 1.7 1.8c.5.7.9 1.4 1.2 2.1l3.8-14.1h3.3l-8.3 31.2H225l1-3.8c-1.1 1.2-2.3 2.2-3.6 3-1.4.8-2.9 1.2-4.7 1.2-1.4.1-2.5-.2-3.6-.6zm8.1-3.1c1.2-.6 2.2-1.4 3.1-2.3.9-1 1.7-2.1 2.2-3.3.6-1.3.9-2.6.9-3.9 0-1-.2-1.9-.5-2.7s-.8-1.5-1.4-2.2c-.6-.6-1.3-1.1-2.2-1.5-.8-.4-1.8-.5-2.8-.5-1.3 0-2.5.3-3.7.9-1.1.6-2.1 1.3-2.9 2.3-.8 1-1.5 2.1-1.9 3.4s-.7 2.6-.7 4c0 1 .2 2 .4 2.8.3.8.7 1.5 1.3 2.1.6.6 1.2 1.1 2 1.4.8.3 1.6.5 2.5.5 1.3-.1 2.5-.4 3.7-1zM244.3 61.6h3.3l-8.4 31.2h-3.3l8.4-31.2z"
    />
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={241.976}
      y1={87.487}
      x2={282.331}
      y2={56.59}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#48b972",
        }}
      />
      <stop
        offset={0.212}
        style={{
          stopColor: "#4bba74",
        }}
      />
      <stop
        offset={0.363}
        style={{
          stopColor: "#54be7b",
        }}
      />
      <stop
        offset={0.495}
        style={{
          stopColor: "#64c487",
        }}
      />
      <stop
        offset={0.616}
        style={{
          stopColor: "#7acc98",
        }}
      />
      <stop
        offset={0.729}
        style={{
          stopColor: "#96d7ae",
        }}
      />
      <stop
        offset={0.837}
        style={{
          stopColor: "#bae4ca",
        }}
      />
      <stop
        offset={0.939}
        style={{
          stopColor: "#e3f4e9",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#fff",
        }}
      />
    </linearGradient>
    <path
      d="M255.5 62.9H277l-.8 3.1H258l-2.9 10.7h16.3l-.9 3.1h-16.2l-3.5 13h-3.4l8.1-29.9z"
      style={{
        fill: "url(#SVGID_1_)",
      }}
    />
    <linearGradient
      id="SVGID_00000170972648424295941250000017830389031746829696_"
      gradientUnits="userSpaceOnUse"
      x1={251.611}
      y1={100.071}
      x2={291.966}
      y2={69.174}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#48b972",
        }}
      />
      <stop
        offset={0.212}
        style={{
          stopColor: "#4bba74",
        }}
      />
      <stop
        offset={0.363}
        style={{
          stopColor: "#54be7b",
        }}
      />
      <stop
        offset={0.495}
        style={{
          stopColor: "#64c487",
        }}
      />
      <stop
        offset={0.616}
        style={{
          stopColor: "#7acc98",
        }}
      />
      <stop
        offset={0.729}
        style={{
          stopColor: "#96d7ae",
        }}
      />
      <stop
        offset={0.837}
        style={{
          stopColor: "#bae4ca",
        }}
      />
      <stop
        offset={0.939}
        style={{
          stopColor: "#e3f4e9",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#fff",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000170972648424295941250000017830389031746829696_)",
      }}
      d="M281.3 70.7h3.3l-5.9 22.1h-3.3l5.9-22.1zm2.1-8.4h3.8l-1 3.6h-3.8l1-3.6z"
    />
    <linearGradient
      id="SVGID_00000145773468150259131020000011186227683857772956_"
      gradientUnits="userSpaceOnUse"
      x1={16.925}
      y1={477.599}
      x2={121.3}
      y2={477.599}
      gradientTransform="translate(0 -414.986)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#9c8ac1",
        }}
      />
      <stop
        offset={0.27}
        style={{
          stopColor: "#998bc2",
        }}
      />
      <stop
        offset={0.486}
        style={{
          stopColor: "#908ec5",
        }}
      />
      <stop
        offset={0.684}
        style={{
          stopColor: "#7f93ca",
        }}
      />
      <stop
        offset={0.869}
        style={{
          stopColor: "#6399d2",
        }}
      />
      <stop
        offset={0.949}
        style={{
          stopColor: "#4f9dd6",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000145773468150259131020000011186227683857772956_)",
      }}
      d="M120.7 28.2c-23.7-23.7-62.2-23.7-86 0-23.7 23.7-23.7 62.2 0 86 .2.2.5.4.7.6l85.9-85.9c-.2-.2-.4-.5-.6-.7z"
    />
    <path
      className="st1"
      d="M104.7 47.8c-13.9-13.9-36.5-13.9-50.4 0s-13.9 36.5 0 50.4l.4.4 50.4-50.4-.4-.4z"
    />
    <linearGradient
      id="SVGID_00000105395969163801728020000004929283119527616384_"
      gradientUnits="userSpaceOnUse"
      x1={131.29}
      y1={476.477}
      x2={43.696}
      y2={527.049}
      gradientTransform="translate(0 -414.986)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#48b972",
        }}
      />
      <stop
        offset={0.192}
        style={{
          stopColor: "#51b47a",
        }}
      />
      <stop
        offset={0.505}
        style={{
          stopColor: "#69a691",
        }}
      />
      <stop
        offset={0.899}
        style={{
          stopColor: "#9190b6",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#9c8ac1",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000105395969163801728020000004929283119527616384_)",
      }}
      d="M41.2 107.3c20 20 52.6 20 72.6 0s20-52.6 0-72.6c-.2-.2-.4-.4-.6-.5l-72.6 72.5.6.6z"
    />
    <path
      className="st0"
      d="M59.6 88.3c9.7 9.7 25.5 9.7 35.2 0s9.7-25.5 0-35.2l-.3-.3L59.3 88l.3.3z"
    />
    <linearGradient
      id="SVGID_00000131331044158251248630000018112670875792311464_"
      gradientUnits="userSpaceOnUse"
      x1={68.201}
      y1={486.186}
      x2={87.304}
      y2={486.186}
      gradientTransform="translate(0 -414.986)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#5786c5",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#3869b2",
        }}
      />
    </linearGradient>
    <circle
      style={{
        fill: "url(#SVGID_00000131331044158251248630000018112670875792311464_)",
      }}
      cx={77.7}
      cy={71.2}
      r={9.6}
    />
  </svg>
);
