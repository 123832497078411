export const MediumIcon = () => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11073_14994)">
      <path
        d="M4.5125 0.0234375C7.00481 0.0234375 9.025 2.25248 9.025 5.00185C9.025 7.75129 7.00462 9.98013 4.5125 9.98013C2.0205 9.98013 0 7.75129 0 5.00185C0 2.25255 2.02031 0.0234375 4.5125 0.0234375ZM11.7188 0.315037C12.9649 0.315037 13.9751 2.41321 13.9751 5.00185H13.9753C13.9753 7.58988 12.9651 9.68867 11.719 9.68867C10.4729 9.68867 9.46269 7.58988 9.46269 5.00185C9.46269 2.41389 10.4727 0.315037 11.7188 0.315037H11.7188ZM15.2064 0.803249C15.6446 0.803249 16 2.68303 16 5.00192C16 7.32006 15.6447 9.20059 15.2064 9.20059C14.7681 9.20059 14.4131 7.32061 14.4131 5.00192C14.4131 2.68317 14.7683 0.803249 15.2064 0.803249Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_11073_14994">
        <rect width="16" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
