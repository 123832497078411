import { Table, Tabs } from "antd";
import styled from "styled-components";
import BaseModal from "components/base/baseModal";
import tab_modal_bg from "assets/images/png/tab_modal_bg.png";
import BaseButton from "components/base/baseButton";
export const Container = styled.div`
  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: -0.04em;
    color: #080f6c;
    margin-bottom: 20px;
  }
`;

export const TableCustom = styled(Table)`
  table {
    overflow: hidden;
    border-radius: 8px 8px 0px 0px;
  }
  thead > tr {
    height: 56px;
    background: linear-gradient(
      90deg,
      rgba(126, 0, 253, 0.1) 0%,
      rgba(193, 67, 252, 0.1) 46.88%,
      rgba(0, 191, 251, 0.1) 100%
    ) !important;
    th {
      background: unset;
      font-family: "DM Sans";
      font-style: normal;
      line-height: 125%;
      letter-spacing: -0.04em;
      vertical-align: middle;
      .ant-table-column-sorters {
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
        .ant-table-column-sorter {
          margin-left: unset;
        }
      }
      h1 {
        font-weight: 600 !important;
        font-size: 15px;
        color: #080f6c;
        display: inline;
      }
      span {
        font-weight: 400 !important;
        font-size: 14px;
        color: rgba(8, 15, 108, 0.82);
      }
      word-break: break-word;
    }
  }

  tbody > tr {
    font-family: "DM Sans";
    font-style: normal;
    line-height: 125%;
    letter-spacing: -0.04em;
    font-weight: 400 !important;
    font-size: 14px;
    color: rgba(8, 15, 108, 0.82);
    height: 56px;
    td {
      padding: 0px 16px;
      vertical-align: middle;
      word-break: break-word;
    }
    td:first-child {
      h1 {
        font-weight: 600 !important;
        font-size: 16px;
        color: #080f6c;
        display: inline;
      }
    }
    td > label.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #7e00fd !important;
      border-color: #7e00fd !important;
    }

    td > .button-select {
      span {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.04em;
        color: #7e00fd;
      }
      .ant-select-selector {
        background: rgba(156, 138, 193, 0.16);
        border: 1px solid rgba(156, 138, 193, 0.2);
        border-radius: 6px;
        span {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: -0.04em;
          color: #7e00fd;
        }
      }
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 12px;
  }
`;

export const BaseModalCustom = styled(BaseModal)`
  width: 684px !important;
  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 12px;
  padding-bottom: unset;
  .ant-modal-content {
    height: 100%;
  }
  .ant-modal-body {
    position: relative;
  }
`;

export const NFTModal = styled(BaseModal)`
  width: 684px !important;
  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 12px;
  padding-bottom: unset;
  .ant-modal-content {
    height: 100%;
  }
  .ant-modal-body {
    position: relative;
  }
  .ant-modal-footer,
  .ant-modal-header {
    border-radius: 12px;
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TabsCustom = styled(Tabs)<{ username?: string }>`
  .ant-tabs-nav {
    margin-bottom: unset;
    position: relative;
    padding-left: 40px;
    ::after {
      position: absolute;
      top: 40px;
      left: 40px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 100%;
      letter-spacing: -0.04em;
      color: #080f6c;
      ${(p) => `content: "${p.username} Assignments" !important`}
    }
    height: 160px;
    background-image: url(${tab_modal_bg});
    .ant-tabs-tab {
      display: flex;
      align-items: flex-end;
      padding: 20px 0;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 100%;
      color: rgba(41, 46, 115, 0.82);
      .ant-tabs-tab-btn {
      }
    }
    .ant-tabs-nav-operations {
      height: 50px;
      margin: 105px 0px;
    }
  }
`;

export const TabPaneCustom = styled(Tabs.TabPane)``;

export const TabContent = styled.div`
  height: 100%;
  padding: 32px 40px;
  display: grid;
  gap: 24px;
`;
export const ImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: rgba(156, 138, 193, 0.06);
  border-radius: 8px;
  overflow: hidden;
  .ant-image {
    width: 100% !important;
    height: 100% !important;
    border-radius: 8px;
    overflow: hidden;
    img {
      object-fit: contain;
      width: 100% !important;
      height: 100% !important;
      margin: 0 auto;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  .ant-input {
    height: 100% !important;
    width: 100% !important;
    border-radius: 8px;
  }
  h1 {
    color: #080f6c;
    font-size: 20px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: unset;
  }
  .options {
    width: 100% !important;
    border: none;
    background: transparent !important;
    padding: 0;
  }
`;
export const ReasonContent = styled.div`
  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
    margin-bottom: 12px;
  }
  textarea {
    background: #ffffff;
    border: 1px solid rgba(156, 138, 193, 0.16);
    border-radius: 4px;
    height: 81px;
  }
`;
export const ActionWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Reject = styled(BaseButton)`
  width: 94px;
  height: 48px;
  background: rgba(156, 138, 193, 0.16);
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 6px;
`;
export const Approve = styled(BaseButton)`
  width: 120px;
  height: 48px;
  background: radial-gradient(
    100% 445.32% at 0% 100%,
    #7e00fd 0%,
    #b93df3 42.35%,
    #a54bf4 57.95%,
    #00bffb 100%
  );
  border-radius: 4px;
`;

export const SendReward = styled(BaseButton)`
  width: 225px;
  height: 48px;
  background: radial-gradient(
    100% 445.32% at 0% 100%,
    #7e00fd 0%,
    #b93df3 42.35%,
    #a54bf4 57.95%,
    #00bffb 100%
  );
  border-radius: 4px;
`;

export const Label = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  h1 {
    display: contents;
  }
  margin-bottom: 10px;
`;
