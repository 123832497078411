import { FC, memo } from "react";
import { Link } from "react-router-dom";
import { camelToFormal } from "utils/camelToFormal";
import { ROUTER_CONSTANTS } from "utils/constant";
import TitleAndContext from "./titleAndContext";
import { IWorktaskCardProps } from "./worktaskCard.props";
import { Container, ViewButton } from "./worktaskCard.style";

const WorktaskCard: FC<IWorktaskCardProps> = ({ worktask }) => {
  const worktaskTransform = {
    worktaskName: worktask.project?.packages.name,
    Amount: worktask.amount,
    transactionType: worktask.type,
    token: worktask.tokenName,
  };

  return (
    <Container column={Object.keys(worktaskTransform).length + 1}>
      {Object.keys(worktaskTransform).map((key, index) => (
        <TitleAndContext
          key={index}
          title={camelToFormal(key)}
          context={(worktaskTransform as any)[key]}
        />
      ))}
      <Link
        to={ROUTER_CONSTANTS.PROJECT.DETAIL.replace(
          ":id",
          worktask.project._id
        )}
      >
        <ViewButton>
          <span>View Project</span>
        </ViewButton>
      </Link>
    </Container>
  );
};

export default memo(WorktaskCard);
