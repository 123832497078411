import { FC, ReactElement } from "react";
import OrganizationInfo from "./OrganizationInfo";
import { IOrganizationPageProps } from "./OrganizationPage.props";
import { OrgContainer } from "./OrganizationPage.style";

const OrganizationPage: FC<IOrganizationPageProps> = ({
  ...props
}: IOrganizationPageProps): ReactElement => {
  return (
    <OrgContainer>
      <OrganizationInfo />
    </OrgContainer>
  );
};

export default OrganizationPage;
