export const EducationIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#9C8AC1" fillOpacity="0.16" />
    <g clipPath="url(#clip0_8235_13318)">
      <path
        d="M17.33 22L24 26L34 20L24 14L14 20H24V22H17.33ZM14 22V30L16 27.78V23.2L14 22ZM24 34L19 31L17 29.8V23.8L24 28L31 23.8V29.8L24 34Z"
        fill="#7E00FD"
      />
    </g>
    <defs>
      <clipPath id="clip0_8235_13318">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(14 14)"
        />
      </clipPath>
    </defs>
  </svg>
);
