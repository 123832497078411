import { BackIcon } from "assets/icons/navArrow.icon";
import { FC } from "react";
import { BackToDetailProps } from "./backToDetail.props";
import { BackLink } from "./backToDetail.style";

const BackToDetail: FC<BackToDetailProps> = ({
  linkToGo,
  linkText,
  routerState,
}) => {
  return (
    <BackLink
      to={
        linkToGo
        // !!packageId
        //   ? ROUTER_CONSTANTS.PROJECT.DELIVER_WORK.replace(
        //       ":projectId",
        //       projectId || ""
        //     ).replace(":packageId", packageId || "")
        //   : ROUTER_CONSTANTS.PROJECT.DETAIL.replace(":id", projectId)
      }
      state={routerState}
      // state={
      //   !!packageId
      //     ? {
      //         toDeliverTab: true,
      //       }
      //     : undefined
      // }
    >
      <BackIcon /> {linkText}
      {/* Back to {!!packageId ? "worktask" : "project"} detail */}
    </BackLink>
  );
};

export default BackToDetail;
