import { CalendarIcon } from "assets/icons/calender.icon";
import dayjs from "dayjs";
import { FC, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { RecentUpdatesCardProps } from "./recentUpdatesCard.props";
import {
  ProjectCard,
  ProjectCardContent,
  ProjectImg,
  ProjectName,
  ProjectDesc,
  ProjectCardFooter,
  TagName,
  Calendar,
} from "./recentUpdatesCard.style";

const RecentUpdatesCard: FC<RecentUpdatesCardProps> = ({
  project,
  isLoading,
}) => {
  const navigate = useNavigate();

  const handleClickCard = () => {
    if (isLoading || !project) return;
    let route: string | undefined;
    switch (project.category) {
      case "Seed":
      case "Open Bounties":
        route = ROUTER_CONSTANTS.PROJECT.DETAIL;
        break;
      case "Learn & Earn":
        route = ROUTER_CONSTANTS.COURSE.COURSE_DETAIL;
        break;
      default:
        break;
    }
    if (route) navigate(route.replace(":id", project?._id));
  };

  const tagColor = useMemo(() => {
    if (project) {
      switch (project.category) {
        case "Learn & Earn":
          return "#7E00FD";
        case "Open Bounties":
          return "#E94600";
        case "Seed":
          return "#49B67A";
        default:
          break;
      }
    }
    return "#7E00FD";
  }, [project]);

  return (
    <ProjectCard onClick={handleClickCard}>
      {isLoading ? (
        <Skeleton
          style={{
            aspectRatio: "2.9375",
            display: "flex",
          }}
          borderRadius="4px 4px 0 0"
        />
      ) : (
        <ProjectImg src={project?.coverImage} alt="project-thumb" />
      )}
      <ProjectCardContent>
        <ProjectName>
          {isLoading ? (
            <Skeleton width="60%" />
          ) : (
            project?.projectName || project?.title
          )}
        </ProjectName>
        <ProjectDesc>
          {isLoading ? (
            <Skeleton count={3} />
          ) : (
            project?.tagline || project?.description
          )}
        </ProjectDesc>
      </ProjectCardContent>
      <ProjectCardFooter space="10px">
        <Calendar>
          <CalendarIcon></CalendarIcon>
          <span>{dayjs(project?.createdAt).format("MMMM DD, YYYY")}</span>
        </Calendar>
        <TagName color={tagColor}>
          <>{project?.category}</>
        </TagName>
      </ProjectCardFooter>
    </ProjectCard>
  );
};

export default RecentUpdatesCard;
