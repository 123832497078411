import PrimaryButton from "components/base/primaryButton";
import {
  GlobalStyle,
  PopoverAntd,
} from "pages/ProjectBoard/Components/CardModal/CardModal.style";
import { FC, useEffect, useState } from "react";
import { IAvatarProps, ICollab } from "./avatarList.props";
import {
  AvatarAntd,
  AvatarAntdWrap,
  PopoverAvatarItem,
  PopoverAvatarWrap,
} from "./avatarList.style";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const AvatarAntdGroup: FC<IAvatarProps> = ({ collabs, prefix, size, max }) => {
  const [list, setList] = useState<ICollab[]>([]);
  const [overMax, setOverMax] = useState<number>(0);
  const [popoverAvatar, setPopoverAvatar] = useState<boolean>(false);

  useEffect(() => {
    setList(collabs?.slice(0, max - 1));
    setOverMax(collabs?.slice(max - 1).length);
  }, [collabs, max]);

  useEffect(() => {
    const handleClickOutside = () => {
      if (popoverAvatar) setPopoverAvatar(false);
    };

    document.onclick = null;
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [popoverAvatar]);

  return (
    <>
      <GlobalStyle />
      <PopoverAntd
        placement="right"
        title={null}
        content={
          <>
            <PopoverAvatarWrap
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {collabs.map((collab: ICollab, i: number) => (
                <PopoverAvatarItem
                  onClick={() => setPopoverAvatar(false)}
                  key={i}
                >
                  <div>
                    <Link to={"/profile-preview?id=" + collab.id}>
                      <AvatarAntd
                        src={collab.url}
                        alt="avatar"
                        icon={<UserOutlined />}
                      />
                    </Link>
                    <div>{collab.username}</div>
                  </div>
                  <PrimaryButton width="110px" height="32px">
                    <Link to={"/conversation?id=" + collab.id}>
                      Send Message
                    </Link>
                  </PrimaryButton>
                </PopoverAvatarItem>
              ))}
            </PopoverAvatarWrap>
          </>
        }
        visible={popoverAvatar}
        trigger="click"
        overlayClassName={"popover-right"}
      >
        <AvatarAntdWrap
          size={size}
          onClick={(e: any) => {
            e.stopPropagation();
            if (collabs?.length) setPopoverAvatar(true);
          }}
        >
          <div>
            {list.map((collab: ICollab, i: number) => (
              <AvatarAntd
                src={collab.url}
                alt="avatar"
                icon={<UserOutlined />}
                key={i}
              />
            ))}
            {!!overMax && <label>{"+" + overMax}</label>}
          </div>
          <span>{prefix}</span>
        </AvatarAntdWrap>
      </PopoverAntd>
    </>
  );
};

export default AvatarAntdGroup;
