import styled from "styled-components";
import { IEmptyDataProps } from "./emptyData.props";

export const StyledEmptyData = styled.div<IEmptyDataProps>`
  display: grid;
  width: 100%;
  align-items: ${(p) => p.align || "center"};
  justify-content: ${(p) => p.justify || "center"};
  margin: ${(p) => p.m};
  gap: ${(p) => p.gap};
  flex-wrap: wrap;
  img {
    margin: 54px auto 32px;
  }
`;
