import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.min.css";
import "@fontsource/rubik";
import "@fontsource/dm-sans";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store } from "redux/store";
import { DAppProvider } from "@usedapp/core";
import ScrollToTop from "ScrollToTop";
import { dappConfig } from "utils/connectors";
import { GlobalStyle } from "globalStyle";
import LoadingFullpage from "components/base/loadingFullpage";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

let persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DAppProvider config={dappConfig}>
        <QueryClientProvider client={queryClient}>
          <LoadingFullpage />
          <BrowserRouter>
            <ScrollToTop />
            <App />
            <GlobalStyle />
          </BrowserRouter>
        </QueryClientProvider>
      </DAppProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
