import PrimaryButton from "components/base/primaryButton";
import { FC, memo } from "react";
import { Link } from "react-router-dom";
import { IPage404Props } from "./Page404.props";
import { Container } from "./Page404.style";

const Page404: FC<IPage404Props> = (props: IPage404Props) => {
  return (
    <Container>
      <div>
        <svg
          width="427"
          viewBox="0 0 427 242"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.23864 171.273H78.9773V194H116.761V171.273H133.523V140.591H116.761V48.5455H66.7614L8.23864 140.591V171.273ZM79.8295 140.591H47.1591V139.455L78.6932 89.4545H79.8295V140.591ZM213.441 197.977C253.001 198.048 277.575 169.71 277.646 121.557C277.717 73.6875 253.001 46.5568 213.441 46.5568C173.81 46.5568 149.379 73.5455 149.237 121.557C149.094 169.497 173.81 197.906 213.441 197.977ZM213.441 165.875C199.805 165.875 190.004 152.168 190.146 121.557C190.288 91.6562 199.805 78.375 213.441 78.375C227.077 78.375 236.665 91.6562 236.737 121.557C236.808 152.168 227.077 165.875 213.441 165.875ZM294.762 171.273H365.501V194H403.285V171.273H420.046V140.591H403.285V48.5455H353.285L294.762 140.591V171.273ZM366.353 140.591H333.683V139.455L365.217 89.4545H366.353V140.591Z"
            fill="currentColor"
          />
          <path
            d="M178 112H206L178 203L245 82H229L265 21L212 29L178 112Z"
            fill="white"
          />
        </svg>
        <div>Looks like you are lost.</div>
        <span>
          Oops! The page you are looking for does not exist. It might have been
          moved or deleted.
        </span>
        <PrimaryButton width="200px" height="48px">
          <Link to="/">Back to home</Link>
        </PrimaryButton>
      </div>
    </Container>
  );
};

export default memo(Page404);
