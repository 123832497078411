import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getBlogByIdAPI } from "./request";

export const useGetBlogById = (
  id: string,
  option?: UseQueryOptions<any, AxiosError>
) => {
  return useQuery<any, AxiosError>(
    ["get-blog-by-id"],
    () => getBlogByIdAPI(id),
    {
      refetchOnWindowFocus: false,
      ...option,
    }
  );
};
