import { Tooltip, Avatar as AntdAvatar } from "antd";
import styled from "styled-components";

export const AcceptCollabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  padding: 0rem 3.125rem;
  overflow: hidden;
`;
export const BackgroundImage = styled.img`
  object-fit: cover;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  position: absolute;
  height: 100%;
  border-bottom: 1px solid rgba(115, 137, 149, 0.4);
`;
export const HeadWrap = styled.div`
  width: 100%;
  height: 100%;
  z-index: 12;
`;
export const Head = styled.div`
  padding: 35px 0px 40px;
  max-width: 1170px;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
export const Wrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
`;
export const Gap = styled.div`
  height: 12px;
`;
export const Title = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #080f6c;
  // min-width: 0;
  overflow-wrap: break-word;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
export const Round = styled.div`
  border-radius: 40px;
  height: 26px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  font-weight: 500;
`;
export const Status = styled(Round)`
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  margin: 0px 8px 0px 12px;
  background: #7e00fd;
`;
export const EndTime = styled(Round)`
  font-size: 14px;
  /* line-height: 110%; */
  letter-spacing: -0.02em;
  color: #ff5722;
  background: rgba(255, 87, 34, 0.16);
  border: 1px solid rgba(255, 87, 34, 0.16);
  border-radius: 20px;
  height: auto;
  flex-shrink: 0;
`;
export const Deadline = styled(Round)`
  font-size: 13px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #ff5722;
  border: 0.0625rem solid #ff5722;
  width: 100px;
  text-align: center;
  width: max-content;
`;
export const SponsoredBy = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  color: rgba(41, 46, 115, 0.82);
  margin-right: 10px;
`;
export const Avatar = styled.img`
  object-fit: cover;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin: 0px 8px;
`;

export const OrganizationAvatarWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  color: #080f6c;
`;

export const AvatarSponsor = styled(AntdAvatar)`
  width: 25px;
  height: 24px;
  border-radius: 50%;
  padding: 0px;
  border: none;
`;

export const OrgName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  color: #080f6c;
`;
export const BodyWrap = styled.div`
  padding: 24px 0px 36px;
  max-width: 1170px;
  margin: auto;
  .line-height-160 {
    line-height: 160%;
  }
`;
export const Bg = styled.div`
  display: flex;
  padding: 30px 32px;
  width: 100%;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      #7e00fd 0%,
      #c143fc 61.44%,
      #00bffb 100%
    );
    opacity: 0.08;
    z-index: -1;
  }
`;
export const BgItem = styled.div<{ marginRight: number }>`
  margin-right: ${(p) => p.marginRight + "px"};

  div {
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;

    color: #080f6c;
  }

  span {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: rgba(41, 46, 115, 0.82);

    svg {
      margin-left: 4.88px;
      cursor: pointer;
    }
  }
`;
export const Infor = styled.div`
  display: flex;
  margin: 24px 0px 36px;
  width: 100%;
  flex-wrap: wrap;
  gap: 120px;
`;
export const InforItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const InforItem = styled.div`
  & > div:nth-child(1) {
    position: relative;
    padding-left: 31.6px;
    display: flex;
    align-items: center;
    svg {
      position: absolute;
      left: 0;
    }
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;

      color: #080f6c;
    }
  }
  & > div:nth-child(2) {
    padding-left: 31.6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgba(41, 46, 115, 0.82);
  }
`;
export const AvatarsWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 4px;
  width: max-content;
  & > span:nth-child(0) {
    margin-left: 0px;
  }
  span {
    /* object-fit: cover;
  width: 24px;
  height: 24px;
  border-radius: 24px; */
    /* margin: 0px 1px; */
    margin-left: -7px;
  }
  label {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    background: #f57f20;
    border: 1px solid #ffffff;
    border-radius: 24px;
    margin-left: -7px;
    z-index: 217;
  }
`;
export const SmallAvatar = styled.img`
  object-fit: cover;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  margin-left: -4px;
`;
export const Accepted = styled.div`
  position: absolute;
  left: calc(100% + 16px);
  font-weight: 400;
  font-size: 12px;
  width: max-content;

  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;
export const GitWrap = styled.div<{
  noPadding?: boolean;
  marginBottom?: boolean;
}>`
  width: 100%;
  padding: ${(p) => (p.noPadding ? "0" : "17px 0px")};
  margin-bottom: ${(p) => (p.marginBottom ? "48px" : "0px")};
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > svg {
    position: absolute;
    z-index: -1;
  }
`;
export const Git = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  label {
    width: 24px;
  }
`;
export const GitBtns = styled.div`
  width: 100%;
  display: flex;
  gap: 9px;
`;
export const Description = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #080f6c;
  margin-bottom: 12px;
  margin-top: 40px;
`;
export const DesTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 156%;
  color: rgba(41, 46, 115, 1);

  margin: 15px 0 5px;
`;
export const DesText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: rgba(41, 46, 115, 0.82);
  overflow: hidden;
  white-space: pre-wrap;

  .text-bold {
    font-weight: 600;
  }
  .child-text {
    margin: 0px 0px 0px 12px;
  }
  span {
  }
`;
export const WorkPlansTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin: 36px 0px 16px;
`;
export const WorkPlansWrap = styled.div`
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 8px;
  padding: 34px 24px 24px;
`;
export const WorkPlansHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export const PersonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & > span {
    width: 52px;
    height: 52px;
    border-radius: 4px !important;
    object-fit: cover;
  }

  & > div {
    & > div {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #080f6c;
      position: relative;
      display: flex;
      align-items: center;

      & > div {
        position: absolute;
        left: calc(100% + 15px);
        width: max-content;
      }
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: rgba(41, 46, 115, 0.82);
      margin-top: 8px;
    }
  }
`;
export const Techs = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;

  & > div {
    display: flex;
    gap: 8px;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 14px;
      color: rgba(41, 46, 115, 0.82);
    }
  }
`;
export const WorkPlans = styled.div`
  padding: 20px 24px 17px;
  line-height: 156%;
  background: rgba(115, 137, 149, 0.06);
  border-radius: 5px;
  display: block;
  margin-top: 16px;
  font-size: 18px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  & > div {
    font-weight: 700;
    color: #080f6c;
  }
  p {
    font-weight: 400;
    color: rgba(41, 46, 115, 0.82);
    margin: 0;
    word-break: break-word;
    margin-bottom: 10px;
  }
`;

export const ApplicantsWrap = styled(WorkPlansWrap)`
  padding: 24px;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  background: rgba(115, 137, 149, 0.06);
  border-radius: 5px;
  padding: 12px;
`;
export const AvatarWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 195px;
  width: 250px;
  & > img {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    object-fit: cover;
  }

  & > div {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #080f6c;
  }
  img {
    width: 50px;
  }
`;
export const ItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: rgba(41, 46, 115, 0.82);
`;
export const ItemTextWrap = styled(ItemText)`
  margin-right: 167px;
`;
export const PopupWrap = styled.div<{
  paddingX?: number;
  width?: number;
  height?: number;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(8, 10, 37, 0.9);
  /* opacity: 0.9; */
  z-index: 999;

  svg {
    flex-shrink: 0;
  }

  & > div {
    padding: 50px 30px 45px;
    width: ${(p) => (p.width ? p.width + "px" : "446px")};
    height: ${(p) => (p.height ? p.height + "px" : "469px")};
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      margin: 27px 0px 12px;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #080f6c;
      text-align: center;
      padding: ${(p) => (p.paddingX ? "0px " + p.paddingX + "px" : "0px")};
    }
    & > p {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      text-align: center;
      color: rgba(41, 46, 115, 0.82);
      margin-bottom: 20px;

      word-break: break-word;
    }
  }
`;
export const SpanText = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
`;

export const TooltipAtnd = styled(Tooltip)``;

export const ProjectLink = styled.div`
  max-width: 260px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const WtInfo = styled.div`
  min-width: 0;
`;
