import { FC } from "react";
import { IEmptyDataProps } from "./emptyData.props";
import { StyledEmptyData } from "./emptyData.style";
import EmptyBG from "assets/images/empty-data.png";

const EmptyData: FC<IEmptyDataProps> = ({ children, entity, ...props }) => {
  return (
    <StyledEmptyData {...props}>
      <img src={EmptyBG} alt="empty-data" />
      <div>
        Currently there are no {entity ?? "ongoing projects"} - Please check out
        soon for exciting missions!
      </div>
    </StyledEmptyData>
  );
};

export default EmptyData;
