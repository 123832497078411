import { useEthers } from "@usedapp/core";
import { PageLoading } from "components/base/PageLoading/PageLoading";
import Footer from "components/layout/footer";
import Header from "components/layout/header";
import ModalConnectWallet from "components/modules/modalConnectWallet";
import { FC, memo, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { ILayoutProps } from "./baseLayout.props";
import { MainContent } from "./baseLayout.style";

const BaseLayout: FC<ILayoutProps> = () => {
  const { connectWalletOpen, connectedWalletName } = useAppSelector(
    (state) => state.modal
  );
  const { activateBrowserWallet } = useEthers();

  useEffect(() => {
    const { ethereum } = window as any;

    if (ethereum && ethereum.on) {
      const handleNetworkChanged = (chainId: string | number) => {
        // console.log({ connectedWalletName });
        if (connectedWalletName) {
          activateBrowserWallet({ type: connectedWalletName });
        }
      };

      ethereum.on("chainChanged", handleNetworkChanged);

      return () => {
        ethereum.removeListener("chainChanged", handleNetworkChanged);
      };
    }
  }, [activateBrowserWallet, connectedWalletName]);

  return (
    <>
      <Header />
      <MainContent>
        <Outlet />
      </MainContent>
      <ModalConnectWallet show={connectWalletOpen} />
      <PageLoading />
      <Footer />
    </>
  );
};

export default memo(BaseLayout);
