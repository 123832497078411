import { useQuery } from "@tanstack/react-query";
import { Col, Form, Row } from "antd";
import { getDataMastersAPI } from "api/users";
import { FC, useEffect, useMemo, useState } from "react";
import { requiredRules, stringRequired } from "utils/validatorRules";
import {
  StyledInput,
  StyledSelect,
  StyledOption,
  BtnContainer,
} from "./createToken.style";
import { ICreateTokenProps } from "./createToken.props";
import { IBlockchainNetwork, ITokenForm } from "api/course";
import type { RuleObject } from "antd/lib/form";
import { checkIfIsToken, handleMessageErrorSC } from "web3/contract";
import { isAddress, parseUnits } from "ethers/lib/utils";
import PrimaryButton from "components/base/primaryButton";
import { createTokenSC } from "web3/learnToEarnContract";
import { BlockchainExplorers, BlockchainNetworks } from "utils/constant";
import {
  useCheckWalletAndSwitchNetwork,
  useHandleTokenContractChanged,
} from "hooks";
import { useAppSelector } from "redux/hooks";
import { useEthers } from "@usedapp/core";
import { scrollToFirstErrorSmooth } from "utils/scrollToSection";

const CreateToken: FC<ICreateTokenProps> = ({
  inModal,
  form,
  onClose,
  isCreateProject = false,
}) => {
  const [tokenForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();
  const [newTokenChainId, setNewTokenChainId] = useState(-1);
  const [selectedExplorer, setTokenExplorer] = useState("");
  const { course } = useAppSelector((state) => state.course);
  const savedReward = course.rewards;
  const handleTokenContractChanged = useHandleTokenContractChanged(form);
  const { chainId: currentChain } = useEthers();
  const [newChainIdToRevalidate, setNewChainIdToRevalidate] = useState(-1);

  const { data: dataNetworks } = useQuery(
    ["getDataNetworks"],
    () => getDataMastersAPI({ type: "Blockchain Networks" }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const blockchainNetworksSelect: IBlockchainNetwork[] = useMemo(() => {
    return dataNetworks?.responseData ? dataNetworks.responseData.values : [];
  }, [dataNetworks?.responseData]);

  const validateNameAndSymbol: (
    _: RuleObject,
    value: string
  ) => Promise<void> = async (_, value) => {
    if (!value || value?.trim()?.length === 0) return Promise.resolve();

    try {
      await checkIfIsToken(value);
    } catch (error) {
      return Promise.reject("Not a valid token contract");
    }

    if (isAddress(value)) {
      return Promise.resolve();
    }

    return Promise.reject("Not a valid token contract");
  };

  const handleChangeNetwork = async (value: any) => {
    const selectedNetwork = blockchainNetworksSelect.find(
      (nw: any) => nw?._id === value
    );
    const newChainId = BlockchainNetworks[selectedNetwork?.name!] as number;
    setNewTokenChainId(newChainId);
    const selectedExplorer =
      BlockchainExplorers[selectedNetwork?.name as string];

    form.setFields([
      {
        name: "tokenExplorer",
        value: selectedExplorer,
        errors: [],
      },
    ]);
    setTokenExplorer(selectedExplorer);

    if (!inModal) {
      if (!checkWallet()) return;
      try {
        await handleSwitchNetwork(newChainId);

        if (currentChain !== newChainId) {
          setNewChainIdToRevalidate(newChainId);
        }
      } catch (err: any) {
        handleMessageErrorSC(err?.reason || err?.message || err.toString());
      }
    }
  };

  const handleCreateToken = async (values: ITokenForm) => {
    if (!checkWallet()) return;

    try {
      setLoading(true);
      await handleSwitchNetwork(newTokenChainId);

      let { event } = await createTokenSC({
        totalSupply: parseUnits(values.totalSupply.toString(), 18),
        name: values.tokenName,
        symbol: values.tokenSymbol,
      });
      const contractAddress = event[0]?.token;
      form.setFieldsValue({
        tokenContract: contractAddress,
        blockchainNetwork: values.blockchainNetwork,
        isExistingToken: isCreateProject ? 1 : true,
        tokenName: values.tokenName,
        tokenSymbol: values.tokenSymbol,
      });

      if (isCreateProject) {
        form.setFieldsValue({
          tokenExplorer: selectedExplorer,
        });
      }

      setLoading(false);
      if (onClose) {
        onClose();
      }
    } catch (err: any) {
      setLoading(false);
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  // const getChainId = (value: unknown) => {
  //   const selectedNetwork = blockchainNetworksSelect.find(
  //     (nw) => nw?._id === value
  //   );
  //   if (selectedNetwork) {
  //     const newChainId = BlockchainNetworks[
  //       selectedNetwork.name as string
  //     ] as number;

  //     return { newChainId, selectedExplorer };
  //   }

  //   return { newChainId: -1, selectedExplorer: "" };
  // };

  // const handleChangeNetwork = async (value: unknown) => {
  //   const { newChainId } = getChainId(value);

  //   if (newChainId === -1) return;

  //   try {
  //     if (!currentChain) {
  //       activateBrowserWallet({ type: connectedWalletName });
  //     }

  //     if (currentChain !== newChainId) {
  //       await switchNetwork(newChainId);
  //     }
  //   } catch (error) {
  //     console.error("err", error);
  //   }
  // };

  useEffect(() => {
    if (!savedReward) return;

    form.setFieldsValue({
      tokenName: savedReward?.token?.name,
      tokenSymbol: savedReward?.token?.symbol,
      tokenContract: savedReward?.token?.contractAddress,
      tokenExplorer: savedReward?.token?.explorer,
    });
  }, [form, savedReward]);

  // revalidate "tokenContract" field if user change network from metamask
  useEffect(() => {
    (async () => {
      if (
        newChainIdToRevalidate !== -1 &&
        currentChain === newChainIdToRevalidate
      ) {
        try {
          setNewChainIdToRevalidate(-1);
          await form.validateFields(["tokenContract"]);
          await handleTokenContractChanged(form.getFieldValue("tokenContract"));
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [currentChain, form, handleTokenContractChanged, newChainIdToRevalidate]);

  const tokenFormItems = (
    <>
      <Col span={12}>
        <Form.Item
          label="Blockchain Network"
          name="blockchainNetwork"
          rules={[requiredRules]}
        >
          <StyledSelect
            placeholder="Blockchain Network"
            onChange={handleChangeNetwork}
          >
            {blockchainNetworksSelect.map((network) => (
              <StyledOption value={network?._id} key={network?._id}>
                {network?.name}
              </StyledOption>
            ))}
          </StyledSelect>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Token Name" name="tokenName" rules={[requiredRules]}>
          <StyledInput placeholder="Token Name" readOnly={!inModal} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Token Symbol"
          name="tokenSymbol"
          rules={[requiredRules]}
        >
          <StyledInput placeholder="Token Symbol" readOnly={!inModal} />
        </Form.Item>
      </Col>

      {inModal ? (
        <Col span={12}>
          <Form.Item
            label="Total Supply"
            name="totalSupply"
            rules={[requiredRules]}
          >
            <StyledInput placeholder="Input the total supply" />
          </Form.Item>
        </Col>
      ) : (
        <>
          <Col span={12}>
            <Form.Item
              label="Token Contract"
              name="tokenContract"
              rules={[
                stringRequired,
                () => {
                  return {
                    validator: validateNameAndSymbol,
                  };
                },
              ]}
            >
              <StyledInput
                onChange={handleTokenContractChanged}
                placeholder="Input the address contract"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Token Explorer"
              name="tokenExplorer"
              // rules={[requiredRules, urlRules]}
            >
              <StyledInput disabled placeholder="Token Explorer" />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );

  return !inModal ? (
    tokenFormItems
  ) : (
    <Form
      layout="vertical"
      form={tokenForm}
      onFinish={handleCreateToken}
      scrollToFirstError={scrollToFirstErrorSmooth}
      initialValues={{
        kind: "TOKEN",
        duration: "custom",
      }}
    >
      <Row gutter={[12, 0]}>{tokenFormItems}</Row>
      <BtnContainer>
        <PrimaryButton htmlType="submit" loading={loading}>
          Create Token
        </PrimaryButton>
      </BtnContainer>
    </Form>
  );
};

export default CreateToken;
