import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

export const useCheckProjectCreator = (
  project: any,
  isSuccess: boolean,
  isError: boolean,
  disableHook = false
) => {
  const userId = useAppSelector((state) => state.auth.user?._id);
  const navigate = useNavigate();

  useEffect(() => {
    // if (!project) return;
    if (disableHook) return;

    if (isSuccess && project?.initiator !== userId) {
      message.error("You are not the project creator!");
      navigate(-1);
    }

    if (isError) {
      message.error("Something wrong while fetching project");
      navigate(-1);
    }

    // if (project?.initiator !== userInfo?._id) {
    //   message.error("You are not the project creator!");
    //   navigate("/");
    // }
  }, [disableHook, isError, isSuccess, navigate, project?.initiator, userId]);
};
