import { Avatar } from "antd";
import PrimaryButton from "components/base/primaryButton";
import { Input, Rate } from "antd";
import FlexBox from "components/base/Flexbox";
import styled from "styled-components";
export const Container = styled.div`
  max-width: 1170px;
  margin: auto;
  color: #080f6c;
  margin-bottom:30px;
  .ant-checkbox-wrapper {
  margin-left:185px;
    span {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: rgba(8, 15, 108, 0.82);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 1.5rem;
  padding: 1.5rem 1.5rem 2.5625rem 1.5rem;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  -webkit-letter-spacing: -0.01em;
  -moz-letter-spacing: -0.01em;
  -ms-letter-spacing: -0.01em;
  letter-spacing: -0.01em;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledRate = styled(Rate)``;

export const RateGroup = styled.div`
  width: 300px;
  margin-bottom: 20px;
`;

export const RateTitle = styled.div`
  font-size: 16px;
  color: #080f6c;
`;

export const StyledTextArea = styled(Input.TextArea)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 6.125rem !important;
  padding: 0.625rem;
  margin-top: 8px;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const ContentTitle = styled.div`
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: rgba(8, 15, 108, 0.82);
  margin-left:185px;
`;

export const RateContainer = styled(FlexBox)`
  flex-wrap: wrap;
`;

export const PostButton = styled(PrimaryButton)`
  margin: 20px 0 0 auto;
`;

export const ErrorText = styled.div`
  color: red;
`;

export const SliderContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  & > div {
    /* margin-right: 20px; */
    /* width: 100px; */
  }
  .ant-avatar img {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    object-fit: cover;
  }
`;

export const SliderWrapper = styled.div`
  background: rgba(156, 138, 193, 0.09);
  /* opacity: 0.5; */
  border-radius: 8px;
  padding: 10px 20px 20px;
  .ant-slider-dot {
    display: none;
  }
  .ant-slider-mark-text {
    color: rgba(41, 46, 115, 0.42);
    margin-top: 10px;
  }
  .ant-slider-rail {
    background: rgba(41, 46, 115, 0.42);
  }
  .ant-slider-handle {
    width: 26px;
    height: 26px;
    top: -2px;
    background: #7e00fd;
    border: none;
  }
  .ant-slider-track {
    background: #7e00fd !important;
    box-shadow: none;
  }
  .ant-slider-mark {
    top: 20px;
  }
`;
