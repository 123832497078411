import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  getAllCourseCategoriesAPI,
  getCourseByIdAPI,
  getCourseCategoriesAPI,
  getListCourseForCreatorAPI,
} from "./request";

export const useGetListCourseForCreator = (
  option?: UseQueryOptions<any, AxiosError>
) => {
  return useQuery<any, AxiosError>(
    ["get-courses-for-creators"],
    () => getListCourseForCreatorAPI(),
    {
      refetchOnWindowFocus: false,
      ...option,
    }
  );
};

export const useGetCourseDetailForGuestAndLearner = (
  courseId: string,
  option?: UseQueryOptions<any, AxiosError>
) => {
  return useQuery<any, AxiosError>(
    ["get-course-detail-for-guest-learner", courseId],
    () => getCourseByIdAPI({ courseId }),
    {
      refetchOnWindowFocus: true,
      ...option,
    }
  );
};

export const useGetCourseCategories = (
  option?: UseQueryOptions<any, AxiosError>
) => {
  return useQuery<any, AxiosError>(
    ["get-course-categories"],
    () => getCourseCategoriesAPI(),
    {
      refetchOnWindowFocus: false,
      ...option,
    }
  );
};

export const useGetAllCourseCategories = (
  option?: UseQueryOptions<any, AxiosError>
) => {
  return useQuery<any, AxiosError>(
    ["get-all-course-categories"],
    () => getAllCourseCategoriesAPI(),
    {
      refetchOnWindowFocus: false,
      ...option,
    }
  );
};
