import { Button, Form, Input, Tabs } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  padding-top: 10.25rem;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  padding-bottom: 11.375rem;
  font-family: "DM Sans";
  font-style: normal;
  display: flex;
  justify-content: center;
  column-gap: 5.8125rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img``;

export const SignUpFormContainer = styled.div`
  box-sizing: border-box;
  width: 29rem;
  height: fit-content;
  background: #ffffff;
  border: 0.0625rem solid rgba(156, 138, 193, 0.16);
  box-shadow: 0.9375rem 2.1875rem 2.5rem rgba(8, 15, 108, 0.05);
  padding: 3.75rem 3.5rem 3.75rem 3.5rem;
`;

export const FormTitle = styled.div`
  height: 1.875rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-bottom: 2rem;
`;

export const ModalFormItem = styled(Form.Item)`
  margin: 0;
`;

export const FormTextInput = styled(Input)`
  background: #ffffff;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 2.25rem;
`;

export const FormPasswordInput = styled(Input.Password)`
  background: #ffffff;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 2.25rem;
`;

export const SignUpLabel = styled.label`
  height: 0.875rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080f6c;
`;

export const ForgotPasswordButton = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 0.5rem;
  cursor: pointer;
`;

export const SignUpLabelForgotPassword = styled.label`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080f6c;
`;

export const SignUpItemContainer = styled.div``;

export const SubmitButton = styled(Button)`
  width: 100%;
  background: radial-gradient(
    100% 445.32% at 0% 100%,
    #7e00fd 0%,
    #b93df3 42.35%,
    #a54bf4 57.95%,
    #00bffb 100%
  );
  border-radius: 0.25rem;
  height: 2.5rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

export const HasAccountContainer = styled.div`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  justify-content: center;
  column-gap: 0.25rem;
  color: #080f6c;
`;

export const ButtonLoginHere = styled.div`
  color: #7e00fd;
  cursor: pointer;
`;

export const EmailTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 1.75rem;
`;

export const TabContainner = styled(Tabs)`
  display: grid;
  gap: 1.75rem;
  .ant-tabs-nav {
    margin-bottom: 0rem;
  }
  .ant-tabs-tab {
    padding-top: 0rem;
    padding-bottom: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #7e00fd;
  }
  form {
    display: grid;
    gap: 1.25rem;
    ${SignUpItemContainer}:last-child {
      display: grid;
      gap: 1.25rem;
    }
  }
  /* form {
    display: grid;
    gap: 1.25rem;
    & > div:last-child {
      display: grid;
      gap: 1.25rem;
    }
  } */
`;
