import styled from "styled-components";
import bg from "assets/images/bg-course-detail.png";
import OutlinedButton from "components/base/outlinedButton";
import { Modal } from "antd";

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  overflow-x: hidden;
`;

export const Head = styled.div`
  background-image: url(${bg});
  background-size: cover;
  display: flex;
  justify-content: center;

  & > div {
    width: 1170px;
    padding: 48px 0px;
    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 127%;
      letter-spacing: -0.04em;
      color: #080f6c;
      margin-bottom: 20px;
      word-break: break-word;
    }
  }
  border-bottom: 1px solid rgba(115, 137, 149, 0.2);
`;

export const WrapButton = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  .bookmark {
    padding: 8px;
    width: 40px;
    height: 40px;
    background: rgba(8, 15, 108, 0.05);
    border: 1px solid rgba(8, 15, 108, 0.15);
    border-radius: 8px;
  }
  margin-bottom: 40px;
`;

export const Body = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: unset;
      .ant-tabs-nav-wrap {
        height: 66px;
        .ant-tabs-tab-btn {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
        }
      }
      background-color: inherit;
      box-shadow: rgb(97 156 253 / 20%) 0px -16px 20px -8px,
        rgb(97 156 253 / 20%) 0px 16px 20px -8px !important;
    }
    .ant-tabs-content-holder {
      .ant-tabs-tabpane-active {
        max-width: 1170px;
        padding: 30px 0px;
      }
    }
  }
`;

export const WrapSubInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivideGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  div {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: #080f6c;
      display: flex;
    }
    button {
      padding: unset;
      border: unset;
      span {
        line-height: unset;
      }
    }
  }
`;

export const OutlinedButtonStyled = styled(OutlinedButton)<{
  $isBookmark?: boolean;
}>`
  ${(p) => {
    return p.$isBookmark
      ? `svg>path {
    fill: #7e00fd;
  }`
      : `  svg>path {
    fill: unset;
  }`;
  }}
`;

export const DeleteCourseBtn = styled(OutlinedButton)`
  width: auto;
  height: auto;
  padding-block: 9px;
  border: 1px solid red;
  color: red;

  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    color: red;
    border-color: red;
  }
`;

export const ConfirmDeleteModal = styled(Modal)``;

export const TimeRemain = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const TagTimeRemain = styled.div`
  padding: 0.1875rem 0.5rem;
  border-radius: 1.25rem;
  font-weight: 400;
  font-size: 16px;
  background: rgba(156, 138, 193, 0.16);
  border: 0.0625rem solid #7e00fd;
  .ant-statistic-content {
    font-size: inherit;
  }
`;
