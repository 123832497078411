import { WORK_STATUS } from "api/projectDetail";
import { ChevronDown, ChevronUp } from "assets/icons/collapse.icon";
import { AllWorkTaskProps } from "pages/ProjectDetail/ProjectDetail.props";
import {
  EmptyText,
  WorktaskList,
} from "pages/ProjectDetail/ProjectDetail.style";
import Worktask from "pages/Worktasks/Worktask";
import { FC } from "react";
import { WorkTaskCollapse, WorktaskWrapper } from "./allWorktask.style";

const { Panel } = WorkTaskCollapse;

const AllWorktasks: FC<AllWorkTaskProps> = ({ worktasksByStatus, project }) => {
  const getPanelTitlePerStatus = (status: WORK_STATUS) => {
    switch (status) {
      case WORK_STATUS.OPEN:
        return "Open packages";

      case WORK_STATUS.INPROGRESS:
        return "In progress";

      case WORK_STATUS.DELIVERED:
        return "Delivered";

      case WORK_STATUS.SUBMITTED:
        return "Submitted";

      case WORK_STATUS.COMPLETED:
        return "Completed";

      default:
        return status;
    }
  };

  if (worktasksByStatus?.length === 0) {
    return (
      <WorktaskWrapper>
        <EmptyText>No worktask added</EmptyText>
      </WorktaskWrapper>
    );
  }

  // console.log("project", project);

  return (
    <WorkTaskCollapse
      expandIconPosition="end"
      ghost
      defaultActiveKey={worktasksByStatus?.map((el) => el.workStatus)}
      expandIcon={(panelProps) => {
        if (panelProps.isActive) {
          return <ChevronUp />;
        }

        return <ChevronDown />;
      }}
    >
      {worktasksByStatus?.map((wtGroup) => {
        return (
          <Panel
            key={wtGroup?.workStatus as string}
            header={
              <>
                {getPanelTitlePerStatus(wtGroup?.workStatus)} (
                {wtGroup?.countPackages})
              </>
            }
          >
            <WorktaskList>
              {wtGroup?.packages?.length ? (
                wtGroup.packages.map((pkg) => (
                  <Worktask
                    worktaskOtherInfo={wtGroup}
                    data={pkg}
                    category={project?.category?.name || ""}
                    totalBudget={project?.totalBudget || 0}
                    key={pkg._id}
                    projectId={project?._id}
                    hideButton={false}
                    projectDecimal={project?.decimal}
                    projectExpectedUsd={project?.expectedUSD}
                    blockchainNetwork={project?.blockchainNetwork}
                  />
                ))
              ) : (
                <EmptyText>No worktask added</EmptyText>
              )}
            </WorktaskList>
          </Panel>
        );
      })}
      {/* {worktaskCategories.map((cat) => {
        return (
          <Panel key={cat} header={cat}>
            <WorktaskList>
              {worktasks?.length ? (
                worktasks.map((w, i) => (
                  <Worktask
                    data={w}
                    category={project?.category?.name || ""}
                    totalBudget={project?.totalBudget || 0}
                    key={i}
                    projectId={project?._id}
                    hideButton={false}
                  />
                ))
              ) : (
                <EmptyText>No worktask added</EmptyText>
              )}
            </WorktaskList>
          </Panel>
        );
      })} */}
    </WorkTaskCollapse>
  );
};

export default AllWorktasks;
