import { Col, Select, Slider, Tag } from "antd";
import CommonModal from "components/base/commonModal";
import OutlinedButton from "components/base/outlinedButton";
import styled from "styled-components";

const { Option } = Select;

export const Container = styled.div``;

export const SectionContainer = styled.div`
  width: 100%;
  padding: 38px 0px;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  margin-bottom: 37px;
`;

export const SectionHeader = styled.div`
  padding: 0px 32px;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  > div:first-child {
    word-break: break-all;
  }

  > .ant-row {
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
  }

  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #080f6c;
  }
`;

export const SectionDesc = styled.div`
  margin-bottom: 20px;
  padding: 0px 32px;
  display: flow-root;
  word-break: break-all;
  img {
    width: 100%;
  }
`;

export const ActionBtn = styled.div`
  border-color: transparent;
  border-radius: 0;
  cursor: pointer;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  min-width: 42px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background: #7e00fd !important;
  padding-inline: 8px;
  ${ActionBtn}:first-child {
    border-radius: 4px 0 0 4px;
  }
  ${ActionBtn}:last-child {
    border-radius: 0 4px 4px 0;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const ActivityItem = styled.div`
  padding: 8px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ActionWrapper} {
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  &:hover {
    background: rgba(8, 15, 108, 0.04);
    ${ActionWrapper} {
      opacity: 1;
    }
  }
`;

export const TitleActivityHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const ActivityHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 125%;
  margin-right: 10px;
  margin-left: 10px;
  word-break: break-all;
`;

export const ActivityStatus = styled(Tag)``;

export const PrimaryBtn = styled(OutlinedButton)`
  width: min-content;
  color: #7e00fd !important;
  font-weight: 500;
  line-height: 24px;
  svg {
    margin-right: 4px;
  }
`;

export const RemoveBtn = styled(PrimaryBtn)`
  width: min-content;
  color: #7e00fd !important;
  font-weight: 500;
  line-height: 24px;
  background: rgba(255, 87, 34, 0.1) !important;
  svg {
    margin-right: 4px;
    path {
      fill: #ff5722;
    }
  }
`;

export const AddActivityBtn = styled(PrimaryBtn)`
  margin-top: 24px;
  margin-left: 32px;
`;

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddSectionContainer = styled(CenterContainer)`
  cursor: pointer;
  width: 100%;
  background: rgba(156, 138, 193, 0.06);
  border: 1px dashed rgba(8, 15, 108, 0.25);
  border-radius: 8px;
  padding: 30px;
  &:hover {
    border-color: #40a9ff;
  }
`;

export const IconCover = styled(CenterContainer)`
  background: rgba(156, 138, 193, 0.16);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  margin-right: 27px;
`;

export const LockSelect = styled(({ unlock, children, ...props }) => (
  <Select {...props}>{children}</Select>
))`
  margin-right: 27px;
  border-radius: 20px;
  font-family: "Rubik";
  letter-spacing: -0.01em;
  &:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    padding: 0px 8px;
  }
  .ant-select-selection-item {
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  ${({ unlock }) =>
    unlock
      ? `
      .ant-select-selection-item{
        color: #E94600 !important;
      }
      path {
        stroke: #E94600;
      }
      &:not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgba(233,70,0,0.12) !important;
      }
      `
      : `
      .ant-select-selection-item{
        color: #49B67A !important;
      }
      path {
        stroke: #49B67A;
      }
      &:not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgba(73, 182, 122, 0.12) !important;
      }
    `}
`;

export const LockOption = styled(Option)``;

export const ExpSlider = styled(Slider)`
  width: 100px;
  margin-right: 10px;
  .ant-slider-rail {
    height: 2px;
  }
  .ant-slider-track {
    height: 2px;
    background-color: #7e00fd;
  }
  .ant-slider-handle {
    width: 12px;
    height: 12px;
    background-color: #7e00fd;
    border: none;
  }
  &:hover {
    .ant-slider-track {
      background-color: rgb(126 0 253 / 50%);
    }
  }
`;

export const ModalCustom = styled(CommonModal)`
  .ant-modal-header {
    padding: 24px 32px !important;
    border-radius: 0.5rem;
    border-bottom: unset;
    .ant-modal-title {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 115%;
      align-items: center;
      letter-spacing: -0.04em;
      color: #080f6c;
    }
  }
  .ant-modal-body {
    padding: 0px 32px 32px 32px !important;
  }
`;

export const BackButton = styled.div`
  cursor: pointer;
  display: inline-block;
`;

export const ColStyled = styled(Col)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ActivityList = styled.div``;
