import { FC } from "react";

export const UnplugIcon: FC = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.8008 15.0016C44.4008 16.6016 44.4008 19.2016 42.8008 20.6016L37.2008 26.2016L21.6008 10.6016L27.2008 5.00156C28.8008 3.40156 31.4008 3.40156 32.8008 5.00156L36.4008 8.60156L42.4008 2.60156L45.2008 5.40156L39.2008 11.4016L42.8008 15.0016ZM31.2008 26.6016L28.4008 23.8016L22.8008 29.4016L18.6008 25.2016L24.2008 19.6016L21.4008 16.8016L15.8008 22.4016L12.8008 19.6016L7.20078 25.2016C5.60078 26.8016 5.60078 29.4016 7.20078 30.8016L10.8008 34.4016L2.80078 42.4016L5.60078 45.2016L13.6008 37.2016L17.2008 40.8016C18.8008 42.4016 21.4008 42.4016 22.8008 40.8016L28.4008 35.2016L25.6008 32.4016L31.2008 26.6016Z"
        fill="#7E00FD"
      />
      <rect
        x="15"
        y="3.75781"
        width="6"
        height="6"
        transform="rotate(45 15 3.75781)"
        fill="#292E73"
        fillOpacity="0.42"
      />
      <rect
        x="32.2422"
        y="37"
        width="6"
        height="6"
        rx="3"
        transform="rotate(45 32.2422 37)"
        fill="#292E73"
        fillOpacity="0.42"
      />
    </svg>
  );
};
