import { FC, useEffect } from "react";
import {
  Heading,
  Container,
  Title,
  CourseContainer,
} from "./createCourse.style";
import { ICreateCourseProps } from "./createCourse.props";
import Steps from "components/base/steps";
import { Divider } from "antd";
import BasicInformation from "./BasicInformation";
import { BuildProjectBannerIcon } from "assets/images/buildProjectBanner.icon";
import CourseCustomisation from "./CourseCustomisation";
import { COURSE_STEPS } from "utils/course";
import PrizeAndAwardSetup from "./Prize&AwardSetup";
import { useAppSelector } from "redux/hooks";
import CourseFinalisation from "./CourseFinalisation";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeCourseData } from "redux/slices";

const CreateCoursePage: FC<ICreateCourseProps> = () => {
  const { step } = useAppSelector((state) => state.course);

  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location.pathname.includes("/course/")) {
      dispatch(removeCourseData());
    }
  }, [location.pathname, dispatch]);

  const stepRender = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return <BasicInformation />;

      case 1:
        return <CourseCustomisation />;

      case 2:
        return <PrizeAndAwardSetup />;

      case 3:
        return <CourseFinalisation />;

      default:
        return null;
    }
  };

  return (
    <CourseContainer>
      <Heading>
        <Title>{!!id ? "Edit" : "Create"} a Course</Title>
        <BuildProjectBannerIcon />
      </Heading>
      <Container>
        <Steps
          key={step}
          current={step}
          items={COURSE_STEPS}
          labelPlacement="vertical"
        />
        <Divider type="horizontal" />
        <div>{stepRender(step)}</div>
      </Container>
    </CourseContainer>
  );
};

export default CreateCoursePage;
