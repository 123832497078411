import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const storageToken = localStorage.getItem("token");
const storageTokenParsed = storageToken;

const storageUser = localStorage.getItem("user");
const storageUserParsed = storageUser ? JSON.parse(storageUser) : null;

interface IAuth {
  token: string | null;
  user: any;
  socket?: any;
}

const initialState: IAuth = {
  token: storageTokenParsed,
  user: storageUserParsed,
  socket: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<IAuth>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setSocket: (state, action: PayloadAction<any>) => {
      state.socket = action.payload.socket;
    },
  },
});

export const { setAuthData, setUserInfo, setSocket } = authSlice.actions;

export default authSlice.reducer;
