import styled from "styled-components";
import { THEME } from "utils/theme";

export const ContainerSkill = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${THEME.MAX_WITH};
  margin: auto;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  background: rgba(156, 138, 193, 0.04);
  margin-bottom: 1.5rem;
  padding: 1.375rem 1.5rem 1.125rem 1.5rem;
`;

export const TitleBlock = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const Title = styled.div`
  color: #080f6c;
  font: 700 1.5rem/125% "DM Sans" !important;
  letter-spacing: -0.04em;
`;

export const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
`;

export const Item = styled.div`
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  /* height: 3rem; */
  padding: 0.8125rem 0rem 0.75rem 1.25rem;
  background: none;
  display: flex;
  align-items: flex-start;
`;

export const PropertyName = styled.div`
  width: 8.5rem;
  color: #292e73;
  font: 400 1.125rem/125% "Rubik" !important;
  opacity: 0.82;
  letter-spacing: -0.04em;
  /* height: 24px; */
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const ExperienceBlock = styled.div`
  // height: 1.5rem;
  display: flex;
  border: 0.0625rem solid #ff5722;
  border-radius: 1.25rem;
  color: #ff5722;
  align-items: center;
  column-gap: 0.5rem;
  padding: 4px 8px;
`;

export const ExperienceValue = styled.div`
  font: 400 0.875rem/110% "Rubik" !important;
  letter-spacing: -0.01em;
`;

export const SpecialtiesBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.8rem;
`;

export const SpecialtiesValue = styled.div`
  display: flex;
  border: 0.0625rem solid rgba(41, 46, 115, 0.42);
  border-radius: 1.25rem;
  color: rgba(41, 46, 115, 0.42);
  align-items: center;
  font: 400 1rem/100% "Rubik" !important;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;

  > div {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ExpertAtBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.8rem;
`;

export const ExpertAtValue = styled.div<{ bg: string | undefined }>`
  background: ${(p) => p.bg || "rgba(126, 0, 253, 1)"};
  height: 1.5rem;
  display: flex;
  border-radius: 2.5rem;
  align-items: center;
  font: 400 1rem/100% "Rubik" !important;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  color: white;
`;

export const LevelBlock = styled(ExpertAtBlock)`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.8rem;
`;

export const LevelValue = styled.div`
  font: 400 1rem/100% "Rubik" !important;
  letter-spacing: -0.01em;
  border-radius: 2.5rem;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  border: 0.0625rem solid #e94600;
  color: #e94600;
`;
