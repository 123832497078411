import { useQuery } from "@tanstack/react-query";
import { Col, Form, message, Row } from "antd";
import { getDataMastersAPI } from "api/users";
import { FC, useState } from "react";
import { requiredRules, stringRequired, urlRules } from "utils/validatorRules";
import {
  StyledInput,
  StyledSelect,
  StyledOption,
  BtnContainer,
} from "./createToken.style";
import { ICreateTokenProps } from "./createToken.props";
import { IBlockchainNetwork, ITokenForm } from "api/course";
import type { RuleObject } from "antd/lib/form";
import { getERC20NameAndSymbol, handleMessageErrorSC } from "web3/contract";
import { isAddress, parseUnits } from "ethers/lib/utils";
import PrimaryButton from "components/base/primaryButton";
import { useEthers } from "@usedapp/core";
import { useAppSelector } from "redux/hooks";
import { createTokenSC } from "web3/learnToEarnContract";
import { BlockchainExplorers, BlockchainNetworks } from "utils/constant";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import { scrollToFirstErrorSmooth } from "utils/scrollToSection";

export const TOKEN_NAME_NOT_FOUND = "Token name not found";
export const TOKEN_SYMBOL_NOT_FOUND = "Token symbol not found";

const CreateToken: FC<ICreateTokenProps> = ({
  inModal,
  form,
  onClose,
  isCreateProject = false,
}) => {
  const {
    account,
    chainId: currentChain,
    activateBrowserWallet,
    switchNetwork,
  } = useEthers();
  const [tokenForm] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const walletAddress: string = useAppSelector(
    (state) => state.auth.user?.walletAddress
  );
  const { connectedWalletName } = useAppSelector((state) => state.modal);

  const { data: dataNetworks } = useQuery(
    ["getDataNetworks"],
    () => getDataMastersAPI({ type: "Blockchain Networks" }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  const blockchainNetworksSelectd: IBlockchainNetwork[] =
    dataNetworks?.responseData ? dataNetworks.responseData.values : [];

  const validateNameAndSymbol: (
    _: RuleObject,
    value: string
  ) => Promise<void> = async (_, value) => {
    if (isAddress(value)) {
      try {
        const { name, symbol } = await getERC20NameAndSymbol(value);

        if (name) {
          form.setFields([
            {
              name: "tokenName",
              value: name,
              errors: [],
            },
            {
              name: "tokenSymbol",
              value: symbol,
              errors: [],
            },
          ]);
        } else {
          form.setFields([
            {
              name: "tokenName",
              value: TOKEN_NAME_NOT_FOUND,
              errors: [TOKEN_NAME_NOT_FOUND],
            },
            {
              name: "tokenSymbol",
              value: TOKEN_SYMBOL_NOT_FOUND,
              errors: [TOKEN_SYMBOL_NOT_FOUND],
            },
          ]);
        }
      } catch (error) {
        form.setFields([
          {
            name: "tokenName",
            value: TOKEN_NAME_NOT_FOUND,
            errors: [TOKEN_NAME_NOT_FOUND],
          },
          {
            name: "tokenSymbol",
            value: TOKEN_SYMBOL_NOT_FOUND,
            errors: [TOKEN_SYMBOL_NOT_FOUND],
          },
        ]);
      }

      return Promise.resolve();
    }

    return Promise.reject("Not a valid token contract");
  };

  const handleCreateToken = async (values: ITokenForm) => {
    setLoading(true);
    if (!account) {
      setLoading(false);
      message.error("Please connect to wallet");
      return;
    }
    if (walletAddress !== account) {
      setLoading(false);
      message.error("You are connecting to a different wallet");
      return;
    }

    const { newChainId, selectedExplorer } = getChainId(
      values.blockchainNetwork
    );

    if (newChainId === -1) {
      return;
    }

    try {
      await handleSwitchNetwork(newChainId);

      let { event } = await createTokenSC({
        totalSupply: parseUnits(values.totalSupply.toString(), 18),
        name: values.tokenName,
        symbol: values.tokenSymbol,
      });
      const contractAddress = event[0]?.token;
      form.setFieldsValue({
        tokenContract: contractAddress,
        blockchainNetwork: values.blockchainNetwork,
        isExistingToken: isCreateProject ? 1 : true,
        tokenName: values.tokenName,
        tokenSymbol: values.tokenSymbol,
      });

      if (isCreateProject) {
        form.setFieldsValue({
          tokenExplorer: selectedExplorer,
        });
      }

      setLoading(false);
      if (onClose) {
        onClose();
      }
    } catch (err: any) {
      setLoading(false);

      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const getChainId = (value: unknown) => {
    const selectedNetwork = blockchainNetworksSelectd.find(
      (nw) => nw?._id === value
    );
    if (selectedNetwork) {
      const newChainId = BlockchainNetworks[
        selectedNetwork.name as string
      ] as number;
      const selectedExplorer =
        BlockchainExplorers[selectedNetwork?.name as string];

      return { newChainId, selectedExplorer };
    }

    return { newChainId: -1, selectedExplorer: "" };
  };

  const handleChangeNetwork = async (value: unknown) => {
    const { newChainId } = getChainId(value);

    if (newChainId === -1) return;

    try {
      if (!currentChain) {
        activateBrowserWallet({ type: connectedWalletName });
      }

      if (currentChain !== newChainId) {
        await switchNetwork(newChainId);
      }
    } catch (error) {
      console.error("err", error);
    }
  };

  const tokenFormItems = (
    <>
      <Col span={12}>
        <Form.Item
          label="Blockchain Network"
          name="blockchainNetwork"
          rules={[requiredRules]}
        >
          <StyledSelect
            onChange={handleChangeNetwork}
            placeholder="Blockchain Network"
          >
            {blockchainNetworksSelectd.map((network) => (
              <StyledOption value={network?._id} key={network?._id}>
                {network?.name}
              </StyledOption>
            ))}
          </StyledSelect>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Token Name" name="tokenName" rules={[requiredRules]}>
          <StyledInput placeholder="Token Name" readOnly={!inModal} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Token Symbol"
          name="tokenSymbol"
          rules={[requiredRules]}
        >
          <StyledInput placeholder="Token Symbol" readOnly={!inModal} />
        </Form.Item>
      </Col>

      {inModal ? (
        <Col span={12}>
          <Form.Item
            label="Total Supply"
            name="totalSupply"
            rules={[requiredRules]}
          >
            <StyledInput placeholder="Input the total supply" />
          </Form.Item>
        </Col>
      ) : (
        <>
          <Col span={12}>
            <Form.Item
              label="Token Contract"
              name="tokenContract"
              rules={[
                stringRequired,
                () => {
                  return {
                    validator: validateNameAndSymbol,
                  };
                },
              ]}
            >
              <StyledInput placeholder="Input the address contract" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Token Explorer"
              name="tokenExplorer"
              rules={[requiredRules, urlRules]}
            >
              <StyledInput placeholder="Token Explorer" />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );

  return !inModal ? (
    tokenFormItems
  ) : (
    <Form
      layout="vertical"
      form={tokenForm}
      onFinish={handleCreateToken}
      scrollToFirstError={scrollToFirstErrorSmooth}
      initialValues={{
        kind: "TOKEN",
        duration: "custom",
      }}
    >
      <Row gutter={[12, 0]}>{tokenFormItems}</Row>
      <BtnContainer>
        <PrimaryButton htmlType="submit" loading={loading}>
          Create Token
        </PrimaryButton>
      </BtnContainer>
    </Form>
  );
};

export default CreateToken;
