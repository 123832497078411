import type { ColumnsType, TableProps } from "antd/lib/table";
import { FC, useState } from "react";
import {
  ICourseForLearner,
  IListCourseForLearnerProps,
} from "./ListCourseForLearner.props";
import {
  BannerFilter,
  BannerFilterContent,
  BaseModalCustom,
  ButtonView,
  Container,
  Heading,
  ImageWrap,
  ReasonContent,
  TabContent,
  TableCustom,
  TabPaneCustom,
  TabsCustom,
} from "./listCourseForLearner.style";
import { Checkbox, Input, message, Image, Radio } from "antd";
import { SearchIcon } from "assets/icons/search.icon";
import { useQuery } from "@tanstack/react-query";
import { getCoursesManagementForLearnerAPI } from "api/course/request";
import {
  ASSIGNMENT_STATUS,
  LESSON_ASSIGNMENT_CATEGORY,
  SortEnum,
} from "api/course";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  BlockchainExplorers,
  ChainIdToBlockChainName,
  ROUTER_CONSTANTS,
} from "../../utils/constant";
import { InfoIcon } from "assets/icons/i.icon";
import { Label } from "pages/ListAssignmentForCreator/TabContent/tabContent.style";
import { validateImage } from "utils/regex";
import {
  CourseDescription,
  PollWrap,
} from "pages/courseDetailNew/path/path.style";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { WrapFileAndInput } from "pages/ListAssignmentForCreator/listAssignmentForCreator.style";

const initParam = {
  sortByProcess: SortEnum.NONE,
  status: [],
  search: "",
  page: 1,
  page_size: 10,
};

interface DataResponse {
  data: ICourseForLearner[];
  total: number;
}

const ListCourseForLearnerPage: FC<IListCourseForLearnerProps> = () => {
  const [params, setParams] = useState(initParam);

  const { data: result } = useQuery(
    ["get-blog-list", params],
    () => getCoursesManagementForLearnerAPI(params),
    {
      select: (data) => data.responseData as DataResponse,
      refetchOnWindowFocus: true,
    }
  );

  const [selectedCourseName, setSelectedCourseName] =
    useState<ICourseForLearner>();

  const columns: ColumnsType<any> = [
    {
      title: (
        <>
          <h1>Course Name </h1>
        </>
      ),
      dataIndex: ["course", "title"],
      render: (value, record: ICourseForLearner) => (
        <Link
          to={ROUTER_CONSTANTS.COURSE.COURSE_DETAIL.replace(
            ":id",
            record.course._id!
          )}
        >
          {value}
        </Link>
      ),
    },
    {
      title: <h1>Process </h1>,
      dataIndex: ["process"],
      render: (value) => (value * 100)?.toFixed(2) + "%",
      sorter: true,
      key: "processColumn",
    },
    {
      title: <h1>Awarded</h1>,
      render: (value, record: ICourseForLearner) => (
        <Checkbox checked={record.hasClaimedReward}></Checkbox>
      ),
    },
    {
      title: <h1>Approved </h1>,
      render: (value, record: ICourseForLearner) => (
        <Checkbox
          checked={
            record.learnedActivities.length > 0
              ? record.learnedActivities.length === record.activity_count &&
                record.learnedActivities.every(
                  (item) =>
                    item.assignments.status === ASSIGNMENT_STATUS.APPROVED
                )
              : false
          }
        ></Checkbox>
      ),
      filters: [
        { text: "Approved", value: "APPROVED" },
        { text: "Waiting", value: "WAITING" },
      ],
      key: "approveColumn",
    },
    {
      title: <h1>Total Assignments </h1>,
      dataIndex: ["activity_count"],
    },
    {
      title: <h1>Status</h1>,
      width: "15%",
      render: (_, record: ICourseForLearner) => (
        <>
          {(() => {
            const result: any = record.learnedActivities.reduce(
              (acc: any, item) => {
                let plus: string = "";
                switch (item.assignments.status) {
                  case "APPROVED":
                    plus = "a";
                    break;
                  case "REJECTED":
                    plus = "r";
                    break;
                  case "WAITING":
                    plus = "w";
                    break;
                  default:
                    break;
                }
                acc[plus] = acc[plus] + 1;
                return acc;
              },
              { a: 0, r: 0, w: 0 }
            );
            return `A-R-W : ${result.a}-${result.r}-${result.w}`;
          })()}
        </>
      ),
    },
    {
      title: <h1>View Reward</h1>,
      render: (_, record: ICourseForLearner) => {
        const handleShowTransaction = () => {
          if (!record.txHash) {
            message.error("You can only see reward after being approved");
            return;
          }

          const selectedExplorer =
            BlockchainExplorers[
              ChainIdToBlockChainName[record.course.chainId as number]
            ];

          window.open(`${selectedExplorer}${record.txHash}`, "_blank");
        };

        return (
          <ButtonView onClick={handleShowTransaction}>
            <EyeOutlined />
          </ButtonView>
        );
      },
    },
    {
      title: <h1>View Rejected</h1>,
      render: (_, record: ICourseForLearner) => {
        const haveRejected = record.learnedActivities.some(
          (val) => val.assignments.status === "REJECTED"
        );

        const handleSelectCourse = () => {
          setSelectedCourseName(record);
        };

        return haveRejected ? (
          <ButtonView onClick={handleSelectCourse}>
            <EyeOutlined />
          </ButtonView>
        ) : null;
      },
    },
  ];
  const onChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    setParams((prev: any) => {
      return {
        ...prev,
        page:
          JSON.stringify(params.status) !==
          JSON.stringify(filters.approveColumn ?? [])
            ? 1
            : pagination.current,
        sortByProcess:
          (sorter as any).columnKey === "processColumn"
            ? (sorter as any).order === "ascend"
              ? SortEnum.ASD
              : (sorter as any).order === "descend"
              ? SortEnum.DESC
              : SortEnum.NONE
            : SortEnum.NONE,
        status: filters.approveColumn ?? [],
      };
    });
  };

  return (
    <>
      <Heading>
        <h1>Learner Assignments List</h1>
      </Heading>
      <Container>
        <BannerFilter>
          <BannerFilterContent>
            <Input
              className="search"
              type="search"
              placeholder="Search"
              suffix={<SearchIcon />}
              onPressEnter={(e: any) =>
                setParams((prev) => {
                  return {
                    ...prev,
                    search: e.target.value,
                    page: 1,
                  };
                })
              }
            />
          </BannerFilterContent>
        </BannerFilter>
        <TableCustom
          columns={columns}
          dataSource={result?.data?.filter(
            (row) => row.course.isDeleted === false
          )}
          rowKey={(rec) => (rec as ICourseForLearner)?._id}
          pagination={{
            current: params.page,
            pageSize: params.page_size,
            total: result?.total,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {
              setParams((prev) => {
                return { ...prev, page_size: size };
              });
            },
            pageSizeOptions: ["1", "5", "10", "20"],
          }}
          onChange={onChange}
        />
        <BaseModalCustom
          closable
          visible={!!selectedCourseName}
          destroyOnClose
          onCancel={() => {
            setSelectedCourseName(undefined);
          }}
        >
          <TabsCustom
            defaultActiveKey={selectedCourseName?.learnedActivities[0]._id}
            username="Your "
          >
            {selectedCourseName?.learnedActivities
              .filter((acti) => acti.assignments.status === "REJECTED")
              .map((val) => {
                return (
                  <TabPaneCustom tab={val?.activity?.title} key={val._id}>
                    <TabContent>
                      <ImageWrap>
                        <CourseDescription className="ck-content">
                          <p> Question: </p>
                          <div className="question">
                            {parse(
                              DOMPurify.sanitize(
                                val?.activity?.assignments?.question ?? ""
                              )
                            )}
                          </div>
                        </CourseDescription>
                        {val?.activity?.assignments?.category ===
                          LESSON_ASSIGNMENT_CATEGORY.FILE && (
                          <WrapFileAndInput>
                            {validateImage.test(val.assignments.fileUrl) && (
                              <Image src={val.assignments.fileUrl} alt="" />
                            )}
                            {!validateImage.test(val.assignments.fileUrl) && (
                              <a href={val.assignments.fileUrl}>
                                {val.assignments.fileUrl.split("/").pop()}
                              </a>
                            )}
                          </WrapFileAndInput>
                        )}
                        {val?.activity?.assignments?.category ===
                          LESSON_ASSIGNMENT_CATEGORY.TEXT && (
                          <WrapFileAndInput>
                            <Input.TextArea value={val?.assignments?.content} />
                          </WrapFileAndInput>
                        )}
                        {val?.activity?.assignments?.category ===
                          LESSON_ASSIGNMENT_CATEGORY.POLL && (
                          <PollWrap className="options">
                            {val?.activity?.assignments.multiValid && (
                              <Checkbox.Group
                                style={{ width: "100%" }}
                                defaultValue={val?.assignments.pollOptions}
                                disabled
                              >
                                {val?.activity?.assignments.options.map(
                                  (item) => (
                                    <Checkbox
                                      key={item._id}
                                      className="item"
                                      value={item._id}
                                    >
                                      {item.value}
                                    </Checkbox>
                                  )
                                )}
                              </Checkbox.Group>
                            )}

                            {!val?.activity?.assignments.multiValid && (
                              <Radio.Group
                                style={{ width: "100%" }}
                                defaultValue={val.assignments.pollOptions[0]}
                                disabled
                              >
                                {val?.activity?.assignments.options.map(
                                  (item) => (
                                    <Radio
                                      key={item._id}
                                      className="item"
                                      value={item._id}
                                    >
                                      {item.value}
                                    </Radio>
                                  )
                                )}
                              </Radio.Group>
                            )}
                          </PollWrap>
                        )}
                      </ImageWrap>
                      <ReasonContent>
                        <Label>
                          <h1>Explain</h1>
                          <InfoIcon />
                        </Label>
                        <Input.TextArea
                          value={val?.assignments?.reasonRejected}
                          disabled
                        />
                      </ReasonContent>
                    </TabContent>
                  </TabPaneCustom>
                );
              })}
          </TabsCustom>
        </BaseModalCustom>
      </Container>
    </>
  );
};

export default ListCourseForLearnerPage;
