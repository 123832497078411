import { FC, useState, useEffect } from "react";
import { Form, message, Avatar } from "antd";
import {
  AddObserverWrapper,
  AddObserverBtn,
  SelectedObserverWrapper,
  ObserverSelect,
} from "./observers.style";
import {
  getDataMastersAPI,
  getListUserWillBecomeObserverAPI,
  IMaster,
  IUserInfo,
} from "api/users";
import { StyledFormItem } from "pages/Bounty/Bounty.style";
import { AddPersonIcon } from "assets/icons/addPerson.icon";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  DeleteObserverWrapper,
  ObserverInfoWrapper,
  ObserverName,
  ObserverRole,
} from "pages/DeliverWork/components/Observers/Observers.style";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import { CrossMarkIcon } from "assets/icons/crossMark.icon";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  addStepSubmit,
  setCompleteStepFour,
  setWorktaskObservers,
} from "redux/slices";
import { IWorktaskObserverProps } from "pages/Bounty/Bounty.props";

export type ExtractedUserInfo = Pick<
  IUserInfo,
  | "_id"
  | "emailId"
  | "profilePicture"
  | "walletAddress"
  | "fullName"
  | "username"
>;

const WorktaskObserver: FC<IWorktaskObserverProps> = ({ worktaskDetails }) => {
  const dispatch = useAppDispatch();
  const curObservers = useAppSelector((state) => state.addWorktask.observers);
  const [selectedObservers, setSelectedObservers] = useState<
    (
      | Partial<
          ExtractedUserInfo & {
            role?: IMaster;
          }
        >
      | undefined
    )[]
  >(curObservers || []);
  const param = useParams();
  const [form] = Form.useForm();
  const { data: userRes } = useQuery(
    [`getListUserWillBecomeObserverAPI-${param?.projectId}`],
    () =>
      getListUserWillBecomeObserverAPI(
        param?.projectId as string,
        param?.packageId as string
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: masterObserverRes } = useQuery(
    ["getMasterObserverRoles"],
    () => getDataMastersAPI({ type: "Observer Roles" }),
    {
      refetchOnWindowFocus: false,
    }
  );
  // const { data: observerListRes } = useQuery(
  //   ["getObserverList"],
  //   () =>
  //     getObserverListAPI({
  //       projectId: param.projectId || "",
  //       packageId: packageId || "",
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled: !!packageId,
  //   }
  // );

  // will remove
  useEffect(() => {
    dispatch(
      addStepSubmit({
        stepSubmit: () => {
          //   console.log("observer step 4 completed");
          dispatch(setCompleteStepFour());
          return true;
        },
      })
    );
  }, [dispatch]);

  // console.log("curObservers", curObservers);

  const usersList: ExtractedUserInfo[] =
    (userRes?.responseData as ExtractedUserInfo[]) || [];
  const observerRoles: IMaster[] =
    masterObserverRes?.responseData?.values || [];
  // const observerList: (Omit<ExtractedUserInfo, "walletAddress"> & {
  //   packages: string[];
  //   role?: string;
  // })[] = observerListRes?.responseData?.records || [];

  const handleAddObserver = (values: { userId?: string; role?: string }) => {
    if (!values?.userId || !values?.role) return;

    const isAlreadyAdded = selectedObservers?.find(
      (item) => item?._id === values?.userId
    );

    if (isAlreadyAdded) {
      message.error("Observer already added");
      form.resetFields();
      return;
    }

    const isAlreadyCollab = worktaskDetails?.collaborators?.some(
      (collab) => collab === values.userId
    );

    if (isAlreadyCollab) {
      message.error("Can not add collaborator to become observer");
      form.resetFields();
      return;
    }

    const foundUser = usersList?.find((item) => item?._id === values?.userId);
    const foundRole = observerRoles?.find((item) => item?._id === values?.role);
    const newSelectedObservers = [
      ...selectedObservers,
      { ...foundUser, role: foundRole },
    ];

    setSelectedObservers(newSelectedObservers);
    dispatch(setWorktaskObservers(newSelectedObservers));
    form.resetFields();
  };

  const handleDeleteObserver = (selectedId?: string) => {
    const newSelectedObservers = selectedObservers?.filter(
      (item) => item?._id !== selectedId
    );

    setSelectedObservers(newSelectedObservers);
    dispatch(setWorktaskObservers(newSelectedObservers));
  };

  return (
    <AddObserverWrapper>
      <Form form={form} onFinish={handleAddObserver} layout="inline">
        <StyledFormItem width="auto" label="Observer's Name" name="userId">
          <ObserverSelect
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
          >
            {(usersList ?? []).map((user) => (
              <ObserverSelect.Option key={user?._id} value={user?._id}>
                {user?.username || user?.emailId.split("@").shift()}
              </ObserverSelect.Option>
            ))}
          </ObserverSelect>
        </StyledFormItem>
        <StyledFormItem width="auto" label="Observer's Role" name="role">
          <ObserverSelect placeholder="Select Role">
            {observerRoles &&
              observerRoles?.map((item) => (
                <ObserverSelect.Option key={item?._id} value={item?._id}>
                  {item?.name}
                </ObserverSelect.Option>
              ))}
          </ObserverSelect>
        </StyledFormItem>
        <StyledFormItem width="auto">
          <AddObserverBtn htmlType="submit">
            <AddPersonIcon />
            Add Observer
          </AddObserverBtn>
        </StyledFormItem>
      </Form>
      {selectedObservers.length > 0 && (
        <SelectedObserverWrapper>
          {selectedObservers.map((item) => {
            return (
              <ObserverInfoWrapper key={item?._id}>
                <Avatar shape="square" src={item?.profilePicture} size={52} />
                <CommonFlexColumn space="14px">
                  <CommonFlexRow space="14px">
                    <ObserverName>
                      {item?.username ||
                        (item?.emailId ?? "").split("@").shift()}
                    </ObserverName>
                    <DeleteObserverWrapper
                      onClick={() => handleDeleteObserver(item?._id)}
                    >
                      <CrossMarkIcon />
                    </DeleteObserverWrapper>
                  </CommonFlexRow>
                  <ObserverRole>{item?.role?.name}</ObserverRole>
                </CommonFlexColumn>
              </ObserverInfoWrapper>
            );
          })}
        </SelectedObserverWrapper>
      )}
    </AddObserverWrapper>
  );
};

export default WorktaskObserver;
