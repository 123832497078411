import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, message, UploadProps, Avatar } from "antd";
import {
  getDataMastersAPI,
  getOrganizationsAPI,
  getProjectDetails,
  IMaster,
  // ICreateProject,
  IOrganization,
  IProject,
  // IProject,
  IUpdateProject,
  updateProjectAPI,
} from "api/users";
import { CreateProjectIcon } from "assets/images/createProject";
import PrimaryButton from "components/base/primaryButton";
import { ButtonText } from "pages/HomePage/components/Banner/banner.style";
// import { AvatarImage } from "pages/ProfileUser/components/BuilderProfile/builderProfile.style";
import { FC, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { IUpdateProjectProps } from "./updateProject.props";
import { Heading, CancelButton, GroupButton } from "./updateProject.style";
import { AxiosError } from "axios";
import { acceptImage, validateImage } from "utils/regex";
import {
  CoverImage,
  CreateProjectTitle,
  SocialFormGrid,
  StyledFormItem,
  StyledInput,
  StyledTextArea,
  BigLabel,
  LabelDescription,
  Container,
  StyledUpload,
  ProjectTitle,
  StyledInputNumber,
  StyledSelect,
} from "pages/CreateProject/createProject.style";
import {
  AllFormFieldWrapper,
  CommonLabel,
  CreateOrgWrapper,
  ExtraSection,
  LabelText,
  OrganizationSection,
  OrganizationSelect,
  OrgCreateBtn,
  OrgInner,
  OrgTypeGradient,
  OrgTypeWrapper,
  ProjectThumbWrapper,
  RegisterDesc,
  SelectedOrgWrapper,
  SelectedSponsorTag,
  SelectWrapper,
  TokenSelectText,
  TypeDescription,
  TypeHint,
  TypeSection,
  UploadWrapper,
} from "pages/CreateProject/ProjectStepOne/projectStepOne.style";
import {
  floatRules,
  maxLength_60,
  numberRules,
  requiredRules,
} from "utils/validatorRules";
import { UploadCloudIcon } from "assets/icons/upload.icon";
import { SelectArrowDown } from "assets/icons/common/selectArrowDown.icon";
import { DeleteSponsorTagIcon } from "assets/icons/delete.icon";
import { ROUTER_CONSTANTS } from "utils/constant";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import { CreateProjectArrowRightIcon } from "assets/icons/arrowRight.icon";
import type { SelectProps } from "antd";
import type { RcFile } from "antd/lib/upload";
import { getBase64 } from "utils/getBase64";
import { getUrlFromS3 } from "utils/getUrlFromS3";
import { useCheckProjectCreator } from "hooks";

const UpdateProjectPage: FC<IUpdateProjectProps> = () => {
  const [form] = Form.useForm();
  const [isTokenExist, setIsTokenExist] = useState<number>(0);
  // const [fileNameAvatar, setFileNameAvatar] = useState<string>();
  const [fileNameCover, setFileNameCover] = useState<string>();
  const [loading, setLoading] = useState(false);
  // const [project, setProject] = useState<IProject>();
  const [categoriesMasterData, setCategoriesMasterData] = useState([] as any);
  // const [categorySelect, setCategorySelect] = useState("");
  // const [blockchainSelect, setBlockchainSelectSelect] = useState("");
  // const [blockchainNetworksMasterData, setBlockchainNetworksMasterData] =
  //   useState([] as any);
  // const [fileAvatar, setFileAvatar] = useState<any>(null);
  // const [fileCover, setFileCover] = useState<RcFile | null>(null);
  const [listOrgSelected, setListOrgSelected] = useState<IMaster[] | undefined>(
    []
  );
  const [blockchainNetworksSelect, setBlockchainNetworksSelect] = useState<
    Partial<IMaster>[]
  >([]);
  const [categoriesSelect, setCategoriesSelect] = useState<
    (IMaster | undefined)[]
  >([]);
  const [orgType, setOrgType] = useState<IMaster>();

  const param = useParams();
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  const fileRef = useRef<RcFile | null>(null);
  const spinAvatarRef = useRef<any>(false);
  // const spinCoverRef = useRef<any>(false);
  const userInfo = useAppSelector((state) => state.auth.user);
  // const userId: string = useAppSelector((state) => state.auth.user?._id);

  const { data: listOrgResponse } = useQuery(
    ["getOrganizations"],
    () => getOrganizationsAPI(),
    {
      onSuccess: (res) => {
        const { responseData, responseCode } = res;
        if (responseCode === 200) {
          form.setFieldValue(
            "currentOrganization",
            responseData?.find(
              (org: any) => org?._id === userInfo?.organization
            )?.name
          );
          // setListOrg(responseData);
        }
      },
      enabled: userInfo?._id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    ["getDataCategory"],
    () => getDataMastersAPI({ type: "Project Categories" }),
    {
      onSuccess: (res) => {
        const { responseData, responseCode } = res;
        if (responseCode === 200) {
          setCategoriesMasterData(responseData?.values);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const {
    isSuccess,
    isError,
    data: projRes,
  } = useQuery(
    ["getProjectDetails", categoriesMasterData],
    () => getProjectDetails(param.id || ""),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          const projectDetail: IProject | undefined = res.responseData;
          // setProject(projectDetail);
          // setCategorySelect(projectDetail?.category?.name);
          setIsTokenExist(projectDetail?.isExistingToken ? 1 : 2);
          // setBlockchainSelectSelect(projectDetail?.blockchainNetwork);
          // if (!fileAvatar) {
          //   setFileNameAvatar(projectDetail?.projectCoverImage);
          // }
          if (!fileRef.current) {
            setFileNameCover(projectDetail?.coverImage);
          }
          const currBlockchainNetwork: Partial<IMaster> = {
            _id: projectDetail?.blockchainNetwork?._id,
            name: projectDetail?.blockchainNetwork?.name,
          };
          const filteredOrgDetail = projectDetail?.organizationDetails?.filter(
            (org) => org?._id !== userInfo?.organization
          );

          setListOrgSelected(
            filteredOrgDetail?.map((org) => ({
              _id: org?._id,
              name: org?.name,
            }))
          );
          setBlockchainNetworksSelect([currBlockchainNetwork]);
          setCategoriesSelect([projectDetail?.category]);
          setOrgType(projectDetail?.category);

          form.setFieldsValue({
            projectName: projectDetail?.projectName,
            tagline: projectDetail?.tagline,
            coverImage: projectDetail?.coverImage,
            organizations: filteredOrgDetail?.map((org) => ({
              value: org?._id,
              label: org?.name,
            })),
            isExistingToken: projectDetail?.isExistingToken ? 1 : 2,
            blockchainNetwork: projectDetail?.blockchainNetwork?._id,
            totalBudget: projectDetail?.totalBudget,
            tokenExplorer: projectDetail?.tokenExplorer,
            tokenName: projectDetail?.tokenName,
            tokenSymbol: projectDetail?.tokenSymbol,
            tokenContract: projectDetail?.tokenContract,
            category: projectDetail?.category?._id,
          });
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      enabled: categoriesMasterData.length > 0,
      refetchOnWindowFocus: false,
    }
  );

  const project: IProject | undefined = projRes?.responseData;

  const { mutate: updateProject } = useMutation(updateProjectAPI, {
    onSuccess: (res: any) => {
      const { responseCode, responseMessage } = res;

      if (responseCode === 200) {
        setLoading(false);
        message.success("Edit Project Successfully");
        navigate(`/project-detail/${project?._id}`);
      } else {
        setLoading(false);
        message.error(responseMessage);
      }
    },
    onError: (err: any) => {
      setLoading(false);
      console.log({ err });
    },
  });

  // const { mutate: postUploads } = useMutation(postUploadsAPI, {
  //   onSuccess: async (rsUpload: any) => {
  //     dispatch(setPageLoading(true));
  //     await axios
  //       .put(rsUpload.signedRequest, fileRef.current)
  //       .then((rs: any) => {
  //         if (spinAvatarRef.current) {
  //           // setFileNameAvatar(rsUpload.url);
  //           form.setFieldValue("profilePicture", rsUpload.url);
  //           spinAvatarRef.current = false;
  //           dispatch(setPageLoading(false));
  //         } else if (spinCoverRef.current) {
  //           // setFileNameCover(rsUpload.url);
  //           form.setFieldValue("coverImage", rsUpload.url);
  //           spinCoverRef.current = false;
  //           dispatch(setPageLoading(false));
  //         }
  //       })
  //       .catch((err: AxiosError) => {
  //         message.error("Error uploading: " + err.message);
  //       });
  //   },
  //   onError: (err: AxiosError) => {
  //     message.error(err.message);
  //   },
  // });

  const listOrg: IOrganization[] | undefined = useMemo(() => {
    return listOrgResponse?.responseData;
  }, [listOrgResponse?.responseData]);
  const filteredOrg = listOrg?.filter(
    (org) => org?._id !== userInfo?.organization
  );
  const currentOrg = listOrg?.find(
    (org) => org?._id === userInfo?.organization
  );
  const orgOptions: SelectProps["options"] = filteredOrg?.map((org) => {
    return {
      label: org?.name,
      value: org?._id,
    };
  });
  const listOrgWithAvatar = listOrgSelected?.map((item) => {
    const foundMappingWithCurrSelected = filteredOrg?.find(
      (filterItem) => filterItem?._id === item?._id
    );

    if (!!foundMappingWithCurrSelected) {
      return {
        ...item,
        avatar: foundMappingWithCurrSelected?.avatar,
      };
    }

    return item;
  });

  const upLoadCoverImage: UploadProps = {
    accept: acceptImage,
    multiple: false,  
    showUploadList: false,
    beforeUpload: async (file) => {
      const isImage = validateImage.test(file.name);
      if (!isImage) {
        message.error("You can only upload Image!");
        return false;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        spinAvatarRef.current = true;
        fileRef.current = file;
        setFileNameCover(await getBase64(file));

        // const fileTag = file.name.split(".").pop();
        // spinCoverRef.current = true;
        // fileRef.current = file;
        // postUploads({
        //   fileName: file.name,
        //   fileType: `image/${fileTag}`,
        // });
        // setFileCover(file);

        return false;
      }
    },
  };

  // const { mutate: postUploads } = useMutation(postUploadsAPI, {
  //   onSuccess: async (rsUpload: any) => {
  //     dispatch(setPageLoading(true));
  //     await axios
  //       .put(rsUpload.signedRequest, fileRef.current)
  //       .then((rs: any) => {
  //         if (spinAvatarRef.current) {
  //           setFileNameAvatar(rsUpload.url);
  //           dispatch(setPageLoading(false));
  //           spinAvatarRef.current = false;
  //         } else if (spinCoverRef.current) {
  //           setFileNameCover(rsUpload.url);
  //           spinCoverRef.current = false;
  //           dispatch(setPageLoading(false));
  //         }
  //       })
  //       .catch((err: AxiosError) => {
  //         message.error("Error uploading: " + err.message);
  //       });
  //   },
  //   onError: (err: AxiosError) => {
  //     message.error(err.message);
  //   },
  // });

  const handleSubmit = async (values: IUpdateProject) => {
    // console.log("val", values);
    try {
      setLoading(true);
      const urlFromS3 = fileRef.current
        ? await getUrlFromS3(fileRef.current)
        : form.getFieldValue("coverImage");
      // const rsUpload: any = await postUploadsAPI({
      //   fileName: fileRef.current?.name as string,
      //   fileType: fileRef.current?.type as string,
      // });

      // await axios.put(rsUpload.signedRequest, fileRef.current);

      updateProject({
        projectName: values.projectName,
        tagline: values.tagline,
        coverImage: urlFromS3,
        organizations: [
          ...values.organizations?.map((org) => org?.value),
          currentOrg?._id,
        ],
        projectId: param.id as string,
      });
    } catch (err: any) {
      message.error("Can't update project");
      setLoading(false);
    }
  };

  // const handleSelectCategory = (id: string) => {
  //   const category = categoriesMasterData.find(
  //     (category: any) => category._id === id
  //   );
  //   form.setFieldValue(
  //     "category",
  //     id
  //       ? id
  //       : categoriesMasterData.find(
  //           (category: any) => category.name === categorySelect
  //         )._id
  //   );
  //   setCategorySelect(category.name);
  // };

  // const handleSelectBlockchainNetwork = (idNetwork: string | unknown) => {
  //   const blockchainNetwork = blockchainNetworksMasterData.find(
  //     (network: any) => network._id === idNetwork
  //   );
  //   form.setFieldValue(
  //     "blockchainNetwork",
  //     idNetwork
  //       ? idNetwork
  //       : blockchainNetworksMasterData.find(
  //           (network: any) => network.name === blockchainSelect
  //         )._id
  //   );
  //   setBlockchainSelectSelect(blockchainNetwork.name);
  // };

  // const handleSelectIsToken = (value: number | unknown) => {
  //   form.setFieldValue("isExistingToken", value === 1);
  // };

  const handleDeleteSponsorTag = (selectedOrg: IMaster) => {
    const newSelectedOrg = listOrgSelected?.filter(
      (org) => org?._id !== selectedOrg?._id
    );

    setListOrgSelected(newSelectedOrg);
    form.setFieldValue(
      "organizations",
      newSelectedOrg?.map((org) => ({ value: org?._id, label: org?.name }))
    );
  };

  const getCategoryContent = (selectedName?: string) => {
    switch (selectedName) {
      case "Seed":
        return (
          <TypeDescription>
            SEED projects are ideal for early stage Web3 communities that are
            seeking flexible teams of builders. SEED projects are offering high
            rewards but in illiquid forms of ownership such as governance
            tokens. Our collaborators act as co-founders to kickstart a
            decentralized community.
          </TypeDescription>
        );

      case "Open Bounties":
        return (
          <TypeDescription>
            Open Bounties are supporting builders to work individually or
            collaboratively to deliver value in Web3 communities. Rewards are
            paid either in stablecoins or liquid tokens.
          </TypeDescription>
        );

      default:
        return null;
    }
  };

  useCheckProjectCreator(project, isSuccess, isError);

  return (
    <>
      <Heading>
        <CreateProjectTitle>Edit project</CreateProjectTitle>
      </Heading>
      <Container>
        {/* <Left>
          <AvatarContainer>
            <AvatarUpload {...upLoadPropsAva} listType="picture-card">
              {fileNameAvatar ? (
                <AvatarImage height="100%" width="100%" src={fileNameAvatar} />
              ) : (
                <div>
                  <CameraIcon />
                </div>
              )}
            </AvatarUpload>
            <BigLabel>Profile Picture</BigLabel>
            <LabelDescription>
              Maximum size: 240x240 .jpg .png | Max size: 5MB
            </LabelDescription>
          </AvatarContainer>
        </Left> */}

        <ProjectTitle>Project Description</ProjectTitle>

        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          scrollToFirstError={true}
          onValuesChange={(changed) => {
            // console.log("changed", changed?.organizations);
            if ("organizations" in changed) {
              setListOrgSelected(
                (
                  changed?.organizations as NonNullable<SelectProps["options"]>
                ).map((org) => {
                  return {
                    _id: org?.value as string,
                    name: org?.label as string,
                  };
                })
              );
            }
          }}
        >
          <AllFormFieldWrapper>
            <StyledFormItem
              label="Your Project Name"
              name="projectName"
              rules={[requiredRules, maxLength_60]}
            >
              <StyledInput placeholder="What should we put at the top of your page?" />
            </StyledFormItem>
            <StyledFormItem
              label="Project description"
              name="tagline"
              rules={[requiredRules]}
            >
              <StyledTextArea placeholder="Tell us what your project is. Keep it short and sweet!" />
            </StyledFormItem>
            <StyledFormItem
            // name="coverImage"
            >
              <ProjectThumbWrapper space="16px">
                <StyledUpload
                  {...upLoadCoverImage}
                  listType="picture-card"
                  maxCount={1}
                  className={
                    fileNameCover ? "upload-image noBorder" : "upload-image"
                  }
                >
                  {fileNameCover ? (
                    <CoverImage src={fileNameCover} />
                  ) : (
                    <UploadWrapper>
                      <UploadCloudIcon />
                    </UploadWrapper>
                  )}
                </StyledUpload>
                <div>
                  <BigLabel>Project thumbnail</BigLabel>
                  <LabelDescription>
                    Recommended size: 376x128 .jpg .png | Max size: 5MB
                  </LabelDescription>
                </div>
              </ProjectThumbWrapper>
            </StyledFormItem>

            <OrganizationSection>
              <SelectWrapper>
                <StyledFormItem
                  label="Sponsoring Organization"
                  name="organizations"
                >
                  <OrganizationSelect
                    mode="multiple"
                    // onChange={handleSelectSponsors}
                    suffixIcon={<SelectArrowDown />}
                    placeholder="Please select a sponsoring organisation from the list"
                    options={orgOptions}
                    labelInValue
                  />
                </StyledFormItem>
                {listOrgWithAvatar && listOrgWithAvatar?.length > 0 && (
                  <SelectedOrgWrapper space="8px">
                    {listOrgWithAvatar.map((selectedOrg) => {
                      return (
                        <SelectedSponsorTag
                          space="8px"
                          onClick={() => handleDeleteSponsorTag(selectedOrg)}
                          key={selectedOrg?._id}
                        >
                          {"avatar" in selectedOrg && (
                            <Avatar src={selectedOrg?.avatar} size={24} />
                          )}{" "}
                          {selectedOrg?.name} <DeleteSponsorTagIcon />
                        </SelectedSponsorTag>
                      );
                    })}
                  </SelectedOrgWrapper>
                )}
              </SelectWrapper>
              <CreateOrgWrapper space="10px">
                {currentOrg?._id ? (
                  <>
                    <RegisterDesc>Your organization:</RegisterDesc>
                    <StyledFormItem
                      style={{
                        width: "100%",
                        marginBottom: "0",
                      }}
                      name="currentOrganization"
                    >
                      <StyledInput disabled />
                    </StyledFormItem>
                  </>
                ) : (
                  <>
                    <RegisterDesc>
                      If you haven't registered an organisation, please create
                      one here.
                    </RegisterDesc>
                    <OrgCreateBtn
                      onClick={() =>
                        navigate(ROUTER_CONSTANTS.ORGANIZATION.CREATE)
                      }
                    >
                      Create New Organisation +
                    </OrgCreateBtn>
                  </>
                )}
              </CreateOrgWrapper>
            </OrganizationSection>

            <TypeSection>
              <LabelText>Type</LabelText>
              <TypeHint>
                Selecting the right project type will help users discover your
                bounty
              </TypeHint>
              <OrgTypeWrapper space="15px">
                {categoriesSelect.map((category: any) => (
                  <OrgTypeGradient
                    key={category?._id}
                    $active={category?._id === orgType?._id}
                    // onClick={() => {
                    //   setOrgType(category);
                    //   form.setFieldValue("category", category?._id);
                    // }}
                  >
                    <OrgInner>{category?.name}</OrgInner>
                  </OrgTypeGradient>
                ))}
              </OrgTypeWrapper>
              {getCategoryContent(orgType?.name)}
            </TypeSection>
            <SocialFormGrid>
              <StyledFormItem
                label="Total Project's Budget"
                name="totalBudget"
                rules={[requiredRules, numberRules]}
              >
                <StyledInputNumber
                  type="number"
                  placeholder="Input the total budget for your BUILD campaign"
                  disabled
                />
              </StyledFormItem>

              <StyledFormItem label="Token">
                <StyledFormItem
                  noStyle
                  name="isExistingToken"
                  rules={[requiredRules]}
                >
                  <StyledSelect
                    suffixIcon={<SelectArrowDown />}
                    placeholder="Is there any existing token?"
                    disabled
                    onChange={(value) => {
                      setIsTokenExist(typeof value === "number" ? value : 0);
                      // handleSelectIsToken(value);
                    }}
                  >
                    <StyledSelect.Option value={1}>Yes</StyledSelect.Option>
                    <StyledSelect.Option value={2}>No</StyledSelect.Option>
                  </StyledSelect>
                </StyledFormItem>
                {isTokenExist === 2 && (
                  <TokenSelectText>
                    If your organisation hasn't issued a token yet that is
                    currently trading on decentralized or centralized markets,
                    please submit this form to receive support from RBKD DAO.
                  </TokenSelectText>
                )}
              </StyledFormItem>
              {isTokenExist === 1 && (
                <>
                  <StyledFormItem label="Name of the currency" name="tokenName">
                    <StyledInput
                      placeholder={
                        form.getFieldValue("tokenName") || "Token Name"
                      }
                      disabled
                    />
                  </StyledFormItem>

                  {orgType?.name === "Seed" && (
                    <StyledFormItem label="Token Contract" name="tokenContract">
                      <StyledInput
                        placeholder="Input the token contract"
                        disabled
                      />
                    </StyledFormItem>
                  )}

                  <StyledFormItem
                    label="Token Explorer"
                    name="tokenExplorer"
                    rules={[
                      // requiredRules,
                      {
                        type: "url",
                        message: "Not a valid url!",
                      },
                    ]}
                  >
                    <StyledInput placeholder="Token Explorer" disabled />
                  </StyledFormItem>

                  {orgType?.name === "Seed" && (
                    <StyledFormItem
                      label="Blockchain Network"
                      name="blockchainNetwork"
                      rules={[requiredRules]}
                    >
                      <StyledSelect
                        placeholder="Blockchain Network"
                        disabled
                        // onChange={handleSelectBlockchainNetwork}
                      >
                        {blockchainNetworksSelect.map((network: any) => (
                          <StyledSelect.Option
                            key={network?._id}
                            value={network?._id}
                          >
                            {network?.name}
                          </StyledSelect.Option>
                        ))}
                      </StyledSelect>
                    </StyledFormItem>
                  )}

                  <StyledFormItem label="Token symbol" name="tokenSymbol">
                    <StyledInput
                      disabled
                      placeholder={
                        form.getFieldValue("tokenSymbol") || "Token Symbol"
                      }
                    />
                  </StyledFormItem>

                  {orgType?.name === "Open Bounties" && (
                    <>
                      <StyledFormItem
                        label="Token Contract"
                        name="tokenContract"
                      >
                        <StyledInput
                          placeholder="Input the token contract"
                          disabled
                        />
                      </StyledFormItem>
                    </>
                  )}
                </>
              )}
              <StyledFormItem label="Peg to USD">
                <ExtraSection space="14px">
                  <CommonFlexColumn space="10px">
                    <CommonLabel>Amount of Tokens</CommonLabel>
                    <StyledInput disabled value={1} />
                  </CommonFlexColumn>
                  <CreateProjectArrowRightIcon />
                  <CommonFlexColumn space="10px">
                    <CommonLabel>Expected USD Value</CommonLabel>
                    <StyledFormItem
                      name="expectedUSD"
                      noStyle
                      rules={[floatRules]}
                    >
                      <StyledInputNumber disabled type="number" />
                    </StyledFormItem>
                  </CommonFlexColumn>
                </ExtraSection>
              </StyledFormItem>
            </SocialFormGrid>
          </AllFormFieldWrapper>
          <GroupButton>
            <PrimaryButton htmlType="submit" loading={loading}>
              <ButtonText>
                <CreateProjectIcon /> Edit Project
              </ButtonText>
            </PrimaryButton>
            <CancelButton
              onClick={() => navigate(`/project-detail/${param.id}`)}
            >
              Cancel
            </CancelButton>
          </GroupButton>
        </Form>
      </Container>
    </>
  );
};

export default UpdateProjectPage;
