import { Form, Input } from 'antd'
import PrimaryButton from 'components/base/primaryButton'
import styled from 'styled-components'

export const ModalTitle = styled.p`
  font-size: 1.5rem;
  color: #080f6c;
  font-family: 'DM Sans';
  font-weight: 700;
  line-height: 125%;
  font-style: 'Bold';
  text-align: center;
  letter-spacing: -0.04em;
  margin-bottom: 0.75rem;
`

export const TextDecs = styled.div`
  text-align: center;
  color: #292e73;
  opacity: 0.82;
  font-size: 0.875rem;
  vertical-align: top;
  letter-spacing: -0.01em;
  font-family: 'DM Sans';
  line-height: 135%;
  padding: 0rem 1.25rem;
`

export const AskIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`

export const LabelInput = styled.p`
  margin-top: 2.25rem;
  text-align: left;
  color: #080f6c;
  font-size: 0.875rem;
  letter-spacing: -0.01em;
  font-family: 'DM Sans';
  line-height: 100%;
`

export const ModalFormItem = styled(Form.Item)`
  margin: 0;
`

export const InputEmail = styled(Input)`
  background: #ffffff;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 2.25rem;
`

export const ErrorEmail = styled.p`
  margin-top: 0.75rem;
  text-align: left;
  color: #e94600;
  font-size: 0.875rem;
  letter-spacing: -0.01em;
  font-family: 'DM Sans';
  line-height: 100%;
`

export const SubmitButton = styled(PrimaryButton)`
  margin-top: 2rem;
  span {
    text-align: center;
    font-size: 0.875rem;
    letter-spacing: -0.01em;
    font-family: 'DM Sans';
    line-height: 100%;
    font-weight: 500;
    font-style: 'Medium';
  }
`

export const BackToLogin = styled.div`
  margin-top: 1.3125rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #080f6c;
  cursor: pointer;
  text-align: center;
`
