import { FC, memo } from "react";
import { ISocialLinkProps } from "./socialLink.props";
import {
  Container,
  ContainerItem,
  ItemSocial,
  LinksSocial,
  Title,
  TitleBlock,
} from "./socialLink.style";
import { SocialLinkIcon } from "assets/images/profile-preview/index";
import {
  // FacebookIcon,
  LinkedInIcon,
  PersonalBlogIcon,
  TwitterIcon,
  WebsiteIcon,
} from "assets/images/profile-preview/social-icon";

const SocialLink: FC<ISocialLinkProps> = (props) => {
  const { viewOtherUser, profile } = props;
  const currUser = viewOtherUser
    ? profile?.username || profile?.emailId.split("@")[0]
    : `You`;

  const handleClickOnLink = (link?: string) => {
    if (!link) return;

    window.open(link, "_blank");
  };

  return (
    <Container>
      <TitleBlock>
        <SocialLinkIcon />
        <Title>Social Links</Title>
      </TitleBlock>
      <ContainerItem>
        <ItemSocial>
          <PersonalBlogIcon />
          <LinksSocial
            hasLink={!!props?.socialLink?.personalBlogURL}
            onClick={() =>
              handleClickOnLink(props?.socialLink?.personalBlogURL)
            }
          >
            {props?.socialLink?.personalBlogURL ||
              `${currUser} have no Personal Blog link`}
          </LinksSocial>
        </ItemSocial>
        <ItemSocial>
          <TwitterIcon />
          <LinksSocial
            hasLink={!!props?.socialLink?.twitterURL}
            onClick={() => handleClickOnLink(props?.socialLink?.twitterURL)}
          >
            {props?.socialLink?.twitterURL ||
              `${currUser} have no Twitter link`}
          </LinksSocial>
        </ItemSocial>
        <ItemSocial>
          <LinkedInIcon />
          <LinksSocial
            hasLink={!!props?.socialLink?.linkedIn}
            onClick={() => handleClickOnLink(props?.socialLink?.linkedIn)}
          >
            {props?.socialLink?.linkedIn || `${currUser} have no Linkedin link`}
          </LinksSocial>
        </ItemSocial>
        <ItemSocial>
          <WebsiteIcon />
          <LinksSocial
            hasLink={!!props?.socialLink?.personURL}
            onClick={() => handleClickOnLink(props?.socialLink?.personURL)}
          >
            {props?.socialLink?.personURL ||
              `${currUser} have no Personal Website link`}
          </LinksSocial>
        </ItemSocial>
      </ContainerItem>
    </Container>
  );
};

export default memo(SocialLink);
