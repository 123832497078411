interface ICommon {
  _id: string;
  title: string;
  description: string;
  hash?: string;
}

export interface IBlogSection extends ICommon {
  subSections: ICommon[];
}

export enum StatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
export interface IBlog extends ICommon {
  categories: { _id: string; name: string }[]; // data from master table
  status: StatusEnum;
  coverImage: string;
  estTime: number; // by minutes
  createdBy: string; // admin object id
  sections: IBlogSection[];
  createdAt: number;
  updatedAt: number;
  loading?: boolean;
}

export interface IGetBlogListRes {
  data: IBlog[];
  total: number;
}

export interface IGetBlogListReq {
  search: string;
  page: number;
  pageSize: number;
  categories: string[]; // ObjectId[]
  sort: number;
  pageSizeAtFirstPage: number;
}
