export const TwitterIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8047_8448)">
      <path
        d="M16 3.03773C15.4116 3.29844 14.7795 3.4746 14.115 3.55422C14.7929 3.14836 15.313 2.50427 15.5582 1.73761C14.924 2.11391 14.2214 2.3873 13.4738 2.53387C12.8748 1.89689 12.0222 1.49805 11.0779 1.49805C9.26556 1.49805 7.79565 2.96796 7.79565 4.78175C7.79574 5.03312 7.82411 5.28368 7.8802 5.52871C5.15107 5.39127 2.73337 4.08413 1.11407 2.09841C0.832235 2.58462 0.670129 3.14836 0.670129 3.74872C0.670129 4.88742 1.25009 5.89299 2.13018 6.4814C1.60893 6.46479 1.09919 6.32395 0.643359 6.07057V6.11284C0.643359 7.70324 1.77434 9.03013 3.27667 9.33174C2.99477 9.4078 2.70404 9.44618 2.41205 9.44588C2.20471 9.44605 1.99781 9.42671 1.79409 9.3881C2.21194 10.6917 3.42396 11.6416 4.86076 11.667C3.73681 12.5478 2.32116 13.0727 0.783586 13.0727C0.518635 13.0727 0.257199 13.0572 0 13.0269C1.45303 13.9592 3.17803 14.5018 5.03128 14.5018C11.0702 14.5018 14.3715 9.50012 14.3715 5.16226C14.3715 5.02134 14.368 4.87898 14.3616 4.73806C15.0029 4.27438 15.5596 3.69728 15.9986 3.03983L16 3.03773Z"
        fill="#1DA1F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_8047_8448">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
