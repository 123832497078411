import { message } from "antd";
import axios from "axios";
import { setAuthData } from "redux/slices";
import { store } from "redux/store";
import { ROUTER_CONSTANTS } from "utils/constant";

declare module "axios" {
  interface AxiosResponse<T = any> {
    responseMessage: T;
    responseCode: number;
    responseData?: T;
    signedRequest: any;
    url: any;
  }
}
const baseURL = process.env.REACT_APP_API_URI;

// axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  function (config: any) {
    config.headers["Content-Type"] = "application/json";
    const token = localStorage.getItem("token");
    const tokenFromRedux = store.getState().auth.token;

    if (token) {
      config.headers["Authorization"] = token;
      if (!tokenFromRedux || tokenFromRedux !== token) {
        const user = localStorage.getItem("user");
        if (user)
          store.dispatch(setAuthData({ user: JSON.parse(user), token }));
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  // this is for old respond: not good enough for rest api
  (response) => {
    if (!response?.data) return;
    const res = response.data;
    if (res.responseCode === 401 || res.responseCode === 403) {
      message.error(res.responseMessage);
      setTimeout(() => {
        window.location.href = ROUTER_CONSTANTS.USER.LOGIN;
      }, 1000);
    }
    return response?.data;
  },
  // this is for new respond
  async function (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      message.error(error?.response?.data?.responseMessage);
      setTimeout(() => {
        window.location.href = ROUTER_CONSTANTS.USER.LOGIN;
      }, 1000);
    } else return Promise.reject(error);
  }
);

export default axiosInstance;
