import axios from "axios";
import { postUploadsAPI } from "api/users";
import type { RcFile } from "antd/lib/upload";

export const getUrlFromS3 = async (fileToGetUrl: RcFile | null) => {
  try {
    const rsUpload: any = await postUploadsAPI({
      fileName: fileToGetUrl?.name as string,
      fileType: fileToGetUrl?.type as string,
    });
    /*
    rsUpload type:
    - file_name: string;
    - signedRequest: string;
    - url: string;
    */

    await axios.put(rsUpload.signedRequest, fileToGetUrl);

    return rsUpload.url as string;
  } catch (error) {
    throw error;
  }
};
