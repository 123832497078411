import { FC } from "react";

export const PencilIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="16" height="16" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_11921_7455" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_11921_7455"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAADyElEQVR4nO3cv2vWQByA8efyVkEEFVQUHZylCK7iIioIrk4qrk4OgmIdfN+mraCCgoMugpPQoQ5OToqjgziJ/gMKgj9AOwnW5ByaF6O+b3uX3OXN5b4PFNr0cnn5NORN8paAJEmSJEmSJElSS1OTfgFtKEUf1OSXNBwFdgKfgecJye0U9dbFNqKHHpBdAm4BvRG//gVcnKd3v+52kroThNyALAVuMxoZYAq41ye7Vndb0e7RBfKs6XgN/QV616tuL0poW+RhdbCjg66KPKwqdlTQdZGHVcGOBtoV8jBb7CigXSOXumB66hf16Z2D7vbR0yYDo4Cep5cCcx6mnkrIL5sMjAIa/GFrOGYyLhpo8Ia9y2RQVNDgBfuzyaDooMEttoIXJuM6Az0gS4vTOKMcYWeK5I7JwE5Al86TZ5vE1nAlRb0xGRv8BcuYi5Fb8/SuWswxA9y03HQ6T8/4jxQ09DpXfD6xrZAhYGjDy2of2NbIECi05b0Ll9iVkCFA6Io3iFxgV0aGwKBr3oWrg10LGQKCdnSrc644pbPZJjbrjCsIaMf3k632bFe1HtrTTfvGsVsN7fGTEWgYu7XQnpGHNYbdSuiGkIc1gt066IaRh3nHbtXduwkhA/zwvYHW7NETRK59MWJSK6C7jgwtgI4BGSYMHQsyTBA6JmSYEHRsyDAB6BiRoWHoWJGhQeiYkaEh6NiRoQFoQV7NK7Qg/8kbtCD/nRdoQf4/59CCPDqn0II8PmfQgrx2TqAFef1qQwuyWeOeU2HUefSGzahlRbbE6pNa9gNb3Ly0NZsLCRkcvxn20YcU+UuXc47I6v/n2pLTT8E/wWtWH4/jqyCRwTH0A9QKsOxyzlJpqMjg5/86fEAH9cY3Kh/Q3x3PF9wb36g8QKtvDicL+nBRzjm0BlfQwR8uyjmHTtDvHUzTicNFOefQivxpzSmCPYVbK6sLlj76gCI/tR5EysqRnOSkgr05apsi3wZqE7C1+No+ZtVOIoMl9CzZDQ1XqQnSR08XV5Dly/XOIoPVoUMrDaeLH6yeIvBvC6h3wMPSok4jgwV0CoeBfaVFtbA1+lXxbeeRwQJak58dsXh2QGb7+AUAFPoLHTpPXi+jY/R59Ibd5B+BHWOGWO+VKXpjivpps07IGe3Re8hOMB4ZKuzZMSGDIXSOOmMwbKbqYSSGTI/Rxw3HCfaYpgzHrRiM+aDgSU7yuM4L6mqm0I+AmRHLvypYykkWF+AlKO3wtXUqI+iEZJCTA5wr1nmWoBc/0ntWfKoiSZIkSZIkSZIkhdpv/6JnjD4F4CEAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};
