export const ExperiencesIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#9C8AC1" fillOpacity="0.16" />
    <path
      d="M32 18H28V16C28 14.89 27.11 14 26 14H22C20.89 14 20 14.89 20 16V18H16C14.89 18 14.01 18.89 14.01 20L14 31C14 32.11 14.89 33 16 33H32C33.11 33 34 32.11 34 31V20C34 18.89 33.11 18 32 18ZM26 18H22V16H26V18Z"
      fill="#7E00FD"
    />
  </svg>
);
