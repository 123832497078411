import { DownIcon } from "assets/icons/down.icon";
import EmptyData from "components/base/emptyData";
import { ProjectList } from "pages/HomePage/homepage.style";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setFilter } from "redux/slices";
import ProjectCard from "../projectCard";
import ProjectCardItem from "../projectCardItem";
import { CardsContainer, SeeMore } from "../projectGroup/projectGroup.style";
import RecentUpdatesCard from "../recentUpdatesCard";
import { ProjectCardContainerProps } from "./projectCardContainer.props";

export const ProjectCardContainer: FC<ProjectCardContainerProps> = ({
  btnName,
  itemsShow,
  projects,
  skipType,
  mt,
  isFetching,
  totalProjectsLength,
  haveChildren = false,
}) => {
  const dispatch = useAppDispatch();

  const { seedSkip, openBountiesSkip, learnAndEarnSkip, portfolioSkip } =
    useAppSelector((state) => {
      return state.filter;
    });

  const [list, setList] = useState<any>([]);
  const [chunks, setChunks] = useState<any>([]);
  const [isLoadMore, setIsLoadMore] = useState<boolean>(true);

  function seeMore() {
    const skip = getSkip();
    if (!isLoadMore) return;

    if (typeof skip === "number" && skipType) {
      dispatch(
        setFilter({
          [skipType]: skip + 1,
        })
      );
    }
  }

  const getSkip = useCallback(() => {
    if (skipType === "seedSkip") return seedSkip;
    if (skipType === "openBountiesSkip") return openBountiesSkip;
    if (skipType === "learnAndEarnSkip") return learnAndEarnSkip;
    if (skipType === "portfolioSkip") return portfolioSkip;
  }, [learnAndEarnSkip, openBountiesSkip, portfolioSkip, seedSkip, skipType]);

  useEffect(() => {
    const skip = getSkip();
    // const newChunk = [...chunks];
    if (typeof skip !== "number") return;
    // if (limit && projects?.length < limit) {
    if (projects?.length < 2) {
      setIsLoadMore(false);
    } else setIsLoadMore(true);
    // newChunk[skip] = projects;
    setChunks((prev: any) => {
      const newChunk = [...prev];
      newChunk[skip] = projects;

      return newChunk;
    });
  }, [projects, getSkip]);

  useEffect(() => {
    if (skipType) {
      const skip = getSkip();
      if (typeof skip !== "number") return;
      const newList: any = [];
      chunks?.find((chunk: any, i: number) => {
        if (skip < i) return true;
        chunk?.forEach((p: any) => {
          newList.push(p);
        });
        return false;
      });
      setList(newList);
    } else setList(projects);
  }, [chunks, getSkip, skipType, projects]);

  const currShowedLength = useMemo(() => {
    if (skipType === "learnAndEarnSkip") return list.length;

    return chunks?.reduce((acc: number, cur: any) => {
      return acc + (cur?.length || 0);
    }, 0);
  }, [chunks, list, skipType]);

  const isShowSeeMore = useMemo(() => {
    return currShowedLength < (totalProjectsLength || 0);
  }, [currShowedLength, totalProjectsLength]);

  return (
    <>
      {haveChildren && skipType && (
        // For seed and bounty
        <>
          {isFetching ? (
            <ProjectList>
              {Array.from(Array(currShowedLength || itemsShow).keys()).map(
                (id) => (
                  <ProjectCardItem key={id} isLoading />
                )
              )}
            </ProjectList>
          ) : list?.length === 0 ? (
            <EmptyData />
          ) : (
            <ProjectList>
              {list?.map((proj: any) => (
                <ProjectCardItem key={proj?._id} project={proj} />
              ))}
            </ProjectList>
          )}
        </>
      )}
      {!haveChildren && skipType && (
        // For learn and earn
        <>
          {isFetching ? (
            <CardsContainer itemsShow={itemsShow} mt={mt}>
              {Array.from(Array(currShowedLength || itemsShow).keys()).map(
                (id) => (
                  <ProjectCard key={id} isFetching={isFetching} />
                )
              )}
            </CardsContainer>
          ) : list?.length === 0 ? (
            <EmptyData />
          ) : (
            <CardsContainer itemsShow={itemsShow} mt={mt}>
              {list?.map((project: any, i: number) => (
                <ProjectCard btnName={btnName} project={project} key={i} />
              ))}
            </CardsContainer>
          )}
        </>
      )}
      {!skipType && (
        // For recent update
        <>
          {isFetching ? (
            <ProjectList>
              {Array.from(Array(currShowedLength || itemsShow).keys()).map(
                (id) => (
                  <RecentUpdatesCard key={id} isLoading />
                )
              )}
            </ProjectList>
          ) : list?.length === 0 ? (
            <EmptyData />
          ) : (
            <ProjectList>
              {list?.map((proj: any) => (
                <RecentUpdatesCard key={proj?._id} project={proj} />
              ))}
            </ProjectList>
          )}
        </>
      )}
      {isShowSeeMore && isLoadMore && !isFetching && skipType && (
        <SeeMore onClick={seeMore}>
          See more <DownIcon fill="#fff" />
        </SeeMore>
      )}
    </>
  );
};
