export function awaitingIcon() {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33334 3.11498V1.97433C9.65557 1.78647 9.875 1.44095 9.875 1.04166V0.770842C9.875 0.621139 9.75386 0.5 9.60416 0.5H0.395842C0.246139 0.5 0.125 0.621139 0.125 0.770842V1.04168C0.125 1.44095 0.344426 1.78647 0.666658 1.97436V3.11498C0.666658 4.18827 1.12422 5.21552 1.92192 5.93334L3.10708 7L1.92192 8.06666C1.12422 8.78448 0.666658 9.81173 0.666658 10.885V12.0256C0.344426 12.2135 0.125 12.559 0.125 12.9583V13.2292C0.125 13.3789 0.246139 13.5 0.395842 13.5H9.60418C9.75389 13.5 9.87503 13.3789 9.87503 13.2292V12.9583C9.87503 12.559 9.6556 12.2135 9.33337 12.0256V10.885C9.33337 9.81173 8.8758 8.78448 8.07811 8.06666L6.89292 7L8.07808 5.93334C8.87578 5.21552 9.33334 4.18824 9.33334 3.11498ZM7.35341 5.12823L5.72099 6.59746C5.60673 6.70009 5.54166 6.84634 5.54166 7C5.54166 7.15366 5.60673 7.29994 5.72099 7.40254L7.35341 8.87177C7.9231 9.38461 8.25 10.1186 8.25 10.885V11.875H7.57278L5.21661 8.73344C5.11451 8.59697 4.88546 8.59697 4.78337 8.73344L2.42722 11.875H1.75V10.885C1.75 10.1185 2.0769 9.38459 2.64662 8.87175L4.27903 7.40252C4.39329 7.29989 4.45837 7.15364 4.45837 6.99997C4.45837 6.84631 4.39329 6.70004 4.27903 6.59743L2.64662 5.1282C2.0769 4.61539 1.75 3.88145 1.75 3.11498V2.125H8.25V3.11498C8.25 3.88145 7.9231 4.61539 7.35341 5.12823Z"
        fill="#7E00FD"
      />
    </svg>
  )
}

export function openIcon() {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.231201C4.94303 0.231201 2.17081 1.9037 0.125192 4.62028C-0.0417306 4.84284 -0.0417306 5.15378 0.125192 5.37634C2.17081 8.09619 4.94303 9.76869 8 9.76869C11.057 9.76869 13.8292 8.09619 15.8748 5.37961C16.0417 5.15705 16.0417 4.84611 15.8748 4.62355C13.8292 1.9037 11.057 0.231201 8 0.231201ZM8.21929 8.35803C6.19004 8.48568 4.51427 6.81318 4.64191 4.78065C4.74665 3.10488 6.10494 1.74659 7.78071 1.64186C9.80996 1.51421 11.4857 3.18671 11.3581 5.21923C11.2501 6.89173 9.89179 8.25002 8.21929 8.35803ZM8.11783 6.80663C7.02465 6.87537 6.1213 5.9753 6.19331 4.88212C6.24895 3.97877 6.9821 3.2489 7.88545 3.18998C8.97862 3.12125 9.88197 4.02132 9.80996 5.1145C9.75105 6.02112 9.0179 6.75099 8.11783 6.80663Z"
        fill="#F57F20"
      />
    </svg>
  )
}

export function completedIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C3.1552 0 0 3.1552 0 7C0 10.8448 3.1552 14 7 14C10.8448 14 14 10.8448 14 7C14 3.1552 10.8448 0 7 0ZM6.15286 10.1839L3.08749 7.11856L4.24747 5.95859L6.20654 7.91766L10.1395 4.34241L11.2434 5.55606L6.15286 10.1839Z"
        fill="#1CC98A"
      />
    </svg>
  )
}

export function underApprovalIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9355 0.7229V3.03894H13.2514L10.9355 0.7229Z"
        fill="#00ADFB"
      />
      <path
        d="M10.5546 3.80072C10.3442 3.80072 10.1737 3.6302 10.1737 3.41987V0.5H4.71482C4.08482 0.5 3.57227 1.01255 3.57227 1.64256V5.90021C3.69774 5.88883 3.82472 5.88271 3.95312 5.88271C5.25127 5.88271 6.41335 6.47623 7.18239 7.40612H11.3163C11.5266 7.40612 11.6971 7.57664 11.6971 7.78697C11.6971 7.99731 11.5266 8.16783 11.3163 8.16783H7.68415C7.92221 8.63236 8.07572 9.147 8.125 9.69123H11.3163C11.5266 9.69123 11.6971 9.86176 11.6971 10.0721C11.6971 10.2824 11.5266 10.4529 11.3163 10.4529H8.125C8.0111 11.7107 7.33907 12.8098 6.35904 13.4998H12.3319C12.9619 13.4998 13.4744 12.9872 13.4744 12.3572V3.80072H10.5546ZM11.3163 5.88271H5.73043C5.5201 5.88271 5.34958 5.71219 5.34958 5.50186C5.34958 5.29153 5.5201 5.12101 5.73043 5.12101H11.3163C11.5266 5.12101 11.6971 5.29153 11.6971 5.50186C11.6971 5.71219 11.5266 5.88271 11.3163 5.88271Z"
        fill="#00ADFB"
      />
      <path
        d="M3.95306 6.64429C2.06304 6.64429 0.525391 8.18194 0.525391 10.072C0.525391 11.962 2.06304 13.4996 3.95306 13.4996C5.84308 13.4996 7.38073 11.962 7.38073 10.072C7.38073 8.18194 5.84308 6.64429 3.95306 6.64429ZM4.96867 10.4528H3.95306C3.74273 10.4528 3.57221 10.2823 3.57221 10.072V8.54855C3.57221 8.33822 3.74273 8.1677 3.95306 8.1677C4.16339 8.1677 4.33391 8.33822 4.33391 8.54855V9.69111H4.96867C5.179 9.69111 5.34952 9.86163 5.34952 10.072C5.34952 10.2823 5.179 10.4528 4.96867 10.4528Z"
        fill="#00ADFB"
      />
    </svg>
  )
}

export function expiredIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1845 8.63486C10.8409 7.75691 11.2347 6.67383 11.2347 5.49229C11.2347 2.60409 8.89625 0.265625 6.00805 0.265625C3.12805 0.265625 0.789585 2.60409 0.789585 5.49229C0.789585 6.66563 1.17523 7.7487 1.83164 8.61845L0.248047 10.202L1.28189 11.2359L2.85728 9.6605C3.73523 10.3251 4.82651 10.719 6.01625 10.719C7.19779 10.719 8.28087 10.3251 9.15882 9.6687L10.726 11.2359L11.7598 10.202L10.1845 8.63486ZM6.59061 7.86357H5.35984V6.97742H6.59061V7.86357ZM6.59061 6.37024H5.35984V3.02255H6.59061V6.37024Z"
        fill="#FF6577"
      />
    </svg>
  )
}

export function CreditCardIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8065_8816)">
        <path
          d="M17.5002 3.33398H2.50016C1.57969 3.33398 0.833496 4.08018 0.833496 5.00065V15.0007C0.833496 15.9211 1.57969 16.6673 2.50016 16.6673H17.5002C18.4206 16.6673 19.1668 15.9211 19.1668 15.0007V5.00065C19.1668 4.08018 18.4206 3.33398 17.5002 3.33398Z"
          stroke="#7E00FD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.833496 8.33398H19.1668"
          stroke="#7E00FD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8065_8816">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function AwardIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8065_8810)">
        <path
          d="M9.99984 12.5007C13.2215 12.5007 15.8332 9.88898 15.8332 6.66732C15.8332 3.44566 13.2215 0.833984 9.99984 0.833984C6.77818 0.833984 4.1665 3.44566 4.1665 6.66732C4.1665 9.88898 6.77818 12.5007 9.99984 12.5007Z"
          stroke="#080F6C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.84183 11.5747L5.8335 19.1664L10.0002 16.6664L14.1668 19.1664L13.1585 11.5664"
          stroke="#080F6C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8065_8810">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
