import PrimaryButton from "components/base/primaryButton";
import styled from "styled-components";
import headingBG from "assets/images/png/createProject/heading-bg.png";
import { Avatar } from "antd";

export const CreateWortaskButton = styled(PrimaryButton)`
  /* margin-right :0; */
  /* margin-left:auto; */
`;

export const InitiateButton = styled(PrimaryButton)`
  margin-right: 0;
  margin-left: auto;
  display: block;
  margin-bottom: 20px;
`;

export const OrgHeadWrap = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: auto;
  background: transparent;

  border: solid 1px transparent; /* !importanté */
  border-style: solid;
  border-radius: 24px 24px 0px 0px;
  background-clip: padding-box; /* !importanté */
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(
      to bottom,
      rgba(8, 10, 37, 0.1),
      rgba(8, 10, 37, 0)
    );
  }
`;

export const OrgHead = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
`;
export const OrgInfoWrap = styled.div`
  padding: 40px 0px 16px 30px;
  display: flex;
`;
export const OrgAvatar = styled(Avatar)`
  width: 110px;
  height: 110px;
  border-radius: 110px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .anticon {
    svg {
      width: 50px;
      height: 50px;
    }
  }
`;
export const DefaultImage = styled.div`
  border-radius: 110px;
  width: 110px;
  height: 110px;
  background-image: url(${headingBG});
  background-size: cover;
  border: 1px solid rgba(41, 46, 115, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 40px;
    height: 40px;
  }
`;
export const OrgInfo = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;
export const OrgName = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #080f6c;
  display: block;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  & > svg {
    margin-left: 5.6704px;
  }
`;
export const OrgUrl = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  opacity: 0.8;
  margin-top: 4px;
`;
export const OrgBtnWrap = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 16px;

  & svg {
    margin-right: 4px;
  }
`;
export const OrgLinksWrap = styled.div`
  display: flex;
  padding: 0px 40px 16px 0px;
  height: inherit;
  align-items: flex-end;
  gap: 40px;
`;
export const OrgWrap = styled.div`
  height: max-content;
`;
export const OrgLinksTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  text-align: right;
  color: rgba(41, 46, 115, 0.82);
  opacity: 0.8;
  margin-bottom: 6px;
  text-align: left;
`;
export const OrgLinks = styled.div`
  display: flex;
  gap: 14px;
  padding-top: 12px;
  border-top: 1px solid rgba(156, 138, 193, 0.16);
`;
export const OrgLinksA = styled.a`
  text-decoration: none;
  border-radius: 24px;
  svg {
    height: 40px;
    width: 40px;
  }
`;

// export const ProjectsNameContainer = styled(Col)`
//   margin: 30px 0px 30px;
// `

// export const ContainerHeaderFilter = styled(ProjectsNameContainer)``
