import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Avatar, Checkbox, message, Slider } from "antd";
import { SliderMarks } from "antd/lib/slider";
import {
  getPackageCollaboratorsAPI,
  submitCollaboratorRatingAPI,
} from "api/users";
import CommonModal from "components/base/commonModal";
import FlexBox from "components/base/Flexbox";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setPageLoading } from "redux/slices";
import { ICollaboratorRatingProps } from "./CollaboratorRating.props";
import {
  Container,
  Title,
  ContentTitle,
  RateContainer,
  PostButton,
  SliderContainer,
  SliderWrapper,
} from "./CollaboratorRating.style";

const CollaboratorRating = ({
  visible,
  setVisible,
}: ICollaboratorRatingProps) => {
  const [score, setScore] = useState([] as any);
  const totalScore = useMemo(
    () => score.reduce((prev: any, curr: any) => prev + curr.rate, 0),
    [score]
  );
  const params = useParams();
  const queryClient = useQueryClient();
  const userId = useAppSelector((state) => state.auth.user?._id);
  const dispatch = useAppDispatch();

  useQuery(
    ["getPackageCollaborators"],
    () =>
      getPackageCollaboratorsAPI({
        projectId: params?.projectId || "",
        packageId: params?.packageId || "",
        collabId: params?.id || "",
      }),
    {
      onSuccess: (res) => {
        const { responseData, responseCode } = res;
        if (responseCode === 200) {
          const allColabsExceptCurrentUser = responseData?.filter(
            (item: any) => item?._id !== userId
          );

          if (allColabsExceptCurrentUser?.length !== score?.length) {
            setScore(
              allColabsExceptCurrentUser?.map((item: any) => ({
                ...item,
                rate: 0,
              }))
            );
          }
        }
      },
      enabled: visible,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: submitCollaboratorRating } = useMutation(
    submitCollaboratorRatingAPI,
    {
      onSuccess: (res) => {
        const { responseCode, responseMessage } = res;
        if (responseCode === 200) {
          message.success(responseMessage);
          queryClient.invalidateQueries([
            `getPackageDetail-${params.packageId}`,
          ]);
          setVisible();
        } else {
          message.error(responseMessage);
        }
      },
      onSettled: () => {
        dispatch(setPageLoading(false));
      },
    }
  );

  const handleRate = () => {
    if (totalScore !== 100) {
      message.error("Total must be equal 100");
      return;
    }
    const data = score.map((item: any) => ({
      user: item._id,
      rate: item.rate,
    }));
    dispatch(setPageLoading(true));
    submitCollaboratorRating({
      projectId: params.projectId || "",
      packageId: params?.packageId || "",
      params: data,
    });
  };
  const marks: SliderMarks = {
    0: 0,
    50: 50,
    100: 100,
  };

  return (
    <CommonModal
      visible={visible}
      width={678}
      closable
      padding="0.5rem 3rem"
      onCancel={setVisible}
    >
      <Container>
        <Title>Rate Collaborator</Title>
        {score.map((collab: any, index: any) => (
          <SliderContainer key={collab?._id}>
            <FlexBox gap="10px">
              <Avatar
                style={{ display: "contents" }}
                src={collab.profilePicture}
              />{" "}
              <span>{collab.fullName || collab?.emailId}</span>
            </FlexBox>
            <SliderWrapper>
              <Slider
                marks={marks}
                style={{ width: "349px" }}
                value={score[index]?.rate}
                onChange={(value) => {
                  const cloneScore = [...score];
                  cloneScore[index].rate = value;
                  setScore(cloneScore);
                }}
                
              />
            </SliderWrapper>
          </SliderContainer>
        ))}

        <RateContainer></RateContainer>
        <ContentTitle>
          Total Score:
          <span style={{ color: totalScore > 100 ? "red" : "inherit" }}>
            {" " + totalScore}
          </span>
        </ContentTitle>
        <FlexBox align="center">
          <Checkbox
            onChange={(e) => {
              if (e.target.checked)
                setScore((prevSc: any[]) =>
                  prevSc.map((item: any) => ({
                    ...item,
                    rate: Math.round(100 / prevSc.length),
                  }))
                );
            }}
          >
            Distribute Equally
          </Checkbox>
          <PostButton onClick={handleRate}>Submit</PostButton>
        </FlexBox>
      </Container>
    </CommonModal>
  );
};

export default React.memo(CollaboratorRating);
