import dayjs from "dayjs";
import { FC, memo } from "react";
import { Link } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { dateDiff } from "utils/time";
import TitleAndContext from "./titleAndContext";
import { ITransactionCard2Props } from "./transactionCard2.props";
import {
  // AvaAndContext,
  ButtonContainer,
  Container,
  // ContentLabel,
  // ImageChainNetwork,
  // Label,
  // TitleAndContextChain,
  ViewButton,
} from "./transactionCard2.style";

const TransactionCard2: FC<ITransactionCard2Props> = ({ transaction }) => {
  return (
    <Container column={6}>
      {/* <AvaAndContext>
        <TitleAndContextChain>
          <Label>{transaction.chainNetwork.name}</Label>
          <ContentLabel>{transaction.chainNetwork.startDate}</ContentLabel>
        </TitleAndContextChain>
      </AvaAndContext> */}
      <TitleAndContext
        key={"Worktask Title"}
        title={"Worktask Title"}
        context={transaction?.packages?.name}
      />
      <TitleAndContext
        key={"Collaborators"}
        title={"Collaborators"}
        context={"Me"}
      />
      <TitleAndContext
        key={"Date"}
        title={"Date"}
        context={dayjs(transaction?.packages?.endDate).format("DD MMM YYYY")}
      />
      <TitleAndContext
        key={"Days Left"}
        title={"Days Left"}
        context={dateDiff(
          transaction?.packages?.endDate,
          transaction?.packages?.startDate
        )}
      />
      <TitleAndContext
        key={"Currency"}
        title={"Currency"}
        context={transaction?.tokenName || transaction?.tokenName}
      />
      <ButtonContainer>
        <Link
          to={ROUTER_CONSTANTS.PROJECT.DETAIL.replace(":id", transaction._id)}
        >
          <ViewButton>
            <span>View Project</span>
          </ViewButton>
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default memo(TransactionCard2);
