import DOMPurify from "dompurify";
import { FC, memo } from "react";
import { IOverViewProps } from "./overView.props";
import { Container, CkContentWrapper } from "./overView.style";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";

const OverView: FC<IOverViewProps> = ({ content, isLoading }) => {
  return (
    <Container>
      <h1>Overview of course</h1>
      <CkContentWrapper className="ck-content">
        {isLoading ? (
          <Skeleton count={2} />
        ) : (
          parse(DOMPurify.sanitize(content))
        )}
      </CkContentWrapper>
    </Container>
  );
};

export default memo(OverView);
