import { Form, message } from "antd";
import {
  addEmploymentHistoryAPI,
  deleteEmploymentHistoryAPI,
  IEmploymentHistory,
  updateEmploymentHistoryAPI,
} from "api/users";
import { EditIcon } from "assets/icons/common/edit.icon";
import { ExperiencesIcon } from "assets/icons/common/experiences.icon";
import { PlusIcon } from "assets/icons/common/plus.icon";
import PrimaryButton from "components/base/primaryButton";
import {
  ExperiencesContainer,
  Description,
  StyledFormItem,
  StyledInput,
  StyledTextArea,
  Heading,
  StyledDatepicker,
  StyledSwitch,
  SwitchGroup,
  SecondaryButton,
  ActionButton,
  Bottom,
  ExperienceCard,
  BoxText,
  BoxName,
  BoxDesc,
  UpdateGroup,
  ExperienceInfoGroup,
  EditButton,
  DeleteButton,
  ExperiencesContainerTop,
} from "./experiences.style";
import { FC, useState } from "react";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { IEmploymentHistoryProps } from "./experiences.props";
import {
  formatByMonths,
  maxLength_1000,
  maxLength_60,
  requiredRules,
  stringRequired,
  urlRules,
} from "utils/validatorRules";
import { convertNumberToMoment } from "utils/timer";
import SectionHeader from "../SectionHeader";
import { FormSectionWrapper } from "pages/ProfileUser/profileUserInner.style";

// const url = "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png";

export const Experiences: FC<IEmploymentHistoryProps> = ({
  employmentHistorys,
  setEmploymentHistorys,
  setLeaveVisible,
}) => {
  const [form] = Form.useForm();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isCurrentWork, setIsCurrentWork] = useState<boolean>(false);
  // const id: string = useAppSelector(
  //   (state) => state.auth.user?._id
  // );

  const queryClient = useQueryClient();
  const { mutate: addEmploymentHistoryMutate } = useMutation(
    addEmploymentHistoryAPI,
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          setEmploymentHistorys((res as any).responseData);
          setIsAdding(false);
          setIsEditing(false);
          queryClient.invalidateQueries(["processUpdateProfile"]);
          message.success((res as any).responseMessage);
        } else message.error((res as any).responseMessage);
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
    }
  );

  const { mutate: updateEmploymentHistoryMutate } = useMutation(
    updateEmploymentHistoryAPI,
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          setEmploymentHistorys((res as any).responseData);
          setIsAdding(false);
          setIsEditing(false);
          message.success((res as any).responseMessage);
        } else message.error((res as any).responseMessage);
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
    }
  );

  const { mutate: deleteEmploymentHistoryMutate } = useMutation(
    deleteEmploymentHistoryAPI,
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          setEmploymentHistorys((res as any).responseData);
          setIsAdding(false);
          setIsEditing(false);
          queryClient.invalidateQueries(["processUpdateProfile"]);
          message.success((res as any).responseMessage);
        } else message.warning((res as any).responseMessage);
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
    }
  );

  const handleAddFormEmploymentHistory = () => {
    setIsAdding((prev) => !prev);
    setIsEditing(false);
    form.setFieldsValue({
      designation: "",
      company: "",
      startDate: "",
      companyUrl: "",
      experienceFromCompany: "",
      endDate: "",
      _id: undefined,
    });
    setIsCurrentWork(false);
  };

  const handleEditFormEmploymentHistory = (
    employmentHistory: IEmploymentHistory
  ) => {
    setIsAdding(false);
    setIsEditing(true);
    form.setFieldsValue({
      ...employmentHistory,
      _id: employmentHistory._id,
      startDate: convertNumberToMoment(employmentHistory.startDate),
      endDate: employmentHistory.endDate
        ? convertNumberToMoment(employmentHistory.endDate)
        : "",
    });
    setIsCurrentWork(
      typeof employmentHistory.isCurrentWork === "boolean" &&
        employmentHistory.isCurrentWork
    );
  };

  const onSubmit = ({ startDate, endDate, ...values }: IEmploymentHistory) => {
    const employmentHistory = {
      isCurrentWork: isCurrentWork,
      startDate: startDate.valueOf(),
      endDate: endDate?.valueOf(),
      ...values,
    };

    setLeaveVisible(true);

    return isEditing
      ? updateEmploymentHistoryMutate({
          employmentHistoryId: form.getFieldValue("_id"),
          employmentHistory,
        })
      : addEmploymentHistoryMutate({ employmentHistory });
  };
  return (
    <FormSectionWrapper id="Work Experiences">
      <Heading>
        {/* <FlexBox>
          <ExperiencesIcon />
          <Title>Work Experiences</Title>
        </FlexBox> */}
        <SectionHeader icon={<ExperiencesIcon />} title="Work Experiences" />
        <PrimaryButton width="156px" onClick={handleAddFormEmploymentHistory}>
          <PlusIcon />
          Add Experience
        </PrimaryButton>
      </Heading>
      {(isAdding || isEditing) && (
        <ExperiencesContainer>
          <ExperiencesContainerTop>
            {/* <div>
              <RoleIcon />
            </div> */}
            <Form
              form={form}
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onSubmit}
              onValuesChange={() => setLeaveVisible(true)}
            >
              <div>
                {/* <StyledFormItem name="_id" noStyle></StyledFormItem> */}
                <StyledFormItem
                  name="designation"
                  label={<span>Role</span>}
                  rules={[stringRequired, maxLength_60]}
                >
                  <StyledInput placeholder="Ex. Software Engineer" />
                </StyledFormItem>
                <StyledFormItem
                  name="company"
                  label={<span>Company</span>}
                  rules={[stringRequired, maxLength_60]}
                >
                  <StyledInput placeholder="Ex. Rebaked" />
                </StyledFormItem>
                <StyledFormItem
                  name="companyUrl"
                  label={<span>URL</span>}
                  rules={[urlRules]}
                >
                  <StyledInput placeholder="Ex. https:blog.acme.com" />
                </StyledFormItem>
                <StyledFormItem
                  name="experienceFromCompany"
                  label={<span>What did you do? (One-liner)</span>}
                  rules={[maxLength_1000]}
                >
                  <StyledTextArea placeholder="Some cool stuff." />
                </StyledFormItem>
                <StyledFormItem
                  name="startDate"
                  label={<span>Start</span>}
                  rules={[requiredRules]}
                >
                  <StyledDatepicker
                    placeholder="Ex. mm/yyyy"
                    disabledDate={(current) => current.isAfter(moment())}
                    picker="month"
                    format={formatByMonths}
                  />
                </StyledFormItem>
                {!isCurrentWork && (
                  <StyledFormItem
                    name="endDate"
                    label={<span>End</span>}
                    rules={[requiredRules]}
                  >
                    <StyledDatepicker
                      placeholder="Ex. mm/yyyy"
                      disabledDate={(current) =>
                        current.isBefore(form.getFieldValue("startDate")) ||
                        current.isAfter(moment())
                      }
                      picker="month"
                      format={formatByMonths}
                    />
                  </StyledFormItem>
                )}
                <Bottom>
                  <SwitchGroup>
                    <StyledSwitch
                      checked={isCurrentWork}
                      onChange={(value) => setIsCurrentWork(value)}
                    />
                    <Description color="#080f6c">
                      I currently work here
                    </Description>
                  </SwitchGroup>
                  <SwitchGroup>
                    <SecondaryButton
                      onClick={() => {
                        setIsAdding(false);
                        setIsEditing(false);
                      }}
                    >
                      Close
                    </SecondaryButton>
                    <ActionButton onClick={() => form.submit()}>
                      {isEditing ? "Save" : "Add"}
                    </ActionButton>
                  </SwitchGroup>
                </Bottom>
              </div>
            </Form>
          </ExperiencesContainerTop>
        </ExperiencesContainer>
      )}
      {employmentHistorys?.map((employmentHistory, index) => {
        return (
          <ExperienceCard key={index}>
            <ExperienceInfoGroup>
              <BoxText>
                <BoxName>{employmentHistory.designation}</BoxName>
                <BoxDesc>{employmentHistory.company}</BoxDesc>
              </BoxText>
            </ExperienceInfoGroup>

            <UpdateGroup>
              <EditButton
                onClick={() =>
                  handleEditFormEmploymentHistory(employmentHistory)
                }
              >
                <EditIcon /> Edit
              </EditButton>
              <DeleteButton
                onClick={() =>
                  deleteEmploymentHistoryMutate({
                    employmentHistoryId: (employmentHistory as any)._id,
                  })
                }
              >
                Delete
              </DeleteButton>
            </UpdateGroup>
          </ExperienceCard>
        );
      })}
    </FormSectionWrapper>
  );
};
