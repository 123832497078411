export interface ICourseCustomisationProps {}

export enum MODAL_TYPE {
  ACTIVITY = "ACTIVITY",
  SECTION = "SECTION",
}

export interface IActivityModal {
  sectionId: number;
  activityId: number;
  type: MODAL_TYPE | null;
  addBelow?: boolean;
}
