import styled from "styled-components";

export const Container = styled.div<{ color: string | undefined }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 0.625rem 0.3125rem 0.4688rem;
  gap: 0.4063rem;
  width: fit-content;
  height: 1.75rem;
  ${({ color }: any) => {
    return `border: 0.0625rem solid ${color}`;
  }};
  border-radius: 1.25rem;
  span {
    ${({ color }: any) => {
      return `color: ${color}`;
    }};
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 110%;
    letter-spacing: -0.01em;
    width: max-content;
  }
`;

export const ContainerNoneIcon = styled.div<{ color: string | undefined }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 22px;
  gap: 0.4063rem;
  width: fit-content;
  height: 1.75rem;
  ${({ color }: any) => {
    return `border: 0.0625rem solid ${color}`;
  }};
  border-radius: 1.25rem;
  position: relative;

  & > label {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background: ${(p) => p.color};
    position: absolute;
    /* top: 13px; */
    left: 8px;
  }
  & > p {
    width: max-content;
    height: max-content;
    position: absolute;
    left: calc(100% + 8px);
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    margin: 0;
    top: 0;
    transform: translateY(50%);
  }
  span {
    ${({ color }: any) => {
      return `color: ${color}`;
    }};
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    width: max-content;
  }
`;
