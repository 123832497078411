import { Col, Row } from "antd";
import styled from "styled-components";
import dashboardHeaderBackground from "assets/dashboard/dashboard_header_bg.png";

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  margin: auto;
  #head-wrap {
    padding-top: 24px;
  }
`;
export const Wrap = styled.div`
  padding: 0px 50px;
`;
export const ContainerFluidHeader = styled.div`
  background-image: url(${dashboardHeaderBackground});
  padding: 0px 50px;
`;

export const DashboardContainer = styled(Row)`
  max-width: 73.125rem;
  margin: auto;
`;

export const DashboardNameContainer = styled(Col)`
  padding: 40px 0px;
`;

export const DashboardName = styled.label`
  font: normal 700 32px/115% "DM Sans";
  color: #080a25;
  cursor: pointer;
`;

export const ContainerHeaderFilter = styled(Col)``;

export const HeaderFilter = styled(Row)`
  align-self: flex-end;
`;

export const HeaderFilterTabs = styled(Col)`
  margin: 0px !important;
`;

export const DashboardMenuTabs = styled.ul`
  display: flex;
  column-gap: 16px;
  padding-inline-start: 0px !important;
  margin-bottom: 0px !important;
`;

export const DashboardMenuItem = styled.li<{ active?: boolean }>`
  list-style: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #080a25;
  padding-bottom: 17px;
  cursor: pointer;
  ${({ active }: any) =>
    active === true
      ? `border-bottom: 2px solid #7E00FD; font-weight: bold; color: #7E00FD`
      : ``}
`;
