import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import {
  getAllProjectsByOrganization,
  getOrganizationInfoAPI,
  IOrganizationInfo,
  IProject,
} from "api/users";
import { EditOrganIcon } from "assets/icons/editOrgan.icon";
// import OrganizationHeadBackground from "assets/images/organization/head-background.png";
import { Checked } from "assets/images/organization/organizationImages";
import { AxiosError } from "axios";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import AllProjects from "../AllProjects";
import Grants from "../Grants";
import { IOrganizationInfoProps } from "../OrganizationPage.props";
import {
  CreateProjectBtn,
  EditBtn,
  MainHeader,
  OrganActionsWrapper,
  OrganImageWrapper,
  OrganInfoWrapper,
  OrganName,
  OrganNameWrapper,
  OrganTabs,
  OrganWebsite,
  OrgAvatar,
  OrgBodyWrap,
  OrgHeadContainer,
  TabTitle,
} from "../OrganizationPage.style";
import Overview from "../Overview";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "redux/hooks";

const OrganizationInfo: FC<IOrganizationInfoProps> = ({
  hasMargin,
  organizationId,
}) => {
  const param = useParams();
  const navigate = useNavigate();
  const userInfo = useAppSelector((state) => state.auth.user);
  const { data: allProjRes } = useQuery(
    ["getProjectsByOrgId", param.id],
    () => getAllProjectsByOrganization(param.id as string),
    {
      refetchOnWindowFocus: false,
      enabled: hasMargin !== true,
    }
  );

  const { data: orgRes, isLoading: orgLoading } = useQuery(
    ["getOrganizationData"],
    () => getOrganizationInfoAPI(organizationId || param.id || ""),
    {
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      refetchOnWindowFocus: false,
    }
  );
  const organization: IOrganizationInfo | undefined = orgRes?.responseData;
  const allProjects: IProject[] = allProjRes?.responseData || [];

  const tabsTitle = [
    {
      name: "Overview",
      content: (
        <>
          <Overview organization={organization} />
        </>
      ),
    },
    {
      name: "All Projects",
      content: (
        <>
          <AllProjects allProjects={allProjects} />
        </>
      ),
    },
    {
      name: "Grants",
      content: (
        <>
          <Grants allProjects={allProjects} />
        </>
      ),
    },
  ];

  return (
    <>
      <OrgHeadContainer>
        <MainHeader>
          <OrganInfoWrapper hasMargin={hasMargin}>
            <OrganNameWrapper>
              <OrganName>
                {orgLoading ? <Skeleton width="200px" /> : organization?.name}
              </OrganName>
              <Checked />
            </OrganNameWrapper>
            {/* {organization?.socialLinks?.personURL && ( */}
            <OrganWebsite>
              {orgLoading ? <Skeleton /> : organization?.socialLinks?.personURL}
            </OrganWebsite>
            {/* )} */}
            {userInfo?.organization === organization?._id && (
              <OrganActionsWrapper>
                <CreateProjectBtn
                  onClick={() =>
                    navigate(ROUTER_CONSTANTS.PROJECT.CREATE_PROJECT)
                  }
                >
                  Create a project
                </CreateProjectBtn>
                <EditBtn
                  onClick={() =>
                    navigate(
                      ROUTER_CONSTANTS.ORGANIZATION.EDIT.replace(
                        ":id",
                        organization?._id || ""
                      )
                    )
                  }
                >
                  <EditOrganIcon />
                  Edit info
                </EditBtn>
              </OrganActionsWrapper>
            )}
          </OrganInfoWrapper>
          <OrganImageWrapper>
            {orgLoading ? (
              <Skeleton width="190px" height="190px" circle />
            ) : (
              <OrgAvatar src={organization?.avatar} alt="Organization avatar" />
            )}
          </OrganImageWrapper>
        </MainHeader>
      </OrgHeadContainer>
      <OrgBodyWrap>
        {hasMargin ? (
          <Overview organization={organization} />
        ) : (
          <OrganTabs>
            {tabsTitle.map((tab) => {
              return (
                <OrganTabs.TabPane
                  key={tab.name}
                  tab={<TabTitle>{tab.name}</TabTitle>}
                >
                  {tab.content}
                </OrganTabs.TabPane>
              );
            })}
          </OrganTabs>
        )}
      </OrgBodyWrap>
    </>
  );
};

export default OrganizationInfo;
