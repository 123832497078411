import styled from "styled-components";
import background from "./images/background.png";

export const Container = styled.div`
  .heading-1 {
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .heading-2 {
    font-size: 24px;
    line-height: 115%;
    letter-spacing: -0.01em;
    max-width: 303px;
    min-width: 26%;
  }
  width: 100%;
  background-image: url(${background});
  background-size: auto;
  background-repeat: round;
  padding-top: 65px;
  & > div {
    max-width: 1170px;
    margin: 0 auto;
  }
`;
export const Wrap = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 80px;
`;
export const Item = styled.div`
  display: flex;
  gap: 74px;
`;

export const TitleStyled = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #080f6c;
  span {
    color: #7e00fd;
  }
`;

export const ContentStyled = styled.div`
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  padding: 56px 40px;
  img {
    max-width: 168px;
    height: auto;
  }
  display: flex;
  align-items: center;
  gap: 59px;
`;

export const UlStyled = styled.ul`
  li {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 164%;
    letter-spacing: -0.01em;
    color: #080f6c;
    opacity: 0.8;
  }
  max-width: 507px;
  list-style-position: outside;
  list-style-type: disc;
  display: grid;
  gap: 6px;
  margin-bottom: unset;
`;

export const HighLight = styled.span`
  color: rgb(126, 0, 253);
`;
