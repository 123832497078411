import "./App.css";
import { AppRoutes } from "Routes";
import "react-loading-skeleton/dist/skeleton.css";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body, html, #root, #main-layout {
  height: -webkit-fill-available;
}

  .safari {
    height: -webkit-fill-available !important;
  }
  
  
  .ant-form-item-label {
    padding-bottom: 0.625rem;
    label {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #080f6c;
    }
  }
`;

function App() {
  return (
    <div className="App">
      <AppRoutes />
      <GlobalStyle />
    </div>
  );
}

export default App;
