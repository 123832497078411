import { UserOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, message, Tooltip } from "antd";
import { getAllFeedbackByUser, IFeedbackByUserId } from "api/projectDetail";
import {
  getProjectDetails,
  getUserRequestReceivedAPI,
  IApplicant,
  IProject,
  removeCollab,
  updateJoinRequestAPI,
} from "api/users";
import { RejectReasonInfoIcon } from "assets/icons/info.icon";
import {
  MessageIcon,
  PurpleAttachIcon2,
  ToogleIcon,
} from "assets/images/deliver-work";
import { AkarIcon } from "assets/images/worktasks-inside";
import { AxiosError } from "axios";
import Button from "components/base/button";
import OutlinedButton from "components/base/outlinedButton";
import Pagination from "components/base/pagination";
import PrimaryButton from "components/base/primaryButton";
import { AvatarAntd } from "components/modules/avatarList/avatarList.style";
import { parseUnits } from "ethers/lib/utils";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import { StyledInput } from "pages/ProfileUser/components/Experiences/experiences.style";
import { SpanText } from "pages/WorktasksInside/WorktasksInside.style";
import { FC, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setPageLoading } from "redux/slices";
import {
  BlockchainNetworks,
  ROUTER_CONSTANTS,
  STATUS,
  VERIFICATION_STATUS,
} from "utils/constant";
import { getSkillTagColor } from "utils/getSkillTagColor";
import {
  addCollaboratorSC,
  handleMessageErrorSC,
  removeCollaboratorSC,
} from "web3/contract";
import { IDeliverCollaboratorsProps } from "./DeliverCollaborators.props";
import {
  AcceptBtn,
  AcceptContentWrapper,
  BtnDownloadWrap,
  ButtonAntd,
  ButtonWrap,
  CollabInfo,
  CollapseToogle,
  ConfirmModal,
  ConfirmText,
  ContentWrap,
  EmptyText,
  Head,
  Item,
  ItemWrap,
  LabelText,
  MarginTitle,
  Options,
  PaginationWrapper,
  RateAntd,
  RejectButton,
  RejectContentWrapper,
  SkillWrap,
  SpanWrap,
  Text,
  Title,
  UserProfileLink,
  SkillTag,
  RateAndReviewWrapper,
  FeedbackMainTitle,
  ListFeedbackWrapper,
  ListFeedbackItem,
  FeedbackColumnTitle,
  FeedbackText,
  FeedbackAction,
} from "./DeliverCollaborators.style";

const PAGE_SIZE = 3;

type RequestUser = IApplicant["requestUsers"][number];

const DeliverCollaborators: FC<IDeliverCollaboratorsProps> = ({
  projectId,
  packageId,
  requestMode,
  worktaskDetail,
  isProjectCreator,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [openItems, setOpenItems] = useState([] as any);
  const requestStatus = requestMode ? STATUS.ACTIVE : STATUS.INACTIVE;
  const location = useLocation();
  const userInfo = useAppSelector((state) => state.auth.user);
  const [popupIndex, setPopupIndex] = useState(0);
  const queryClient = useQueryClient();
  const [selectedApplicant, setSelectedApplicant] = useState<RequestUser>();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFeedbackPage, setCurrentFeedbackPage] = useState(1);
  const { mutate: updateJoinRequest } = useMutation(updateJoinRequestAPI, {
    onSuccess: async (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        await queryClient.invalidateQueries(["getUserRequestReceived"]);
        await queryClient.invalidateQueries([
          `getPackageDetail-${params.packageId}`,
        ]);
        message.success(responseMessage);
        setPopupIndex(0);
      } else {
        message.error(responseMessage);
        setPopupIndex(0);
      }
    },
    onError: (err) => {
      setPopupIndex(0);
      message.error((err as any)?.message);
    },
    onSettled: () => {
      dispatch(setPageLoading(false));
    },
  });
  const { mutate: removeCollabMutation } = useMutation(removeCollab);
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();
  const { data: feedbackRes, isLoading: feedbackLoading } = useQuery(
    [`getAllFeedbackBy-${selectedApplicant?.userId}`],
    () => getAllFeedbackByUser(selectedApplicant?.userId as string),
    {
      refetchOnWindowFocus: false,
      enabled: popupIndex === 4 && !!selectedApplicant?.userId,
    }
  );
  const feedbackInfo = useMemo(() => {
    return feedbackRes?.responseData;
  }, [feedbackRes?.responseData]);

  const { data: projectDetailRes } = useQuery(
    [`getProjectDetail-${projectId}`],
    () => getProjectDetails(projectId || ""),
    {
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      refetchOnWindowFocus: false,
    }
  );
  const project: IProject | undefined = projectDetailRes?.responseData;

  const { data: collabRes, isLoading } = useQuery(
    ["getUserRequestReceived"],
    () =>
      getUserRequestReceivedAPI(
        projectId || "",
        packageId || "",
        requestStatus
      ),
    {
      refetchOnWindowFocus: true,
    }
  );

  const collabs: IApplicant | undefined = collabRes?.responseData?.records;

  const collaboratorsWithoutEndAgreement =
    isProjectCreator === true
      ? collabs?.requestUsers?.filter(
          (reqUser) =>
            reqUser?.status === "INACTIVE" &&
            worktaskDetail?.collaborators?.some(
              (colId) => colId === reqUser?.userId
            )
        )
      : collabs?.requestUsers;

  const handleClickApprove = async () => {
    if (!checkWallet()) return;

    if (!selectedApplicant?.user?.walletAddress) {
      message.error("This collaborator doesn't have wallet address");
      return;
    }

    if (worktaskDetail?.collaborators?.length === worktaskDetail?.memberLimit) {
      message.error("This worktask has reached maximum collaborators");
      return;
    }

    try {
      dispatch(setPageLoading(true));

      const projectChainId =
        BlockchainNetworks[project?.blockchainNetwork?.name!];
      await handleSwitchNetwork(projectChainId);
      const scParams = {
        projectId: collabs?.scProjectId,
        packageId: collabs?.packages?.scPackageId,
        mgp: parseUnits(
          "" + +(collabs?.packages?.minimumCost as number),
          collabs?.decimal
        ).toString(),
        collaborator: selectedApplicant?.user?.walletAddress,
      };

      await addCollaboratorSC(scParams);
      updateJoinRequest({
        userId: selectedApplicant?.userId,
        projectId: projectId || "",
        packageId: packageId || "",
        verificationStatus: VERIFICATION_STATUS.ACCEPTED,
      });
    } catch (err: any) {
      dispatch(setPageLoading(false));
      setPopupIndex(0);
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const handleClickReject = (values: any) => {
    try {
      dispatch(setPageLoading(true));
      updateJoinRequest({
        userId: selectedApplicant?.userId || "",
        projectId: projectId || "",
        packageId: packageId || "",
        verificationStatus: VERIFICATION_STATUS.REJECTED,
      });
    } catch (error) {
      dispatch(setPageLoading(false));
    }
  };

  const handleSelectAction = (id: number, selected: RequestUser) => {
    if (id === 4 || selected?.status === "ACTIVE") {
      setSelectedApplicant(selected);
      setPopupIndex(id);
    }
  };

  const handleSelectRemoveCollabAction = (
    id: number,
    selected: RequestUser
  ) => {
    setSelectedApplicant(selected);
    setPopupIndex(id);
  };

  const onChange = (key: string | string[]) => {
    setOpenItems(key);
  };

  const handleClickCancelModal = () => {
    setPopupIndex(0);
  };

  const handleRemoveCollaborator = async (collab?: RequestUser) => {
    if (!checkWallet()) return;

    try {
      dispatch(setPageLoading(true));
      const workStarted = worktaskDetail?.workProgress?.some(
        (val) => val?.user === collab?.userId
      );

      const projectChainId =
        BlockchainNetworks[project?.blockchainNetwork?.name!];
      await handleSwitchNetwork(projectChainId);

      const { tx } = await removeCollaboratorSC({
        projectId: worktaskDetail?.scProjectId || "",
        packageId: worktaskDetail?.scPackageId || "",
        collaborator: collab?.user.walletAddress,
        shouldPayMgp: workStarted || false,
      });
      removeCollabMutation(
        {
          projectId: params?.projectId || "",
          packageId: params?.packageId || "",
          collabId: collab?.userId || "",
          txHash: tx.hash,
        },
        {
          onSuccess: async (res) => {
            const { responseCode, responseMessage } = res;

            dispatch(setPageLoading(false));
            if (responseCode === 200) {
              await queryClient.invalidateQueries(["getUserRequestReceived"]);
              await queryClient.invalidateQueries([
                `getPackageDetail-${params.packageId}`,
              ]);
              message.success(responseMessage);
              handleClickCancelModal();
            } else {
              message.error(responseMessage);
              handleClickCancelModal();
            }
          },
          onError: (err) => {
            dispatch(setPageLoading(false));
            handleClickCancelModal();
            message.error((err as any)?.message);
          },
        }
      );
    } catch (err: any) {
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const handleGoToFeedbackDetail = (
    selectedFb: IFeedbackByUserId["data"][number]
  ) => {
    navigate(
      ROUTER_CONSTANTS.PROJECT.FEEDBACK.replace(
        ":projectId",
        selectedFb.project._id
      ).replace(":packageId", selectedFb.package._id),
      {
        state: {
          viewFeedback: true,
          feedbackId: selectedFb.feedback._id,
          projectId: params.projectId,
          packageId: params.packageId,
          from: location.pathname,
        },
      }
    );
  };

  if (isLoading) {
    return <EmptyText>Loading...</EmptyText>;
  }

  return (
    <>
      {collaboratorsWithoutEndAgreement &&
      collaboratorsWithoutEndAgreement?.length === 0 ? (
        <EmptyText>No applicants</EmptyText>
      ) : (
        <ItemWrap
          collapsible="header"
          onChange={onChange}
          activeKey={openItems}
        >
          {collaboratorsWithoutEndAgreement
            ?.slice((currentPage - 1) * PAGE_SIZE, PAGE_SIZE * currentPage)
            ?.map((coll) => (
              <Item
                key={coll?.userId}
                header={
                  <Head>
                    <CollabInfo>
                      <AvatarAntd
                        icon={<UserOutlined />}
                        src={coll?.user?.profilePicture}
                      />
                      <div>
                        <div>
                          <UserProfileLink
                            to={`${ROUTER_CONSTANTS.USER.PROFILE_PREVIEW}?id=${coll?.userId}`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {coll?.user?.username ||
                              coll?.user?.emailId.split("@")[0]}
                          </UserProfileLink>
                        </div>
                        <RateAndReviewWrapper
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectAction(4, coll);
                          }}
                        >
                          <RateAntd disabled value={coll?.averageScore} />
                          <span>
                            ({coll?.totalReviews}
                            {coll?.totalReviews && coll?.totalReviews > 1
                              ? " reviews"
                              : " review"}
                            )
                          </span>
                        </RateAndReviewWrapper>
                      </div>
                    </CollabInfo>
                    <Options>
                      <SpanWrap
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {coll?.userId === userInfo._id ? null : requestMode ? (
                          <Link to={"/conversation?id=" + coll?.userId}>
                            <MessageIcon />
                          </Link>
                        ) : (
                          <span
                            onClick={(e) =>
                              navigate(`/conversation?id=${coll?.userId}`)
                            }
                          >
                            <MessageIcon />
                          </span>
                        )}
                        {requestMode && (
                          <>
                            <AcceptBtn
                              width="81px"
                              height="32px"
                              onClick={() => handleSelectAction(1, coll)}
                              disabled={coll?.status !== "ACTIVE"}
                            >
                              Accept
                            </AcceptBtn>
                            <RejectButton
                              onClick={() => handleSelectAction(2, coll)}
                            >
                              Reject
                            </RejectButton>
                          </>
                        )}
                        {!requestMode &&
                          isProjectCreator &&
                          worktaskDetail?.workStatus !== "COMPLETED" && (
                            <RejectButton
                              onClick={() =>
                                handleSelectRemoveCollabAction(3, coll)
                              }
                            >
                              End Agreement
                            </RejectButton>
                          )}
                      </SpanWrap>
                      <CollapseToogle
                        className={
                          openItems.includes(coll?.userId) ? "" : "toogle-down"
                        }
                      >
                        <ToogleIcon />
                      </CollapseToogle>
                    </Options>
                  </Head>
                }
              >
                <ContentWrap>
                  <>
                    {coll?.user?.skills?.specialty?.length > 0 && (
                      <>
                        {/* {console.log(coll?.user?.skills?.specialty)} */}
                        <MarginTitle>Skills</MarginTitle>
                        <SkillWrap>
                          {coll?.user?.skills?.specialty
                            ?.split(",")
                            ?.map((item) => (
                              <SkillTag key={item} cl={getSkillTagColor()}>
                                {item}
                              </SkillTag>
                            ))}
                        </SkillWrap>
                      </>
                    )}
                    <Title>Cover letter</Title>
                    <Text>{coll?.coverLetter}</Text>
                    <Title>Action plan</Title>
                    <Text>{coll?.actionPlan}</Text>
                    <Title>Expected deliverables</Title>
                    <Text>{coll?.expectedDeliverables}</Text>
                    {coll?.files?.length > 0 && (
                      <>
                        <MarginTitle>Resourses</MarginTitle>
                        <BtnDownloadWrap>
                          {coll.files.map((file: any) => (
                            <ButtonAntd key={file?._id}>
                              <a href={file?.fileLink}>
                                <PurpleAttachIcon2 />
                                {file?.fileName}
                              </a>
                            </ButtonAntd>
                          ))}
                        </BtnDownloadWrap>
                      </>
                    )}
                  </>
                </ContentWrap>
              </Item>
            ))}
        </ItemWrap>
      )}

      {collaboratorsWithoutEndAgreement &&
        collaboratorsWithoutEndAgreement?.length > 0 && (
          <PaginationWrapper>
            <Pagination
              current={currentPage}
              pageSize={PAGE_SIZE}
              total={collaboratorsWithoutEndAgreement?.length}
              onChange={(page: number) => setCurrentPage(page)}
            />
          </PaginationWrapper>
        )}

      <ConfirmModal
        width={700}
        visible={popupIndex === 4}
        onCancel={handleClickCancelModal}
        footer={null}
        destroyOnClose
      >
        <AcceptContentWrapper>
          <FeedbackMainTitle>All feedbacks</FeedbackMainTitle>
          <ListFeedbackWrapper>
            {feedbackLoading ? (
              <FeedbackColumnTitle>Loading...</FeedbackColumnTitle>
            ) : feedbackInfo && feedbackInfo.data.length > 0 ? (
              <>
                <ListFeedbackItem>
                  <FeedbackColumnTitle>Project name</FeedbackColumnTitle>
                  <FeedbackColumnTitle>Worktask name</FeedbackColumnTitle>
                  <FeedbackColumnTitle>Feedback</FeedbackColumnTitle>
                </ListFeedbackItem>
                {feedbackInfo.data
                  .slice(
                    (currentFeedbackPage - 1) * PAGE_SIZE,
                    PAGE_SIZE * currentFeedbackPage
                  )
                  .map((item, key) => (
                    <ListFeedbackItem key={key}>
                      <FeedbackText>{item.project.name}</FeedbackText>
                      <FeedbackText>{item.package.name}</FeedbackText>
                      <FeedbackAction
                        onClick={() => handleGoToFeedbackDetail(item)}
                      >
                        View
                      </FeedbackAction>
                    </ListFeedbackItem>
                  ))}
                <PaginationWrapper>
                  <Pagination
                    current={currentFeedbackPage}
                    pageSize={PAGE_SIZE}
                    total={feedbackInfo.data?.length}
                    onChange={(page) => setCurrentFeedbackPage(page)}
                  />
                </PaginationWrapper>
              </>
            ) : (
              <FeedbackColumnTitle>
                This user don't have any feedbacks
              </FeedbackColumnTitle>
            )}
          </ListFeedbackWrapper>
        </AcceptContentWrapper>
      </ConfirmModal>

      <ConfirmModal
        visible={popupIndex === 3}
        onCancel={handleClickCancelModal}
        footer={null}
        width={468}
        closable={false}
        destroyOnClose
      >
        <AcceptContentWrapper>
          <ConfirmText>
            {worktaskDetail?.workProgress?.some(
              (val) => val?.user === selectedApplicant?.userId
            )
              ? "Are you sure to remove this collaborator? The MGP will be sent to the collaborator"
              : "This collaborator will be removed, you can add a new collaborator to do this work task"}
          </ConfirmText>
          <ButtonWrap>
            <PrimaryButton
              width="192px"
              height="48px"
              onClick={() => handleRemoveCollaborator(selectedApplicant)}
            >
              <SpanText>Yes Accept</SpanText>
            </PrimaryButton>
            <OutlinedButton
              width="192px"
              height="48px"
              color="rgba(41, 46, 115, 0.42)"
              onClick={handleClickCancelModal}
            >
              Cancel
            </OutlinedButton>
          </ButtonWrap>
        </AcceptContentWrapper>
      </ConfirmModal>

      <ConfirmModal
        visible={popupIndex === 1}
        onCancel={handleClickCancelModal}
        footer={null}
        width={468}
        closable={false}
        destroyOnClose
      >
        <AcceptContentWrapper>
          <AkarIcon />
          <ConfirmText>
            Are you sure you want to accept this applicant to participate as a
            collaborator?
          </ConfirmText>
          <ButtonWrap>
            <PrimaryButton
              width="192px"
              height="48px"
              onClick={handleClickApprove}
            >
              <SpanText>Yes Accept</SpanText>
            </PrimaryButton>
            <OutlinedButton
              width="192px"
              height="48px"
              color="rgba(41, 46, 115, 0.42)"
              onClick={handleClickCancelModal}
            >
              Cancel
            </OutlinedButton>
          </ButtonWrap>
        </AcceptContentWrapper>
      </ConfirmModal>

      <ConfirmModal
        visible={popupIndex === 2}
        onCancel={handleClickCancelModal}
        footer={null}
        width={468}
        closable={false}
        destroyOnClose
      >
        <RejectContentWrapper>
          <AkarIcon />
          <ConfirmText>
            Are you sure you want to reject this applicant?
          </ConfirmText>
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => handleClickReject(values)}
            preserve={false}
          >
            <ButtonWrap>
              <Button
                width="192px"
                height="48px"
                color="#E94600"
                background="rgba(233, 70, 0, 0.2)"
                htmlType="submit"
                // onClick={handleClickReject}
              >
                Yes Reject
              </Button>
              <OutlinedButton
                width="192px"
                height="48px"
                color="rgba(41, 46, 115, 0.42)"
                htmlType="button"
                onClick={handleClickCancelModal}
              >
                Cancel
              </OutlinedButton>
            </ButtonWrap>

            <Form.Item
              label={
                <LabelText>
                  Specify your reason
                  <Tooltip
                    title={
                      <span>
                        Please explain why you want to reject this applicant &
                        help them improve their next applications. This is how
                        you will help build a passionate community around your
                        project.
                      </span>
                    }
                  >
                    <span>
                      <RejectReasonInfoIcon />
                    </span>
                  </Tooltip>
                </LabelText>
              }
              name="reason"
            >
              <StyledInput placeholder="Input reason" />
            </Form.Item>
          </Form>
        </RejectContentWrapper>
      </ConfirmModal>
    </>
  );
};

export default DeliverCollaborators;
