import { Button } from "antd";
import styled from "styled-components";
import ContainerFluidCustom from "../containerFluidCustom";

export const Container = styled.div``;

export const ContainerTableCard = styled(ContainerFluidCustom)`
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  width: 1091px;
  margin: 0px;
  background: white;
  display: flex;
  &:hover,
  &:focus {
    background: linear-gradient(
      rgba(126, 0, 253, 0.07),
      rgba(255, 255, 255, 0)
    );
  }
`;

export const ProtocolBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 14px;
  height: 100%;
  padding: 20px 0px 16px 22px;
  width: 150px;
`;

export const ProjectAvatar = styled.img`
  width: 28px;
  height: 24px;
  border-radius: 100%;
`;

export const ProtocolTokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: space-between;
`;

export const CountToken = styled.span`
  font: normal 700 20px/125% "DM Sans" !important;
  color: #080F6C;
`;

export const ProtocolDateTimeBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 9px;
`;

export const ProtocolName = styled.div`
  font: normal 400 16px/125% "DM Sans" !important;
  color: rgba(41, 46, 115, 0.82);
`;

export const TransactionDataTime = styled.div`
  color: rgba(41, 46, 115, 0.42);
  font: normal 400 14px/125% "DM Sans" !important;
  border-radius: 50px;
`;

export const Divider = styled.div`
  margin: 16px 37px 15px 0px;
  height: 58px;
  width: 0px;
  border: 1px solid rgba(156, 138, 193, 0.16);
`;

export const TransactionIdBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 153px;
  margin-top: 20px;
  margin-bottom: 21px;
`;

export const TableCardLabel = styled.div`
  font-size: 14px;
  line-height: 125%;
  color: rgba(41, 46, 115, 0.42);
  font-weight: 400;
  height: 100%;
`;

export const TransactionIdValue = styled.div`
  font-size: 16px;
  line-height: 125%;
  color: rgba(41, 46, 115, 0.82);
  font-weight: 400;
  text-decoration: underline;
  margin-left: 0px;
`;

export const DestinationWalletBlock = styled(TransactionIdBlock)`
  width: 410px;
`;

export const ButtonView = styled(Button)`
  width: 32px;
  margin-top: 29px;
  padding: 8px;
  border-radius: 4px;
  background: rgba(156, 138, 193, 0.16);
  height: 32px;
  width: 99px;
  display: flex;
  align-items: center;
  span {
    color: #7e00fd;
    font-size: 14px;
    font-weight: 500;
    line-height: 115%;
    font-style: "Medium";
    font-family: "Rubik";
  }
`;
