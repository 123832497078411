import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { getPackageDetailsAPI, getProjectDetails, IWorktask } from "api/users";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  AwardStophyIcon,
  ConnectionIcon,
  DetailsIcon,
  DollarIcon,
  EyeCheckIcon,
  FinishFlagIcon,
  MultipleUsersIcon,
} from "assets/images/Bounty";
import { AxiosError } from "axios";
import Button from "components/base/button";
import PrimaryButton from "components/base/primaryButton";
import {
  PopupWrap,
  SpanText,
} from "pages/WorktasksInside/WorktasksInside.style";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setProject, setWorktaskDetails } from "redux/slices";
import {
  BlockchainExplorers,
  PROGRESS,
  ROUTER_CONSTANTS,
} from "utils/constant";
import { IBountyProps } from "./Bounty.props";
import {
  ButtonsWrap,
  Container,
  FooterWrap,
  Frame,
  Header,
  HeaderWrap,
  Line,
  MainTitleWrap,
  MenuStep,
} from "./Bounty.style";
import Completed from "./components/Completed";
import WorktaskObserver from "./components/Observers";
import ReviewWorktask from "./components/ReviewWorktask";
import SessionBudget from "./components/SessionBudget";
import WorktaskDetails from "./components/WorktaskDetails";
import WorktaskType from "./components/WorktaskType";

const Bounty: FC<IBountyProps> = ({ editMode = false }): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const WorktaskSteps = [
    {
      title: "WorkTask Type",
      icon: <AwardStophyIcon />,
    },
    {
      title: "WorkTask Details",
      icon: <DetailsIcon />,
    },
    {
      title: "Session Budget & Tokenomics",
      icon: <DollarIcon />,
    },
    {
      title: "WorkTask Observer",
      icon: <MultipleUsersIcon />,
    },
    {
      title: "Review WorkTask",
      icon: <EyeCheckIcon />,
    },
    {
      title: "Completed",
      icon: <FinishFlagIcon />,
    },
  ];

  // useEffect(() => {
  //   dispatch(setMode({ editMode }));
  // }, [dispatch, editMode]);

  const {
    stepSubmit,
    step1Done,
    step2Done,
    step3Done,
    step4Done,
    success,
    createdTxHash,
    project,
  } = useAppSelector((state) => state.addWorktask);
  const explorerPrefix = BlockchainExplorers[project?.blockchainNetwork?.name!];

  const [stepIndex, setStepIndex] = useState(0);
  const [openSuccessPopup, setOpenSuccessPopup] = useState<boolean>(false);
  const param = useParams();

  async function nextStep() {
    if (stepIndex === WorktaskSteps.length - 1) return;
    const result = await stepSubmit?.();
    if (!result) return;

    let nextStepIndex = stepIndex + 1;
    if (result.observerFees === 0) nextStepIndex++;
    setStepIndex(nextStepIndex);
  }

  function backStep() {
    if (stepIndex === 0) {
      navigate(
        ROUTER_CONSTANTS.PROJECT.DETAIL.replace(
          ":id",
          param.projectId as string
        )
      );
      return;
    }
    setStepIndex(stepIndex - 1);
  }

  useEffect(() => {
    if (success === PROGRESS.SUCCESS) {
      setOpenSuccessPopup(true);
      setStepIndex(WorktaskSteps.length - 1);
    }
  }, [WorktaskSteps.length, success]);

  useQuery(
    [`getProjectDetail-${param.projectId}`],
    () => getProjectDetails(param.projectId || ""),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          dispatch(setProject({ project: res.responseData }));
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: worktaskDetailRes, isLoading: isWorktaskLoading } = useQuery(
    [`getPackageDetail-${param.packageId}`],
    () => getPackageDetailsAPI(param.projectId || "", param.packageId || ""),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          // console.log("setWorktaskDetails", res);
          const worktaskDetails = res.responseData;

          dispatch(
            setWorktaskDetails({
              projectType: worktaskDetails.projectType?._id,
              issueType: worktaskDetails.issueType?._id,
              expertiseLevel: worktaskDetails.expertiseLevel?._id,
              startDate: worktaskDetails.startDate,
              endDate: worktaskDetails.endDate,
              expectedTimeCommitment: worktaskDetails.expectedTimeCommitment,
              tags: worktaskDetails.tags,
              observers: worktaskDetails.observers?.map((obs: any) => ({
                ...obs,
                observerId: obs?._id,
                ...obs?.user,
              })),

              name: worktaskDetails.name,
              description: worktaskDetails.description,
              link: worktaskDetails.link,
              context: worktaskDetails.context,
              reference: worktaskDetails.reference,
              acceptanceCriteria: worktaskDetails.acceptanceCriteria,
              resourceFiles: worktaskDetails.resourceFiles,

              githubIssueURL: worktaskDetails.githubIssueURL || undefined,
              importName: worktaskDetails.githubIssueURL
                ? worktaskDetails.name
                : undefined,
              importDescription: worktaskDetails.githubIssueURL
                ? worktaskDetails.description
                : undefined,

              minimumCost: worktaskDetails.minimumCost,
              observerFees: worktaskDetails.observerFees,
              memberLimit: worktaskDetails.memberLimit,
              bonus: worktaskDetails.bonus,
              isCustom: worktaskDetails.githubIssueURL ? false : true,
              packageId: worktaskDetails._id,
            })
          );
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      enabled: !!editMode,
      refetchOnWindowFocus: false,
    }
  );
  const worktaskDetails: IWorktask | undefined =
    worktaskDetailRes?.responseData;

  function switchStepValidate(step: number) {
    switch (step) {
      case 1:
        if (!step1Done) return false;
        return true;
      case 2:
        if (!step1Done || !step2Done) return false;
        return true;
      case 3:
        if (!step1Done || !step2Done || !step3Done) return false;
        return true;
      case 4:
        if (!step1Done || !step2Done || !step3Done || !step4Done) return false;
        return true;
      case 5:
        return false;

      default:
        return true;
    }
  }

  const havePaddingX = [0, 2, 3, 4];

  return (
    <>
      <Container>
        <Frame>
          <HeaderWrap>
            <Header>
              <MenuStep
                className={"active"}
                onClick={() => {
                  if (stepIndex === WorktaskSteps.length - 1) return;
                  setStepIndex(0);
                }}
              >
                {WorktaskSteps[0].icon}
                <div className="step-title">{WorktaskSteps[0].title}</div>
              </MenuStep>
              {WorktaskSteps.slice(1).map((content: any, i: number) => (
                <React.Fragment key={i}>
                  <Line
                    className={
                      i <= stepIndex
                        ? i + 1 === WorktaskSteps.length &&
                          stepIndex === WorktaskSteps.length - 1
                          ? ""
                          : "line-active"
                        : ""
                    }
                  />
                  <MenuStep
                    key={i}
                    className={
                      i + 1 <= stepIndex
                        ? i + 2 === WorktaskSteps.length &&
                          stepIndex === WorktaskSteps.length - 1
                          ? "complete-active"
                          : "active"
                        : ""
                    }
                    onClick={() => {
                      if (
                        stepIndex === WorktaskSteps.length - 1 ||
                        !switchStepValidate(i + 1)
                      )
                        return;
                      setStepIndex(i + 1);
                    }}
                    paddingX={havePaddingX.some((item) => item === i) ? 20 : 0}
                  >
                    {content.icon}
                    <div className="step-title">{content.title}</div>
                  </MenuStep>
                </React.Fragment>
              ))}
            </Header>
          </HeaderWrap>
          <MainTitleWrap>
            <div>
              {stepIndex === WorktaskSteps.length - 1
                ? "Completed"
                : WorktaskSteps[stepIndex].title}
            </div>
            <span>
              {stepIndex === WorktaskSteps.length - 1 ? (
                <p>Step Completed</p>
              ) : (
                <>
                  <p>Step {" " + (stepIndex + 1)}</p>
                  <span>of 5</span>
                </>
              )}
            </span>
          </MainTitleWrap>

          {stepIndex === 0 && (
            <WorktaskType
              worktaskDetails={worktaskDetails}
              isWorktaskLoading={isWorktaskLoading}
              editMode={editMode}
            />
          )}
          {stepIndex === 1 && (
            <WorktaskDetails
              worktaskDetails={worktaskDetails}
              isWorktaskLoading={isWorktaskLoading}
              editMode={editMode}
            />
          )}
          {stepIndex === 2 && (
            <SessionBudget
              worktaskDetails={worktaskDetails}
              isWorktaskLoading={isWorktaskLoading}
              editMode={editMode}
            />
          )}
          {stepIndex === 3 && (
            <WorktaskObserver worktaskDetails={worktaskDetails} />
          )}
          {stepIndex === 4 && (
            <ReviewWorktask
              worktaskDetails={worktaskDetails}
              isWorktaskLoading={isWorktaskLoading}
              editMode={editMode}
              stepIndex={stepIndex}
              setStepIndex={setStepIndex}
            />
          )}
          {/* {stepIndex === 4 && <PaymentInformation />} */}
          {stepIndex === 5 && <Completed projectId={param.projectId} />}

          {stepIndex < WorktaskSteps.length - 2 && (
            <FooterWrap
            // className={stepIndex === 0 ? "first-step" : ""}
            >
              {/* {stepIndex !== 0 && ( */}
              <Button
                width="89px"
                height="48px"
                border="1px solid rgba(156, 138, 193, 0.16)"
                color="rgba(41, 46, 115, 0.42)"
                background="#FFF"
                onClick={() => backStep()}
              >
                <ArrowLeftIcon />
                Back
              </Button>
              {/* )} */}
              <ButtonsWrap>
                <Button
                  width="83px"
                  height="48px"
                  border="1px solid rgba(156, 138, 193, 0.16)"
                  color="rgba(41, 46, 115, 0.42)"
                  background="#FFF"
                >
                  <Link to="/">Cancel</Link>
                </Button>
                <PrimaryButton
                  width={stepIndex === 4 ? "181px" : "76px"}
                  height="48px"
                  onClick={() => nextStep()}
                >
                  {stepIndex === 4
                    ? editMode
                      ? "Proceed to Edit"
                      : "Proceed to Payment"
                    : "Next"}
                  <ArrowRightIcon />
                </PrimaryButton>
              </ButtonsWrap>
            </FooterWrap>
          )}
        </Frame>

        {openSuccessPopup && (
          <PopupWrap height={editMode ? 380 : 0}>
            <div>
              <ConnectionIcon />
              {editMode ? (
                <>
                  <div>Update successful</div>
                  <p>
                    We have successfully updated your worktask! It's now time to
                    start co-building your project with our collaborators!
                  </p>
                </>
              ) : (
                <>
                  <div>Payment successful</div>
                  <p>
                    We have successfully received your payment! It's now time to
                    start co-building your project with our collaborators!
                  </p>
                  <p>
                    Transaction Hash:
                    <a
                      href={`${explorerPrefix}${createdTxHash}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        display: "block",
                        width: "100%",
                      }}
                    >
                      {explorerPrefix}
                      {createdTxHash}
                    </a>
                  </p>
                </>
              )}
              <PrimaryButton
                width="192px"
                height="48px"
                onClick={() => {
                  setOpenSuccessPopup(false);
                  // dispatch(setReset());
                }}
              >
                <SpanText>Got it</SpanText>
              </PrimaryButton>
            </div>
          </PopupWrap>
        )}
      </Container>
    </>
  );
};

export default Bounty;
