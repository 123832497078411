import styled from "styled-components";
import { CardContainer } from "../projectCard/projectCard.style";

export const EmptyText = styled.div`
  width: 100%;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #fff;
`;
export const GroupWrapper = styled.div<{ ref?: any }>`
  width: 100%;
  position: relative;
  margin-top: 8px;
  padding: 46px 50px;
`;
export const GroupContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
`;
export const CardsContainer = styled.div<{ itemsShow?: number; mt: any }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(365px, 1fr));
  gap: 20px;
  ${CardContainer} {
    margin-top: ${(p) => p.itemsShow === 2 && "16px"};
  }
`;
export const GroupTitle = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 115%;
  letter-spacing: -0.02em;
  color: #fff;
`;
export const GroupHeader = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  text-align: center;
  .ant-btn {
    margin: auto;
    margin-top: 2rem;
    min-width: 160px;
  }
`;

export const GroupDescription = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 156%;
  color: #fff;
  margin-top: 12px;
  margin-inline: 75px;
`;
export const SeeMore = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 115%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  cursor: pointer;
`;

export const GroupRecent = styled(GroupHeader)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  ${GroupTitle}, ${GroupDescription} {
    color: #080f6c;
  }
  ${GroupTitle} {
    white-space: nowrap;
  }
  ${GroupDescription} {
    max-width: 607px;
    margin: 0 0 0 auto;
  }
`;
