import styled from "styled-components";
import PrimaryButton from "components/base/primaryButton";
import { Avatar } from "antd";

export const StatusButton = styled(PrimaryButton)`
  background: rgb(255, 153, 0);
  color: rgb(255, 255, 255);
  position: absolute;
  top: 0px;
  right: 0;
  transition: width 0.5s;
  &:hover {
    background: rgb(255, 153, 0);
    color: rgb(255, 255, 255);
  }
  text-transform: capitalize;
  height: 30px !important;
  padding: 10px 0 10px 5px !important ;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 0.25rem !important;
  svg {
    margin-right: 0 !important ;
  }
`;
export const CardContainer = styled.div`
  position: relative;
  // height: 470px;
  // width: calc((100% - 23px * 2) / 3);
  border-radius: 0.25rem;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  grid-gap: 1.25rem 1.25rem;
  background: white;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    box-shadow: rgb(0 0 0 / 57%) 2px 5px 9px;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 8rem;
  object-fit: cover;
  border-radius: 0.25rem 0.25rem 0 0;
`;

export const CategoryList = styled.div`
  display: flex;
  margin-top: 1rem;
`;
export const Category = styled.div`
  background: rgba(244, 120, 32, 0.08);
  border: 0.0625rem solid rgba(244, 120, 32, 0.5);
  border-radius: 1.25rem;
  font-family: "Rubik";
  font-size: 0.8125rem;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #f47820;
  margin-right: 0.5rem;
  padding: 3px 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const CategoryGreen = styled(Category)`
  background: rgba(0, 209, 150, 0.08);
  border: 0.0625rem solid rgba(0, 209, 150, 0.5);
  color: #00d196;
`;

export const Sponsor = styled.div`
  color: rgba(41, 46, 115, 0.82);
  display: flex;
  align-items: center;
  height: 1.25rem;
  .sponsor-by {
    margin-right: 0.625rem !important;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: rgba(41, 46, 115, 0.82);
    width: max-content;
    height: 20px;
    display: flex;
    align-items: center;
  }
  margin-top: 1.0625rem;
  a {
    width: 20px;
    height: 20px;
    margin-right: 0.25rem;
  }
`;

export const SponsorIcon = styled(Avatar)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* margin-right: 0.25rem; */
`;

export const Title = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #080f6c;
  /* width: 16.3125rem; */
  height: 50px;
  margin-top: 0.5rem;
  word-break: break-word;
`;
export const Content = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  height: 95px;
  word-break: break-word;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
`;

export const CardContent = styled.div`
  padding: 0 1rem;
`;

export const CardFooter = styled.div`
  border-top: 0.0625rem solid rgba(41, 46, 115, 0.12);
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 0.6875rem 1rem;
  align-items: center;
`;

export const ActionButton = styled(PrimaryButton)`
  width: 8.3rem;
  height: 32px;
  font-size: 0.875rem;
  /* padding: 0; */
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  display: flex;
  justify-content: center;
  span {
    font-weight: 500;
    font-size: 14px;
    font-family: "Rubik";
    font-style: normal;
    line-height: 115%;
  }
`;

export const Timer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  gap: 0.6875rem;

  width: 7.6875rem;
  height: 1.75rem;
  border: 0.0625rem solid #ff5722;
  border-radius: 1.25rem;
  span {
    color: #ff5722;
    font-weight: 600;
    font-size: 0.8125rem;
    letter-spacing: -0.025rem;
  }
`;
