import { Input, Upload } from "antd";
import BaseModal from "components/base/baseModal";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import TagWithIcon from "components/base/tagWithIcon";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 38px;

  .ant-tree {
    min-width: 280px;
    width: 280px;
  }
  .ant-tree-treenode::before {
    height: 100%;
  }
  .ant-tree-treenode-selected:before {
    background: rgba(156, 138, 193, 0.16) !important;
    height: 100%;
    .ant-tree-node-selected {
      background-color: unset;
    }
  }
  .ant-tree-treenode-selected .ant-tree-switcher {
    color: unset !important;
  }

  .ant-tree.ant-tree-block-node
    .ant-tree-list-holder-inner
    .ant-tree-node-content-wrapper {
    min-width: 0;
    max-width: 85%;
    display: flex;
    align-items: center;
  }

  .ant-tree-node-selected {
    color: #7e00fd !important;
  }

  .section,
  .activity {
    /* padding-bottom: unset; */
    padding: 11px 12px;
    width: 100%;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    cursor: pointer;
    .ant-tree-iconEle {
      display: none;
    }
  }
  .section {
    .ant-tree-switcher {
      display: flex;
      align-items: center;
      width: fit-content;
      .anticon > svg {
        height: 15px;
        width: 15px;
      }
    }
    .ant-tree-node-content-wrapper {
      flex-grow: 1;
      span {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 115%;
        color: #080f6c;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .activity {
    padding-left: 24px;
  }
  .activity > .ant-tree-node-content-wrapper {
    flex-grow: 1;
    span {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: -0.02em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .activity {
    .ant-tree-indent {
      display: none;
    }
  }
`;

export const WrapBlock = styled.div`
  height: 22px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(8, 15, 108, 0.12);
  border-radius: 20px;
`;

export const Content = styled.div`
  min-height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div {
    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #080f6c;
      word-break: break-word;
    }
    div {
      word-break: break-word !important;
    }
  }
`;

export const Previous = styled(OutlinedButton)`
  gap: 10px;
  height: 40px;
  padding: unset;
`;

export const SubmitOrNext = styled(PrimaryButton)`
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  span {
    color: inherit;
  }
`;

export const TagWithIconStyled = styled(TagWithIcon)``;

export const WrapFoot = styled.div`
  /* display: flex; */
  /*justify-content: space-between;
  align-items: center; */
  & > ${Previous} {
    float: left;
  }
  position: relative;
  & > ${TagWithIconStyled} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > ${SubmitOrNext} {
    float: right;
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
`;

export const BaseModalCustom = styled(BaseModal)`
  .ant-modal-header {
    padding-top: 40px !important;
    padding-bottom: 24px !important;
    border-radius: 0.5rem;
    border-bottom: unset;
    .ant-modal-title {
      text-align: center;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 115%;
      align-items: center;
      letter-spacing: -0.04em;
      color: #080f6c;
    }
  }
  .ant-modal-body {
    padding: 0px 32px 32px 32px !important;
  }
`;

export const ModalWrap = styled.div`
  display: grid;
  gap: 24px;
`;

export const CourseDescription = styled.div`
  word-break: break-word;
  color: #080f6c;
  font-size: 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-align: left;
  width: 100%;
  .question {
    p {
      margin-bottom: 12px;
    }
  }
`;

export const ReadyTag = styled.span`
  position: relative;
  right: 16px;
  padding: 4px;
  background-color: #00de8c;
  border-radius: 10px;
  color: #fff;
`;

export const StyledInput = styled(Input)`
  max-width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledUpload = styled(Upload)`
  .ant-upload-list {
    width: 100%;
    .ant-upload-list-picture-card-container {
      display: block;
      margin: auto;
    }
    .ant-upload-list-picture-card-container {
      width: 18.75rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .ant-upload-list-item {
      border: 0.0625rem solid rgba(41, 46, 115, 0.22);
    }
  }
  .ant-upload {
    width: 100%;
    height: 8rem;
    &.ant-upload-select-picture-card {
      border-radius: 0.25rem;
      border: 0.0625rem dashed rgba(41, 46, 115, 0.12);
    }
  }
  &.noBorder {
    .ant-upload-select-picture-card {
      border: none;
    }
  }
  div.ant-upload:hover {
    border-color: #40a9ff;
  }
`;

export const ChooseImageButton = styled.div`
  width: 102px;
  height: 32px;
  background: rgba(126, 0, 253, 0.1);
  border: 1px solid #7e00fd;
  border-radius: 4px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  color: #080f6c;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FooterModal = styled.div`
  justify-content: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const NoFileText = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  margin-left: 0.9rem;
`;
export const CoverImage = styled.img``;

export const WrapUploadButton = styled.div`
  display: flex;
  align-items: center;
`;

export const PollWrap = styled.div`
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid rgba(41, 46, 115, 0.12);
  background: linear-gradient(
      0deg,
      rgba(156, 138, 193, 0.04) 0%,
      rgba(156, 138, 193, 0.04) 100%
    ),
    #fff;

  h1 {
    color: #080f6c;
    font-size: 20px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 24px;
  }

  .ant-checkbox-group,
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto !important;
    max-height: 280px;
  }

  label {
    margin: 0 !important;
    gap: 10px;
    span {
      display: inline-flex;
      align-items: end;
    }
  }

  .item {
    border-radius: 4px;
    border: 1px solid rgba(156, 138, 193, 0.16);
    background: #fff;
    display: flex;
    padding: 12px 8px;
    align-items: center;
  }
  span {
    color: rgba(41, 46, 115, 0.82);
    font-size: 14px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
`;
