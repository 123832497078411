import { FC } from "react";
import { IFlexboxProps } from "./flexbox.props";
import { StyledFlexbox } from "./flexbox.style";

const FlexBox: FC<IFlexboxProps> = ({
  children,
  ...props
}: IFlexboxProps) => {
  return <StyledFlexbox {...props}>{children}</StyledFlexbox>;
};

export default FlexBox;
