import OutlinedButton from "components/base/outlinedButton";
import styled from "styled-components";

export const Container = styled.div<{ column: number }>`
  height: fit-content;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  background: white;
  padding: 20px 24px 20px 24px;
  display: grid;
  grid-template-columns: ${(p) => `repeat(${p.column}, 1fr)`};
  grid-column-gap: 36px;
  align-items: center;
  &:hover {
    background: linear-gradient(
      180deg,
      rgba(126, 0, 253, 0.0672) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border: 1px solid rgba(156, 138, 193, 0.16);
  }
`;

export const ViewButton = styled(OutlinedButton)`
  width: fit-content;
  padding: 8px;
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 115%;
    color: #7e00fd;
    display: flex;
    align-items: center;
  }
`;

export const WithdrawButton = styled(ViewButton)`
  width: 150px;
`;
