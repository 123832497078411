import { useMutation } from "@tanstack/react-query";
import { Checkbox, Form, Radio, Tree, UploadProps, message } from "antd";
import {
  ACTIVITY_STATUS,
  IActivity,
  IAssignmentForm,
  ISection,
  LESSON_ASSIGNMENT_CATEGORY,
} from "api/course";
import {
  getActivityDetailForLearner,
  submitAssignmentAPI,
} from "api/course/request";
import { postUploadsAPI } from "api/users";
import { CompletedIcon } from "assets/icons/complete.icon";
import { LockIcon } from "assets/icons/lock.icon";
import { BackIcon } from "assets/icons/navArrow.icon";
import axios, { AxiosError } from "axios";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { useWatch } from "rc-field-form";
import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { setPageLoading } from "redux/slices";
import { validateImage } from "utils/regex";
import { requiredRules } from "utils/validatorRules";
import FinishedModal from "../FinishedModal";
import { IPathProps } from "./path.props";
import {
  BaseModalCustom,
  ChooseImageButton,
  Container,
  Content,
  CourseDescription,
  CoverImage,
  FooterModal,
  ModalWrap,
  NoFileText,
  PollWrap,
  Previous,
  ReadyTag,
  StyledInput,
  StyledUpload,
  SubmitOrNext,
  TagWithIconStyled,
  WrapBlock,
  WrapFoot,
  WrapUploadButton,
} from "./path.style";

const { DirectoryTree } = Tree;

const Path: FC<IPathProps> = ({ course, refetchCourse, ...props }) => {
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [sectionSelected, setSectionSelected] = useState<ISection>();
  const [activitySelected, setActivitySelected] = useState<IActivity>();
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const { mutateAsync: getActivitySelectedMutate, status: statusFetchAct } =
    useMutation(getActivityDetailForLearner, {
      onSuccess: (res) => {
        const activity: IActivity = res.responseData;
        setActivitySelected(activity);
        fileRef.current = null;
      },
      onError: (err: AxiosError) => message.error(err.message),
    });

  const { mutateAsync: submitAssignmentMutate, isLoading } =
    useMutation(submitAssignmentAPI);
  const treeData = useMemo(() => {
    if (course && course.sections)
      return course.sections.map((section: ISection) => ({
        title: section.title,
        className: "section",
        key: section._id,
        status: "IDE",
        children: section.activities.map((activity: IActivity) => {
          const isDisable =
            !activity.status || activity.status === ACTIVITY_STATUS.BLOCK;
          const isReady = activity.status === ACTIVITY_STATUS.READY;
          return {
            className: "activity",
            title: activity.title,
            key: `${section._id}-${activity._id}`,
            disabled: isDisable,
            status: activity.status,
            switcherIcon: isDisable ? (
              <WrapBlock>
                <LockIcon />
              </WrapBlock>
            ) : (
              isReady && <ReadyTag>Ready</ReadyTag>
            ),
            isLeaf: true,
          };
        }),
      }));
  }, [course]);

  const isLastItem = useMemo(
    () =>
      treeData &&
      treeData.length > 0 &&
      (treeData.slice(-1)[0].children.length > 0
        ? selectedKeys[0] === treeData.slice(-1)[0].children.slice(-1)[0].key
        : selectedKeys[0] === treeData.slice(-1)[0].key),
    [selectedKeys, treeData]
  );

  useEffect(() => {
    if (
      treeData &&
      selectedKeys.length === 0 &&
      course &&
      course.sections?.length > 0
    ) {
      const sectionExpand = course.sections.find((section: ISection) =>
        section.activities.some(
          (activity: IActivity) => activity.status === ACTIVITY_STATUS.READY
        )
      );
      if (sectionExpand) {
        setSelectedKeys([sectionExpand?._id!]);
      } else setSelectedKeys([treeData[0].key!]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const keyListWithStatus = useMemo(() => {
    if (treeData && treeData.length > 0) {
      return treeData?.reduce((acc: any[], parent) => {
        return [
          ...acc,
          { key: parent.key as string, status: parent?.status },
          ...parent.children.map((child) => ({
            key: child.key,
            status: child.status,
          })),
        ];
      }, []);
    }
  }, [treeData]);

  useEffect(() => {
    if (treeData) setExpandedKeys(treeData.map((item) => item.key!));
  }, [treeData]);

  useEffect(() => {
    if (selectedKeys && selectedKeys.length > 0) {
      const arr = selectedKeys[0].toString().split("-");
      // Children
      if (arr.length === 2) {
        setExpandedKeys((prev: any) =>
          prev.includes(arr[0]) ? [...prev] : [...prev, arr[0]]
        );
        getActivitySelectedMutate({
          courseId: course._id!,
          activityId: arr[1],
        });
      }
      //Parent
      else {
        const section = course.sections.find(
          (section) => section._id === arr[0]
        );
        setSectionSelected(section);
        setActivitySelected(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys, course?._id]);

  const [isShowModal, setIsShowModal] = useState(false);
  const fileRef = useRef<any>(null);
  const spinCoverRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const fileNameCover = useWatch("fileUrl", form);
  const { mutate: postUploads } = useMutation(postUploadsAPI, {
    onSuccess: async (rsUpload: any) => {
      dispatch(setPageLoading(true));
      await axios
        .put(rsUpload.signedRequest, fileRef.current)
        .then((rs: any) => {
          if (spinCoverRef.current) {
            form.setFieldValue("fileUrl", rsUpload.url);
            fileRef.current.src = rsUpload.url;
            spinCoverRef.current = false;
            dispatch(setPageLoading(false));
          }
        })
        .catch((err: AxiosError) => {
          message.error("Error uploading: " + err.message);
        });
    },
    onError: (err: AxiosError) => {
      message.error(err.message);
    },
  });
  const upLoadCoverImage: UploadProps = {
    // accept: acceptImage,
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        spinCoverRef.current = true;
        fileRef.current = file;
        postUploads({
          fileName: file.name,
          fileType: file.type,
        });
        return false;
      }
    },
  };

  const handleSubmitAssignment = (value: IAssignmentForm) => {
    let value_ = { ...value };
    if (
      activitySelected?.assignments?.category ===
        LESSON_ASSIGNMENT_CATEGORY.POLL &&
      !activitySelected.assignments.multiValid
    )
      value_ = { pollOptions: [(value as any).pollOptions] };
    submitAssignmentMutate({
      activityId: activitySelected?._id!,
      assignment: value_,
      courseId: course._id!,
    })
      .then(() => {
        message.success("Submit assignment successfully!");
        refetchCourse();
        handleClose();
        getActivitySelectedMutate({
          courseId: course._id!,
          activityId: activitySelected?._id!,
        });
      })
      .catch((err: AxiosError) => message.error(err.message));
  };
  const handleClose = () => {
    fileRef.current = null;
    setIsShowModal(false);
  };

  const [showFinishedModal, setShowFinishedModal] = useState(false);

  const handleClickPrev = () => {
    if (keyListWithStatus && keyListWithStatus.length > 0) {
      const foundCurSectionId = course.sections.findIndex(
        (sec) => sec._id === selectedKeys[0]
      );
      const currentReady = keyListWithStatus?.find(
        (item) => item.status === ACTIVITY_STATUS.READY
      );

      if (foundCurSectionId > 0) {
        const prevSection = course.sections[foundCurSectionId - 1];
        const childBeforeCurSection =
          prevSection.activities[prevSection.activities.length - 1];
        const isDisable =
          !childBeforeCurSection.status ||
          childBeforeCurSection.status === ACTIVITY_STATUS.BLOCK;

        if (isDisable) {
          setSelectedKeys([currentReady.key]);
          return;
        }

        const index = keyListWithStatus?.findIndex(
          (item) => item.key === selectedKeys[0]
        );
        setSelectedKeys([keyListWithStatus[index - 1].key]);
      } else {
        const index = keyListWithStatus?.findIndex(
          (item) => item.key === selectedKeys[0]
        );
        const isNextItemIsBlock =
          keyListWithStatus?.[index - 1].status === ACTIVITY_STATUS.BLOCK;

        setSelectedKeys([
          isNextItemIsBlock
            ? currentReady.key
            : keyListWithStatus[(index as number) - 1].key,
        ]);
      }
    }
  };

  const handleClickNext = () => {
    if (keyListWithStatus && keyListWithStatus.length > 0) {
      const foundSection = course.sections.find(
        (sec) => sec._id === selectedKeys[0]
      );
      const currentReady = keyListWithStatus?.find(
        (item) => item.status === ACTIVITY_STATUS.READY
      );

      if (foundSection) {
        const nextChild = foundSection.activities[0];
        const isDisable =
          !nextChild.status || nextChild.status === ACTIVITY_STATUS.BLOCK;

        if (isDisable) {
          setSelectedKeys([currentReady.key]);
          return;
        }

        const index = keyListWithStatus?.findIndex(
          (item) => item.key === selectedKeys[0]
        );
        setSelectedKeys([keyListWithStatus[index + 1].key]);
      } else {
        const index = keyListWithStatus?.findIndex(
          (item) => item.key === selectedKeys[0]
        );

        const isNextItemIsBlock =
          keyListWithStatus?.[index + 1].status === ACTIVITY_STATUS.BLOCK;

        setSelectedKeys([
          isNextItemIsBlock
            ? currentReady.key
            : keyListWithStatus[(index as number) + 1].key,
        ]);
      }
    }
  };

  const [optionValids, setOptionValids] = useState<string[]>([]);
  useEffect(() => {
    if (
      activitySelected?.answer?.pollOptions &&
      activitySelected?.answer?.pollOptions.length > 0
    ) {
      setOptionValids(activitySelected?.answer?.pollOptions);
    } else setOptionValids([]);
  }, [activitySelected?.answer?.pollOptions]);

  return (
    <Container>
      <DirectoryTree
        multiple
        treeData={treeData}
        onSelect={(value) => setSelectedKeys(value)}
        selectedKeys={selectedKeys}
        expandedKeys={expandedKeys}
        onExpand={(remainKeys) => setExpandedKeys(remainKeys)}
      />
      <Content>
        {activitySelected && (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1>{activitySelected?.title}</h1>
              {activitySelected?.answer && (
                <SubmitOrNext onClick={() => setIsShowModal(true)}>
                  Re-Submit
                </SubmitOrNext>
              )}
            </div>
            <div className="ck-content">
              {parse(DOMPurify.sanitize(activitySelected?.content ?? ""))}
            </div>
          </div>
        )}

        {sectionSelected && !activitySelected && (
          <div>
            <h1>{sectionSelected?.title}</h1>
            <div className="ck-content">
              {parse(DOMPurify.sanitize(sectionSelected?.description ?? ""))}
            </div>
          </div>
        )}

        <WrapFoot>
          <Previous
            icon={<BackIcon />}
            onClick={handleClickPrev}
            disabled={
              (treeData &&
                treeData.length > 0 &&
                selectedKeys[0] === treeData[0].key) ||
              !course?.isJoinCourse
            }
          >
            Previous
          </Previous>

          {activitySelected?.answer && (
            <TagWithIconStyled color="#1CC98A">
              <CompletedIcon></CompletedIcon>
              <span>Assignment submitted</span>
            </TagWithIconStyled>
          )}

          {activitySelected && !activitySelected?.answer && (
            <SubmitOrNext onClick={() => setIsShowModal(true)}>
              Submit Assignment
            </SubmitOrNext>
          )}

          {!isLastItem && (!activitySelected || activitySelected?.answer) && (
            <SubmitOrNext
              onClick={handleClickNext}
              disabled={!course?.isJoinCourse}
            >
              Next
            </SubmitOrNext>
          )}

          {isLastItem &&
            activitySelected?.answer &&
            statusFetchAct === "success" && (
              <SubmitOrNext onClick={() => setShowFinishedModal(true)}>
                Finish and Share
              </SubmitOrNext>
            )}
        </WrapFoot>
      </Content>
      <BaseModalCustom
        visible={isShowModal}
        centered
        width={"624px"}
        onCancel={handleClose}
        destroyOnClose
        title={
          activitySelected?.assignments?.category ===
          LESSON_ASSIGNMENT_CATEGORY.TEXT
            ? "Type the answer"
            : activitySelected?.assignments?.category ===
              LESSON_ASSIGNMENT_CATEGORY.FILE
            ? "Upload File"
            : "Submit assessment"
        }
        closable
      >
        <ModalWrap>
          <CourseDescription className="ck-content">
            <p> Question: </p>
            <div className="question">
              {parse(
                DOMPurify.sanitize(
                  activitySelected?.assignments?.question ?? ""
                )
              )}
            </div>
          </CourseDescription>
          <Form form={form} onFinish={handleSubmitAssignment} preserve={false}>
            {activitySelected?.assignments?.category ===
              LESSON_ASSIGNMENT_CATEGORY.TEXT && (
              <Form.Item
                name="content"
                rules={[requiredRules]}
                initialValue={activitySelected.answer?.content}
              >
                <StyledInput.TextArea
                  placeholder="type here..."
                  rows={6}
                  style={{ borderRadius: "6px" }}
                />
              </Form.Item>
            )}

            {activitySelected?.assignments?.category ===
              LESSON_ASSIGNMENT_CATEGORY.FILE && (
              <Form.Item
                name="fileUrl"
                rules={[requiredRules]}
                initialValue={activitySelected.answer?.fileUrl}
              >
                <StyledUpload
                  {...upLoadCoverImage}
                  listType="picture-card"
                  maxCount={1}
                >
                  {(fileNameCover || fileRef?.current) &&
                    (validateImage.test(fileNameCover) ||
                    fileRef?.current?.type?.includes("image") ? (
                      <CoverImage
                        height="100px"
                        width="100px"
                        src={fileRef?.current?.src ?? fileNameCover}
                      />
                    ) : (
                      fileRef?.current?.name ?? fileNameCover.split("/").pop()
                    ))}
                  {!activitySelected?.answer && !fileRef?.current && (
                    <WrapUploadButton>
                      <ChooseImageButton>Choose File</ChooseImageButton>
                      <NoFileText>No File Chosen</NoFileText>
                    </WrapUploadButton>
                  )}
                </StyledUpload>
              </Form.Item>
            )}

            {activitySelected?.assignments?.category ===
              LESSON_ASSIGNMENT_CATEGORY.POLL && (
              <PollWrap style={{ marginBottom: "24px" }}>
                {activitySelected.assignments.multiValid && (
                  <Form.Item
                    name={"pollOptions"}
                    initialValue={optionValids}
                    rules={[
                      { required: true, message: "Please select an option!" },
                    ]}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      {activitySelected.assignments.options.map((item) => (
                        <Checkbox
                          key={item._id}
                          className="item"
                          value={item._id}
                        >
                          {item.value}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                )}
                {!activitySelected.assignments.multiValid && (
                  <Form.Item
                    name={"pollOptions"}
                    initialValue={optionValids[0]}
                    rules={[
                      { required: true, message: "Please select an option!" },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }}>
                      {activitySelected.assignments.options.map((item) => (
                        <Radio key={item._id} className="item" value={item._id}>
                          {item.value}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
              </PollWrap>
            )}

            <FooterModal>
              <OutlinedButton onClick={handleClose} height="48px" width="97px">
                Cancel
              </OutlinedButton>
              <PrimaryButton
                htmlType="submit"
                height="48px"
                width="97px"
                loading={isLoading}
              >
                Submit
              </PrimaryButton>
            </FooterModal>
          </Form>
        </ModalWrap>
      </BaseModalCustom>

      <FinishedModal
        visible={showFinishedModal}
        title={"You did it"}
        onCancel={() => setShowFinishedModal(false)}
        course={course}
      />
    </Container>
  );
};

export default memo(Path);
