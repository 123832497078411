export const StarIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71491 13.7609C9.74908 13.8982 9.86886 14 10.0103 14V14C10.153 14 10.2734 13.8962 10.3073 13.7576C10.414 13.3208 10.6218 12.8509 10.9375 12.3477C11.6418 11.2363 13.0176 10.1455 14.2598 9.79344C14.3985 9.75415 14.5 9.63105 14.5 9.48696V9.48696C14.5 9.34407 14.4001 9.22153 14.2624 9.18333C13.7 9.02728 13.1476 8.7552 12.6133 8.375C11.4556 7.5559 10.5829 6.3602 10.3083 5.24322C10.274 5.10389 10.1527 5 10.0093 5V5C9.86795 5 9.74779 5.10088 9.71303 5.23784C9.57681 5.77456 9.32481 6.29689 8.95703 6.82812C8.1742 7.96379 6.9764 8.81935 5.73456 9.18009C5.5979 9.21979 5.5 9.34257 5.5 9.48487V9.48487C5.5 9.63028 5.60221 9.75465 5.74234 9.79347C6.33188 9.95677 6.92828 10.2639 7.51563 10.707C8.7695 11.6474 9.47501 12.7972 9.71491 13.7609Z"
        fill="#111111"
      />
      <path
        d="M4.84162 6.86719C4.8606 6.94344 4.92714 7 5.00573 7V7C5.085 7 5.1519 6.94232 5.17072 6.86531C5.23002 6.62268 5.34543 6.36159 5.52083 6.08203C5.91208 5.46459 6.67646 4.85863 7.36657 4.66302C7.44359 4.64119 7.5 4.57281 7.5 4.49276V4.49276C7.5 4.41337 7.44452 4.34529 7.36802 4.32407C7.05554 4.23737 6.74868 4.08622 6.45182 3.875C5.80868 3.41994 5.32386 2.75567 5.17127 2.13512C5.15224 2.05771 5.08486 2 5.00514 2V2C4.92664 2 4.85988 2.05605 4.84057 2.13213C4.76489 2.43031 4.62489 2.72049 4.42057 3.01562C3.98566 3.64655 3.32022 4.12186 2.63031 4.32227C2.55439 4.34433 2.5 4.41254 2.5 4.49159V4.49159C2.5 4.57238 2.55678 4.64147 2.63464 4.66304C2.96215 4.75376 3.29349 4.92441 3.61979 5.17057C4.31639 5.69302 4.70834 6.33179 4.84162 6.86719Z"
        fill="#111111"
      />
    </svg>
  );
};
