import { useState, FC, useEffect, useCallback } from "react";
import { DragDropContext } from "@hello-pangea/dnd";
import {
  AddCard,
  AddCardBtn,
  BoardContainer,
  BoardTitle,
  BtnsWrap,
  CardList,
  CardMenuItem,
  // Color,
  // ColorTabs,
  DropdownAntd,
  GlobalStyle,
  ItemFooter,
  ListContainer,
  ListTitle,
  MenuAntd,
  // PopoverAntd,
  SearchInput,
  StyledDraggable,
  StyledDroppable,
  TaskContainer,
  TaskContent,
  TaskContentTitle,
} from "./Board.style";
import { BoardTitleIcon } from "assets/icons/boardTitle.icon";
import { BoardCheckIcon } from "assets/icons/boardCheck.icon";
import {
  BtnPlusIcon,
  EditIcon,
  MenuIcon,
  PlusIcon,
} from "assets/images/project-board";

// import Avatar1 from "assets/images/submit-deliverable/submit-avatar1.png";
// import Avatar2 from "assets/images/submit-deliverable/submit-avatar2.png";
// import Avatar3 from "assets/images/submit-deliverable/submit-avatar3.png";
// import Avatar4 from "assets/images/submit-deliverable/submit-avatar4.png";
// import Pic1 from "assets/images/project-board/BG.svg";
import { SearchIcon } from "assets/icons/search.icon";
import { IBoardProps } from "./Board.props";
import { useMutation } from "@tanstack/react-query";
import { PROJECT_BOARD_ROLE } from "utils/constant";
import { AxiosError } from "axios";
import { addCardAPI, dragdropCardAPI, updateCardAPI } from "api/users";
import BaseModal from "components/base/baseModal";
import { CloseIcon } from "assets/images/deliver-work";
import PrimaryButton from "components/base/primaryButton";
import OutlinedButton from "components/base/outlinedButton";
import { Form, message } from "antd";
import {
  StyledFormItem,
  StyledInput,
  StyledTextArea,
} from "pages/CreateProject/createProject.style";

import { requiredRules } from "utils/validatorRules";
import { useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { DatePickerAtnd } from "pages/Worktasks/CreateWorkTask/CreateWorktask.style";
import moment from "moment";
import AvatarAntdGroup from "components/modules/avatarList";

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: 0,

  // change background colour if dragging
  background: isDragging ? "white" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "rgba(156, 138, 193, 0.22)" : "transparent",
  // : 'rgba(156, 138, 193, 0.04)',
  padding: 0,
  width: "100%",
  // maxHeight: '713px',
  overflow: "auto",
});

// fake data generator
const getItems = (data: any, offset = 0) =>
  data.cards.map((card: any) => ({
    id: `item-${card._id + offset}-${new Date().getTime()}`,
    cardData: card,
  }));

export const Board: FC<IBoardProps> = ({
  setCardData,
  setOpenModal,
  projectBoardDetails,
  refetch,
  role,
  worktaskDetail,
}) => {
  const [state, setState] = useState<any>([]);

  useEffect(() => {
    if (projectBoardDetails) {
      const newState = projectBoardDetails.lanes.map((land: any) =>
        getItems(land)
      );
      setState(newState);
      setSearchItem(newState);
    }
  }, [projectBoardDetails]);

  function onDragEnd(result: any) {
    const { source, destination } = result;

    if (searchText) return;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    const params = {
      id: userId,
      projectId: param.projectId || "",
      packageId: param.packageId || "",
      cardId: state[source.droppableId][source.index].cardData._id,
      body: {
        tocardCategoryId:
          projectBoardDetails.lanes[destination.droppableId]._id,
        dropSequence: destination.index,
      },
    };

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    dragdropCard(params);

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState: any = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];
      setState(newState);
    }
  }

  const [openAddCard, setOpenAddCard] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [typeIndex, setTypeIndex] = useState<number>(0);
  const [beEditedCard, setBeEditedCard] = useState<any>();

  function saveCard() {
    const cardCategoryId = projectBoardDetails.lanes[typeIndex]._id;
    const title = form.getFieldValue("title");
    const descText = form.getFieldValue("descText");
    const startDate = Number(
      form.getFieldValue("startDate").endOf("day").format("x")
    );
    const endDate = Number(
      form.getFieldValue("endDate").endOf("day").format("x")
    );
    if (beEditedCard) {
      const updateParams = {
        projectId: param.projectId || "",
        packageId: param.packageId || "",
        cardId: beEditedCard._id,
        body: {
          title,
          descText,
          startDate,
          endDate,
        },
      };

      updateCard(updateParams);
      return;
    }
    const params = {
      id: userId,
      projectId: param.projectId || "",
      packageId: param.packageId || "",
      body: {
        cardCategoryId,
        title,
        descText,
        startDate,
        endDate,
      },
    };

    addCard(params);
  }

  function openAddCardModal(ind: number) {
    if (worktaskDetail?.workStatus === "COMPLETED") return;

    setTypeIndex(ind);
    setOpenAddCard(true);
  }

  function openEditCardModal(card: any) {
    setBeEditedCard(card);
    // setTypeIndex(ind);
  }

  useEffect(() => {
    if (!beEditedCard) {
      setOpenAddCard(false);
      form.resetFields();
      return;
    }

    form.setFieldValue("title", beEditedCard?.title);
    form.setFieldValue("descText", beEditedCard?.descText);
    form.setFieldValue("startDate", moment(beEditedCard?.startDate));
    form.setFieldValue("endDate", moment(beEditedCard?.endDate));

    setOpenAddCard(true);
  }, [beEditedCard, form]);

  const { mutate: updateCard } = useMutation(updateCardAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        refetch();
        setBeEditedCard(undefined);
      } else message.warning(responseMessage);
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const userId: string = useAppSelector((state) => state.auth.user?._id);
  const param = useParams();

  const { mutate: addCard } = useMutation(addCardAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        // navigate(-1);

        refetch();
        setOpenAddCard(false);
        form.resetFields();
      } else {
        message.error(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const { mutate: dragdropCard } = useMutation(dragdropCardAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        refetch();
      } else {
        message.error(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const [searchItem, setSearchItem] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");

  const searchLane = useCallback(
    (lane: any) => {
      return (
        lane?.filter((card: any) => {
          const title = card?.cardData?.title?.toLowerCase();
          return title?.includes(searchText?.toLowerCase());
        }) || []
      );
    },
    [searchText]
  );

  useEffect(() => {
    if (searchText) {
      const newState = searchItem?.map((lane: any) => searchLane(lane));
      setState([...newState]);
    } else {
      setState([...searchItem]);
    }
  }, [searchText, searchLane, searchItem]);

  // date rule
  const dateRule = {
    validator: (_: any, value: string) => {
      const endDateMoment = form.getFieldValue("endDate");
      const startDateMoment = form.getFieldValue("startDate");

      if (!endDateMoment || !startDateMoment) return Promise.resolve();

      const endDate = endDateMoment.endOf("day").format("x");
      const startDate = startDateMoment.startOf("day").format("x");

      if (endDate < startDate) {
        return Promise.reject(new Error("End Date must be after Start Date!"));
      }

      return Promise.resolve();
    },
  };

  function disabledEndDate(current: moment.Moment) {
    const rule1 = current.isBefore(moment().startOf("day"));
    let rule2 = false;
    const startDate = form.getFieldValue("startDate")?.startOf("day");
    if (startDate) {
      rule2 = current.isBefore(startDate);
    }
    return rule1 || rule2;
  }

  function disabledStartDate(current: moment.Moment) {
    const rule1 = current.isBefore(moment().startOf("day"));
    let rule2 = false;
    const endDate = form.getFieldValue("endDate")?.endOf("day");
    if (endDate) {
      rule2 = current.isAfter(endDate);
    }
    return rule1 || rule2;
  }

  function isPermission(role: string, createdBy: any) {
    if (!role) return false;
    if (!createdBy) return false;
    if (role === PROJECT_BOARD_ROLE.COLLABORATOR && userId !== createdBy)
      return false;
    return true;
  }

  return (
    <BoardContainer>
      <GlobalStyle />
      <BoardTitle>
        Project Boards
        <div>
          <SearchInput
            className="search"
            type="search"
            placeholder="Search Tasks"
            suffix={<SearchIcon />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </BoardTitle>
      <ListContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          {state.map((land: any, ind: number) => (
            <StyledDroppable key={ind} droppableId={`${ind}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <ListTitle>
                    <div>
                      <div>
                        <BoardTitleIcon />{" "}
                        {projectBoardDetails?.lanes[ind]?.laneTitle}
                      </div>
                      <div>
                        <span onClick={() => openAddCardModal(ind)}>
                          <PlusIcon />
                        </span>
                      </div>
                    </div>
                  </ListTitle>
                  <CardList>
                    {land.map((card: any, index: any) => (
                      <StyledDraggable
                        key={card?.id}
                        draggableId={card?.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TaskContainer
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            onClick={() => {
                              setCardData(card?.cardData || {});
                              setOpenModal(true);
                            }}
                          >
                            <div>
                              <TaskContent>
                                <TaskContentTitle>
                                  <BoardCheckIcon />
                                  {card?.cardData?.title}
                                  {isPermission(
                                    role,
                                    card?.cardData?.createdBy
                                  ) && (
                                    <DropdownAntd
                                      trigger={["click"]}
                                      overlay={
                                        <MenuAntd
                                          onClick={({ domEvent }: any) => {
                                            domEvent.stopPropagation();
                                          }}
                                        >
                                          <MenuAntd.Item>
                                            <CardMenuItem
                                              onClick={() =>
                                                openEditCardModal(
                                                  card?.cardData
                                                )
                                              }
                                            >
                                              <EditIcon />
                                              Edit
                                            </CardMenuItem>
                                          </MenuAntd.Item>
                                        </MenuAntd>
                                      }
                                      placement="bottomRight"
                                      onVisibleChange={(visible: boolean) => {
                                        openEditCardModal(undefined);
                                      }}
                                    >
                                      <span
                                        className="card-menu"
                                        onClick={(e: any) =>
                                          e.stopPropagation()
                                        }
                                      >
                                        <MenuIcon />
                                      </span>
                                    </DropdownAntd>
                                  )}
                                </TaskContentTitle>
                              </TaskContent>
                              <ItemFooter>
                                <AvatarAntdGroup
                                  collabs={
                                    card?.cardData?.profiles?.map(
                                      (profile: any) => {
                                        return {
                                          url: profile?.profilePicture,
                                          username:
                                            profile?.username ||
                                            profile?.emailId.split("@")[0],
                                          id: profile?._id,
                                        };
                                      }
                                    ) || []
                                  }
                                  size={24}
                                  max={5}
                                  prefix={
                                    <span>
                                      {moment(card?.cardData?.endDate).format(
                                        "MMM Do"
                                      )}
                                    </span>
                                  }
                                />
                              </ItemFooter>
                            </div>
                          </TaskContainer>
                        )}
                      </StyledDraggable>
                    ))}
                    <AddCardBtn onClick={() => openAddCardModal(ind)}>
                      <BtnPlusIcon />
                      add a card
                    </AddCardBtn>
                    {provided.placeholder}
                  </CardList>
                </div>
              )}
            </StyledDroppable>
          ))}
        </DragDropContext>
      </ListContainer>

      <BaseModal visible={openAddCard} centered width={624}>
        <AddCard>
          <CloseIcon
            callback={() => {
              setOpenAddCard(false);
              openEditCardModal(undefined);
            }}
          />
          <Form
            form={form}
            layout="vertical"
            onFinish={saveCard}
            scrollToFirstError={true}
          >
            <StyledFormItem
              label="Task Name"
              name="title"
              rules={[requiredRules]}
            >
              <StyledInput placeholder="Enter Your Task" />
            </StyledFormItem>
            <StyledFormItem
              label="Description"
              name="descText"
              rules={[requiredRules]}
            >
              <StyledTextArea placeholder="Enter Task Description" />
            </StyledFormItem>
            <StyledFormItem
              name="startDate"
              rules={[requiredRules, dateRule]}
              label="Start Date"
            >
              <DatePickerAtnd
                placeholder="Start Date"
                disabledDate={disabledStartDate}
              />
            </StyledFormItem>
            <StyledFormItem
              name="endDate"
              rules={[requiredRules, dateRule]}
              label="End Date"
            >
              <DatePickerAtnd
                placeholder="End Date"
                disabledDate={disabledEndDate}
              />
            </StyledFormItem>
            <BtnsWrap>
              <PrimaryButton width="100px" height="32px" htmlType="submit">
                Save
              </PrimaryButton>
              <OutlinedButton
                width="100px"
                height="32px"
                onClick={() => {
                  setOpenAddCard(false);
                  form.resetFields();
                }}
              >
                Cancel
              </OutlinedButton>
            </BtnsWrap>
          </Form>
        </AddCard>
      </BaseModal>
    </BoardContainer>
  );
};
