export const CMCIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4103 9.30073C12.1838 9.44772 11.917 9.46593 11.7141 9.34854C11.4565 9.19927 11.3149 8.84967 11.3149 8.3635V6.90886C11.3149 6.20642 11.0443 5.70658 10.5918 5.57163C9.82492 5.34236 9.2482 6.30528 9.03145 6.66691L7.67901 8.91732V6.16675C7.6638 5.5339 7.46353 5.15537 7.08328 5.04122C6.83168 4.96577 6.45522 4.99602 6.08955 5.57032L3.05955 10.5635C2.6539 9.77318 2.44244 8.89317 2.44354 7.99992C2.44354 4.99211 4.79224 2.54528 7.67901 2.54528C10.5658 2.54528 12.9142 4.99211 12.9142 7.99992C12.9142 8.00512 12.9154 8.00967 12.9158 8.01455C12.9158 8.01976 12.9148 8.02431 12.9151 8.02951C12.9424 8.61195 12.7586 9.07504 12.4103 9.30073ZM14.0774 8.00024V7.98496L14.0771 7.97C14.061 4.30691 11.1973 1.33325 7.6787 1.33325C4.15088 1.33325 1.28027 4.32382 1.28027 7.99992C1.28027 11.6757 4.15088 14.6666 7.67901 14.6666C9.29795 14.6666 10.8431 14.035 12.0291 12.8887C12.2649 12.6611 12.2788 12.2776 12.0602 12.0324C12.0087 11.9742 11.9465 11.9271 11.877 11.8939C11.8076 11.8606 11.7324 11.8419 11.6558 11.8389C11.5793 11.8359 11.5029 11.8486 11.4312 11.8762C11.3595 11.9039 11.2939 11.9459 11.2382 11.9999C10.2768 12.9342 9.00305 13.4548 7.67901 13.4546C6.13328 13.4546 4.74249 12.7524 3.7833 11.6389L6.51575 7.13618V9.21195C6.51575 10.209 6.89252 10.5313 7.20845 10.6256C7.52469 10.7199 8.00793 10.6555 8.51526 9.81L10.0182 7.30951C10.0664 7.22886 10.1107 7.15927 10.1513 7.0991V8.3635C10.1513 9.29553 10.5151 10.0409 11.1492 10.408C11.7208 10.7391 12.4395 10.7092 13.0251 10.33C13.7349 9.86984 14.1174 9.02203 14.0774 8.00024Z"
      fill="#17181B"
    />
  </svg>
);
