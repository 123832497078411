import { Tag } from "antd";
import styled from "styled-components";

const { CheckableTag } = Tag;

export const CoverImage = styled.img``;

export const Container = styled.div`
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 1rem;
  line-height: 100%;
  .ant-divider {
    position: absolute;
    left: 0;
  }
  color: #080f6c;
`;

export const AwardWrapper = styled.div`
  width: 100%;
  padding: 28px 24px;
  background: linear-gradient(
    90deg,
    rgba(126, 0, 253, 0.08) 0%,
    rgba(193, 67, 252, 0.08) 61.44%,
    rgba(0, 191, 251, 0.08) 100%
  );
  border-radius: 8px;
`;

export const AwardDesc = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.03em;
`;

export const AwardTagWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  .ant-tag-checkable-checked {
    background: #fff;
    color: #7e00fd;
  }
  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #7e00fd;
  }
  .ant-tag-checkable:active {
    background-color: rgba(126, 0, 253, 0.1);
  }
`;

export const AwardTag = styled(CheckableTag)<{ $disable?: boolean }>`
  padding: 26px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #080f6c;
  background: #ffffff;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  border: none;
  min-width: 314px;
  min-height: 100px;
  gap: 11px;
  width: 100%;
  justify-content: start;
  border: 1px solid rgba(156, 138, 193, 0.16);
  &:active {
    background-color: #fff !important;
  }
  .ant-radio-wrapper {
    margin-left: auto;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #7e00fd;
    &:after {
      background-color: #7e00fd;
    }
  }
`;

export const TagWrapper = styled.div<{ disable: boolean }>`
  ${({ disable }) => {
    return (
      disable &&
      `
      cursor: not-allowed;
      ${AwardTag} {
        pointer-events: none;
      }
    `
    );
  }}
`;

export const AwardBg = styled.div<{ checked: boolean }>`
  background: ${({ checked }: any) =>
    checked
      ? `radial-gradient(100% 445.32% at 0% 100%, #7E00FD 0%, #B93DF3 42.19%, #A54BF4 57.95%, #00BFFB 100%)`
      : ``};
  border-radius: 8px;
  padding: 2px;
`;

export const AwardModalDesc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgba(41, 46, 115, 0.82);
`;

export const AwardModalWrapper = styled.div`
  display: grid;
  margin-top: 20px;
  gap: 10px;
  ${AwardTag} {
    min-height: 64px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 14px;
  .ant-btn {
    width: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    gap: 4px;
    height: 36px;
  }
`;

export const FormWrapper = styled.div`
  margin-top: 48px;
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 100%;
  }
  .ant-input-number,
  .ant-input-number-input-wrap {
    width: 100%;
  }
`;

export const BudgetNumber = styled.div`
  font-weight: 700;
  text-align: right;
`;

export const TotalBudget = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080f6c;
`;
