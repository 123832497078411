import { Button } from "antd";
import styled from "styled-components";

export const ButtonPrimaryAntd = styled(Button)<{
  width?: string;
  height?: string;
  alignRight?: boolean;
}>`
  border-radius: 0.25rem;
  background: radial-gradient(
    100% 445.32% at 0% 100%,
    #7e00fd 0%,
    #b93df3 42.35%,
    #a54bf4 57.95%,
    #00bffb 100%
  );
  color: #ffffff;
  outline: none;
  border: none;
  font-weight: 500;
  line-height: 115%;
  font-size: 0.875rem;
  padding: 10px;
  width: ${(p) => p.width || "auto!important"};
  height: ${(p) => p.height || "auto!important"};

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg:nth-child(1) {
    margin-right: 0.25rem;
  }

  & > svg:nth-child(2) {
    margin-left: 0.25rem;
    margin-right: 0rem;
  }

  &:hover,
  &:focus {
    background: radial-gradient(
      100% 445.32% at 0% 100%,
      #7e00fd 0%,
      #b93df3 42.35%,
      #a54bf4 57.95%,
      #00bffb 100%
    );
    color: #ffffff;
    /* filter: brightness(88%); */
  }

  a:hover,
  a:focus {
    color: #ffffff;
  }
  a {
    color: #ffffff;
  }
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 115%;
    color: #ffffff;
    display: flex;
    align-items: center;
  }
`;
