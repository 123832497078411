import { FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  CardContainer,
  CardImage,
  Title,
  CardContent,
  Container,
} from "./NFTList.style";
import { INFTList } from "./NFTList.props";
import { AuroraTestnet, useEthers } from "@usedapp/core";
import { Network, Alchemy } from "alchemy-sdk";
import { getTokensOfAccount, NETWORK_KEY } from "web3/getNFT";
import { useDispatch } from "react-redux";
import { setPageLoading } from "redux/slices";

const NFTList: FC<INFTList> = ({ course, NFTIds, setNFTIds }) => {
  const dispatch = useDispatch();
  const { account } = useEthers();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [NFTList, setNFTList] = useState<any[]>([]);
  const contractAddress = course.rewards?.nft?.contractAddress;

  const network: number = course.chainId ?? 0;

  const settings = {
    apiKey: "lIui4Z1fgyWQiBk4gD84YTbD6qmWiYy9", // Replace with your Alchemy API Key.
    network: NETWORK_KEY[network] as Network, // Replace with your network.
    // network: Network.MATIC_MUMBAI, // Replace with your network.
  };

  const alchemy = new Alchemy(settings);

  useEffect(() => {
    if (contractAddress) {
      (async () => {
        dispatch(setPageLoading(true));
        setIsFetching(true);
        if (network === AuroraTestnet.chainId) {
          const listNFT = await getTokensOfAccount(
            1313161555,
            "0x3D7B83Cf8042139Ec54b6B6F5789D2046a8E4bE4",
            account
          );
          setNFTList(listNFT);
          dispatch(setPageLoading(false));
          setIsFetching(false);
        } else {
          alchemy.nft
            .getNftsForOwner(account ?? "", {
              contractAddresses: contractAddress
                ? [contractAddress]
                : ["0x086BdECe06069016F506f90dB30261Af654B7d0a"],
            })
            .then((res) => {
              setNFTList(res.ownedNfts);
              setIsFetching(false);
              dispatch(setPageLoading(false));
            });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractAddress, account]);

  const handleSelectNFT = (tokenId: string) => {
    setNFTIds([tokenId]);
  };

  return (
    <Container>
      {NFTList.map((item) => (
        <CardContainer
          key={item?.tokenId}
          isSelected={NFTIds.includes(item.tokenId)}
        >
          <div onClick={() => handleSelectNFT(item.tokenId)}>
            {!isFetching ? (
              <CardImage
                src={item?.media ? item.media[0]?.thumbnail : item?.image}
              />
            ) : (
              <Skeleton
                style={{ height: "8rem", paddingTop: 0, marginTop: "-30px" }}
              />
            )}

            <CardContent>
              <Title>
                {item.title} {item.name ? `- ${item.name}` : null}
              </Title>
            </CardContent>
          </div>
        </CardContainer>
      ))}
    </Container>
  );
};

export default NFTList;
