import { Button, Collapse, Modal, Rate } from "antd";
import PrimaryButton from "components/base/primaryButton";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SkillTag = styled.div<{ cl?: string }>`
  padding: 4px 12px;
  background: #fff;
  border: 1px solid ${({ cl }) => cl || "rgba(41, 46, 115, 0.82)"};
  color: ${({ cl }) => cl || "rgba(41, 46, 115, 0.82)"};
  border-radius: 40px;
  display: flex;
  align-items: center;
`;

export const EmptyText = styled.div`
  width: 100%;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: rgba(41, 46, 115, 0.82);
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LabelText = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080a25;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const AcceptBtn = styled(PrimaryButton)`
  &.ant-btn[disabled] > * {
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const ConfirmText = styled.span`
  margin-top: 24px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #080f6c;
  max-width: 400px;
`;

export const AcceptContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RejectContentWrapper = styled(AcceptContentWrapper)`
  .ant-form-item {
    margin-top: 24px;
    margin-bottom: 0;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    ):after {
    display: none;
  }
`;

export const ConfirmModal = styled(Modal)``;

export const ItemWrap = styled(Collapse)`
  background: #ffffff;
  border: unset;
`;

export const Item = styled(Collapse.Panel)`
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 8px;
  /* border-bottom: unset; */
  margin-bottom: 32px;
  .ant-collapse-header {
    padding: 24px !important;
    height: 100px;
  }
  .ant-collapse-header-text {
    cursor: default !important;
    pointer-events: none;
    flex: auto !important;
  }
  .ant-collapse-content {
    background-color: unset;
  }
  .ant-collapse-expand-icon {
    display: none;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const UserProfileLink = styled(Link)`
  pointer-events: auto;
`;

export const CollabInfo = styled.div`
  display: flex;
  gap: 14px;

  & > span {
    width: 52px;
    height: 52px;
    border-radius: 4px;
    .anticon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  & > div {
    & > div {
      margin-bottom: 10px;
      display: flex;
      gap: 14px;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.01em;

      ${UserProfileLink} {
        color: #080f6c;
      }
    }
    & > span {
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: rgba(41, 46, 115, 0.42);
      pointer-events: none;
    }
  }
`;
export const Options = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 24px;
  height: max-content;
  .toogle-down {
    svg {
      color: rgba(41, 46, 115, 0.42);
      g {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
  }
  span {
    cursor: pointer;
    pointer-events: initial;
  }
`;
export const SpanWrap = styled.span`
  pointer-events: initial;
  display: flex;
  gap: 8px;
  height: 32px;
  & > span {
    display: flex;
    align-items: center;
  }
`;
export const CollapseToogle = styled.span`
  pointer-events: initial;
  display: flex;
  align-items: center;
  svg {
    color: rgba(126, 0, 253, 0.4);
  }
`;
export const RateAntd = styled(Rate)`
  color: #7e00fd;
  .ant-rate-star:not(:last-child) {
    margin-right: 0px;
  }
`;

export const ContentWrap = styled.div`
  padding: 32px 24px 37px;
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  letter-spacing: -0.01em;
  color: rgba(41, 46, 115, 0.82);
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #080f6c;
  user-select: none;
`;

export const MarginTitle = styled(Title)`
  margin-bottom: 24px;
`;

export const SkillWrap = styled.div`
  display: flex;
  gap: 18px;
  line-height: 120%;
  margin-bottom: 27px;
`;
export const Text = styled.div`
  margin-bottom: 24px;
  user-select: none;
`;
export const ResourcesWrap = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

export const BtnDownloadWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const ButtonAntd = styled(Button)`
  border-radius: 4px;
  border: 1px solid rgba(126, 0, 253, 0.24);
  background: #fff;
  color: #7e00fd;
  outline: none;
  font-weight: 600;
  font-size: 0.875rem;
  width: max-content;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background: #fff;
    color: #7e00fd;
    /* filter: brightness(80%); */
    border: 1px solid rgba(126, 0, 253, 0.24);
  }

  a:hover,
  a:focus {
    color: rgba(126, 0, 253, 1);
  }

  & > div,
  a {
    /* height: 34px; */
    color: rgba(126, 0, 253, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 0.25rem;
    }
  }
`;
export const RejectButton = styled(ButtonAntd)`
  border: 1px solid rgba(41, 46, 115, 0.82);
  color: rgba(41, 46, 115, 0.82);
  width: auto;
  height: 32px;
  &:hover,
  &:focus {
    background: #fff;
    color: rgba(41, 46, 115, 0.82);
    /* filter: brightness(80%); */
    border: 1px solid rgba(41, 46, 115, 0.82);
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  gap: 24px;
  margin: 28px 0px 0px !important;
`;

export const RateAndReviewWrapper = styled.span`
  > span {
    cursor: pointer;
    pointer-events: auto;
  }
`;

export const FeedbackAction = styled(PrimaryButton)``;

export const FeedbackText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  letter-spacing: -0.01em;
  color: rgba(41, 46, 115, 0.82);
`;

export const FeedbackColumnTitle = styled(ConfirmText)`
  margin-top: 0;
  max-width: none;
`;

export const FeedbackMainTitle = styled(ConfirmText)`
  margin-top: 0;
  font-size: 26px;
  max-width: none;
`;

export const ListFeedbackItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
  place-items: center;
`;

export const ListFeedbackWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
