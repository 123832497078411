import { MinusCircleOutlined } from "@ant-design/icons";
import { Form, Row } from "antd";
import { ArrowLeftIcon, ArrowRightIcon, InfoIcon } from "assets/images/Bounty";
import { CameraIcon } from "assets/images/addPortfolio";
import Button from "components/base/button";
import PrimaryButton from "components/base/primaryButton";
import {
  ButtonsWrap,
  FooterWrap,
  LabelWithIcon,
  StyledBountyInput,
  StyledBountyTextArea,
} from "pages/Bounty/Bounty.style";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { requiredRules } from "utils/validatorRules";
import { ISkill } from "../AddPortfolio.props";
import { IAddDetailStepProps } from "./addDetailStep.props";
import {
  AddVideosWrap,
  ColAntd,
  Container,
  ContentBlock,
  FormTextInput,
  Note,
  OptionsText,
  StyledFormItem,
  SubInputBlock,
  WrapBlock,
} from "./addDetailStep.style";
import ProjectFile from "./projectFile";
import SkillTagSystem from "./skillTagSystem";

const allowImgType = ["png", "gif", "jpeg"];
const allowedAudioType = ["mp3", "wav", "mpeg"];
const allowedVideoType = ["mp4", "x-ms-wmv"];
const allowedDocType = ["pdf"];
export const allAllowedType = [
  allowedVideoType,
  allowImgType,
  allowedDocType,
  allowedAudioType,
];

const AddDetailStep: FC<IAddDetailStepProps> = ({
  nextStep,
  projectFormData,
  setProjectFormData,
}) => {
  const [form] = Form.useForm();
  const skills =
    projectFormData.skills?.map((item: any) => ({
      value: item,
      isSelected: true,
    })) || [];
  const [tags, setTags] = useState<ISkill[]>(skills);
  const navigate = useNavigate();
  const { portfolioId } = useParams();
  const isEdit = !!portfolioId;

  const onChangeFiles = (data: any) => {
    setProjectFormData({
      ...projectFormData,
      files: data.map((item: any) => {
        const type = allAllowedType.findIndex((allowType) =>
          allowType.some((t) => item?.url?.includes(t))
        );
        return {
          url: item.url,
          type: type + 1,
          name: item.name,
        };
      }),
    });
  };

  function onSubmit(values: any) {
    setProjectFormData({
      ...projectFormData,
      videos: values.embedUrls,
      projectTitle: values.projectTitle,
      description: values.description,
      skills: tags.map((tag: any) => tag.value),
      projectUrl: values.website,
      files: isEdit
        ? projectFormData.files.map((item: any) => {
            const type = allAllowedType.findIndex((allowType) =>
              allowType.some((t) => (item?.fileLink || item.url)?.includes(t))
            );
            return {
              url: item?.fileLink || item.url,
              type: type + 1,
              name: item?.fileName || item.name,
            };
          })
        : projectFormData.files,
    });
    nextStep();
  }

  useEffect(() => {
    if (projectFormData.files.length) {
      form.setFieldValue("projectFiles", projectFormData.files.length);
    } else form.setFieldValue("projectFiles", undefined);
  }, [form, projectFormData.files]);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  return (
    <>
      <Container form={form} onFinish={onSubmit}>
        <ContentBlock>
          <WrapBlock>
            <Row gutter={[16, 0]}>
              <ColAntd xs={24} sm={24} md={24} lg={24} xl={24}>
                <StyledFormItem
                  label={
                    <LabelWithIcon gap={6}>
                      Project Title <InfoIcon />
                    </LabelWithIcon>
                  }
                  name="projectTitle"
                  labelAlign="left"
                  initialValue={projectFormData.projectTitle}
                  rules={[requiredRules]}
                >
                  <StyledBountyInput placeholder="Enter a brief but descriptive title" />
                </StyledFormItem>
              </ColAntd>
              <ColAntd xs={13} sm={13} md={13} lg={13} xl={13}>
                <StyledFormItem
                  label={
                    <LabelWithIcon gap={6}>
                      Project Files <InfoIcon />
                    </LabelWithIcon>
                  }
                  name="projectFiles"
                  labelAlign="left"
                  rules={[requiredRules]}
                >
                  <ProjectFile
                    onChangeFiles={onChangeFiles}
                    projectFormData={projectFormData}
                    setProjectFormData={setProjectFormData}
                    setIsUploading={setIsUploading}
                  />
                </StyledFormItem>
              </ColAntd>
              <ColAntd xs={11} sm={11} md={11} lg={11} xl={11}>
                <StyledFormItem label="Or, embed a video" labelAlign="left">
                  <Form.List
                    name="embedUrls"
                    initialValue={projectFormData.videos}
                  >
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map((field, index) => (
                            <AddVideosWrap key={field.key}>
                              <StyledFormItem
                                name={[index, "videoName"]}
                                label="Video Name"
                                rules={[requiredRules]}
                              >
                                <FormTextInput placeholder="Add video name" />
                              </StyledFormItem>
                              <StyledFormItem
                                name={[index, "videoLink"]}
                                label="Video Link"
                                rules={[
                                  requiredRules,
                                  {
                                    type: "url",
                                    message: "This field must be a valid url.",
                                  },
                                ]}
                              >
                                <FormTextInput placeholder="Add video link" />
                              </StyledFormItem>
                              <SubInputBlock>
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              </SubInputBlock>
                            </AddVideosWrap>
                          ))}
                          <Form.Item>
                            <Button
                              height="40px"
                              width="182px"
                              background="linear-gradient(263.55deg, rgba(0, 222, 140, 0.1) 1.97%, rgba(197, 138, 255, 0.1) 100%);"
                              color="rgba(8, 10, 37, 0.6)"
                              onClick={() => {
                                add();
                              }}
                            >
                              <CameraIcon />
                              <span>Add Video Link</span>
                            </Button>
                          </Form.Item>
                        </div>
                      );
                    }}
                  </Form.List>
                </StyledFormItem>
              </ColAntd>
              <ColAntd xs={24} sm={24} md={24} lg={24} xl={24}>
                <StyledFormItem
                  label={
                    <LabelWithIcon gap={6}>
                      Short Description <InfoIcon />
                    </LabelWithIcon>
                  }
                  name="description"
                  labelAlign="left"
                  rules={[requiredRules]}
                  initialValue={projectFormData.description}
                >
                  <StyledBountyTextArea placeholder="Write description" />
                </StyledFormItem>
              </ColAntd>
              <ColAntd xs={24} sm={24} md={24} lg={24} xl={24}>
                <StyledFormItem
                  label={
                    <LabelWithIcon gap={6}>
                      Skills and deliverables
                      <OptionsText>(optional)</OptionsText>
                      <InfoIcon />
                    </LabelWithIcon>
                  }
                  labelAlign="left"
                >
                  <SkillTagSystem
                    tags={tags}
                    setTags={setTags}
                    suggestedSkills={[]}
                    setSuggestedSkills={() => {}}
                  />
                </StyledFormItem>
              </ColAntd>
              <ColAntd xs={24} sm={24} md={24} lg={24} xl={24}>
                <StyledFormItem
                  label={
                    <LabelWithIcon gap={6}>
                      Project URL<OptionsText>(optional)</OptionsText>
                      <InfoIcon />
                    </LabelWithIcon>
                  }
                  name="website"
                  labelAlign="left"
                  initialValue={projectFormData.projectUrl}
                  rules={[
                    { type: "url", message: "This field must be a valid url." },
                  ]}
                >
                  <StyledBountyInput placeholder="Project URL" />
                </StyledFormItem>
                <Note>
                  Make sure you have approval from your clients to display the
                  work you've done for them publicly.
                </Note>
              </ColAntd>
            </Row>
          </WrapBlock>
        </ContentBlock>
        <FooterWrap>
          <Button
            width="89px"
            height="48px"
            border="1px solid rgba(156, 138, 193, 0.16)"
            color="rgba(41, 46, 115, 0.42)"
            background="#FFF"
            onClick={() => navigate(ROUTER_CONSTANTS.USER.PROFILE_USERINNER)}
          >
            <ArrowLeftIcon />
            Back
          </Button>
          <ButtonsWrap>
            <Button
              width="83px"
              height="48px"
              border="1px solid rgba(156, 138, 193, 0.16)"
              color="rgba(41, 46, 115, 0.42)"
              background="#FFF"
            >
              <Link to="/">Cancel</Link>
            </Button>
            <PrimaryButton
              loading={isUploading}
              width="76px"
              height="48px"
              htmlType="submit"
            >
              Next
              <ArrowRightIcon />
            </PrimaryButton>
          </ButtonsWrap>
        </FooterWrap>
      </Container>
    </>
  );
};

export default AddDetailStep;
