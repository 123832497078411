import FlexBox from "components/base/Flexbox";
import styled, { createGlobalStyle } from "styled-components";
import BoardBG from "assets/images/board.png";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { Button, Dropdown, Input, Menu, Popover } from "antd";
export const BoardContainer = styled.div`
  background: rgba(156, 138, 193, 0.04);
  border: 1px solid rgba(41, 46, 115, 0.12);
  width: 100%;
  padding-bottom: 36px;
`;

export const ListContainer = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const TaskContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 6px;
  margin-bottom: 12px;
  cursor: pointer !important;
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
  }
  & > div {
    padding: 16px 16px;
  }
  .card-menu {
    position: absolute;
    right: 7px;
    top: 13px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      margin: 0;
    }
    &:hover {
      background-color: #292e7310;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
`;

export const BoardTitle = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #080f6c;
  background-image: url(${BoardBG});
  background-size: cover;
  border-bottom: 1px solid rgba(41, 46, 115, 0.12);

  padding: 0px 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 71px;

  & > div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export const ListTitle = styled.div`
  padding: 0px 12px;
  margin-top: 12px;
  margin-bottom: 16px;
  height: 44px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(41, 46, 115, 0.12);
    height: 100%;

    & > div:nth-child(1) {
      gap: 8px;
      align-items: flex-end;
      font-weight: 500;
      font-size: 16px;
      line-height: 80%;
      letter-spacing: -0.03em;
      color: #080f6c;
      user-select: none;
    }

    & > div:nth-child(2) {
      gap: 8px;

      svg {
        cursor: pointer;
      }
      span {
        height: 20px;
      }
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
export const PopoverAntd = styled(Popover)``;
export const GlobalStyle = createGlobalStyle`
.popover-bottom-right {
  .ant-popover-content{
    transform: translate(14px ,-12px);
  }
  .ant-popover-inner{
    border-radius: 4px;
  }
  .ant-popover-inner-content{
    padding: 6px 0px;
  }
  .ant-popover-arrow{
    right: 12px;
  }
}
`;
export const CardList = styled.div`
  max-height: 713px;
  overflow: auto;
  padding: 0px 12px;

  &::-webkit-scrollbar-track {
    background: rgba(41, 46, 115, 0.12);
    border-radius: 10px;
    /* height: 386px; */
  }
  &::-webkit-scrollbar {
    width: 4px;
    /* background-color: rgba(8, 15, 108, 0.08); */
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(41, 46, 115, 0.42);
    border-radius: 10px;
  }
`;

export const TaskContent = styled.div`
  margin-bottom: 20px;
`;

export const TaskContentTitle = styled(FlexBox)`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  /* text-indent: 24px; */
  color: rgba(41, 46, 115, 0.82);
  /* display: inline; */
  display: block;
  text-align: left;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  svg {
    margin-right: 4px;
    margin-bottom: -2px;
  }
`;
export const ItemFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* & > div {
    display: flex;
    align-items: center;
    & > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #080f6c;
      margin-left: 8px;
    }
  } */
  & > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: rgba(41, 46, 115, 0.42);
    display: flex;
    align-items: center;
    svg {
      margin-left: 4px;
    }
  }
`;

export const StyledDraggable = styled(Draggable)``;
export const StyledDroppable = styled(Droppable)`
  max-height: 300px;
  overflow: auto;
  & > div {
    background: red !important;
  }
`;

export const ColorTabs = styled(FlexBox)`
  justify-content: space-around;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const Color = styled.div<{ color?: string }>`
  width: 33px;
  height: 10px;
  background: ${(p) => p.color || "#FF8C00"};
  border-radius: 10px;
`;
export const AddCardBtn = styled(Button)`
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #080f6c;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 4px;
  svg {
    margin-right: 4px;
  }
  &:hover,
  &:focus {
    color: #080f6c;
    background: #ffffff;
  }
`;
export const SearchInput = styled(Input)`
  width: 316px;
  height: 40px;
  padding: 9px 16px;
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 6px;

  .ant-input {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: rgba(8, 10, 37, 1);

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: rgba(8, 10, 37, 0.6);
    }
  }
`;

export const AddCard = styled.div`
  padding: 32px 24px 24px;
  position: relative;
  & > svg {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
  }
`;
export const BtnsWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
`;

export const DropdownAntd = styled(Dropdown)``;
export const MenuAntd = styled(Menu)``;

export const CardMenuItem = styled.div`
  cursor: pointer;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 115%;
  color: rgba(41, 46, 115, 0.82);
  display: flex;
  align-items: center;
  gap: 4px;
`;
