import { CSSProperties, FC, useState } from "react";
import {
  ActionBtn,
  ActionWrapper,
  TitleActivityHeader,
  ActivityHeader,
  ActivityItem,
  AddActivityBtn,
  AddSectionContainer,
  BtnWrapper,
  Container,
  IconCover,
  ModalCustom,
  PrimaryBtn,
  SectionContainer,
  SectionDesc,
  SectionHeader,
  ActivityList,
  RemoveBtn,
} from "./courseCustomisation.style";
import {
  IActivityModal,
  ICourseCustomisationProps,
  MODAL_TYPE,
} from "./courseCustomisation.props";
import {
  AddSection,
  DragIcon,
  MultimediaIcon,
  PlusIcon,
  RemoveIcon,
} from "assets/images/course/activity";
import { ACTIVITY_TYPE, IActivity, ISection } from "api/course";
import ActivityTemplate from "./ContentInModal/ActivityTemplate";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CreateSection from "./ContentInModal/CreateSection";
import { ACTIVITY_ACTION, ACTIVITY_TOOL_TYPE } from "utils/course";
import { Col, Modal, Tooltip, Row, message } from "antd";
import { EditIcon } from "assets/icons/common/edit.icon";
import { ContentWrapper } from "../createCourse.style";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import {
  nextStep,
  setCourseSectionAtId,
  setCourseSections,
} from "redux/slices";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import type {
  DropResult,
  ResponderProvided,
  DraggableStyle,
} from "@hello-pangea/dnd";
import { HeadingCourse } from "../components/HeadingCourse";

const initModal = {
  sectionId: -1,
  activityId: -1,
  type: null,
};

const overlayTooltipStyle: CSSProperties = {
  width: "auto",
};

const CourseCustomisation: FC<ICourseCustomisationProps> = () => {
  const dispatch = useDispatch();
  const { step, course } = useAppSelector((state) => state.course);
  const { id } = useParams();

  const { sections: sectionList } = course;

  const [actionModal, setActionModal] = useState<IActivityModal>(initModal);

  const modalTitle = (type: MODAL_TYPE | null) => {
    switch (type) {
      case MODAL_TYPE.ACTIVITY:
        return actionModal.activityId !== -1 && !actionModal.addBelow
          ? `Edit activity: ${
              sectionList[actionModal.sectionId].activities[
                actionModal.activityId
              ].title
            }`
          : "Add learning activity";
      case MODAL_TYPE.SECTION:
        return actionModal.sectionId !== -1
          ? `Edit section: ${sectionList[actionModal.sectionId].title}`
          : "Add a new section";
      default:
        return <></>;
    }
  };

  const handleCloseModal = () => {
    setActionModal(initModal);
  };

  const modalRender = (type: MODAL_TYPE | ACTIVITY_TYPE | null) => {
    switch (type) {
      case MODAL_TYPE.SECTION:
        return (
          <CreateSection
            section={sectionList[actionModal.sectionId!]}
            handleSubmitModal={handleSaveSection}
            handleCancel={handleCloseModal}
          />
        );
      case MODAL_TYPE.ACTIVITY:
        return (
          <ActivityTemplate
            handleSubmitModal={handleSaveActitvity}
            handleCancel={handleCloseModal}
            activity={
              !actionModal.addBelow
                ? sectionList[actionModal.sectionId!].activities[
                    actionModal.activityId!
                  ]
                : null
            }
          />
        );
      default:
        return <div />;
    }
  };

  const handleOpenModal = (
    type: MODAL_TYPE,
    activityId: number,
    sectionId: number,
    addBelow?: boolean
  ) => {
    setActionModal({
      sectionId: sectionId,
      activityId: activityId,
      type,
      addBelow,
    });
  };

  const setSectionList = (list: ISection[]) => {
    dispatch(setCourseSections(list));
  };

  const handleSaveActitvity = (values: IActivity) => {
    // add a new activity
    if (actionModal.activityId === -1 && actionModal.sectionId !== -1) {
      const newList = structuredClone(sectionList);
      newList[actionModal.sectionId].activities.push(values);
      setSectionList(newList);
    }

    // edit an activity
    if (
      !actionModal.addBelow &&
      actionModal.activityId !== -1 &&
      actionModal.sectionId !== -1
    ) {
      const newList = structuredClone(sectionList);
      newList[actionModal.sectionId].activities[actionModal.activityId] = {
        ...newList[actionModal.sectionId].activities[actionModal.activityId],
        ...values,
      };
      setSectionList([...newList]);
    }

    // add a new activity below the current activity
    if (
      actionModal.addBelow &&
      actionModal.activityId !== -1 &&
      actionModal.sectionId !== -1
    ) {
      const newList = structuredClone(sectionList);
      newList[actionModal.sectionId].activities.splice(
        actionModal.activityId + 1,
        0,
        values
      );
      setSectionList(newList);
    }

    // reset modal
    setActionModal(initModal);
  };

  const handleSaveSection = (values: ISection) => {
    // edit a section
    if (actionModal.sectionId !== -1) {
      const newList = structuredClone(sectionList);
      newList[actionModal.sectionId] = {
        ...newList[actionModal.sectionId],
        ...values,
      };
      setSectionList(newList);
    } else
      setSectionList([
        ...sectionList,
        {
          title: values.title,
          description: values.description,
          activities: [],
        },
      ]);
    setActionModal(initModal);
  };

  const handleRemoveItem = (sectionIndex: number, actIndex?: number) => {
    const isActivity = typeof actIndex === "number";

    Modal.confirm({
      title: `Are you sure remove this ${isActivity ? "activity" : "section"}?`,
      icon: <ExclamationCircleOutlined />,
      content: "It will be removed immediately. You can't undo this action.",
      okText: "Remove",
      cancelText: "Cancel",
      onOk: () => {
        const newSection = structuredClone(sectionList);
        if (isActivity) {
          newSection[sectionIndex].activities.splice(actIndex, 1);
          setSectionList(newSection);
        } else {
          newSection.splice(sectionIndex, 1);
          setSectionList(newSection);
        }
      },
    });
  };

  const handleClickToolButtons = (
    sectionIndex: number,
    actIndex: number,
    type: ACTIVITY_TOOL_TYPE
  ) => {
    const newSection = structuredClone(sectionList);
    switch (type) {
      case ACTIVITY_TOOL_TYPE.ADD:
        handleOpenModal(MODAL_TYPE.ACTIVITY, actIndex, sectionIndex, true);
        break;
      case ACTIVITY_TOOL_TYPE.DUPLICATE:
        let activity = structuredClone(
          newSection[sectionIndex].activities[actIndex]
        );

        delete activity?._id;
        delete activity?.createdAt;
        newSection[sectionIndex].activities.splice(actIndex + 1, 0, {
          ...activity,
        });
        setSectionList(newSection);
        break;
      case ACTIVITY_TOOL_TYPE.REMOVE:
        handleRemoveItem(sectionIndex, actIndex);
        break;
      default:
        break;
    }
  };

  const onDragEnd = (result: DropResult, _: ResponderProvided) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const sectionId = source.droppableId.split("-")[1];
    const sectionHavingDnd = structuredClone(sectionList[sectionId]);
    const { activities } = sectionHavingDnd;
    const draggingItem = activities[source.index];

    activities.splice(source.index, 1);
    activities.splice(destination.index, 0, draggingItem);

    dispatch(
      setCourseSectionAtId({
        id: +sectionId,
        section: sectionHavingDnd,
      })
    );
  };

  const getStyle = (style: DraggableStyle | undefined) => {
    if (!style) return;

    if (style.transform) {
      const axisLockY =
        "translate(0px" +
        style.transform.slice(
          style.transform.indexOf(","),
          style.transform.length
        );
      return {
        ...style,
        transform: axisLockY,
      };
    }
    return style;
  };

  const handleSubmit = () => {
    if (sectionList.length === 0)
      return message.warning("There is at least one section in the course");

    const isHasSectionEmpty = sectionList.some(
      (section) => !section.activities.length
    );
    if (isHasSectionEmpty)
      return message.warning("There is at least one activity in each section");
    dispatch(setCourseSections(sectionList));
    dispatch(nextStep());
  };

  return (
    <>
      <HeadingCourse step={step} action={handleSubmit} />
      <ContentWrapper>
        <Container>
          <DragDropContext onDragEnd={onDragEnd}>
            {sectionList.map((section, sectionIndex: number) => (
              <SectionContainer key={sectionIndex}>
                <SectionHeader>
                  <div>{section.title}</div>
                  <Row>
                    <Col flex={"auto"}>
                      <ActionWrapper>
                        <PrimaryBtn
                          icon={<EditIcon />}
                          onClick={() =>
                            handleOpenModal(
                              MODAL_TYPE.SECTION,
                              -1,
                              sectionIndex
                            )
                          }
                        >
                          Edit
                        </PrimaryBtn>
                        <RemoveBtn
                          icon={<RemoveIcon />}
                          onClick={() => handleRemoveItem(sectionIndex)}
                        >
                          Remove
                        </RemoveBtn>
                      </ActionWrapper>
                    </Col>
                  </Row>
                </SectionHeader>
                <SectionDesc className="ck-content">
                  {parse(DOMPurify.sanitize(section.description))}
                </SectionDesc>
                {id ? (
                  <>
                    <ActivityList>
                      {section.activities.map((activity, actIndex: number) => (
                        <ActivityItem key={actIndex}>
                          <TitleActivityHeader>
                            <MultimediaIcon size={32} />
                            <ActivityHeader>{activity.title}</ActivityHeader>
                          </TitleActivityHeader>
                          <ActionWrapper>
                            <PrimaryBtn
                              icon={<EditIcon />}
                              onClick={() =>
                                handleOpenModal(
                                  MODAL_TYPE.ACTIVITY,
                                  actIndex,
                                  sectionIndex
                                )
                              }
                            >
                              Edit
                            </PrimaryBtn>
                            <BtnWrapper>
                              {ACTIVITY_ACTION.map((item) => (
                                <Tooltip
                                  overlayStyle={overlayTooltipStyle}
                                  title={item.title}
                                  key={item.value}
                                >
                                  <ActionBtn
                                    onClick={() =>
                                      handleClickToolButtons(
                                        sectionIndex,
                                        actIndex,
                                        item.value
                                      )
                                    }
                                  >
                                    {item.icon}
                                  </ActionBtn>
                                </Tooltip>
                              ))}
                            </BtnWrapper>
                          </ActionWrapper>
                        </ActivityItem>
                      ))}
                    </ActivityList>
                  </>
                ) : (
                  <Droppable
                    droppableId={`section-${sectionIndex}`}
                    type={`section-${sectionIndex}`} // allow activity only dnd in the current section
                  >
                    {(provided) => (
                      <ActivityList
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {section.activities.map(
                          (activity, actIndex: number) => (
                            <Draggable
                              key={actIndex}
                              draggableId={`section-${sectionIndex}-activity-${actIndex}`}
                              index={actIndex}
                            >
                              {(provided) => (
                                <ActivityItem
                                  ref={provided.innerRef}
                                  // {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  style={getStyle(
                                    provided.draggableProps.style
                                  )}
                                >
                                  <TitleActivityHeader>
                                    <MultimediaIcon size={32} />
                                    <ActivityHeader>
                                      {activity.title}
                                    </ActivityHeader>
                                  </TitleActivityHeader>
                                  <ActionWrapper>
                                    <PrimaryBtn
                                      icon={<EditIcon />}
                                      onClick={() =>
                                        handleOpenModal(
                                          MODAL_TYPE.ACTIVITY,
                                          actIndex,
                                          sectionIndex
                                        )
                                      }
                                    >
                                      Edit
                                    </PrimaryBtn>
                                    <BtnWrapper>
                                      {ACTIVITY_ACTION.map((item) => (
                                        <Tooltip
                                          overlayStyle={overlayTooltipStyle}
                                          title={item.title}
                                          key={item.value}
                                        >
                                          <ActionBtn
                                            onClick={() =>
                                              handleClickToolButtons(
                                                sectionIndex,
                                                actIndex,
                                                item.value
                                              )
                                            }
                                          >
                                            {item.icon}
                                          </ActionBtn>
                                        </Tooltip>
                                      ))}
                                      <Tooltip
                                        overlayStyle={overlayTooltipStyle}
                                        title="Move"
                                      >
                                        <ActionBtn
                                          {...provided.dragHandleProps}
                                        >
                                          <DragIcon />
                                        </ActionBtn>
                                      </Tooltip>
                                    </BtnWrapper>
                                  </ActionWrapper>
                                </ActivityItem>
                              )}
                            </Draggable>
                          )
                        )}
                        {provided.placeholder}
                      </ActivityList>
                    )}
                  </Droppable>
                )}
                <AddActivityBtn
                  icon={<PlusIcon />}
                  color="#7e00fd"
                  onClick={() =>
                    handleOpenModal(MODAL_TYPE.ACTIVITY, -1, sectionIndex)
                  }
                >
                  Add a new activity
                </AddActivityBtn>
              </SectionContainer>
            ))}
          </DragDropContext>
          <AddSectionContainer
            onClick={() => handleOpenModal(MODAL_TYPE.SECTION, -1, -1)}
          >
            <IconCover>
              <AddSection />
            </IconCover>
            + Add a new section
          </AddSectionContainer>
        </Container>
      </ContentWrapper>
      <ModalCustom
        visible={!!actionModal.type}
        centered
        destroyOnClose
        closable
        width={"624px"}
        onCancel={handleCloseModal}
        title={modalTitle(actionModal.type)}
      >
        {modalRender(actionModal.type)}
      </ModalCustom>
    </>
  );
};

export default CourseCustomisation;
