import { useQuery } from "@tanstack/react-query";
import { getPackageDetailsAPI, IWorktask } from "api/users";
import { useAppSelector } from "redux/hooks";
import type { Params } from "react-router-dom";
import { useMemo } from "react";

export const useGetWorktaskDetailAndCheckIsStranger = (
  params: Readonly<Params<string>>
) => {
  const userInfo = useAppSelector((state) => state.auth.user);
  const { data: packageDetailRes, ...queryRest } = useQuery(
    [`getPackageDetail-${params.packageId}`],
    () => getPackageDetailsAPI(params.projectId || "", params.packageId || ""),
    {
      // onSuccess: async (res) => {
      //   if (res.responseCode === 200) {
      //     console.log("getPackageDetail", res.responseData);
      //   }
      // },
      refetchOnWindowFocus: false,
    }
  );

  const userId: string = userInfo?._id;
  const worktaskDetail: IWorktask | undefined = useMemo(
    () => packageDetailRes?.responseData,
    [packageDetailRes?.responseData]
  );
  const isProjectCreator = worktaskDetail?.initiatorDetails?._id === userId;
  const isObserver = worktaskDetail?.observers?.some(
    (obser) => obser?.user?._id === userId
  );
  const isAlreadyCollaborator = worktaskDetail?.collaborators?.some(
    (collaborator: any) => collaborator === userId
  );
  const isSponsor = worktaskDetail?.organizations?.some(
    (org: any) => org._id === userInfo?.organization
  );
  const isStranger = !(
    isProjectCreator ||
    isObserver ||
    isAlreadyCollaborator ||
    isSponsor
  );

  return {
    worktaskDetail,
    isStranger,
    isProjectCreator,
    isObserver,
    ...queryRest,
  };
};
