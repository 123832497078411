import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import styled from "styled-components";

export const BackBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackHomeBtn = styled(OutlinedButton)`
  max-width: 128px;
  width: 100%;
  height: unset;
  border-radius: 6px;
  margin-bottom: 24px;
  text-transform: capitalize;
  padding: 8px;
  opacity: 0.32;
  background: #fff;
`;

export const SeeProjectBtn = styled(PrimaryButton)`
  padding: 12px 16px;
  max-width: 130px;
`;

export const CongratDesc = styled.p`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: rgba(8, 10, 37, 0.6);
  margin-bottom: 0;
`;

export const CongratTitle = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080a25;
`;

export const CongratInfo = styled(CommonFlexColumn)`
  max-width: 520px;
`;

export const CongratImgWrapper = styled.img``;

export const CompleteSection = styled.section`
  background: #f7f7f7;
  mix-blend-mode: normal;
  padding: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 30px;
`;

export const StepThreeWrapper = styled.div``;
