import { useQuery } from "@tanstack/react-query";
import {
  getCurrentWorktaskInitiatorAPI,
  getDeliveredWorktaskInitiatorAPI,
  // getInitiatorDashboardStatsAPI,
  getTransactionsInitiatorByTokenNameAPI,
} from "api/users";
import { AddressWalletIcon } from "assets/icons/addressWallet.icon";
// import { AddWalletIcon } from "assets/icons/addWallet.icon";
import { ArrowRightIcon } from "assets/icons/arrowRight.icon";
import { BlockChainIcon } from "assets/icons/blockchain.icon";
import Pagination from "components/base/pagination";
// import { EyeIcon } from "assets/icons/eye.icon";
// import { HourglassIcon } from "assets/icons/hourglass.icon";
// import { TickIcon } from "assets/icons/tick.icon";
import {
  DashboardTable,
  Heading,
} from "pages/PaymentDashboard/paymentDashboard.style";
import { FC, memo, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { supportedNetworks } from "utils/connectors";
// import DropDownItem from "./dropDownItem";
import { IPaymentDashboardProps } from "./paymentDashboard.props";
import {
  // AddWalletButton,
  // AddWalletButtonContainer,
  Card,
  CardList,
  Container,
  DashboardHeadingTable,
  ItemContainer,
  ItemTitle,
  HeadingTable,
  TableColumn,
  Title,
  TokenValue,
  Wallet,
  WalletRow,
  TokenSymbol,
} from "./paymentDashboard.style";
// import TransactionCard from "./transactionCard";
import TransactionCardInititator from "./transactionCardInititator";

const PAGE_SIZE = 5;

const PaymentDashboard: FC<IPaymentDashboardProps> = () => {
  const [deliverWtCurrentPage, setDeliverWtCurrentPage] = useState(1);
  const [currentWtPage, setCurrentWtPage] = useState(1);
  const userId: string = useAppSelector((state) => state.auth.user?._id);
  const walletAddress: string = useAppSelector(
    (state) => state.auth.user?.walletAddress
  );
  const { data: currWtRes } = useQuery(
    ["getCurrentWorktask"],
    () => getCurrentWorktaskInitiatorAPI(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const currentWorkTasks = currWtRes?.responseData?.records || [];
  currentWorkTasks.sort(
    (x, y) => y.packages?.startDate - x.packages?.startDate
  );

  const { data: deliveredWtRes } = useQuery(
    ["getDeliveredWorktaskInitiator"],
    () => getDeliveredWorktaskInitiatorAPI(userId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const deliveredWorkTasks = deliveredWtRes?.responseData?.records || [];
  deliveredWorkTasks.sort(
    (x, y) => y.packages?.startDate - x.packages?.startDate
  );

  const { data: statsRes } = useQuery(
    ["getTransactionsInitiatorByToken"],
    () =>
      getTransactionsInitiatorByTokenNameAPI({
        skip: 0,
        limit: 5,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const stats = statsRes?.responseData || [];

  const currentWorkTasksWillShow = useMemo(() => {
    return currentWorkTasks.slice(
      (currentWtPage - 1) * PAGE_SIZE,
      PAGE_SIZE * currentWtPage
    );
  }, [currentWorkTasks, currentWtPage]);

  return (
    <>
      <Heading>
        <div>Initiator Payment Dashboard</div>
      </Heading>
      <Container>
        <div>
          <DashboardHeadingTable
            dataSource={stats}
            pagination={false}
            rowKey={(record: any) =>
              record?.tokenSymbol + record?.blockchainNetwork?._id
            }
          >
            <TableColumn
              title="All Currencies"
              render={(record: any) => (
                <TokenSymbol>{record.tokenName}</TokenSymbol>
              )}
            />
            <TableColumn
              title="Total Remaining Budget"
              render={(record: any) => (
                <TokenValue>{record.totalRemainingByToken}</TokenValue>
              )}
            />
            <TableColumn
              title="Total Spent"
              render={(record: any) => (
                <TokenValue>{record.currentSpent}</TokenValue>
              )}
            />
            <TableColumn
              title="Escrow Budget"
              render={(record: any) => (
                <TokenValue>{record.escrowBudget}</TokenValue>
              )}
            />
            <TableColumn
              title="Bonuses Distributed"
              render={(record: any) => (
                <TokenValue>{record.bonusDistributed}</TokenValue>
              )}
            />
            <TableColumn
              title="Guaranteed
              Payments"
              render={(record: any) => (
                <TokenValue>{record.mgpPaid}</TokenValue>
              )}
            />
            <TableColumn
              title="Observer Fees"
              render={(record: any) => (
                <TokenValue>{record.observerFeesPaid}</TokenValue>
              )}
            />
          </DashboardHeadingTable>
          <HeadingTable>
            <Title>My Wallets</Title>
            <Card>
              <Wallet>
                <ItemContainer>
                  <ItemTitle>
                    <BlockChainIcon />
                    <span>Blockchain Network</span>
                  </ItemTitle>
                  {supportedNetworks.map((network) => (
                    <WalletRow key={network.chainId}>
                      {network.chainName}
                    </WalletRow>
                  ))}
                </ItemContainer>
                <ItemContainer>
                  <ItemTitle>
                    <AddressWalletIcon />
                    <span>Wallet Address</span>
                  </ItemTitle>
                  {supportedNetworks.map((network) => (
                    <WalletRow key={network.chainId}>
                      <span>{walletAddress}</span>
                      <ArrowRightIcon />
                    </WalletRow>
                  ))}
                </ItemContainer>
              </Wallet>
            </Card>
          </HeadingTable>
          <DashboardTable>
            <Title>Current WorkTasks</Title>
            <CardList>
              {currentWorkTasks?.length ? (
                <>
                  {currentWorkTasksWillShow.map((item: any, index: number) => (
                    <TransactionCardInititator key={index} transaction={item} />
                  ))}
                  <Pagination
                    current={currentWtPage}
                    pageSize={PAGE_SIZE}
                    total={currentWorkTasks?.length}
                    onChange={(page: number) => setCurrentWtPage(page)}
                  />
                </>
              ) : (
                <ItemTitle>You have no current worktask</ItemTitle>
              )}
            </CardList>
          </DashboardTable>
          <DashboardTable>
            <Title>Delivered WorkTasks</Title>
            <CardList>
              {deliveredWorkTasks?.length ? (
                <>
                  {deliveredWorkTasks
                    ?.slice(
                      (deliverWtCurrentPage - 1) * PAGE_SIZE,
                      PAGE_SIZE * deliverWtCurrentPage
                    )
                    ?.map((item: any) => (
                      <TransactionCardInititator
                        key={item?._id}
                        transaction={item}
                      />
                    ))}
                  <Pagination
                    current={deliverWtCurrentPage}
                    pageSize={PAGE_SIZE}
                    total={deliveredWorkTasks?.length}
                    onChange={(page: number) => setDeliverWtCurrentPage(page)}
                  />
                </>
              ) : (
                <ItemTitle>You have no delivered worktask</ItemTitle>
              )}
            </CardList>
          </DashboardTable>
        </div>
      </Container>
    </>
  );
};

export default memo(PaymentDashboard);
