import { message, Upload, UploadProps } from "antd";
import { BuilderIcon } from "assets/icons/common/builder.icon";
import { CameraIcon } from "assets/icons/common/camera.icon";
import OutlinedButton from "components/base/outlinedButton";
import { isAddress } from "ethers/lib/utils";
import { FormSectionWrapper } from "pages/ProfileUser/profileUserInner.style";
import { FC, useEffect, useState } from "react";
import { getBase64 } from "utils/getBase64";
import { acceptImage, validateImage } from "utils/regex";
import {
  maxLength_60,
  requiredRules,
  stringRequired,
} from "utils/validatorRules";
import SectionHeader from "../SectionHeader";
import { IBuilderProfileProps } from "./builderProfile.props";
import {
  BuilderProfileContainer,
  Description,
  StyledFormItem,
  StyledInput,
  BottomContainer,
  UploadButton,
  UploadContainer,
  WhiteContainer,
  AvatarImageAntd,
} from "./builderProfile.style";
import { useWatch } from "antd/lib/form/Form";
import { checkUniqueOfUsernameAPI } from "api/users";
import { useMutation } from "@tanstack/react-query";
const requiredMark = <span></span>;

export const BuilderProfile: FC<IBuilderProfileProps> = ({
  form,
  setLeaveVisible,
}) => {
  const [fileName, setFileName] = useState<string>("");
  const fileUrlResponse = form.getFieldValue([
    "builderProfile",
    "profilePicture",
  ]);

  const upLoadProps: UploadProps = {
    accept: acceptImage,
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isImage = validateImage.test(file.name);
      if (!isImage) {
        message.error("You can only upload Image!");
        return false;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        form.setFieldValue(["builderProfile", "profilePicture"], file);
        setLeaveVisible(true);
        setFileName(await getBase64(file));
        return false;
      }
    },
  };

  const { mutateAsync: checkUniqueOfUsernameMutate } = useMutation(
    checkUniqueOfUsernameAPI
  );

  const builderProfileUsername = useWatch(["builderProfile", "username"], form);
  useEffect(() => {
    if (!builderProfileUsername) return;
    const debounceTimer = setTimeout(() => {
      checkUniqueOfUsernameMutate(builderProfileUsername).then((res: any) => {
        if (!res.result)
          form.setFields([
            {
              name: ["builderProfile", "username"],
              errors: ["This username has already been used"],
            },
          ]);
      });
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [builderProfileUsername, checkUniqueOfUsernameMutate, form]);

  return (
    <FormSectionWrapper id="Basic Info">
      <SectionHeader icon={<BuilderIcon />} title="Basic Info" />
      <BuilderProfileContainer>
        <Description>
          Your work deserves a kick-ass Web3 Builder Profile. Pick a unique
          handle for your own space on the Web & show off what you've shipped.
        </Description>
        <WhiteContainer>
          <BottomContainer>
            <div>
              <StyledFormItem
                name={["builderProfile", "fullName"]}
                label={<span>Update full name {requiredMark}</span>}
                rules={[stringRequired, maxLength_60]}
              >
                <StyledInput placeholder="Enter your full name" />
              </StyledFormItem>
              <StyledFormItem
                name={["builderProfile", "username"]}
                label={<span>Update username {requiredMark}</span>}
                rules={[stringRequired, maxLength_60]}
              >
                <StyledInput placeholder="Enter your username" />
              </StyledFormItem>
              <StyledFormItem
                name="walletAddress"
                label={<span>Wallet address</span>}
                rules={[
                  requiredRules,
                  () => {
                    return {
                      validator(_, value) {
                        if (typeof value !== "string" || value?.length === 0)
                          return Promise.resolve();

                        if (!isAddress(value)) {
                          return Promise.reject("Not a valid address");
                        }
                        return Promise.resolve();
                      },
                    };
                  },
                ]}
              >
                <StyledInput placeholder="Enter your wallet address" />
              </StyledFormItem>
            </div>
            <UploadContainer>
              <UploadButton>
                <AvatarImageAntd src={fileName || fileUrlResponse}>
                  {!(fileName || fileUrlResponse) && <CameraIcon />}
                </AvatarImageAntd>
              </UploadButton>
              <Upload {...upLoadProps}>
                <OutlinedButton width="110px" height="36px" color="#080F6C">
                  <strong>Upload File</strong>
                </OutlinedButton>
              </Upload>
            </UploadContainer>
          </BottomContainer>
        </WhiteContainer>
      </BuilderProfileContainer>
    </FormSectionWrapper>
  );
};
