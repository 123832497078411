import { FC } from "react";

export const EditOrganIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 6.86667V5.33333H2.66667V14H7.33333V12.7533L12.9267 7.16C13.0467 7.04 13.1867 6.94667 13.3333 6.86667ZM10 8.66667H6V7.66667C6 7.48 6.14667 7.33333 6.33333 7.33333H9.66667C9.85333 7.33333 10 7.48 10 7.66667V8.66667ZM14 4.66667H2V2H14V4.66667ZM15.2333 9.46L14.58 10.1133L13.22 8.75333L13.8733 8.1C14 7.96667 14.22 7.96667 14.3533 8.1L15.2333 8.98C15.3667 9.11333 15.3667 9.33333 15.2333 9.46ZM12.7533 9.22L14.1133 10.58L10.0267 14.6667H8.66667V13.3067L12.7533 9.22Z"
        fill="#7E00FD"
      />
    </svg>
  );
};

export const EditProjectIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.3333 6.86667V5.33333H2.66667V14H7.33333V12.7533L12.9267 7.16C13.0467 7.04 13.1867 6.94667 13.3333 6.86667ZM10 8.66667H6V7.66667C6 7.48 6.14667 7.33333 6.33333 7.33333H9.66667C9.85333 7.33333 10 7.48 10 7.66667V8.66667ZM14 4.66667H2V2H14V4.66667ZM15.2333 9.46L14.58 10.1133L13.22 8.75333L13.8733 8.1C14 7.96667 14.22 7.96667 14.3533 8.1L15.2333 8.98C15.3667 9.11333 15.3667 9.33333 15.2333 9.46ZM12.7533 9.22L14.1133 10.58L10.0267 14.6667H8.66667V13.3067L12.7533 9.22Z"
        fill="#080F6C"
      />
    </svg>
  );
};
