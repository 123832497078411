import { FC, memo } from "react";
import { IJobPreferenceProps } from "./jobPreference.props";
import {
  Container,
  LeftContainer,
  LookingRole,
  LookingRoleBlock,
  RightContainer,
  Salary,
  SalaryBlock,
  Title,
  TitleBlock,
} from "./jobPreference.style";
import {
  JobPreferenceIcon,
  DesignerRoleIcon,
  SalaryIcon,
  EyeIcon,
} from "assets/images/profile-preview/index";
import { formatterNumber } from "utils/validatorRules";

const JobPreference: FC<IJobPreferenceProps> = ({
  jobPreference,
  ...props
}: IJobPreferenceProps) => {
  return (
    <Container>
      <LeftContainer>
        <TitleBlock>
          <JobPreferenceIcon />
          <Title>Job Preference</Title>
        </TitleBlock>
        <LookingRoleBlock>
          <DesignerRoleIcon />
          <LookingRole>
            Looking for <span>{jobPreference?.expectJob}</span> Role
          </LookingRole>
        </LookingRoleBlock>
        <SalaryBlock>
          <SalaryIcon />
          <Salary>
            Compensation Expectation{" "}
            {jobPreference?.expectation && (
              <span>
                USD ${formatterNumber(jobPreference?.expectation)}/month
              </span>
            )}
          </Salary>
        </SalaryBlock>
      </LeftContainer>
      {jobPreference?.attachmentURL && (
        <RightContainer href={jobPreference?.attachmentURL} target="_blank">
          <EyeIcon />
        </RightContainer>
      )}
    </Container>
  );
};

export default memo(JobPreference);
