import styled from "styled-components";
import { Input, Checkbox, Form, Modal, Upload } from "antd";
import FormItem from "antd/es/form/FormItem";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
const { TextArea } = Input;

export const ResourcesWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 10px;

  div {
    display: flex;
    align-items: center;
    border: 1px solid rgba(126, 0, 253, 0.24);
    border-radius: 4px;
    padding: 8px 12px;
  }
`;

export const UploadInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
`;

export const AttachUpload = styled(Upload.Dragger)``;

export const AttachConfirmBtn = styled(PrimaryButton)`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  padding-block: 17px;
  flex-grow: 1;
`;

export const CancelBtn = styled(OutlinedButton)`
  height: auto;
  background: #fff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 4px;
  padding-block: 15px;
  flex-grow: 1;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.42);
`;

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

export const AttachTitle = styled.h4`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-bottom: 0;
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  > span {
    width: 100%;
  }
`;

export const AttachModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-body {
    padding-top: 40px;
  }
`;

export const AttachBtn = styled(OutlinedButton)`
  height: auto;
  width: 100%;
  padding-block: 18px;
  background: rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  cursor: pointer;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  padding: 0rem 3.125rem;
`;

export const Head = styled.div`
  padding: 2rem 0rem 2.125rem;
  max-width: 73.125rem;
  margin: auto;
  border-bottom: solid 0.0625rem #292e7312;
`;
export const Gap = styled.div`
  height: 1.25rem;
`;
export const Title = styled.div`
  font-weight: 500;
  font-size: 2rem;
  line-height: 127%;
  letter-spacing: -0.04em;
  color: #080f6c;

  & > div:nth-child(1) {
    // display: flex;
    // flex-wrap: wrap;
    word-break: break-all;

    span {
      color: #796af6;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    color: #8608fc;
    span {
      color: #080f6c;
    }
  }
`;
export const Round = styled.div`
  border-radius: 2.5rem;
  height: 1.625rem;
  display: flex;
  align-items: center;
  padding: 0rem 0.75rem;
  font-weight: 500;
`;
export const Status = styled(Round)`
  font-size: 1rem;
  line-height: 100%;
  color: #ffffff;
  margin: 0rem 0.5rem 0rem 0rem;
  background: #7e00fd;
`;

export const Body = styled(Form)`
  padding: 2.5rem 0rem 6.25rem;
  max-width: 73.125rem;
  margin: auto;
`;
export const Text = styled.div`
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 110%;
  color: rgba(41, 46, 115, 0.82);
  margin-bottom: 0.625rem;
`;
export const StyledTextArea = styled(Input.TextArea)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 8.125rem !important;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledFormItem = styled(FormItem)`
  .ant-row {
    display: block;
  }

  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
    height: auto;
  }
  .ant-form-item-label {
    padding-bottom: 0.625rem;
  }
`;
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div:nth-child(1) {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  & > div:nth-child(2) {
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    color: rgba(41, 46, 115, 0.82);
    margin: 0.75rem 0rem 0.625rem;
  }
`;
export const InputMulty = styled(TextArea)`
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 100% !important;
  color: rgba(41, 46, 115, 1);
  padding: 0.9375rem 1rem;

  &::placeholder {
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    color: rgba(41, 46, 115, 0.42);
  }
`;
export const CheckboxPurple = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7e00fd;
  }
  .ant-checkbox-inner {
    width: 1.125rem;
    height: 1.125rem;
    border: 0.125rem solid rgba(41, 46, 115, 0.42);
  }
  .ant-checkbox-inner:after {
    left: 22%;
    top: 42%;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #7e00fd;
  }
  .ant-checkbox-checked:after {
    border: none;
  }
`;
export const CheckboxWrap = styled.div`
  margin: 22.5008px 0px 54.5008px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  position: relative;
  & > div {
    display: flex;
    align-items: center;
    gap: 0.6875rem;
    cursor: pointer;
    span {
      font-weight: 400;
      font-size: 1rem;
      line-height: 120%;
      color: rgba(41, 46, 115, 0.82);
    }
  }
  & > span {
    position: absolute;
    top: 100%;
    color: #ff4d4f !important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    color: rgba(41, 46, 115, 0.82);
  }
`;
export const SendText = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 115%;
`;

export const OrgName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  color: #080f6c;
  margin-left: 6px;
`;
