import { FC, Fragment } from "react";
import { Container, Separator, A, Background, Wrap } from "./breadcrumb.style";
import { IBreadcrumbProps } from "./breadcrumb.props";
import { RightOutIcon } from "assets/images/worktasks";
import { Link } from "react-router-dom";
import { handleLongText } from "utils/text";

const Breadcrumb: FC<IBreadcrumbProps> = ({ items }) => {
  const copyItems = [...items];
  const firstItem = copyItems.shift();
  const lastItem = copyItems.pop();

  return (
    <Container>
      <Background />
      <Wrap>
        <Link to={firstItem?.to || ""} color="rgba(0, 0, 0, 0.5)">
          {firstItem?.text}
        </Link>
        {copyItems.map((item, i) => (
          <Fragment key={i}>
            <Separator color="rgba(0, 0, 0, 0.5)">
              <RightOutIcon />
            </Separator>
            <A to={item.to || ""} key={i} color="rgba(0, 0, 0, 0.5)">
              {item.text}
            </A>
          </Fragment>
        ))}
        <Separator color="#000">
          <RightOutIcon />
        </Separator>
        <A to={lastItem?.to || ""} color="#000" title={lastItem?.text}>
          {handleLongText(lastItem?.text, 40)}
        </A>
      </Wrap>
    </Container>
  );
};

export default Breadcrumb;
