import { useQuery } from "@tanstack/react-query";
import {
  getTransactionsPaymentByTokenNameAPI,
  paymentDashboardCollaboratorObserver,
} from "api/users";
import { AddressWalletIcon } from "assets/icons/addressWallet.icon";
import { ArrowRightIcon } from "assets/icons/arrowRight.icon";
import { BlockChainIcon } from "assets/icons/blockchain.icon";
import Pagination from "components/base/pagination";
import { Heading } from "pages/PaymentDashboard/paymentDashboard.style";
import { FC, memo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { supportedNetworks } from "utils/connectors";
import { IPaymentDashboardProps } from "./paymentDashboard.props";
import {
  Card,
  CardList,
  Container,
  ItemContainer,
  ItemTitle,
  DashboardTable,
  Title,
  Wallet,
  WalletRow,
  DashboardHeadingTable,
  TableColumn,
  TokenSymbol,
  TokenValue,
} from "./paymentDashboard.style";
import WorktaskCardObserver from "./worktaskCardObserver";

const PAGE_SIZE = 5;

const PaymentDashboardObserver: FC<IPaymentDashboardProps> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const walletAddress: string = useAppSelector(
    (state) => state.auth.user?.walletAddress
  );
  // const [transactionObserver, setTransactionObserver] = useState([] as any);
  // const [incomesData, setIncomesData] = useState([] as any);
  const { data: paymentCollRes } = useQuery(
    ["getTransactionPaymentObserver"],
    () =>
      paymentDashboardCollaboratorObserver({
        role: "Observer",
        skip: 0,
        limit: 5,
      }),
    {
      // onSuccess: async (res) => {
      //   const { responseData, responseCode } = res;
      //   if (responseCode === 200) {
      //     try {
      //       dispatch(setPageLoading(true));
      //       setTransactionObserver(responseData);

      //       dispatch(setPageLoading(false));
      //     } catch {
      //       dispatch(setPageLoading(false));
      //     }
      //   }
      // },
      refetchOnWindowFocus: false,
    }
  );
  const transactionObserver = paymentCollRes?.responseData || [];
  transactionObserver.sort((x, y) => y.createdAt - x.createdAt);

  const { data: transacPaymentRes } = useQuery(
    ["getTransactionsPaymentByTokenName"],
    () =>
      getTransactionsPaymentByTokenNameAPI({
        role: "Observer",
        skip: 0,
        limit: 10,
      }),
    {
      // onSuccess: async (res) => {
      //   const { responseData, responseCode } = res;
      //   if (responseCode === 200) {
      //     try {
      //       dispatch(setPageLoading(true));
      //       setIncomesData(responseData);

      //       dispatch(setPageLoading(false));
      //     } catch {
      //       dispatch(setPageLoading(false));
      //     }
      //   }
      // },
      refetchOnWindowFocus: false,
    }
  );
  const incomesData = (transacPaymentRes?.responseData || []).map(
    (txsByTokenName: any, index: number) => ({
      currency: txsByTokenName.tokenName,
      totalIncome: txsByTokenName.typeObserver?.reduce(
        (sum: any, data: any) => sum + data.amount,
        0
      ),
      key: index,
    })
  );

  return (
    <>
      <Heading>
        <div>Observer Payment Dashboard</div>
      </Heading>
      <Container>
        <div>
          <DashboardHeadingTable dataSource={incomesData} pagination={false}>
            <TableColumn
              title="All Tokens"
              render={(record: any) => (
                <TokenSymbol>{record.currency}</TokenSymbol>
              )}
            />
            <TableColumn
              title="Net Income"
              render={(record: any) => (
                <TokenValue>{record.totalIncome}</TokenValue>
              )}
            />
          </DashboardHeadingTable>
          <DashboardTable>
            <Title>My Wallets</Title>
            <Card>
              <Wallet>
                <ItemContainer>
                  <ItemTitle>
                    <BlockChainIcon />
                    <span>Blockchain Network</span>
                  </ItemTitle>
                  {supportedNetworks.map((network) => (
                    <WalletRow key={network.chainId}>
                      {network.chainName}
                    </WalletRow>
                  ))}
                  {/* <WalletRow>
                    <span>Polygon</span>
                  </WalletRow>{" "}
                  <WalletRow>
                    <span>Aurora</span>
                  </WalletRow>{" "} */}
                </ItemContainer>
                <ItemContainer>
                  <ItemTitle>
                    <AddressWalletIcon />
                    <span>Wallet Address</span>
                  </ItemTitle>
                  {supportedNetworks.map((network) => (
                    <WalletRow key={network.chainId}>
                      <span>{walletAddress}</span>
                      <ArrowRightIcon />
                    </WalletRow>
                  ))}

                  {/* <WalletRow>
                    <span>{walletAddress}</span>
                    <ArrowRightIcon />
                  </WalletRow>
                  <WalletRow>
                    <span>{walletAddress}</span>
                    <ArrowRightIcon />
                  </WalletRow> */}
                </ItemContainer>
              </Wallet>
            </Card>
          </DashboardTable>
          <DashboardTable>
            <Title>Transactions</Title>
            <CardList>
              {transactionObserver.length ? (
                <>
                  {transactionObserver
                    ?.slice(
                      (currentPage - 1) * PAGE_SIZE,
                      PAGE_SIZE * currentPage
                    )
                    ?.map((item: any) => (
                      <WorktaskCardObserver
                        key={item?._id}
                        transaction={item}
                      />
                    ))}
                  <Pagination
                    current={currentPage}
                    pageSize={PAGE_SIZE}
                    total={transactionObserver?.length}
                    onChange={(page: number) => setCurrentPage(page)}
                  />
                </>
              ) : (
                <ItemTitle>You have no transaction</ItemTitle>
              )}
            </CardList>
          </DashboardTable>
        </div>
      </Container>
    </>
  );
};

export default memo(PaymentDashboardObserver);
