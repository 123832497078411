export const CalendarIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8333 3.33398H3.16667C2.24619 3.33398 1.5 4.08018 1.5 5.00065V16.6673C1.5 17.5878 2.24619 18.334 3.16667 18.334H14.8333C15.7538 18.334 16.5 17.5878 16.5 16.6673V5.00065C16.5 4.08018 15.7538 3.33398 14.8333 3.33398Z"
        stroke="#292E73"
        strokeOpacity="0.82"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.332 1.66602V4.99935"
        stroke="#292E73"
        strokeOpacity="0.82"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66797 1.66602V4.99935"
        stroke="#292E73"
        strokeOpacity="0.82"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 8.33398H16.5"
        stroke="#292E73"
        strokeOpacity="0.82"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
