import { useEthers, useTokenBalance } from "@usedapp/core";
import { CheckPurpleIcon } from "assets/icons/check.icon";
import { EthereumIcon } from "assets/icons/common/coins/ethereum.icon";
import { BudgetInfoIcon } from "assets/icons/info.icon";
import { BackIcon, NextIcon } from "assets/icons/navArrow.icon";
import { formatUnits } from "ethers/lib/utils";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { nFormatter } from "utils/priceFormat";
import { ProjectStepTwoProps } from "../createProject.props";
import {
  TitleWrapper,
  ProjectTitle,
  StepIndication,
} from "../createProject.style";
import {
  ButtonWrapper,
  GrayBtn,
  MainActionBtn,
} from "../ProjectStepOne/projectStepOne.style";
import {
  StepTwoWrapper,
  PaymentSectionInner,
  BudgetSection,
  PartTitle,
  BudgetTotal,
  TextDesc,
  WalletSection,
  WalletItem,
  WalletInfo,
  NetworkName,
  WalletBalance,
} from "./projectStepTwo.style";

const ProjectStepTwo: FC<ProjectStepTwoProps> = ({
  handleChangeStep,
  projectData,
  handleSubmit,
}) => {
  const { account } = useEthers();
  const tokenBalance = useTokenBalance(projectData?.tokenContract, account);
  const decimal = projectData?.decimal;
  const navigate = useNavigate();
  const truncatedTokenSymbol = projectData?.tokenSymbol?.slice(0, 5);

  return (
    <StepTwoWrapper>
      <TitleWrapper space="10px">
        <ProjectTitle>Payment Infomation</ProjectTitle>
        <StepIndication>Step 2</StepIndication>
      </TitleWrapper>
      <PaymentSectionInner>
        <BudgetSection space="32px">
          <PartTitle>Budget information</PartTitle>
          <CommonFlexColumn space="4px">
            <BudgetTotal>
              {nFormatter(projectData?.totalBudget)} {truncatedTokenSymbol}
            </BudgetTotal>
            <CommonFlexRow space="4px">
              <TextDesc>Total Project's Budget</TextDesc>
              <BudgetInfoIcon />
            </CommonFlexRow>
          </CommonFlexColumn>
        </BudgetSection>
        {projectData?.isExistingToken === 1 && (
          <WalletSection space="32px">
            <PartTitle>Your wallet</PartTitle>
            <CommonFlexColumn space="16px">
              {tokenBalance !== undefined && (
                <WalletItem space="12px" $active>
                  <EthereumIcon />
                  <WalletInfo>
                    <CommonFlexColumn space="8px">
                      <NetworkName>{projectData?.tokenName}</NetworkName>
                      <WalletBalance>
                        {nFormatter(+formatUnits(tokenBalance, decimal))}{" "}
                        {truncatedTokenSymbol}
                      </WalletBalance>
                    </CommonFlexColumn>
                    <CheckPurpleIcon />
                  </WalletInfo>
                </WalletItem>
              )}
            </CommonFlexColumn>
          </WalletSection>
        )}
      </PaymentSectionInner>
      <ButtonWrapper>
        <GrayBtn
          onClick={() => {
            handleChangeStep(0);
          }}
        >
          <BackIcon />
          Back
        </GrayBtn>
        <CommonFlexRow space="24px">
          <GrayBtn onClick={() => navigate("/")}>Cancel</GrayBtn>
          <MainActionBtn onClick={handleSubmit}>
            Proceed to payment <NextIcon />
          </MainActionBtn>
        </CommonFlexRow>
      </ButtonWrapper>
    </StepTwoWrapper>
  );
};

export default ProjectStepTwo;
