import moment from "moment";

export const openFrom = (time: number) => {
  const now = moment();
  const createAttime = moment(time);

  const days = now.diff(createAttime, "days");
  if (days < 2) return days + " day";
  else return days + " days";
};

export const timeRemaining = (endTime: number, startTime: number) => {
  if (endTime < Date.now()) return "<strong>0h:</strong>&nbsp0m";

  let baseTime = moment();
  if (startTime > Number(baseTime.format("x"))) baseTime = moment(startTime);

  const totalMinute = moment(endTime).diff(baseTime, "m") + 1;
  const totalHour = Math.floor(totalMinute / 60);
  const minute = totalMinute % 60;
  const day = Math.floor(totalHour / 24);
  const hour = totalHour % 24;

  if (day) return `<strong>${day}d: ${hour}h:</strong>&nbsp${minute}m`;

  return `<strong>${hour}h:</strong>&nbsp${minute}m`;
};

export const dateDiff = (start: number, end: number) => {
  const now = moment(start);
  const createAttime = moment(end);

  const days = now.diff(createAttime, "days");
  if (days < 2) return days + " day";
  else return days + " days";
};

export enum TimeUnit {
  HOURS = "Hours",
  DAYS = "Days",
}
export const convertTimeTo = ({
  value,
  convertFromDBToForm,
  type,
}: {
  value: any;
  convertFromDBToForm: boolean;
  type?: TimeUnit;
}): { value: number; unit: string | null } => {
  const value_ = value ? parseInt(value) : 0;
  if (!convertFromDBToForm) {
    if (type === TimeUnit.DAYS)
      return { value: value_ * 24 * 3600, unit: null };
    else return { value: value_ * 3600, unit: null };
  } else
    return (value_ / 3600) % 24 === 0
      ? { value: value_ / 3600 / 24, unit: "day" }
      : { value: value_ / 3600, unit: "hour" };
};
