import { Button } from "antd";
import styled from "styled-components";

export const ChildrenCover = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 0px 28px;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
`;

export const ButtonOutlinedAntd = styled(Button)<{
  width: string | undefined;
  height: string | undefined;
  color: string | undefined;
}>`
  border-radius: 0.25rem;
  background: rgba(156, 138, 193, 0.16);
  color: ${(p) => p.color};
  outline: none;
  border: none;
  font-weight: 600;
  line-height: 115%;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  padding: 1px;
  align-items: center;
  width: ${(p) => p.width || "8.5rem"};
  height: ${(p) => p.height || "2rem"};
  &:hover,
  &:focus {
    background: radial-gradient(
      100% 445.32% at 0% 100%,
      rgb(126, 0, 253) 0%,
      rgb(185, 61, 243) 42.35%,
      rgb(165, 75, 244) 57.95%,
      rgb(0, 191, 251) 100%
    );
    color: ${(p) => p.color};
  }
`;
