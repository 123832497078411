import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, message } from "antd";
import {
  getProfileInfoAPI,
  IEducation,
  IEmploymentHistory,
  IFormDataProfileOtherPage,
  IFormDataProfilePage,
  IProfile,
  updateAnotherFieldAPI,
} from "api/users";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setPageLoading, setUserInfo } from "redux/slices";
import { BuilderProfile } from "./components/BuilderProfile";
import { BuilderSkill } from "./components/BuilderSkill";
import { Education } from "./components/Education";
import { Invite } from "./components/Invite";
import { JobPreferences } from "./components/JobPreferences";
import { LinkSocial } from "./components/LinkSocial";
import { Portfolio } from "./components/Portfolio";
import { IProfileUserInnerProps } from "./profileUserInner.props";
import {
  Container,
  FormTab,
  GradientTopSection,
  SavePreviewBtn,
  RightSection,
  SaveBtnWrapper,
  WhiteBgWrapper,
  LeaveModal,
  LeaveActionsWrapper,
} from "./profileUserInner.style";
import AboutYourself from "./components/AboutYourself";
import { Experiences } from "./components/Experiences";
import { ProjectHistory } from "./components/ProjectHistory";
import { getUrlFromS3 } from "utils/getUrlFromS3";
import MenuList from "./components/MenuList";
import { ROUTER_CONSTANTS } from "utils/constant";
import { useNavigate } from "react-router-dom";
import { useCallbackPrompt } from "hooks";
import PrimaryButton from "components/base/primaryButton";
import OutlinedButton from "components/base/outlinedButton";
import { scrollToFirstErrorSmooth } from "utils/scrollToSection";

const ProfileUserInnerPage: FC<IProfileUserInnerProps> = () => {
  const [profile, setProfile] = useState<IProfile>();
  const [educations, setEducations] = useState<Array<IEducation>>([]);
  const [employmentHistorys, setEmploymentHistorys] = useState<
    Array<IEmploymentHistory>
  >([]);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const userState = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [leaveVisible, setLeaveVisible] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(leaveVisible);

  useQuery(["getProfile"], () => getProfileInfoAPI(), {
    onSuccess: async (res) => {
      if (res.responseCode === 200) {
        const customSkills = res.responseData?.skills.skillIds.filter(
          (item) => item.skillName
        );
        const masterSkills = res.responseData?.skills.skillIds.filter(
          (item) => item.skillId
        );

        const formData = {
          builderProfile: {
            basicInfo: res.responseData?.basicInfo,
            buildHandle: res.responseData?.buildHandle,
            country: res.responseData?.country,
            emailId: res.responseData?.emailId,
            fullName: res.responseData?.fullName,
            profilePicture: res.responseData?.profilePicture,
            // new value
            username: res.responseData?.username,
            about: res.responseData?.about,
            description: res.responseData?.description,
          },
          walletAddress: res.responseData?.walletAddress,
          jobPreference: res.responseData?.jobPreference,
          builderSkill: {
            ...res.responseData?.skills,
            skillIds: masterSkills,
            customSkillIds: customSkills,
          },
          socialLink: {
            personalBlogURL: res.responseData?.personalBlogURL,
            twitterURL: res.responseData?.twitterURL,
            linkedIn: res.responseData?.linkedIn,
            personURL: res.responseData?.personURL,
          },
        };
        form.setFieldsValue(formData);
        setEmploymentHistorys(res.responseData?.employmentHistory);
        setEducations(res.responseData?.education);
        // will remove
        setProfile(res.responseData);
      }
    },
    onError: (err: AxiosError) => {
      message.error(err?.message);
    },
    refetchOnWindowFocus: false,
  });
  const queryClient = useQueryClient();
  const { mutate: updateAnotherFieldMutate } = useMutation(
    updateAnotherFieldAPI
  );

  const handleSubmit = async (params: IFormDataProfilePage) => {
    const customSkills = form.getFieldValue(["builderSkill", "customSkillIds"]);
    const mixedSkills = customSkills
      ? [...params.builderSkill?.skillIds, ...customSkills]
      : params.builderSkill?.skillIds;

    if (mixedSkills?.length === 0) {
      message.error("Please select select at least 1 skill");
      return;
    }

    dispatch(setPageLoading(true));

    setLeaveVisible(false);

    const curProfilePictureVal = form.getFieldValue([
      "builderProfile",
      "profilePicture",
    ]);
    const userAvatar =
      typeof curProfilePictureVal === "string"
        ? curProfilePictureVal
        : await getUrlFromS3(curProfilePictureVal);

    const dataToUpdateProfile: IFormDataProfileOtherPage = {
      ...params.builderProfile,
      profilePicture: userAvatar,
      ...params.socialLink,
      skills: { ...params?.builderSkill, skillIds: mixedSkills },
      jobPreference: params.jobPreference,
      walletAddress: params.walletAddress,
    };

    updateAnotherFieldMutate(dataToUpdateProfile, {
      onSuccess: async (res) => {
        const { responseData } = res;
        if (res.responseCode === 200) {
          setProfile(res.responseData);
          await queryClient.invalidateQueries(["processUpdateProfile"]);
          dispatch(setPageLoading(false));
          message.success((res as any).responseMessage);
          dispatch(
            setUserInfo({
              ...userState,
              fullName: responseData.fullName,
              profilePicture: responseData.profilePicture,
              walletAddress: responseData.walletAddress,
            })
          );
          dispatch(setUserInfo(res.responseData));
          navigate(ROUTER_CONSTANTS.USER.PROFILE_PREVIEW);
        } else {
          message.error((res as any).responseMessage);
          dispatch(setPageLoading(false));
        }
      },
      onError: (err: any) => {
        const rs: any = err?.response?.data;
        dispatch(setPageLoading(false));
        message.error(rs?.message);
      },
    });
  };

  const handleSubmitForms = () => {
    form.submit();
  };

  return (
    <>
      <Container>
        <MenuList />
        <RightSection>
          <WhiteBgWrapper>
            <GradientTopSection>
              <SaveBtnWrapper>
                <SavePreviewBtn onClick={handleSubmitForms}>
                  Save & Preview
                </SavePreviewBtn>
              </SaveBtnWrapper>
            </GradientTopSection>
          </WhiteBgWrapper>
          <FormTab>
            <Form
              form={form}
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={handleSubmit}
              scrollToFirstError={scrollToFirstErrorSmooth}
              onValuesChange={() => setLeaveVisible(true)}
            >
              <BuilderProfile
                form={form}
                profile={profile}
                setLeaveVisible={setLeaveVisible}
              />
              <AboutYourself form={form} profile={profile} />
              <LinkSocial />
              <BuilderSkill
                form={form}
                profile={profile}
                setLeaveVisible={setLeaveVisible}
              />
              <JobPreferences form={form} setLeaveVisible={setLeaveVisible} />
            </Form>
            <Experiences
              employmentHistorys={employmentHistorys}
              setEmploymentHistorys={setEmploymentHistorys}
              setLeaveVisible={setLeaveVisible}
            />
            <Education
              educations={educations}
              setEducations={setEducations}
              setLeaveVisible={setLeaveVisible}
            />
            <ProjectHistory profileId={profile?._id || ""} />
            <Portfolio profileId={profile?._id || ""} />
            <Invite />
          </FormTab>
        </RightSection>
      </Container>
      <LeaveModal
        visible={showPrompt}
        onCancel={cancelNavigation}
        closable={false}
        footer={null}
        destroyOnClose
      >
        <h3>Are you sure to leave this page?</h3>
        <span>Changes you made won't be saved</span>
        <LeaveActionsWrapper>
          <PrimaryButton onClick={confirmNavigation}>Leave</PrimaryButton>
          <OutlinedButton width="auto" height="auto" onClick={cancelNavigation}>
            Stay
          </OutlinedButton>
        </LeaveActionsWrapper>
      </LeaveModal>
    </>
  );
};

export default ProfileUserInnerPage;
