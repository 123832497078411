import { Tabs } from "antd";
import { FC, memo, useState } from "react";
import { ISignInProps } from "./signIn.props";
import {
  Container,
  FormTitle,
  Image,
  ImageContainer,
  SignInFormContainer,
  TabContainner,
} from "./signIn.style";
import image from "assets/images/signup-image.png";
import { IUserInfo } from "api/auth";
import { OTPVerifyForm } from "./OTPVerifyForm";
import { SignInForm } from "./SignInForm";

const SignIn: FC<ISignInProps> = (props: ISignInProps) => {
  const [userLogin, setUserLogin] = useState<IUserInfo>();
  return (
    <Container>
      <ImageContainer>
        <Image src={image} alt="" />
      </ImageContainer>
      <SignInFormContainer>
        <FormTitle> {userLogin ? "Verify OTP" : "Sign In"}</FormTitle>
        <TabContainner defaultActiveKey="1">
          <Tabs.TabPane tab={userLogin ? "OTP" : "Email"} key="1">
            {userLogin ? (
              <OTPVerifyForm
                userLogin={userLogin}
                setUserLogin={setUserLogin}
              />
            ) : (
              <SignInForm setUserLogin={setUserLogin} />
            )}
          </Tabs.TabPane>
        </TabContainner>
      </SignInFormContainer>
    </Container>
  );
};

export default memo(SignIn);
