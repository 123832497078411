import { FC } from "react";
import { IButtonOutlinedProps } from "./primaryOutlineBtn.props";
import { ButtonOutlinedAntd, ChildrenCover } from "./outlinedButton.style";

const PrimaryOutlineBtn: FC<IButtonOutlinedProps> = ({
  children,
  width,
  height,
  color = "#080F6C",
  ...props
}: IButtonOutlinedProps) => {
  return (
    <ButtonOutlinedAntd {...props} width={width} height={height} color={color}>
      <ChildrenCover>{children}</ChildrenCover>
    </ButtonOutlinedAntd>
  );
};

export default PrimaryOutlineBtn;
