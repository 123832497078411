import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import {
  GroupDescription,
  GroupTitle,
  PrimaryButtonStyled,
  RevolvesContainer,
} from "./revolves.style";
const Revolves = () => {
  const navigate = useNavigate();
  return (
    <RevolvesContainer>
      <GroupTitle>BuidlFi Revolves</GroupTitle>
      <GroupDescription>
        Our work at BuidlFi revolves around bridging the gap between project
        sponsors and contributors. We aim to create an atmosphere of trust,
        enthusiasm and inspiration for individuals and organizations passionate
        about what they do. Our goal is to assist them in achieving optimal
        outcomes.
      </GroupDescription>
      <PrimaryButtonStyled
        onClick={() => navigate(ROUTER_CONSTANTS.ABOUT_BUIDLFI)}
      >
        Learn More
      </PrimaryButtonStyled>
    </RevolvesContainer>
  );
};

export default Revolves;
