import { useQuery } from "@tanstack/react-query";
import {
  getTransactionsByProject,
  ITransactionByProject,
} from "api/projectDetail";
import Pagination from "components/base/pagination";
import moment from "moment";
import { FC, useState } from "react";
import { BlockchainExplorers } from "utils/constant";
import { TransactionProps } from "../OrganizationPage.props";
import { CommonFlexRow } from "../OrganizationPage.style";
import {
  TransactionsWrapper,
  SectionTitle,
  TransactionList,
  TransactionItem,
  TransactionInfo,
  CommonFlexColumn,
  BlockChainName,
  BlurText,
  BlockchainValue,
  TransactionWorktaskWrapper,
  WorktaskName,
  TagStatus,
  ViewProjectBtn,
  PaginationWrapper,
} from "./transaction.style";

const PAGE_SIZE = 5;

type TransactionWithPackageName =
  ITransactionByProject["transactions"][number] & {
    packageName: string;
  };

const Transactions: FC<TransactionProps> = ({ project }) => {
  const { data, isLoading } = useQuery(
    [`getTransactionsByProject${project?._id}`, project?._id],
    () => getTransactionsByProject(project?._id as string),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [currentPage, setCurrentPage] = useState(1);

  const transacData: ITransactionByProject[] | undefined = data?.responseData;

  if (isLoading) {
    return (
      <TransactionsWrapper>
        <SectionTitle>Transactions</SectionTitle>
        <BlockChainName textSize="18px">Loading...</BlockChainName>
      </TransactionsWrapper>
    );
  }

  if (!transacData || transacData?.length === 0) {
    return (
      <TransactionsWrapper>
        <SectionTitle>Transactions</SectionTitle>
        <BlockChainName textSize="18px">
          No transactions available
        </BlockChainName>
      </TransactionsWrapper>
    );
  }

  const blockchainDetail = transacData?.[0]?.blockchainNetworkDetail;
  const selectedBlockchainExplorer =
    BlockchainExplorers[blockchainDetail?.name];
  const combinedTransacData = transacData?.reduce((transac, curr) => {
    return [
      ...transac,
      ...curr?.transactions?.map((item) => ({
        ...item,
        packageName: curr?.packages?.name,
      })),
    ];
  }, [] as TransactionWithPackageName[]);
  combinedTransacData.sort((x, y) => y.createdAt - x.createdAt);

  return (
    <TransactionsWrapper>
      <SectionTitle>Transactions</SectionTitle>
      <TransactionList space="12px">
        {combinedTransacData
          ?.slice((currentPage - 1) * PAGE_SIZE, PAGE_SIZE * currentPage)
          .map((transaction) => {
            return (
              <TransactionItem key={transaction._id}>
                <TransactionInfo space="12px">
                  <CommonFlexColumn space="5px">
                    <CommonFlexRow space="9px">
                      <BlockChainName textSize="18px">
                        {blockchainDetail?.name}
                      </BlockChainName>
                      <BlurText textSize="14px">
                        {moment(transaction.createdAt).format("Do MMMM, YYYY")}
                      </BlurText>
                    </CommonFlexRow>
                    <BlockchainValue textSize="20px">
                      {transaction.amount}{" "}
                      {transaction.tokenSymbol?.slice(0, 5)}
                    </BlockchainValue>
                  </CommonFlexColumn>
                </TransactionInfo>
                <TransactionWorktaskWrapper>
                  <CommonFlexColumn space="10px">
                    <BlurText textSize="14px">Worktask Title</BlurText>
                    <WorktaskName textSize="16px">
                      {transaction?.packageName}
                    </WorktaskName>
                  </CommonFlexColumn>
                  <TagStatus>{transaction.type}</TagStatus>
                  {/* <ViewProjectBtn>View Project</ViewProjectBtn> */}
                  <ViewProjectBtn
                    onClick={() =>
                      window.open(
                        `${selectedBlockchainExplorer}${transaction.txHash}`,
                        "_blank"
                      )
                    }
                  >
                    View transaction
                  </ViewProjectBtn>
                </TransactionWorktaskWrapper>
              </TransactionItem>
            );
          })}
      </TransactionList>
      {combinedTransacData && combinedTransacData?.length > 0 && (
        <PaginationWrapper>
          <Pagination
            current={currentPage}
            pageSize={PAGE_SIZE}
            total={combinedTransacData?.length}
            onChange={(page: number) => setCurrentPage(page)}
            simple={true}
          />
        </PaginationWrapper>
      )}
    </TransactionsWrapper>
  );
};

export default Transactions;
