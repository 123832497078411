export const LinkedInIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8047_8451)">
      <path
        d="M0 1.17352C0 0.543428 0.529625 0.0319824 1.1825 0.0319824H14.8175C15.4706 0.0319824 16 0.543428 16 1.17352V14.8266C16 15.4569 15.4706 15.968 14.8175 15.968H1.1825C0.529688 15.968 0 15.457 0 14.8268V1.17352Z"
        fill="#006699"
      />
      <path
        d="M4.86225 13.3683V6.19386H2.468V13.3683H4.86225ZM3.66563 5.21448C4.50038 5.21448 5.02006 4.66357 5.02006 3.97508C5.00444 3.27091 4.50037 2.73538 3.6815 2.73538C2.86206 2.73538 2.32687 3.27091 2.32687 3.97502C2.32687 4.66351 2.84637 5.21442 3.64994 5.21442L3.66563 5.21448ZM6.1875 13.3683H8.58156V9.3622C8.58156 9.14806 8.59719 8.93336 8.66044 8.78041C8.83344 8.35182 9.22738 7.90816 9.88894 7.90816C10.7551 7.90816 11.1018 8.56602 11.1018 9.53059V13.3683H13.4958V9.25469C13.4958 7.05111 12.3147 6.02566 10.7396 6.02566C9.44812 6.02566 8.88094 6.74459 8.56581 7.23425H8.58175V6.19411H6.18762C6.21887 6.86716 6.1875 13.3683 6.1875 13.3683Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8047_8451">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
