import { FC, useEffect, useState } from "react";
import { ICardModalProps } from "./CardModal.props";
// import CardModalBackground from 'assets/images/project-board/project-board-background.svg'
import {
  ArrowDownIcon,
  ArrowIcon,
  BLockIcon,
  DueDateIcon,
  EditIcon,
  MenuIcon,
  PlusIcon,
  TrashIcon,
} from "assets/images/project-board";
import {
  AddSubtaskBtns,
  AddSubtasksBtn,
  AddSubtaskWrap,
  // AvatarGroup,
  BlockBy,
  ButtonAntd,
  CheckboxAntd,
  CommentDeleteCard,
  Dependencies,
  DueDate,
  Flex,
  ModalWrap,
  Name,
  OptionAntd,
  // Project,
  PropertiesWrap,
  // PropertiesWrap1,
  // PropertiesWrap2,
  // PropertiesWrap3,
  // PropertiesWrap4,
  SelectAntd,
  SelectAntdWrap,
  SubTasks,
  SubTasksItem,
  SubTasksItemWrap,
  SubTasksWrap,
  SubtitleWrap,
  Text,
  Title,
} from "./CardModal.style";
import BaseModal from "components/base/baseModal";
import { CloseIcon } from "assets/images/deliver-work";

// import Avatar1 from "assets/images/submit-deliverable/submit-avatar1.png";
// import Avatar2 from "assets/images/submit-deliverable/submit-avatar2.png";
// import Avatar3 from "assets/images/submit-deliverable/submit-avatar3.png";
// import Avatar4 from "assets/images/submit-deliverable/submit-avatar4.png";
import CommentComponent from "./CommentComponent";
import { Form, message } from "antd";
import {
  addSubtaskAPI,
  cardMarkAsCompleteAPI,
  deleteCardAPI,
  deleteCardSubtaskAPI,
  editSubTaskAPI,
  updateCardAPI,
} from "api/users";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import { MARK_AS_COMPLETED_STATUS, PROJECT_BOARD_ROLE } from "utils/constant";
import moment from "moment";
import { confirmModal } from "components/modules/confirmModal";
import { requiredRules } from "utils/validatorRules";
import {
  StyledFormItem,
  StyledInput,
  StyledOption,
  StyledSelect,
} from "pages/CreateProject/createProject.style";
import { DatePickerAtnd } from "pages/Worktasks/CreateWorkTask/CreateWorktask.style";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import { SelectArrowDown } from "assets/icons/common/selectArrowDown.icon";
import AvatarAntdGroup from "components/modules/avatarList";
import { CardMenuItem, DropdownAntd, MenuAntd } from "../Board/Board.style";

const CardModal: FC<ICardModalProps> = ({
  open,
  closeModel,
  data,
  projectIdentity,
  refetch,
  lanes,
  role,
}) => {
  const [updatingSubtasks, setUpdatingSubtasks] = useState<any>({});
  const [beDeletedSubtask, setBeDeletedSubtask] = useState<any>();

  const [openAddSubtask, setOpenAddSubtask] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (updatingSubtasks?._id && openAddSubtask) {
      form.setFieldValue("subTaskName", updatingSubtasks?.subTaskName);
      form.setFieldValue(
        "collaborators",
        updatingSubtasks?.collaborators.map((coll: any) => coll?._id)
      );
      form.setFieldValue("startDate", moment(updatingSubtasks?.startDate));
      form.setFieldValue("endDate", moment(updatingSubtasks?.endDate));
      form.setFieldValue("status", updatingSubtasks?.markCompleted);
    }
  }, [openAddSubtask]);

  useEffect(() => {
    if (updatingSubtasks?._id) {
      if (openAddSubtask) {
        form.setFieldValue("subTaskName", updatingSubtasks?.subTaskName);
        form.setFieldValue(
          "collaborators",
          updatingSubtasks?.collaborators.map((coll: any) => coll?._id)
        );
        form.setFieldValue("startDate", moment(updatingSubtasks?.startDate));
        form.setFieldValue("endDate", moment(updatingSubtasks?.endDate));
        form.setFieldValue("status", updatingSubtasks?.markCompleted);
      } else setOpenAddSubtask(true);
    } else {
      form.resetFields();
      setOpenAddSubtask(false);
    }
  }, [updatingSubtasks]);

  useEffect(() => {
    if (beDeletedSubtask?._id) {
      showDeleteSubtaskConfirm();
    }
  }, [beDeletedSubtask]);

  function saveSubtask() {
    const subTaskName = form.getFieldValue("subTaskName");
    const collaborators = form.getFieldValue("collaborators");
    const startDate = Number(
      form.getFieldValue("startDate").endOf("day").format("x")
    );
    const endDate = Number(
      form.getFieldValue("endDate").endOf("day").format("x")
    );

    if (updatingSubtasks?._id) {
      const status = form.getFieldValue("status");
      const params = {
        projectId: projectIdentity.projectId || "",
        packageId: projectIdentity.packageId || "",
        cardId: data._id,
        subTaskId: updatingSubtasks?._id,
        body: {
          subTaskName,
          collaborators,
          startDate,
          endDate,
          markCompleted: status,
        },
      };
      editSubTask(params);

      return;
    }
    const params = {
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data._id,
      body: {
        subTaskName,
        collaborators,
        startDate,
        endDate,
      },
    };

    addSubtask(params);
  }

  const { mutate: addSubtask } = useMutation(addSubtaskAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        setOpenAddSubtask(false);
        form.resetFields();
        refetch();
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const { mutate: editSubTask } = useMutation(editSubTaskAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        setUpdatingSubtasks({});
        refetch();
      } else message.error(responseMessage);
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const { mutate: deleteCardSubtask } = useMutation(deleteCardSubtaskAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        refetch();
      } else message.warning(responseMessage);
      setBeDeletedSubtask(undefined);
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
      setBeDeletedSubtask(undefined);
    },
  });

  function showDeleteSubtaskConfirm() {
    confirmModal({
      title: "Are you sure delete this subtask?",
      content: "This action can not be undo!",
      onOk: () => onDeleteSubtask(),
      onCancel: () => {
        // console.log("Cancel delete subtask");
        setBeDeletedSubtask(undefined);
      },
    });
  }
  function onDeleteSubtask() {
    const params = {
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data._id,
      subTaskId: beDeletedSubtask?._id,
    };
    deleteCardSubtask(params);
  }

  function showDeleteCardConfirm() {
    confirmModal({
      title: "Are you sure delete this card?",
      content: "This action can not be undo!",
      onOk: () => onDeleteCard(),
      // onCancel: () => console.log("Cancel delete card"),
    });
  }
  function onDeleteCard() {
    const params = {
      id: projectIdentity.userId || "",
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data._id,
    };

    deleteCard(params);
  }

  const { mutate: deleteCard } = useMutation(deleteCardAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;

      if (responseCode === 200) {
        message.success(responseMessage);
        closeModel();
        refetch();
      } else message.warning(responseMessage);
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const { mutate: cardMarkAsComplete } = useMutation(cardMarkAsCompleteAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        setMarkCompleted(MARK_AS_COMPLETED_STATUS.COMPLETED);
        refetch();
      } else message.warning(responseMessage);
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });
  const [markCompleted, setMarkCompleted] = useState<string>(
    data?.markCompleted
  );
  useEffect(() => {
    setMarkCompleted(data?.markCompleted);

    if (!lanes) return;
    const selectCards: any = [];
    lanes?.forEach((land: any) =>
      land?.cards?.forEach((card: any) => {
        if (card._id !== data._id) selectCards.push(card);
      })
    );
    setSelectCards(selectCards);

    const lane = lanes?.find((land: any) =>
      land?.cards?.find((card: any) => card._id === data._id)
    );
    setCardType(lane?.laneTitle);

    setBlockedBy(data?.blockedBy?._id || "");
    setMarkCompleted(data?.markCompleted);
  }, [data]);

  const markAsComplete = () => {
    const status =
      markCompleted !== MARK_AS_COMPLETED_STATUS.COMPLETED
        ? MARK_AS_COMPLETED_STATUS.COMPLETED
        : MARK_AS_COMPLETED_STATUS.PENDING;
    const params = {
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data._id,
      body: { status },
    };

    cardMarkAsComplete(params);
  };

  const [selectCards, setSelectCards] = useState<any>([]);
  const [cardType, setCardType] = useState<string>("");

  const [blockedBy, setBlockedBy] = useState<string>(data?.blockedBy?._id);

  const saveBlockedBy = (id: string) => {
    const params = {
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data._id,
      body: {
        blockedBy: id,
      },
    };
    setBlockedBy(id);
    updateCard(params);
  };

  const { mutate: updateCard } = useMutation(updateCardAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        refetch();
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
      setBlockedBy("");
    },
  });

  // date rule
  const dateRule = {
    validator: (_: any, value: string) => {
      const endDateMoment = form.getFieldValue("endDate");
      const startDateMoment = form.getFieldValue("startDate");

      if (!endDateMoment || !startDateMoment) return Promise.resolve();

      const endDate = endDateMoment.endOf("day").format("x");
      const startDate = startDateMoment.startOf("day").format("x");

      if (endDate < startDate) {
        return Promise.reject(new Error("End Date must be after Start Date!"));
      }

      return Promise.resolve();
    },
  };

  function disabledEndDate(current: moment.Moment) {
    if (!data?.startDate || !data?.endDate) return true;
    const packageRange =
      current.isBefore(moment(data?.startDate).startOf("day")) ||
      current.isAfter(moment(data?.endDate).endOf("day"));
    const rule1 = current.isBefore(moment().startOf("day"));
    let rule2 = false;
    const startDate = form.getFieldValue("startDate")?.startOf("day");
    if (startDate) {
      rule2 = current.isBefore(startDate);
    }
    return packageRange || rule1 || rule2;
  }

  function disabledStartDate(current: moment.Moment) {
    if (!data?.startDate || !data?.endDate) return true;
    const packageRange =
      current.isBefore(moment(data?.startDate).startOf("day")) ||
      current.isAfter(moment(data?.endDate).endOf("day"));

    const rule1 = current.isBefore(moment().startOf("day"));
    let rule2 = false;
    const endDate = form.getFieldValue("endDate")?.endOf("day");
    if (endDate) {
      rule2 = current.isAfter(endDate);
    }
    return packageRange || rule1 || rule2;
  }

  function isPermission(role: string, createdBy: string) {
    if (!role) return false;
    if (!createdBy) return false;
    if (
      role === PROJECT_BOARD_ROLE.COLLABORATOR &&
      projectIdentity.userId !== createdBy
    )
      return false;
    return true;
  }

  function isPermissionSubtaskStatus(role: string, collabs: any) {
    if (!role) return false;
    if (role !== PROJECT_BOARD_ROLE.COLLABORATOR) return true;
    if (
      collabs?.length &&
      collabs.find((coll: any) => coll?._id === projectIdentity.userId)
    )
      return true;
    return false;
  }

  function changeSubtaskStatus(subtask: any) {
    let status = MARK_AS_COMPLETED_STATUS.COMPLETED;
    if (subtask?.markCompleted === MARK_AS_COMPLETED_STATUS.COMPLETED) {
      status = MARK_AS_COMPLETED_STATUS.PENDING;
    }
    const params = {
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data._id,
      subTaskId: subtask?._id,
      body: {
        markCompleted: status,
        collaborators: subtask?.collaborators.map(
          (coll: any) => coll?._id || ""
        ),
      },
    };

    editSubTask(params);
  }

  return (
    <BaseModal visible={open} centered width={624}>
      <ModalWrap>
        <CloseIcon
          callback={() => {
            closeModel();
            setUpdatingSubtasks({});
          }}
        />
        <ButtonAntd
          onClick={markAsComplete}
          disabled={!isPermission(role, data?.createdBy)}
        >
          <CheckboxAntd
            value={"Mark as Complete"}
            checked={markCompleted === MARK_AS_COMPLETED_STATUS.COMPLETED}
          />
          Mark as Complete
        </ButtonAntd>
        <SubtitleWrap>
          <span>{cardType + " => " + data?.title}</span>
          <ArrowIcon />
        </SubtitleWrap>
        <Title>{cardType + " => " + data?.title}</Title>
        <DueDate>
          <Name>Start date</Name>
          <DueDateIcon />
          <Text>
            {data?.startDate
              ? moment(data?.startDate).format("Do MMM YYYY")
              : "No start date"}
          </Text>
        </DueDate>
        <DueDate>
          <Name>End date</Name>
          <DueDateIcon />
          <Text>
            {data?.endDate
              ? moment(data?.endDate).format("Do MMM YYYY")
              : "No end date"}
          </Text>
        </DueDate>
        {/* <Project>
          <Name>Project</Name>
          <div>
            <PropertiesWrap>
              <PropertiesWrap1>
                <Text>Appropriately actualize ...</Text>
                <ArrowDownIcon />
              </PropertiesWrap1>
              <PropertiesWrap2>
                <Text>Enthusiastically foster ...</Text>
                <ArrowDownIcon />
              </PropertiesWrap2>
            </PropertiesWrap>
            <PropertiesWrap>
              <PropertiesWrap3>
                <Text>Distinctively Envisioneer ...</Text>
                <ArrowDownIcon />
              </PropertiesWrap3>
              <PropertiesWrap4>
                <Text>Harmony high standards ...</Text>
                <ArrowDownIcon />
              </PropertiesWrap4>
            </PropertiesWrap>
          </div>
        </Project> */}
        <Dependencies>
          <Name>Dependencies</Name>
          <PropertiesWrap>
            <BlockBy>
              <BLockIcon />
              <Text>Blocked by</Text>
            </BlockBy>
            <SelectAntdWrap>
              <SelectAntd
                key={blockedBy}
                suffixIcon={<ArrowDownIcon width={16} />}
                placeholder="Select the task"
                value={blockedBy || undefined}
                onChange={(value: any) => {
                  saveBlockedBy(value);
                }}
                disabled={!isPermission(role, data?.createdBy)}
              >
                {selectCards.map((card: any, i: number) => (
                  <OptionAntd value={card?._id} key={i}>
                    {card?.title}
                  </OptionAntd>
                ))}
              </SelectAntd>
            </SelectAntdWrap>
          </PropertiesWrap>
        </Dependencies>
        <SubTasks>
          <Flex>
            <Name>Subtasks</Name>
            {!data?.subTasks?.length && !openAddSubtask && (
              <AddSubtasksBtn
                color="#7E00FD"
                onClick={() => {
                  setOpenAddSubtask(true);
                }}
              >
                <PlusIcon /> Add subtask
              </AddSubtasksBtn>
            )}
          </Flex>
          {!!data?.subTasks?.length && (
            <>
              <SubTasksWrap>
                <SubTasksItemWrap>
                  {data?.subTasks.map((subtask: any, i: number) => (
                    <SubTasksItem key={i}>
                      <div>
                        <CheckboxAntd
                          value={subtask?._id}
                          checked={
                            subtask?.markCompleted ===
                            MARK_AS_COMPLETED_STATUS.COMPLETED
                          }
                          onChange={() => changeSubtaskStatus(subtask)}
                          disabled={
                            !isPermissionSubtaskStatus(
                              role,
                              subtask?.collaborators
                            )
                          }
                        />
                        <div>{subtask?.subTaskName}</div>
                      </div>
                      <div>
                        <span>{moment(subtask?.endDate).format("MMM Do")}</span>
                        <AvatarAntdGroup
                          collabs={
                            subtask?.collaborators?.map((collab: any) => {
                              return {
                                url: collab?.profilePicture,
                                username:
                                  collab?.username ||
                                  (collab?.emailId ?? "").split("@")[0],
                                id: collab?._id,
                              };
                            }) || []
                          }
                          size={24}
                          max={5}
                        />
                        {isPermission(role, subtask?.createdBy) ? (
                          <DropdownAntd
                            trigger={["click"]}
                            overlay={
                              <MenuAntd>
                                <MenuAntd.Item key={"Edit"}>
                                  <CardMenuItem
                                    onClick={() => setUpdatingSubtasks(subtask)}
                                  >
                                    <EditIcon />
                                    Edit
                                  </CardMenuItem>
                                </MenuAntd.Item>
                                <MenuAntd.Item key={"Delete"}>
                                  <CardMenuItem
                                    onClick={() => setBeDeletedSubtask(subtask)}
                                  >
                                    <TrashIcon />
                                    Delete
                                  </CardMenuItem>
                                </MenuAntd.Item>
                              </MenuAntd>
                            }
                            placement="bottomRight"
                          >
                            <span className="subtask-menu">
                              <MenuIcon />
                            </span>
                          </DropdownAntd>
                        ) : (
                          <span className="replaced-menu-icon" />
                        )}
                      </div>
                    </SubTasksItem>
                  ))}
                </SubTasksItemWrap>
                {openAddSubtask || (
                  <AddSubtasksBtn
                    color="#7E00FD"
                    onClick={() => {
                      setOpenAddSubtask(true);
                    }}
                  >
                    <PlusIcon /> Add subtask
                  </AddSubtasksBtn>
                )}
              </SubTasksWrap>
            </>
          )}
          {openAddSubtask && (
            <AddSubtaskWrap>
              <Form
                form={form}
                layout="vertical"
                onFinish={saveSubtask}
                scrollToFirstError={true}
              >
                <StyledFormItem
                  label="Subtask Name"
                  name="subTaskName"
                  rules={[requiredRules]}
                >
                  <StyledInput placeholder="Enter Your Subtask" />
                </StyledFormItem>

                <StyledFormItem
                  name="startDate"
                  rules={[requiredRules, dateRule]}
                  label="Start Date"
                >
                  <DatePickerAtnd
                    placeholder="Start Date"
                    disabledDate={disabledStartDate}
                  />
                </StyledFormItem>
                <StyledFormItem
                  name="endDate"
                  rules={[requiredRules, dateRule]}
                  label="End Date"
                >
                  <DatePickerAtnd
                    placeholder="End Date"
                    disabledDate={disabledEndDate}
                  />
                </StyledFormItem>

                <StyledFormItem
                  label="Collaborators"
                  name="collaborators"
                  rules={[requiredRules]}
                >
                  <StyledSelect
                    suffixIcon={<SelectArrowDown />}
                    placeholder="Select Collaborators"
                    mode="tags"
                  >
                    {data?.profiles.map((coll: any, i: number) => (
                      <StyledOption value={coll?._id} key={i}>
                        {coll?.fullName || coll?.emailId}
                      </StyledOption>
                    ))}
                  </StyledSelect>
                </StyledFormItem>

                {/* {updatingSubtasks?._id && (
                  <StyledFormItem label="Status" name="status" rules={[]}>
                    <StyledSelect suffixIcon={<SelectArrowDown />}>
                      <StyledOption value={MARK_AS_COMPLETED_STATUS.PENDING}>
                        {MARK_AS_COMPLETED_STATUS.PENDING}
                      </StyledOption>
                      <StyledOption
                        selected
                        value={MARK_AS_COMPLETED_STATUS.COMPLETED}
                      >
                        {MARK_AS_COMPLETED_STATUS.COMPLETED}
                      </StyledOption>
                    </StyledSelect>
                  </StyledFormItem>
                )} */}

                <AddSubtaskBtns>
                  <OutlinedButton
                    width="100px"
                    height="32px"
                    color="rgb(46 45 47)"
                    onClick={() => {
                      if (updatingSubtasks?._id) {
                        setUpdatingSubtasks({});
                      } else {
                        setOpenAddSubtask(false);
                        form.resetFields();
                      }
                    }}
                  >
                    Cancel
                  </OutlinedButton>
                  <PrimaryButton width="100px" height="32px" htmlType="submit">
                    {updatingSubtasks?._id ? "Update" : "Save"}
                  </PrimaryButton>
                </AddSubtaskBtns>
              </Form>
            </AddSubtaskWrap>
          )}
        </SubTasks>
        <CommentComponent
          data={data}
          refetch={refetch}
          projectIdentity={projectIdentity}
          updateCard={updateCard}
          role={role}
        >
          {isPermission(role, data?.createdBy) ? (
            <CommentDeleteCard onClick={showDeleteCardConfirm}>
              <TrashIcon />
              Delete Card
            </CommentDeleteCard>
          ) : (
            <></>
          )}
        </CommentComponent>
      </ModalWrap>
    </BaseModal>
  );
};

export default CardModal;
