import PrimaryButton from "components/base/primaryButton";
import styled from "styled-components";

export const Container = styled.div`
  .wrap-icon {
    transition: 0.3s ease-in-out;
    .arrow-icon {
      position: absolute;
    }
    cursor: pointer;
    width: 350px;
    height: 140px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgba(41, 46, 115, 0.12);
    border-radius: 16px;
    :hover {
      box-shadow: 0px 0px 20px 5px rgba(126, 0, 253, 0.2);
      border: 1.5px solid rgba(0, 191, 251, 1);
      background-image: radial-gradient(
        100% 445.32% at 0% 100%,
        #7e00fd 0%,
        #b93df3 42.35%,
        #a54bf4 57.95%,
        #00bffb 100%
      );
      border-radius: 16px;
      z-index: 0;
      position: relative;
      :before {
        /* specify the value of border width here */
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        /* --- */
        content: "";
        position: absolute;
        background-color: #fff;
        border-radius: 13px;
        box-sizing: border-box;
        z-index: -1;
      }
    }
  }
`;
export const Heading = styled.div`
  padding: 22px 16px 22px 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;

  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #080f6c;
  }
  span {
    max-width: 585.4px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 156%;
    color: rgba(41, 46, 115, 0.82);
  }
  background: linear-gradient(
    to bottom,
    rgba(216, 177, 255, 0.26) 0%,
    rgba(237, 221, 255, 0.44) 37%,
    rgba(247, 239, 255, 0.62) 57%,
    rgba(251, 248, 255, 0.8) 78%,
    rgba(255, 254, 255, 0.67) 97%,
    rgba(255, 255, 255, 0.12) 100%
  );
`;

export const Content = styled.div`
  padding-top: 120px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const WrapIconLeft = styled.div`
  cursor: pointer;
  position: relative;
  .arrow-icon {
    right: -63%;
    bottom: -5%;
  }
  :hover {
    .arrow-icon {
      defs {
        linearGradient {
          stop:first-child {
            stop-color: #7e00fd;
          }
        }
      }
    }
  }
`;
export const WrapIconRight = styled.div`
  position: relative;
  .arrow-icon {
    left: -63%;
    bottom: -5%;
  }
  :hover {
    .arrow-icon {
      defs {
        linearGradient {
          stop:first-child {
            stop-color: #7e00fd;
          }
        }
      }
    }
  }
`;
export const WrapIconBottom = styled.div`
  position: relative;
  .arrow-icon {
    bottom: 110%;
  }
  :hover {
    .arrow-icon {
      defs {
        linearGradient {
          stop:first-child {
            stop-color: #7e00fd;
          }
        }
      }
    }
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 415px;
`;

export const Top = styled.div`
  justify-content: center;
  display: flex;
  .arrow-icon {
    transform: rotate(180deg);
    top: 150px;
    right: 48%;
  }
`;

export const MainCenter = styled.div`
  height: 140px;
  display: flex;
  align-items: center;
`;

export const PrimaryButtonStyled = styled(PrimaryButton)`
  padding: 20px 34px;
  margin-top: 60px;
  margin: 60px auto;
`;
