import { FC, memo } from "react";
import { IImageProps } from "./image.props";
import { Container } from "./image.style";

const Image: FC<IImageProps> = ({ width, height, ...props }: IImageProps) => {
  return (
    <Container
      preview={false}
      width={width}
      height={height}
      {...props}
    ></Container>
  );
};

export default memo(Image);
