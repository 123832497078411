import { Form } from "antd";
import { ISessionBudgetProps } from "pages/Bounty/Bounty.props";
import {
  BudgetInputNumber,
  HideNumberHandlerInputNumber,
  SessionBudgetDetails,
  SessionBudgetWrap,
  StyledFormItemHorizontal,
  TotalBalance,
  TotalBudget,
} from "pages/Bounty/Bounty.style";
import { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { addStepSubmit, setSessionBudget } from "redux/slices";
import {
  divideBy2and3,
  maxLengthNumber_15,
  numberRules,
  requiredRules,
  totalProjectBudget,
} from "utils/validatorRules";

const SessionBudget: FC<ISessionBudgetProps> = ({
  worktaskDetails,
  isWorktaskLoading,
  editMode,
}) => {
  const [form] = Form.useForm();
  // const [isIndividual, setIsIndividual] = useState(true);
  const {
    project,
    memberLimit,
    observerFees,
    minimumCost,
    bonus,
    // editMode,
    observers,
  } = useAppSelector((state) => state.addWorktask);
  const caculatedTotal =
    (memberLimit ?? (worktaskDetails?.memberLimit as number)) *
      (minimumCost ?? (worktaskDetails?.minimumCost as number)) +
    (bonus ?? +(worktaskDetails?.bonus as string)) +
    (observerFees ?? (worktaskDetails?.observerFees as number));

  const [totalWorktaskBudget, setTotalWorktaskBudget] = useState(
    caculatedTotal
    // memberLimit * minimumCost + bonus + observerFees
  );

  const [budgetaValid, setBudgetaValid] = useState(false);
  useEffect(() => {
    if (budgetaValid)
      form.validateFields(["minimumCost", "observerFees", "bonus"]);
  }, [budgetaValid, form]);

  const dispatch = useAppDispatch();
  const truncatedTokenSymbol = project?.tokenSymbol?.slice(0, 5);

  const budgetRule = {
    validator: (_: any, value: string) => {
      const bonus = form.getFieldValue("bonus") || 0;
      const minimumCost = form.getFieldValue("minimumCost") || 0;
      const observerFees = form.getFieldValue("observerFees") || 0;
      const memberLimit = form.getFieldValue("memberLimit");

      if (
        minimumCost * memberLimit + bonus + observerFees <=
        (project?.remainingBudget || 0)
      ) {
        setBudgetaValid(true);
        return Promise.resolve();
      } else {
        setBudgetaValid(false);
        return Promise.reject(
          new Error(
            `Amount can not be greater than remaining project budget (${project?.remainingBudget} ${truncatedTokenSymbol}) `
          )
        );
      }
    },
  };

  useEffect(() => {
    if (editMode && isWorktaskLoading) return;

    form.setFieldValue(
      "memberLimit",
      memberLimit ?? worktaskDetails?.memberLimit
    );
    form.setFieldValue(
      "observerFees",
      observerFees ?? worktaskDetails?.observerFees
    );
    form.setFieldValue(
      "minimumCost",
      minimumCost ?? worktaskDetails?.minimumCost
    );
    form.setFieldValue("bonus", bonus ?? worktaskDetails?.bonus);
    form.setFieldValue(
      "totalMGP",
      (memberLimit ?? (worktaskDetails?.memberLimit as number)) *
        (minimumCost ?? (worktaskDetails?.minimumCost as number))
    );
    setTotalWorktaskBudget(caculatedTotal);
  }, [
    bonus,
    caculatedTotal,
    editMode,
    form,
    isWorktaskLoading,
    memberLimit,
    minimumCost,
    observerFees,
    worktaskDetails,
  ]);

  function minimumCostChange(value: any) {
    const currentMemberLimit = form.getFieldValue("memberLimit");
    form.setFieldValue("totalMGP", value * currentMemberLimit);

    const bonus = form.getFieldValue("bonus");
    const observerFees = form.getFieldValue("observerFees");
    setTotalWorktaskBudget(value * currentMemberLimit + bonus + observerFees);
  }

  // function memberLimitChange(value: any) {
  //   const currentMinimumCost = form.getFieldValue("minimumCost");
  //   form.setFieldValue("totalMGP", value * currentMinimumCost);

  //   const bonus = form.getFieldValue("bonus");
  //   const observerFees = form.getFieldValue("observerFees");
  //   setTotalWorktaskBudget(value * currentMinimumCost + bonus + observerFees);
  //   form.validateFields(["bonus", "minimumCost", "observerFees"]);
  // }

  function bonusChange(value: any) {
    const currentMinimumCost = form.getFieldValue("minimumCost");
    const memberLimit = form.getFieldValue("memberLimit");
    const observerFees = form.getFieldValue("observerFees");
    setTotalWorktaskBudget(
      memberLimit * currentMinimumCost + value + observerFees
    );
  }

  function observerFeesChange(value: any) {
    const currentMinimumCost = form.getFieldValue("minimumCost");
    const memberLimit = form.getFieldValue("memberLimit");
    const bonus = form.getFieldValue("bonus");
    setTotalWorktaskBudget(memberLimit * currentMinimumCost + bonus + value);
  }

  const sessionBudgetSubmit = useCallback(async () => {
    const validation = await form
      .validateFields(["observerFees", "bonus", "minimumCost"])
      .then((values) => {
        return true;
      })
      .catch((errorInfo) => {
        const errNumber = errorInfo?.errorFields.length;
        if (typeof errNumber === "number" && !errNumber) return true;
        return false;
      });

    if (!validation) return false;

    const memberLimit = form.getFieldValue("memberLimit");
    const observerFees = form.getFieldValue("observerFees") || 0;
    const bonus = form.getFieldValue("bonus") || 0;
    const minimumCost = form.getFieldValue("minimumCost") || 0;

    const payload = { memberLimit, observerFees, bonus, minimumCost };

    // console.log("payload sessionBudgetSubmit", payload);

    dispatch(setSessionBudget(payload));

    return { observerFees };
  }, [dispatch, form]);

  useEffect(() => {
    dispatch(addStepSubmit({ stepSubmit: sessionBudgetSubmit }));
  }, [dispatch, form, sessionBudgetSubmit]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        // onFinish={onSubmit}
        scrollToFirstError
      >
        <SessionBudgetWrap>
          <TotalBalance>
            <div>Total Available Balance</div>
            <span>{project?.remainingBudget + " " + truncatedTokenSymbol}</span>
          </TotalBalance>
          <SessionBudgetDetails>
            <div>
              <StyledFormItemHorizontal
                name="memberLimit"
                label={<div>Number of Collaborators</div>}
              >
                <BudgetInputNumber
                  min={memberLimit === 1 ? 1 : 3}
                  max={memberLimit === 1 ? 1 : 20}
                  // onChange={memberLimitChange}
                  disabled
                />
              </StyledFormItemHorizontal>
            </div>
            <div>
              <StyledFormItemHorizontal
                name="minimumCost"
                rules={
                  editMode
                    ? []
                    : [
                        maxLengthNumber_15,
                        totalProjectBudget,
                        budgetRule,
                        numberRules,
                        requiredRules,
                      ]
                }
                label={<div>MGP (Minimum Guaranteed Payment)</div>}
              >
                <HideNumberHandlerInputNumber
                  addonAfter={truncatedTokenSymbol}
                  onChange={minimumCostChange}
                  min={1}
                  disabled={editMode}
                />
              </StyledFormItemHorizontal>
            </div>
            <div>
              <StyledFormItemHorizontal
                name="totalMGP"
                label={<div>Total MGP (#of collaborator x MGP)</div>}
              >
                <HideNumberHandlerInputNumber
                  addonAfter={truncatedTokenSymbol}
                  disabled
                />
              </StyledFormItemHorizontal>
            </div>
            <div>
              <StyledFormItemHorizontal
                name="observerFees"
                rules={
                  editMode
                    ? []
                    : [
                        maxLengthNumber_15,
                        totalProjectBudget,
                        divideBy2and3,
                        budgetRule,
                        requiredRules,
                      ]
                }
                label={<div>Observers Fee</div>}
              >
                <HideNumberHandlerInputNumber
                  addonAfter={truncatedTokenSymbol}
                  onChange={observerFeesChange}
                  disabled={editMode}
                  min={observers?.length ? 6 : 0}
                />
              </StyledFormItemHorizontal>
            </div>
            <div>
              <StyledFormItemHorizontal
                name="bonus"
                rules={
                  editMode
                    ? []
                    : [
                        maxLengthNumber_15,
                        totalProjectBudget,
                        budgetRule,
                        numberRules,
                        requiredRules,
                      ]
                }
                label={<div>Bonus</div>}
              >
                <HideNumberHandlerInputNumber
                  addonAfter={truncatedTokenSymbol}
                  onChange={bonusChange}
                  min={1}
                  disabled={editMode}
                />
              </StyledFormItemHorizontal>
            </div>
          </SessionBudgetDetails>
          <TotalBudget>
            <div>Total Worktask's Budget </div>
            <span>{totalWorktaskBudget || 0 + " " + truncatedTokenSymbol}</span>
          </TotalBudget>
        </SessionBudgetWrap>
      </Form>
    </>
  );
};

export default SessionBudget;
