import { Button } from "antd";
import styled from "styled-components";

export const ButtonOutlinedAntd = styled(Button)<{
  width: string | undefined;
  height: string | undefined;
  color: string | undefined;
}>`
  border-radius: 0.25rem;
  background: rgba(156, 138, 193, 0.16);
  color: ${(p) => p.color};
  outline: none;
  border: none;
  font-weight: 500;
  line-height: 115%;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  width: ${(p) => p.width || "8.5rem"};
  height: ${(p) => p.height || "2rem"};

  &:hover,
  &:focus {
    background: rgba(156, 138, 193, 0.16);
    color: ${(p) => p.color};
  }
`;
