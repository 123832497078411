import styled from "styled-components";

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  padding: 0px 50px;
  overflow: hidden;

  #head-wrap {
    padding-top: 24px;
  }
`;
export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  width: 100vw;
  object-fit: cover;
  z-index: -1;
`;
export const AllWorktasksLabel = styled.div`
  width: 100%;
  position: relative;
  padding: 36px 0px 20px 30px;
  max-width: 1170px;
  margin: auto;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #080f6c;
  img {
    height: 89.59px;
  }
`;
export const WorktaskList = styled.div`
  padding: 32px 0px 114px;
  max-width: 1170px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
