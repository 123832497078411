import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICourseBasicInfo, ICourseReward, ISection } from "api/course";

interface ICourseData {
  course: {
    info: ICourseBasicInfo | null;
    sections: ISection[];
    rewards: ICourseReward | null;
    network?: number;
  };
  loading: boolean;
  step: number;
}

const initialState: ICourseData = {
  course: {
    info: null,
    sections: [],
    rewards: null,
  },
  loading: false,
  step: 0,
};

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setCourseInfo: (state, action: PayloadAction<ICourseBasicInfo | null>) => {
      state.course.info = action.payload;
    },
    setCourseSections: (state, action: PayloadAction<ISection[]>) => {
      state.course.sections = action.payload;
    },
    setCourseSectionAtId: (
      state,
      action: PayloadAction<{
        id: number;
        section: ISection;
      }>
    ) => {
      state.course.sections[action.payload.id] = action.payload.section;
    },
    setCourseRewards: (state, action: PayloadAction<ICourseReward | null>) => {
      state.course.rewards = action.payload;
    },
    setCourseNetwork: (state, action: PayloadAction<number | undefined>) => {
      state.course.network = action.payload;
    },
    removeCourseData: (state) => {
      state.course = initialState.course;
      state.step = initialState.step;
    },
    loadingToggle: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    nextStep: (state) => {
      state.step += 1;
    },
    previousStep: (state) => {
      state.step -= 1;
    },
    resetStep: (state) => {
      state.step = initialState.step;
    },
  },
});

export const {
  setCourseInfo,
  setCourseSections,
  setCourseSectionAtId,
  setCourseRewards,
  removeCourseData,
  loadingToggle,
  nextStep,
  previousStep,
  resetStep,
  setCourseNetwork,
} = courseSlice.actions;

export default courseSlice.reducer;
