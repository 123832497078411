import styled from "styled-components";
import { Input, InputNumber } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FlexBox from "components/base/Flexbox";
import image from "components/base/image";
import {
  CommonGradientContainer,
  StyledSelect as CommonSelect,
} from "pages/ProfileUser/profileUserInner.style";

export const JobPreferencesContainer = styled(CommonGradientContainer)`
  padding: 26px;
`;

export const JobPreferencesContainerTop = styled(FlexBox)`
  padding: 0 24px 0 0;
  margin-bottom: 1.5rem;
  align-items: flex-start;
  .ant-form {
    width: 100%;
    padding-left: 2rem;
  }
  display: flex;
  gap: 24px;
`;

export const FormContainer = styled.div`
  width: 100%;
`;
export const Heading = styled(FlexBox)`
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 6px;
`;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-left: 1rem;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 115%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledFormItem = styled(FormItem)`
  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  .ant-form-item-label {
    padding-bottom: 0.35rem;
  }
  margin-bottom: 18px;
`;

export const UploadContainer = styled.div`
  margin-top: 38px;
  display: grid;
  gap: 20px;
`;
export const UploadButton = styled.div`
  border: 1px solid #7e00fd;
  border-radius: 4px;
  width: fit-content;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 115%;
    color: #7e00fd;
  }
`;

export const UploadImage = styled(image)`
  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
`;

export const UploadImageContainer = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  div > svg {
    position: absolute;
    top: -17%;
    right: -26%;
    cursor: pointer;
  }
`;

export const StyledSelect = styled(CommonSelect)`
  width: 100%;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input-wrap {
    width: 100%;
  }
  input {
    padding: 0;
    width: 100%;
  }
`;
