import { useScrollSpy } from "hooks";
import { FC, memo } from "react";
import { scrollToSection } from "utils/scrollToSection";
import {
  MenuListWrapper,
  MenuListWithHighlight,
  MenuListItem,
} from "./menuList.style";

const MenuList: FC = () => {
  const allFieldTitle = [
    "Basic Info",
    "About Yourself",
    "Link your socials",
    "Your skills as builder",
    "Job Preferences",
    "Work Experiences",
    "Education",
    "Project History",
    "Portfolio & Project Showcase",
    "Invite Friends",
  ];
  const activeId = useScrollSpy(allFieldTitle, 150);

  return (
    <MenuListWrapper>
      <MenuListWithHighlight>
        {allFieldTitle.map((item) => (
          <MenuListItem
            key={item}
            highlight={item === activeId}
            onClick={() => scrollToSection(item)}
          >
            {item}
          </MenuListItem>
        ))}
      </MenuListWithHighlight>
    </MenuListWrapper>
  );
};

export default memo(MenuList);
