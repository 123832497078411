import { JobPreferencesIcon } from "assets/icons/common/jobPreferences.icon";
import { LinkSocialContainer, StyledInput } from "./linkSocial.style";
import { ReactElement } from "react";
import { urlRules } from "utils/validatorRules";
import SectionHeader from "../SectionHeader";
import {
  FormSectionWrapper,
  StyledFormItem,
} from "pages/ProfileUser/profileUserInner.style";

export const LinkSocial = (): ReactElement => {
  return (
    <FormSectionWrapper id="Link your socials">
      {/* <Heading>
        <FlexBox>
          <JobPreferencesIcon />
          <Title>Link your socials</Title>
        </FlexBox>
      </Heading> */}
      <SectionHeader icon={<JobPreferencesIcon />} title="Link your socials" />
      <LinkSocialContainer>
        <StyledFormItem
          name={["socialLink", "personalBlogURL"]}
          label={<span>Personal Blog</span>}
          rules={[urlRules]}
        >
          <StyledInput placeholder="Enter your Personal Blog link" />
        </StyledFormItem>
        <StyledFormItem
          name={["socialLink", "linkedIn"]}
          label={<span>LinkedIn</span>}
          rules={[urlRules]}
        >
          <StyledInput placeholder="Enter your LinkedIn link" />
        </StyledFormItem>
        <StyledFormItem
          name={["socialLink", "twitterURL"]}
          label={<span>Twitter</span>}
          rules={[urlRules]}
        >
          <StyledInput placeholder="Enter your Twitter link" />
        </StyledFormItem>
        <StyledFormItem
          name={["socialLink", "personURL"]}
          label={<span> Personal Website</span>}
          rules={[urlRules]}
        >
          <StyledInput placeholder="Enter your Website link" />
        </StyledFormItem>
      </LinkSocialContainer>
    </FormSectionWrapper>
  );
};
