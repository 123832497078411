import { Image } from "antd";
import styled from "styled-components";

export const PreviewWrap = styled.div`
  width: 800px;
  margin: auto;
`;
export const ImageAntd = styled(Image)``;

export const ProjectTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 20px 0px;

  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #080f6c;

  .red-tag {
    background: rgba(255, 87, 34, 0.16);
    border: 1px solid rgba(255, 87, 34, 0.16);
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #ff5722;
    padding: 5.5px 12px;
  }
`;

export const DescDetail = styled.div`
  margin: 8px 0px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: rgba(41, 46, 115, 0.82);
  overflow: hidden;
  white-space: pre-wrap;

  .text-bold {
    font-weight: 600;
  }
  .child-text {
    margin: 0px 0px 0px 12px;
  }
  span {
  }
`;

export const ShortDescription = styled.div`
  padding: 32px 36px;
  position: relative;

  > div:first-child {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #080f6c;
  }
  // p {
  //   margin: 8px 0px 0px;
  //   font-weight: 400;
  //   font-size: 18px;
  //   line-height: 162%;
  //   color: rgba(41, 46, 115, 0.82);
  // }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
      linear-gradient(90deg, #7e00fd 0%, #c143fc 46.88%, #00bffb 100%);
    opacity: 0.1;
    z-index: -1;
  }
`;

export const DetailsWrap = styled.div`
  padding: 8px 36px 24px;
`;

export const FieldName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #080f6c;
  margin-bottom: 16px;
`;

export const Wrap = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

export const FileWrap = styled(Wrap)`
  gap: 20px;
`;

export const SkillWrap = styled.div<{ color: string }>`
  background: #ffffff;
  border: ${(p) => `1px solid ${p.color}`};
  border-radius: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  padding: 4px 12px;

  color: ${(p) => p.color};
`;

export const FileItem = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  height: 72px;
  padding: 12px;

  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;

  img {
    width: 48px;
    height: 48px;
    object-fit: cover;

    // background: rgba(41, 46, 115, 0.05);
    border-radius: 6px;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;

    letter-spacing: -0.02em;
    color: #080f6c;
  }

  &:hover {
    background: rgba(156, 138, 193, 0.16);
    border: 1px solid rgba(156, 138, 193, 0.16);
    div {
      background: #fff;
    }
  }
`;

export const ProjectUrlWrap = styled(Wrap)`
  gap: 9px;
  margin-bottom: 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: rgba(41, 46, 115, 0.82);
`;

export const VideosWrapper = styled.div`
  margin-bottom: 40px;
`;
