import { FC } from "react";

export const BuildProjectBannerIcon: FC = () => {
  return (
    <svg
      width="812"
      height="291"
      viewBox="0 0 812 291"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.90611"
        y="0.00573397"
        width="164.249"
        height="164.249"
        rx="6"
        transform="matrix(0.953965 -0.299917 0.952143 0.305651 247.395 191.369)"
        stroke="#414065"
        strokeOpacity="0.4"
        strokeWidth="2"
      />
      <rect
        x="1.90611"
        y="0.00573397"
        width="128.444"
        height="128.444"
        rx="6"
        transform="matrix(0.953965 -0.299917 0.952143 0.305651 281.52 230.705)"
        stroke="#414065"
        strokeOpacity="0.2"
        strokeWidth="2"
      />
      <rect
        width="186.512"
        height="186.512"
        rx="7"
        transform="matrix(0.953965 -0.299917 0.952143 0.305651 228 156.266)"
        fill="#F9FAFD"
      />
      <rect
        x="1.90611"
        y="0.00573397"
        width="184.512"
        height="184.512"
        rx="6"
        transform="matrix(0.953965 -0.299917 0.952143 0.305651 228.082 156.841)"
        stroke="#414065"
        strokeOpacity="0.7"
        strokeWidth="2"
      />
      <rect
        width="72.286"
        height="72.286"
        transform="matrix(0.953965 -0.299917 0.952143 0.305651 336.863 156.594)"
        fill="#7E00FD"
      />
      <rect
        x="385.461"
        y="-13"
        width="40"
        height="170"
        fill="url(#paint0_linear_11073_9813)"
      />
      <rect
        x="385.461"
        y="-13"
        width="40"
        height="170"
        fill="url(#paint1_linear_11073_9813)"
      />
      <circle
        cx="405.999"
        cy="156.999"
        r="219.139"
        stroke="#414065"
        strokeOpacity="0.4"
      />
      <circle
        opacity="0.4"
        cx="406.001"
        cy="157.001"
        r="292.352"
        stroke="#414065"
        strokeOpacity="0.4"
      />
      <circle
        opacity="0.2"
        cx="406"
        cy="157"
        r="405.5"
        stroke="#414065"
        strokeOpacity="0.4"
      />
      <rect x="126" y="56" width="10" height="10" fill="#7E00FD" />
      <rect
        x="391"
        y="105.07"
        width="10"
        height="10"
        transform="rotate(-45 391 105.07)"
        fill="white"
      />
      <rect
        x="401"
        y="84"
        width="5.65682"
        height="5.65682"
        transform="rotate(-45 401 84)"
        fill="white"
      />
      <rect
        x="415"
        y="95"
        width="3.53553"
        height="3.53553"
        transform="rotate(-45 415 95)"
        fill="white"
      />
      <rect
        x="398"
        y="58.5"
        width="3.53553"
        height="3.53553"
        transform="rotate(-45 398 58.5)"
        fill="white"
      />
      <rect x="598" y="253" width="7" height="7" fill="#7E00FD" />
      <rect x="687" y="191" width="13.3115" height="13.3115" fill="#49B67A" />
      <rect x="198" y="232" width="7" height="7" fill="#49B67A" />
      <defs>
        <linearGradient
          id="paint0_linear_11073_9813"
          x1="385.461"
          y1="157"
          x2="395.329"
          y2="-14.7315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E00FD" />
          <stop offset="0.46875" stopColor="#C143FC" />
          <stop offset="1" stopColor="#00BFFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11073_9813"
          x1="405.461"
          y1="-13"
          x2="405.461"
          y2="157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
