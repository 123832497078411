export const DiscordIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71733 7.33325C7.11733 7.33325 7.44133 7.63325 7.434 7.99992C7.434 8.36659 7.118 8.66659 6.71733 8.66659C6.324 8.66659 6 8.36659 6 7.99992C6 7.63325 6.31667 7.33325 6.71733 7.33325ZM9.28267 7.33325C9.68333 7.33325 10 7.63325 10 7.99992C10 8.36659 9.68333 8.66659 9.28267 8.66659C8.88933 8.66659 8.566 8.36659 8.566 7.99992C8.566 7.63325 8.882 7.33325 9.28267 7.33325ZM12.594 1.33325C13.3693 1.33325 14 1.97725 14 2.77525V15.3333L12.526 14.0033L11.696 13.2193L10.818 12.3859L11.182 13.6813H3.406C2.63067 13.6813 2 13.0373 2 12.2393V2.77525C2 1.97725 2.63067 1.33325 3.406 1.33325H12.5933H12.594ZM9.94733 10.4753C11.4627 10.4266 12.046 9.41125 12.046 9.41125C12.046 7.15725 11.058 5.32992 11.058 5.32992C10.0713 4.57459 9.13133 4.59525 9.13133 4.59525L9.03533 4.70725C10.2013 5.07125 10.7427 5.59659 10.7427 5.59659C10.106 5.23791 9.40443 5.00948 8.67867 4.92459C8.21829 4.87259 7.75329 4.87707 7.294 4.93792C7.25267 4.93792 7.218 4.94525 7.17733 4.95192C6.93733 4.97325 6.354 5.06392 5.62067 5.39325C5.36733 5.51192 5.216 5.59659 5.216 5.59659C5.216 5.59659 5.78533 5.04325 7.01933 4.67925L6.95067 4.59525C6.95067 4.59525 6.01133 4.57459 5.024 5.33059C5.024 5.33059 4.03667 7.15725 4.03667 9.41125C4.03667 9.41125 4.61267 10.4259 6.128 10.4753C6.128 10.4753 6.38133 10.1606 6.588 9.89459C5.71667 9.62792 5.388 9.06792 5.388 9.06792C5.388 9.06792 5.456 9.11725 5.57933 9.18725C5.586 9.19392 5.59267 9.20125 5.60667 9.20792C5.62733 9.22259 5.648 9.22925 5.66867 9.24325C5.84 9.34125 6.01133 9.41792 6.16867 9.48125C6.45 9.59325 6.786 9.70525 7.17733 9.78259C7.76355 9.89749 8.36627 9.89975 8.95333 9.78925C9.29528 9.72822 9.62889 9.62731 9.94733 9.48859C10.1873 9.39725 10.4547 9.26392 10.736 9.07525C10.736 9.07525 10.3933 9.64925 9.49467 9.90859C9.70067 10.1746 9.948 10.4753 9.948 10.4753H9.94733Z"
      fill="#2E1EEA"
    />
  </svg>
);
