import styled from "styled-components";
import headingBG from "assets/images/png/createProject/heading-bg.png";
import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import PrimaryButton from "components/base/primaryButton";

export const Container = styled.div`
  min-height: 70vh;
  margin: 3rem auto;
  font-family: "DM Sans";
  font-style: normal;
  padding: 0px 50px;
`;

export const Heading = styled.div`
  width: 100%;
  height: 8rem;
  background-image: url(${headingBG});

  padding: 0px 50px;
  div {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #080f6c;
    max-width: 73.125rem;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 1270px;
    margin: auto;
  }
`;

export const Content = styled.div`
  max-width: 73.125rem;
  height: fit-content;
  background: rgba(156, 138, 193, 0.04);
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  max-width: 1270px;
  margin: auto;
`;

export const FormContainer = styled.div`
  height: fit-content;
  background: #ffffff;
  margin: 2rem 2rem 3rem 2rem;
  padding: 1.25rem;
`;

export const FormAboveContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 1rem;
`;

export const FormItemContainer = styled.div`
  display: grid;
`;

export const SupportTicketLabel = styled.label`
  height: 0.875rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080f6c;
  margin-bottom: 0.75rem;
`;

export const ModalFormItem = styled(Form.Item)`
  /* margin: 0; */
  min-width: 0;
`;

export const FormTextInput = styled(Input)`
  background: #ffffff;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  padding: 0.6875rem 0.75rem 0.6875rem 0.75rem;
  height: 2.25rem;
`;

export const FormBelowContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const FormTextAreaInput = styled(TextArea)`
  background: #ffffff;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  padding: 0.6875rem 0.75rem 0.6875rem 0.75rem;
  height: 5.875rem !important;
`;

export const SendButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  height: 2rem;
`;

export const SendButton = styled(PrimaryButton)`
  &:hover {
    color: #ede5e5;
  }
`;

export const CardContainer = styled.div`
  height: fit-content;
  background: #ffffff;
  margin: 1.25rem 2rem;
  padding: 1.25rem;
`;
