import { IParticipant } from "api/course";
import { OfflineIcon } from "assets/icons/active.icon";
import { FC, memo } from "react";
import { ROUTER_CONSTANTS } from "utils/constant";
import { ILearnersProps } from "./learners.props";
import { Container, Left, User, UserRight, WrapAva } from "./learners.style";

const Learners: FC<ILearnersProps> = ({ course, ...props }: ILearnersProps) => {
  return (
    <Container>
      <Left>
        <h1>All Learners</h1>
        <div>
          {course?.participants?.map((participant: IParticipant, index) => {
            return (
              <User key={index}>
                <WrapAva
                  to={
                    ROUTER_CONSTANTS.USER.PROFILE_PREVIEW +
                    `?id=${participant.userId}`
                  }
                >
                  <img src={participant.profilePicture} alt="" />
                  {/* <OnlineIcon></OnlineIcon> */}
                  <OfflineIcon></OfflineIcon>
                </WrapAva>
                <UserRight>
                  <h2>
                    {participant.username ||
                      participant.emailId.split("@").shift()}
                  </h2>
                  <span>
                    {participant.employmentHistory.find(
                      (item) => item.isCurrentWork
                    )?.designation ?? "The role has not been added yet"}
                  </span>
                </UserRight>
              </User>
            );
          })}
        </div>
      </Left>
    </Container>
  );
};

export default memo(Learners);
