import BaseModal from "components/base/baseModal";
import styled from "styled-components";
export const BaseModalCustom = styled(BaseModal)`
  .ant-modal-header {
    padding-top: 56px !important;
    padding-bottom: 12px !important;
    border-radius: 0.5rem;
    border-bottom: unset;
    .ant-modal-title {
      text-align: center;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 115%;
      align-items: center;
      letter-spacing: -0.04em;
      color: #080f6c;
    }
  }
  .ant-modal-body {
    padding: 0px 52px 52px 52px !important;
    text-align: center;
    display: grid;
    gap: 20px;
  }
  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    color: rgba(41, 46, 115, 0.82);
  }
  span.score {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #080f6c;
    margin: 0 auto;
  }
`;

export const Buttons = styled.div`
  display: grid;
  gap: 10px;
  button {
    display: flex;
    gap: 8px;
    width: 100% !important;
    svg path {
      fill: white;
    }
    span {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
    }
  }
  .linkedin svg > g > path:last-child {
    fill: #b93df3;
  }
`;
