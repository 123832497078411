import { InfoIcon } from "assets/icons/info.icon";
import { FC, Fragment, memo, useCallback, useState } from "react";
import { nFormatter } from "utils/priceFormat";
import { GrantsProps } from "../OrganizationPage.props";
import { CommonFlexRow } from "../OrganizationPage.style";
import {
  GrantWrapper,
  GrantTitle,
  GrantStatWrapper,
  WrapperColumn,
  GrantSubTitle,
  GrantDescription,
} from "./grants.style";
import { InfoModal } from "components/modules/InfoModal";

const Grants: FC<GrantsProps> = ({ allProjects }) => {
  const getAllTotalBudget = useCallback(() => {
    const allBudgetAndSymbol: {
      [key: string | number]: number;
    } = {};
    allProjects?.forEach((val) => {
      const curTokenName = (val?.tokenSymbol?.slice(0, 5) ||
        val?.tokenName) as string;

      if (!(curTokenName in allBudgetAndSymbol)) {
        allBudgetAndSymbol[curTokenName] = 0;
        allBudgetAndSymbol[curTokenName] += val?.totalBudget;
      } else {
        allBudgetAndSymbol[curTokenName] += val?.totalBudget;
      }
    });

    return Object.entries(allBudgetAndSymbol);
  }, [allProjects]);

  const allTotalBudget = getAllTotalBudget();

  const infoList = [
    {
      title: "What is Total Budget?",
      description:
        "This indicates the total allocated budget for the project. It represents the overall financial resources available to support the execution of WorkTasks and reward contributors.",
    },
    {
      title: "What is Currency?",
      description:
        "This specifies the currency used for the project's budget and financial transactions. It determines the monetary unit in which rewards and expenses are denominated.",
    },
  ];
  const [popupIndex, setPopupIndex] = useState(0);

  return (
    <GrantWrapper>
      <GrantTitle>Grants</GrantTitle>
      <GrantStatWrapper space="70px">
        {allTotalBudget?.map(([currency, val]) => {
          return (
            <Fragment key={currency}>
              <WrapperColumn>
                <GrantSubTitle>
                  {nFormatter(val)} {currency}
                </GrantSubTitle>
                <CommonFlexRow space="4px">
                  <GrantDescription>Total Budget</GrantDescription>
                  <InfoIcon
                    cursor={"pointer"}
                    onClick={() => setPopupIndex(1)}
                  />
                </CommonFlexRow>
              </WrapperColumn>
              <WrapperColumn>
                <CommonFlexRow space="4px">
                  <GrantSubTitle>{currency}</GrantSubTitle>
                </CommonFlexRow>
                <CommonFlexRow space="4px">
                  <GrantDescription>Currency</GrantDescription>
                  <InfoIcon
                    cursor={"pointer"}
                    onClick={() => setPopupIndex(2)}
                  />
                </CommonFlexRow>
              </WrapperColumn>
            </Fragment>
          );
        })}
      </GrantStatWrapper>
      <InfoModal
        visible={popupIndex !== 0}
        onCancel={() => setPopupIndex(0)}
        description={
          infoList[popupIndex - 1] ? infoList[popupIndex - 1].description : ""
        }
        title={infoList[popupIndex - 1] ? infoList[popupIndex - 1].title : ""}
      />
    </GrantWrapper>
  );
};

export default memo(Grants);
