import { TimerIcon } from "assets/icons/timer.icon";
import moment from "moment";
import { FC, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { handleLongText } from "utils/text";
import { IProjectCardProps } from "./projectCard.props";
import {
  CardContainer,
  CardImage,
  CategoryList,
  Category,
  Sponsor,
  SponsorIcon,
  Title,
  Content,
  CardContent,
  CardFooter,
  ActionButton,
  Timer,
  StatusButton,
} from "./projectCard.style";
import { ClockIconStatus } from "assets/icons/clock.icon";
import { Avatar } from "antd";

const ProjectCard: FC<IProjectCardProps> = ({
  btnName,
  project,
  isFetching,
}: IProjectCardProps) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const isCourse = btnName === "Start Learning";

  const handleClickCard = () => {
    navigate(
      isCourse
        ? ROUTER_CONSTANTS.COURSE.COURSE_DETAIL.replace(":id", project?._id)
        : ROUTER_CONSTANTS.PROJECT.DETAIL.replace(":id", project?._id)
    );
  };

  return (
    <CardContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!isFetching && !isCourse && (
        <StatusButton width={hover ? "120px" : "30px"}>
          <ClockIconStatus />{" "}
          {hover ? project?.projectStatus?.toLowerCase() : ""}
        </StatusButton>
      )}
      <div onClick={handleClickCard}>
        {!isFetching ? (
          <CardImage src={project?.coverImage} />
        ) : (
          <Skeleton
            style={{ height: "8rem", paddingTop: 0, display: "flex" }}
          />
        )}

        <CardContent>
          <CategoryList>
            {!isFetching ? (
              <Category>
                {project?.category?.name || project?.category}
              </Category>
            ) : (
              <Skeleton />
            )}
          </CategoryList>
          {!isCourse && (
            <Sponsor>
              {project && project?.organizations?.length !== 0 ? (
                <>
                  <span className="sponsor-by">Sponsored by</span>
                  {project?.organizations?.map((item: any, i: number) => (
                    <Link
                      to={ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(
                        ":id",
                        item?._id
                      )}
                      key={i}
                    >
                      <SponsorIcon
                        src={
                          item?.avatar ||
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzPb_pSj-ir-9eB6mi0lVJdQP1KKHiB8fRBS1CbmOXGd9Z1FEGMJHbEKhahwhWLGSaEXY&usqp=CAU"
                        }
                      />
                    </Link>
                  ))}
                </>
              ) : (
                <span className="sponsor-by">Not have sponsor</span>
              )}
            </Sponsor>
          )}
          <Title>
            {!isFetching ? (
              handleLongText(project?.projectName || project?.title, 60)
            ) : (
              <Skeleton count={3} />
            )}
          </Title>
          <Content>
            {!isFetching ? (
              handleLongText(project?.tagline || project?.description, 120)
            ) : (
              <Skeleton count={3} />
            )}
          </Content>
        </CardContent>
        <CardFooter>
          {isCourse && project?.participationIds?.length ? (
            <span
              className="sponsor-by"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Avatar.Group maxCount={3} size={20} maxPopoverTrigger="click">
                {project?.participants.map((item: any, index: number) => (
                  <Avatar key={index} src={item?.user?.profilePicture} />
                ))}
              </Avatar.Group>
              <span style={{ marginLeft: 10 }}>
                {project?.participationIds?.length || 0} Participants
              </span>
            </span>
          ) : (
            <div />
          )}
          {!isFetching ? (
            <>
              {" "}
              <ActionButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (isCourse) {
                    navigate(
                      ROUTER_CONSTANTS.COURSE.COURSE_DETAIL.replace(
                        ":id",
                        project?._id
                      )
                    );
                  }
                }}
                style={
                  project.status === "DRAFT" ? { background: "#2EB67D" } : {}
                }
              >
                {project.status === "DRAFT" ? "View Draft" : btnName}
              </ActionButton>
              {project?.dueDate && (
                <Timer>
                  <TimerIcon />
                  <span>
                    {/* {project?.dueDate} */}
                    {moment(project?.dueDate).fromNow()}
                  </span>
                </Timer>
              )}
            </>
          ) : (
            <>
              <Skeleton style={{ width: 100, height: 20 }} />{" "}
              <Skeleton style={{ width: 100, height: 20 }} />
            </>
          )}
        </CardFooter>
      </div>
    </CardContainer>
  );
};

export default ProjectCard;
