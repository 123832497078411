import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div``;

export const Left = styled.div`
  width: 376px;
  h1 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 115%;
    color: #080f6c;
  }
  display: grid;
  gap: 16px;
  & > div {
    display: grid;
    gap: 8px;
  }
`;

export const User = styled.div`
  padding: 8px 11px;
  display: flex;
  gap: 12px;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
  cursor: pointer;
  transition: 0.3s ease-in;
  &:hover {
    background: rgba(8, 15, 108, 0.05);
    ::after {
      opacity: 1;
    }
  }
  position: relative;
  ::after {
    position: absolute;
    content: "";
    width: 4px;
    height: 64.3%;
    background-color: #7e00fd;
    top: 50%;
    left: 3px;
    transform: translate(-50%, -50%);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    opacity: 0;
    transition: inherit;
  }
`;

export const UserRight = styled.div`
  h2 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #080f6c;
    margin-bottom: unset;
  }
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: rgba(8, 15, 108, 0.6);
  }
`;

export const WrapAva = styled(Link)`
  display: flex;
  position: relative;
  height: fit-content;
  svg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
