import { ethers, utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { ABI, ERC20ABI, ERC721ABI } from "./ABI";
import { message } from "antd";
import { LEARN_TO_EARN_ADDRESS, REBAKED_ADDRESS } from "utils/constant";
import { Erc20 } from "gen/types";
import { Goerli } from "@usedapp/core";

export const MAX_UINT256 =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export var currentChain = Goerli.chainId;

export const getCurrentNetwork = () => currentChain;

export const changeNetwork = (chainId: number) => {
  if (chainId in REBAKED_ADDRESS) {
    currentChain = chainId;
  }
};

export const getContract = () => {
  try {
    const wethInterface = new utils.Interface(ABI);
    const wethContractAddress = REBAKED_ADDRESS[currentChain];
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const signer = provider.getSigner();

    return new Contract(wethContractAddress, wethInterface, signer);
  } catch (error) {
    message.error("Please connect to metamask");
    throw error;
  }
};

export const getContractForRead = () => {
  try {
    const wethInterface = new utils.Interface(ABI);
    const wethContractAddress = REBAKED_ADDRESS[currentChain];
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    return new Contract(wethContractAddress, wethInterface, provider);
  } catch (error) {
    message.error("Please connect to metamask");
    throw error;
  }
};

export const getContractERC20 = (address: string) => {
  try {
    const wethInterface = new utils.Interface(ERC20ABI);
    const wethContractAddress = address;
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const signer = provider.getSigner();
    return new Contract(wethContractAddress, wethInterface, signer) as Erc20;
  } catch (error) {
    message.error("Please connect to metamask");
    throw error;
  }
};

export const getERC20Allowance = async ({
  walletAddress,
  erc20Address,
}: any) => {
  const contract = getContractERC20(erc20Address);
  return await contract.allowance(walletAddress, REBAKED_ADDRESS[currentChain]);
};

export const checkIfIsToken = async (erc20Address: any) => {
  try {
    const contract = getContractERC20(erc20Address);
    return await contract.decimals();
  } catch (error) {
    throw new Error("Not valid Token");
  }
};

export const getERC20NameAndSymbol = async (erc20Address: any) => {
  const contract = getContractERC20(erc20Address);
  return {
    name: await contract.name(),
    symbol: await contract.symbol(),
  };
};

export const getERC20Decimal = async (erc20Address: any) => {
  const contract = getContractERC20(erc20Address);
  return await contract.decimals();
};

export const approveERC20 = async ({ erc20Address }: any) => {
  const contract = getContractERC20(erc20Address);
  await contract.callStatic.approve(REBAKED_ADDRESS[currentChain], MAX_UINT256);
  let tx = await contract.approve(REBAKED_ADDRESS[currentChain], MAX_UINT256);
  let event = await tx.wait();
  return { event, tx };
};

export const increaseAllowanceERC20 = async ({
  erc20Address,
  amountApproveToken,
}: any) => {
  const contract = getContractERC20(erc20Address);
  await contract.callStatic.increaseAllowance(
    REBAKED_ADDRESS[currentChain],
    amountApproveToken
  );
  let tx = await contract.increaseAllowance(
    REBAKED_ADDRESS[currentChain],
    amountApproveToken
  );
  let event = await tx.wait();

  return { event, tx };
};

export interface ISCCreateProjectParams {
  token: string;
  budget: string;
}

export const createProjectSC = async ({
  token,
  budget,
}: ISCCreateProjectParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.createProject(token, budget);
    let tx = await contract.createProject(token, budget);

    const receipt = await tx.wait();
    const createProjectEvent = receipt.events
      ?.filter((ev: any) => ev.event === "CreatedProject")
      ?.map((ev: any) => ev.args);

    return { tx, event: createProjectEvent };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCCreatePackageParams {
  projectId: string;
  budget: string;
  bonus: string;
  observerBudget: string;
  collaboratorsLimit: number;
  observers: string[];
}

export const createWorktaskSC = async ({
  projectId,
  budget,
  bonus,
  observerBudget,
  collaboratorsLimit,
  observers,
}: ISCCreatePackageParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.createPackage(
      projectId,
      budget,
      bonus,
      observerBudget,
      collaboratorsLimit,
      observers
    );
    let tx = await contract.createPackage(
      projectId,
      budget,
      bonus,
      observerBudget,
      collaboratorsLimit,
      observers
    );

    const receipt = await tx.wait();
    const createPackageEvent = receipt.events
      ?.filter((ev: any) => ev.event === "CreatedPackage")
      ?.map((ev: any) => ev.args);

    return { tx, event: createPackageEvent };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCAddCollaboratorParams {
  projectId: string | undefined;
  packageId: string | undefined;
  collaborator: string | undefined;
  mgp: string;
}

export const addCollaboratorSC = async ({
  projectId,
  packageId,
  collaborator,
  mgp,
}: ISCAddCollaboratorParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.addCollaborator(
      projectId,
      packageId,
      collaborator,
      mgp
    );
    let tx = await contract.addCollaborator(
      projectId,
      packageId,
      collaborator,
      mgp
    );

    const event = await tx.wait();

    return { tx, event };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCRemoveCollaboratorParams {
  projectId: string | undefined;
  packageId: string | undefined;
  collaborator: string | undefined;
  shouldPayMgp: boolean;
}

export const removeCollaboratorSC = async ({
  projectId,
  packageId,
  collaborator,
  shouldPayMgp,
}: ISCRemoveCollaboratorParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.removeCollaborator(
      projectId,
      packageId,
      collaborator,
      shouldPayMgp
    );
    let tx = await contract.removeCollaborator(
      projectId,
      packageId,
      collaborator,
      shouldPayMgp
    );

    const receipt = await tx.wait();

    const removedCollabEvent = receipt.events
      ?.filter((ev: any) => ev.event === "RemovedCollaborator")
      ?.map((ev: any) => ev.args);

    return { tx, event: removedCollabEvent };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCAddObserverParams {
  projectId: string | undefined;
  packageId: string | undefined;
  observers: string[] | undefined;
}

export const addObserversSC = async ({
  projectId,
  packageId,
  observers,
}: ISCAddObserverParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.addObservers(projectId, packageId, observers);
    let tx = await contract.addObservers(projectId, packageId, observers);

    const receipt = await tx.wait();
    const addObserversEvent = receipt.events
      ?.filter((ev: any) => ev.event === "AddedObservers")
      ?.map((ev: any) => ev.args);

    return { tx, event: addObserversEvent };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCRemoveObserverParams {
  projectId: string | undefined;
  packageId: string | undefined;
  observers: string[] | undefined;
}

export const removeObserversSC = async ({
  projectId,
  packageId,
  observers,
}: ISCRemoveObserverParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.removeObservers(projectId, packageId, observers);
    let tx = await contract.removeObservers(projectId, packageId, observers);

    const receipt = await tx.wait();
    const removeObserversEvent = receipt.events
      ?.filter((ev: any) => ev.event === "RemovedObservers")
      ?.map((ev: any) => ev.args);

    return { tx, event: removeObserversEvent };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCUpdateObserverParams {
  projectId: string | undefined;
  packageId: string | undefined;
  observersIn: string[] | undefined;
  observersOut: string[] | undefined;
}

export const updateObserversSC = async ({
  projectId,
  packageId,
  observersIn,
  observersOut,
}: ISCUpdateObserverParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.updateObservers(
      projectId,
      packageId,
      observersIn,
      observersOut
    );
    let tx = await contract.updateObservers(
      projectId,
      packageId,
      observersIn,
      observersOut
    );

    const events: any = {};
    const receipt = await tx.wait();

    const addObserversEvent = receipt.events
      ?.filter((ev: any) => ev.event === "AddedObservers")
      ?.map((ev: any) => ev.args);
    events.AddedObservers = addObserversEvent;

    const removeObserversEvent = receipt.events
      ?.filter((ev: any) => ev.event === "RemovedObservers")
      ?.map((ev: any) => ev.args);
    events.RemovedObservers = removeObserversEvent;

    return { tx, events };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCApproveCollaboratorParams {
  projectId: string | undefined;
  packageId: string | undefined;
  collaborator: string | undefined;
}

export const approveCollaboratorSC = async ({
  projectId,
  packageId,
  collaborator,
}: ISCApproveCollaboratorParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.approveCollaborator(
      projectId,
      packageId,
      collaborator
    );
    let tx = await contract.approveCollaborator(
      projectId,
      packageId,
      collaborator
    );

    const event = await tx.wait();

    return { tx, event };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCFinishPackageParams {
  projectId: string | undefined;
  packageId: string | undefined;
  collaborators: string[];
  observers: string[];
  scores: number[];
}

export const finishPackageSC = async ({
  projectId,
  packageId,
  collaborators,
  observers,
  scores,
}: ISCFinishPackageParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.finishPackage(
      projectId,
      packageId,
      collaborators,
      observers,
      scores
    );
    let tx = await contract.finishPackage(
      projectId,
      packageId,
      collaborators,
      observers,
      scores
    );

    const receipt = await tx.wait();

    const events: any = {};

    const paidCollabRewardsEvents = receipt.events
      ?.filter((ev: any) => ev.event === "PaidCollaboratorRewards")
      ?.map((ev: any) => ev.args);
    events.PaidCollaboratorRewards = paidCollabRewardsEvents;

    const paidObserverFeeEvents = receipt.events
      ?.filter((ev: any) => ev.event === "PaidObserverFee")
      ?.map((ev: any) => ev.args);
    events.PaidObserverFee = paidObserverFeeEvents;

    const finishedPackageEvent = receipt.events
      ?.filter((ev: any) => ev.event === "FinishedPackage")
      ?.map((ev: any) => ev.args);
    events.FinishedPackage = finishedPackageEvent;

    return { tx, events };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCCancelWorktaskParams {
  projectId: string | undefined;
  packageId: string | undefined;
  collaborators: string[];
  observers: string[];
  collabStartedWork: boolean[];
}

export const cancelWorktaskSC = async ({
  projectId,
  packageId,
  collaborators,
  observers,
  collabStartedWork,
}: ISCCancelWorktaskParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.cancelPackage(
      projectId,
      packageId,
      collaborators,
      observers,
      collabStartedWork
    );
    let tx = await contract.cancelPackage(
      projectId,
      packageId,
      collaborators,
      observers,
      collabStartedWork
    );
    const receipt = await tx.wait();

    const events: any = {};

    const paidRewardEvents = receipt.events
      ?.filter((ev: any) => ev.event === "PaidCollaboratorRewards")
      ?.map((ev: any) => ev.args);
    events.PaidCollaboratorRewards = paidRewardEvents;

    const paidObserverFeeEvents = receipt.events
      ?.filter((ev: any) => ev.event === "PaidObserverFee")
      ?.map((ev: any) => ev.args);
    events.PaidObserverFee = paidObserverFeeEvents;

    const canceledPackageEvent = receipt.events
      ?.filter((ev: any) => ev.event === "CanceledPackage")
      ?.map((ev: any) => ev.args);
    events.CanceledPackage = canceledPackageEvent;

    return { tx, events };
  }
  throw Error;
};

export interface ISCFinishProjectParams {
  projectId: string | undefined;
}

export const finishProjectSC = async ({
  projectId,
}: ISCFinishProjectParams) => {
  const contract = getContract();
  if (contract) {
    await contract.callStatic.finishProject(projectId);
    let tx = await contract.finishProject(projectId);

    const receipt = await tx.wait();
    const finishedProjectEvent = receipt.events
      ?.filter((ev: any) => ev.event === "FinishedProject")
      ?.map((ev: any) => ev.args);

    return { tx, event: finishedProjectEvent };
  }
  throw Error;
};

export interface ISCGetCollaboratorData {
  projectId: string | undefined;
  packageId: string | undefined;
  walletAddress: string | undefined;
}

export const getCollaboratorDataSC = async ({
  projectId,
  packageId,
  walletAddress,
}: ISCGetCollaboratorData) => {
  const contract = getContractForRead();
  if (contract) {
    let result = await contract.getCollaboratorData(
      projectId,
      packageId,
      walletAddress
    );

    return result;
  }
  throw Error;
};

export interface ISCGetCollaboratorData {
  projectId: string | undefined;
  packageId: string | undefined;
  walletAddress: string | undefined;
}

export const getObserverDataSC = async ({
  projectId,
  packageId,
  walletAddress,
}: ISCGetCollaboratorData) => {
  const contract = getContractForRead();
  if (contract) {
    let result = await contract.getObserverData(
      projectId,
      packageId,
      walletAddress
    );

    return result;
  }
  throw Error;
};

// ======== LEARN TO EARN ========

export const getERC20AllowanceLearnToEarn = async ({
  walletAddress,
  erc20Address,
}: any) => {
  const contract = getContractERC20(erc20Address);
  if (contract) {
    return await contract.allowance(
      walletAddress,
      LEARN_TO_EARN_ADDRESS[currentChain]
    );
  }
  message.error("Please connect to metamask");
  throw Error();
};

export const getContractERC721 = (address: string) => {
  try {
    const wethInterface = new utils.Interface(ERC721ABI);
    const wethContractAddress = address;
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const signer = provider.getSigner();
    return new Contract(wethContractAddress, wethInterface, signer);
  } catch (error) {
    message.error("Please connect to metamask");
    throw error;
  }
};

export const getERC721Approval = async ({
  walletAddress,
  erc721Address,
}: any) => {
  const contract = getContractERC721(erc721Address);

  return await contract.isApprovedForAll(
    walletAddress,
    LEARN_TO_EARN_ADDRESS[currentChain]
  );
};
export const checkIfIsNFT = async (erc721Address: any) => {
  try {
    const contract = getContractERC721(erc721Address);
    // ERC-165 interface ID for ERC-721
    const erc721InterfaceId = "0x80ac58cd";
    return await contract.supportsInterface(erc721InterfaceId);
  } catch (error) {
    throw new Error("Not valid NFT");
  }
};

export const getERC721NameAndSymbol = async (erc721Address: any) => {
  const contract = getContractERC721(erc721Address);
  const name = await contract.name();
  const symbol = await contract.name();
  return { name, symbol };
};

export interface ISCApproveERC721LearnToEarn {
  erc721Address: string;
  approved: boolean;
}

export const approveERC721LearnToEarn = async ({
  erc721Address,
  approved,
}: ISCApproveERC721LearnToEarn) => {
  const contract = getContractERC721(erc721Address);
  if (contract) {
    let tx = await contract.setApprovalForAll(
      LEARN_TO_EARN_ADDRESS[currentChain],
      approved
    );
    let event = await tx.wait();
    return { event, tx };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export const approveERC20LearnToEarn = async ({ erc20Address }: any) => {
  const contract = getContractERC20(erc20Address);
  if (contract) {
    let tx = await contract.approve(
      LEARN_TO_EARN_ADDRESS[currentChain],
      MAX_UINT256
    );
    let event = await tx.wait();
    return { event, tx };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export const increaseAllowanceERC20LearnToEarn = async ({
  erc20Address,
  amountApproveToken,
}: any) => {
  const contract = getContractERC20(erc20Address);
  await contract.callStatic.increaseAllowance(
    LEARN_TO_EARN_ADDRESS[currentChain],
    amountApproveToken
  );
  let tx = await contract.increaseAllowance(
    LEARN_TO_EARN_ADDRESS[currentChain],
    amountApproveToken
  );
  let event = await tx.wait();

  return { event, tx };
};

export const handleMessageErrorSC = (reason: string | undefined) => {
  if (reason?.includes("unapproved collaborators left")) {
    message.error(
      "All collaborators deliverables must be approved before finishing worktask"
    );
    return;
  }
  if (reason?.includes("not enough package budget left")) {
    message.error("The worktask's remaining budget is insufficient");
    return;
  }
  if (reason?.includes("caller is not the project initiator")) {
    message.error("Please connect to the correct wallet of project initiator");
    return;
  }
  if (reason?.includes("unknown account #0")) {
    message.error("Please connect to wallet");
    return;
  }
  if (reason?.includes("low-level call failed")) {
    message.error("Not enough token");
    return;
  }
  if (reason?.includes("Connector not initialized")) {
    message.error(reason + ". Please try again.");
    return;
  }
  if (reason?.includes("already pending")) {
    message.error(
      "Already processing ethereum request Accounts. Please accept the request."
    );
    return;
  }
  if (reason) message.error(reason?.replace("execution reverted:", ""));
};

export const getProjectData = async (projectId: string) => {
  const contract = getContract();
  if (contract) {
    return await contract.getProjectData(projectId);
  }
  // message.error("Please connect to metamask");
  throw Error();
};
