export const TimerIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.677734 7.99862C0.677734 3.94853 3.96098 0.665283 8.01107 0.665283C12.0612 0.665283 15.3444 3.94853 15.3444 7.99862V8.66529H13.6205L8.67774 13.6081V15.332H8.01107C3.96098 15.332 0.677734 12.0487 0.677734 7.99862ZM9.34126 9.33234V3.99901H8.00793V7.99901H4.00793V9.33234H9.34126Z"
      fill="#FF5722"
    />
    <circle cx="10.1751" cy="14.1656" r="0.833333" fill="#FF5722" />
    <circle cx="14.1751" cy="10.1656" r="0.833333" fill="#FF5722" />
    <circle cx="12.6667" cy="12.6667" r="0.666667" fill="#FF5722" />
  </svg>
);
