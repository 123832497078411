import { Input } from "antd";
import CommonModal from "components/base/commonModal";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import TextEditor from "components/base/textEditor";
import styled from "styled-components";

export const Modal = styled(CommonModal)`
  .ant-modal-header {
    padding: 24px 32px !important;
    border-radius: 0.5rem;
    border-bottom: unset;
    .ant-modal-title {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 115%;
      align-items: center;
      letter-spacing: -0.04em;
      color: #080f6c;
    }
  }
  .ant-modal-body {
    padding: 0px 32px 32px 32px !important;
  }
`;

export const WrapButton = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
`;

export const CancelButton = styled(OutlinedButton)`
  width: 100px;
  height: 48px;
`;

export const SaveButton = styled(PrimaryButton)`
  width: 100px !important;
  height: 48px !important;
`;

export const WrapContent = styled.div`
  background: rgba(156, 138, 193, 0.06);
  border: 1px solid rgba(156, 138, 193, 0.12);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 24px;
  padding: 16px;
  display: grid;
  gap: 16px;
`;
export const ItemSection = styled.div`
  display: grid;
  gap: 8px;
  .ant-form-item-control-input {
    min-height: unset !important;
  }
`;

export const Label = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.42);
  user-select: none;
`;

export const InputStyled = styled(Input)`
  background: inherit;
  border: unset;
  border-bottom: 1px solid rgba(41, 46, 115, 0.12);
  padding: unset;
`;
