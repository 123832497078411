import { FC } from "react";

export const CrossMarkIcon: FC = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2471 1.93047C11.5716 1.606 11.5716 1.07994 11.2471 0.755469C10.9227 0.431001 10.3966 0.431002 10.0721 0.755469L6.0013 4.8263L1.93047 0.755469C1.606 0.431002 1.07994 0.431001 0.755469 0.755469C0.431001 1.07994 0.431002 1.606 0.755469 1.93047L4.8263 6.0013L0.755469 10.0721C0.431002 10.3966 0.431001 10.9227 0.755469 11.2471C1.07994 11.5716 1.606 11.5716 1.93047 11.2471L6.0013 7.1763L10.0721 11.2471C10.3966 11.5716 10.9227 11.5716 11.2471 11.2471C11.5716 10.9227 11.5716 10.3966 11.2471 10.0721L7.1763 6.0013L11.2471 1.93047Z"
        fill="#080F6C"
      />
    </svg>
  );
};
