import { Form, message } from "antd";
import {
  FormTextInput,
  ModalFormItem,
  ResendWrap,
  SignInItemContainer,
  SignInLabel,
  SubmitButton,
} from "./signIn.style";
import Countdown from "antd/lib/statistic/Countdown";
import { useMutation } from "@tanstack/react-query";
import { IUserInfo, resendVerifyEmailAPI, verifyOTPAPI } from "api/auth";
import { setAuthData } from "redux/slices";
import { ROUTER_CONSTANTS } from "utils/constant";
import { AxiosError } from "axios";
import { useAppDispatch } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

export const OTPVerifyForm = ({
  userLogin,
  setUserLogin,
}: {
  userLogin: IUserInfo;
  setUserLogin: any;
}) => {
  const showCoundown = useRef(Date.now());
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { mutateAsync: resendOTP } = useMutation(resendVerifyEmailAPI);
  const { mutateAsync: verifyOTP, isLoading } = useMutation(verifyOTPAPI);
  const handleVerifyOTP = (values: any) => {
    verifyOTP({
      ...values,
      id: userLogin!._id,
      redisId: userLogin!.redisId,
    })
      .then((res) => {
        const { responseData, responseCode, responseMessage } = res;
        if (responseCode === 200) {
          message.success("Login success");
          const { token, ...info } = responseData;
          localStorage.setItem("user", JSON.stringify(info));
          localStorage.setItem("token", token);
          dispatch(setAuthData({ user: info, token: token }));
          navigate(ROUTER_CONSTANTS.HOME);
        } else {
          message.error(responseMessage);
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  const handleSendOTP = () => {
    resendOTP({ id: userLogin._id })
      .then((res) => {
        message.success("OTP was send successfully");
        showCoundown.current = Date.now();
        setUserLogin((prev: IUserInfo) => ({
          ...prev,
          redisId: res.responseData.redisId,
        }));
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  return (
    <Form
      name="OTP"
      wrapperCol={{ span: 32 }}
      layout="vertical"
      initialValues={{ remember: true }}
      autoComplete="off"
      onFinish={handleVerifyOTP}
    >
      <SignInItemContainer>
        <SignInLabel>Enter OTP</SignInLabel>
        <ModalFormItem name="code" labelAlign="left">
          <FormTextInput placeholder="Enter your OTP" />
        </ModalFormItem>
      </SignInItemContainer>

      <SignInItemContainer>
        <SubmitButton loading={isLoading} htmlType="submit">
          Verify OTP
        </SubmitButton>
      </SignInItemContainer>
      <ResendWrap onClick={handleSendOTP}>
        <Countdown
          value={showCoundown.current + 1000 * 60 * 2}
          format="[(]s[s)]"
          prefix="Resend Now"
          style={!showCoundown.current ? { opacity: 0.6 } : {}}
        />
      </ResendWrap>
    </Form>
  );
};
