import { FC, Fragment, ReactElement, useLayoutEffect, useMemo } from "react";
import { IBlogDetailProps } from "./blogDetail.props";
import {
  BlogContent,
  ButtonStyled,
  Container,
  Content,
  Dot,
  Heading,
  Menu,
  MenuAndTabs,
  Section,
  SkeletonWrapper,
  Tabs,
  BlogDetailTime,
  SubSectionIcon,
  ProjectBg,
} from "./blogDetail.style";
import Breadcrumb from "components/base/breadcrumb";
import { ROUTER_CONSTANTS } from "utils/constant";
import TagWithIcon from "components/base/tagWithIcon";
import ProjectGroup from "components/modules/projectGroup";
import { useQuery } from "@tanstack/react-query";
import { IRecentUpdatesRes, getRecentUpdates } from "api/users";
import { Link, useLocation, useParams } from "react-router-dom";
import { useGetBlogById } from "api/blog/queries";
import { IBlog } from "api/blog";
import dayjs from "dayjs";
import { useScrollSpy } from "hooks";
import { scrollToSection } from "utils/scrollToSection";
import Skeleton from "react-loading-skeleton";
import { convertToSnakeCase } from "utils/regex";

const BlogDetail: FC<IBlogDetailProps> = (): ReactElement => {
  const { id } = useParams();
  const location = useLocation();
  const { data, isLoading: blogDetailLoading } = useGetBlogById(id!);
  const {
    isFetching: isFetchingRecentUpdates,
    isLoading: isLoadingRecentUpdates,
    data: recentUpdatesRes,
  } = useQuery(["get-recent-updates"], () => getRecentUpdates(), {
    refetchOnWindowFocus: false,
  });

  // new recent updates
  const newRecentUpdates: IRecentUpdatesRes[] =
    recentUpdatesRes?.responseData || [];

  // blog
  const blog: IBlog | undefined = useMemo(() => {
    if (data) {
      const blogTemp: IBlog = data.responseData;
      blogTemp.sections = blogTemp.sections.map((section, indexSec) => ({
        ...section,
        hash: `${convertToSnakeCase(section.title)}_${indexSec}`,
        subSections: section.subSections.map((subSection, indexSub) => ({
          ...subSection,
          hash: `${convertToSnakeCase(subSection.title)}_${indexSub}`,
        })),
      }));
      return blogTemp;
    }
  }, [data]);

  const items = [
    {
      text: "Home",
      to: ROUTER_CONSTANTS.HOME,
    },
    {
      text: "Blog",
    },
    {
      text: blog?.title ?? "",
    },
  ];

  // handle scroll section
  useLayoutEffect(() => {
    if (blog) {
      if (location.hash) {
        const id = location.hash.replace("#", "");
        scrollToSection(id);
      }
    }
  }, [blog, location.hash]);

  // highlight curent section
  const scrollIds = useMemo(() => {
    if (blog)
      return blog.sections.reduce(
        (acc: string[], section) => [
          ...acc,
          section.hash!,
          ...section.subSections.map((subSection) => subSection.hash!),
        ],
        []
      );
    return [];
  }, [blog]);
  const activeId = useScrollSpy(scrollIds, 150);

  return (
    <Container>
      <Breadcrumb items={items} />
      <Heading>
        <div>
          <h1>{blogDetailLoading ? <Skeleton width="50%" /> : blog?.title}</h1>
          <BlogDetailTime>
            {blogDetailLoading ? (
              <SkeletonWrapper>
                <Skeleton />
              </SkeletonWrapper>
            ) : (
              <>
                {dayjs(blog?.updatedAt).format("dddd, MMMM D, YYYY h:mm A")}
                <Dot></Dot>
                {blog?.estTime} min read
              </>
            )}
          </BlogDetailTime>
        </div>
      </Heading>
      <Content>
        <BlogContent>
          {blogDetailLoading ? (
            <Skeleton height="540px" />
          ) : (
            blog?.coverImage && (
              <img
                src={blog.coverImage}
                alt=""
                style={{ height: "500px", objectFit: "cover" }}
              />
            )
          )}
          {blogDetailLoading ? (
            <div>
              <h1 className="title">
                <Skeleton />
              </h1>
              <p className="ck-content content">
                <Skeleton count={5} />
              </p>
            </div>
          ) : (
            <>
              <p
                className="ck-content content"
                dangerouslySetInnerHTML={{ __html: blog?.description ?? "" }}
              ></p>
              {blog?.sections.map((section) => (
                <Fragment key={section._id}>
                  <div id={section.hash}>
                    <h1 className="title">{section.title}</h1>
                    <p
                      className="ck-content content"
                      dangerouslySetInnerHTML={{
                        __html: section.description,
                      }}
                    />
                  </div>
                  {section.subSections.length > 0 &&
                    section.subSections.map((subSection) => (
                      <div key={subSection._id} id={subSection.hash}>
                        <h1 className="title">{subSection.title}</h1>
                        <p
                          className="ck-content content"
                          dangerouslySetInnerHTML={{
                            __html: subSection.description,
                          }}
                        />
                      </div>
                    ))}
                </Fragment>
              ))}
            </>
          )}
        </BlogContent>
        <MenuAndTabs>
          <Menu>
            <h2>On this page</h2>
            {blogDetailLoading
              ? [...Array(2)].map((_, index) => (
                  <Section
                    key={index}
                    style={{ paddingLeft: "20px", marginBottom: "10px" }}
                  >
                    <h3>
                      <Skeleton width="60%" />
                    </h3>
                    <div>
                      {[...Array(2)].map((_, id) => (
                        <Skeleton key={id} width="80%" height="100%" />
                      ))}
                    </div>
                  </Section>
                ))
              : blog?.sections.map((section) => (
                  <Section key={section._id}>
                    <Link key={section._id} to={`#${section.hash}`}>
                      <ButtonStyled
                        type="link"
                        $highlight={section.hash === activeId}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <h3>{section.title}</h3>
                      </ButtonStyled>
                    </Link>

                    {section.subSections.length > 0 && (
                      <div>
                        {section.subSections.map((subSection) => (
                          <Link key={subSection._id} to={`#${subSection.hash}`}>
                            <ButtonStyled
                              type="link"
                              icon={<SubSectionIcon>{">"}</SubSectionIcon>}
                              key={subSection._id}
                              $highlight={subSection.hash === activeId}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {subSection.title}
                            </ButtonStyled>
                          </Link>
                        ))}
                      </div>
                    )}
                  </Section>
                ))}
          </Menu>
          <Tabs>
            <h2>Tags</h2>
            <div>
              {blogDetailLoading
                ? [...Array(2)].map((_, id) => (
                    <Skeleton key={id} width="100px" />
                  ))
                : blog?.categories.map((category, index) => (
                    <TagWithIcon key={index}>{category.name}</TagWithIcon>
                  ))}
            </div>
          </Tabs>
        </MenuAndTabs>
      </Content>
      <ProjectBg>
        <ProjectGroup
          data={{
            title: "Explore projects",
            description:
              "Learn how to navigate the uncharted territories of the Web 3.0 economy. Learn new skills with the support of experts, while earn rewards from promising projects. ",
            btn: "",
          }}
          projects={newRecentUpdates}
          skipType={undefined}
          isFetching={isFetchingRecentUpdates || isLoadingRecentUpdates}
          totalProjectsLength={newRecentUpdates.length}
        />
      </ProjectBg>
    </Container>
  );
};

export default BlogDetail;
