import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IModal {
  connectWalletOpen: boolean;
  connectedWalletName: string;
}

const storageWalletName = localStorage.getItem("walletConnector") || "";

const initialState: IModal = {
  connectWalletOpen: false,
  connectedWalletName: storageWalletName,
};

export const modalSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setConnectWalletOpen: (state, action: PayloadAction<boolean>) => {
      state.connectWalletOpen = action.payload;
    },
    setWalletName: (state, action: PayloadAction<string>) => {
      state.connectedWalletName = action.payload;
      localStorage.setItem("walletConnector", action.payload);
    },
  },
});

export const { setConnectWalletOpen, setWalletName } = modalSlice.actions;

export default modalSlice.reducer;
