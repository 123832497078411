export const AddressWalletIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4.00047H1.5V3.50047L12.5 2.62047V3.50047H14V2.00047C14 0.900475 13.109 0.128475 12.021 0.283475L1.98 1.71747C0.891 1.87347 0 2.90047 0 4.00047V14.0005C0 14.5309 0.210714 15.0396 0.585786 15.4147C0.960859 15.7898 1.46957 16.0005 2 16.0005H14C14.5304 16.0005 15.0391 15.7898 15.4142 15.4147C15.7893 15.0396 16 14.5309 16 14.0005V6.00047C16 5.47004 15.7893 4.96133 15.4142 4.58626C15.0391 4.21119 14.5304 4.00047 14 4.00047ZM12.5 11.0065C12.303 11.0064 12.1078 10.9675 11.9258 10.8921C11.7438 10.8166 11.5784 10.706 11.4391 10.5666C11.2998 10.4273 11.1894 10.2618 11.114 10.0797C11.0387 9.89765 10.9999 9.70252 11 9.50548C11.0001 9.30843 11.0389 9.11332 11.1144 8.9313C11.1899 8.74927 11.3005 8.5839 11.4398 8.44461C11.5792 8.30532 11.7447 8.19485 11.9267 8.1195C12.1088 8.04416 12.304 8.00541 12.501 8.00547C12.899 8.00561 13.2806 8.16382 13.5619 8.44531C13.8432 8.72681 14.0011 9.10852 14.001 9.50647C14.0009 9.90443 13.8427 10.286 13.5612 10.5673C13.2797 10.8486 12.898 11.0066 12.5 11.0065Z"
        fill="#7E00FD"
      />
    </svg>
  );
};
