import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { getFeedbackDetail } from "api/projectDetail";
import { getProjectDetails, submitCollaboratorReviewAPI } from "api/users";
import BackToDetail from "components/modules/backToDetail";
import { useCheckProjectCreator } from "hooks";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { setPageLoading } from "redux/slices";
import { ROUTER_CONSTANTS } from "utils/constant";
import {
  Container,
  Content,
  StyledRate,
  RateGroup,
  RateTitle,
  StyledTextArea,
  ContentTitle,
  RateContainer,
  PostButton,
  UserName,
  Description,
  LeftContainer,
  RightContainer,
  ContentHead,
  Score,
  ScoreText,
  ReviewTitle,
  HeadingWrapper,
  WrapperBehindBg,
} from "./Feeback.style";

const Feedback = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isView =
    typeof location.state === "object" &&
    location?.state !== null &&
    "viewFeedback" in location?.state;
  const routerState = location.state as any;
  const feedbackId = routerState?.feedbackId;
  const projectId = routerState?.projectId;
  const packageId = routerState?.packageId;
  const prevUrl = routerState?.from;
  const isFromWtDetail = prevUrl?.includes("deliver");

  const [score, setScore] = useState({
    skills: 0,
    adherenceSchedule: 0,
    workQuality: 0,
    communication: 0,
    cooperation: 0,
  });
  const [description, setDescription] = useState("");
  const [validate, setValidate] = useState(true);
  const dispatch = useAppDispatch();

  const {
    data: projectRes,
    isSuccess,
    isError,
  } = useQuery(
    [`getProjectDetail-${params.projectId}`],
    () => getProjectDetails(params.projectId || ""),
    {
      // onSuccess: (res) => {
      //   if (res.responseCode === 200) {
      //     setProject(res.responseData);
      //   }
      // },
      // onError: (err: AxiosError) => {
      //   const rs: any = err?.response?.data;
      //   message.error(rs?.message);
      // },
      refetchOnWindowFocus: false,
      enabled: !isView,
    }
  );
  const { mutate: submitCollaboratorReview } = useMutation(
    submitCollaboratorReviewAPI
  );
  const { data: feedbackRes } = useQuery(
    [`getFeedbackDetail-${feedbackId}`],
    () => getFeedbackDetail(feedbackId),
    {
      onSuccess: (res) => {
        const {
          skills,
          adherenceSchedule,
          workQuality,
          communication,
          cooperation,
          description,
        } = res.responseData!.feedback;

        setScore({
          skills,
          adherenceSchedule,
          workQuality,
          communication,
          cooperation,
        });
        setDescription(description);
      },
      refetchOnWindowFocus: false,
      enabled: isView === true,
    }
  );
  const feedbackDetail = feedbackRes?.responseData;

  useCheckProjectCreator(projectRes?.responseData, isSuccess, isError, isView);

  const feedbackType = {
    skills: {
      name: "Skills",
      values: 0,
    },
    cooperation: {
      name: "Cooperation",
      values: 0,
    },

    adherenceSchedule: {
      name: "Adherence to Schedule",
      values: 0,
    },
    workQuality: {
      name: "Quality of Work",
      values: 0,
    },

    communication: {
      name: "Communication",
      values: 0,
    },
  };

  const totalScore =
    Object.values(score).reduce((prev, curr) => prev + curr, 0) / 5;
  const data = (location?.state as any)?.data;

  const handleFeedback = () => {
    if (description) setValidate(true);

    if (Object.values(score).includes(0))
      message.error("Please review all fields");
    else if (!description) {
      setValidate(false);
    } else {
      dispatch(setPageLoading(true));
      const sendData = {
        ...score,
        description: description || "",
        user: data?._id,
      };
      submitCollaboratorReview(
        {
          params: sendData,
          projectId: params?.projectId || "",
          packageId: params.packageId || "",
        },
        {
          onSuccess: (res) => {
            const { responseCode, responseMessage } = res;
            if (responseCode === 200) {
              message.success(responseMessage);
              navigate(-1);
            } else {
              message.error(responseMessage);
            }
          },
          onSettled: () => {
            dispatch(setPageLoading(false));
          },
        }
      );
    }
  };

  return (
    <>
      <HeadingWrapper>
        <WrapperBehindBg>
          {isFromWtDetail ? (
            <BackToDetail
              linkToGo={prevUrl}
              linkText="Back to worktask detail"
            />
          ) : isView ? (
            <BackToDetail
              linkToGo={ROUTER_CONSTANTS.PROJECT.REVIEW_WORKTASK.replace(
                ":projectId",
                projectId as string
              ).replace(":packageId", packageId as string)}
              linkText="Back to review applicants"
            />
          ) : (
            <BackToDetail
              linkToGo={ROUTER_CONSTANTS.PROJECT.DETAIL.replace(
                ":id",
                params.projectId as string
              )}
              linkText="Back to project detail"
              routerState={{
                toAllWorktaskTab: true,
              }}
            />
          )}
          <ReviewTitle>Feedback</ReviewTitle>
        </WrapperBehindBg>
      </HeadingWrapper>
      <Container>
        <ContentTitle>
          Public Feedback for{" "}
          <UserName>
            {isView
              ? feedbackDetail?.feedback.user.emailId
              : data?.fullName || data?.emailId}
          </UserName>
        </ContentTitle>
        <Description>
          This feedback will be shared on your freelancer's profile only after
          they've left feedback for you.
        </Description>
        <Content>
          <LeftContainer>
            <RateContainer>
              <ContentHead>Feedback to the collaborator</ContentHead>
              {(Object.keys(feedbackType) as (keyof typeof feedbackType)[]).map(
                (key) => (
                  <RateGroup key={key}>
                    <RateTitle>{feedbackType[key].name}</RateTitle>
                    <StyledRate
                      disabled={isView === true}
                      value={score[key]}
                      onChange={(value) => setScore({ ...score, [key]: value })}
                      style={{ color: "#F57F20", fontSize: 28 }}
                      // character={<StarIcon/>}
                    />
                  </RateGroup>
                )
              )}
            </RateContainer>
            {!isView && (
              <PostButton onClick={handleFeedback} width="196px" height="48px">
                Post Feedback
              </PostButton>
            )}
          </LeftContainer>
          <RightContainer>
            <ScoreText>Total Score: </ScoreText>{" "}
            <Score> {totalScore || "00.00"}</Score>
            <Description>
              Share your experience with this collaborator the BUILD community
            </Description>
            <StyledTextArea
              placeholder="Share your experience with this collaborator the BUILD community"
              disabled={isView === true}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {!validate && <span style={{ color: "red" }}>Can't be blank</span>}
          </RightContainer>
        </Content>
      </Container>
    </>
  );
};

export default Feedback;
