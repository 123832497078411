export function SendIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_7882_5084" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_7882_5084"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAADRElEQVR4nO2dv27UQBCHf0sACaW6k3JRKELBn1fgBWhTXgctT4Co0kB1FFQISCokRMGr8AQUQBkKRB6ApPhR+Fay7uw9e3a93l3PJ0WW4vN59HludmzfrQFFURRFURRFUZRSIHmb5BuSv9d/ZySPx46rOEiuuM0/FR6YdRa3cUXyM8n7Y8eZPSQvHaI3M/ze2PFmC8lvHURrhvtC8ksP0Sp8zQ3BNj8F29wC8AzA96kL7wzJp4KM1gzvC8nHAUSr8F2QnAcUrcJdsFuLp8J9Yb8WT4VD1nUAwI+gUWxTXJciFS1p8SQUIzx10ZbshadaOtrIXngvOEyLJyGbQdNINyR5CWAeMBYfrgF8BfDKGPNr7GCakJYOIH6ddpF8SfERPVaddpGs8FIyepPkhJcq2pKM8NJKRxujC/fpOuYALgPGEpPoXYooo0keADgLHEtMRs/wnZBckvwT+cRkaAY/8elcOlhl8XsAy6GCSYDBSkon0SSXqCQfhNx5wgQX7hQ9kSx2EUx4q+gJZrELb+Eu0XcAzAAcAbhbW27+7xjATcnOM0QsXNxH1yE5Q/NBaDpAJdBbeBDRXSnwU9JZeFTRXSG5B2AB4BCV/MV6eYjqQBzV1u2PFGadKwAfALw0xlw3vSBJ0X0guY/2g1A/QAsAewOH89YY86JpRfaiuxLpU/LXGNPYpflcvVO2YduKHAYcJ4mVjk9tK5IUnelg+A7AadsLUmnvNtu83Nq718YY5x2n0CcsbX2xXc5C7C8BOgu2SE7Bc82+EPQWbHEJegLgHOWcNvsgFmzZdZl0BmAF4LnkzQvAW7Cl64X/E0wru4MJ7g3JGcnzqHfy4mPvHT6IKrdF+AndvwfPkXQE12E52R1N8NS/QBOtBvv0vw+DRRGP0Qa5qYge/YvqPqLTGjiaGV2wpVTRyQi2lFY6khNsKSWjkxXsBas+OgWy+fmbNKMfBY2iP9llsFT0WPU5O8EWqejY9TlbwZbURWcv2AvqxChxoE71MzwcprVTwZtQp2MTIRkMQwyEkxvkJKJ9eujJCRZDnQQ2DtRpjeNAnag7DiQvNIMjQPfDFDSDQ8Hq8SCrdWZfkPxIfVqFoiiKoiiKoihKvvwHg9j0F5OAmK0AAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  )
}
