import styled from "styled-components";
import FlexBox from "components/base/Flexbox";
import { THEME } from "utils/theme";
import { CommonGradientContainer } from "pages/ProfileUser/profileUserInner.style";

export const PortfolioContainer = styled(CommonGradientContainer)`
  // max-width: ${THEME.MAX_WITH};
  // margin: auto;
  // height: auto;
  // background: white;
  // border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  // padding: 1.5rem 0 1.5rem;
  margin-bottom: 48px;
`;

export const Heading = styled(FlexBox)`
  justify-content: space-between;
  /* margin-bottom: 1.25rem; */
`;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-left: 1rem;
`;
