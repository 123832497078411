import PrimaryButton from "components/base/primaryButton";
import { KindSelect } from "../../../ProfileUser/components/BuilderSkill/builderSkill.style";
import FormItem from "antd/es/form/FormItem";
import styled from "styled-components";
import OutlinedButton from "components/base/outlinedButton";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import {
  Item,
  SendMessageButton,
} from "pages/ProjectDetail/ProjectDetail.style";

export const ActionObserverBtnWrapper = styled.div`
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  ${SendMessageButton} {
    margin-left: 0;
  }
`;

export const ObserverItem = styled(Item)`
  border-bottom: 1px solid #eee;

  > div:first-child {
    flex-basis: 49%;
    flex-wrap: nowrap;

    .ant-avatar-image {
      flex-shrink: 0;
    }

    > span:last-child {
      word-break: break-all;
    }
  }

  > div:last-child {
    flex-basis: 51%;
  }
`;

export const ActionBtnsWrapper = styled(CommonFlexRow)`
  margin-top: 24px;
  justify-content: flex-end;
  gap: 16px;

  > button {
    height: auto;
    padding-block: 15px;
  }
`;

export const DeleteObserverWrapper = styled.button`
  background: #fff1f1;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ObserverRole = styled.span`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.42);
`;

export const ObserverName = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080f6c;
`;

export const ObserverInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  .ant-avatar-square {
    border-radius: 4px;
  }
`;

export const SelectedObserverWrapper = styled.div`
  margin-top: 24px;
  margin-inline: -24px;
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-left: none;
  border-right: none;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;

export const AddObserverBtn = styled(OutlinedButton)`
  height: auto;
  width: 100%;
  padding-block: 10px;
  color: #7e00fd;
  font-family: "Rubik";
  font-style: normal;
  gap: 4px;

  svg {
    position: relative;
    top: -1px;
  }

  &.ant-btn:active,
  &.ant-btn:focus,
  &.ant-btn:hover {
    color: #7e00fd;
  }
`;

export const AddObserverFormWrapper = styled.div`
  // display: flex;
  // align-items: flex-end;

  // .ant-form {
  //   flex-grow: 1;
  // }

  .ant-form-inline .ant-form-item:not(:last-child) {
    flex-basis: 42%;
    max-width: 42%;
  }

  .ant-form-inline .ant-form-item:last-child {
    flex-grow: 1;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export const AddProjectSection = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? "block" : "none")};
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 8px;
  padding: 24px;
`;

export const AddProjectTitle = styled.h3`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #080f6c;
  margin-bottom: 40px;
`;

export const ModalTitle = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin: 0 auto 1.25rem;
  margin-bottom: 30px;
`;

export const StyledFormItem = styled(FormItem)`
  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  .ant-form-item-label {
    /* padding-bottom: 0.625rem; */
  }
  .ant-row {
    display: block !important;
  }
  ${KindSelect} {
    margin-top: 5px;
    width: 275px;
  }
`;

export const ModalButton = styled(PrimaryButton)<{ $open: boolean }>`
  display: ${({ $open }) => ($open ? "flex" : "none")};
  margin-right: 0;
  margin-left: auto;
`;
