import styled from "styled-components";
import { CommonFlexRow } from "../OrganizationPage.style";

export const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const GrantDescription = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const GrantSubTitle = styled.h4`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-bottom: 0;
`;

export const GrantStatWrapper = styled(CommonFlexRow)`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
`;

export const GrantTitle = styled.h3`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #080f6c;
`;

export const GrantWrapper = styled.section`
  padding-block: 48px;
`;
