import PrimaryButton from "components/base/primaryButton";
import { Input, Rate } from "antd";
import FlexBox from "components/base/Flexbox";
import styled from "styled-components";

export const ReviewTitle = styled.h1`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-block: 20px;
`;

export const HeadingWrapper = styled.div`
  padding: 0 99px;
  background: linear-gradient(
    90deg,
    rgba(208, 234, 255, 0.58) 0%,
    rgba(248, 233, 245, 0.75) 40%,
    rgba(246, 244, 241, 0.83) 60%,
    rgba(233, 247, 251, 0.92) 81%,
    rgba(233, 247, 250, 0.92) 100%
  );
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  max-width: 1170px;
  margin: 30px auto;
  color: #080f6c;
`;

export const Content = styled.div`
  display: flex;
  margin: auto;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  margin-bottom: 1.5rem;
  padding: 1.5rem 1.5rem 2.5625rem 1.5rem;
  margin-top: 20px;
`;

export const StyledRate = styled(Rate)`
  .ant-rate-star-zero {
    path {
      fill: #e0e0e0 !important;
    }
  }
`;

export const RateGroup = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

export const RateTitle = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080f6c;
  margin-bottom: 5px;
`;

export const StyledTextArea = styled(Input.TextArea)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 6.125rem !important;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const ContentTitle = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 24px;
  color: #080f6c;
`;

export const UserName = styled.span`
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 24px;
  color: #7e00fd;
`;

export const RateContainer = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 0 50px;
`;

export const BackButton = styled(PrimaryButton)`
  margin: 20px auto 0 0;
  display: flex;
  justify-content: center;
`;

export const Description = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  color: rgba(41, 46, 115, 0.82);
`;
export const RightContainer = styled.div`
  width: 50%;
`;

export const LeftContainer = styled.div`
  width: 50%;
`;

export const ContentHead = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #080f6c;
  width: 100%;
  margin-bottom: 30px;
`;
export const ScoreText = styled(ContentHead)`
  margin-bottom: 15px;
`;
export const Score = styled(ContentHead)`
  font-size: 24px;
  font-weight: 600;
`;

export const DownloadButton = styled.div`
  cursor: pointer;
  padding: 7px 15px;
  background: rgba(126, 0, 253, 0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #080f6c;
    font-weight: 600;
  }
`;
