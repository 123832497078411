export function SeachIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke="#080F6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 20.9999L16.65 16.6499"
        stroke="#080F6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function MenuIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        stroke="#080F6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
        stroke="#080F6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
        stroke="#080F6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7954_4402)">
        <path
          d="M8.33337 10.8332C8.69125 11.3117 9.14784 11.7075 9.67217 11.994C10.1965 12.2805 10.7763 12.4508 11.3722 12.4935C11.9682 12.5362 12.5664 12.4502 13.1262 12.2414C13.686 12.0326 14.1943 11.7058 14.6167 11.2832L17.1167 8.78322C17.8757 7.99738 18.2957 6.94487 18.2862 5.85238C18.2767 4.7599 17.8385 3.71485 17.066 2.94231C16.2934 2.16978 15.2484 1.73157 14.1559 1.72208C13.0634 1.71259 12.0109 2.13256 11.225 2.89156L9.79171 4.31656"
          stroke="#292E73"
          strokeOpacity="0.82"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 9.16677C11.3088 8.68833 10.8522 8.29245 10.3279 8.00599C9.80359 7.71953 9.22379 7.54918 8.62784 7.50649C8.03188 7.46381 7.43372 7.5498 6.87392 7.75862C6.31412 7.96744 5.80578 8.29421 5.38338 8.71677L2.88338 11.2168C2.12439 12.0026 1.70441 13.0551 1.7139 14.1476C1.7234 15.2401 2.1616 16.2851 2.93413 17.0577C3.70667 17.8302 4.75172 18.2684 5.84421 18.2779C6.93669 18.2874 7.9892 17.8674 8.77504 17.1084L10.2 15.6834"
          stroke="#292E73"
          strokeOpacity="0.82"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7954_4402">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function PictureIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08331 8.33325C7.77367 8.33325 8.33331 7.77361 8.33331 7.08325C8.33331 6.3929 7.77367 5.83325 7.08331 5.83325C6.39296 5.83325 5.83331 6.3929 5.83331 7.08325C5.83331 7.77361 6.39296 8.33325 7.08331 8.33325Z"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 12.4999L13.3334 8.33325L4.16669 17.4999"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export function MicIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.833252C9.33696 0.833252 8.70107 1.09664 8.23223 1.56549C7.76339 2.03433 7.5 2.67021 7.5 3.33325V9.99992C7.5 10.663 7.76339 11.2988 8.23223 11.7677C8.70107 12.2365 9.33696 12.4999 10 12.4999C10.663 12.4999 11.2989 12.2365 11.7678 11.7677C12.2366 11.2988 12.5 10.663 12.5 9.99992V3.33325C12.5 2.67021 12.2366 2.03433 11.7678 1.56549C11.2989 1.09664 10.663 0.833252 10 0.833252V0.833252Z"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8334 8.33325V9.99992C15.8334 11.547 15.2188 13.0307 14.1248 14.1247C13.0308 15.2187 11.5471 15.8333 10 15.8333C8.45292 15.8333 6.96919 15.2187 5.87523 14.1247C4.78127 13.0307 4.16669 11.547 4.16669 9.99992V8.33325"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15.8333V19.1666"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66669 19.1667H13.3334"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export function PaperPlaneIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7954_4389)">
        <path
          d="M18.3334 1.66675L9.16669 10.8334"
          stroke="#7E00FD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3334 1.66675L12.5 18.3334L9.16669 10.8334L1.66669 7.50008L18.3334 1.66675Z"
          stroke="#7E00FD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7954_4389">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function UploadIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4045 17.244L32.7004 17.5399C32.8764 17.7162 32.9117 17.9341 32.9117 18.228V27.8424V34.1965V34.3744C32.9117 34.6964 32.5878 35.0099 32.2047 35.1083C32.2014 35.1092 32.198 35.1101 32.1947 35.1111C32.1904 35.1124 32.1861 35.1138 32.1819 35.1151C32.1707 35.1186 32.1596 35.1222 32.1479 35.1244C32.0879 35.1364 32.0268 35.1429 31.9654 35.1429H16.5675C16.506 35.1429 16.4449 35.1364 16.3846 35.1244C16.3729 35.1222 16.3617 35.1186 16.3506 35.1151L16.3506 35.1151L16.3505 35.1151C16.343 35.1127 16.3354 35.1103 16.3277 35.1083C15.945 35.0099 15.6211 34.6964 15.6211 34.3744V34.1965V27.8424V13.9815C15.6211 13.2091 16.1118 12.8572 16.5675 12.8572H27.7188C27.909 12.8572 28.0953 12.9344 28.2294 13.0693L28.5245 13.3644C28.5249 13.3644 28.5253 13.3648 28.5253 13.3648L32.4045 17.244ZM31.5419 17.468L28.3009 14.227V17.468H31.5419ZM16.5675 34.3744C16.5408 34.3744 16.5167 34.3652 16.493 34.3562L16.4914 34.3556C16.4314 34.3268 16.3896 34.2668 16.3896 34.1965V28.6109H32.1433V34.1965C32.1433 34.2668 32.1014 34.3268 32.0414 34.3556L32.0398 34.3562C32.0161 34.3652 31.992 34.3744 31.9654 34.3744H16.5675ZM16.3896 13.9815V27.8424H32.1433V18.228C32.1433 18.2249 32.1432 18.2227 32.1431 18.2206C32.143 18.2187 32.1429 18.2168 32.1429 18.2142C32.0945 18.2269 32.0449 18.2365 31.993 18.2365H27.5324V13.7763C27.5324 13.724 27.542 13.6741 27.5547 13.6257H16.5675C16.4022 13.6257 16.3896 13.8981 16.3896 13.9815Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6415 30.2439H22.5958L22.4855 30.328L20.6415 33.0964V33.6059H23.2159V33.0964H21.2667L21.372 33.0123L23.2159 30.2439V29.7344H20.6415V30.2439Z"
        fill="white"
      />
      <rect
        x="24.1143"
        y="29.7344"
        width="0.640906"
        height="3.87157"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3184 29.8131C27.4794 29.8654 27.6239 29.9446 27.7519 30.0494C27.8798 30.1547 27.9835 30.2811 28.0623 30.4302C28.1411 30.5793 28.1807 30.7465 28.1807 30.932C28.1807 31.1284 28.1472 31.3059 28.0808 31.4654C28.0139 31.6248 27.9213 31.7589 27.8022 31.8673C27.6831 31.9756 27.5394 32.0598 27.3715 32.1193C27.2035 32.1789 27.0176 32.2085 26.8147 32.2085H26.3471V33.6059H25.7166V29.7344H26.8301C26.9945 29.7344 27.1574 29.7605 27.3184 29.8131ZM27.3607 31.5779C27.4237 31.5184 27.4741 31.4354 27.5129 31.3286C27.5513 31.2218 27.5709 31.0896 27.5705 30.9317C27.5705 30.8686 27.5617 30.796 27.5444 30.7138C27.5267 30.6316 27.491 30.5528 27.4368 30.4775C27.3822 30.4022 27.3061 30.3392 27.2082 30.2884C27.1098 30.2377 26.9803 30.2124 26.8193 30.2124H26.3467V31.7462H26.9246C27.0014 31.7462 27.0779 31.7328 27.1532 31.7067C27.2285 31.6805 27.2977 31.6375 27.3607 31.5779Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.227 21.6947H24.4586V22.4631H25.9955V24.3843C25.9955 25.4437 25.1337 26.3055 24.0743 26.3055C23.015 26.3055 22.1531 25.4437 22.1531 24.3843V22.4631H23.6901V21.6947H22.9216V20.9262H23.6901V20.1577H22.9216V19.3892H23.6901V18.6208H22.9216V17.8523H23.6901V17.0838H22.9216V16.3153H23.6901V15.5469H24.4586V16.3153H25.227V17.0838H24.4586V17.8523H25.227V18.6208H24.4586V19.3892H25.227V20.1577H24.4586V20.9262H25.227V21.6947ZM24.0743 25.537C24.7098 25.537 25.227 25.0198 25.227 24.3843V23.2316H22.9216V24.3843C22.9216 25.0198 23.4388 25.537 24.0743 25.537Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6901 24.7685H24.4586C24.6707 24.7685 24.8428 24.5967 24.8428 24.3842C24.8428 24.1718 24.6707 24 24.4586 24H23.6901C23.478 24 23.3058 24.1718 23.3058 24.3842C23.3058 24.5967 23.478 24.7685 23.6901 24.7685Z"
        fill="white"
      />
    </svg>
  )
}
