export const LockIcon = () => {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49967 12.834C1.17884 12.834 0.904285 12.7198 0.676008 12.4916C0.447341 12.2629 0.333008 11.9882 0.333008 11.6673V5.83398C0.333008 5.51315 0.447341 5.2384 0.676008 5.00973C0.904285 4.78146 1.17884 4.66732 1.49967 4.66732H2.08301V3.50065C2.08301 2.69371 2.36748 2.00576 2.93642 1.43682C3.50498 0.868262 4.19273 0.583984 4.99967 0.583984C5.80662 0.583984 6.49456 0.868262 7.06351 1.43682C7.63206 2.00576 7.91634 2.69371 7.91634 3.50065V4.66732H8.49967C8.82051 4.66732 9.09526 4.78146 9.32392 5.00973C9.5522 5.2384 9.66634 5.51315 9.66634 5.83398V11.6673C9.66634 11.9882 9.5522 12.2629 9.32392 12.4916C9.09526 12.7198 8.82051 12.834 8.49967 12.834H1.49967ZM4.99967 9.91732C5.32051 9.91732 5.59526 9.80318 5.82392 9.5749C6.0522 9.34623 6.16634 9.07148 6.16634 8.75065C6.16634 8.42982 6.0522 8.15507 5.82392 7.9264C5.59526 7.69812 5.32051 7.58398 4.99967 7.58398C4.67884 7.58398 4.40429 7.69812 4.17601 7.9264C3.94734 8.15507 3.83301 8.42982 3.83301 8.75065C3.83301 9.07148 3.94734 9.34623 4.17601 9.5749C4.40429 9.80318 4.67884 9.91732 4.99967 9.91732ZM3.24967 4.66732H6.74967V3.50065C6.74967 3.01454 6.57954 2.60135 6.23926 2.26107C5.89898 1.92079 5.48579 1.75065 4.99967 1.75065C4.51356 1.75065 4.10037 1.92079 3.76009 2.26107C3.41981 2.60135 3.24967 3.01454 3.24967 3.50065V4.66732Z"
        fill="#292E73"
        fillOpacity="0.42"
      />
    </svg>
  );
};
