import styled from "styled-components";
import { Input, Avatar as AntdAvatar } from "antd";
import FormItem from "antd/lib/form/FormItem";
import OutlinedButton from "components/base/outlinedButton";
import image from "components/base/image";
import { CommonGradientContainer } from "pages/ProfileUser/profileUserInner.style";

export const Description = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: rgba(41, 46, 115, 0.82);
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const BigLabel = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-bottom: 0.3rem;
`;

export const StyledInput = styled(Input)`
  width: 408px;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;
export const StyledTextArea = styled(Input.TextArea)`
  width: 408px;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 5.875rem !important;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledFormItem = styled(FormItem)`
  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  .ant-form-item-label {
    padding-bottom: 0.35rem;
  }
  margin-bottom: 33px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(156, 138, 193, 0.16);
  margin-bottom: 15px;
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
`;

export const UploadButton = styled.div`
  span {
    width: 84px;
    height: 84px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 30px;
  }
  margin: 20px auto;
`;

export const SecondaryButton = styled(OutlinedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: auto;
  height: 32px;
  background: rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  color: rgba(41, 46, 115, 0.42);
`;

export const ActionButton = styled(SecondaryButton)`
  background: #7e00fd;
  color: white;
  margin-left: auto;
`;

export const UploadContainer = styled.div`
  // > div {
  //   margin-left: 36px;
  // }
  // > span {
  //   margin-left: 26px;
  // }
`;

export const AvatarImage = styled(image)`
  object-fit: cover;
`;

export const AvatarImageAntd = styled(AntdAvatar)`
  margin: auto;
`;

export const WhiteContainer = styled.div`
  // background: white;
  padding: 26px;
`;

export const BuilderProfileContainer = styled(CommonGradientContainer)`
  // height: fit-content;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  // &:hover {
  //   ${WhiteContainer} {
  //     background: transparent;
  //   }
  // }
`;
