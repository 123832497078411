export const DownIcon = ({ fill }: { fill?: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7882_4639)">
      <path
        d="M8.99956 9.879L12.7121 6.1665L13.7726 7.227L8.99956 12L4.22656 7.227L5.28706 6.1665L8.99956 9.879Z"
        fill={fill || "#7E00FD"}
      />
    </g>
    <defs>
      <clipPath id="clip0_7882_4639">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
