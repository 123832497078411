export const JobPreferencesIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#9C8AC1" fillOpacity="0.16" />
    <path
      d="M25.6252 33.0752L32.7002 26.0127L35.3502 28.6627L28.2752 35.7252L25.6252 33.0752ZM29.6252 22.9127L25.7502 19.0627C25.6024 18.9179 25.4339 18.7957 25.2502 18.7002L23.8502 13.3377C23.7604 13.0256 23.5528 12.7607 23.2713 12.5989C22.9897 12.437 22.6562 12.391 22.3414 12.4706C22.0265 12.5501 21.7549 12.7489 21.5839 13.025C21.413 13.3012 21.3561 13.6329 21.4252 13.9502L22.7502 19.0377L19.8377 12.9877C19.5502 12.3627 18.8002 12.1252 18.1752 12.3877C17.5502 12.6877 17.2877 13.4377 17.5877 14.0627L19.7002 18.4877L15.4877 14.3127C15.0002 13.8252 14.2002 13.8252 13.7127 14.3127C13.2252 14.8127 13.2377 15.6002 13.7252 16.0877L18.3752 20.7127L17.5002 21.0502L16.1502 21.5877L13.7627 23.2377C13.7627 23.2377 12.7502 24.5502 12.6627 25.1127C12.5627 25.6752 13.6502 28.5002 13.6502 28.5002H13.6627C13.8627 28.9252 14.2877 29.2252 14.7877 29.2252C15.1193 29.2252 15.4372 29.0935 15.6716 28.8591C15.9061 28.6247 16.0377 28.3067 16.0377 27.9752C16.0377 27.8502 16.0002 27.7502 15.9627 27.6377L15.9877 27.6252L15.2502 25.4627L16.7877 24.2377C17.3752 24.2627 18.6377 24.3627 19.2502 24.4002C22.6627 27.4627 19.4377 28.6627 19.4377 28.6627L15.1127 29.9877L14.9002 30.1627C14.772 30.2813 14.6718 30.427 14.6069 30.5891C14.542 30.7513 14.5141 30.9259 14.5252 31.1002L14.5502 32.4377L22.5877 31.8127C23.0627 31.8252 23.5127 31.6502 23.8627 31.3127L29.6252 25.5627C30.2877 24.8877 30.3127 23.6252 29.6252 22.9127Z"
      fill="#7E00FD"
    />
  </svg>
);
