import BaseButton from "components/base/baseButton";
import styled from "styled-components";

import { Input, Select, Upload } from "antd";
import FormItem from "antd/lib/form/FormItem";
import OutlinedButton from "components/base/outlinedButton";
import { StyledTextArea as CommonStyledTextArea } from "pages/ProfileUser/profileUserInner.style";

const { Option } = Select;

export const Heading = styled.div`
  width: 100%;
  height: 8rem;
  background: linear-gradient(
    90deg,
    rgba(208, 234, 255, 0.58) 0%,
    rgba(248, 233, 245, 0.75) 40%,
    rgba(246, 244, 241, 0.83) 60%,
    rgba(233, 247, 251, 0.92) 81%,
    rgba(233, 247, 250, 0.92) 100%
  );
  div {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #080f6c;
    max-width: 73.125rem;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0rem 3.125rem;
    max-width: 1270px;
    margin: auto;
  }
`;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin: 3rem auto 1.25rem;
`;
export const Container = styled.div`
  display: grid;
  max-width: 73.125rem;
  margin: auto;
  grid-template-columns: 12.5rem auto;
  max-width: 1170px;
  margin: auto;
  padding: 0rem 3.125rem;
`;

export const Left = styled.div`
  width: 12.5rem;
  border-right: 0.0625rem solid rgba(41, 46, 115, 0.12);
  height: 100%;
  padding-top: 48px;
`;
export const Right = styled.div`
  padding-left: 3rem;
  padding-bottom: 6.25rem;
`;
export const AvatarContainer = styled.div`
  width: 9.375rem;
  text-align: center;
`;
export const StyledInput = styled(Input)`
  max-width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;
export const StyledTextArea = styled(CommonStyledTextArea)`
  &.ant-input {
    min-height: 130px;
  }
`;

export const StyledFormItem = styled(FormItem)`
  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  .ant-form-item-label {
    padding-bottom: 0.625rem;
  }
`;

export const StandaloneFormItem = styled(StyledFormItem)`
  .ant-form-vertical & .ant-form-item-control {
    width: calc(50% - 0.625rem);
  }
`;

export const BigLabel = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-bottom: 0.3rem;
`;

export const LabelDescription = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  color: rgba(41, 46, 115, 0.82);
  opacity: 0.6;
  margin-bottom: 1rem;
`;

export const StyledUpload = styled(Upload)`
  .ant-upload-list {
    width: 100%;
    .ant-upload-list-picture-card-container {
      display: block;
      margin: auto;
    }
    .ant-upload-list-picture-card-container {
      width: 18.75rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .ant-upload-list-item {
      border: 0.0625rem solid rgba(41, 46, 115, 0.22);
    }
  }
  .ant-upload {
    width: 100%;
    height: 8rem;
    &.ant-upload-select-picture-card {
      border-radius: 0.25rem;
      border: 0.0625rem dashed rgba(41, 46, 115, 0.12);
    }
  }
`;

export const AvatarUpload = styled(Upload)`
  .ant-upload-list {
    width: 9.375rem;
    margin-top: 1.875rem;
    margin-bottom: 0.625rem;
    .ant-upload-list-picture-card-container {
      display: block;
      margin: auto;
    }
    .ant-upload-list-picture-card-container {
      width: 9.375rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .ant-upload-list-item {
      border: 0.0625rem solid rgba(41, 46, 115, 0.22);
    }
  }
  .ant-upload {
    width: 9.375rem;
    height: 9.375rem;

    &.ant-upload-select-picture-card {
      border-radius: 0.25rem;
      background: radial-gradient(
        100% 445.32% at 0% 100%,
        rgba(126, 0, 253, 0.07) 0%,
        rgba(185, 61, 243, 0.07) 42.35%,
        rgba(165, 75, 244, 0.07) 57.95%,
        rgba(0, 191, 251, 0.07) 100%
      );
      border: 0.0625rem solid rgba(41, 46, 115, 0.07);
    }
  }
`;
export const NoFileText = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  margin-left: 0.9rem;
`;
export const SocialFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 0 1.25rem;
  margin-top: 2rem;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080f6c;
`;

export const LabelWithIcon = styled(Label)`
  display: flex;
  align-items: center;
  // font-weight: 400;
  // font-size: 14px;
  // line-height: 100%;
  // letter-spacing: -0.01em;
  // color: #080f6c;
  svg {
    margin-right: 0.3rem;
  }
`;

export const StyledSelect = styled(Select)`
  max-width: 100%;
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 2.25rem;
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  svg {
    margin-right: 0.3rem;
  }
  .ant-select-selection-placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #080f6c;

    margin-left: 0.5rem;
  }
  .ant-select-selection-item {
    padding-top: 0.1875rem !important;
  }
  .ant-select-arrow {
    svg {
      margin-right: 0;
    }
  }
  &.ant-select-open {
    border: 1px solid #7e00fd;
    border-radius: 4px 4px 0px 0px;
    .ant-select-arrow {
      transform: rotate(180deg);
      path {
        stroke: #7e00fd;
      }
    }
  }
`;

export const StyledOption = styled(Option)`
  /* background: red !important; */
`;

export const TokenSelectText = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 156%;
  letter-spacing: -0.01em;
  color: rgba(41, 46, 115, 0.82);
  margin-top: 0.3rem;
`;

export const ChooseImageButton = styled(BaseButton)`
  width: 9.5rem;
  height: 2.75rem;
`;

export const BtnActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const CancelButton = styled(OutlinedButton)`
  width: unset;
  height: unset;
  padding: 16px;
  color: #7e00fd;
`;
