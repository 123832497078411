import { FC, ReactElement } from "react";
import { IConversationPageProps } from "./Conversation.props";
import ConversationBackground from "assets/images/submit-deliverable/submit-deliverable-background.png";
import { Container } from "./Conversation.style";
import {
  BackgroundImage,
  TitleWrap,
} from "pages/SubmitDeliverable/SubmitDeliverable.style";
import Conversation from "./components/Conversation";
import { ROOM_TYPES } from "utils/constant";

const ConversationPage: FC<IConversationPageProps> = ({
  ...props
}: IConversationPageProps): ReactElement => {
  return (
    <Container>
      <TitleWrap>
        <BackgroundImage
          src={ConversationBackground}
          alt="Conversation background"
        />
        All Conversations
      </TitleWrap>
      <Conversation marginTop status={ROOM_TYPES.DIRECT} />
    </Container>
  );
};

export default ConversationPage;
