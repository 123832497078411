import { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { ICourseFinalisationProps } from "./courseFinalisation.props";
import {
  Body,
  Container,
  Head,
  WrapButton,
  WrapSubInfo,
} from "./courseFinalisation.style";
import PrimaryButton from "components/base/primaryButton";
import { BookmarkIcon } from "assets/icons/bookmark.icon";
import OutlinedButton from "components/base/outlinedButton";
import { message, Tabs } from "antd";
import OverView from "./overView";
import Path from "./path";
import Learners from "./learners";
import { useNavigate, useParams } from "react-router-dom";
import { ClockTransparentIcon, ExerciseIcon } from "assets/icons/clock.icon";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { removeCourseData, setPageLoading } from "redux/slices";
import { COURSE_STATUS, COURSE_TYPE, ICourse, ICourseReward } from "api/course";
import {
  createCourseAPI,
  getCourseByIdAPI,
  updateCourseAPI,
} from "api/course/request";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEthers } from "@usedapp/core";
import {
  approveERC20LearnToEarn,
  approveERC721LearnToEarn,
  getERC20AllowanceLearnToEarn,
  getERC20Decimal,
  getERC721Approval,
  handleMessageErrorSC,
} from "web3/contract";
import { createCourseSC } from "web3/learnToEarnContract";
import { parseUnits } from "ethers/lib/utils";
import dayjs from "dayjs";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import {
  formatTimeCommitment,
  getPluralText,
} from "utils/formatTimeCommitment";
import { HeadingCourse } from "../components/HeadingCourse";
import { convertTimeTo } from "utils/time";
import { ROUTER_CONSTANTS } from "utils/constant";

const CourseFinalisation: FC<ICourseFinalisationProps> = (
  props: ICourseFinalisationProps
) => {
  const navigate = useNavigate();
  const { account } = useEthers();
  const dispatch = useAppDispatch();

  const user: any = useAppSelector((state) => state.auth.user);
  const { step, course } = useAppSelector((state) => state.course);
  const { pageLoading } = useAppSelector((state) => state.loading);

  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();
  const { mutate: createCourse } = useMutation(createCourseAPI);
  const { mutate: updateCourse } = useMutation(updateCourseAPI);
  const params = useParams();

  const [activeTab, setActiveTab] = useState<string>("0");

  const parseCourse = {
    ...course.info,
    rewards: course.rewards as ICourseReward,
    sections: course.sections,
  } as ICourse;

  const tabs = [
    {
      label: `Course introduction`,
      children: <OverView course={parseCourse} />,
    },
    {
      label: `Path`,
      children: <Path course={parseCourse} />,
    },
    {
      label: `Learners`,
      children: <Learners />,
    },
  ];

  const courseExercise = course.sections.reduce((init, item) => {
    return (init += item.activities.length);
  }, 0);

  const { rewards, info, sections } = course;

  const [courseCreated, setCourseCreated] = useState<ICourse>();
  const countRef = useRef(0);

  useEffect(() => {
    if (countRef.current === 4) {
      dispatch(setPageLoading(false));
      dispatch(removeCourseData());
      navigate(ROUTER_CONSTANTS.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, countRef.current]);

  useQuery(
    ["get-course-detail", courseCreated],
    () => {
      countRef.current += 1;
      return getCourseByIdAPI({ courseId: courseCreated!._id! });
    },
    {
      refetchOnWindowFocus: false,
      select: (data) =>
        data && data.responseCode === 200
          ? (data.responseData as ICourse)
          : undefined,
      enabled: courseCreated !== undefined,
      onSuccess: (data) => {
        if (data?.status === "PUBLISHED") {
          dispatch(setPageLoading(false));
          dispatch(removeCourseData());
          navigate(ROUTER_CONSTANTS.HOME);
        }
      },
      refetchInterval: () => {
        if (countRef.current < 4) return 10000;
        return false;
      },
      refetchIntervalInBackground: true,
    }
  );

  const infoConverted: any = useMemo(() => {
    if (info)
      return {
        ...info,
        startDate:
          info.type === COURSE_TYPE.CUSTOM_DAY_PLAN
            ? null
            : dayjs(info.startDate).unix(),
        endDate:
          info.type === COURSE_TYPE.CUSTOM_DAY_PLAN || info.noExpire
            ? null
            : dayjs(info.endDate).unix(),
      };
    else return {};
  }, [info]);

  const handleEditingCourse = () => {
    dispatch(setPageLoading(true));
    delete infoConverted?.txHash;
    updateCourse(
      {
        courseId: params.id!,
        sections: sections,
        info: infoConverted,
      },
      {
        onSuccess: () => {
          dispatch(setPageLoading(false));
          dispatch(removeCourseData());
          navigate(ROUTER_CONSTANTS.HOME);
        },
        onError: () => {
          dispatch(setPageLoading(false));
        },
      }
    );
  };
  const handleCreateCourse = async () => {
    // Edit course already publish
    if (params?.id && course.info?.status === COURSE_STATUS.PUBLISHED) {
      handleEditingCourse();
      return;
    }
    // Create a new course || update reward for course
    const network = course.network || rewards?.token?.network;
    if (!user?.organization) {
      message.error("Please become an organization before proceeding");
      return;
    }

    if (!checkWallet()) return;

    dispatch(setPageLoading(true));

    if (network) {
      try {
        await handleSwitchNetwork(+network);
      } catch (error) {
        return;
      }

      const tokenContract = rewards?.token?.contractAddress;
      const nftContract = rewards?.nft?.contractAddress;

      if (rewards && info && sections) {
        const totalCourseBudget = rewards.prizeTotal * rewards.rewardPerLearner;
        const rewardAddress: string = tokenContract || nftContract || "";
        if (tokenContract) {
          try {
            const decimal = await getERC20Decimal(tokenContract);
            const allowance = (
              await getERC20AllowanceLearnToEarn({
                walletAddress: account,
                erc20Address: tokenContract,
              })
            )?.toString();
            const allowanceEnough =
              +allowance <
              +parseUnits("" + totalCourseBudget, decimal).toString();

            if (allowance === "0" || allowanceEnough) {
              try {
                await approveERC20LearnToEarn({
                  walletAddress: account,
                  erc20Address: tokenContract,
                });
              } catch (error: any) {
                handleMessageErrorSC(error?.reason);
                dispatch(setPageLoading(false));
                return;
              }
            }
          } catch (err: any) {
            handleMessageErrorSC(err?.reason);
            dispatch(setPageLoading(false));
            return;
          }
        }

        if (nftContract) {
          // TODO: User grants L2E contract permission to transfer NFT later on
          try {
            const allowance = await getERC721Approval({
              walletAddress: account,
              erc721Address: nftContract,
            });
            if (!allowance) {
              await approveERC721LearnToEarn({
                erc721Address: nftContract,
                approved: true,
              });
            }
          } catch (err: any) {
            handleMessageErrorSC(err?.reason);
            dispatch(setPageLoading(false));
            return;
          }
        }

        const executeSC = async (response: any) => {
          try {
            let decimal = 0;
            if (tokenContract) {
              decimal = await getERC20Decimal(tokenContract);
            }

            // Create course in blockchain
            await createCourseSC({
              rewardAddress,
              budget: parseUnits("" + totalCourseBudget, decimal).toString(),
              bonus: parseUnits(
                "" + rewards.rewardPerLearner,
                decimal
              ).toString(),
              timeStart:
                info.type === "CUSTOM_DAY_PLAN"
                  ? 0
                  : dayjs().diff(dayjs(info?.startDate), "d") === 0 &&
                    dayjs() > dayjs(info?.startDate)
                  ? 0
                  : dayjs(info.startDate).unix(),
              timeEndBonus:
                info.type === "CUSTOM_DAY_PLAN"
                  ? info.range
                  : info.noExpire
                  ? 0
                  : dayjs(info.endDate).unix(),
              isUsingDuration: info.type === "CUSTOM_DAY_PLAN",
              isBonusToken: rewards.kind === "TOKEN",
              courseId: response?.responseData?._id,
            });
            setCourseCreated(response.responseData);
          } catch (error: any) {
            handleMessageErrorSC(error?.reason);
            dispatch(setPageLoading(false));
            setCourseCreated(undefined);
          }
        };

        if (!params?.id)
          createCourse(
            {
              ...info,
              startDate:
                info.type === COURSE_TYPE.CUSTOM_DAY_PLAN
                  ? null
                  : dayjs(info.startDate).unix(),
              endDate:
                info.type === COURSE_TYPE.CUSTOM_DAY_PLAN || info.noExpire
                  ? null
                  : dayjs(info.endDate).unix(),
              rewards: {
                ...rewards,
                totalCourseBudget:
                  rewards.prizeTotal * rewards.rewardPerLearner,
              },
              sections: sections,
            },
            {
              onSuccess: async (response) => executeSC(response),
              onError: (error: any) => {
                const rs: any = error?.response?.data;
                message.error(rs.responseMessage);
                dispatch(setPageLoading(false));
              },
            }
          );
        else {
          updateCourse(
            {
              rewards: {
                ...rewards,
                totalCourseBudget:
                  rewards.prizeTotal * rewards.rewardPerLearner,
              },
              courseId: params.id!,
              sections: sections,
              info:
                {
                  ...info,
                  startDate:
                    info.type === COURSE_TYPE.CUSTOM_DAY_PLAN
                      ? null
                      : dayjs(info.startDate).unix(),
                  endDate:
                    info.type === COURSE_TYPE.CUSTOM_DAY_PLAN || info.noExpire
                      ? null
                      : dayjs(info.endDate).unix(),
                } ?? undefined,
            },
            {
              onSuccess: async (response) => executeSC(response),
              onError: (error: any) => {
                const rs: any = error?.response?.data;
                message.error(rs.responseMessage);
                dispatch(setPageLoading(false));
              },
            }
          );
        }
      }
    }
  };

  const deadline = useMemo(() => {
    if (!course || !course.info || !info) return "";
    if (course.info.type === COURSE_TYPE.CUSTOM_DAY_PLAN)
      return formatTimeCommitment(info.range / 3600);
    return `${dayjs(course.info.startDate).format("DD MMM YYYY")} - ${
      course.info.noExpire
        ? "unlimited"
        : dayjs.tz(course.info.endDate).format("DD MMM YYYY")
    }`;
  }, [course, info]);

  const estTime = useMemo(() => {
    const { value, unit } = convertTimeTo({
      value: course.info?.estimatedTime,
      convertFromDBToForm: true,
    });
    return getPluralText(value, unit!);
  }, [course.info]);

  return (
    <>
      <HeadingCourse
        step={step}
        action={handleCreateCourse}
        loading={pageLoading}
      />
      <Container>
        <Head>
          <div>
            <h1>{course.info?.title}</h1>
            <WrapButton>
              <PrimaryButton>Start Free Course</PrimaryButton>
              <OutlinedButton icon={<BookmarkIcon />} className="bookmark" />
            </WrapButton>
            <WrapSubInfo>
              <div>
                <ClockTransparentIcon />
                <span>{estTime}</span>
              </div>
              <div>
                <ExerciseIcon />
                <span>{courseExercise} exercises</span>
              </div>
              <div>
                <span>
                  Reward: {course?.rewards?.rewardPerLearner}{" "}
                  {(
                    course?.rewards?.token?.symbol ??
                    course?.rewards?.nft?.symbol ??
                    ""
                  ).slice(0, 5)}
                </span>
              </div>
              <div>
                <span>
                  Deadline to complete the training to be eligible for a reward:{" "}
                  {deadline}
                </span>
              </div>
            </WrapSubInfo>
          </div>
        </Head>
        <Body>
          <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
            {tabs.map((item: any, index: number) => (
              <Tabs.TabPane tab={item.label} key={index}>
                {item.children}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Body>
      </Container>
    </>
  );
};

export default memo(CourseFinalisation);
