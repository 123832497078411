import styled from "styled-components";

import bg_img from "assets/images/addPortfolio/bg_datasetup.png";

import { Link } from "react-router-dom";

const STEP_THEME = {
  BG_ACTIVE_COLOR: "#7e00fd",
  BG_MISS_COLOR: "#C3C6CF",
  BG_COMPLETE_COLOR: "#00DE8C",
};

//
export const StepContainer = styled.div`
  min-height: 50vh;
`;

export const StepBlock = styled.div``;

export const StepBody = styled.div`
  margin: 0px 135px;
  border-bottom: 1px solid #73899533;
  padding-bottom: 60px;
`;

export const StepFooter = styled.div`
  display: flex;
  margin: 25px 135px 0px 135px;
`;

export const BackStep = styled.div`
  color: #000000;
  cursor: pointer;
`;

export const BackHome = styled(Link)`
  color: #000000;
  cursor: pointer;
  margin: auto;
`;

export const NextStep = styled.div`
  margin-left: auto;
  background: ${STEP_THEME.BG_ACTIVE_COLOR};
  border-radius: 6px;
  height: 40px;
  width: 87px;
  padding: 8px 16px 8px 16px;
  color: #ffffff;
  cursor: pointer;
`;

export const Header = styled.div`
  width: 100%;
  height: 149px;
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  padding-top: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .active {
    background: #7e00fd;
    color: #fff;
    /* .step-menu {
      color: #080a25;
      font-weight: 600;
    } */
  }
  .complete-active {
    background: #00de8c;
    color: #fff;
  }
  .line-active {
    &::before {
      background: #7e00fd;
    }
  }
`;

export const MainTitleWrap = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 17px;
  padding: 28px 24px;
  margin-bottom: 24px;
  border-radius: 8px 8px 0px 0px;
  /* background-color: red; */
  position: relative;
  & > div {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #080a25;
  }
  & > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: rgba(16, 37, 72, 0.5);
    display: flex;
    gap: 4px;
    p {
      color: #7e00fd;
      margin: 0px;
    }
  }

  &::after {
    content: "";
    background: url(${bg_img});
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;
