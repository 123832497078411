import moment from "moment";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
export const convertNumberToMoment = (dateNumber: number) => {
  return moment(new Date(dateNumber));
};

export function timeRemaining(endTime: number, startTime: number) {
  if (endTime < Date.now()) return "0h:0m";

  let baseTime = moment();
  if (startTime > Number(baseTime.format("x"))) baseTime = moment(startTime);

  const totalMinute = moment(endTime).diff(baseTime, "m") + 1;
  const hour = Math.floor(totalMinute / 60);
  const minute = totalMinute % 60;

  // var now = Number(moment().format("x"));
  // var a = 1670198400000;

  return `${hour}h:${minute}m`;
}

export function deliveryTime(endDate: number) {
  const now = Number(moment().format("x"));

  const days = moment(endDate).diff(moment(now), "days") + 1;
  if (days === 1) return `${days} day task`;

  if (days < 0) return "expired";
  else return `${days} days task`;
}

export function dayNotification(date: number) {
  const now = Number(moment().format("x"));
  const minutes = moment(now).diff(moment(date), "m");

  if (minutes <= 1) return `few seconds ago`;

  if (minutes > 1) {
    if (minutes < 60) return `${minutes} minutes ago`;

    if (minutes <= 120) return `1 hour ago`;

    if (minutes > 120) {
      const hours = Math.floor(minutes / 60);
      if (hours < 24) return `${hours} hours ago`;

      if (hours < 48) return `1 day ago`;

      if (hours >= 48) {
        const day = Math.floor(hours / 24);
        return `${day} days ago`;
      }
    }
  }
}
