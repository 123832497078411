import { IUserInfo } from "api/users";
import { Dayjs } from "dayjs";

export interface ICommon {
  id?: string;
  _id?: string;
  title?: string;
  name?: string;
}
export interface IParticipant {
  participationId: string;
  userId: string;
  emailId: string;
  profilePicture: string;
  fullName: string;
  username: string;
  employmentHistory: [
    {
      isCurrentWork: boolean;
      _id: string;
      startDate: number;
      endDate: number;
      designation: string;
      company: string;
      companyUrl: string;
      experienceFromCompany: string;
    }
  ];
}
export interface ISomeInfo {
  video_duration?: string;
  video_count?: number;
  exercise_count?: number;
  participant_count?: number;
  participants?: IParticipant[];
  isJoinCourse?: boolean;
  remainReward: number;
}

export enum COURSE_FOR_USER {
  ALL,
  BOOKMARK,
  OWNER,
  PARTICIPATE,
}
export interface ICourseFilter {
  search: string;
  page: number;
  page_size: number;
  categories: string[];
  type: COURSE_FOR_USER;
}

export interface ICategory extends ICommon {}

export interface IBlockchainNetwork extends ICommon {}

export enum LESSON_ASSIGNMENT_CATEGORY {
  FILE = "FILE",
  TEXT = "TEXT",
  POLL = "POLL",
}

export interface IAssignment {
  category: LESSON_ASSIGNMENT_CATEGORY;
  question: string;
  multiValid: boolean;
  options: [{ _id?: string; value: string; valid: boolean }];
}

export interface ICourseSection extends ICommon {
  activities: IActivity[];
}

export interface ICourseOverView extends ICommon {
  category: string;
  image: string;
}

export interface ICourseParams {
  sections?: ICourseSection[];
  txHash?: string;
}

export type REWARD_KIND = "NFT" | "TOKEN";

export type TIME_TYPE = "CUSTOM_DAY_PLAN" | "START_AND_END_DATE";

export enum ASSIGNMENT_STATUS {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  WAITING = "WAITING",
}

export interface IAssignmentForm {
  content?: string;
  fileUrl?: string;
  pollOptions?: string[];
}

export interface ICourse extends ICommon, ISomeInfo {
  _id?: string;
  title: string;
  description: string;
  category: string;
  image: string;
  coverImage: string;
  startDate: number;
  endDate: number;
  type: COURSE_TYPE;
  range: number;
  sections: ISection[];
  createdBy: IUserInfo;
  isDeleted?: boolean;
  rewards: {
    totalCourseBudget?: number;
    prizeTotal: number;
    rewardPerLearner: number;
    kind: KIND_TYPE;
    nft: {
      contractAddress: string;
      name: string;
      symbol: string;
      network: string;
    };
    token: {
      network: string;
      explorer: string;
      name: string;
      symbol: string;
      contractAddress: string;
    };
  };
  status: COURSE_STATUS;
  txHash: string;
  paymentStatus: PAYMENT_STATUS;
  participationIds: IUserInfo[];
  createdAt: number;
  updatedAt: number;
  estimatedTime: number;
  noExpire: boolean;
  bookmarkUserIds: string[];
  chainId?: number;
}

export interface ICourseGeneralInfo {
  title: string;
  description: string;
  category: string;
  coverImage: string;
  startDate: number | Dayjs | null;
  endDate: number | Dayjs | null;
  type: COURSE_TYPE;
  estimatedTime: number;
  noExpire?: boolean;
  range?: number;
  rewards?: ICourseReward;
  sections: ISection[];
}

export interface ITokenForm {
  blockchainNetwork: string;
  tokenName: string;
  tokenExplorer: string;
  tokenSymbol: string;
  tokenContract: string;
  totalSupply: number;
}

export interface INFTMetadata {
  name: string;
  attributes: {
    trait_type: string;
    value: string;
    display_type?: string;
  }[];
  description: string;
  image: string;
}

export interface INFTForm {
  imageUrl: string;
  NFTImage: string;
  title: string;
  symbol: string;
  description: string;
  tags: string[];
  blockchainNetwork: string;

  attributes: {
    type: string;
    value: string;
    name: string;
  }[];
}

export interface ICourseForm {
  title: string;
  description: string;
  category: string;
  image: string;
  coverImage: string;
  duration: string;
  totalCourseBudget: number;
  prizeTotal: number;
  bonus: number;
  startDate: Dayjs | number | null;
  endDate: Dayjs | number | null;
  range: number | null;
  type: TIME_TYPE;
  rewardPerLearner: 200;
  kind: REWARD_KIND;
  isExistingToken: boolean;
  isExistingNFT: boolean;
  NFTAddress: string;
  blockchainNetwork: string;
  tokenName: string;
  tokenSymbol: string;
  tokenExplorer: string;
  tokenContract: string;
  contractAddress: string;
  totalSupply: number;
  estimatedTime: number;
  nftName: string;
  nftSymbol: string;
}
export enum SortEnum {
  ASD = 1,
  NONE = 0,
  DESC = -1,
}

export interface IParticipationRes {
  _id: string;
  course: ICourse;
  user: {
    username: string;
    fullName: string;
    emailId: string;
    _id: string;
    walletAddress: string;
  };
  startDate: number;
  completedDate: number;
  learnedActivities: IAssignmentRes[];
  canClaimReward: boolean;
  hasClaimedReward: boolean;
  txHash: string;
  activity_count: number;
}

export interface IAssignmentRes {
  _id: string;
  activityId: string;
  activity: IActivity;
  assignments: {
    status: ASSIGNMENT_STATUS;
    content: string;
    fileUrl: string;
    pollOptions: string[];
    reasonRejected: string;
    createdAt: number;
    updatedAt: number;
  };
  createdAt: number;
}

export enum ACTIVITY_TYPE {
  MULTIMEDIA = "MULTIMEDIA",
  EXAM = "EXAM",
  FORM = "FORM",
  CODE_ASSIGNMENT = "CODE_ASSIGNMENT",
  EMBED_VIDEO = "EMBED_VIDEO",
}

export enum ACTIVITY_STATUS {
  LEARNED = "LEARNED",
  BLOCK = "BLOCK",
  READY = "READY",
}

export interface Answer extends IAssignmentForm {
  createdAt?: number;
  updatedAt?: number;
  status?: ASSIGNMENT_STATUS;
}

export interface IActivity {
  _id?: string;
  title: string;
  content?: string;
  assignments?: IAssignment;
  createdAt?: number;
  status?: ACTIVITY_STATUS;
  answer?: Answer;
}

export interface ISection {
  _id?: string;
  title: string;
  description: string;
  activities: IActivity[];
}

export const enum COURSE_TYPE {
  CUSTOM_DAY_PLAN = "CUSTOM_DAY_PLAN",
  START_AND_END_DATE = "START_AND_END_DATE",
}

export const enum KIND_TYPE {
  NFT = "NFT",
  TOKEN = "TOKEN",
}

export const enum COURSE_STATUS {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
}

const enum PAYMENT_STATUS {
  PENDING = "PENDING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}

export interface ICourseBasicInfo {
  title: string;
  description: string;
  category: string;
  coverImage: string;
  startDate: Dayjs | number | null;
  endDate: Dayjs | number | null;
  type: COURSE_TYPE;
  range: number;
  estimatedTime: number;
  noExpire?: boolean;
  txHash?: string;
  chainId?: number;
  status?: COURSE_STATUS;
}

export interface ICourseReward {
  totalCourseBudget?: number;
  prizeTotal: number;
  rewardPerLearner: number;
  kind: KIND_TYPE;
  nft?: {
    contractAddress: string;
    name: string;
    symbol: string;
    network: string;
  };
  token?: {
    network: string;
    explorer: string;
    name: string;
    symbol: string;
    contractAddress: string;
  };
}
