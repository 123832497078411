import BaseModal from "components/base/baseModal";
import { PopupWrapStyle, SpanText } from "./InfoModal.style";
import { AkarIcon } from "assets/images/worktasks-inside";
import PrimaryButton from "components/base/primaryButton";
import { ModalProps } from "antd";

interface IInfoModalProps extends ModalProps {
  title: string;
  description: string;
}
export const InfoModal = ({
  title,
  description,
  ...props
}: IInfoModalProps) => {
  return (
    <BaseModal width={"446px"} {...props}>
      <PopupWrapStyle>
        <div>
          <AkarIcon />
          <div>{title}</div>
          <p>{description}</p>
          <PrimaryButton width="192px" height="48px" onClick={props.onCancel}>
            <SpanText>Got it</SpanText>
          </PrimaryButton>
        </div>
      </PopupWrapStyle>
    </BaseModal>
  );
};
