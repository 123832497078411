import styled from "styled-components";

import BaseButton from "components/base/baseButton";
import { Input } from "antd";

export const BannerContainer = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(230, 240, 255, 0.58) 0%,
    rgba(252, 233, 250, 0.67) 22%,
    rgba(251, 244, 231, 0.71) 32%,
    rgba(241, 240, 249, 0.745) 40%,
    rgba(238, 239, 255, 0.76) 42%,
    rgba(234, 244, 255, 0.8) 52%,
    rgba(231, 246, 254, 0.92) 80%,
    rgba(255, 255, 255, 0.92) 100%
  );
  background-size: cover;
  padding: 0px;
  padding-bottom: 8rem;

  & > div {
    /* max-width: 1170px; */
    margin: auto;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const BannerContent = styled.div`
  max-width: 73.125rem;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 4rem;
  @media (max-width: 1200px) {
    padding: 6rem 20px 0;
  }
`;

export const BannerImageWrapper = styled.div`
  max-width: 28.125rem;
  position: relative;
  .move {
    animation: MoveUpDown 3s linear infinite;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
  }
  .move1 {
    animation: MoveUpDown1 3s linear infinite;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
  .move2 {
    position: absolute;
    left: 8px;
    bottom: 80px;
    z-index: 1;
  }
  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 90px;
    }
    50% {
      bottom: 120px;
    }
  }

  @keyframes MoveUpDown1 {
    0%,
    100% {
      bottom: 110px;
    }
    50% {
      bottom: 140px;
    }
  }
  @keyframes MoveUpDown2 {
    0%,
    100% {
      bottom: 60px;
    }
    50% {
      bottom: 90px;
    }
  }
  @media (max-width: 1200px) {
    /* padding-left: 20px; */
  }
`;

export const BannerText = styled.span<{ color?: string }>`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 115%;
  letter-spacing: -0.02em;
  color: ${(p) => p.color || "#080f6c"};
  @media (max-width: 1200px) {
    font-size: 2.75rem;
  }
`;

export const TextGradient = styled(BannerText)`
  background: linear-gradient(
    91.93deg,
    #a24df5 24.59%,
    #05a4ec 37.51%,
    #00de8c 50.69%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const BannerDescription = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 148%;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  color: #3e4168;
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`;

export const HighLight = styled.span`
  color: #7e00fd;
`;

export const ButtonText = styled.div<{ color?: string }>`
  font-family: "Rubik";
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: ${(p) => p.color || "#fff"};
`;
export const RightContent = styled.div`
  max-width: 627px;
  padding-bottom: 5.8125rem;
  svg {
    margin-right: 0.5rem;
  }
  @media (max-width: 1200px) {
    max-width: 32rem;
  }
`;

export const BannerFilter = styled.div<{
  padding?: string;
  mt?: string;
  mb?: string;
}>`
  height: 4.4375rem;
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(126, 0, 253, 0.08) 0%,
    rgba(193, 67, 252, 0.08) 46.88%,
    rgba(0, 191, 251, 0.08) 100%
  );
  background-size: cover;
  margin-top: ${(p) => p.mt};
  margin-bottom: ${(p) => p.mb};

  .search {
    position: relative;
    width: 16.5625rem;
    height: 2.5rem;
    background: #ffffff;
    border: 0.0625rem solid rgba(8, 10, 37, 0.1);
    border-radius: 0.375rem;
    .ant-input {
      color: #292e73;
      font-family: "Rubik";
      opacity: 0.8;
    }
    input::placeholder {
      color: rgba(41, 46, 115, 0.82);
    }
  }
  padding: ${(p) => p.padding};
`;

export const BannerFilterContent = styled.div`
  max-width: 73.125rem;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1200px) {
    padding: 0 1rem;
  }
  .ant-input-affix-wrapper {
    &:hover {
      border-color: #ae8ece;
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ):before {
        background-color: #ae8ece;
      }
    }
    .ant-radio-button-wrapper {
      padding: 0px 6px;
    }
    padding: 0px 7px 0px 11px !important;
    .ant-radio-button-wrapper:first-child {
      border-left: 1px solid #d9d9d9;
      border-radius: 6px 0 0 6px;
    }
    .ant-radio-button-wrapper:last-child {
      border-radius: 0 6px 6px 0;
    }
  }

  .ant-input-affix-wrapper
    .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #9c8ac129;
    border-color: #9c8ac129;
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 115%;
      color: #7e00fd;
    }
  }
  .ant-radio-button-wrapper:hover {
    color: #7e00fd;
  }
`;

export const ButtonFilter = styled(BaseButton)`
  height: 2.5rem;
  width: 7.1875rem;
  border-radius: 0.375rem;
  border: 0.0625rem solid rgba(8, 10, 37, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Rubik";
  span {
    opacity: 0.8;
  }
  svg {
    color: black;
  }
`;

export const InputStyled = styled(Input)``;
