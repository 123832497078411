import { Pagination } from "antd";
import styled from "styled-components";

export const StyledPagination = styled(Pagination)`
  .ant-pagination-item a {
    color: rgba(41, 46, 115, 0.82);
  }

  .ant-pagination-item-active {
    background: #7e00fd;
    border-color: #7e00fd;

    a {
      color: #fff;
    }
  }

  .ant-pagination-item:hover {
    border-color: #7e00fd;

    a {
      color: #7e00fd;
    }
  }

  .ant-pagination-item-active:hover {
    background: rgba(126, 0, 253, 0.5);
  }

  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: #7e00fd;
    border-color: #7e00fd;
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: rgba(41, 46, 115, 0.42);
    border-color: rgba(156, 138, 193, 0.16);
  }
`;
