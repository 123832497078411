import { FC } from "react";
import {
  IntroductionContainer,
  IntroductionContent,
  ButtonWrapper,
  SectionTitle,
  SectionDescription,
  SectionHeader,
} from "./introduction.style";
import { IIntroductionProps } from "./introduction.props";
import PrimaryOutlineBtn from "components/base/primaryOutlineBtn";
import { DiscordIcon } from "assets/icons/common/discord.icon";

const Introduction: FC<IIntroductionProps> = ({
  title,
  description,
  img,
  btn,
  bg,
  redirectBtn,
  mgr,
}) => {
  return (
    <IntroductionContainer bg={bg}>
      <IntroductionContent mgr={mgr}>
        <SectionHeader>
          <SectionTitle>{title}</SectionTitle>
          <SectionDescription>{description}</SectionDescription>
          {btn ? (
            <ButtonWrapper>
              <PrimaryOutlineBtn
                height="56px"
                width="max-content"
                onClick={() => window.open(redirectBtn, "_blank")}
              >
                <DiscordIcon />
                {btn}
              </PrimaryOutlineBtn>
            </ButtonWrapper>
          ) : null}
        </SectionHeader>
        <img src={img} alt="intro-img" />
      </IntroductionContent>
    </IntroductionContainer>
  );
};

export default Introduction;
