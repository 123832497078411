import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Logo } from "assets/images/logo";
import { useAppSelector } from "redux/hooks";
import { Container } from "./PageLoading.style";

const antIcon = (
  <LoadingOutlined className="loading" style={{ fontSize: 55 }} spin />
);

export const PageLoading = () => {
  const loadingState = useAppSelector((state) => state.loading.pageLoading);
  return loadingState ? (
    <Container>
      <div>
        <Logo />
        <Spin indicator={antIcon} />
      </div>
    </Container>
  ) : null;
};
