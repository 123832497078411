import { FC, memo, useEffect } from "react";
import { IDropBoxLogoutProps } from "./dropBoxLogout.props";
import {
  ConnectWalletBlock,
  ConnectWalletButton,
  DropBoxText,
  LogoutText,
  AccountText,
  Avatar,
  ProfileName,
  InfoWrapper,
} from "./dropBoxLogout.style";
import { DropBoxLogoutIcon } from "assets/icons/dropboxLogoutIcon";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  removeCourseData,
  setAuthData,
  setConnectWalletOpen,
  setWalletName,
} from "redux/slices";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Menu, message } from "antd";
import type { MenuProps } from "antd";
import { useEthers } from "@usedapp/core";
import { AVATAR_FALLBACK_URL, ROUTER_CONSTANTS } from "utils/constant";
import { handleLongText } from "utils/text";
import { DownIcon } from "assets/icons/down.icon";
import { useMutation } from "@tanstack/react-query";
import { logoutAPI } from "api/auth";

const DropBoxLogout: FC<IDropBoxLogoutProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { account, deactivate } = useEthers();
  const { user } = useAppSelector((state) => {
    return state.auth;
  });

  // watch changes from account UseEthers
  useEffect(() => {
    if (!user.walletAddress) return;
    if (!account) return;
    if (user.walletAddress !== account) deactivate();
  }, [account, deactivate, user.walletAddress]);

  const { mutateAsync: logoutMutate } = useMutation(logoutAPI);

  const handleLogout = () => {
    logoutMutate()
      .then(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        dispatch(setAuthData({ user: null, token: null }));
        deactivate();
        localStorage.removeItem("walletConnector");
        dispatch(setWalletName(""));
        dispatch(removeCourseData());
        message.info("Logout success");
        navigate("/");
      })
      .catch((error) => message.error(error.message));
  };

  const handleClickConnect = () => {
    dispatch(setConnectWalletOpen(true));
  };

  const buttonLinks = [
    {
      to: ROUTER_CONSTANTS.USER.PROFILE_PREVIEW,
      label: "My Profile",
    },
    {
      to: ROUTER_CONSTANTS.DASHBOARD,
      label: "My Dashboard",
    },
    {
      to: ROUTER_CONSTANTS.USER.PAYMENT_DASHBOARD_INITIATOR,
      label: "Initiator Dashboard",
    },
    {
      to: ROUTER_CONSTANTS.USER.PAYMENT_DASHBOARD,
      label: "Collaborator Dashboard",
    },
    {
      to: ROUTER_CONSTANTS.USER.PAYMENT_DASHBOARD_OBSERVER,
      label: "Observer Dashboard",
    },
    {
      to: ROUTER_CONSTANTS.COURSE.LIST_COURSE_FOR_LEARNER,
      label: "Learning Dashboard",
    },
    {
      to: ROUTER_CONSTANTS.COURSE.LIST_ASSIGNMENT_FOR_CREATOR,
      label: "Manage Courses",
    },
    {
      to: ROUTER_CONSTANTS.USER.SUPPORT_TICKET,
      label: "Support Ticket",
    },
  ];

  const dropdownItems: MenuProps["items"] = [
    {
      key: "wallet",
      label: (
        <ConnectWalletBlock>
          {!!account ? (
            <>
              <AccountText>
                {account?.slice(0, 10)}...{account?.slice(32, 42)}
              </AccountText>
            </>
          ) : (
            <>
              <ConnectWalletButton
                height="32px"
                width="148px"
                onClick={handleClickConnect}
              >
                <DropBoxLogoutIcon />
                Connect wallet
              </ConnectWalletButton>
            </>
          )}
        </ConnectWalletBlock>
      ),
    },
    ...buttonLinks.map((item) => ({
      key: item.to,
      label: (
        <Link to={item.to}>
          <DropBoxText>{item.label}</DropBoxText>
        </Link>
      ),
    })),
    {
      key: "logout",
      label: <LogoutText onClick={handleLogout}>Log Out</LogoutText>,
    },
  ];

  const menu = <Menu items={dropdownItems} />;

  return (
    <Dropdown placement="bottomRight" overlay={menu}>
      <InfoWrapper onClick={(e) => e.preventDefault()}>
        <Avatar
          src={user?.profilePicture}
          alt="avatar"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = AVATAR_FALLBACK_URL;
          }}
        />
        <ProfileName>
          {handleLongText(
            user?.username || user?.emailId.split("@").shift(),
            30
          )}
        </ProfileName>
        <DownIcon fill="white" />
      </InfoWrapper>
    </Dropdown>
  );
};

export default memo(DropBoxLogout);
