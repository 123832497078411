export const BoardCheckIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8301_15467)">
      <circle cx="8" cy="8" r="7.5" fill="white" stroke="#7E00FD" />
      <path
        d="M11.2727 6L6.27273 11L4 8.72727"
        stroke="#7E00FD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8301_15467">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
