import axios from "api/axios";
import { END_POINT } from "./endpoints";
import { IGetBlogListReq } from "./types";

export const getBlogByIdAPI = async (blogId: string) => {
  return await axios.get(`${END_POINT.BLOGS}/${blogId}`);
};

export const getBlogListAPI = async (params: IGetBlogListReq) => {
  return await axios.get(
    `${END_POINT.BLOGS}?search=${params.search}&page=${params.page}&pageSize=${
      params.pageSize
    }&categories=${JSON.stringify(params.categories)}&sort=${
      params.sort
    }&pageSizeAtFirstPage=${params.pageSizeAtFirstPage}`
  );
};
