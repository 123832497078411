import { Radio } from "antd";
import { FilterIcon } from "assets/icons/filter.icon";
import {
  BannerFilter,
  BannerFilterContent,
  ButtonFilter,
  InputStyled,
} from "pages/HomePage/components/Banner/banner.style";
import { IFilterGroupProps } from "pages/HomePage/homepage.props";
import { FC, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  initialCourseState,
  initialFilterState,
  setCourseFilter,
  setFilter,
} from "redux/slices";
import { debounce } from "utils/debounce";
import Filter from "../filter";

export const FilterGroup: FC<IFilterGroupProps> = ({
  padding,
  mt,
  mb,
  hiddenFilter,
}) => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { filterType } = useAppSelector((state) => state.filter);
  const paramsCourse = useAppSelector((state) => state.filterCourse);
  const location = useLocation();
  const isInProfile = location.pathname.includes("profile");
  const debounceSeachProj = useMemo(
    () =>
      debounce((val: string) => {
        if (filterType)
          dispatch(
            setFilter({
              search: val.trim(),
              seedSkip: 0,
              openBountiesSkip: 0,
              learnAndEarnSkip: 0,
              portfolioSkip: 0,
            })
          );
        else {
          dispatch(
            setCourseFilter({
              ...paramsCourse,
              search: val.trim(),
            })
          );
        }
      }, 400),
    [dispatch, filterType, paramsCourse]
  );

  useEffect(() => {
    if (isInProfile) {
      dispatch(
        setFilter({
          ...initialFilterState,
          filterType: true,
        })
      );
      dispatch(setCourseFilter(initialCourseState));
    }
  }, [dispatch, isInProfile]);

  return (
    <BannerFilter padding={padding} mt={mt} mb={mb}>
      <BannerFilterContent>
        <InputStyled
          className="search"
          type="search"
          placeholder="Search"
          suffix={
            !isInProfile ? (
              <Radio.Group
                onChange={(e) => {
                  dispatch(
                    setFilter({
                      ...initialFilterState,
                      filterType: e.target.value,
                    })
                  );
                  dispatch(setCourseFilter(initialCourseState));
                }}
                value={filterType}
                buttonStyle="solid"
              >
                <Radio.Button value={true}>Project</Radio.Button>
                <Radio.Button value={false}>Course</Radio.Button>
              </Radio.Group>
            ) : undefined
          }
          onChange={(e) => debounceSeachProj(e.target.value)}
        />

        {!hiddenFilter && (
          <ButtonFilter onClick={() => setOpenFilter(true)}>
            <FilterIcon />
            Filter
          </ButtonFilter>
        )}
      </BannerFilterContent>
      <Filter open={openFilter} onClose={() => setOpenFilter(false)} />
    </BannerFilter>
  );
};
