import styled from "styled-components";

export const Container = styled.div`
  max-height: 400px;
  width: 300px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid rgba(41, 46, 115, 0.12);
  padding-bottom: 20px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: rgba(8, 15, 108, 0.05);
    height: 24.125rem;
  }
  &::-webkit-scrollbar {
    width: 0.4375rem;
    background-color: rgba(8, 15, 108, 0.08);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(8, 15, 108, 0.08);
    height: 2.5rem;
  }
`;

export const DropDownItem = styled.div``;

export const Content = styled.div`
  padding: 7px 12px;
  cursor: pointer;

  > * {
    word-break: break-word;
  }
  span {
    text-decoration: none;
    color: #080f6c;
    font: 400 14px "Rubik" !important;

    span {
      margin-left: 4px;
      color: rgba(41, 46, 115, 0.82);
      opacity: 0.8;
    }
  }

  :hover {
    background: #e9ecef;
  }
`;

export const HorizontalLine = styled.div`
  margin: 0px 10px;
  border-bottom: 1px solid #e2e2e2;
`;

export const NoNotification = styled.p`
  margin-top: 20px;
  color: rgba(41, 46, 115, 0.82);
  text-align: center;
  font-weight: 500;
`;
