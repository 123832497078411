import { COURSE_STEPS } from "utils/course";
import { Divider, FormInstance, Modal, message } from "antd";
import {
  BtnContainer,
  CurrentStep,
  DraftBtn,
  Header,
  RightAction,
  StepHeader,
  StepText,
} from "../createCourse.style";
import OutlinedButton from "components/base/outlinedButton";
import { BackIcon, NextIcon } from "assets/icons/navArrow.icon";
import { previousStep, removeCourseData } from "redux/slices";
import { useDispatch } from "react-redux";
import PrimaryButton from "components/base/primaryButton";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { createCourseAPI, updateCourseAPI } from "api/course/request";
import { useAppSelector } from "redux/hooks";
import {
  COURSE_STATUS,
  COURSE_TYPE,
  ICourseReward,
  KIND_TYPE,
} from "api/course";
import dayjs from "dayjs";
import { convertTimeTo } from "utils/time";
import { ROUTER_CONSTANTS } from "utils/constant";
import { useMemo } from "react";

interface IHeadingCourseProps {
  step: number;
  loading?: boolean;
  action?: any;
  form?: FormInstance;
}

export const HeadingCourse = ({
  step,
  loading = false,
  action,
  form,
}: IHeadingCourseProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCancel = () => {
    Modal.confirm({
      title: "Do you wanna leave this page?",
      icon: <ExclamationCircleOutlined />,
      content: "Changes you made may not be saved.",
      okText: "Leave",
      cancelText: "Stay",
      onOk: () => {
        dispatch(removeCourseData());
        navigate("/");
      },
    });
  };
  const { course } = useAppSelector((state) => state.course);
  const { info, sections } = course;
  const params = useParams();

  const { mutateAsync: createCourseMutate, isLoading: isLoadingCreate } =
    useMutation(createCourseAPI);
  const { mutateAsync: updateCourseMutate, isLoading: isLoadingUpdate } =
    useMutation(updateCourseAPI);

  const infoConverted: any = useMemo(() => {
    if (info)
      return {
        ...info,
        startDate:
          info.type === COURSE_TYPE.CUSTOM_DAY_PLAN
            ? null
            : dayjs(info.startDate).unix(),
        endDate:
          info.type === COURSE_TYPE.CUSTOM_DAY_PLAN || info.noExpire
            ? null
            : dayjs(info.endDate).unix(),
      };
    else return {};
  }, [info]);

  const handleUpdateOrCreateDraftForStep3 = () => {
    form!.validateFields().then((values: any) => {
      const {
        blockchainNetwork,
        kind,
        prizeTotal,
        rewardPerLearner,
        tokenContract,
        tokenExplorer,
        tokenName,
        tokenSymbol,
        contractAddress,
        nftName,
        nftSymbol,
      } = values;

      let newRewards: ICourseReward = {
        prizeTotal,
        rewardPerLearner,
        kind,
      };

      if (kind === KIND_TYPE.TOKEN) {
        newRewards.token = {
          network: blockchainNetwork,
          explorer: tokenExplorer,
          name: tokenName,
          symbol: tokenSymbol,
          contractAddress: tokenContract,
        };
      } else {
        newRewards.nft = {
          network: blockchainNetwork,
          contractAddress: contractAddress,
          name: nftName,
          symbol: nftSymbol,
        };
      }
      if (!params.id) {
        createCourseMutate({
          ...infoConverted,
          sections,
          rewards: newRewards,
        }).then(() => {
          navigate(ROUTER_CONSTANTS.HOME);
          message.success("Course was saved as draft successfully!");
        });
      } else {
        updateCourseMutate({
          courseId: params.id,
          rewards: newRewards,
          info: infoConverted,
          sections: sections,
        }).then(() => {
          navigate(ROUTER_CONSTANTS.HOME);
          message.success("Course was saved as draft successfully!");
        });
      }
    });
  };

  const handleUpdateOrCreateDraftForStep2 = () => {
    if (!params.id) {
      createCourseMutate({ ...infoConverted, sections }).then(() => {
        navigate(ROUTER_CONSTANTS.HOME);
        message.success("Course was saved as draft successfully!");
      });
    } else {
      updateCourseMutate({
        courseId: params.id,
        info: infoConverted,
        sections,
      }).then(() => {
        navigate(ROUTER_CONSTANTS.HOME);
        message.success("Course was saved as draft successfully!");
      });
    }
  };

  const handleUpdateOrCreateDraftForStep1 = () => {
    form!
      .validateFields()
      .then((values: any) => {
        const {
          title,
          description,
          category,
          coverImage,
          type,
          rangeUnit,
          estUnit,
          startDate,
          endDate,
          range,
          estimatedTime,
          noExpire,
        } = values;
        const startDate_ =
          type === COURSE_TYPE.CUSTOM_DAY_PLAN
            ? null
            : dayjs(startDate.utc()).startOf("date").unix();
        const endDate_ =
          type === COURSE_TYPE.CUSTOM_DAY_PLAN || noExpire
            ? null
            : dayjs(endDate.utc()).endOf("date").unix();
        const range_ =
          type === COURSE_TYPE.CUSTOM_DAY_PLAN
            ? convertTimeTo({
                value: range,
                convertFromDBToForm: false,
                type: rangeUnit,
              }).value
            : 0;
        const estimatedTime_ = convertTimeTo({
          value: estimatedTime,
          convertFromDBToForm: false,
          type: estUnit,
        }).value;
        const details = {
          title,
          description,
          category,
          coverImage,
          type,
          noExpire,
          startDate: startDate_,
          endDate: endDate_,
          estimatedTime: estimatedTime_,
          range: range_,
        };
        if (!params.id)
          createCourseMutate({
            ...details,
            sections: [],
          }).then(() => {
            navigate(ROUTER_CONSTANTS.HOME);
            message.success("Course was saved as draft successfully!");
          });
        else {
          updateCourseMutate({
            info: details,
            courseId: params.id,
            sections: sections,
          }).then(() => {
            navigate(ROUTER_CONSTANTS.HOME);
            message.success("Course was saved as draft successfully!");
          });
        }
      })
      .catch((err) => {});
  };
  return (
    <Header>
      <StepHeader>
        {COURSE_STEPS[step].title}
        <StepText>
          <CurrentStep> Step {step + 1}</CurrentStep> of {COURSE_STEPS.length}
        </StepText>
      </StepHeader>
      <BtnContainer>
        <OutlinedButton onClick={handleCancel}>Cancel</OutlinedButton>
        <Divider type="vertical" />
        <RightAction>
          <OutlinedButton
            icon={<BackIcon />}
            onClick={() => dispatch(previousStep())}
            disabled={step === 0}
          >
            Back
          </OutlinedButton>
          {(!info?.status || info.status === COURSE_STATUS.DRAFT) &&
            step < 3 && (
              <DraftBtn
                onClick={() => {
                  switch (step) {
                    case 0:
                      return handleUpdateOrCreateDraftForStep1();
                    case 1:
                      return handleUpdateOrCreateDraftForStep2();
                    case 2:
                      return handleUpdateOrCreateDraftForStep3();
                    default:
                      break;
                  }
                }}
                disabled={loading}
                loading={isLoadingCreate || isLoadingUpdate}
              >
                Save as draft
              </DraftBtn>
            )}
          <PrimaryButton
            icon={<NextIcon />}
            onClick={action ? action : () => form!.submit()}
            loading={loading}
          >
            {step === COURSE_STEPS.length - 1 ? "Submit" : "Next"}
          </PrimaryButton>
        </RightAction>
      </BtnContainer>
    </Header>
  );
};
