export const PurpleEarth = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 2C6.7103 2 2 6.7103 2 12.5C2 18.2897 6.7103 23 12.5 23C18.2897 23 23 18.2897 23 12.5C23 6.7103 18.2897 2 12.5 2ZM20.8275 11.45H17.9253C17.7958 9.15181 17.1529 6.91186 16.0437 4.89485C17.3292 5.4955 18.4418 6.41147 19.2782 7.55756C20.1146 8.70365 20.6476 10.0426 20.8275 11.45ZM13.0565 4.12835C14.1432 5.56055 15.6048 8.09735 15.8148 11.45H9.3815C9.52745 8.7242 10.4252 6.1706 11.955 4.1273C12.1356 4.1168 12.3162 4.1 12.5 4.1C12.6879 4.1 12.8717 4.1168 13.0565 4.12835ZM9.0224 4.86335C7.9892 6.8489 7.3928 9.1001 7.2815 11.45H4.17245C4.35386 10.0302 4.89446 8.68021 5.74314 7.52764C6.59181 6.37507 7.72048 5.45806 9.0224 4.86335ZM4.17245 13.55H7.29515C7.43795 16.0479 7.9934 18.2519 8.92895 20.0915C7.65025 19.4887 6.54411 18.5734 5.71276 17.43C4.8814 16.2867 4.35165 14.9522 4.17245 13.55ZM11.9225 20.8716C10.4514 19.0887 9.5831 16.5908 9.39305 13.55H15.8117C15.5933 16.4616 14.6388 19.0058 13.0785 20.8706C12.8874 20.8832 12.6963 20.9 12.5 20.9C12.3047 20.9 12.1146 20.8832 11.9225 20.8716ZM16.134 20.061C17.1368 18.1773 17.749 15.965 17.9096 13.55H20.8265C20.6493 14.9405 20.1272 16.2646 19.3076 17.4018C18.488 18.539 17.3971 19.4531 16.134 20.061Z"
        fill="#7E00FD"
      />
    </svg>
  );
};
