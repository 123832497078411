import { ActiveIcon } from "assets/images/connect-wallet/connectWallet";
import { FC, memo, useMemo } from "react";
import { WalletSectionProps } from "./modalConnectWallet.props";
import {
  ContainerPanel,
  ContainerLeftPanel,
  WalletAvatar,
  WalletName,
  ActiveWallet,
} from "./modalConnectWallet.style";

const WalletSection: FC<WalletSectionProps> = ({
  walletIcon,
  connectorName,
  selectedWallet,
  handleSelectWallet,
}) => {
  const nameCapitalized = useMemo(
    () => connectorName.charAt(0).toUpperCase() + connectorName.slice(1),
    [connectorName]
  );

  return (
    <ContainerPanel onClick={() => handleSelectWallet(connectorName)}>
      <ContainerLeftPanel>
        <WalletAvatar>{walletIcon}</WalletAvatar>
        <WalletName>{nameCapitalized}</WalletName>
      </ContainerLeftPanel>
      {selectedWallet === connectorName && (
        <ActiveWallet>
          <ActiveIcon />
        </ActiveWallet>
      )}
    </ContainerPanel>
  );
};

export default memo(WalletSection);
