import { useCallback } from "react";
import { isAddress } from "ethers/lib/utils";
import { getERC20NameAndSymbol } from "web3/contract";
import type { FormInstance } from "antd";
import type { ChangeEvent } from "react";
import { TOKEN_NAME_NOT_FOUND, TOKEN_SYMBOL_NOT_FOUND } from "utils/constant";
import { useCheckWalletAndSwitchNetwork } from "./useCheckWalletAndSwitchNetwork";

export const useHandleTokenContractChanged = (form: FormInstance<any>) => {
  const { checkWallet } = useCheckWalletAndSwitchNetwork();
  const handleTokenContractChanged = useCallback(
    async (eventOrCurTokenContract: ChangeEvent<HTMLInputElement> | string) => {
      const curVal =
        typeof eventOrCurTokenContract === "string"
          ? eventOrCurTokenContract
          : eventOrCurTokenContract.target.value;

      if (isAddress(curVal)) {
        try {
          const { name, symbol } = await getERC20NameAndSymbol(curVal);

          if (name) {
            form.setFields([
              {
                name: "tokenName",
                value: name,
                errors: [],
              },
              {
                name: "tokenSymbol",
                value: symbol,
                errors: [],
              },
            ]);
          } else {
            form.setFields([
              {
                name: "tokenName",
                value: TOKEN_NAME_NOT_FOUND,
                errors: [TOKEN_NAME_NOT_FOUND],
              },
              {
                name: "tokenSymbol",
                value: TOKEN_SYMBOL_NOT_FOUND,
                errors: [TOKEN_SYMBOL_NOT_FOUND],
              },
            ]);
          }
        } catch (error: any) {
          if (error?.message?.includes("account")) {
            checkWallet();
            return;
          }

          form.setFields([
            {
              name: "tokenName",
              value: TOKEN_NAME_NOT_FOUND,
              errors: [TOKEN_NAME_NOT_FOUND],
            },
            {
              name: "tokenSymbol",
              value: TOKEN_SYMBOL_NOT_FOUND,
              errors: [TOKEN_SYMBOL_NOT_FOUND],
            },
          ]);
        }
      }
    },
    [form, checkWallet]
  );

  return handleTokenContractChanged;
};
