import { FC, useState } from "react";
import { IWorktaskProps } from "./Worktask.props";
import { StarIcon, GiftIcon } from "assets/images/worktasks";
import PrimaryButton from "components/base/primaryButton";
import {
  Container,
  Right,
  Left,
  Title,
  Subtitle,
  InforWrap,
  Flex,
  Property,
  Budget,
  Prifix,
  Devider,
  Icon,
  Money,
  WrapCategory,
  WrapTag,
  Value,
  WrapTimeRemaining,
  TitleModal,
  Applied,
  SponsorText,
  WrapperFlex,
  SponsorWrapper,
  WorktaskDesc,
  CollabWrapper,
  BtnActionsWrapper,
  RequestNumber,
  DeliverableWrap,
  NumberInforWrap,
  NumberItem,
  PaymentTitle,
  PaymentValue,
} from "./Worktask.style";
import {
  BlockchainNetworks,
  ROUTER_CONSTANTS,
  SKILL_LEVEL,
} from "utils/constant";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { SecondaryButton } from "pages/ProfileUser/components/Experiences/experiences.style";
import FlexBox from "components/base/Flexbox";
import {
  cancelPackageAPI,
  finishPackageAPI,
  getPackageBonusScoresAPI,
  getPackageCollaboratorsAPI,
  getProfileInfoAPI,
  IFeedback,
  IUserInfo,
  PaidCollaboratorEvent,
  PaidObserverEvent,
  updateFinishStatusAPI,
} from "api/users";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { message, Avatar as AntdAvatar, Popconfirm, Avatar } from "antd";
import CommonModal from "components/base/commonModal";
import { timeRemaining } from "utils/time";
import { setPageLoading, setUserInfo } from "redux/slices";
import {
  cancelWorktaskSC,
  finishPackageSC,
  handleMessageErrorSC,
} from "web3/contract";
import { TooltipAtnd } from "pages/WorktasksInside/WorktasksInside.style";
import AvatarAntdGroup from "components/modules/avatarList";
import Button from "components/base/button";
import { PendingIcon } from "assets/images/worktasks-inside";
import {
  formatTimeCommitment,
  getPluralText,
} from "utils/formatTimeCommitment";
import { formatUnits } from "ethers/lib/utils";
import { useCheckWalletAndSwitchNetwork } from "hooks";

const Worktask: FC<IWorktaskProps> = ({
  worktaskOtherInfo,
  data: worktaskData,
  totalBudget,
  category,
  projectId,
  hideButton,
  isDashboard,
  hideNumber,
  projectDecimal,
  projectExpectedUsd,
  blockchainNetwork,
}) => {
  const param = useParams();
  const userInfo: IUserInfo = useAppSelector((state) => state.auth.user);
  const userId: string = userInfo?._id; // useAppSelector((state) => state.auth.user?._id);
  const [openCancelPopConfirm, setOpenCancelPopConfirm] = useState(false);
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  function projectTypeBackground(value: string) {
    if (value === "Individual") return { background: "#00DE8C", color: "#000" };
    if (value === "Collaborative")
      return { background: "#00DE8C", color: "##080F6C" };
    return { background: "#E94600", color: "#FFF" };
  }

  function expertiseLevelBackground(value: string) {
    if (value === SKILL_LEVEL.BEGINNER)
      return { background: "rgba(41, 46, 115, 0.42)", color: "#FFF" };
    if (value === SKILL_LEVEL.INTERMEDIATE)
      return { background: "rgba(41, 46, 115, 0.82)", color: "#FFF" };
    if (value === SKILL_LEVEL.PROFESSIONAL)
      return { background: "#00ADFB", color: "#FFF" };
    if (value === SKILL_LEVEL.EXPERT)
      return { background: "#FCC300", color: "#000" };
    return { background: "rgba(41, 46, 115, 0.42)", color: "#FFF" };
  }

  const getWorktaskTypeStyle = (category?: string) => {
    switch (category) {
      case "Seed":
        return {
          color: "#FFF",
          background: "#49B67A",
        };

      case "Open Bounties":
        return {
          color: "#fff",
          background: "#3B6CB4",
        };

      default:
        return {
          color: "#fff",
          background: "rgba(156, 138, 193, 0.16)",
        };
    }
  };

  const isProjectCreator = worktaskOtherInfo?.initiatorDetails?._id === userId;
  const queryClient = useQueryClient();

  useQuery(["getProfile"], () => getProfileInfoAPI(), {
    onSuccess: async (res) => {
      if (res.responseCode === 200) {
        dispatch(setUserInfo(res.responseData));
      }
    },
    enabled: userId === undefined,
    refetchOnWindowFocus: false,
  });

  const [openFeedback, setOpenFeedback] = useState(false);
  const [listFeedback, setListFeedback] = useState([] as IFeedback[]);
  const [popupIndex, setPopupIndex] = useState(0);

  const toggleSetOpenFeedback = async () => {
    setOpenFeedback(!openFeedback);
    if (!openFeedback) {
      const feedbackResp = await getPackageCollaborators({
        projectId: projectId || "",
        packageId: worktaskData._id || "",
        collabId: "",
      });
      setListFeedback(feedbackResp.responseData);
    }
  };

  const handleOpenDeliverPopup = () => {
    const isCollab = worktaskData?.collaborators?.some((val) => val === userId);

    if (isCollab) {
      setPopupIndex(1);
    } else {
      message.error("You're not collaborator of this worktask!");
    }
  };

  const isDelivered =
    worktaskData?.workStatus === "DELIVERED" ||
    worktaskData?.workStatus === "SUBMITTED";

  const { mutateAsync: getPackageCollaborators } = useMutation(
    getPackageCollaboratorsAPI
  );

  const dispatch = useAppDispatch();
  const isAbleToCancel =
    worktaskData?.workStatus === "OPEN" ||
    worktaskData?.workStatus === "DELIVERED" ||
    worktaskData?.workStatus === "INPROGRESS";
  const { mutate: cancelPackage } = useMutation(cancelPackageAPI);

  const handleCancelPackage = async () => {
    if (!checkWallet()) return;

    try {
      setOpenCancelPopConfirm(false);
      dispatch(setPageLoading(true));

      const projectChainId = BlockchainNetworks[blockchainNetwork?.name!];

      await handleSwitchNetwork(projectChainId);

      const scParams = {
        projectId: worktaskOtherInfo?.scProjectId || "",
        packageId: worktaskData.scPackageId || "",
        collaborators:
          worktaskData?.collaboratorsDetails.map((c: any) => c.walletAddress) ||
          [],
        observers: worktaskData?.observerWalletAddress || [],
        collabStartedWork: worktaskData?.collaboratorsDetails
          ? worktaskData?.collaboratorsDetails!.map((clb: any) =>
              worktaskData?.workProgress!.some((wp) => wp.user === clb._id)
            )
          : [],
      };

      const { tx, events } = await cancelWorktaskSC(scParams);
      const paidCollaboratorEvents: PaidCollaboratorEvent[] =
        events.PaidCollaboratorRewards.map((args: any) => {
          return {
            walletAddress: args[2],
            mgp: +formatUnits(args[3], projectDecimal),
          };
        });
      const paidObserverEvents: PaidObserverEvent[] =
        events.PaidObserverFee.map((args: any) => {
          return {
            walletAddress: args[2],
            amount: +formatUnits(args[3], projectDecimal),
          };
        });

      cancelPackage(
        {
          projectId: projectId || "",
          packageId: worktaskData._id || "",
          txHash: tx.hash,
          paidCollaboratorEvents,
          paidObserverEvents,
        },
        {
          onSuccess: async (res: any) => {
            const { responseCode, responseMessage } = res;
            if (responseCode === 200) {
              await queryClient.invalidateQueries([
                `getAllPackagesByProjectId-${
                  param?.id || (projectId as string)
                }`,
              ]);
              await queryClient.invalidateQueries(["getPackageCollaborators"]);
              await queryClient.invalidateQueries(["getProjectDetails"]);
              dispatch(setPageLoading(false));
              message.success(responseMessage);
            } else {
              dispatch(setPageLoading(false));
              message.error(responseMessage);
            }
          },
          onError: () => {
            dispatch(setPageLoading(false));
          },
        }
      );
    } catch (err: any) {
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const isCollaboratorDeliver = worktaskData?.workProgress?.find(
    (deliver: any) => deliver.user === userId
  );
  const isCreatorDelivers = (worktaskData?.workProgress?.length || 0) > 0;
  const isAlreadyCollaborator = worktaskData?.collaborators?.some(
    (collaborator: any) => collaborator === userId
  );
  const isAlreadyRequest = worktaskData?.requests?.some(
    (request: any) => request.userId === userId
  );
  const isObserver = worktaskData?.observersIds?.includes(userId);
  const isFinish = worktaskData?.workStatus === "COMPLETED";
  const isSponsor = worktaskOtherInfo?.organizationDetails?.some(
    (org) => org._id === userInfo?.organization
  );
  const newRequest = worktaskData?.requests?.filter(
    (item) => item?.status === "ACTIVE"
  );

  const isCollaboratorReviewed = worktaskData?.collaboratorReviews?.find(
    (review) => review.user === userId
  );
  const isAlreadyRequestAndApprove = worktaskData?.requests?.some(
    (item) => item?.userId === userId && item?.status === "INACTIVE"
  );
  const isPendingOrBeingRemoved =
    !isAlreadyCollaborator && isAlreadyRequest && !isObserver && !hideButton;
  const isPending = isPendingOrBeingRemoved && !isAlreadyRequestAndApprove;
  const isBeingRemoved = isPendingOrBeingRemoved && isAlreadyRequestAndApprove;

  function getStatus() {
    if (
      !isAlreadyCollaborator &&
      !isAlreadyRequest &&
      !isObserver &&
      !isFinish &&
      !isSponsor &&
      (worktaskData?.workStatus === "OPEN" ||
        worktaskData?.workStatus === "INPROGRESS") &&
      !hideButton
    )
      return (
        <Link
          to={ROUTER_CONSTANTS.PROJECT.EXPRESS_INTEREST.replace(
            ":projectId",
            param?.id || (projectId as string)
          )
            .replace(":id", userId)
            .replace(":packageId", worktaskData?._id || "")}
        >
          <PrimaryButton>Express Interest</PrimaryButton>
        </Link>
      );
    if (isPending)
      return (
        <Button
          width="152px"
          height="36px"
          color="#080F6C"
          background="#FFCE07"
        >
          <PendingIcon />
          Pending Review
        </Button>
      );
    if (isBeingRemoved) {
      return <div>You've been removed</div>;
    }
    if (
      worktaskData?.startDate <= new Date() &&
      worktaskData?.workStatus !== "DELIVERED" &&
      isAlreadyCollaborator &&
      !isFinish &&
      !isObserver &&
      !hideButton
    )
      return <Applied>Applied</Applied>;
  }

  const filteredOrgs = worktaskOtherInfo?.organizationDetails;

  const isStranger = !(
    isProjectCreator ||
    isObserver ||
    isAlreadyCollaborator ||
    isSponsor
  );
  const goToDeliverWorkUrl = `${ROUTER_CONSTANTS.PROJECT.DELIVER_WORK.replace(
    ":projectId",
    projectId || ""
  ).replace(":packageId", worktaskData?._id || "")}?type=${
    isStranger
      ? "stranger"
      : isProjectCreator || isObserver
      ? "initiator"
      : "collaborator"
  }
  `;

  const { mutateAsync: getPackageBonusScores } = useMutation(
    getPackageBonusScoresAPI
  );
  const { mutate: updateFinishStatus } = useMutation(updateFinishStatusAPI);
  const { mutate: finishPackage } = useMutation(finishPackageAPI);

  const collaboratorsLength = worktaskData?.collaborators?.length ?? 0;
  const removeDuplicateCollaboratorRating =
    worktaskData?.collaboratorRatings?.filter(
      (v: any, i: any, a: any) =>
        a.findIndex(
          (v2: any) => v2.fromUser === v.fromUser && v2.user === v.user
        ) === i
    );
  const isCreatorApproveAllCollabs =
    worktaskData?.workProgress?.length === collaboratorsLength &&
    worktaskData?.deliverables?.every(
      (item) => item?.deliveryStatus === "COMPLETED"
    );
  const isShowFinishPackage =
    worktaskData?.workStatus === "SUBMITTED" &&
    worktaskData?.finishStatus === "OPEN" &&
    isProjectCreator;
  const isCollaboratorReviewFinish =
    collaboratorsLength === worktaskData?.collaboratorReviews?.length;
  const isCollaboratorRatingFinish =
    collaboratorsLength * (collaboratorsLength - 1) ===
    removeDuplicateCollaboratorRating?.length;
  const isCanSubmitDeliverables =
    worktaskData?.progressHistory?.inProgressUsers.includes(userId);
  const isCompletedWorktask = worktaskData?.workStatus === "COMPLETED";

  const handleFinishWorktask = async () => {
    if (!isCollaboratorReviewFinish) {
      message.error(
        "Please review each collaborators before completing the Work Task"
      );
      return;
    }

    if (!isCollaboratorRatingFinish) {
      message.error(
        "All collaborators need to rate each other before completing the Work Task"
      );
      return;
    }

    if (!checkWallet()) return;

    dispatch(setPageLoading(true));

    try {
      const bonusRes = await getPackageBonusScores({
        projectId: projectId || "",
        packageId: worktaskData?._id || "",
      });

      if (bonusRes?.responseCode === 200) {
        const projectChainId = BlockchainNetworks[blockchainNetwork?.name!];

        await handleSwitchNetwork(projectChainId);
        const scParams = {
          projectId: worktaskOtherInfo?.scProjectId,
          packageId: worktaskData.scPackageId,
          collaborators: worktaskData.collaboratorWalletAddress as string[],
          observers: worktaskData.observerWalletAddress as string[],
          scores:
            bonusRes?.responseData?.paymentCalculation?.collaboratorBonusScores,
        };

        let { tx, events } = await finishPackageSC(scParams);
        const budgetLeft = (+formatUnits(
          events.FinishedPackage[0][2],
          projectDecimal
        )).toFixed(1);
        const paidCollaboratorEvents: PaidCollaboratorEvent[] =
          events.PaidCollaboratorRewards.map((args: any) => {
            return {
              walletAddress: args[2],
              mgp: +formatUnits(args[3], projectDecimal),
              bonus: +formatUnits(args[4], projectDecimal),
            };
          });
        const paidObserverEvents: PaidObserverEvent[] =
          events.PaidObserverFee.map((args: any) => {
            return {
              walletAddress: args[2],
              amount: +formatUnits(args[3], projectDecimal),
            };
          });

        updateFinishStatus(
          {
            projectId: projectId || "",
            packageId: worktaskData?._id || "",
            status: "SC_SUCCESS",
          },
          {
            onSuccess: async (res) => {
              const { responseCode, responseMessage } = res;
              if (responseCode === 200) {
                finishPackage(
                  {
                    projectId: projectId || "",
                    packageId: worktaskData?._id || "",
                    budgetLeft,
                    txHash: tx.hash,
                    paidCollaboratorEvents,
                    paidObserverEvents,
                  },
                  {
                    onSuccess: async (res) => {
                      const { responseCode, responseMessage } = res;
                      if (responseCode === 200) {
                        await queryClient.invalidateQueries([
                          `getAllPackagesByProjectId-${
                            param?.id || (projectId as string)
                          }`,
                        ]);
                        dispatch(setPageLoading(false));
                        message.success(responseMessage);
                      } else {
                        dispatch(setPageLoading(false));
                        message.error(responseMessage);
                      }
                    },
                    onError: () => {
                      dispatch(setPageLoading(false));
                    },
                  }
                );
              } else {
                message.error(responseMessage);
                dispatch(setPageLoading(false));
              }
            },
            onError: () => {
              dispatch(setPageLoading(false));
            },
          }
        );
      } else {
        message.error(bonusRes?.responseMessage);
        dispatch(setPageLoading(false));
      }
    } catch (err: any) {
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };
  const totalBudgetCalculated =
    (worktaskData?.memberLimit || 0) * worktaskData?.minimumCost +
    +worktaskData?.bonus +
    worktaskData?.observerFees;
  const truncatedTokenSymbol = worktaskOtherInfo?.tokenSymbol?.slice(0, 5);

  return (
    <>
      <Container>
        {isDelivered && (
          <CommonModal
            visible={openFeedback}
            width={450}
            closable
            padding="1rem 1.5rem"
            onCancel={toggleSetOpenFeedback}
          >
            <TitleModal>Select collaborator</TitleModal>
            {listFeedback?.map((item) => (
              <FlexBox m="5px 0 0" justify="space-between" key={item?._id}>
                <FlexBox
                  gap="10px"
                  align="center!important"
                  // m="0.5rem 0rem 1.125rem"
                >
                  <AntdAvatar src={item?.profilePicture} />
                  <Subtitle> {item.fullName || item.emailId}</Subtitle>
                </FlexBox>

                {item.isReviewed && !hideButton ? (
                  <div>Reviewed</div>
                ) : (
                  !item.isReviewed &&
                  !hideButton && (
                    <Link
                      to={ROUTER_CONSTANTS.PROJECT.FEEDBACK.replace(
                        ":projectId",
                        projectId || ""
                      ).replace(":packageId", worktaskData._id || "")}
                      state={{
                        data: item,
                      }}
                    >
                      <PrimaryButton>Feedback</PrimaryButton>
                    </Link>
                  )
                )}
              </FlexBox>
            ))}
          </CommonModal>
        )}
        <Right>
          <WrapperFlex space="12px">
            <Title>{worktaskData.name}</Title>
            <WrapCategory
              className="category-tag"
              {...getWorktaskTypeStyle(category)}
            >
              {category ? category : "CATEGORY"}
            </WrapCategory>
          </WrapperFlex>
          <SponsorWrapper>
            <SponsorText>Sponsored by</SponsorText>
            {(filteredOrgs?.length || 0) > 0 ? (
              filteredOrgs?.map((orgDetail) => (
                <span key={orgDetail._id}>
                  <Avatar size={"small"} src={orgDetail?.avatar} />{" "}
                  <Link
                    style={{ color: "#080f6c", fontWeight: "500" }}
                    to={ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(
                      ":id",
                      orgDetail?._id
                    )}
                  >
                    {orgDetail?.name}
                  </Link>
                </span>
              ))
            ) : (
              <span>No sponsor</span>
            )}
          </SponsorWrapper>
          <WorktaskDesc>{worktaskData.description}</WorktaskDesc>

          {hideNumber ? (
            <>
              <DeliverableWrap />
              <NumberInforWrap>
                <NumberItem>
                  <PaymentTitle>Payments</PaymentTitle>
                  <PaymentValue>
                    {Number(worktaskData.minimumCost) *
                      Number(worktaskData.countCollaborators) +
                      Number(worktaskData.observerFees) +
                      Number(worktaskData.bonus)}{" "}
                    {truncatedTokenSymbol}
                  </PaymentValue>
                </NumberItem>
                <NumberItem>
                  <PaymentTitle>Bonus</PaymentTitle>
                  <PaymentValue>
                    {worktaskData?.bonus} {truncatedTokenSymbol}
                  </PaymentValue>
                </NumberItem>
                <NumberItem>
                  <PaymentTitle>Amount</PaymentTitle>
                  <PaymentValue>
                    {totalBudget} {truncatedTokenSymbol}
                  </PaymentValue>
                </NumberItem>
                <NumberItem>
                  <PaymentTitle>Currency</PaymentTitle>
                  <PaymentValue>
                    {truncatedTokenSymbol || worktaskOtherInfo?.tokenName}
                  </PaymentValue>
                </NumberItem>
              </NumberInforWrap>
              <DeliverableWrap marginBottom="18px" />
            </>
          ) : (
            <InforWrap>
              <Flex>
                <Property marginRight={20}>Total Budget</Property>
                <TooltipAtnd
                  placement="top"
                  title={
                    <span>{`${totalBudgetCalculated} ${
                      truncatedTokenSymbol || worktaskOtherInfo?.tokenName
                    }`}</span>
                  }
                >
                  <Budget>{`${totalBudgetCalculated} ${
                    truncatedTokenSymbol || worktaskOtherInfo?.tokenName
                  }`}</Budget>
                </TooltipAtnd>
                <Prifix>
                  ~$
                  {(totalBudgetCalculated * (projectExpectedUsd || 0)).toFixed(
                    2
                  )}
                </Prifix>
                <Devider />
                <Icon>
                  <StarIcon />
                </Icon>
                <TooltipAtnd
                  placement="top"
                  title={
                    <span>{`${worktaskData?.minimumCost} ${
                      truncatedTokenSymbol || worktaskOtherInfo?.tokenName
                    } `}</span>
                  }
                >
                  <WrapperFlex>
                    <Money>{`${worktaskData?.minimumCost} ${
                      truncatedTokenSymbol || worktaskOtherInfo?.tokenName
                    }`}</Money>
                    <Prifix>Min. Guaranteed Payment</Prifix>
                  </WrapperFlex>
                </TooltipAtnd>
                <Devider />
                <Icon>
                  <GiftIcon />
                </Icon>
                <TooltipAtnd
                  placement="top"
                  title={
                    <span>
                      {worktaskData?.bonus}{" "}
                      {truncatedTokenSymbol || worktaskOtherInfo?.tokenName}
                    </span>
                  }
                >
                  <Money>
                    {worktaskData?.bonus}{" "}
                    {truncatedTokenSymbol || worktaskOtherInfo?.tokenName}
                  </Money>
                </TooltipAtnd>
                <Prifix>Total Bonus</Prifix>
              </Flex>
              <Flex>
                <Property marginRight={12} width="max-content">
                  Build with
                </Property>
                <CollabWrapper space="4px">
                  Up to <Budget>{worktaskData.memberLimit}</Budget>{" "}
                  Collaborators
                </CollabWrapper>
                <Devider />
                <Property marginRight={18} width="max-content">
                  Currently Accepted
                </Property>
                <Budget style={{ marginRight: "8px" }}>
                  {worktaskData?.collaboratorsDetails?.length === 0
                    ? "No Collaborator"
                    : getPluralText(
                        worktaskData?.collaboratorsDetails?.length as number,
                        "Collaborator"
                      )}
                </Budget>
                <AvatarAntdGroup
                  collabs={
                    worktaskData?.collaboratorsDetails?.map((collab: any) => {
                      return {
                        url: collab?.profilePicture,

                        id: collab?._id,
                        username:
                          collab?.username ||
                          collab?.emailId.split("@").shift(),
                      };
                    }) || []
                  }
                  size={20}
                  max={5}
                />
              </Flex>
            </InforWrap>
          )}
          {userId ? (
            <FlexBox gap="6px">
              <Link to={goToDeliverWorkUrl}>
                <PrimaryButton>View Worktask</PrimaryButton>
              </Link>

              {isProjectCreator && !isFinish && !hideButton && (
                <Link to={`/edit-worktask/${projectId}/${worktaskData?._id}`}>
                  <PrimaryButton>Edit </PrimaryButton>
                </Link>
              )}
              {isFinish && !hideButton && (
                <PrimaryButton>Completed </PrimaryButton>
              )}
              {isProjectCreator || getStatus()}
              {(isProjectCreator ||
                isObserver ||
                isAlreadyCollaborator ||
                isSponsor) && (
                // isProjectCreator &&
                <>
                  {!isCompletedWorktask &&
                    (isCreatorDelivers || isCollaboratorDeliver) && (
                      <Link
                        to={goToDeliverWorkUrl}
                        state={{
                          toDeliverTab: true,
                        }}
                      >
                        {(!isSponsor || isProjectCreator) && (
                          <PrimaryButton width="180px">
                            {isProjectCreator &&
                              isCreatorDelivers &&
                              " Review the Deliverables"}
                            {isCollaboratorDeliver && " View your Deliverables"}
                          </PrimaryButton>
                        )}
                      </Link>
                    )}
                  {((isProjectCreator && !hideButton && isDelivered) ||
                    (isSponsor && !hideButton && isDelivered)) && (
                    <PrimaryButton onClick={toggleSetOpenFeedback}>
                      Feedback
                    </PrimaryButton>
                  )}
                  {isCanSubmitDeliverables &&
                    isDashboard &&
                    hideButton &&
                    worktaskData?.workStatus === "INPROGRESS" &&
                    isAlreadyCollaborator && (
                      <PrimaryButton onClick={handleOpenDeliverPopup}>
                        Submit your work
                      </PrimaryButton>
                    )}
                  {isCollaboratorReviewed &&
                    isDashboard &&
                    hideButton &&
                    worktaskData?.workStatus === "DELIVERED" &&
                    isAlreadyCollaborator && (
                      <Link
                        to={ROUTER_CONSTANTS.PROJECT.FEEDBACK_INFO.replace(
                          ":projectId",
                          projectId || ""
                        ).replace(":packageId", worktaskData?._id || "")}
                      >
                        <PrimaryButton>Check your feedback</PrimaryButton>
                      </Link>
                    )}
                </>
              )}
              {isProjectCreator &&
                !isFinish &&
                isAbleToCancel &&
                !hideButton && (
                  <Popconfirm
                    title="Are you sure you want to cancel the worktask?"
                    okText="Yes"
                    cancelText="No"
                    visible={openCancelPopConfirm}
                    onConfirm={() => handleCancelPackage()}
                    onCancel={() => setOpenCancelPopConfirm(false)}
                  >
                    <SecondaryButton
                      $borderColor={"red"}
                      $color={"red"}
                      onClick={() => setOpenCancelPopConfirm(true)}
                    >
                      Cancel Worktask
                    </SecondaryButton>
                  </Popconfirm>
                )}
              {isProjectCreator && newRequest && newRequest?.length > 0 && (
                <BtnActionsWrapper space="10px">
                  <Link
                    to={ROUTER_CONSTANTS.PROJECT.REVIEW_WORKTASK.replace(
                      ":projectId",
                      param?.id || (projectId as string)
                    ).replace(":packageId", worktaskData?._id || "")}
                  >
                    <PrimaryButton>Review Applicants</PrimaryButton>
                  </Link>
                  <RequestNumber>
                    {getPluralText(newRequest?.length, "New request")}
                  </RequestNumber>
                </BtnActionsWrapper>
              )}
              {isCreatorApproveAllCollabs && isShowFinishPackage && (
                <PrimaryButton onClick={handleFinishWorktask}>
                  Finish Worktask
                </PrimaryButton>
              )}
            </FlexBox>
          ) : (
            <FlexBox gap="6px">
              <Link to={ROUTER_CONSTANTS.USER.LOGIN}>
                <PrimaryButton>View Worktask</PrimaryButton>
              </Link>
            </FlexBox>
          )}
        </Right>
        <Left>
          <Flex>
            <Property width="168px" marginRight={0}>
              Type of Task
            </Property>
            <Value>{worktaskData.issueType?.name}</Value>
          </Flex>
          <Flex>
            <Property width="168px" marginRight={0}>
              WorkTask Type
            </Property>
            <WrapTag {...projectTypeBackground(worktaskData.projectType.name)}>
              {worktaskData.projectType?.name}
            </WrapTag>
          </Flex>
          <Flex>
            <Property width="168px" marginRight={0}>
              Experience Level
            </Property>
            <WrapTag
              {...expertiseLevelBackground(worktaskData.expertiseLevel.name)}
            >
              {worktaskData.expertiseLevel?.name}
            </WrapTag>
          </Flex>
          <Flex>
            <Property width="168px" marginRight={0}>
              Expected Effort Required
            </Property>
            <Value>
              {formatTimeCommitment(
                worktaskData.expectedTimeCommitment as number
              )}
            </Value>
          </Flex>
          <Flex>
            <Property width="168px" marginRight={0}>
              Deadline
            </Property>
            <WrapTimeRemaining
              dangerouslySetInnerHTML={{
                __html: timeRemaining(
                  worktaskData.endDate,
                  worktaskData.startDate
                ),
              }}
            ></WrapTimeRemaining>
          </Flex>
        </Left>
      </Container>
    </>
  );
};

export default Worktask;
