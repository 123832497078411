import { useQuery } from "@tanstack/react-query";
import { Form, message } from "antd";
import { getCreateWorktaskMasters } from "api/users";
import { SelectArrowDown } from "assets/icons/common/selectArrowDown.icon";
import { InfoIcon } from "assets/images/Bounty";
import { AxiosError } from "axios";
import Button from "components/base/button";
import moment from "moment";
import { StyledInput } from "pages/CreateProject/createProject.style";
import { IMaster } from "pages/Worktasks/CreateWorkTask/CreateWorktask.props";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { addStepSubmit, setMastersData, setWorktaskType } from "redux/slices";
import { generateAscendingNumberFromZero } from "utils/generateAscendingNumber";
import { requiredRules } from "utils/validatorRules";
import { IWorktaskTypeProps } from "../../Bounty.props";
import {
  DatePickerAtnd,
  Divider,
  FlexWrap,
  LabelWithIcon,
  StyledFormItem,
  StyledOption,
  StyledSelect,
  Subtile,
  TimeWrap,
  SelectItem,
  Title16,
  // ObserverItemWrap,
  // ObserverItem,
  StyledSelectTags,
  StyledTimeSelect,
  // StyledSelectNorotate,
  StyledCenterTimeSelect,
  StyledFlexEndTimeSelect,
} from "../../Bounty.style";

const WorktaskType: FC<IWorktaskTypeProps> = ({
  worktaskDetails,
  isWorktaskLoading,
  editMode,
}) => {
  const {
    issueType,
    expertiseLevel,
    startDate,
    endDate,
    expectedTimeCommitment,
    tags,
    memberLimit,
  } = useAppSelector((state) => {
    return state.addWorktask;
  });

  const [selectedTypeOfTask, setSelectedTypeOfTask] = useState<string>("");
  const [isIndividual, setIsIndividual] = useState(
    false // memberLimit === 1
  );
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editMode && isWorktaskLoading) return;

    form.setFieldValue(
      "expertiseLevel",
      expertiseLevel || worktaskDetails?.expertiseLevel?._id
    );
    form.setFieldValue(
      "startDate",
      startDate || worktaskDetails?.startDate
        ? moment(startDate || worktaskDetails?.startDate)
        : undefined
    );
    form.setFieldValue(
      "endDate",
      endDate || worktaskDetails?.endDate
        ? moment(endDate || worktaskDetails?.endDate)
        : undefined
    );
    form.setFieldValue("tags", tags || worktaskDetails?.tags);
    form.setFieldValue(
      "memberLimit",
      memberLimit || worktaskDetails?.memberLimit
    );
    form.setFieldValue(
      "typeOfTask",
      issueType || worktaskDetails?.issueType?._id
    );
    form.setFieldValue(
      "expectedTimeCommitment",
      expectedTimeCommitment || worktaskDetails?.expectedTimeCommitment
    );

    setSelectedTypeOfTask(
      issueType || (worktaskDetails?.issueType?._id as string)
    );
    setIsIndividual((memberLimit || worktaskDetails?.memberLimit) === 1);

    function getTime(startDate: number, hourDuration: number) {
      function getAbsoluteMonths(momentDate: moment.Moment) {
        const days = Number(momentDate.format("DD"));
        const months = Number(momentDate.format("MM"));
        const years = Number(momentDate.format("YYYY"));
        return [months + years * 12, days];
      }
      if (!startDate) {
        return [0, 0, 0, 0];
      } else {
        const start = moment(startDate);
        const end = moment(startDate).add(hourDuration, "hours");

        const startTimes = getAbsoluteMonths(start);
        const endTimes = getAbsoluteMonths(end);

        const gap = startTimes[1] - endTimes[1] > 0 ? 1 : 0;

        const months = endTimes[0] - startTimes[0] - gap;

        const momentWithMonths = moment(startDate).add(months, "months");

        const duration = moment.duration(end.diff(momentWithMonths));
        const diffInHours = duration.asHours();
        const weeks = Math.floor(duration.asWeeks());
        const days = Math.floor(duration.asDays()) - weeks * 7;
        const hours = diffInHours - weeks * 7 * 24 - days * 24;

        return [months, weeks, days, hours];
      }
    }

    const times = getTime(
      startDate || worktaskDetails?.startDate,
      expectedTimeCommitment ||
        (worktaskDetails?.expectedTimeCommitment as number)
    );

    form.setFieldValue("months", times[0]);
    form.setFieldValue("weeks", times[1]);
    form.setFieldValue("days", times[2]);
    form.setFieldValue("hours", times[3]);

    // setSelectedObservers(
    //   observersData.filter((obs: any) => observers.includes(obs._id))
    // );
    // setTypeOfTask(issueType);
  }, [
    editMode,
    endDate,
    expectedTimeCommitment,
    expertiseLevel,
    form,
    isWorktaskLoading,
    issueType,
    memberLimit,
    startDate,
    tags,
    worktaskDetails,
  ]);

  // const [selectedObservers, setSelectedObservers] = useState<any>(
  //   observersData.filter((obs1: any) =>
  //     observers.find((obs2: any) => obs1._id === obs2._id)
  //   )
  // );

  // const [selectObservers, setSelectObservers] = useState<any>([]);
  // const [selectObserverId, setSelectObserverId] = useState<string>("");

  // useEffect(() => {
  //   setSelectObservers(
  //     observersData.filter(
  //       (obs1: any) => !observers.find((obs2: any) => obs1._id === obs2._id)
  //     )
  //   );
  // }, [observersData, observers]);

  const monthsSelection = useMemo(
    () => generateAscendingNumberFromZero(13),
    []
  ); // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const weeksSelection = useMemo(() => generateAscendingNumberFromZero(5), []); // [0, 1, 2, 3, 4];
  const daysSelection = useMemo(() => generateAscendingNumberFromZero(7), []); // [0, 1, 2, 3, 4, 5, 6];
  const hoursSelection = useMemo(() => generateAscendingNumberFromZero(24), []);
  // [
  //   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  //   21, 22, 23,
  // ];

  // const [monthsSelection] = useState(monthsMax);
  // const [weeksSelection] = useState(weeksMax);
  // const [daysSelection] = useState(daysMax);
  // const [hoursSelection] = useState(hoursMax);

  // function addObserver(id: string) {
  //   if (selectedObservers.find((obs: any) => obs._id === id)) return;
  //   const selectObserver = observersData.find((obs: any) => obs._id === id);
  //   const newSelectObservers = selectObservers.filter(
  //     (obs: any) => obs._id !== id
  //   );
  //   const newSelectedObservers = [...selectedObservers, selectObserver];

  //   setSelectObservers(newSelectObservers);
  //   setSelectedObservers(newSelectedObservers);
  // }

  // function removeObserver(observer: any) {
  //   const newSelectedObservers = selectedObservers.filter(
  //     (obs: any) => obs._id !== observer._id
  //   );
  //   const newSelectObservers = observersData.filter(
  //     (obs1: any) =>
  //       !newSelectedObservers.find((obs2: any) => obs1._id === obs2._id)
  //   );

  //   setSelectObservers(newSelectObservers);
  //   setSelectedObservers(newSelectedObservers);
  // }

  const { data: collabMasterRes, isLoading: collabMasterLoading } = useQuery(
    ["getCreateWorktaskMasters"],
    () => getCreateWorktaskMasters(),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          dispatch(
            setMastersData({
              projectTypes: res.responseData[0].values || [],
              typeOfTasks: res.responseData[1].values || [],
              levelOfExpertise: res.responseData[2].values,
            })
          );

          // if (
          //   worktaskDetails?.memberLimit === 1
          //   // memberLimit === 1
          // ) {
          //   form.setFieldValue(
          //     "collaborationMode",
          //     res.responseData[0].values[0]._id
          //   );
          // } else {
          //   form.setFieldValue(
          //     "collaborationMode",
          //     res.responseData[0].values[1]._id
          //   );
          // }
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      refetchOnWindowFocus: false,
    }
  );
  const projectTypes = collabMasterRes?.responseData[0].values || [];
  const typeOfTasks = collabMasterRes?.responseData[1].values || [];
  const levelOfExpertise = collabMasterRes?.responseData[2].values || [];

  useEffect(() => {
    if (collabMasterLoading) return;

    if ((memberLimit || worktaskDetails?.memberLimit) === 1) {
      form.setFieldValue(
        "collaborationMode",
        collabMasterRes?.responseData[0].values[0]._id
      );
    } else {
      form.setFieldValue(
        "collaborationMode",
        collabMasterRes?.responseData[0].values[1]._id
      );
    }
  }, [
    collabMasterLoading,
    collabMasterRes?.responseData,
    form,
    memberLimit,
    worktaskDetails?.memberLimit,
  ]);

  const stepSubmit = useCallback(async () => {
    const validation = await form
      .validateFields([
        "typeOfTask",
        "expertiseLevel",
        "startDate",
        "endDate",
        "expectedTimeCommitment",
        "memberLimit",
      ])
      .then((values) => {
        return true;
      })
      .catch((errorInfo) => {
        return false;
      });

    if (!validation) return false;

    const issueType = form.getFieldValue("typeOfTask");
    const expertiseLevel = form.getFieldValue("expertiseLevel");
    const startDate = +form
      .getFieldValue("startDate")
      .startOf("day")
      .format("x");
    const endDate = +form.getFieldValue("endDate").endOf("day").format("x");
    const tags = form.getFieldValue("tags");
    const expectedTimeCommitment = form.getFieldValue("expectedTimeCommitment");
    const memberLimit = form.getFieldValue("memberLimit");
    const projectType = form.getFieldValue("collaborationMode");

    const payload = {
      projectType,
      issueType,
      expertiseLevel,
      startDate,
      endDate,
      expectedTimeCommitment,
      tags,
      memberLimit,
      // observers: selectedObservers,
    };

    dispatch(setWorktaskType(payload));

    return true;
  }, [dispatch, form]);

  useEffect(() => {
    dispatch(addStepSubmit({ stepSubmit }));
  }, [dispatch, stepSubmit]);

  const dateRule = {
    validator: (_: any, value: string) => {
      const endDateMoment = form.getFieldValue("endDate");
      const startDateMoment = form.getFieldValue("startDate");

      if (!endDateMoment || !startDateMoment) return Promise.resolve();

      const endDate = endDateMoment.endOf("day").format("x");
      const startDate = startDateMoment.startOf("day").format("x");

      if (endDate < startDate) {
        return Promise.reject(new Error("End Date must be after Start Date!"));
      }

      return Promise.resolve();
    },
  };

  function disabledEndDate(current: moment.Moment) {
    const rule1 = current.isBefore(moment().startOf("day"));
    let rule2 = false;
    const startDate = form.getFieldValue("startDate")?.startOf("day");
    if (startDate) {
      rule2 = current.isBefore(startDate);
    }
    return rule1 || rule2;
  }

  function disabledStartDate(current: moment.Moment) {
    const rule1 = current.isBefore(moment().startOf("day"));
    let rule2 = false;
    const endDate = form.getFieldValue("endDate")?.endOf("day");
    if (endDate) {
      rule2 = current.isAfter(endDate);
    }
    return rule1 || rule2;
  }

  function onCollaborationModeChange(value: any) {
    const type = projectTypes.find((t: IMaster) => t._id === value);

    if (type?.name.includes("Individual")) {
      setIsIndividual(true);
      form.setFieldValue("memberLimit", 1);
    } else {
      setIsIndividual(false);
      form.setFieldValue("memberLimit", undefined);
    }
  }

  function checkExpectedTimeCommitment(_?: any) {
    const months = form.getFieldValue("months");
    const weeks = form.getFieldValue("weeks");
    const days = form.getFieldValue("days");
    const hours = form.getFieldValue("hours");

    const relativelyHours =
      months * 24 * 30 + weeks * 7 * 24 + days * 24 + hours;
    form.setFieldValue("expectedTimeCommitment", relativelyHours);

    if (!form.getFieldValue("endDate") || !form.getFieldValue("endDate")) {
      form.validateFields(["expectedTimeCommitment"]);
      return;
    }

    const endDate = +form.getFieldValue("endDate").endOf("day").format("x");
    const startDate =
      +form.getFieldValue("startDate").startOf("day").format("x") - 1;

    const commitmentDuration = moment(startDate).add({
      months,
      weeks,
      days,
      hours,
    });

    if (commitmentDuration.isAfter(moment(endDate)))
      form.setFieldValue("expectedTimeCommitment", "OverTime");
    else {
      const differenceInMs = commitmentDuration.diff(startDate);
      const duration = moment.duration(differenceInMs);
      const differenceInHours = duration.asHours();
      form.setFieldValue("expectedTimeCommitment", differenceInHours);
    }

    form.validateFields(["expectedTimeCommitment"]);
  }

  function checkOnDateChange(_?: any) {
    if (!form.getFieldValue("endDate") || !form.getFieldValue("startDate")) {
      return;
    }

    const endDate = +form.getFieldValue("endDate").endOf("day").format("x");
    const startDate =
      +form.getFieldValue("startDate").startOf("day").format("x") - 1;

    const months = form.getFieldValue("months");
    const weeks = form.getFieldValue("weeks");
    const days = form.getFieldValue("days");
    const hours = form.getFieldValue("hours");

    const commitmentDuration = moment(startDate).add({
      months,
      weeks,
      days,
      hours,
    });

    if (commitmentDuration.isAfter(moment(endDate)))
      form.setFieldValue("expectedTimeCommitment", "OverTime");
    else {
      const differenceInMs = commitmentDuration.diff(startDate);
      const duration = moment.duration(differenceInMs);
      const differenceInHours = duration.asHours();
      form.setFieldValue("expectedTimeCommitment", differenceInHours);
    }

    if (!(months + weeks + days + hours)) return;

    form.validateFields(["expectedTimeCommitment"]);
  }

  const durationValidationRule = {
    validator: (_: any, value: any) => {
      if (value === "OverTime")
        return Promise.reject(
          new Error(
            "Expected Time Commitment over between Start Time and End Time."
          )
        );

      if (value === 0 || !value)
        return Promise.reject(new Error("Expected Time Commitment empty."));

      if (value) return Promise.resolve();

      // return Promise.reject(new Error("Error occurred!"));
    },
  };

  return (
    <>
      <Form form={form} layout="vertical" scrollToFirstError>
        <Title16>
          <LabelWithIcon gap={6}>
            Type{" "}
            <InfoIcon title="Please select the most appropriate tags related to your WorkTask. This helps categorize and identify the type of work involved." />
            <label style={{ color: "#ff4d4f" }}>*</label>
          </LabelWithIcon>
        </Title16>
        <Subtile>
          Please select the most appropriate tags related to your WorkTask
        </Subtile>
        <FlexWrap gap={6} key={selectedTypeOfTask}>
          {typeOfTasks.map((type: any) => (
            <Button
              border="1px solid rgba(115, 137, 149, 0.3)"
              color={
                selectedTypeOfTask === type?._id
                  ? "#FFF"
                  : "rgba(16, 37, 72, 0.5)"
              }
              background={selectedTypeOfTask === type?._id ? "#7e00fd" : "#FFF"}
              key={type?._id}
              onClick={() => {
                setSelectedTypeOfTask(type?._id);
                form.setFieldValue("typeOfTask", type?._id);
                form.validateFields(["typeOfTask"]);
              }}
            >
              {type?.name}
            </Button>
          ))}
        </FlexWrap>
        <StyledFormItem name="typeOfTask" rules={[requiredRules]}>
          <StyledInput style={{ display: "none" }} />
        </StyledFormItem>

        <FlexWrap gap={20} columns={2} margin="28px 0px 0px">
          <StyledFormItem
            name="expertiseLevel"
            rules={[requiredRules]}
            label={
              <LabelWithIcon gap={6}>
                Experience Level{" "}
                <InfoIcon title="Indicate the level of experience required to successfully complete the WorkTask. This helps collaborators assess their suitability for the task." />
              </LabelWithIcon>
            }
          >
            <StyledSelect
              suffixIcon={<SelectArrowDown />}
              placeholder="Experience Level"
            >
              {levelOfExpertise.map((type: any, i: number) => (
                <StyledOption value={type._id} key={i}>
                  {type.name}
                </StyledOption>
              ))}
            </StyledSelect>
          </StyledFormItem>
          <StyledFormItem
            label={
              <LabelWithIcon gap={6}>
                Expected Time Commitment{" "}
                <InfoIcon title="Estimate the amount of time required to complete the WorkTask. This provides collaborators with an idea of the time investment needed." />
                <label style={{ color: "#ff4d4f" }}>*</label>
              </LabelWithIcon>
            }
          >
            <TimeWrap>
              <StyledFormItem name="months" width="21%">
                <StyledTimeSelect
                  placeholder="Sellect months"
                  onChange={checkExpectedTimeCommitment}
                >
                  {monthsSelection.map((month: number, i: number) => (
                    <StyledOption value={month} key={i}>
                      <SelectItem>
                        <p>{month > 9 || month === 0 ? month : "0" + month}</p>
                        {month === 1 ? "month" : "months"}
                      </SelectItem>
                    </StyledOption>
                  ))}
                </StyledTimeSelect>
              </StyledFormItem>

              <Divider />

              <StyledFormItem name="weeks" width="29%">
                <StyledCenterTimeSelect
                  placeholder="Sellect weeks"
                  onChange={checkExpectedTimeCommitment}
                >
                  {weeksSelection.map((week: number, i: number) => (
                    <StyledOption value={week} key={i}>
                      <SelectItem>
                        <p>{week > 9 || week === 0 ? week : "0" + week}</p>
                        {week === 1 ? "week" : "weeks"}
                      </SelectItem>
                    </StyledOption>
                  ))}
                </StyledCenterTimeSelect>
              </StyledFormItem>

              <Divider />

              <StyledFormItem name="days" width="29%">
                <StyledCenterTimeSelect
                  placeholder="Sellect days"
                  onChange={checkExpectedTimeCommitment}
                >
                  {daysSelection.map((day: number, i: number) => (
                    <StyledOption value={day} key={i}>
                      <SelectItem>
                        <p>{day > 9 || day === 0 ? day : "0" + day}</p>
                        {day === 1 ? "day" : "days"}
                      </SelectItem>
                    </StyledOption>
                  ))}
                </StyledCenterTimeSelect>
              </StyledFormItem>

              <Divider />

              <StyledFormItem name="hours" width="21%">
                <StyledFlexEndTimeSelect
                  placeholder="Sellect hours"
                  onChange={checkExpectedTimeCommitment}
                >
                  {hoursSelection.map((hour: number, i: number) => (
                    <StyledOption value={hour} key={i}>
                      <SelectItem>
                        <p>{hour > 9 || hour === 0 ? hour : "0" + hour}</p>
                        {hour === 1 ? "hour" : "hours"}
                      </SelectItem>
                    </StyledOption>
                  ))}
                </StyledFlexEndTimeSelect>
              </StyledFormItem>
            </TimeWrap>

            <StyledFormItem
              name="expectedTimeCommitment"
              rules={[durationValidationRule]}
            >
              <StyledInput style={{ display: "none" }} />
            </StyledFormItem>
          </StyledFormItem>
        </FlexWrap>
        <FlexWrap gap={20} columns={2} margin="28px 0px 0px">
          <FlexWrap gap={15} columns={2}>
            <StyledFormItem
              name="startDate"
              rules={[requiredRules, dateRule]}
              label={
                <LabelWithIcon gap={6}>
                  Start Date{" "}
                  <InfoIcon title="Specify the intended start date for the WorkTask. This helps collaborators plan their availability and schedules accordingly." />
                </LabelWithIcon>
              }
            >
              <DatePickerAtnd
                placeholder="Start Date"
                disabledDate={disabledStartDate}
                onChange={checkOnDateChange}
              />
            </StyledFormItem>
            <StyledFormItem
              name="endDate"
              rules={[requiredRules, dateRule]}
              label={
                <LabelWithIcon gap={6}>
                  End Date{" "}
                  <InfoIcon title="Specify the desired completion date for the WorkTask. This provides collaborators with a clear timeline for the task." />
                </LabelWithIcon>
              }
            >
              <DatePickerAtnd
                placeholder="End Date"
                disabledDate={disabledEndDate}
                onChange={checkOnDateChange}
              />
            </StyledFormItem>
          </FlexWrap>
          <StyledFormItem
            name="collaborationMode"
            rules={[requiredRules]}
            label={
              <LabelWithIcon gap={6}>
                Collaboration Mode{" "}
                <InfoIcon title="Select the preferred mode of collaboration for the WorkTask. This defines how collaborators will work together on the task." />
              </LabelWithIcon>
            }
          >
            <StyledSelect
              suffixIcon={<SelectArrowDown />}
              placeholder="Collaboration Mode"
              onChange={onCollaborationModeChange}
              disabled={editMode}
            >
              {projectTypes.map((type: any, i: number) => (
                <StyledOption value={type._id} key={i}>
                  {type.name}
                </StyledOption>
              ))}
            </StyledSelect>
          </StyledFormItem>
        </FlexWrap>

        <FlexWrap gap={20} columns={2} margin="28px 0px 0px">
          <StyledFormItem
            name="memberLimit"
            rules={[requiredRules]}
            label={
              <LabelWithIcon gap={6}>
                Maximum Number of Collaborators{" "}
                <InfoIcon title="Determine the maximum number of collaborators allowed for the WorkTask. This ensures effective team management and resource allocation." />
              </LabelWithIcon>
            }
          >
            <StyledSelect
              suffixIcon={<SelectArrowDown />}
              placeholder="Choose maximize of members"
              disabled={editMode || isIndividual}
            >
              {(isIndividual ? [1] : generateAscendingNumberFromZero(8, 3))
                // [3, 4, 5, 6, 7, 8, 9, 10]
                .map((_: number) => (
                  <StyledOption value={_} key={_}>
                    {_}
                  </StyledOption>
                ))}
            </StyledSelect>
          </StyledFormItem>

          <StyledFormItem
            name="tags"
            label={
              <LabelWithIcon gap={6}>
                Tags{" "}
                <InfoIcon title="Add relevant tags that describe the specific requirements, technologies, or skills associated with the WorkTask. This aids in the discovery and matching of suitable collaborators." />
              </LabelWithIcon>
            }
          >
            <StyledSelectTags
              suffixIcon={<SelectArrowDown />}
              placeholder="Type tags"
              mode="tags"
              size="small"
            >
              {[].map((_, i) => (
                <StyledOption value={_} key={i}>
                  {_}
                </StyledOption>
              ))}
            </StyledSelectTags>
          </StyledFormItem>
        </FlexWrap>
      </Form>
    </>
  );
};

export default WorktaskType;
