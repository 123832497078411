import { StepProps } from "antd";
import { COURSE_TYPE, KIND_TYPE } from "api/course";
import {
  AddBelowIcon,
  DuplicateIcon,
  RemoveIcon,
} from "assets/images/course/activity";
import { NFTIcon, TokenIcon } from "assets/images/course/rewardIcon";
import {
  CompleteIcon,
  CourseCustomizeIcon,
  PaymentInfoIcon,
  ProjectDetailsIcon,
} from "assets/images/createProject";

export const COURSE_STEPS: StepProps[] = [
  {
    title: "Basic Information",
    icon: <ProjectDetailsIcon />,
  },
  {
    title: "Course customisation",
    icon: <CourseCustomizeIcon />,
  },
  {
    title: "Prizes and Reward setup",
    icon: <PaymentInfoIcon />,
  },
  {
    title: "Finalisation",
    icon: <CompleteIcon />,
  },
];

export enum ACTIVITY_TOOL_TYPE {
  ADD = "add",
  DUPLICATE = "duplicate",
  REMOVE = "remove",
  MOVE = "move",
}

export const COURSE_DURATION_TYPE = [
  {
    title: "Duration",
    value: COURSE_TYPE.CUSTOM_DAY_PLAN,
  },
  {
    title: "Start date - End date",
    value: COURSE_TYPE.START_AND_END_DATE,
  },
];

export const ACTIVITY_ACTION = [
  {
    title: "Add an activity below",
    value: ACTIVITY_TOOL_TYPE.ADD,
    icon: <AddBelowIcon />,
  },
  {
    title: "Duplicate",
    value: ACTIVITY_TOOL_TYPE.DUPLICATE,
    icon: <DuplicateIcon />,
  },
  {
    title: "Remove",
    value: ACTIVITY_TOOL_TYPE.REMOVE,
    icon: <RemoveIcon />,
  },
];

export const COURSE_AWARDS = [
  {
    title: "Fungible token",
    icon: <TokenIcon />,
    value: KIND_TYPE.TOKEN,
    desc: "Please select if you wish to introduce a new or an existing token to reward successful participants in this Learn 2 Earn initiative.",
    options: ["Use an existing token", "Issue a new token"],
  },
  {
    title: "NFT token",
    icon: <NFTIcon />,
    value: KIND_TYPE.NFT,
    desc: "Please select if you wish to introduce a new or an issued NFT to reward successful participants in this Learn 2 Earn initiative.",
    options: ["Import an issued NFT", "Issue a new NFT"],
  },
];
