import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { getProjectDetails, IProject } from "api/users";
import { Background } from "assets/images/worktasks-inside";
import { AxiosError } from "axios";
import Conversation from "pages/Conversation/components/Conversation";
import OrganizationInfo from "pages/OrganizationPage/OrganizationInfo";
import ProjectBoard from "pages/ProjectBoard";
import SubmitDeliverable from "pages/SubmitDeliverable";
import WorktaskHead from "pages/WorktasksInside/WorktaskHead";
import WorktaskInfo from "pages/WorktasksInside/WorktaskInfo";
import { FC, Fragment, useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ROOM_TYPES, TABS_CONTENT } from "utils/constant";
import {
  BodyWrap,
  DesText,
  DesTitle,
  Git,
  GitWrap,
} from "../WorktasksInside/WorktasksInside.style";
import DeliverCollaborators from "./components/DeliverCollaborators";
import Observers from "./components/Observers";
import { IDeliverWorkProps } from "./DeliverWork.props";
import { Container, ResourcesWrap, StyledTabs } from "./DeliverWork.style";
import { elementing, splitDescription } from "utils/formatDescription";
import { PurpleAttachIcon2 } from "assets/images/deliver-work";
import { ButtonAntd } from "./components/DeliverCollaborators/DeliverCollaborators.style";
import { useGetWorktaskDetailAndCheckIsStranger } from "hooks";

const DeliverWork: FC<IDeliverWorkProps> = () => {
  const location = useLocation();
  const param = useParams();
  const {
    worktaskDetail,
    isStranger,
    isProjectCreator,
    isObserver,
    isLoading: wtLoading,
  } = useGetWorktaskDetailAndCheckIsStranger(param);
  const [searchParams] = useSearchParams();

  const { data: projectDetailRes } = useQuery(
    [`getProjectDetail-${param.projectId}`],
    () => getProjectDetails(param.projectId || ""),
    {
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      refetchOnWindowFocus: false,
    }
  );

  const project: IProject | undefined = projectDetailRes?.responseData;

  const [tab, setTab] = useState<string>(TABS_CONTENT.PROJECT_DETAILS);

  const allowedTabs = [
    TABS_CONTENT.PROJECT_DETAILS,
    TABS_CONTENT.COMPANY_INFOMATION,
  ];

  useEffect(() => {
    const routerState: any = location.state;
    const tabKey = searchParams.get("tabIndex");

    if (routerState?.toProjectBoardTab) {
      setTab(TABS_CONTENT.PROJECT_BOARD);
    }

    if (routerState?.toDeliverTab) {
      setTab(TABS_CONTENT.DELIVERABLES);
    }

    if (tabKey) {
      setTab(TABS_CONTENT.PROJECT_BOARD);
    }
  }, [location.state, searchParams]);

  const formatTextToNewType = (text: string) => {
    return (
      <DesText>
        {splitDescription(text).map((str: string, i: number) => (
          <Fragment key={i}>
            {elementing(str)}
            <br />
          </Fragment>
        ))}
      </DesText>
    );
  };

  if (!wtLoading && !worktaskDetail) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <WorktaskHead worktaskDetail={worktaskDetail} project={project} />
      <BodyWrap>
        <WorktaskInfo worktaskDetail={worktaskDetail} project={project} />
        <GitWrap noPadding marginBottom>
          <Background />
          <Git>
            <StyledTabs
              onChange={(selectedTab: string) => setTab(selectedTab)}
              activeKey={tab}
            >
              {Object.keys(TABS_CONTENT).map((tab: string) => (
                <StyledTabs.TabPane
                  tab={(TABS_CONTENT as any)[tab]}
                  key={(TABS_CONTENT as any)[tab]}
                  disabled={
                    isStranger
                      ? !allowedTabs.some(
                          (val) => (TABS_CONTENT as any)[tab] === val
                        )
                      : false
                  }
                />
              ))}
            </StyledTabs>
          </Git>
        </GitWrap>
        {tab === TABS_CONTENT.PROJECT_DETAILS && (
          <div>
            {worktaskDetail?.description && (
              <>
                <DesTitle>Description</DesTitle>
                {formatTextToNewType(worktaskDetail?.description)}
              </>
            )}
            {worktaskDetail?.context && (
              <>
                <DesTitle>What you will be working on</DesTitle>
                {formatTextToNewType(worktaskDetail?.context)}
              </>
            )}
            {worktaskDetail?.acceptanceCriteria && (
              <>
                <DesTitle>Acceptance Criteria</DesTitle>
                {formatTextToNewType(worktaskDetail?.acceptanceCriteria)}
              </>
            )}
            {(worktaskDetail?.reference ||
              (worktaskDetail?.resourceFiles ?? []).length > 0) && (
              <>
                <DesTitle>Resources</DesTitle>
                <DesText>{worktaskDetail?.reference}</DesText>
                <DesTitle>
                  <ResourcesWrap>
                    {worktaskDetail?.resourceFiles.map(
                      (file: any, i: number) => (
                        <ButtonAntd
                          onClick={() => {
                            window.open(file?.fileLink, "_blank");
                          }}
                          key={i}
                        >
                          <div>
                            <PurpleAttachIcon2 />
                            {file.fileName}
                          </div>
                        </ButtonAntd>
                      )
                    )}
                  </ResourcesWrap>
                </DesTitle>
              </>
            )}
          </div>
        )}
        {tab === TABS_CONTENT.DELIVERABLES && (
          <SubmitDeliverable
            projectId={param.projectId}
            packageId={param.packageId}
            type={
              // deliverableType && deliverableType === "initiator"
              isProjectCreator || isObserver ? "initiator" : "collaborator"
            }
            worktaskDetail={worktaskDetail}
          />
        )}
        {tab === TABS_CONTENT.PROJECT_BOARD && (
          <ProjectBoard
            projectId={param.projectId}
            packageId={param.packageId}
            worktaskDetail={worktaskDetail}
          />
        )}
        {tab === TABS_CONTENT.COMPANY_INFOMATION && (
          <OrganizationInfo
            hasMargin
            organizationId={project?.initiatorDetails?.organization}
          />
        )}
        {tab === TABS_CONTENT.MESSAGE && (
          <Conversation
            groupRoomId={worktaskDetail?.roomId}
            packageId={param.packageId}
            status={ROOM_TYPES.GROUP}
          />
        )}
        {tab === TABS_CONTENT.COLLABORATORS && (
          <DeliverCollaborators
            projectId={param.projectId}
            packageId={param.packageId}
            isProjectCreator={isProjectCreator}
            worktaskDetail={worktaskDetail}
          />
        )}
        {tab === TABS_CONTENT.OBSERVERS && (
          <Observers worktaskDetail={worktaskDetail} project={project} />
        )}
      </BodyWrap>
    </Container>
  );
};

export default DeliverWork;
