import { FC, memo } from "react";
import { useEthers } from "@usedapp/core";
import { REBAKED_ADDRESS } from "utils/constant";
import { ITableCardProps } from "./tableCard.props";
import {
  Container,
  ContainerTableCard,
  ProtocolBlock,
  ProtocolTokenInfo,
  CountToken,
  TransactionDataTime,
  ProtocolDateTimeBlock,
  ProjectAvatar,
  ProtocolName,
  Divider,
  TransactionIdBlock,
  TableCardLabel,
  TransactionIdValue,
  DestinationWalletBlock,
  ButtonView,
} from "./tableCard.style";

const TableCard: FC<ITableCardProps> = ({
  transaction,
  ...props
}: ITableCardProps) => {
  const { chainId } = useEthers();

  return (
    <Container>
      <ContainerTableCard>
        <ProtocolBlock>
          <ProtocolTokenInfo>
            <ProtocolDateTimeBlock>
              <ProtocolName>{transaction.tokenName}</ProtocolName>
              <TransactionDataTime>{transaction.dateTime}</TransactionDataTime>
            </ProtocolDateTimeBlock>
            <CountToken>{transaction.amount}</CountToken>
          </ProtocolTokenInfo>
        </ProtocolBlock>
        <Divider />
        <TransactionIdBlock>
          <TableCardLabel>Rewards Type </TableCardLabel>
          <TransactionIdValue>{transaction.type}</TransactionIdValue>
        </TransactionIdBlock>
        <TransactionIdBlock>
          <TableCardLabel>Transaction Hash</TableCardLabel>
          <TransactionIdValue title={transaction.txHash}>
            {transaction.txHash.slice(0, 12)}...
          </TransactionIdValue>
        </TransactionIdBlock>
        <DestinationWalletBlock>
          <TableCardLabel>Destination Wallet</TableCardLabel>
          <TransactionIdValue>{REBAKED_ADDRESS[chainId ?? 0]}</TransactionIdValue>
        </DestinationWalletBlock>
        <a href={"https://goerli.etherscan.io/tx/"+transaction.txHash} target="_blank">
          <ButtonView>View Details</ButtonView>
        </a>
      </ContainerTableCard>
    </Container>
  );
};

export default memo(TableCard);
