import styled from "styled-components";
export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
`;

export const Head = styled.div`
  background: linear-gradient(
    90deg,
    rgba(208, 234, 255, 0.58) 0%,
    rgba(248, 233, 245, 0.75) 40%,
    rgba(246, 244, 241, 0.83) 60%,
    rgba(233, 247, 251, 0.92) 81%,
    rgba(233, 247, 250, 0.92) 100%
  );
  // height: 262px;
  background-size: cover;
  display: flex;
  justify-content: center;
  margin-top: 16px;

  & > div {
    width: 100%;
    padding: 48px 20px;
    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 127%;
      letter-spacing: -0.04em;
      color: #080f6c;
      margin-bottom: 20px;
      word-break: break-word;
    }
  }
  border-bottom: 1px solid rgba(115, 137, 149, 0.2);
  border-top: 1px solid rgba(115, 137, 149, 0.1);
`;

export const WrapButton = styled.div`
  display: flex;
  gap: 16px;
  .bookmark {
    padding: 8px;
    width: 40px;
    height: 40px;
    background: rgba(8, 15, 108, 0.05);
    border: 1px solid rgba(8, 15, 108, 0.15);
    border-radius: 8px;
  }
  margin-bottom: 40px;
`;

export const Body = styled.div`
  max-width: 100%;
  margin: 0 auto;
  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: unset;
      .ant-tabs-nav-wrap {
        height: 66px;
        .ant-tabs-tab-btn {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
        }
      }
      background-color: inherit;
      box-shadow: rgb(97 156 253 / 20%) 0px -16px 20px -8px,
        rgb(97 156 253 / 20%) 0px 16px 20px -8px !important;
    }
    .ant-tabs-content-holder {
      .ant-tabs-tabpane {
        padding: 40px 0px;
      }
    }
  }
`;

export const WrapSubInfo = styled.div`
  display: flex;
  gap: 20px;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: #080f6c;
    }
  }
`;
