import { FC } from "react";

export const CopyIcon = ({ fill }: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2507 17.2501V3.75H6.75"
      stroke={fill || "#7E00FD"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2505 6.75H3.75V20.25H17.2505V6.75Z"
      stroke={fill || "#7E00FD"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SecondaryCopyIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6654 12.668H5.33203C4.96536 12.668 4.65159 12.5375 4.3907 12.2766C4.12936 12.0153 3.9987 11.7013 3.9987 11.3346V2.0013C3.9987 1.63464 4.12936 1.32064 4.3907 1.0593C4.65159 0.798413 4.96536 0.667969 5.33203 0.667969H9.9987L13.9987 4.66797V11.3346C13.9987 11.7013 13.8683 12.0153 13.6074 12.2766C13.346 12.5375 13.032 12.668 12.6654 12.668ZM9.33203 5.33464V2.0013H5.33203V11.3346H12.6654V5.33464H9.33203ZM2.66536 15.3346C2.2987 15.3346 1.98492 15.2042 1.72403 14.9433C1.4627 14.682 1.33203 14.368 1.33203 14.0013V4.66797H2.66536V14.0013H9.9987V15.3346H2.66536ZM5.33203 2.0013V5.33464V2.0013V11.3346V2.0013Z"
        fill="#292E73"
        fillOpacity="0.42"
      />
    </svg>
  );
};

export const NewCopyIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11133_7883)">
        <path
          d="M2.66667 6H8.66667C9.40305 6 10 6.59695 10 7.33333V13.3333C10 14.0697 9.40305 14.6667 8.66667 14.6667H2.66667C1.93029 14.6667 1.33333 14.0697 1.33333 13.3333V7.33333C1.33333 6.59695 1.93029 6 2.66667 6Z"
          stroke="#080F6C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.668 10.0007H13.3346C13.6883 10.0007 14.0274 9.86018 14.2774 9.61013C14.5275 9.36008 14.668 9.02094 14.668 8.66732V2.66732C14.668 2.3137 14.5275 1.97456 14.2774 1.72451C14.0274 1.47446 13.6883 1.33398 13.3346 1.33398H7.33464C6.98101 1.33398 6.64187 1.47446 6.39183 1.72451C6.14178 1.97456 6.0013 2.3137 6.0013 2.66732V3.33398"
          stroke="#080F6C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11133_7883">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(-1 0 0 1 16 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DownFileIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
      stroke="#080F6C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66699 6.66602L8.00033 9.99935L11.3337 6.66602"
      stroke="#080F6C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10V2"
      stroke="#080F6C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SendIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9885_10159)">
      <path
        d="M14.6663 1.33398L7.33301 8.66732"
        stroke="#49B67A"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6663 1.33398L9.99967 14.6673L7.33301 8.66732L1.33301 6.00065L14.6663 1.33398Z"
        stroke="#49B67A"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9885_10159">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
