import { DatePicker } from "antd";
import styled from "styled-components";

export const ModalWrap = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: auto;
  position: relative;
  padding: 24px 24px;
  background: rgba(156, 138, 193, 0.04);
  border: 1px solid rgba(41, 46, 115, 0.12);
  margin-top: 66px;
  & > svg {
    position: absolute;
    right: 24px;
    top: 24px;
  }
  #override {
    grid-template-columns: 1fr !important;
    margin-top: 12px;
  }

  & > div:nth-child(2) {
    margin-top: 0 !important;
    margin-bottom: 48px !important;
  }
  button {
    margin-right: 0;
    margin-left: auto;
  }
`;
export const SocialFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  grid-gap: 0 1.25rem;
  margin-top: 2rem;
`;

export const Title = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #080f6c;
`;
export const DatePickerAtnd = styled(DatePicker)`
  max-width: 46.625rem;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;
