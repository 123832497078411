import { useMutation } from "@tanstack/react-query";
import { Form, message, Upload, UploadProps } from "antd";
import { postUploadsAPI } from "api/users";
import { githubRequest } from "api/users/githubRequest";
import {
  CloseIcon,
  CloudSaveIcon,
  DownloadIcon,
  GithubIcon,
  InfoIcon,
  StarIcon,
} from "assets/images/Bounty";
import { PurpleAttachIcon2 } from "assets/images/deliver-work";
import axios, { AxiosError } from "axios";
import Button from "components/base/button";
import PrimaryButton from "components/base/primaryButton";
import { IWorktaskDetailsProps } from "pages/Bounty/Bounty.props";
import {
  AttachPopupWrap,
  BountyInformationItem,
  DragAndDropFileWrap,
  FileAttachWrap,
  FilesWrap,
  FlexWrap,
  LabelWithIcon,
  ResourcesFieldWrap,
  StyledBountyInput,
  StyledBountyTextArea,
  StyledFormItem,
  UploadBtn,
} from "pages/Bounty/Bounty.style";
import {
  ButtonAntd,
  ButtonWrap,
  ResourcesWrap,
} from "pages/DeliverWork/components/DeliverCollaborators/DeliverCollaborators.style";
import { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  addStepSubmit,
  setCreateCustomWorkTask,
  setImportFromGithub,
  setPageLoading,
  setResourceFiles,
} from "redux/slices";
import { acceptDocument, validateDoc } from "utils/regex";
import { requiredRules, stringRequired, urlRules } from "utils/validatorRules";

const WorktaskDetails: FC<IWorktaskDetailsProps> = ({
  worktaskDetails,
  isWorktaskLoading,
  editMode,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const {
    githubIssueURL,
    importName,
    importDescription,
    name,
    description,
    link,
    resourceFiles,
    acceptanceCriteria,
    reference,
    context,
    isCustom,
  } = useAppSelector((state) => {
    return state.addWorktask;
  });

  const [openAttach, setOpenAttach] = useState<boolean>(false);
  const [bountyInfoOption, setBountyInfoOption] = useState<number>(
    isCustom ? 2 : 1
  );

  const importFromGithubSubmit = useCallback(async () => {
    const validation = await form
      .validateFields(["githubIssueURL", "description", "name"])
      .then((values) => {
        return true;
      })
      .catch((errorInfo) => {
        return false;
      });

    if (!validation) return false;

    const githubIssueURL = form.getFieldValue("githubIssueURL");
    const importDescription = form.getFieldValue("description");
    const importName = form.getFieldValue("name");

    const payload = { githubIssueURL, importDescription, importName };

    dispatch(setImportFromGithub(payload));

    return true;
  }, [dispatch, form]);

  const createCustomWorktaskSubmit = useCallback(async () => {
    const validation = await form
      .validateFields([
        "githubIssueURL",
        "description",
        "name",
        "link",
        "context",
        "acceptanceCriteria",
        "reference",
      ])
      .then((values) => {
        return true;
      })
      .catch((errorInfo) => {
        return false;
      });

    if (!validation) return false;

    setCustomWorkTaskToRedux();

    return true;

    // eslint-disable-next-line
  }, [dispatch, form, resourceFiles]);

  useEffect(() => {
    if (editMode && isWorktaskLoading) return;

    if (bountyInfoOption === 1) {
      form.setFieldValue(
        "githubIssueURL",
        githubIssueURL || worktaskDetails?.githubIssueURL
      );
      form.setFieldValue("name", importName || worktaskDetails?.name);
      form.setFieldValue(
        "description",
        importDescription || worktaskDetails?.description
      );
      if (
        importName ||
        worktaskDetails?.name ||
        importDescription ||
        worktaskDetails?.description
      )
        form.validateFields(["name", "description"]);

      dispatch(addStepSubmit({ stepSubmit: importFromGithubSubmit }));
    } else if (bountyInfoOption === 2) {
      form.setFieldValue("link", link || worktaskDetails?.link);
      form.setFieldValue("name", name || worktaskDetails?.name);
      form.setFieldValue(
        "description",
        description || worktaskDetails?.description
      );
      form.setFieldValue("context", context || worktaskDetails?.context);
      form.setFieldValue("reference", reference || worktaskDetails?.reference);
      form.setFieldValue(
        "acceptanceCriteria",
        acceptanceCriteria || worktaskDetails?.acceptanceCriteria
      );

      dispatch(addStepSubmit({ stepSubmit: createCustomWorktaskSubmit }));
    }
  }, [
    acceptanceCriteria,
    bountyInfoOption,
    context,
    createCustomWorktaskSubmit,
    description,
    dispatch,
    editMode,
    form,
    githubIssueURL,
    importDescription,
    importFromGithubSubmit,
    importName,
    isWorktaskLoading,
    link,
    name,
    reference,
    worktaskDetails,
  ]);

  async function githubIssueURLOnBlur() {
    const githubIssueURL = form.getFieldValue("githubIssueURL") || "";
    const url = githubIssueURL.split("github.com/")[1] || "";
    const ERR_URL_GITHUB = "Get github issue url err";

    if (githubIssueURL) {
      try {
        const result = await githubRequest(url);
        if (result?.data) {
          form.setFieldValue("name", result?.data?.title);
          form.setFieldValue("description", result?.data?.body);
          form.validateFields(["githubIssueURL", "description", "name"]);
          // setIsImportFromGithubData(true);
        } else {
          form.setFieldValue("name", undefined);
          form.setFieldValue("description", undefined);
          // setIsImportFromGithubData(false);
          message.error(ERR_URL_GITHUB);
        }
      } catch (err: any) {
        form.setFieldValue("name", undefined);
        form.setFieldValue("description", undefined);
        // setIsImportFromGithubData(false);
        message.error(ERR_URL_GITHUB);
      }
    }
  }

  const [uploadFile, setUploadFile] = useState<any>(null);
  const upLoadProps: UploadProps = {
    accept: acceptDocument,
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isImage = validateDoc.test(file.name);
      if (!isImage) {
        message.error("You can only upload Document files!");
      }
      if (file.size > 5000000) {
        message.error("File is too big");
      } else {
        setUploadFile(file);
      }
      return false;
    },
  };

  const { mutate: postUploads } = useMutation(postUploadsAPI, {
    onSuccess: async (rsUpload: any) => {
      await axios.put(rsUpload.signedRequest, uploadFile).then((rs: any) => {
        const newResourceFiles = [
          ...(resourceFiles || []),
          {
            fileName: uploadFile.name,
            fileLink: rsUpload.url,
          },
        ];

        setCustomWorkTaskToRedux();
        dispatch(setResourceFiles({ resourceFiles: newResourceFiles }));
        dispatch(setPageLoading(false));
        setUploadFile(undefined);
      });
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
      dispatch(setPageLoading(false));
    },
  });

  function uploadFileToS3() {
    if (!uploadFile) return;
    dispatch(setPageLoading(true));
    setOpenAttach(false);
    postUploads({
      fileName: uploadFile.name,
      fileType: uploadFile.type,
    });
  }

  const setCustomWorkTaskToRedux = (isReset = false) => {
    const description = form.getFieldValue("description");
    const name = form.getFieldValue("name");
    const link = form.getFieldValue("link");
    const context = form.getFieldValue("context");
    const acceptanceCriteria = form.getFieldValue("acceptanceCriteria");
    const reference = form.getFieldValue("reference");

    const payload = {
      description,
      name,
      link,
      context,
      acceptanceCriteria,
      reference,
      resourceFiles,
    };

    dispatch(setCreateCustomWorkTask(isReset ? {} : payload));
  };

  return (
    <>
      <StyledFormItem
        rules={[requiredRules]}
        label={<LabelWithIcon>Bounty Information</LabelWithIcon>}
      >
        <FlexWrap gap={20}>
          <BountyInformationItem
            className={bountyInfoOption === 1 ? "active-bounty-info-item" : ""}
            onClick={() => {
              if (editMode) return;
              setBountyInfoOption(1);
              setCustomWorkTaskToRedux(true);
            }}
          >
            <span>
              <GithubIcon />
            </span>
            <div>
              <span>Import from Github</span>
              <div>Import details from a Github Issue</div>
            </div>
          </BountyInformationItem>
          <BountyInformationItem
            className={bountyInfoOption === 2 ? "active-bounty-info-item" : ""}
            onClick={() => {
              if (editMode) return;
              setBountyInfoOption(2);
            }}
          >
            <span>
              <StarIcon />
            </span>
            <div>
              <span>Create Custom WorkTask</span>
              <div>Create a new bounty from scratch</div>
            </div>
          </BountyInformationItem>
        </FlexWrap>
      </StyledFormItem>
      {!!bountyInfoOption && (
        <Form form={form} layout="vertical" scrollToFirstError>
          {bountyInfoOption === 1 && (
            <FlexWrap margin="28px 0px 0px">
              <StyledFormItem
                name="githubIssueURL"
                rules={[requiredRules, urlRules]}
                label={
                  <LabelWithIcon gap={6}>
                    GitHub Issue URL <InfoIcon />
                  </LabelWithIcon>
                }
              >
                <StyledBountyInput
                  placeholder=""
                  onBlur={() => githubIssueURLOnBlur()}
                  onKeyUp={(e) => {
                    if (e.code === "Enter") githubIssueURLOnBlur();
                  }}
                />
              </StyledFormItem>
            </FlexWrap>
          )}
          <FlexWrap
            gap={bountyInfoOption === 1 ? 0 : 20}
            columns={bountyInfoOption === 1 ? 1 : 2}
            margin="28px 0px 0px"
          >
            <StyledFormItem
              name="name"
              rules={[stringRequired]}
              label={
                <LabelWithIcon gap={6}>
                  Title <InfoIcon />
                </LabelWithIcon>
              }
            >
              <StyledBountyInput
                placeholder=""
                disabled={bountyInfoOption === 1}
              />
            </StyledFormItem>
            {bountyInfoOption === 2 && (
              <StyledFormItem
                name="link"
                rules={[requiredRules, urlRules]}
                label={
                  <LabelWithIcon gap={6}>
                    Show as Organization <InfoIcon />
                  </LabelWithIcon>
                }
              >
                <StyledBountyInput placeholder="Github.com" />
              </StyledFormItem>
            )}
          </FlexWrap>
          <FlexWrap margin="28px 0px 0px">
            <StyledFormItem
              name="description"
              rules={[stringRequired]}
              label={
                <LabelWithIcon gap={6}>
                  Description <InfoIcon />
                </LabelWithIcon>
              }
            >
              <StyledBountyTextArea
                placeholder="Describe your project here"
                disabled={bountyInfoOption === 1}
              />
            </StyledFormItem>
          </FlexWrap>
          {bountyInfoOption === 2 && (
            <>
              <FlexWrap margin="28px 0px 0px">
                <StyledFormItem
                  name="context"
                  rules={[stringRequired]}
                  label={
                    <LabelWithIcon gap={6}>
                      What you will be working on <InfoIcon />
                    </LabelWithIcon>
                  }
                >
                  <StyledBountyTextArea placeholder="Please provide further context about the task and how you are envisioning the collaborators to deliver" />
                </StyledFormItem>
              </FlexWrap>
              <FlexWrap margin="28px 0px 0px">
                <StyledFormItem
                  name="acceptanceCriteria"
                  rules={[stringRequired]}
                  label={
                    <LabelWithIcon gap={6}>
                      Acceptance Criteria <InfoIcon />
                    </LabelWithIcon>
                  }
                >
                  <StyledBountyTextArea placeholder="Please provide the minimum acceptance criteria to consider a deliverable as a successful" />
                </StyledFormItem>
              </FlexWrap>
              <ResourcesFieldWrap margin="28px 0px 0px">
                <StyledFormItem
                  label={
                    <LabelWithIcon gap={6}>
                      Resources <InfoIcon />
                    </LabelWithIcon>
                  }
                >
                  <StyledFormItem name="reference">
                    <StyledBountyTextArea
                      placeholder="Please include references, inspirational material, or other useful resources"
                      className="padding-bottom"
                    />
                  </StyledFormItem>
                </StyledFormItem>
                <FilesWrap>
                  {resourceFiles && resourceFiles?.length > 0 && (
                    <ResourcesWrap>
                      {resourceFiles.map((file: any, i: number) => (
                        <FileAttachWrap key={i}>
                          <ButtonAntd>
                            <div>
                              <PurpleAttachIcon2 />
                              {file.fileName}
                            </div>
                          </ButtonAntd>
                          <span
                            onClick={() => {
                              const newResourceFiles = resourceFiles.filter(
                                (f: any) => file.fileLink !== f.fileLink
                              );
                              setCustomWorkTaskToRedux();
                              dispatch(
                                setResourceFiles({
                                  resourceFiles: newResourceFiles,
                                })
                              );
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </FileAttachWrap>
                      ))}
                    </ResourcesWrap>
                  )}
                  <UploadBtn className="upload-file">
                    <div
                      onClick={() => {
                        setOpenAttach(true);
                      }}
                    >
                      Attach a file <DownloadIcon />
                    </div>
                  </UploadBtn>
                </FilesWrap>

                {openAttach && (
                  <AttachPopupWrap width={448} height={322}>
                    <div>
                      <div className="line-height-160">Upload a file</div>

                      <Upload {...upLoadProps} className="upload-antd-wrap">
                        <DragAndDropFileWrap>
                          <CloudSaveIcon />
                          <span>
                            {uploadFile ? (
                              <strong>{uploadFile.name}</strong>
                            ) : (
                              <>
                                <strong>Click to upload</strong> or Drag & Drop
                              </>
                            )}
                          </span>
                        </DragAndDropFileWrap>
                      </Upload>
                      <ButtonWrap>
                        <Button
                          width="192px"
                          height="48px"
                          color="rgba(41, 46, 115, 0.42)"
                          background="#FFF"
                          border="1px solid rgba(41, 46, 115, 0.12)"
                          onClick={() => {
                            setOpenAttach(false);
                            setUploadFile(undefined);
                          }}
                        >
                          Cancel
                        </Button>
                        <PrimaryButton
                          width="192px"
                          height="48px"
                          onClick={() => uploadFileToS3()}
                        >
                          Attach Files
                        </PrimaryButton>
                      </ButtonWrap>
                    </div>
                  </AttachPopupWrap>
                )}
              </ResourcesFieldWrap>
            </>
          )}
        </Form>
      )}
    </>
  );
};

export default WorktaskDetails;
