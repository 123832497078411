import { useQuery } from "@tanstack/react-query";
import { getProjectTransactionsSummary } from "api/projectDetail";
import { FC } from "react";
import { TransactionProps } from "../OrganizationPage.props";
import {
  BlockChainName,
  BlurText,
  CommonFlexColumn,
  OverviewItem,
  OverviewWrapper,
  SectionTitle,
  WalletItemTitle,
} from "./transaction.style";

const Overview: FC<TransactionProps> = ({ project }) => {
  const { data: tokenNameRes, isLoading } = useQuery(
    [`getTransactionsBy${project?._id}`, project?._id],
    () => getProjectTransactionsSummary(project?._id as string),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <OverviewWrapper space="32px">
        <SectionTitle>Loading...</SectionTitle>
      </OverviewWrapper>
    );
  }

  const overviewData = tokenNameRes?.responseData;
  const symbolOrName =
    overviewData?.tokenSymbol?.slice(0, 5) || overviewData?.tokenName;

  return (
    <OverviewWrapper space="32px">
      <CommonFlexColumn space="20px">
        <SectionTitle>Current Available</SectionTitle>
        <OverviewItem>
          <BlockChainName>{symbolOrName}</BlockChainName>
          <CommonFlexColumn space="4px">
            <BlurText textSize="14px">Network</BlurText>
            <BlockChainName>
              {overviewData?.blockchainNetwork?.name}
            </BlockChainName>
          </CommonFlexColumn>
          <CommonFlexColumn space="4px">
            <BlurText textSize="14px">Current Available</BlurText>
            <WalletItemTitle textSize="18px">
              {overviewData?.totalRemainingByToken} {symbolOrName}
            </WalletItemTitle>
          </CommonFlexColumn>
        </OverviewItem>
      </CommonFlexColumn>
      <CommonFlexColumn space="20px">
        <SectionTitle>Current Spend</SectionTitle>
        <OverviewItem>
          <BlockChainName>{symbolOrName}</BlockChainName>
          <CommonFlexColumn space="4px">
            <BlurText textSize="14px">Network</BlurText>
            <BlockChainName>
              {overviewData?.blockchainNetwork?.name}
            </BlockChainName>
          </CommonFlexColumn>
          <CommonFlexColumn space="4px">
            <BlurText textSize="14px">Current Spend</BlurText>
            <WalletItemTitle textSize="18px">
              {overviewData?.currentSpent} {symbolOrName}
            </WalletItemTitle>
          </CommonFlexColumn>
        </OverviewItem>
      </CommonFlexColumn>
      <CommonFlexColumn space="20px">
        <SectionTitle>Protocol Collected Fees</SectionTitle>
        <OverviewItem>
          <BlockChainName>{symbolOrName}</BlockChainName>
          <CommonFlexColumn space="4px">
            <BlurText textSize="14px">Network</BlurText>
            <BlockChainName>
              {overviewData?.blockchainNetwork?.name}
            </BlockChainName>
          </CommonFlexColumn>
          <CommonFlexColumn space="4px">
            <BlurText textSize="14px">Protocol Collected Fees</BlurText>
            <WalletItemTitle textSize="18px">
              {overviewData?.protocolCollectedFees} {symbolOrName}
            </WalletItemTitle>
          </CommonFlexColumn>
        </OverviewItem>
      </CommonFlexColumn>
    </OverviewWrapper>
  );
};

export default Overview;
