import styled from "styled-components";
import headingBG from "assets/images/heading_small_bg.png";
import { Button, Input } from "antd";
import PrimaryButton from "components/base/primaryButton";

export const Container = styled.div`
  min-height: 50vh;
`;
export const Heading = styled.div`
  background-image: url(${headingBG});
  height: 56px;
  & > div {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1170px;
    margin: 0 auto;
  }
`;

export const OutlineButton = styled(Button)`
  display: flex;
  padding: 8px 12px;
  gap: 4px;
  background: #ffffff;
  border-radius: 4px;
  span {
    margin-left: unset !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 115%;
    color: #080f6c;
  }
`;

export const WrapOption = styled.div`
  display: flex;
  gap: 10px;
  .ant-select {
    min-width: 150px;
  }
`;

export const WrapEditor = styled.div`
  max-width: 1170px;
  margin: 10px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
`;

export const WrapRequest = styled.div`
  section {
    border: 1px solid rgba(41, 46, 115, 0.12);
    border-radius: 8px;
    overflow: hidden;
    .monaco-editor {
      padding: 16px 0px;
      div.monaco-scrollable-element {
        height: 529px !important;
      }
    }
    height: 564px !important;
  }
`;

export const WrapResponse = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ShowOutPut = styled(Input.TextArea)`
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 8px;
  background: #080f6c;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #ffffff !important;
  padding: 16px 20px;
  height: 50% !important;
  min-height: 300px !important;
`;

export const CustomInput = styled(Input.TextArea)`
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 8px;
  background: #080f6c;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #ffffff !important;
  padding: 16px 20px;
  height: 15% !important;
  min-height: 90px !important;
`;

export const WrapLabel = styled.div`
  display: grid;
  gap: 7px;
`;
export const Label = styled.div`
  display: flex;
  gap: 7px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  span {
    display: flex;
    align-items: center;
  }
`;

export const Border = styled.span`
  border-radius: 10px;
  background-color: rgb(243 244 246 / 1);
  font-weight: 600;
  padding: 4px 10px;
`;

export const ButtonRun = styled(PrimaryButton)`
  padding: 0px 10px;
`;
export const ButtonSubmit = styled(PrimaryButton)`
  padding: 9px 10px;
  float: right;
  margin-top: 10px;
`;
