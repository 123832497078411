import styled from "styled-components";
import { Input } from "antd";
// import FlexBox from "components/base/Flexbox";
import { CommonGradientContainer } from "pages/ProfileUser/profileUserInner.style";

// export const LinkSocialContainerTop = styled(FlexBox)`
//   padding: 0 24px 0 0;
//   margin-bottom: 1.5rem;
//   align-items: flex-start;
//   .ant-form {
//     width: 100%;
//     padding-left: 2rem;
//   }
// `;

// export const Heading = styled(FlexBox)`
//   justify-content: space-between;
//   margin-bottom: 40px;
//   margin-top: 6px;
// `;

// export const Title = styled.div`
//   font-family: "DM Sans";
//   font-style: normal;
//   font-weight: 700;
//   font-size: 24px;
//   line-height: 125%;
//   display: flex;
//   align-items: center;
//   letter-spacing: -0.04em;
//   color: #080f6c;
//   margin-left: 1rem;
// `;

export const StyledInput = styled(Input)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

// export const StyledFormItem = styled(FormItem)`
//   label {
//     font-family: "DM Sans";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 1rem;
//     line-height: 100%;
//     letter-spacing: -0.01em;
//     color: #080f6c;
//   }
//   .ant-form-item-label {
//     padding-bottom: 0.35rem;
//   }
//   margin-bottom: 18px;
// `;

export const SocialGrid = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 20px;
  padding: 22px;
  background: white;
`;

export const LinkSocialContainer = styled(CommonGradientContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 20px;
  padding: 22px;
  background: white;
  margin-bottom: 48px;
`;
