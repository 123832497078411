import { FC } from 'react'
import { IButtonOutlinedProps } from './outlinedButton.props'
import { ButtonOutlinedAntd } from './outlinedButton.style'

const OutlinedButton: FC<IButtonOutlinedProps> = ({
  children,
  width,
  height,
  color,
  ...props
}: IButtonOutlinedProps) => {
  return (
    <ButtonOutlinedAntd {...props} width={width} height={height} color={color}>
      {children}
    </ButtonOutlinedAntd>
  )
}

export default OutlinedButton
