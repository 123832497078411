import BaseButton from "components/base/baseButton";
import styled from "styled-components";
import { Input, Select, Upload } from "antd";
import OutlinedButton from "components/base/outlinedButton";

export const CoverImage = styled.img``;

export const Container = styled.div`
  .upload-image {
    .ant-upload {
      width: 100%;
      height: unset;
    }
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
    }
  }
`;

export const StyledInput = styled(Input)`
  max-width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 8.125rem !important;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const BigLabel = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-bottom: 0.3rem;
`;

export const LabelDescription = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  color: rgba(41, 46, 115, 0.82);
  opacity: 0.6;
  margin-bottom: 1rem;
`;

export const StyledUpload = styled(Upload)`
  .ant-upload-list {
    width: 100%;
    .ant-upload-list-picture-card-container {
      display: block;
      margin: auto;
    }
    .ant-upload-list-picture-card-container {
      width: 18.75rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .ant-upload-list-item {
      border: 0.0625rem solid rgba(41, 46, 115, 0.22);
    }
  }
  .ant-upload {
    width: 100%;
    height: 8rem;
    &.ant-upload-select-picture-card {
      border-radius: 0.25rem;
      border: 0.0625rem dashed rgba(41, 46, 115, 0.12);
    }
  }
  &.noBorder {
    .ant-upload-select-picture-card {
      border: none;
    }
  }
`;

export const RemoveBtn = styled(OutlinedButton)`
  height: 2.25rem;
  margin-top: 30px;
  svg {
    margin-right: 5px;
    path {
      fill: #080f6c;
    }
  }
`;

export const NoFileText = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  margin-left: 0.9rem;
`;

export const StyledSelect = styled(Select)`
  max-width: 100%;
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 2.25rem;
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  svg {
    margin-right: 0.3rem;
  }
  .ant-select-selection-placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #080f6c;
  }
  .ant-select-selection-item {
    padding-top: 0.1875rem !important;
  }
  .ant-select-arrow {
    svg {
      margin-right: 0;
    }
  }
  &.ant-select-open {
    border: 1px solid #7e00fd;
    border-radius: 4px 4px 0px 0px;
    .ant-select-arrow {
      transform: rotate(180deg);
      path {
        stroke: #7e00fd;
      }
    }
  }
`;

export const ChooseImageButton = styled(BaseButton)`
  width: 9.5rem;
  height: 2.75rem;
`;

export const AddAttributeBtn = styled(OutlinedButton)`
  width: 100%;
  margin-bottom: 30px;
`;

export const RemoveAttributeBtn = styled(OutlinedButton)`
  margin-top: 30px;
`;
