import { INFTMetadata } from "api/course";
import { create } from "ipfs-http-client";
import { Buffer } from "buffer";

const ipfsProtocol = "ipfs://";

const infura_auth =
  "Basic " +
  Buffer.from(
    process.env.REACT_APP_IPFS_INFURA_PROJECT_ID +
      ":" +
      process.env.REACT_APP_IPFS_INFURA_PROJECT_SECRET
  ).toString("base64");

export const ipfs = create({
  url: process.env.REACT_APP_IPFS_GATEWAY_URL,
  headers: {
    Authorization: infura_auth,
    // "Access-Control-Allow-Origin": "*",
  },
});

export const getIPFSUrlFromPath = (path: string) => {
  const url = `${ipfsProtocol}${path}`;
  return url;
};

export const uploadFile = async (file: File) => {
  return await ipfs.add(file);
};

export const uploadJsonObjectFile = async (obj: object) => {
  const blob = new Blob([JSON.stringify(obj)], {
    type: "text/plain",
  });
  const file = new File([blob], `${Math.random() * 10}.json`);
  return uploadFile(file);
};

export const uploadNFTMetadata = async (metadata: INFTMetadata) => {
  const result = await uploadJsonObjectFile(metadata);
  return {
    ipfsUrl: getIPFSUrlFromPath(result.path),
    ...result,
  };
};
