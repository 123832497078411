import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #0f0f0fa1;
  opacity: 1;
  position: fixed;
  top: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    text-align: center;
  }
  padding-bottom: 20vh;
  svg {
    display: block;
    margin-bottom: 10px;
  }
`;
