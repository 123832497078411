import { Col, Collapse, Row } from "antd";
import ContainerFluidCustom from "components/base/containerFluidCustom";
import styled from "styled-components";

export const Container = styled.div``;

export const ContainerBodyInitiator = styled(Collapse)`
  max-width: 73.125rem;
  margin: auto;
  margin-top: 56px;
  margin-bottom: 100px;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-left: none;
  border-right: none;
  background: none;
  .ant-collapse-item {
    padding: 0px;
    .ant-collapse-header {
      padding: 27px 0px 27px 0px;
    }
  }
  .ant-collapse-content-box {
    padding: 0px;
  }
`;

export const PanelCollapse = styled(Collapse.Panel)`
  .ant-collapse-content-active {
    border-top: none;
  }
  .ant-collapse-header-text {
    cursor: pointer;
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    font-style: "Medium";
    color: rgba(8, 15, 108, 1);
    letter-spacing: -0.02em;
  }
  .ant-collapse-arrow svg {
    color: rgba(126, 0, 253, 1);
    margin: 34px 29px 34px 0px;
  }
`;

export const ContainerProjectSummary = styled.div`
  background: linear-gradient(
    90deg,
    rgba(126, 0, 253, 0.08),
    rgba(193, 67, 252, 0.08),
    rgba(0, 191, 251, 0.08)
  );
  max-width: 73.125rem;
  margin: auto;
  margin-top: 40px;
  height: 120px;
`;

export const ProjectSummaryContent = styled(Row)`
  padding: 32px 33px;
  display: flex;
  justify-content: space-between;
  width: 704px;
`;

export const ProjectSummaryItem = styled(Col)``;

export const TitleHeading = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  color: ${(p) => p.color};
  cursor: pointer;
  font-style: "Regular";
  font-weight: 400;
  font-size: 13px;
  font-family: "Rubik";
  line-height: 13px;
  letter-spacing: -0.01em;
  svg {
    margin-right: 4px;
  }
`;

export const ContentHeading = styled.p`
  cursor: pointer;
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  font-style: "Bold";
  color: #383a4f;
  margin: 10px 0px 24px;
  letter-spacing: -0.04em;
`;

export const SeeMore = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 115%;
  color: #7e00fd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0rem;
  cursor: pointer;
`;
