import ContainerFluidCustom from "components/base/containerFluidCustom";
import styled from "styled-components";

export const ErrorContainer = styled(ContainerFluidCustom)`
  text-align: center;
  height: 238px;
  padding-top: 30px;
  img {
    height: 136px;
    width: 136px;
  }
`;

export const Content = styled.p`
  font-family: "DM Sans";
  font-style: "Regular";
  font-weight: 400;
  font-size: 20px;
  line-height: 156%;
  text-align: center;
  color: #292E73;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
