export const BlockChainIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6671 6.50072V13.6452L8.55599 16.4674V8.24517L13.0449 6.17295C12.6398 5.99754 12.2648 5.75944 11.9338 5.46739L8.00044 7.2785L2.10599 4.55628L8.00044 1.83406L10.5615 3.01739C10.5214 2.79181 10.501 2.56318 10.5004 2.33406C10.5018 2.14824 10.5148 1.96269 10.5393 1.7785L8.23377 0.717391C8.16064 0.683546 8.08102 0.666016 8.00044 0.666016C7.91985 0.666016 7.84023 0.683546 7.7671 0.717391L0.54488 4.05072C0.448447 4.09535 0.366835 4.1667 0.309723 4.25631C0.252611 4.34591 0.222395 4.45002 0.222658 4.55628V14.0007C0.222395 14.107 0.252611 14.2111 0.309723 14.3007C0.366835 14.3903 0.448447 14.4617 0.54488 14.5063L7.7671 17.8396C7.84023 17.8735 7.91985 17.891 8.00044 17.891C8.08102 17.891 8.16064 17.8735 8.23377 17.8396L15.456 14.5063C15.5524 14.4617 15.634 14.3903 15.6911 14.3007C15.7483 14.2111 15.7785 14.107 15.7782 14.0007V6.34517C15.4167 6.44776 15.0429 6.5001 14.6671 6.50072Z"
        fill="#7E00FD"
      />
    </svg>
  );
};
