import PrimaryButton from "components/base/primaryButton";
import { Above, Below, Container, SmallCards } from "./ourRecentBlogs.styles";
import { BigCard, SmallCard } from "./cardItems";
import { IGetBlogListReq, IGetBlogListRes, getBlogListAPI } from "api/blog";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { ROUTER_CONSTANTS } from "utils/constant";
import { useNavigate } from "react-router-dom";

export const OurRecentBlogs = () => {
  const initParams: IGetBlogListReq = useMemo(
    () => ({
      categories: [],
      page: 1,
      pageSize: 5,
      search: "",
      sort: -1,
      pageSizeAtFirstPage: 5,
    }),
    []
  );

  const { data } = useQuery(
    ["get-blog-list", initParams],
    () => getBlogListAPI(initParams),
    {
      select: (data) =>
        data && data.responseCode === 200
          ? (data.responseData as IGetBlogListRes)
          : undefined,
      refetchOnWindowFocus: true,
    }
  );
  const navigate = useNavigate();
  return (
    <Container>
      <Above>
        <h1>
          Our <br /> Recent Updates
        </h1>
        <PrimaryButton
          width={"146px"}
          height={"56px"}
          onClick={() => navigate(ROUTER_CONSTANTS.BLOG.LIST_BLOG)}
        >
          View More
        </PrimaryButton>
      </Above>
      {data && (
        <Below>
          {data.data.slice(0, 2).map((item) => (
            <BigCard blog={item} key={item._id} />
          ))}
          <SmallCards>
            {data.data.slice(2).map((item) => (
              <SmallCard blog={item} key={item._id} />
            ))}
          </SmallCards>
        </Below>
      )}
    </Container>
  );
};
