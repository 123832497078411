import { FC, memo } from "react";
import { ITitleAndContextProps } from "./titleAndContext.props";
import { Container, Context, Title } from "./titleAndContext.style";

const TitleAndContext: FC<ITitleAndContextProps> = (
  props: ITitleAndContextProps
) => {
  const { title, context } = props;
  return (
    <Container>
      <Title >{title}</Title>
      <Context title={context}>{context}</Context>
    </Container>
  );
};

export default memo(TitleAndContext);
