import { FC, memo, useEffect, useMemo, useState } from "react";
import { IPathProps } from "./path.props";
import { Tree } from "antd";
import {
  BackAndNext,
  CkContentWrapper,
  Container,
  Content,
  EditButton,
  NextActive,
  Previous,
  PreviousActive,
  SubmitOrNext,
  WrapButtons,
  WrapFoot,
  WrapTree,
} from "./path.style";
import { ACTIVITY_TYPE, IActivity, ISection } from "api/course";
import DOMPurify from "dompurify";
import {
  NextIcon,
  PreviousIcon,
} from "assets/icons/common/PreviousAndNextIcon";
import { PencilIcon } from "assets/icons/pencil.icon";
import { ModalCustom } from "pages/CreateCourse/CourseCustomisation/courseCustomisation.style";
import {
  IActivityModal,
  MODAL_TYPE,
} from "pages/CreateCourse/CourseCustomisation/courseCustomisation.props";
import CreateSection from "pages/CreateCourse/CourseCustomisation/ContentInModal/CreateSection";
import ActivityTemplate from "pages/CreateCourse/CourseCustomisation/ContentInModal/ActivityTemplate";
import { setCourseSections } from "redux/slices";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";

const { DirectoryTree } = Tree;

const initModal = {
  sectionId: -1,
  activityId: -1,
  type: null,
};
const Path: FC<IPathProps> = ({ course }) => {
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([0]);
  const [sectionSelected, setSectionSelected] = useState<ISection>();
  const [activitySelected, setActivitySelected] = useState<IActivity>();
  const [actionModal, setActionModal] = useState<IActivityModal>(initModal);
  const dispatch = useDispatch();

  const treeData = useMemo(() => {
    if (course && course.sections.length > 0)
      return course.sections.map((section: ISection, indexSection: number) => ({
        title: section.title,
        className: "section",
        key: indexSection,
        children: section.activities.map(
          (activity: IActivity, indexAct: number) => ({
            className: "activity",
            title: activity.title,
            key: `${indexSection}-${indexAct}`,
            isLeaf: true,
          })
        ),
      }));
  }, [course]);

  useEffect(() => {
    setSelectedKeys((prev: any) => [...prev]);
  }, [course]);

  useEffect(() => {
    if (selectedKeys && selectedKeys.length > 0) {
      const arr = selectedKeys[0].toString().split("-");
      // children
      if (arr.length === 2) {
        setActivitySelected(course.sections[arr[0]].activities[arr[1]]);
      }
      //Parent
      else {
        const section = course.sections[arr[0]];
        setSectionSelected(section);
        setActivitySelected(undefined);
      }
    }
  }, [selectedKeys]);

  const keyList = useMemo(() => {
    if (treeData && treeData.length > 0) {
      return treeData?.reduce((acc: any, parent: any) => {
        return [
          ...acc,
          parent.key,
          ...parent.children.map((child: { key: any }) => child.key),
        ];
      }, []);
    }
  }, [treeData]);

  const handleCloseModal = () => {
    setActionModal(initModal);
  };

  const modalRender = (type: MODAL_TYPE | ACTIVITY_TYPE | null) => {
    switch (type) {
      case MODAL_TYPE.SECTION:
        return (
          <CreateSection
            section={sectionSelected!}
            handleSubmitModal={handleSaveSection}
            handleCancel={handleCloseModal}
          />
        );
      case MODAL_TYPE.ACTIVITY:
        return (
          <ActivityTemplate
            handleSubmitModal={handleSaveActitvity}
            activity={activitySelected!}
            handleCancel={handleCloseModal}
          />
        );
      default:
        return <div />;
    }
  };

  const handleSaveSection = (values: ISection) => {
    const newSection = structuredClone(course.sections);
    newSection[actionModal.sectionId] = {
      ...newSection[actionModal.sectionId],
      ...values,
    };
    dispatch(setCourseSections(newSection));
    setActionModal(initModal);
  };

  const handleSaveActitvity = (values: IActivity) => {
    const newSection = structuredClone(course.sections);
    newSection[actionModal.sectionId].activities[actionModal.activityId] = {
      ...newSection[actionModal.sectionId].activities[actionModal.activityId],
      ...values,
    };
    dispatch(setCourseSections(newSection));
    setActionModal(initModal);
  };

  const modalTitle = (type: MODAL_TYPE | null) => {
    switch (type) {
      case MODAL_TYPE.ACTIVITY:
        return `Edit activity: ${activitySelected?.title}`;
      case MODAL_TYPE.SECTION:
        return `Edit section: ${sectionSelected?.title}`;
      default:
        return <></>;
    }
  };
  return (
    <Container>
      <WrapTree>
        <WrapButtons>
          <EditButton
            onClick={() => {
              const arr = selectedKeys[0].toString().split("-");
              if (arr.length === 2)
                setActionModal({
                  activityId: +arr[1],
                  sectionId: +arr[0],
                  type: MODAL_TYPE.ACTIVITY,
                });
              else
                setActionModal({
                  activityId: -1,
                  sectionId: +arr[0],
                  type: MODAL_TYPE.SECTION,
                });
            }}
            icon={<PencilIcon />}
          >
            Edit
          </EditButton>
          <BackAndNext>
            <PreviousActive
              onClick={() => {
                if (keyList && keyList.length > 0) {
                  const index = keyList?.findIndex(
                    (key) => key === selectedKeys[0]
                  );
                  setSelectedKeys([keyList[index - 1]]);
                }
              }}
              type="text"
              icon={<PreviousIcon />}
              disabled={
                treeData && treeData.length > 0
                  ? selectedKeys[0] === treeData[0].key
                  : true
              }
            >
              Previous
            </PreviousActive>
            <NextActive
              onClick={() => {
                if (keyList && keyList.length > 0) {
                  const index = keyList?.findIndex(
                    (key) => key === selectedKeys[0]
                  );
                  setSelectedKeys([keyList[index + 1]]);
                }
              }}
              type="text"
              icon={<NextIcon />}
              disabled={
                treeData && treeData.slice(-1)[0].children.length > 0
                  ? selectedKeys[0] ===
                    treeData.slice(-1)[0].children.slice(-1)[0].key
                  : true
              }
            >
              Next
            </NextActive>
          </BackAndNext>
        </WrapButtons>
        <DirectoryTree
          multiple
          defaultExpandAll
          treeData={treeData}
          onSelect={(value) => setSelectedKeys(value)}
          selectedKeys={selectedKeys}
        />
      </WrapTree>
      <Content>
        {activitySelected && (
          <div>
            <h1>{activitySelected?.title}</h1>
            <CkContentWrapper className="ck-content">
              {parse(DOMPurify.sanitize(activitySelected?.content ?? ""))}
            </CkContentWrapper>
          </div>
        )}

        {sectionSelected && !activitySelected && (
          <div>
            <h1>{sectionSelected?.title}</h1>
            <CkContentWrapper className="ck-content">
              {parse(DOMPurify.sanitize(sectionSelected?.description ?? ""))}
            </CkContentWrapper>
          </div>
        )}
        <WrapFoot>
          <Previous
            onClick={() => {
              if (keyList && keyList.length > 0) {
                const index = keyList?.findIndex(
                  (key) => key === selectedKeys[0]
                );
                setSelectedKeys([keyList[index - 1]]);
              }
            }}
            disabled={
              treeData && treeData.length > 0
                ? selectedKeys[0] === treeData[0].key
                : true
            }
            icon={<PreviousIcon />}
          >
            Previous
          </Previous>
          <SubmitOrNext
            onClick={() => {
              if (keyList && keyList.length > 0) {
                const index = keyList?.findIndex(
                  (key) => key === selectedKeys[0]
                );
                setSelectedKeys([keyList[index + 1]]);
              }
            }}
            disabled={
              treeData && treeData.slice(-1)[0].children.length > 0
                ? selectedKeys[0] ===
                  treeData.slice(-1)[0].children.slice(-1)[0].key
                : true
            }
            icon={<NextIcon />}
          >
            Next
          </SubmitOrNext>
        </WrapFoot>
      </Content>
      <ModalCustom
        visible={!!actionModal.type}
        centered
        destroyOnClose
        closable
        width={"624px"}
        onCancel={handleCloseModal}
        title={modalTitle(actionModal.type)}
      >
        {modalRender(actionModal.type)}
      </ModalCustom>
    </Container>
  );
};

export default memo(Path);
