import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, message } from "antd";
import {
  IEducation,
  addEducationAPI,
  deleteEducationAPI,
  updateEducationAPI,
} from "api/users";
import { EditIcon } from "assets/icons/common/edit.icon";
import { EducationIcon } from "assets/icons/common/education.icon";
import { PlusIcon } from "assets/icons/common/plus.icon";
import { AxiosError } from "axios";
import PrimaryButton from "components/base/primaryButton";
import moment from "moment";
import { FormSectionWrapper } from "pages/ProfileUser/profileUserInner.style";
import { FC, useState } from "react";
import { convertNumberToMoment } from "utils/timer";
import {
  formatByMonths,
  maxLength_1000,
  maxLength_60,
  requiredRules,
  stringRequired,
  urlRules,
} from "utils/validatorRules";
import SectionHeader from "../SectionHeader";
import { IEducationProps } from "./education.props";
import {
  ActionButton,
  Bottom,
  BoxDesc,
  BoxName,
  BoxText,
  BoxYear,
  BoxYearText,
  BoxYearTextUp,
  DeleteButton,
  Description,
  EditButton,
  EducationCard,
  EducationContainer,
  EducationContainerTop,
  EducationInfoGroup,
  Heading,
  SecondaryButton,
  StyledDatepicker,
  StyledFormItem,
  StyledInput,
  StyledSwitch,
  StyledTextArea,
  SwitchGroup,
  UpdateGroup,
} from "./education.style";

export const Education: FC<IEducationProps> = ({
  educations,
  setEducations,
  setLeaveVisible,
}) => {
  const [form] = Form.useForm();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isCurrentStudy, setIsCurrentStudy] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { mutate: addEducationMutate } = useMutation(addEducationAPI, {
    onSuccess: (res) => {
      if (res.responseCode === 200) {
        setEducations((res as any).responseData);
        setIsAdding(false);
        setIsEditing(false);
        queryClient.invalidateQueries(["processUpdateProfile"]);
        message.success((res as any).responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const { mutate: updateEducationMutate } = useMutation(updateEducationAPI, {
    onSuccess: (res) => {
      if (res.responseCode === 200) {
        setEducations((res as any).responseData);
        setIsAdding(false);
        setIsEditing(false);
        message.success((res as any).responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const { mutate: deleteEducationMutate } = useMutation(deleteEducationAPI, {
    onSuccess: (res) => {
      if (res.responseCode === 200) {
        setEducations((res as any).responseData);
        setIsAdding(false);
        setIsEditing(false);
        queryClient.invalidateQueries(["processUpdateProfile"]);
        message.success((res as any).responseMessage);
      } else message.warning((res as any).responseMessage);
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const handleAddFormEducation = () => {
    setIsAdding((prev) => !prev);
    setIsEditing(false);
    form.setFieldsValue({
      degree: "",
      college: "",
      startDate: "",
      collegeWebsite: "",
      experienceFromCollege: "",
      endDate: "",
      _id: undefined,
    });
    setIsCurrentStudy(false);
  };

  const handleEditFormEducation = (education: IEducation) => {
    setIsAdding(false);
    setIsEditing(true);
    form.setFieldsValue({
      ...education,
      _id: education._id,
      startDate: convertNumberToMoment(education.startDate),
      endDate: education.endDate
        ? convertNumberToMoment(education.endDate)
        : "",
    });
    setIsCurrentStudy(
      typeof education.isCurrentStudy === "boolean" && education.isCurrentStudy
    );
  };

  const onSubmit = ({ startDate, endDate, ...values }: IEducation) => {
    const education = {
      isCurrentStudy: isCurrentStudy,
      startDate: startDate.valueOf(),
      endDate: endDate?.valueOf(),
      ...values,
    };

    setLeaveVisible(true);

    return isEditing
      ? updateEducationMutate({
          educationId: form.getFieldValue("_id"),
          education,
        })
      : addEducationMutate({ education });
  };

  return (
    <FormSectionWrapper id="Education">
      <Heading>
        <SectionHeader icon={<EducationIcon />} title="Education" />
        <PrimaryButton width="156px" onClick={handleAddFormEducation}>
          <PlusIcon />
          Add Education
        </PrimaryButton>
      </Heading>
      {(isAdding || isEditing) && (
        <EducationContainer>
          <EducationContainerTop>
            {/* <div>
              <UniversityIcon />
            </div> */}

            <Form
              form={form}
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onSubmit}
              onValuesChange={() => setLeaveVisible(true)}
            >
              <div>
                <StyledFormItem
                  name="degree"
                  label={<span>Degree</span>}
                  rules={[stringRequired, maxLength_60]}
                >
                  <StyledInput placeholder="Ex. Masters in AI" />
                </StyledFormItem>
                <StyledFormItem
                  name="college"
                  label={<span>University</span>}
                  rules={[stringRequired, maxLength_60]}
                >
                  <StyledInput placeholder="Ex. University of Oxford" />
                </StyledFormItem>
                <StyledFormItem
                  name="collegeWebsite"
                  label={<span>University website</span>}
                  rules={[urlRules]}
                >
                  <StyledInput placeholder="Ex. https:blog.acme.com" />
                </StyledFormItem>
                <StyledFormItem
                  name="experienceFromCollege"
                  label={<span>What did you learned? (One-liner)</span>}
                  rules={[maxLength_1000]}
                >
                  <StyledTextArea placeholder="Some cool stuff." />
                </StyledFormItem>
                <StyledFormItem
                  name="startDate"
                  label={<span>Start</span>}
                  rules={[requiredRules]}
                >
                  <StyledDatepicker
                    placeholder="Ex. mm/yyyy"
                    disabledDate={(current) => current.isAfter(moment())}
                    picker="month"
                    format={formatByMonths}
                  />
                </StyledFormItem>
                {!isCurrentStudy && (
                  <StyledFormItem
                    name="endDate"
                    label={<span>End</span>}
                    rules={[requiredRules]}
                  >
                    <StyledDatepicker
                      placeholder="Ex. mm/yyyy"
                      disabledDate={(current) =>
                        current.isBefore(form.getFieldValue("startDate")) ||
                        current.isAfter(moment())
                      }
                      picker="month"
                      format={formatByMonths}
                    />
                  </StyledFormItem>
                )}
                <Bottom>
                  <SwitchGroup>
                    <StyledSwitch
                      checked={isCurrentStudy}
                      onChange={(value) => setIsCurrentStudy(value)}
                    />
                    <Description color="#080f6c">
                      I currently study here
                    </Description>
                  </SwitchGroup>
                  <SwitchGroup>
                    <SecondaryButton
                      onClick={() => {
                        setIsAdding(false);
                        setIsEditing(false);
                      }}
                    >
                      Close
                    </SecondaryButton>
                    <ActionButton onClick={() => form.submit()}>
                      {isEditing ? "Save" : "Add"}
                    </ActionButton>
                  </SwitchGroup>
                </Bottom>
              </div>
            </Form>
          </EducationContainerTop>
        </EducationContainer>
      )}

      {educations?.map((education, index) => {
        return (
          <EducationCard key={index}>
            <EducationInfoGroup>
              <BoxText>
                <BoxName>{education.college}</BoxName>
                <BoxDesc>{education.degree}</BoxDesc>
              </BoxText>
              <BoxYear>
                <BoxYearTextUp>Year</BoxYearTextUp>
                <BoxYearText>
                  {new Date(education.startDate).getFullYear()}
                </BoxYearText>
              </BoxYear>
            </EducationInfoGroup>
            <UpdateGroup>
              <EditButton onClick={() => handleEditFormEducation(education)}>
                <EditIcon /> Edit
              </EditButton>
              <DeleteButton
                onClick={() =>
                  deleteEducationMutate({
                    educationId: (education as any)._id,
                  })
                }
              >
                Delete
              </DeleteButton>
            </UpdateGroup>
          </EducationCard>
        );
      })}
    </FormSectionWrapper>
  );
};
