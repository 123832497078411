import styled from "styled-components";
import { THEME } from "utils/theme";
import bgImage from "assets/images/profile-preview/bg-image.png";

export const Container = styled.div`
  display: flex;
  max-width: ${THEME.MAX_WITH};
  margin: auto;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  background: rgba(156, 138, 193, 0.04);
  margin-bottom: 1.5rem;
  padding: 1.375rem 1.5rem 1.125rem 1.5rem;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45.5rem;
`;

export const TitleBlock = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 0.5625rem;
`;

export const Title = styled.div`
  color: #080f6c;
  font: 700 1.5rem/125% "DM Sans" !important;
  letter-spacing: -0.04em;
`;

export const LookingRoleBlock = styled.div`
  display: flex;
  column-gap: 0.9375rem;
  align-items: center;
  margin-bottom: 0.8125rem;
  padding-left: 4.0625rem;
`;

export const LookingRole = styled.div`
  color: #080f6c;
  letter-spacing: -0.04em;
  span {
    font: normal 500 1rem/125% "DM Sans" !important;
  }
  #text {
    font: normal 400 1rem/125% "DM Sans" !important;
  }
`;

export const SalaryBlock = styled(LookingRoleBlock)``;

export const Salary = styled(LookingRole)``;

export const RightContainer = styled.a`
  display: flex;
  justify-content: center;
  background-image: url(${bgImage});
  border-radius: 0.25rem;
  width: 5.5rem;
  height: 7.375rem;
  align-items: center;
`;
