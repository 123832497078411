import { FC, memo } from "react";
import { ICardItemAdminProps } from "./cardItemAdmin.props";
import {
  AvaAndSubItem,
  AvatarAntd,
  CardContent,
  CardItemAbove,
  CardItemAdminReply,
  CardItemUserReply,
  Container,
  LabelWrap,
  FormReplyContainer,
  FormTextInput,
  Label,
  LeftCardItem,
  ModalFormItem,
  ReplyButton,
  SubItem,
  Wrap,
  AvatarAntdGroupStyled,
  LinkStyled,
} from "./cardItemAdmin.style";
// import avatar1 from "assets/images/supportTicketPage/avatar1.png";
// import projectImage from "assets/images/supportTicketPage/projectImage.png";
import TagWithIcon from "components/base/tagWithIcon";
import { Form, message } from "antd";
import { ClockIcon } from "assets/icons/clock.icon";
import { requiredRules } from "utils/validatorRules";
import { ROUTER_CONSTANTS, SUPPORT_TICKET_STATUS } from "utils/constant";
import { addCommentAPI } from "api/users";
import { AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import { CompletedIcon } from "assets/icons/complete.icon";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";

const CardItemAdmin: FC<ICardItemAdminProps> = ({ supportTiket, refresh }) => {
  const [form] = Form.useForm();
  const { mutate: addComment } = useMutation(addCommentAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success("Support ticket comment successfully sent!");
        form.resetFields();
        refresh();
      } else {
        message.error(responseMessage);
      }
    },
  });
  function sendComment() {
    const comment = form.getFieldValue("comment");
    const params = {
      ticketId: supportTiket?._id,
      data: {
        comments: comment,
      },
    };
    addComment(params);
  }

  return (
    <Container>
      <CardItemAbove>
        <LeftCardItem>
          <AvaAndSubItem>
            <AvatarAntdGroupStyled
              collabs={[
                {
                  url: supportTiket?.userDetails?.profilePicture,
                  username:
                    supportTiket?.userDetails?.username ||
                    supportTiket?.userDetails?.emailId.split("@").shift(),
                  id: supportTiket?.userDetails?._id,
                },
              ]}
              size={42}
              max={5}
            />
            <SubItem className="sub-item">
              <Label>Subject</Label>
              <LabelWrap>
                <label>{supportTiket?.subject}</label>
              </LabelWrap>
            </SubItem>
          </AvaAndSubItem>
          <SubItem>
            <Label>Project</Label>
            <LabelWrap>
              <AvatarAntd
                src={supportTiket?.projectDetails?.coverImage}
                icon={<UserOutlined />}
              />
              <LinkStyled
                to={ROUTER_CONSTANTS.PROJECT.DETAIL.replace(
                  ":id",
                  supportTiket?.projectDetails?._id
                )}
              >
                {supportTiket?.projectDetails?.projectName}
              </LinkStyled>
            </LabelWrap>
          </SubItem>
        </LeftCardItem>
        {supportTiket?.ticketStatus === SUPPORT_TICKET_STATUS.OPEN ? (
          <TagWithIcon color="#FF5722">
            <ClockIcon></ClockIcon>
            {/* <span>
              {supportTiket?.ticketStatus === SUPPORT_TICKET_STATUS.INPROGRESS
                ? "In progress"
                : "Open"}
            </span> */}
            <span>Open</span>
          </TagWithIcon>
        ) : (
          <TagWithIcon color="#1CC98A">
            <CompletedIcon />
            <span>{"Closed"}</span>
          </TagWithIcon>
        )}
      </CardItemAbove>
      <CardContent>
        <span>{supportTiket?.reason}</span>
        <br />
        <span>{supportTiket?.details}</span>
      </CardContent>
      <Wrap>
        {supportTiket?.comments
          ?.sort((a: any, b: any) => (a?.createdAt > b?.createdAt ? 1 : -1))
          ?.map((cmt: any, i: number) =>
            cmt?.senderDetails ? (
              <CardItemUserReply key={i}>
                <TagWithIcon color="#1CC98A" type="NoneIcon">
                  <label />
                  <span>
                    {cmt?.senderDetails?.fullName ||
                      cmt?.senderDetails?.emailId}
                  </span>
                  <p>{moment(cmt?.createdAt).fromNow()}</p>
                </TagWithIcon>
                <CardContent>
                  <span>{cmt?.msg}</span>
                </CardContent>
              </CardItemUserReply>
            ) : (
              <CardItemAdminReply key={i}>
                <TagWithIcon color="#FF5722" type="NoneIcon">
                  <label />
                  <span>Admin</span>
                  <p>{moment(cmt?.createdAt).fromNow()}</p>
                </TagWithIcon>
                <CardContent>
                  <span>{cmt?.msg}</span>
                </CardContent>
              </CardItemAdminReply>
            )
          )}
      </Wrap>

      {supportTiket?.ticketStatus === SUPPORT_TICKET_STATUS.OPEN && (
        <Form
          name="reply"
          wrapperCol={{ span: 32 }}
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={sendComment}
          form={form}
        >
          <FormReplyContainer>
            <ModalFormItem
              name="comment"
              labelAlign="left"
              rules={[requiredRules]}
            >
              <FormTextInput placeholder="Write here" />
            </ModalFormItem>
            <ReplyButton htmlType="submit" width={"86px"} height={"36px"}>
              <span>Reply</span>
            </ReplyButton>
          </FormReplyContainer>
        </Form>
      )}
    </Container>
  );
};

export default memo(CardItemAdmin);
