import { FC, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import WorktasksInsideBackground from "assets/images/worktasks-inside/worktasks-inside-background.png";
import {
  BackgroundImage,
  HeadWrap,
  Head,
  Wrap,
  Title,
  Status,
  EndTime,
  SponsoredBy,
  Gap,
  AvatarSponsor,
  OrganizationAvatarWrap,
  WtInfo,
} from "../WorktasksInside.style";
import { BlockchainNetworks, ROUTER_CONSTANTS } from "utils/constant";
import dayjs from "dayjs";
import FlexBox from "components/base/Flexbox";
// import { SecondaryButton } from "pages/ProjectDetail/ProjectDetail.style";
import { IWorktaskHeadProps } from "../WorktasksInside.props";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import CollaboratorRating from "pages/CollaboratorRating";
import PrimaryButton from "components/base/primaryButton";
import { message } from "antd";
import { setPageLoading } from "redux/slices";
import { finishPackageSC, handleMessageErrorSC } from "web3/contract";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getPackageBonusScoresAPI,
  finishPackageAPI,
  startWorkAPI,
  updateFinishStatusAPI,
  PaidCollaboratorEvent,
  PaidObserverEvent,
} from "api/users";
import { formatUnits } from "ethers/lib/utils";
import BackToProjectDetail from "components/modules/backToDetail";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import { WORK_STATUS } from "api/projectDetail";

const WorktaskHead: FC<IWorktaskHeadProps> = ({
  worktaskDetail,
  hideBtnsOption,
  project,
}) => {
  const [openRate, setOpenRate] = useState(false);
  // const [packageBonusScores, setPackageBonusScores] = useState([] as number[]);
  const toggleOpenRate = () => setOpenRate(!openRate);
  const userInfo = useAppSelector((state) => state.auth.user);
  const userId: string = userInfo?._id; // useAppSelector((state) => state.auth.user?._id);
  const isAlreadyCollaborator = worktaskDetail?.collaborators?.includes(userId);
  const collaboratorsLength = worktaskDetail?.collaborators?.length ?? 0;
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const param = useParams();
  const location = useLocation();
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  const { mutateAsync: getPackageBonusScores } = useMutation(
    getPackageBonusScoresAPI
  );

  const { mutate: updateFinishStatus } = useMutation(updateFinishStatusAPI, {
    onSuccess: async (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        // message.success(responseMessage);
        await queryClient.invalidateQueries([
          `getPackageDetail-${param.packageId}`,
        ]);
      } else {
        message.error(responseMessage);
        dispatch(setPageLoading(false));
      }
    },
    onError: () => {
      dispatch(setPageLoading(false));
    },
  });

  const { mutate: finishPackage } = useMutation(finishPackageAPI, {
    onSuccess: async (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        await queryClient.invalidateQueries([
          `getPackageDetail-${param.packageId}`,
        ]);
        dispatch(setPageLoading(false));
        message.success(responseMessage);
      } else {
        dispatch(setPageLoading(false));
        message.error(responseMessage);
      }
    },
    onError: () => {
      dispatch(setPageLoading(false));
    },
  });

  const { mutate: startWork } = useMutation(startWorkAPI, {
    onSuccess: async (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        await queryClient.invalidateQueries([
          `getPackageDetail-${param.packageId}`,
        ]);
        message.success(responseMessage);
      } else {
        message.error(responseMessage);
      }
    },
    onSettled: () => {
      dispatch(setPageLoading(false));
    },
  });

  const removeDuplicateCollaboratorRating =
    worktaskDetail?.collaboratorRatings?.filter(
      (v: any, i: any, a: any) =>
        a.findIndex(
          (v2: any) => v2.fromUser === v.fromUser && v2.user === v.user
        ) === i
    );

  const isCollaboratorReviewFinish =
    collaboratorsLength === worktaskDetail?.collaboratorReviews?.length;
  const isCollaboratorRatingFinish =
    collaboratorsLength * (collaboratorsLength - 1) ===
    removeDuplicateCollaboratorRating?.length;

  const isProjectCreator = worktaskDetail?.initiator === userId;
  const isCanSubmitDeliverables =
    worktaskDetail?.progressHistory?.inProgressUsers.includes(userId);
  const isSubmitedDeliverables = (worktaskDetail?.workProgress ?? [])
    .map((item) => item.user)
    .includes(userId);

  const isEveryCollabSubmitted =
    worktaskDetail?.progressHistory?.submittedUsers.filter((item: any) => item)
      .length === worktaskDetail?.collaborators?.length ||
    worktaskDetail?.workProgress?.filter(
      (item: any) => item.status === "SUBMISSION_APPROVED"
    ).length === worktaskDetail?.collaborators?.length;
  const isUserGotApprovedDeliverables = worktaskDetail?.workProgress?.some(
    (progress: any) =>
      progress.user === userId && progress.status === "SUBMISSION_APPROVED"
  );
  const isShowRateCollaborator =
    isUserGotApprovedDeliverables &&
    isEveryCollabSubmitted &&
    !((worktaskDetail?.isSubmittedRatings?.length ?? 0) > 0) &&
    !isCollaboratorRatingFinish &&
    !isProjectCreator;
  const isCreatorApproveAllCollabs =
    worktaskDetail?.workProgress?.length === collaboratorsLength &&
    worktaskDetail?.deliverables?.every(
      (item) => item?.deliveryStatus === "COMPLETED"
    );
  const isShowFinishPackage =
    worktaskDetail?.workStatus === "SUBMITTED" &&
    worktaskDetail?.finishStatus === "OPEN" &&
    isProjectCreator;
  const isRateOther =
    (worktaskDetail?.isSubmittedRatings?.length ?? 0) > 0 ? true : false;
  const isObserver = worktaskDetail?.observers?.includes(userId);
  const isFinish = worktaskDetail?.finishStatus === "SUCCESS";

  const handleFinishWorktask = async () => {
    if (!isCollaboratorReviewFinish) {
      message.error(
        "Please review each collaborators before completing the Work Task"
      );
      return;
    }

    if (!isCollaboratorRatingFinish) {
      message.error(
        "All collaborators need to rate each other before completing the Work Task"
      );
      return;
    }

    if (!checkWallet()) return;
    // if (!account) {
    //   message.error("Please connect to wallet");
    //   return;
    // }

    // if (account !== userInfo?.walletAddress) {
    //   message.error("You are connecting to a different wallet");
    //   return;
    // }

    dispatch(setPageLoading(true));

    const bonusRes = await getPackageBonusScores({
      projectId: param.projectId || "",
      packageId: param.packageId || "",
    });

    // console.log("bonusRes", bonusRes?.responseData);

    try {
      if (bonusRes?.responseCode === 200) {
        const projectChainId =
          BlockchainNetworks[project?.blockchainNetwork?.name!];

        await handleSwitchNetwork(projectChainId);
        // if (!chainId) {
        //   activateBrowserWallet({ type: connectedWalletName });
        // }

        // if (chainId !== projectChainId) {
        //   await switchNetwork(projectChainId);
        // }
        // changeNetwork(projectChainId);

        const scParams = {
          projectId: worktaskDetail.scProjectId,
          packageId: worktaskDetail.scPackageId,
          collaborators: worktaskDetail.collaboratorWalletAddress as string[],
          observers: worktaskDetail.observerWalletAddress as string[],
          scores:
            bonusRes?.responseData?.paymentCalculation?.collaboratorBonusScores,
        };

        let { tx, events } = await finishPackageSC(scParams);
        const budgetLeft = (+formatUnits(
          events.FinishedPackage[0][2],
          project?.decimal
        )).toFixed(1);

        const paidCollaboratorEvents: PaidCollaboratorEvent[] =
          events.PaidCollaboratorRewards.map((args: any) => {
            return {
              walletAddress: args[2],
              mgp: +formatUnits(args[3], project?.decimal),
              bonus: +formatUnits(args[4], project?.decimal),
            };
          });
        const paidObserverEvents: PaidObserverEvent[] =
          events.PaidObserverFee.map((args: any) => {
            return {
              walletAddress: args[2],
              amount: +formatUnits(args[3], project?.decimal),
            };
          });

        updateFinishStatus({
          projectId: param?.projectId || "",
          packageId: param?.packageId || "",
          status: "SC_SUCCESS",
        });
        finishPackage({
          projectId: param?.projectId || "",
          packageId: param?.packageId || "",
          budgetLeft,
          txHash: tx.hash,
          paidCollaboratorEvents,
          paidObserverEvents,
        });
      } else {
        message.error(bonusRes?.responseMessage);
        dispatch(setPageLoading(false));
      }
    } catch (err: any) {
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const handleStartWork = () => {
    dispatch(setPageLoading(true));
    startWork({
      projectId: param.projectId || "",
      packageId: param.packageId || "",
    });
  };

  const isStartWork = useMemo(() => {
    const workTaskAvailable =
      worktaskDetail?.workStatus === "INPROGRESS" ||
      worktaskDetail?.workStatus === "OPEN";
    return (
      worktaskDetail?.startDate <= new Date() &&
      workTaskAvailable &&
      isAlreadyCollaborator &&
      !isSubmitedDeliverables &&
      !isCanSubmitDeliverables &&
      !isUserGotApprovedDeliverables &&
      !isFinish &&
      !isObserver
    );
  }, [
    isAlreadyCollaborator,
    isCanSubmitDeliverables,
    isFinish,
    isObserver,
    isSubmitedDeliverables,
    isUserGotApprovedDeliverables,
    worktaskDetail?.startDate,
    worktaskDetail?.workStatus,
  ]);

  return (
    <>
      <CollaboratorRating visible={openRate} setVisible={toggleOpenRate} />
      <Wrap>
        <BackgroundImage
          src={WorktasksInsideBackground}
          alt="WorktasksInside background"
        />
        <HeadWrap>
          <Head>
            <WtInfo>
              {location.pathname.includes("deliver-work") && (
                <BackToProjectDetail
                  linkToGo={ROUTER_CONSTANTS.PROJECT.DETAIL.replace(
                    ":id",
                    param.projectId as string
                  )}
                  linkText="Back to project detail"
                />
              )}
              <Wrap>
                <Title>{worktaskDetail?.name}</Title>
                <Status>
                  {worktaskDetail?.workStatus === WORK_STATUS.INPROGRESS
                    ? "IN PROGRESS"
                    : worktaskDetail?.workStatus}
                </Status>
                {isFinish || (
                  <EndTime>
                    Ends on {dayjs(worktaskDetail?.endDate).format("DD MMM YY")}
                  </EndTime>
                )}
              </Wrap>
              <Gap />
              <Wrap>
                <SponsoredBy>Sponsored by</SponsoredBy>
                <FlexBox gap="8px">
                  {project?.organizationDetails?.map((org) => (
                    <Link
                      to={ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(
                        ":id",
                        org._id
                      )}
                      title={org?.name}
                      key={org?._id}
                    >
                      <OrganizationAvatarWrap>
                        <AvatarSponsor src={org?.avatar} alt="Avatar" />
                        <div>{org?.name}</div>
                      </OrganizationAvatarWrap>
                    </Link>
                  ))}
                </FlexBox>
              </Wrap>
            </WtInfo>
            {hideBtnsOption || (
              <FlexBox gap="10px">
                {isShowRateCollaborator && (
                  <PrimaryButton onClick={toggleOpenRate}>
                    Rate Collaborators
                  </PrimaryButton>
                )}
                {isRateOther && (
                  <PrimaryButton>
                    You have rated other collaborators
                  </PrimaryButton>
                )}
                {isCreatorApproveAllCollabs && isShowFinishPackage && (
                  <PrimaryButton onClick={handleFinishWorktask}>
                    Finish Worktask
                  </PrimaryButton>
                )}
                {isStartWork && (
                  <PrimaryButton onClick={handleStartWork}>
                    Start Work
                  </PrimaryButton>
                )}
              </FlexBox>
            )}
          </Head>
        </HeadWrap>
      </Wrap>
    </>
  );
};

export default WorktaskHead;
