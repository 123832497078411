export const AttachIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2929 7.36726L8.16621 13.4939C7.41565 14.2445 6.39766 14.6662 5.33621 14.6662C4.27475 14.6662 3.25677 14.2445 2.50621 13.4939C1.75565 12.7434 1.33398 11.7254 1.33398 10.6639C1.33398 9.60248 1.75565 8.58449 2.50621 7.83393L8.63288 1.70726C9.13325 1.20689 9.8119 0.925781 10.5195 0.925781C11.2272 0.925781 11.9058 1.20689 12.4062 1.70726C12.9066 2.20764 13.1877 2.88629 13.1877 3.59393C13.1877 4.30157 12.9066 4.98022 12.4062 5.4806L6.27288 11.6073C6.02269 11.8575 5.68336 11.998 5.32954 11.998C4.97572 11.998 4.6364 11.8575 4.38621 11.6073C4.13602 11.3571 3.99547 11.0177 3.99547 10.6639C3.99547 10.3101 4.13602 9.97079 4.38621 9.7206L10.0462 4.06726"
        stroke="#7E00FD"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DeleteAttachIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.2275" cy="10" r="10" fill="#7E00FD" />
      <path
        d="M13.6025 6.625L6.85254 13.375"
        stroke="white"
        strokeWidth="1.75424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6025 13.375L6.85254 6.625"
        stroke="white"
        strokeWidth="1.75424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
