import { useEthers } from "@usedapp/core";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { setConnectWalletOpen } from "redux/slices";
import {
  changeNetwork,
  currentChain,
} from "web3/contract";

export const useCheckWalletAndSwitchNetwork = () => {
  const dispatch = useDispatch();
  const { account, chainId, switchNetwork, activateBrowserWallet, deactivate } =
    useEthers();
  const userInfo = useAppSelector((state) => state.auth.user);
  const { connectedWalletName } = useAppSelector((state) => state.modal);

  const reConnect = async () => {
    await (window as any)?.ethereum
      ?.request({
        method: "wallet_requestPermissions",
        params: [
          {
            eth_accounts: {},
          },
        ],
      })
      .then(() => {
        (window as any)?.ethereum?.request({
          method: "eth_requestAccounts",
        });
        activateBrowserWallet({ type: connectedWalletName });
      });
  };

  const checkWallet = () => {
    if (!account) {
      message.error("Please connect to wallet");
      dispatch(setConnectWalletOpen(true));
      return false;
    }

    if (userInfo?.walletAddress !== account) {
      message.error(
        <span>
          We have identified that you are using different wallet from the one
          you have setup/ verified your account.
          <br />
          Please switch back to your verified wallet to proceed.
        </span>
      );
      deactivate();
      reConnect();

      return false;
    }
    return true;
  };

  const handleSwitchNetwork = async (chainIdToConnect: number) => {
    if (!chainId) {
      activateBrowserWallet({ type: connectedWalletName });
    }

    if (chainId !== chainIdToConnect) {
      try {
        await switchNetwork(chainIdToConnect);
      } catch (error: any) {
        throw error;
      }
    }
    if (currentChain !== chainIdToConnect) {
      changeNetwork(chainIdToConnect);
    }
  };

  return {
    checkWallet,
    handleSwitchNetwork,
  };
};
