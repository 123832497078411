export const EthereumIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.90998 0L7.80273 0.364508V10.9417L7.90998 11.0487L12.8198 8.14657L7.90998 0Z"
      fill="#343434"
    />
    <path d="M7.90981 0L3 8.14657L7.90981 11.0488V5.91494V0Z" fill="#8C8C8C" />
    <path
      d="M7.90906 11.9788L7.84863 12.0525V15.8203L7.90906 15.9968L12.8218 9.07812L7.90906 11.9788Z"
      fill="#3C3C3B"
    />
    <path
      d="M7.90981 15.9959V11.9779L3 9.07715L7.90981 15.9959Z"
      fill="#8C8C8C"
    />
    <path
      d="M7.90918 11.0488L12.8189 8.14667L7.90918 5.91504V11.0488Z"
      fill="#141414"
    />
    <path d="M3 8.14667L7.90974 11.0489V5.91504L3 8.14667Z" fill="#393939" />
  </svg>
);
