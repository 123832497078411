import { configureStore } from "@reduxjs/toolkit";
import {
  authSlice,
  modalSlice,
  loadingSlice,
  filterSlice,
  conversationSlice,
  addWorktaskSlice,
  filterCourseSlice,
  courseSlice,
} from "./slices";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

const reducers = combineReducers({
  auth: authSlice.reducer,
  modal: modalSlice.reducer,
  loading: loadingSlice.reducer,
  filter: filterSlice.reducer,
  conversation: conversationSlice.reducer,
  addWorktask: addWorktaskSlice.reducer,
  course: courseSlice.reducer,
  filterCourse: filterCourseSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["course"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
