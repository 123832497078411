import FlexBox from "components/base/Flexbox";
import PrimaryButton from "components/base/primaryButton";
import { Collapse } from "antd";
import styled from "styled-components";
import OutlinedButton from "components/base/outlinedButton";

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  padding: 0rem 3.125rem;
  overflow: hidden;
`;
export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  object-fit: cover;
  z-index: -1;
  height: 128px;
`;
export const TitleWrap = styled.div`
  width: 100%;
  padding: 3rem 0rem;
  max-width: 1170px;
  margin: auto;
  position: relative;
  // font-weight: 700;
  // font-size: 2rem;
  // line-height: 100%;
  // letter-spacing: -0.02em;
  // color: #080f6c;
  // overflow: visible;
`;

export const ReviewDeliverTitle = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-block: 0;
`;

export const DeliverCollapse = styled(Collapse)`
  &.ant-collapse {
    margin-top: 60px;
    border-top: 1px solid rgba(41, 46, 115, 0.12);
    border-left: 1px solid rgba(41, 46, 115, 0.12);
    border-right: 1px solid rgba(41, 46, 115, 0.12);

    > .ant-collapse-item {
      border-bottom: 1px solid rgba(41, 46, 115, 0.12);

      > .ant-collapse-header {
        align-items: center;
        padding-left: 30px;
        padding-right: 54px;
        padding-block: 30px;
      }
    }
  }

  &.ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 30px;
  }

  .ant-collapse-content {
    border-top: 1px solid rgba(41, 46, 115, 0.12);
  }

  .ant-collapse-header-text {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: rgba(41, 46, 115, 0.82);
    text-transform: uppercase;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 30px;
  }
`;

export const MaxWidthWrapper = styled.div`
  max-width: 400px;
  margin-top: 16px;
  margin-left: 46px;
`;

export const OpenDeliverWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 6px;
  cursor: pointer;
`;

export const BodyWrap = styled.div`
  width: 100%;
  padding: 0rem 0rem 8.4375rem;
  max-width: 1170px;
  margin: auto;
`;

export const DeliverableWrap = styled.div`
  background: white;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  padding: 0 0 1.875rem;
  margin-top: 40px;
`;

export const ItemWrap = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

export const Item = styled.div`
  background: #ffffff;
  border-radius: 0.25rem;
  width: 100%;
  svg.document {
    width: 24px;
    height: 24px;
  }
`;

export const ItemTitle3 = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: rgba(41, 46, 115, 0.82);
  max-width: 400px;
  font-family: "DM Sans";
`;
export const ItemTitle1 = styled(ItemTitle3)`
  font-weight: 600;
  font-size: 1.125rem;
`;
export const ItemTitle2 = styled(ItemTitle3)`
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
`;
export const Note = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: rgba(8, 15, 108, 0.42);
  word-break: break-all;
`;

export const Title = styled(Note)`
  font-weight: 600;
`;

export const ButtonGroup = styled.div`
  text-align: center;
  button {
    margin: 15px auto 0;
  }
`;

export const ModalTitle = styled.div`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 110%;
  color: #080f6c;
  text-align: center;
  margin-bottom: 30px;
`;

export const SendButton = styled(PrimaryButton)`
  margin: 10px 0 10px auto;
`;

export const CopyElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SecondaryButton = styled(OutlinedButton)<{
  width?: string;
  height?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: ${(p) => p.width || "auto"};
  height: ${(p) => p.height || "38px"};
  background: transparent;
  /* border: none; */
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right bottom, #6a5af9, #f62682);
  border-radius: 4px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  span {
    color: #080f6c;
  }
`;

export const HeadingItem = styled(FlexBox)`
  background: rgba(156, 138, 193, 0.04);
  border: 1px solid rgba(41, 46, 115, 0.12);
  padding: 30px;
`;

export const Content = styled.div`
  padding: 10px 30px;
`;

export const FileGrid = styled.div`
  display: grid;
  grid-row-gap: 8px;
`;

export const FileInfoWrapper = styled(FlexBox)`
  flex-wrap: nowrap;

  > div:first-child {
    flex-wrap: nowrap;

    .ant-avatar-image {
      flex-shrink: 0;
    }
  }
`;

export const FileNameWrapper = styled(FlexBox)`
  justify-content: flex-end;
  flex-wrap: nowrap;
`;

export const RejectWrapper = styled(Note)`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 700;
  color: rgba(41, 46, 115, 0.82);
`;
