import { FC, Fragment } from "react";
import { IPreviewProps } from "./portfolioPreview.props";
import {
  DetailsWrap,
  FieldName,
  FileItem,
  FileWrap,
  PreviewWrap,
  ProjectTitle,
  ProjectUrlWrap,
  ShortDescription,
  SkillWrap,
  Wrap,
  VideosWrapper,
  DescDetail,
} from "./portfolioPreview.style";
// import bg_img from "assets/images/addPortfolio/bg_datasetup.png";
import { ButtonsWrap, FooterWrap } from "pages/Bounty/Bounty.style";
import Button from "components/base/button";
import { ArrowLeftIcon, ArrowRightIcon } from "assets/images/Bounty";
import PrimaryButton from "components/base/primaryButton";
import { GlobalIcon } from "assets/images/addPortfolio";
import { useMutation } from "@tanstack/react-query";
import { addPortfolioAPI, editPortfolioAPI } from "api/users";
import { message } from "antd";
import { AxiosError } from "axios";
import { setPageLoading } from "redux/slices";
import { useAppDispatch } from "redux/hooks";
import { PROGRESS, ROUTER_CONSTANTS } from "utils/constant";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FileOutlined, FilePdfOutlined } from "@ant-design/icons";
import { elementing, splitDescription } from "utils/formatDescription";
import { allAllowedType } from "../addDetailStep";

const Preview: FC<IPreviewProps> = ({
  nextStep,
  backStep,
  projectFormData,
  setSuccess,
}) => {
  const colors = ["#E94600", "#7E00FD", "#3B6CB4", "#49B67A"];
  const dispatch = useAppDispatch();
  const { portfolioId } = useParams();
  const isEdit = !!portfolioId;
  const location = useLocation();

  const { mutate: addPortfolio } = useMutation(addPortfolioAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        nextStep();
        setSuccess(PROGRESS.SUCCESS);
      } else {
        message.error(responseMessage);
        setSuccess(PROGRESS.ERROR);
      }

      dispatch(setPageLoading(false));
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);

      setSuccess(PROGRESS.ERROR);
      dispatch(setPageLoading(false));
    },
  });
  const navigate = useNavigate();
  const { mutate: editPortfolio } = useMutation(editPortfolioAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage, responseData } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        const { viewOtherUser } = location.state as any;
        navigate(`${ROUTER_CONSTANTS.USER.EDIT_PORTFOLIO}/${portfolioId}`, {
          state: {
            viewOtherUser,
            selectedPortfolio: responseData,
            finishedSubmitting: true,
          },
        });
      } else {
        message.error(responseMessage);
        setSuccess(PROGRESS.ERROR);
      }

      dispatch(setPageLoading(false));
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);

      setSuccess(PROGRESS.ERROR);
      dispatch(setPageLoading(false));
    },
  });

  function publishPortfolio() {
    dispatch(setPageLoading(true));
    const payload = {
      ...projectFormData,
      videos: [
        ...projectFormData.videos,
        ...(projectFormData.files
          ?.filter((file: any) => file.type === 1)
          .map((file: any) => ({
            videoName: file.name,
            videoLink: file.url,
          })) || []),
      ],
      files: projectFormData.files
        ?.filter((file: any) => {
          const fileType = allAllowedType.findIndex((allowType) =>
            allowType.some((t) => file.fileLink?.includes(t))
          );
          return file.type || fileType + 1 !== 1;
        })
        .map((file: any) => ({
          fileName: file.name || file.fileName,
          fileLink: file.url || file.fileLink,
        })),
      projectUrl: projectFormData.projectUrl || undefined,
    };
    if (isEdit) {
      editPortfolio(payload);
    } else {
      addPortfolio(payload);
    }
  }

  const getIconByFileType = (fileType: number) => {
    switch (fileType) {
      case 1: // video
      case 4: // audio
        return <FileOutlined />;
      case 3: // application
        return <FilePdfOutlined />;
    }
  };

  const formatTextToNewType = (text: string) => {
    return (
      <DescDetail>
        {splitDescription(text).map((str: string, i: number) => (
          <Fragment key={i}>
            {elementing(str)}
            <br />
          </Fragment>
        ))}
      </DescDetail>
    );
  };
  return (
    <>
      <PreviewWrap>
        <ProjectTitle>
          <span>{projectFormData.projectTitle}</span>
        </ProjectTitle>
        <ShortDescription>
          <div>Short description</div>
          {formatTextToNewType(projectFormData.description)}
        </ShortDescription>
        <DetailsWrap>
          <FieldName>Skills and deliverables</FieldName>
          <Wrap>
            {projectFormData.skills?.map((skill: string, i: number) => (
              <SkillWrap key={i} color={colors[(i + 1) % colors.length]}>
                {skill}
              </SkillWrap>
            ))}
          </Wrap>
          <FieldName>Project Files</FieldName>
          <FileWrap>
            {projectFormData.files?.map((file: any, i: number) => {
              const fileType = allAllowedType.findIndex((allowType) =>
                allowType.some((t) => file.fileLink?.includes(t))
              );
              return (
                <FileItem key={i}>
                  {file?.type === 2 ? (
                    <img src={file?.url} alt={file?.name} />
                  ) : (
                    getIconByFileType(file?.type || fileType + 1)
                  )}
                  <span>{file.name || file.fileName}</span>
                </FileItem>
              );
            })}
          </FileWrap>
          {projectFormData?.videos?.length > 0 && (
            <VideosWrapper>
              <FieldName>Video Link</FieldName>
              <ProjectUrlWrap>
                {projectFormData?.videos.map((vid, id) => (
                  <Fragment key={id}>
                    <GlobalIcon />
                    <a href={vid.videoLink} target="_blank" rel="noreferrer">
                      {vid.videoName}
                    </a>
                  </Fragment>
                ))}
              </ProjectUrlWrap>
            </VideosWrapper>
          )}
          {projectFormData.projectUrl && (
            <>
              <FieldName>Project URL</FieldName>
              <ProjectUrlWrap>
                <GlobalIcon />
                <a
                  href={projectFormData.projectUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {projectFormData.projectUrl}
                </a>
              </ProjectUrlWrap>
            </>
          )}
        </DetailsWrap>
      </PreviewWrap>

      <FooterWrap>
        <Button
          width="89px"
          height="48px"
          border="1px solid rgba(156, 138, 193, 0.16)"
          color="rgba(41, 46, 115, 0.42)"
          background="#FFF"
          onClick={() => backStep()}
        >
          <ArrowLeftIcon />
          Back
        </Button>
        <ButtonsWrap>
          <Button
            width="83px"
            height="48px"
            border="1px solid rgba(156, 138, 193, 0.16)"
            color="rgba(41, 46, 115, 0.42)"
            background="#FFF"
          >
            <Link to="/">Cancel</Link>
          </Button>
          <PrimaryButton width="181px" height="48px" onClick={publishPortfolio}>
            Publish the project
            <ArrowRightIcon />
          </PrimaryButton>
        </ButtonsWrap>
      </FooterWrap>
    </>
  );
};

export default Preview;
