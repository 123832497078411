import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  BuildFiLogo,
  CrowdHackLogo,
  DataSourceLogo,
  LogoCenter,
  SoulLogo,
} from "assets/icons/logo.icon";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { IDoMoreWithBakedProps } from "./doMoreWithbaked.props";
import {
  Center,
  Container,
  Content,
  Heading,
  MainCenter,
  PrimaryButtonStyled,
  Top,
  WrapIconBottom,
  WrapIconLeft,
  WrapIconRight,
} from "./doMoreWithBaked.style";

const DoMoreWithBaked: FC<IDoMoreWithBakedProps> = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Heading>
        <h1>Do More With reBaked</h1>
        <span>
          BuidlFi is a part of reBaked portfolio of products. Go beyond BuidlFi
          with CrowdHack, DataSource, and OpenSoul.
        </span>
      </Heading>
      <div>
        <Top>
          <WrapIconLeft
            onClick={() => window.open("https://www.datasource.ai/", "_blank")}
            className="wrap-icon revert"
          >
            <ArrowDown />
            <DataSourceLogo />
          </WrapIconLeft>
        </Top>
        <Content>
          <WrapIconLeft
            onClick={() => window.open("http://3.132.181.237/", "_blank")}
            className="wrap-icon"
          >
            <ArrowLeft />
            <SoulLogo />
          </WrapIconLeft>
          <Center>
            <MainCenter>
              <LogoCenter />
            </MainCenter>
            <WrapIconBottom
              onClick={() => window.open("https://crowdhack.io/", "_blank")}
              className="wrap-icon"
            >
              <ArrowDown />
              <CrowdHackLogo />
            </WrapIconBottom>
          </Center>
          <WrapIconRight onClick={() => navigate("/")} className="wrap-icon">
            <ArrowRight />
            <BuildFiLogo />
          </WrapIconRight>
        </Content>
      </div>
      <PrimaryButtonStyled
        onClick={() => window.open("https://www.rebaked.com/", "_blank")}
      >
        reBaked projects
      </PrimaryButtonStyled>
    </Container>
  );
};

export default DoMoreWithBaked;
