export const END_POINT = {
  CHECK_UNIQUE_USERNAME: "/user/checkUniqueOfUsername",
  GET_PROFILE: "/user/getProfile",
  GET_PROFILE_FROM_OTHER: "/user/getProfileFromOther/:id",
  GET_PROCESS_UPDATE_PROFILE: "/user/getProcessUpdateProfile",
  GET_LIST_PORTFOLIO: "/user/getListPortfolios",
  ADD_EDUCATION: "/user/addEducation/",
  DELETE_EDUCATION: "/user/deleteEducation/:educationId",
  UPDATE_EDUCATION: "/user/updateEducationDetails/:educationId",
  ADD_EMPLOYMENT_HISTORY: "/user/addEmploymentHistory/",
  DELETE_EMPLOYMENT_HISTORY:
    "/user/deleteEmploymentHistory/:employmentHistoryId",
  UPDATE_EMPLOYMENT_HISTORY:
    "/user/updateEmploymentDetails/:employmentHistoryId",
  UPDATE_ANOTHER_FIELD_PROFILE: "/user/updateProfile",
  GET_DATA_MASTER: "/user/getMasterByType",
  GET_SKILLS: "/user/getSkills/",
  GET_PROJECT_BY_ORGANIZATION: "/user/getAllProjectsByOrganization/:orgId",
  CREATE_PROJECT: "/user/createProject",
  UPDATE_PROJECT: "/user/updateProject/:projectId",
  GET_ORGANIZATION_INFO: "user/getOrganizationInfo/:organizationId",
  GET_ORGANIZATIONS: "user/getOrganizations",
  UPDATE_ORGANIZATIONS: "user/updateOrganization/:organizationId",
  CREATE_ORGANIZATION: "/user/createOrganization",
  TOGGLE_FOLLOW: "user/followOrganization/:organizationId",
  GET_CREATEWORKTASKMASTERS: "user/getCreateWorktaskMasters/",
  GET_LIST_USER_WILL_BECOME_OBSERVER:
    "user/getListUserWillBecomeObserver/:projectId/:packageId",
  CREATE_WORKTASK: "user/addPackage/:projectId",
  UPDATE_WORK_TASK: "user/updatePackage/:projectId/:packageId",
  GET_ALLPACKAGES: "user/getAllPackages/:projectId",
  GET_ALLPACKAGES_ORG: "user/getAllPackagesOrganization/:orgId",
  GET_PROJECTDETAILS: "user/getProjectDetails/:projectId",
  REQUEST_JOIN_PACKAGE: "user/joinPackage/:projectId/:packageId",
  CREATE_FAQ: "user/addFAQ/:projectId",
  UPDATE_FAQ: "user/updateFAQ/:projectId/:FAQId",
  DELETE_FAQ: "user/deleteFAQ/:projectId/:FAQId",
  GET_FAQ: "user/getAllFAQs/:projectId",
  EXPLORE_PROJECTS: "user/exploreProjects",
  GET_USER_REQUEST_RECEIVED:
    "user/getUserRequestReceived/:projectId/:packageId",
  UPDATE_JOIN_REQUEST:
    "user/updateJoinRequest/:projectId/:packageId/:userId/:verificationStatus",
  REMOVE_COLLAB: "user/removeCollaborator",
  GET_INITIATED_PROJECTS: "user/getMyInitiatedProjects",
  GET_INITIATOR_DASHBOARD_STATS: "user/getInitiatorDashboardStats",
  GET_PACKAGE_DETAILS: "user/getPackageDetails/:projectId/:packageId",
  START_WORK: "user/startWork/:projectId/:packageId",
  GET_TASK_LIST: "user/getTaskList/:projectId",
  SUBMIT_DELIVERABLE: "user/submitDeliverable/:projectId/:packageId",
  GET_ALL_DELIVERABLES: "user/getAllDeliverables",
  APPROVE_DELIVERY_REQUEST:
    "user/approveDeliveryRequest/:projectId/:packageId/:userId/:deliveryId",
  GET_CHATLIST: "user/getChatList",
  GET_CHATGROUP: "user/getRoomChatWorkTask/:packageId",
  UPDATE_FINISH_STATUS:
    "/user/updateFinishStatus/:projectId/:packageId/:status",
  GET_PACKAGE_BONUS_SCORES: "/user/getPackageBonusScores/:projectId/:packageId",
  FINISH_PACKAGE: "/user/finishPackage/:projectId/:packageId",
  GET_PACKAGE_COLLABORATORS:
    "user/getPackageCollaborators/:projectId/:packageId?colaboratorsId=",
  SUBMIT_COLLABORATOR_RATING:
    "user/submitCollaboratorRating/:projectId/:packageId",
  SUBMIT_COLLABORATOR_REVIEW:
    "user/submitCollaboratorReview/:projectId/:packageId",
  GET_FEEDBACK_INFO_COLLABORATOR: "user/getMyFeedBack/:projectId/:packageId",
  UPDATE_FINISH_STATUS_PROJECT:
    "/user/updateFinishProjectStatus/:projectId/:status",
  FINISH_PROJECT: "/user/finishProject/:projectId",
  DELETE_PROJECT: "/user/deleteProject/:projectId",
  // GET_MASTERBYTYPE: "/user/getMasterByType",
  GET_ALLMASTERS: "/user/getAllMasters",
  ADD_CARD: "/user/addCard/:projectId/:packageId",
  GET_PROJECT_BOARD_DETAILS:
    "/user/getProjectBoardDetails/:projectId/:packageId",
  DRAG_DROP_CARD: "/user/dragdropCard/:projectId/:packageId/:cardId",
  DELETE_CARD: "/user/deleteCard/:projectId/:packageId/:cardId",
  CARD_MARK_AS_COMPLETE:
    "/user/cardMarkAsComplete/:projectId/:packageId/:cardId",
  UPDATE_CARD: "/user/updateCard/:projectId/:packageId/:cardId",
  GET_CARD_DETAILS: "/user/getCardDetails/:projectId/:packageId/:cardId",
  DELETE_COMMENT:
    "/user/deleteCardComment/:projectId/:packageId/:cardId/:commentId",
  UPDATE_COMMENT:
    "/user/updateCardComment/:projectId/:packageId/:cardId/:commentId",
  ADD_SUBTASK: "/user/addSubTask/:projectId/:packageId/:cardId",
  UPDATE_SUBTASK: "/user/editSubTask/:projectId/:packageId/:cardId/:subTaskId",
  DELETE_SUBTASK:
    "/user/deleteCardSubtask/:projectId/:packageId/:cardId/:subTaskId",
  GET_REVIEW_DELIVERABLES:
    "user/getReviewDeliverables/:projectId/:packageId/:userId",
  UPDATE_REJECT_REASON: "user/updateRejectReason/:projectId/:packageId",
  GET_ALL_USERS: "user/getAllUsers",
  GET_ALL_OPEN_WORK_TASKS: "/user/getAllOpenProject",
  GET_ALL_DELIVERED_WORK_TASKS: "/user/getAllDeliveredProject",
  GET_ALL_INPROGRESS_WORK_TASKS_INITIATOR:
    "/user/getAllInprogressProjectsInitiator",
  GET_ALL_COMPLETED_WORK_TASKS: "/user/getAllCompletedProject",
  GET_ALL_WORK_TASKS_REQUEST_RECEIVED: "/user/getAllProjectRequestReceived",
  GET_ALL_OPEN_WORK_TASKS_COLLABORATOR: "/user/getAllOpenProjectsCol",
  GET_ALL_WAITING_APPROVAL_WORK_TASKS_COLLABORATOR:
    "/user/getAllWaitingApprovalProjectsCol",
  GET_ALL_IN_PROGRESS_WORK_TASKS_COLLABORATOR:
    "/user/getAllInProgressProjectsCol",
  GET_ALL_SUBMITTED_WORK_TASKS_COLLABORATOR: "/user/getAllSubmittedProjectsCol",
  GET_ALL_COMPLETED_WORK_TASKS_COLLABORATOR: "/user/getAllCompletedProjectsCol",
  GET_TRANSACTION_HISTORY: "user/getTransactionHistory",
  GET_COLLABORATOR_LIST: "user/getCollaboratorList/:projectId",
  GET_COWORKERS_API: "user/getCoWorkers/:projectId/:packageId?colaboratorsId=",
  GET_EARNING_LIST: "user/getEarningList",
  GET_CURRENT_WORKTASK: "user/getCurrentWorkTask",
  GET_CURRENT_WORKTASK_INITIATOR: "user/getCurrentWorkTaskInitiator",
  GET_DELIVERED_WORKTASK_INITIATOR: "user/getDeliveredWorkTaskInitiator",
  CANCEL_PACKAGE: "user/cancelPackage/:projectId/:packageId",
  GET_NOTIFICATIONS: "user/getAllNotifications",
  UPDATE_NOTIFICATION: "user/updateNotificationStatus",
  UPDATE_WALLET: "user/updateProfileWallet",
  GET_MY_PROJECTS: "user/getMyProjectList",
  GET_BACKAGE_BY_PROJECTID: "user/getPackagesByProjectId/:projectId",
  GET_COLLABORATORS_BY_PACKAGEID:
    "user/getCollaboratorsByPackageId/:projectId/:packageId",
  CREATE_SUPPORT_TICKET: "user/createSupportTicket",
  GET_ALL_SUPPORT_TICKETS: "user/getAllSupportTicket",
  ADD_SUPPORT_TICKET_COMMENT: "user/addComment/:ticketId",
  UPDATE_OBSERVER: "user/updateObserver/:projectId",
  ADD_OBSERVER: "user/addObservers/:projectId",
  GET_OBSERVER_LIST: "user/getObserverList/:projectId/:packageId",
  REMOVE_OBSERVER: "user/removeObserver/:projectId/:packageId",
  GET_TRANSACTION_HISTORY_OBSERVERS: "user/getTransactionHistoryObserver/:id",
  GET_TRANSACTIONS: "user/getAllTransactions",
  GET_PROJECT_HISTORY: "user/getProjectHistory",
  ADD_TRANSACTION: "user/saveTransaction",
  START_PROJECT: "user/startProjectInitiator/:projectId",
  INVITE: "user/inviteFriends",
  REFERRAL: "user/getReferralUsers",
  UPLOADS: "/upload/upload-aws",
  GETMASTERBYTYPE: "user/getMasterByType?type=",
  PAYMENT_DASHBOARD_COLLABORATOR_OBSERVER:
    "user/getTransactionsPaymentDashboard",
  PAYMENT_DASHBOARD_BY_TOKEN_COLLABORATOR_OBSERVER:
    "user/getTransactionsPaymentByTokenName",
  PAYMENT_DASHBOARD_BY_TOKEN_INITIATOR:
    "user/getTransactionsInitiatorByTokenName",
  ADD_PORTFOLIO: "user/addPortfolio",
  EDIT_PORTFOLIO: "user/updatePortfolio",
  DELETE_PORTFOLIO: "user/deletePortfolio",
  GET_TRANSACTION_HISTORY_PROFILE: "user/getHistoryTransactionsUser",
  UPDATE_HIDDEN_TRANSACTION: "user/updateHiddenTransaction/:txId",
  GET_RECENT_UPDATES: "user/getRecentUpdates",
} as const;
