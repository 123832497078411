import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COURSE_FOR_USER, ICourseFilter } from "api/course";

export const initialCourseState: ICourseFilter = {
  type: COURSE_FOR_USER.ALL,
  categories: [],
  page: 1,
  page_size: 3,
  search: "",
};

export const filterCourseSlice = createSlice({
  name: "filter",
  initialState: initialCourseState,
  reducers: {
    setCourseFilter: (state, action: PayloadAction<ICourseFilter>) => {
      state.type = action.payload.type;
      state.categories = action.payload.categories;
      state.search = action.payload.search;
    },
  },
});

export const { setCourseFilter } = filterCourseSlice.actions;

export default filterCourseSlice.reducer;
