import { FC, memo } from "react";
import { ITagWithIconProps } from "./tagWithIcon.props";
import { Container, ContainerNoneIcon } from "./tagWithIcon.style";

const TagWithIcon: FC<ITagWithIconProps> = ({
  children,
  color,
  type,
  ...props
}: ITagWithIconProps) => {
  return !type ? (
    <Container color={color} {...props}>
      {children}
    </Container>
  ) : (
    <ContainerNoneIcon color={color} {...props}>
      {children}
    </ContainerNoneIcon>
  );
};

export default memo(TagWithIcon);
