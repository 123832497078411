import { FC, memo } from "react";
import { TransactionWrapper, FundAndWalletWrapper } from "./transaction.style";
import Wallet from "./wallet";
import Transactions from "./transactions";
import { TransactionProps } from "../OrganizationPage.props";
import Overview from "./overview";

const Transaction: FC<TransactionProps> = ({ project }) => {
  return (
    <TransactionWrapper>
      <FundAndWalletWrapper>
        {/* <Fund /> */}
        <Overview project={project} />
        <Wallet project={project} />
      </FundAndWalletWrapper>
      <Transactions project={project} />
    </TransactionWrapper>
  );
};

export default memo(Transaction);
