export function CloseIcon(props: { callback: () => void }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.callback}
    >
      <path
        d="M15.625 4.375L4.375 15.625"
        stroke="#080F6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.625 15.625L4.375 4.375"
        stroke="#080F6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AttachIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8355_13807)">
        <path
          d="M12.3926 6.30241L8.86596 12.4108C8.16929 13.6174 6.66595 14.1813 5.39839 13.6034C5.05908 13.4503 4.75566 13.2277 4.50774 12.9499C4.25982 12.6722 4.07291 12.3456 3.95908 11.9911C3.84525 11.6367 3.80703 11.2623 3.84689 10.8922C3.88675 10.522 4.0038 10.1643 4.19049 9.84226L8.31049 2.70621C8.74716 1.94988 9.68653 1.57617 10.4804 1.93445C10.6939 2.02961 10.8849 2.1686 11.0411 2.34238C11.1974 2.51616 11.3153 2.72086 11.3873 2.94318C11.4593 3.16551 11.4838 3.40049 11.459 3.63287C11.4343 3.86525 11.361 4.08983 11.2439 4.29205L7.74391 10.3542C7.56057 10.6718 7.15077 10.7816 6.83322 10.5982C6.51568 10.4149 6.40587 10.0051 6.58921 9.68756L9.50587 4.63575C9.64254 4.39903 9.55957 4.0894 9.32286 3.95273C9.08615 3.81607 8.77651 3.89903 8.63985 4.13575L5.76985 9.10673C5.33318 9.86306 5.47922 10.8634 6.18645 11.3718C6.37558 11.5091 6.59146 11.605 6.82008 11.6535C7.0487 11.7019 7.28495 11.7017 7.51348 11.6529C7.74202 11.604 7.95774 11.5077 8.14663 11.3701C8.33553 11.2325 8.49338 11.0568 8.60993 10.8542L12.0533 4.8902C12.7499 3.68354 12.4866 2.09967 11.3524 1.29086C11.0499 1.0741 10.7054 0.923073 10.341 0.847512C9.97663 0.77195 9.60047 0.773526 9.23675 0.852137C8.87302 0.930749 8.52978 1.08465 8.22914 1.30395C7.92849 1.52324 7.67709 1.80305 7.49113 2.12538L3.40113 9.20947C2.44446 10.8665 2.80023 13.0503 4.36145 14.1595C6.16094 15.4293 8.60198 14.868 9.67532 13.0089L13.2587 6.80241C13.3953 6.5657 13.3124 6.25607 13.0756 6.1194C12.8389 5.98273 12.5293 6.0657 12.3926 6.30241Z"
          fill="#292E73"
          fillOpacity="0.82"
        />
      </g>
      <defs>
        <clipPath id="clip0_8355_13807">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function SubmitLinkIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96856 8.39766C6.94506 8.37439 6.91334 8.36134 6.88027 8.36134C6.84721 8.36134 6.81549 8.37439 6.79199 8.39766L4.97637 10.2133C4.13574 11.0539 2.71699 11.143 1.78887 10.2133C0.85918 9.28359 0.948242 7.86641 1.78887 7.02578L3.60449 5.21016C3.65293 5.16172 3.65293 5.08203 3.60449 5.03359L2.98262 4.41172C2.95912 4.38846 2.9274 4.37541 2.89434 4.37541C2.86127 4.37541 2.82955 4.38846 2.80606 4.41172L0.99043 6.22734C-0.331445 7.54922 -0.331445 9.68828 0.99043 11.0086C2.3123 12.3289 4.45137 12.3305 5.77168 11.0086L7.58731 9.19297C7.63574 9.14453 7.63574 9.06484 7.58731 9.01641L6.96856 8.39766ZM11.0092 0.991406C9.68731 -0.330469 7.54824 -0.330469 6.22793 0.991406L4.41074 2.80703C4.38748 2.83052 4.37443 2.86225 4.37443 2.89531C4.37443 2.92837 4.38748 2.9601 4.41074 2.98359L5.03106 3.60391C5.07949 3.65234 5.15918 3.65234 5.20762 3.60391L7.02324 1.78828C7.86387 0.947656 9.28262 0.858594 10.2107 1.78828C11.1404 2.71797 11.0514 4.13516 10.2107 4.97578L8.39512 6.79141C8.37185 6.8149 8.35881 6.84663 8.35881 6.87969C8.35881 6.91275 8.37185 6.94448 8.39512 6.96797L9.01699 7.58984C9.06543 7.63828 9.14512 7.63828 9.19356 7.58984L11.0092 5.77422C12.3295 4.45234 12.3295 2.31328 11.0092 0.991406V0.991406ZM7.53262 3.81797C7.50912 3.79471 7.4774 3.78166 7.44434 3.78166C7.41128 3.78166 7.37955 3.79471 7.35606 3.81797L3.81699 7.35547C3.79373 7.37896 3.78068 7.41069 3.78068 7.44375C3.78068 7.47681 3.79373 7.50854 3.81699 7.53203L4.43574 8.15078C4.48418 8.19922 4.56387 8.19922 4.61231 8.15078L8.14981 4.61328C8.19824 4.56484 8.19824 4.48516 8.14981 4.43672L7.53262 3.81797Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
    </svg>
  );
}

export function PlusIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 5.25V0H5.25V5.25H0V6.75H5.25V12H6.75V6.75H12V5.25H6.75Z"
        fill="#7E00FD"
      />
    </svg>
  );
}
export function PurpleAttachIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8355_13807)">
        <path
          d="M12.3926 6.30241L8.86596 12.4108C8.16929 13.6174 6.66595 14.1813 5.39839 13.6034C5.05908 13.4503 4.75566 13.2277 4.50774 12.9499C4.25982 12.6722 4.07291 12.3456 3.95908 11.9911C3.84525 11.6367 3.80703 11.2623 3.84689 10.8922C3.88675 10.522 4.0038 10.1643 4.19049 9.84226L8.31049 2.70621C8.74716 1.94988 9.68653 1.57617 10.4804 1.93445C10.6939 2.02961 10.8849 2.1686 11.0411 2.34238C11.1974 2.51616 11.3153 2.72086 11.3873 2.94318C11.4593 3.16551 11.4838 3.40049 11.459 3.63287C11.4343 3.86525 11.361 4.08983 11.2439 4.29205L7.74391 10.3542C7.56057 10.6718 7.15077 10.7816 6.83322 10.5982C6.51568 10.4149 6.40587 10.0051 6.58921 9.68756L9.50587 4.63575C9.64254 4.39903 9.55957 4.0894 9.32286 3.95273C9.08615 3.81607 8.77651 3.89903 8.63985 4.13575L5.76985 9.10673C5.33318 9.86306 5.47922 10.8634 6.18645 11.3718C6.37558 11.5091 6.59146 11.605 6.82008 11.6535C7.0487 11.7019 7.28495 11.7017 7.51348 11.6529C7.74202 11.604 7.95774 11.5077 8.14663 11.3701C8.33553 11.2325 8.49338 11.0568 8.60993 10.8542L12.0533 4.8902C12.7499 3.68354 12.4866 2.09967 11.3524 1.29086C11.0499 1.0741 10.7054 0.923073 10.341 0.847512C9.97663 0.77195 9.60047 0.773526 9.23675 0.852137C8.87302 0.930749 8.52978 1.08465 8.22914 1.30395C7.92849 1.52324 7.67709 1.80305 7.49113 2.12538L3.40113 9.20947C2.44446 10.8665 2.80023 13.0503 4.36145 14.1595C6.16094 15.4293 8.60198 14.868 9.67532 13.0089L13.2587 6.80241C13.3953 6.5657 13.3124 6.25607 13.0756 6.1194C12.8389 5.98273 12.5293 6.0657 12.3926 6.30241Z"
          fill="#7E00FD"
          fillOpacity="0.82"
        />
      </g>
      <defs>
        <clipPath id="clip0_8355_13807">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function SuccessIcon() {
  return (
    <svg
      width="117"
      height="125"
      viewBox="0 0 117 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.0399 80.8932C72.9189 83.2088 71.4323 85.3473 69.4582 87.2498C60.4883 96.1454 45.9529 96.0284 37.2382 86.9956C33.0047 82.6896 30.6901 76.9057 30.6717 70.729C30.7751 64.6113 33.2454 58.8873 37.5514 54.6538C40.78 51.4029 44.9322 49.3514 49.2809 48.448C49.6426 48.3223 49.9492 48.6212 50.0749 48.9829C50.2007 49.3446 49.9018 49.6512 49.5401 49.777C45.3723 50.6175 41.5818 52.5433 38.4713 55.5504C34.4051 59.5992 32.1118 64.9576 32.0635 70.6506C32.0152 76.3437 34.204 81.7659 38.1938 85.9539C46.4172 94.448 60.1662 94.6356 68.5974 86.2314C77.0915 78.008 77.279 64.259 68.8748 55.8278C65.8677 52.7172 62.0269 50.7074 57.7769 49.8534C57.3523 49.7982 57.2266 49.4365 57.2817 49.0119C57.3368 48.5873 57.6986 48.4615 58.1232 48.5167C62.6169 49.4887 66.7015 51.6165 69.8933 54.967C76.7055 62.0257 78.1109 72.4842 74.0399 80.8932Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <path
        d="M86.471 86.9121C84.642 90.69 82.2037 94.173 79.1521 97.0582C72.2153 103.929 62.9358 107.56 53.1853 107.503C43.4347 107.446 34.2393 103.446 27.3682 96.5092C13.2564 82.1557 13.442 58.778 27.9173 44.7252C33.8395 39.0176 41.2436 35.5317 49.4059 34.519C49.7087 34.5151 50.0743 34.6921 50.141 35.1757C50.1449 35.4785 49.9679 35.8441 49.4843 35.9108C41.5618 36.7388 34.3386 40.1619 28.5972 45.8066C14.9044 59.486 14.6443 81.7746 28.3277 95.7702C35.0141 102.467 43.722 106.232 53.1698 106.292C62.6176 106.353 71.5353 102.848 78.2323 96.1616C84.9293 89.4752 88.6936 80.7673 88.7545 71.3195C88.8154 61.8717 85.3101 52.954 78.6237 46.257C73.1009 40.5746 65.9696 36.9718 58.1457 36.0423C57.7211 35.9872 57.5954 35.6255 57.5915 35.3227C57.6466 34.8981 58.0084 34.7724 58.3111 34.7685C66.5005 35.875 73.8756 39.5958 79.6422 45.3962C86.5134 52.333 90.1444 61.6125 90.0873 71.363C89.9761 76.8752 88.772 82.1591 86.471 86.9121Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <path
        d="M46.6896 77.7316C42.9474 73.9643 43.0509 67.8466 46.8182 64.1044C50.5854 60.3622 56.7031 60.4657 60.4453 64.233C64.1875 68.0003 64.084 74.1179 60.3167 77.8601C56.4276 81.5433 50.2509 81.5617 46.6896 77.7316Z"
        fill="#E94600"
      />
      <path
        d="M62.8295 75.4693C62.2985 76.5661 61.7046 77.4821 60.6861 78.3429C58.712 80.2454 56.1209 81.2475 53.3962 81.2823C50.6716 81.3172 48.0533 80.2 46.1508 78.226C44.2482 76.2519 43.2462 73.6607 43.2113 70.936C43.1765 68.2114 44.2936 65.5931 46.2677 63.6906C48.2418 61.7881 50.833 60.786 53.5576 60.7512C56.2823 60.7163 58.9005 61.8335 60.8031 63.8075C62.7056 65.7816 63.7077 68.3728 63.7425 71.0975C63.7618 72.6111 63.4785 74.1287 62.8295 75.4693ZM45.4021 67.0323C44.8121 68.251 44.5248 69.4659 44.4813 70.7987C44.5123 73.2206 45.3886 75.45 46.9845 77.1253C48.7023 78.8595 50.9549 79.7996 53.3179 79.8905C55.7398 79.8595 57.9692 78.9832 59.6444 77.3873C61.3786 75.6695 62.3188 73.4168 62.4097 71.0539C62.3787 68.632 61.5024 66.4026 59.9065 64.7274C58.1887 62.9932 55.936 62.053 53.5731 61.9621C51.1512 61.9931 48.9217 62.8694 47.2465 64.4653C46.5308 65.3223 45.8151 66.1792 45.4021 67.0323Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <path
        d="M54.0527 71.2181C53.9347 71.4618 53.6948 71.6465 53.4511 71.5285C53.1483 71.5324 52.8417 71.2335 52.716 70.8718L53.3289 14.6058C53.325 14.303 53.6239 13.9964 53.9856 13.8707C54.2884 13.8668 54.595 14.1657 54.7207 14.5274L54.1707 70.9743C54.1117 71.0962 54.0527 71.2181 54.0527 71.2181Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <path
        d="M53.998 28.7698L54.2407 14.5962L61.4329 8.75124L61.3711 22.862L53.998 28.7698Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <path
        d="M61.9781 23.1544C61.9191 23.2763 61.9191 23.2763 61.8601 23.3982L54.5461 29.1841C54.3062 29.3689 54.0035 29.3727 53.8816 29.3137C53.6378 29.1957 53.4531 28.9559 53.5711 28.7121L53.7548 14.6604C53.6919 14.4795 53.8099 14.2358 53.9908 14.1729L61.3049 8.38695C61.5447 8.20221 61.8475 8.19833 61.9694 8.25733C62.2131 8.37533 62.3978 8.6152 62.2798 8.85894L62.0961 22.9107C62.0371 23.0326 62.0371 23.0326 61.9781 23.1544ZM54.7037 27.3048L60.6376 22.5054L60.7816 10.0892L54.8478 14.8886L54.7037 27.3048Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <path
        d="M53.8723 28.7091L54.056 14.6574L46.8937 8.63259L46.8319 22.7434L53.8723 28.7091Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <path
        d="M54.4821 29.005C54.4231 29.1269 54.3641 29.2488 54.1832 29.3117C54.0023 29.3745 53.6996 29.3784 53.4558 29.2604L46.4154 23.2946C46.2936 23.2356 46.1088 22.9958 46.2268 22.752L46.4105 8.70027C46.4066 8.39753 46.5246 8.15379 46.8274 8.14992C47.0082 8.08705 47.311 8.08318 47.5547 8.20118L54.5951 14.1669C54.717 14.2259 54.9018 14.4658 54.7838 14.7096L54.6001 28.7613C54.6001 28.7613 54.5411 28.8832 54.4821 29.005ZM47.4339 22.4338L53.1889 27.326L53.2739 15.0316L47.5189 10.1395L47.4339 22.4338Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
    </svg>
  );
}

export function MessageIcon() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.683594" width="32" height="32" rx="4" fill="white" />
      <path
        d="M10.2327 19.8276C9.30089 18.2567 8.97456 16.3998 9.315 14.6053C9.65543 12.8109 10.6392 11.2025 12.0816 10.0821C13.524 8.96173 15.3258 8.40643 17.1487 8.5205C18.9715 8.63457 20.6901 9.41016 21.9815 10.7016C23.273 11.9931 24.0486 13.7116 24.1627 15.5345C24.2768 17.3573 23.7215 19.1591 22.6011 20.6015C21.4807 22.044 19.8723 23.0277 18.0779 23.3682C16.2835 23.7086 14.4265 23.3823 12.8557 22.4505L12.8557 22.4504L10.2653 23.1905C10.1582 23.2211 10.0447 23.2225 9.93685 23.1946C9.82895 23.1666 9.73049 23.1103 9.65168 23.0315C9.57287 22.9527 9.51656 22.8542 9.4886 22.7463C9.46064 22.6384 9.46205 22.525 9.49267 22.4179L10.2328 19.8275L10.2327 19.8276Z"
        stroke="#292E73"
        strokeOpacity="0.82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9336 16.9414C13.4514 16.9414 13.8711 16.5217 13.8711 16.0039C13.8711 15.4861 13.4514 15.0664 12.9336 15.0664C12.4158 15.0664 11.9961 15.4861 11.9961 16.0039C11.9961 16.5217 12.4158 16.9414 12.9336 16.9414Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <path
        d="M16.6836 16.9414C17.2014 16.9414 17.6211 16.5217 17.6211 16.0039C17.6211 15.4861 17.2014 15.0664 16.6836 15.0664C16.1658 15.0664 15.7461 15.4861 15.7461 16.0039C15.7461 16.5217 16.1658 16.9414 16.6836 16.9414Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <path
        d="M20.4336 16.9414C20.9514 16.9414 21.3711 16.5217 21.3711 16.0039C21.3711 15.4861 20.9514 15.0664 20.4336 15.0664C19.9158 15.0664 19.4961 15.4861 19.4961 16.0039C19.4961 16.5217 19.9158 16.9414 20.4336 16.9414Z"
        fill="#292E73"
        fillOpacity="0.82"
      />
      <rect
        x="1.18359"
        y="0.5"
        width="31"
        height="31"
        rx="3.5"
        stroke="#292E73"
        strokeOpacity="0.82"
      />
    </svg>
  );
}
export function ToogleIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M12.6836 22C18.1976 22 22.6836 17.514 22.6836 12C22.6836 6.486 18.1976 2 12.6836 2C7.1686 2 2.6836 6.486 2.68359 12C2.68359 17.514 7.16859 22 12.6836 22ZM12.6836 8L17.6836 14L7.68359 14L12.6836 8Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export function PurpleAttachIcon2() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8164 10.125V6C10.8164 5.20435 10.5003 4.44129 9.93773 3.87868C9.37512 3.31607 8.61206 3 7.81641 3C7.02076 3 6.25769 3.31607 5.69509 3.87868C5.13248 4.44129 4.81641 5.20435 4.81641 6V10.125C4.81641 11.4179 5.33002 12.6579 6.24426 13.5721C7.1585 14.4864 8.39848 15 9.69141 15C10.9843 15 12.2243 14.4864 13.1386 13.5721C14.0528 12.6579 14.5664 11.4179 14.5664 10.125V3H16.0664V10.125C16.0664 11.8158 15.3948 13.4373 14.1992 14.6328C13.0037 15.8284 11.3822 16.5 9.69141 16.5C8.00065 16.5 6.37915 15.8284 5.1836 14.6328C3.98806 13.4373 3.31641 11.8158 3.31641 10.125V6C3.31641 4.80653 3.79051 3.66193 4.63443 2.81802C5.47834 1.97411 6.62293 1.5 7.81641 1.5C9.00988 1.5 10.1545 1.97411 10.9984 2.81802C11.8423 3.66193 12.3164 4.80653 12.3164 6V10.125C12.3164 10.8212 12.0398 11.4889 11.5476 11.9812C11.0553 12.4734 10.3876 12.75 9.69141 12.75C8.99521 12.75 8.32753 12.4734 7.83525 11.9812C7.34297 11.4889 7.06641 10.8212 7.06641 10.125V6H8.56641V10.125C8.56641 10.4234 8.68493 10.7095 8.89591 10.9205C9.10689 11.1315 9.39304 11.25 9.69141 11.25C9.98977 11.25 10.2759 11.1315 10.4869 10.9205C10.6979 10.7095 10.8164 10.4234 10.8164 10.125V10.125Z"
        fill="#7E00FD"
      />
    </svg>
  );
}
