import styled from "styled-components";
import { Avatar, Progress as AProgress } from "antd";
import OutlinedButton from "components/base/outlinedButton";

export const Container = styled.div``;

export const Wrap = styled.div`
  padding: 0px 50px;
  & > div {
    /* max-width: 1170px; */
    /* margin: auto; */

    & > div {
      margin-left: 0;
      margin-right: 0;
      max-width: unset;
    }
  }
`;

export const ContainerFluidHeader = styled.div`
  background: linear-gradient(
    90deg,
    rgba(208, 234, 255, 0.58) 0%,
    rgba(248, 233, 245, 0.75) 40%,
    rgba(246, 244, 241, 0.83) 60%,
    rgba(233, 247, 251, 0.92) 81%,
    rgba(233, 247, 250, 0.92) 100%
  );
  // height: 13.625rem;
  background-repeat: repeat;
  background-size: cover;
  margin-bottom: 2.5rem;
  padding: 0px 50px;
  padding-top: 2.625rem;
  padding-bottom: 2.5625rem;
  & > div {
    max-width: 840px;
    /* margin: auto; */
  }
`;

export const ProfileBox = styled.div`
  max-width: 73.125rem;
  display: flex;
  justify-content: space-between;
  column-gap: 1.5rem;
  margin: auto;
`;

export const AvatarProfile = styled(Avatar)`
  height: 6.375rem;
  width: 6.375rem;
  border-radius: 50%;
  margin-top: 0rem;
  flex-shrink: 0;
`;

export const InfoProfile = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: center;
  max-width: 520px;
  flex-grow: 1;
`;

export const UserNameBlock = styled.div`
  display: flex;
  column-gap: 0.375rem;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

export const TwitterName = styled.div`
  color: #080f6c;
  font: normal 400 1.125rem/100% "DM Sans" !important;
  letter-spacing: -0.04em;
`;

export const UserName = styled.div`
  font: normal 700 1.75rem/100% "DM Sans";
  letter-spacing: -0.04em;
  color: #080f6c;
  word-break: break-all;
`;

export const JobInfo = styled.div`
  font: normal 400 1rem/156% "DM Sans" !important;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const LocationBlock = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

export const Location = styled.div`
  color: #080f6c;
  letter-spacing: -0.04em;
  span {
    font: normal 500 1.125rem/100% "DM Sans" !important;
  }
  #text {
    font: normal 400 1.125rem/100% "DM Sans" !important;
  }
`;

export const ShortBio = styled.div`
  color: #080f6c;
  font: normal 500 1rem/156% "DM Sans" !important;
  letter-spacing: -0.02em;
  word-break: break-all;
`;

export const PercentText = styled.span<{ cl?: string }>`
  font: normal 500 1rem/120% "DM Sans";
  letter-spacing: -0.02em;
  color: ${({ cl }) => cl || "#080F6C"};
`;

export const TitleHeader = styled.div`
  max-width: 52.5rem;
  margin: auto;
  margin-bottom: 1.5rem;
  letter-spacing: -0.02em;
  a {
    font: normal 500 1rem/125% "DM Sans" !important;
    color: rgba(126, 0, 253, 1);
  }
  #text {
    color: rgba(41, 46, 115, 0.82);
    font: normal 400 1rem/125% "DM Sans" !important;
  }
`;

export const BioWrapper = styled.div`
  display: flex;
  column-gap: 1.5rem;
`;

export const PercentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Progress = styled(AProgress)``;

export const DividedLine = styled.div`
  height: 1px;
  background: rgba(41, 46, 115, 0.12);
`;

export const EditProfileBtn = styled(OutlinedButton)`
  max-width: 120px;
  width: auto;
  height: auto;
  background: rgba(156, 138, 193, 0.16);
  padding-block: 8px;
  gap: 4px;
  font-family: "Rubik";
  color: #7e00fd;

  &:hover,
  &:focus {
    color: #7e00fd;
  }
`;
