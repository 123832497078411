import { FC, memo } from "react";
import { ISkillProps } from "./skill.props";
import {
  ContainerItem,
  ContainerSkill,
  PropertyName,
  ExperienceBlock,
  ExperienceValue,
  Item,
  Title,
  TitleBlock,
  SpecialtiesBlock,
  SpecialtiesValue,
  ExpertAtBlock,
  ExpertAtValue,
  LevelBlock,
  LevelValue,
} from "./skill.style";
import {
  SkillICon,
  SkillExperienceIcon,
} from "assets/images/profile-preview/index";
import { useQuery } from "@tanstack/react-query";
import { getSkillsByIdAPI } from "api/users";
import { useAppSelector } from "redux/hooks";

const Skill: FC<ISkillProps> = ({ profileId, ...props }: ISkillProps) => {
  const id: string = useAppSelector((state) => state.auth.user?._id);
  const colorOfSkills = ["7E00FD", "0093FD", "1E03CA"];
  // const [skills, setSkills] = useState<Array<ISkill>>([]);

  const { data: skillRes } = useQuery(
    ["getSkills"],
    () => getSkillsByIdAPI(id === profileId ? id : profileId),
    {
      // onSuccess: (res) => {
      //   setSkills(res.responseData);
      // },
      refetchOnWindowFocus: false,
    }
  );
  const skills = skillRes?.responseData;
  const specialties = skills?.specialty
    ?.split(",")
    ?.map((specialty: any) => specialty.trim());

  return (
    <ContainerSkill>
      <TitleBlock>
        <SkillICon />
        <Title>Skills</Title>
      </TitleBlock>
      <ContainerItem>
        <Item>
          <PropertyName>Experience</PropertyName>
          {skills?.time && (
            <ExperienceBlock>
              <SkillExperienceIcon />
              <ExperienceValue>{skills?.time}</ExperienceValue>
            </ExperienceBlock>
          )}
        </Item>
        <Item>
          <PropertyName>Specialties in</PropertyName>
          <SpecialtiesBlock>
            {specialties?.map((specialty: any) => (
              <SpecialtiesValue key={specialty}>
                <div>{specialty}</div>
              </SpecialtiesValue>
            ))}
          </SpecialtiesBlock>
        </Item>
        <Item>
          <PropertyName>Expert at</PropertyName>
          <ExpertAtBlock>
            {skills?.skillIds?.map((skill: any, index: number) => (
              <ExpertAtValue key={skill?._id} bg={"#" + colorOfSkills[index]}>
                {skill?.skillDetail?.name}
              </ExpertAtValue>
            ))}
          </ExpertAtBlock>
        </Item>
        <Item>
          <PropertyName>Builder Level</PropertyName>
          <LevelBlock>
            {skills?.skillIds?.map((skill: any) => (
              <LevelValue key={skill?._id}>{skill?.level}</LevelValue>
            ))}
          </LevelBlock>
          {/* {skill?.level && <LevelValue>{skill?.level}</LevelValue>} */}
        </Item>
      </ContainerItem>
    </ContainerSkill>
  );
};

export default memo(Skill);
