import { Pagination, Tag, Tooltip } from "antd";
import PrimaryButton from "components/base/primaryButton";
import styled from "styled-components";

const { CheckableTag: Checkable } = Tag;

export const Container = styled.div`
  .wrap-icon {
    transition: 0.3s ease-in-out;
    .arrow-icon {
      position: absolute;
    }
    cursor: pointer;
    width: 350px;
    height: 140px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgba(41, 46, 115, 0.12);
    border-radius: 16px;
    :hover {
      box-shadow: 0px 0px 20px 5px rgba(126, 0, 253, 0.2);
      border: 1.5px solid rgba(0, 191, 251, 1);
      background-image: radial-gradient(
        100% 445.32% at 0% 100%,
        #7e00fd 0%,
        #b93df3 42.35%,
        #a54bf4 57.95%,
        #00bffb 100%
      );
      border-radius: 16px;
      z-index: 0;
      position: relative;
      :before {
        /* specify the value of border width here */
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        /* --- */
        content: "";
        position: absolute;
        background-color: #fff;
        border-radius: 13px;
        box-sizing: border-box;
        z-index: -1;
      }
    }
  }
`;

export const HeaderContent = styled.div`
  position: relative;
  z-index: 1;
  width: 546px;
  padding-top: 90px;
  h1 {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 64px;
    line-height: 120%;
    text-align: center;
    color: #111111;
    line-height: 120%;
    margin-bottom: 18px;
    margin-top: 14px;
  }
  span {
    max-width: 585.4px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 156%;
    color: rgba(17, 17, 17, 0.6);
  }
  span:first-child {
    display: flex;
    align-items: center;
    color: #111111;
    font-size: 16px;
  }
`;

export const Heading = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(208, 234, 255, 0.28) 0%,
    rgba(248, 233, 245, 0.35) 40%,
    rgba(246, 244, 241, 0.43) 60%,
    rgba(233, 247, 251, 0.52) 81%,
    rgba(233, 247, 250, 0.52) 100%
  );

  &:before {
    content: "";
    position: absolute;
    bottom: -45px;
    left: 0;
    width: 100%;
    height: 75px;
    background: linear-gradient(
      90deg,
      rgba(208, 234, 255, 0.7) 0%,
      rgba(248, 233, 245, 0.7) 40%,
      rgba(246, 244, 241, 0.8) 60%,
      rgba(233, 247, 251, 0.8) 81%,
      rgba(233, 247, 250, 0.8) 100%
    );
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
`;

export const PrimaryButtonStyled = styled(PrimaryButton)`
  width: fit-content !important;
`;

export const FirstBlog = styled.div`
  max-width: 73.125rem;
  margin: auto;
  border: 1px solid rgba(17, 17, 17, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 56px;
  margin-bottom: 40px;
  img.first-blog--img {
    width: 654px;
    height: 472px;
  }
  border: 1px solid rgba(17, 17, 17, 0.1);
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  :hover {
    box-shadow: rgb(0 0 0 / 24%) 0px 4px 9px;
  }
  .skeleton-img {
    width: 654px;
    height: 472px;
  }
`;

export const BlogList = styled.div`
  max-width: 73.125rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(374px, 1fr));
  gap: 24px;
  margin: auto;
`;

export const BlogImage = styled.img`
  border-right: 1px solid rgba(17, 17, 17, 0.1);
  width: 374px;
  height: 270px;
  object-fit: cover;
`;

export const BlogInfoSection = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  .react-loading-skeleton {
    width: 90px;
    height: 27.38px;
    border-radius: 20px;
  }
`;

export const TooltipStyled = styled(Tooltip)``;

export const BlogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 139%;
    color: #080f6c;
    margin-bottom: unset;
  }
  p,
  .paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(41, 46, 115, 0.82);
    word-break: break-word;
    text-align: justify;
  }
  padding: 24px 56px 24px 0;
  .block-content--context {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const BlogItem = styled(FirstBlog)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 374px;
  height: 563px;
  border-radius: 6px;
  gap: 20px;
  ${BlogImage} {
    width: 100%;
    border-bottom: 1px solid rgba(17, 17, 17, 0.1);
    border-radius: 6px 6px 0px 0px;
    border-right: none;
  }
  ${BlogContent} {
    padding: 0 16px 24px;
    flex-grow: 1;
  }
  cursor: pointer;
  transition: 0.3s;
  :hover {
    box-shadow: rgb(0 0 0 / 24%) 0px 4px 9px;
  }
`;

export const BlogTag = styled.div`
  background: #080f6c;
  border-radius: 20px;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding: 6px 8px;
  width: fit-content;
`;

export const TagSection = styled.div`
  width: fit-content;
  padding: 8px;
  height: 56px;
  background: #ffffff;
  border: 1px solid rgba(17, 17, 17, 0.08);
  box-shadow: 0px 4px 10px rgba(17, 17, 17, 0.02);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 75px auto;
  gap: 8px;
  .ant-tag-checkable-checked {
    background: #7e00fd;
  }
  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #7e00fd;
  }
`;

export const CheckableTag = styled(Checkable)`
  font-size: 16px;
  line-height: 150%;
  padding: 8px 16px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  user-select: none;
  &:active {
    background-color: #7e00fd;
    color: #fff !important;
  }
`;

export const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`;
