import React, { ReactNode, useRef, useState } from "react";

const CopyTextComponent = ({
  children,
  textToCopy,
  cpnForSetTimeout,
}: {
  children: ReactNode;
  textToCopy: string;
  cpnForSetTimeout?: ReactNode;
}) => {
  const [isCopying, setIsCopying] = useState<boolean>(false);

  const textRef = useRef<any>(null);

  const handleCopy = () => {
    // Select the text
    textRef.current.select();

    try {
      // Execute copy command
      document.execCommand("copy");
      setIsCopying(true);
      if (cpnForSetTimeout) {
        setTimeout(() => {
          setIsCopying(false);
        }, 2000);
      }
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  };
  return (
    <>
      <input
        type="text"
        value={textToCopy}
        readOnly
        ref={textRef}
        style={{ position: "absolute", left: "-9999px" }}
      />
      <div onClick={handleCopy}>
        {isCopying && cpnForSetTimeout ? cpnForSetTimeout : children}
      </div>
    </>
  );
};

export default CopyTextComponent;
