import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  .ant-tooltip {
    max-width: 280px;
    width: 100%;
  }

  .ant-tooltip-inner {
    background-color: #fff;
    color: rgba(41, 46, 115, 0.82);
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  .ant-tooltip-arrow-content:before {
    background: #fff;
  }
`;
