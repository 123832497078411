import PrimaryButton from "components/base/primaryButton";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import styled from "styled-components";

export const BudgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

export const BuildBtn = styled(PrimaryButton)``;

export const ProjectCardFooter = styled(CommonFlexRow)`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 16px;
  justify-content: space-between;
`;

export const ProjectDesc = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 0) 36.09%,
  //   #ffffff 96.24%
  // );
  margin-top: 10px;
  margin-bottom: 0;
  word-break: break-word;

  @supports (-webkit-line-clamp: 5) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: initial;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
`;

export const ProjectName = styled.h4`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #080f6c;
  margin-top: 8px;
  word-break: break-word;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
  }
`;

export const CommonProjectText = styled.span`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

export const ProjectSponsorText = styled(CommonProjectText)`
  line-height: 120%;
  letter-spacing: -0.03em;
  color: rgba(41, 46, 115, 0.656);
`;

export const ProjectWorktask = styled(CommonProjectText)`
  line-height: 140%;
  color: rgba(41, 46, 115, 0.82);
`;

export const BudgetText = styled(CommonProjectText)`
  font-size: 10px;
  line-height: 115%;
  color: #080f6c;
`;

export const BudgetNumber = styled(CommonProjectText)`
  font-weight: 500;
  font-size: 18px;
  line-height: 115%;

  background: radial-gradient(
    100% 445.32% at 0% 100%,
    #7e00fd 0%,
    #b93df3 42.35%,
    #a54bf4 57.95%,
    #00bffb 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const SponsorWrapper = styled(CommonFlexRow)`
  flex-wrap: wrap;
`;

export const ProjectCardContent = styled.div`
  flex-grow: 1;
  padding: 16px;
`;

export const ProjectImg = styled.img`
  width: 100%;
  aspect-ratio: 2.9375;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.3s;
  background: #fff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 4px;
  &:hover {
    box-shadow: rgb(0 0 0 / 57%) 2px 5px 9px;
  }
`;

export const TagName = styled.div<{ color: string }>`
  padding: 4px 8px 5px;
  background: ${(p) => p.color};
  border-radius: 20px;
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #ffffff;
`;

export const Calendar = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  opacity: 0.8;
  display: flex;
  align-items: center;
  gap: 10.5px;
`;
