export const InfoIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10C6.32608 10 7.59785 9.47322 8.53553 8.53553C9.47322 7.59785 10 6.32608 10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 0 5 0C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5C0 6.32608 0.526784 7.59785 1.46447 8.53553C2.40215 9.47322 3.67392 10 5 10ZM5.58125 4.1175L4.95625 7.05813C4.9125 7.27063 4.97438 7.39125 5.14625 7.39125C5.2675 7.39125 5.45062 7.3475 5.575 7.2375L5.52 7.4975C5.34062 7.71375 4.945 7.87125 4.60437 7.87125C4.165 7.87125 3.97813 7.6075 4.09937 7.04688L4.56063 4.87937C4.60063 4.69625 4.56437 4.63 4.38125 4.58562L4.09937 4.535L4.15062 4.29688L5.58187 4.1175H5.58125ZM5 3.4375C4.83424 3.4375 4.67527 3.37165 4.55806 3.25444C4.44085 3.13723 4.375 2.97826 4.375 2.8125C4.375 2.64674 4.44085 2.48777 4.55806 2.37056C4.67527 2.25335 4.83424 2.1875 5 2.1875C5.16576 2.1875 5.32473 2.25335 5.44194 2.37056C5.55915 2.48777 5.625 2.64674 5.625 2.8125C5.625 2.97826 5.55915 3.13723 5.44194 3.25444C5.32473 3.37165 5.16576 3.4375 5 3.4375Z"
        fill="#080A25"
      />
    </svg>
  );
};
