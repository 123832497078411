import { Image, Input, Spin, Upload } from "antd";
import { Accepted } from "pages/WorktasksInside/WorktasksInside.style";
import styled from "styled-components";

export const Container = styled.div`
  font-family: "DM Sans";
  padding: 0px 50px;
`;
export const ConversationWrap = styled.div`
  display: flex;
  padding: 0px;
  max-width: 1170px;
  margin: auto;
`;
export const Divider = styled.div`
  border: 0.5px solid rgba(41, 46, 115, 0.12);
  margin: 0px 32px;
  width: 0px;
`;
export const LeftSide = styled.div<{ marginTop?: boolean }>`
  margin: ${(p) => (p.marginTop ? "34px" : "")};
  margin-bottom: 148px;
  width: 316px;
  /* height: 812px; */
  height: calc(100vh - 200px - 34px);
`;

export const SearchInput = styled(Input)`
  width: 316px;
  height: 40px;
  padding: 9px 16px;
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 6px;

  .ant-input {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: rgba(41, 46, 115, 1);

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: rgba(41, 46, 115, 0.42);
    }
  }
`;
export const ChaterWrap = styled.div`
  margin-top: 12px;
  width: 316px;
  height: 760px;
  height: calc(100vh - 200px - 34px - 40px - 12px);
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: rgba(8, 15, 108, 0.05);
    height: 386px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(8, 15, 108, 0.08);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(8, 15, 108, 0.08);
    height: 239px;
  }
`;
export const Item = styled.div`
  width: 100%;
  height: 76px;
  padding: 0px 20px 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(41, 46, 115, 0.12);

  position: relative;
  cursor: pointer;

  .ant-spin {
    width: unset !important;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;

    & > div:nth-child(1) {
      position: relative;
      & > span {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & > label {
        position: absolute;
        right: 0px;
        bottom: 0px;
        transform: translate(-2px, 0px);
        width: 8px;
        height: 8px;
        background: #00de8c;
        border-radius: 8px;
      }
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      width: calc(100% - 36px - 12px);
      height: max-content;

      & > div {
        width: calc(100% - 45px);
        div {
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: -0.04em;
          color: #080f6c;
          margin-bottom: 6px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      & > p {
        margin: 0;
        margin-top: 3px;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        text-align: right;
        letter-spacing: -0.02em;
        color: rgba(41, 46, 115, 0.42);
      }
      & > span {
        width: 13px;
        height: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 8px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #ffffff;
        background: #7e00fd;
        border-radius: 13px;

        position: absolute;
        right: 0px;
        bottom: 6px;
        transform: translate(2px, 50%);
      }
    }
  }
`;
export const LastMessage = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
  width: 175px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 1px 0px;
`;
export const LastUnreadMessage = styled(LastMessage)`
  font-weight: 500;
  color: #080f6c;
`;
export const ItemBackground = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: radial-gradient(
    100% 445.32% at 0% 100%,
    #7e00fd 0%,
    #b93df3 42.35%,
    #a54bf4 57.95%,
    #00bffb 100%
  );
  opacity: 0.14;
  border-radius: 8px;
`;
export const RightSide = styled(LeftSide)`
  width: calc(100% - 316px - 1px - 32px * 2);
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #9c8ac1 22.35%
      ),
      radial-gradient(
        100% 445.32% at 0% 100%,
        #7e00fd 0%,
        #b93df3 42.35%,
        #a54bf4 57.95%,
        #00bffb 100%
      );
    opacity: 0.13;
    border-radius: 8px;
    z-index: -1;
  }

  .group-case {
    margin-top: 12px;
    height: calc(100vh - 200px - 68px - 34px - 12px);
    padding-top: 56px;
  }
`;
export const MessageBoardHead = styled.div`
  width: 100%;
  height: 68px;
  border-bottom: 1px solid rgba(41, 46, 115, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0px 30px 0px 20px;
`;
export const FlexWrap = styled.div`
  display: flex;
  align-items: center;
`;
export const AvatarWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  & > div:nth-child(1) {
    position: relative;
    height: max-content;
    & > span {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > label {
      position: absolute;
      right: 0px;
      bottom: 0px;
      transform: translate(-2px, 0px);
      width: 8px;
      height: 8px;
      background: #00de8c;
      border-radius: 8px;
    }
  }
  & > div:nth-child(2) {
    div {
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: -0.04em;
      color: #080f6c;
      margin-bottom: 6px;
    }
    p {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: rgba(41, 46, 115, 0.82);
    }
  }
`;
export const HeadDivider = styled.div`
  height: 34px;
  width: 0px;
  border: 1px solid rgba(41, 46, 115, 0.12);
  margin-left: 27px;
  margin-right: 25px;
`;
export const CollaboratorWrap = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(41, 46, 115, 0.82);
`;
export const ExtendAccepted = styled(Accepted)`
  left: calc(100% + 8px);
`;
export const ActionWrap = styled(FlexWrap)`
  gap: calc(34px + 2px);
`;

export const MessageBoardBody = styled.div`
  padding: 0px 20px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* height: 744px; */
  height: calc(100vh - 200px - 68px - 34px);
`;
export const ProjectInfo = styled.div`
  position: absolute;
  top: 0px;
  left: 20px;
  width: calc(100% - 40px);
  height: 56px;
  background: #fff;

  & > div {
    display: flex;
    gap: 33px;
    z-index: 2;
    padding: 7px 27px 11px;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    & > div {
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        letter-spacing: -0.02em;
        color: rgba(41, 46, 115, 0.82);
        margin-bottom: 2px;
      }
      div {
        font-size: 14px;
        line-height: 125%;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #080f6c;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      #7e00fd 0%,
      #c143fc 61.44%,
      #00bffb 100%
    );
    opacity: 0.16;
    z-index: 1;
  }
`;

export const ChatInputWrap = styled.div<{ served: boolean }>`
  height: 48px;
  width: 100%;
  position: relative;
  background: ${(props) => (props.served ? "#FFF" : "transparent")};
  border-bottom-left-radius: ${(props) => (props.served ? "8px;" : "0px")};
  border-bottom-right-radius: ${(props) => (props.served ? "8px;" : "0px")};
`;
export const ServedImages = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 220px;
  overflow: auto;
  padding: 14px 16px;
  user-select: none;

  position: absolute;
  bottom: 100%;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &::-webkit-scrollbar-track {
    background-color: rgba(8, 15, 108, 0.05);
    height: 386px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(8, 15, 108, 0.08);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(8, 15, 108, 0.08);
    height: 20px;
  }
`;
export const ImageItem = styled.div`
  height: 168px;
  max-width: 168px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%);

  & > svg {
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
    transform: scale(0.7);
    z-index: 119;
  }

  img {
    max-height: 168px;
  }

  .file-frame {
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 24px 12px 0px;
    flex-direction: column;

    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    -webkit-letter-spacing: -0.02em;
    -moz-letter-spacing: -0.02em;
    -ms-letter-spacing: -0.02em;
    letter-spacing: -0.02em;
    color: rgba(41, 46, 115, 0.82);

    span {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background-color: #7e00fd;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div {
      text-align: center;
      margin: 8px 0px;

      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;
export const ChatInput = styled(Input)`
  height: 48px;
  width: 100%;
  padding: 14px 16px;
  background: #ffffff;
  border-radius: 8px;
  border: none;

  .ant-input {
    font-weight: 300;
    font-size: 14px;
    line-height: 115%;
    color: rgba(41, 46, 115, 1);

    &::placeholder {
      font-weight: 300;
      font-size: 14px;
      line-height: 115%;
      color: rgba(41, 46, 115, 0.42);
    }
  }
  .ant-input-prefix {
    margin-right: 16px;
  }

  .emoji-wrap {
    position: relative;
    width: 20px;
    height: 20px;
    svg {
      width: 20px;
      height: 20px;
    }

    .emoji-position {
      position: absolute;
      bottom: 0px;
      left: 20px;
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
      /* border-radius: 4px;
  overflow: hidden; */
      z-index: 83570;
    }
  }
`;
export const ChatInputSuffix = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  & > div {
    width: 0px;
    height: 20px;
    border: 0.5px solid rgba(41, 46, 115, 0.12);
  }
  & > span {
    height: 20px;
    display: flex;
    align-items: center;
  }
  svg {
    cursor: pointer;
  }
  .select-file-wrap {
    height: 20px;
    .ant-upload.ant-upload-select-picture-card {
      height: 20px;
      width: 20px;
      margin: 0;
      border: none;
      background-color: transparent;
    }
  }
`;

export const ChatBoxSC = styled.div<{ isGroup: boolean | undefined }>`
  max-height: ${(p) => (p.isGroup ? "608px" : "676px")};
  height: ${(p) => (p.isGroup ? "608px" : "676px")};
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;

  &::-webkit-scrollbar-track {
    background-color: rgba(8, 15, 108, 0.05);
    height: 386px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(8, 15, 108, 0.08);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(8, 15, 108, 0.08);
    height: 239px;
  }
`;
export const MessageWrap = styled.div`
  width: 100%;
`;
export const MessageChunk = styled.div`
  width: 100%;
`;
export const Message = styled.div<{ marginBottom: number; side: string }>`
  display: flex;
  flex-direction: ${(p) => (p.side === "left" ? "row" : "row-reverse")};
  width: 100%;
  gap: 9px;
  margin-bottom: ${(p) => (p.marginBottom > 0 ? p.marginBottom + "px" : "0px")};

  & > div:nth-child(1) {
    position: relative;
    width: 24px;
    overflow: hidden;
    & > span {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #ffffff;
      position: absolute;
      bottom: 20px;
      left: 0;
    }
  }
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: ${(p) => (p.side === "left" ? "flex-start" : "flex-end")};
    gap: 6px;
    & > span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: rgba(41, 46, 115, 0.42);
    }
  }
`;
export const MessageTextSC = styled.div<{
  side: string;
}>`
  max-width: 490px;
  padding: 7px 15px;
  background-color: ${(p) => (p.side === "left" ? "#FFF" : "#7E00FD")};
  color: ${(p) => (p.side === "left" ? "rgba(41, 46, 115, 0.82)" : "#FFFFFF")};
  border-radius: 17px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
`;
export const UploadedFile = styled(MessageTextSC)`
  width: 263px;
  height: 74px;
  padding: 13px 16px;
  display: flex;
  align-items: center;
  gap: 9px;
  border-radius: ${(p) =>
    p.side === "left" ? "17px 17px 17px 0px" : "17px 17px 0px 17px"};
  img {
    width: 48px;
    height: 48px;
    max-width: 48px;
    max-height: 48px;
    border-radius: 48px;
    object-fit: cover;
  }
  & > div {
    div {
      width: 174px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  & > a {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #7e00fd;
  }
`;
export const UploadImages = styled(MessageTextSC)`
  padding: 0;
  overflow: hidden;
  background-color: #fff;

  img {
    max-height: 400px;
  }
`;

export const SpindAntd = styled(Spin)``;
export const ItemSpin = styled.div`
  height: 76px;
  padding: 0px 20px 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ItemEnd = styled.div`
  height: 10px;
  width: 100%;
`;

export const UploadAntd = styled(Upload)`
  .ant-upload-picture-card-wrapper {
    width: 20px;
    height: 20px;
  }
  /* .ant-upload-list {
    width: 9.375rem;
    margin-top: 1.875rem;
    margin-bottom: 0.625rem;
    .ant-upload-list-picture-card-container {
      display: block;
      margin: auto;
    }
    .ant-upload-list-picture-card-container {
      width: 9.375rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .ant-upload-list-item {
      border: 0.0625rem solid rgba(41, 46, 115, 0.22);
    }
  }
  .ant-upload {
    width: 9.375rem;
    height: 9.375rem;
    &.ant-upload-select-picture-card {
      border-radius: 0.25rem;
      background-size: cover;
      border: 0.0625rem solid rgba(41, 46, 115, 0.07);
    }
  } */
`;

export const ImageAntd = styled(Image)``;

export const LastImageMessage = styled.span`
  height: max-content;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 22px;
  svg {
    position: absolute;
    left: 0;
    height: 18px;
    width: 18px;
  }
`;

export const Gap = styled.span`
  height: 12px;
`;
