import axios from "api/axios";
import { cleanObject } from "utils/cleanObject";
import {
  ASSIGNMENT_STATUS,
  IAssignmentForm,
  ICourseFilter,
  ICourseGeneralInfo,
  ICourseReward,
  ICourseSection,
} from ".";
import { END_POINT } from "./endpoints";
import { Dayjs } from "dayjs";

export const createCourseAPI = async ({ ...params }: ICourseGeneralInfo) => {
  return await axios.post(`${END_POINT.COURSE}`, cleanObject(params));
};

export const updateCourseAPI = async ({
  courseId,
  sections,
  info,
  rewards,
  txHash,
  chainId,
}: {
  courseId: string;
  sections?: ICourseSection[];
  info?: {
    title: string;
    description: string;
    category: string;
    coverImage: string;
    estimatedTime: number;
    startDate: number | Dayjs | null;
    endDate: number | Dayjs | null;
    range: number;
    noExpire?: boolean;
  };
  rewards?: ICourseReward;
  txHash?: string;
  chainId?: number;
}) => {
  let params: any = {};
  if (sections) params.sections = sections;
  if (txHash) params.txHash = txHash;
  if (info) params = { ...params, ...info };
  if (rewards) params.rewards = rewards;
  if (chainId) params.chainId = chainId;
  return await axios.put(`${END_POINT.COURSE}/${courseId}`, params);
};

export const getCourseByIdAPI = async ({ courseId }: { courseId: string }) => {
  return await axios.get(
    `${END_POINT.COURSE}/${courseId}/getCourseDetailForGuestAndLearner`
  );
};

export const getCourseByIdForLeanerAPI = async ({
  courseId,
}: {
  courseId: string;
}) => {
  return await axios.get(
    `${END_POINT.COURSE}/${courseId}/getCourseDetailForLearner`
  );
};

export const getCourseByIdForCreatorAPI = async (courseId: string) => {
  return await axios.get(
    `${END_POINT.COURSE}/${courseId}/getCourseDetailForCreator`
  );
};

export const getListCourseForCreatorAPI = async () => {
  return await axios.get("user/courses/getCoursesForCreator");
};

export const getListCourseForGuestAPI = async (body: ICourseFilter) => {
  return await axios.post("user/courses/getCoursesForGuest", { ...body });
};

export const getParticipantsByCourseIdAPI = async ({
  ...params
}: {
  courseId: string;
  sortByEmail: number;
  sortByCompletedDate: number;
  page: number;
  page_size: number;
  status: string[];
}) => {
  return await axios.post(
    `user/courses/${params.courseId}/getAllParticipantsAlreadyLearn`,
    params
  );
};

export const getActivityDetailForLearner = async ({
  courseId,
  activityId,
}: {
  courseId: string;
  activityId: string;
}) => {
  return await axios.get(
    `${END_POINT.COURSE}/${courseId}/getActivityDetailForLearner/${activityId}`
  );
};

export const submitAssignmentAPI = async ({
  courseId,
  activityId,
  assignment,
}: {
  courseId: string;
  activityId: string;
  assignment: IAssignmentForm;
}) => {
  return await axios.post(
    `${END_POINT.COURSE}/${courseId}/activities/${activityId}/assignments`,
    assignment
  );
};

export const joinCourseAPI = async ({
  courseId,
  startDate,
}: {
  courseId: string;
  startDate: number;
}) => {
  return await axios.put(`${END_POINT.COURSE}/${courseId}/join`, { startDate });
};

export const bookmarkCourseAPI = async ({ courseId }: { courseId: string }) => {
  return await axios.put(`${END_POINT.COURSE}/${courseId}/bookmarkCourse`);
};

export const getListCourseAPI = async ({
  filters,
}: {
  filters: ICourseFilter;
}) => {
  return await axios.post(`${END_POINT.COURSE}/getCoursesForGuest`, filters);
};

export const updateStatusAssignmentSingleAPI = async ({
  ...params
}: {
  courseId: string;
  activityId: string;
  userId: string;
  status: ASSIGNMENT_STATUS;
  explain: string;
}) => {
  return await axios.put(
    `user/courses/${params.courseId}/updateStatusAssignmentSingle/${params.activityId}`,
    { userId: params.userId, status: params.status, explain: params.explain }
  );
};

export const updateStatusAssignmentsMultipleAPI = async ({
  ...params
}: {
  courseId: string;
  userId: string;
  approvedList: string[];
  rejectedList: string[];
}) => {
  return await axios.put(
    `user/courses/${params.courseId}/updateStatusAssignmentsMultiple`,
    {
      userId: params.userId,
      approvedList: params.approvedList,
      rejectedList: params.rejectedList,
    }
  );
};

export const updateParticipantAPI = async ({
  ...params
}: {
  participationId: string;
  courseId: string;
  canClaimReward: boolean;
  txHash: string;
}) => {
  return await axios.put(
    `user/courses/${params.courseId}/participation/${params.participationId}`,
    {
      canClaimReward: params.canClaimReward,
      txHash: params.txHash,
    }
  );
};

export const getCoursesManagementForLearnerAPI = async ({
  ...params
}: {
  sortByProcess: number;
  search: string;
  page: number;
  page_size: number;
  status: string[];
}) => {
  return await axios.post(
    `user/courses/getCoursesManagementForLearner`,
    params
  );
};

export const submitCodeAPI = async ({
  ...params
}: {
  language_id: number;
  source_code: string;
  stdin: string;
}) => {
  return await axios.post(`user/courses/submitCode`, params);
};

export const getCourseCategoriesAPI = async () => {
  return await axios.get("user/getCourseCategories");
};

export const getAllCourseCategoriesAPI = async () => {
  return await axios.get("user/getAllCourseCategories");
};

export const canDeleteCourseAPI = async (courseIdToDelete: string) => {
  return await axios.post(`user/courses/${courseIdToDelete}/canDeleteCourse`);
};

export const deleteCourseAPI = async (courseIdToDelete: string) => {
  return await axios.delete(`user/courses/${courseIdToDelete}`);
};
