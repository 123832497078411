import {
  Container,
  ContentStyled,
  HighLight,
  Item,
  TitleStyled,
  UlStyled,
  Wrap,
} from "./launchYourProject.style";
import image1 from "./images/three_men.png";
import image2 from "./images/garden.png";
import image3 from "./images/female_male.png";
import image4 from "./images/christmas_gift.png";

export const LaunchYourProject = () => {
  const list = [
    {
      title: (
        <>
          <span>Collaborate</span> with the most talented devs, designers, and
          marketeers in the Web3
        </>
      ),
      ul: [
        "Join forces with the brightest minds in the Web3 ecosystem to contribute to game-changing projects.",
        "Collaborate with Web3 industry players",
        "Create better and more impactful projects",
      ],
      image: image1,
    },
    {
      title: (
        <>
          <span>#BUIDL</span> & test fast - with the support of a huge community
        </>
      ),
      ul: [
        "Bring your ideas to life and create groundbreaking solutions that have the potential to revolutionize decentralized ecosystems.",
        "Turn your ideas into reality with the help of a decentralized team.",
        "Access a global pool of talented contributors with diverse skill sets.",
      ],
      image: image2,
    },
    {
      title: (
        <>
          <span>Manage</span> 100s of collaborators who BUIDL with you
        </>
      ),
      ul: [
        `Effortlessly track the progress of your project with BuidlFi's intuitive project management features. Stay on top of deadlines, milestones, and deliverables, while maintaining complete control over your project.`,
        "Intuitive project management features for effective control of your project.",
        "Never miss deadlines, milestones or deliverables.",
      ],
      image: image3,
    },
    {
      title: (
        <>
          Sustainable <br /> ecosystem & grants <span>management</span>
        </>
      ),
      ul: [
        <div>
          Fairly and transparently distribute rewards to your team members and
          contributors using
          <HighLight> BuidlFi's</HighLight> advanced reward distribution system.
        </div>,
        "Eliminate conflicts and disputes.",
        "Build trust and loyalty with transparent rewards.",
        "Automate the process, saving time and effort.",
      ],
      image: image4,
    },
  ];

  return (
    <Container>
      <div>
        <TitleStyled className="heading-1">
          Launch your project on <span>BuidlFi</span>
        </TitleStyled>
        <Wrap>
          {list.map((item, index) => (
            <Item key={index}>
              <TitleStyled className="heading-2">{item.title}</TitleStyled>
              <ContentStyled>
                <img src={item.image} alt="" />
                <UlStyled>
                  {item.ul.map((li, index_) => (
                    <li key={index_}>{li}</li>
                  ))}
                </UlStyled>
              </ContentStyled>
            </Item>
          ))}
        </Wrap>
      </div>
    </Container>
  );
};
