import { IMaster, IWorktask } from "api/users";

export interface ITransactionByTokenName {
  totalBudgetByToken: number;
  currentAvailable: number;
  currentSpent: number;
  protocolCollectedFees: number;
  tokenName: string;
  blockchainNetwork: {
    _id: string;
    name: string;
  };
  tokenSymbol: string | null;
  totalRemainingByToken: number;
}

export interface ITransactionByProject {
  _id: string;
  projectName: string;
  tokenName: string;
  tokenSymbol?: string;
  blockchainNetworkDetail?: any;
  packages: {
    memberLimit: number;
    workStatus: string;
    status: string;
    finishStatus: string;
    _id: string;
    name: string;
  };
  transactions: {
    _id: string;
    createdAt: number;
    tokenName: string;
    tokenSymbol?: string;
    amount: number;
    type: string;
    user: string;
    walletAddress: string;
    txHash: string;
    projectId: string;
    packageId: string;
  }[];
}

export interface IWorktasksByStatusItem {
  _id: string;
  finishStatus: string;
  projectName: string;
  category: IMaster;
  tagline: string;
  totalBudget: number;
  scProjectId: string;
  initiatorDetails?: {
    _id: string;
    profilePicture: string;
    emailId: string;
  };
  tokenSymbol?: string;
  tokenName?: string;
  decimal: number;
  organizationDetails: (IMaster & {
    avatar: string;
  })[];
  packages: IWorktask[];
  workStatus: WORK_STATUS;
  countPackages?: number;
}

export enum WORK_STATUS {
  OPEN = "OPEN", // when package is created
  INPROGRESS = "INPROGRESS", // when any 1st collaborator starts work
  DELIVERED = "DELIVERED", // when all user's have submitted their code and it is under review
  SUBMITTED = "SUBMITTED", // when all user's submissions are approved
  COMPLETED = "COMPLETED", // when initiator finishes the package
  EXPIRED = "EXPIRED", // when submission is delayed
}

export interface IFeedbackByUserId {
  data: {
    feedback: {
      _id: string;
      fromUser: string;
      skills: number;
      adherenceSchedule: number;
      workQuality: number;
      communication: number;
      cooperation: number;
      description: string;
      user: string;
      reviewedAt: number;
    };
    project: {
      _id: string;
      name: string;
    };
    package: {
      _id: string;
      name: string;
    };
  }[];
  total: number;
}

// IFeedbackByUserId['data'][number]: get the type of array's element

export type IFeedbackWithModifiedUser = Omit<
  IFeedbackByUserId["data"][number]["feedback"],
  "user"
> & {
  user: {
    _id: string;
    emailId: string;
  };
};

export interface IFeedbackDetail
  extends Omit<IFeedbackByUserId["data"][number], "feedback"> {
  feedback: IFeedbackWithModifiedUser;
}
