import BannerImage from "assets/images/homepage/upper.png";
import upper1Img from "assets/images/homepage/upper1.png";
import upper2Img from "assets/images/homepage/upper2.png";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  BannerContainer,
  BannerText,
  RightContent,
  BannerDescription,
  BannerContent,
  BannerImageWrapper,
  ButtonText,
  BtnContainer,
  HighLight,
} from "./banner.style";
import PrimaryButton from "components/base/primaryButton";
import { IBannerProps } from "pages/HomePage/homepage.props";
import { ROUTER_CONSTANTS } from "utils/constant";
import PrimaryOutlineBtn from "components/base/primaryOutlineBtn";

const Banner: FC<IBannerProps> = ({ children, ...props }: IBannerProps) => {
  let navigate = useNavigate();

  return (
    <BannerContainer>
      <BannerContent>
        <BannerImageWrapper>
          <img className="move" src={BannerImage} alt="main" />
          <img className="move1" src={upper1Img} alt="secondary" />
          <img className="move2" src={upper2Img} alt="third" />
        </BannerImageWrapper>
        <RightContent>
          <BannerText>
            The Collaboration <br /> Platform for Decentralised Communities{" "}
          </BannerText>
          <BannerDescription>
            <HighLight>BuidlFi</HighLight> is the ultimate platform that acts as
            a reverse launchpad for Web3 projects, by facilitating
            collaboration, transparent distribution of ecosystem treasuries, and
            community growth.
          </BannerDescription>
          <BtnContainer>
            <PrimaryButton
              width="197px"
              height="56px"
              onClick={() => navigate(ROUTER_CONSTANTS.PROJECT.CREATE_PROJECT)}
            >
              <ButtonText>Create a Project</ButtonText>
            </PrimaryButton>
            <a href="#seedSkip">
              <PrimaryOutlineBtn width="197px" height="56px">
                Join a Project
              </PrimaryOutlineBtn>
            </a>
            <PrimaryButton
              width="197px"
              height="56px"
              onClick={() => navigate(ROUTER_CONSTANTS.COURSE.CREATE_COURSE)}
            >
              <ButtonText>Create a Course</ButtonText>
            </PrimaryButton>
          </BtnContainer>
        </RightContent>
      </BannerContent>
      {children}
    </BannerContainer>
  );
};

export default Banner;
