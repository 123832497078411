import { FC, ReactElement } from "react";
import { IOrganizationHeadProps } from "./OrganizationHead.props";
import {
  OrgHeadWrap,
  OrgHead,
  OrgInfoWrap,
  OrgAvatar,
  OrgInfo,
  OrgName,
  OrgUrl,
  OrgBtnWrap,
  OrgLinksWrap,
  OrgWrap,
  OrgLinksTitle,
  OrgLinks,
  OrgLinksA,
  DefaultImage,
} from "./OrganizationHead.style";
import {
  Checked,
  // FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  // DiscordIcon,
  // TelegramIcon,
  CoinmarketcapIcon,
  CoinmarketcapIcon2,
  GithubIcon,
  AnonymousIcon,
} from "assets/images/organization/organizationImages";
import { CameraIcon } from "assets/icons/common/camera.icon";
import { UserOutlined } from "@ant-design/icons";

const OrganizationHead: FC<IOrganizationHeadProps> = ({
  organization,
  children,
  ...props
}: IOrganizationHeadProps): ReactElement => {
  return (
    <OrgHeadWrap id="head-wrap">
      <OrgHead>
        <OrgInfoWrap>
          {organization?.avatar ? (
            <OrgAvatar
              src={organization?.avatar}
              alt="Organization avatar"
              icon={<UserOutlined />}
            />
          ) : (
            <DefaultImage>
              <CameraIcon />
            </DefaultImage>
          )}
          <OrgInfo>
            <OrgName>
              {organization?.name}
              <Checked />
            </OrgName>
            <OrgUrl>{organization?.socialLinks?.personURL}</OrgUrl>
            <OrgBtnWrap>{children}</OrgBtnWrap>
          </OrgInfo>
        </OrgInfoWrap>
        <OrgLinksWrap>
          <OrgWrap>
            <OrgLinksTitle>Social Visibility</OrgLinksTitle>
            <OrgLinks>
              {/* <OrgLinksA
                href={organization?.socialLinks?.facebookURL}
                target="_blank"
              >
                <FacebookIcon />
              </OrgLinksA> */}
              <OrgLinksA
                href={organization?.socialLinks?.twitterURL}
                target="_blank"
              >
                <TwitterIcon />
              </OrgLinksA>
              <OrgLinksA
                href={organization?.socialLinks?.linkedIn}
                target="_blank"
              >
                <LinkedinIcon />
              </OrgLinksA>
              {/* <OrgLinksA
                href={organization?.socialLinks?.discordURL}
                target="_blank"
              >
                <DiscordIcon />
              </OrgLinksA>
              <OrgLinksA
                href={organization?.socialLinks?.telegramURL}
                target="_blank"
              >
                <TelegramIcon />
              </OrgLinksA> */}
            </OrgLinks>
          </OrgWrap>

          <OrgWrap>
            <OrgLinksTitle>Crypto</OrgLinksTitle>
            <OrgLinks>
              <OrgLinksA
                href={organization?.crypto?.coinMarketCapLink}
                target="_blank"
              >
                <CoinmarketcapIcon />
              </OrgLinksA>
              <OrgLinksA
                href={organization?.crypto?.coinGeckoLink}
                target="_blank"
              >
                <CoinmarketcapIcon2 />
              </OrgLinksA>
            </OrgLinks>
          </OrgWrap>

          <OrgWrap>
            <OrgLinksTitle>Dev Resources</OrgLinksTitle>
            <OrgLinks>
              <OrgLinksA
                href={organization?.devResources?.githubURL}
                target="_blank"
              >
                <GithubIcon />
              </OrgLinksA>
              <OrgLinksA
                href={organization?.devResources?.documentURL}
                target="_blank"
              >
                <AnonymousIcon />
              </OrgLinksA>
            </OrgLinks>
          </OrgWrap>
        </OrgLinksWrap>
      </OrgHead>
    </OrgHeadWrap>
  );
};

export default OrganizationHead;
