import styled from "styled-components";

export const Container = styled.div``;

export const Above = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
    color: #080f6c;
    margin-bottom: unset;
  }
`;
export const Below = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 50px;
`;
export const SmallCards = styled.div`
  display: grid;
  gap: 24px;
  max-width: 452px;
`;

export const BigCardContainer = styled.div`
  padding: 24px;
  background: #ffffff;
  border: 1px solid rgba(8, 10, 37, 0.1);
  border-radius: 16px;
  img {
    width: 287px;
    height: 362px;
    object-fit: cover;
    border-radius: 16px;
  }
  cursor: pointer;
  max-width: 335px;
  display: grid;
  transition: 0.2s;
  :hover {
    box-shadow: rgb(97 156 253 / 20%) 6px 6px 16px 0px;
  }
`;

export const DateAndCategory = styled.div`
  display: flex;
  gap: 24px;
  div {
    h1 {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #666775;
      margin-bottom: unset;
      margin-left: 15px;
    }
    display: flex;
  }
  height: fit-content;
  margin-bottom: 12px;
  margin-top: 22px;
`;
export const BigCardTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #080a25;
`;

export const SmallCardContainer = styled.div`
  img {
    height: 160px;
    aspect-ratio: 156/160;
    object-fit: cover;
    border-radius: 16px;
    cursor: pointer;
  }
  display: flex;
  gap: 28px;
`;

export const Content = styled.div`
  & > div.above {
    & > span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #666775;
      margin-bottom: 4px;
    }
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #080a25;
      cursor: pointer;
    }
  }
`;
export const DateAndAuthor = styled.div`
  color: #666775;
  h1 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-right: 15px;
  }
  span {
    margin-left: 15px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;
