import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMaster, IProject } from "api/users";
import { PROGRESS } from "utils/constant";

interface IAddWorktask {
  projectType?: string;
  minimumCost?: number;
  observerFees?: number;
  memberLimit?: number;
  bonus?: number;

  issueType?: string;
  expertiseLevel?: string;
  startDate?: number;
  endDate?: number;
  observers?: any[];
  tags?: string[];
  expectedTimeCommitment?: number;

  isCustom?: boolean;
  githubIssueURL?: string;
  importName?: string;
  importDescription?: string;

  name?: string;
  description?: string;
  link?: string;
  context?: string;
  reference?: string;
  acceptanceCriteria?: string;
  resourceFiles?: {
    fileName: string;
    fileLink: string;
  }[];

  project?: IProject;
  stepSubmit?: Function;

  step1Done?: boolean;
  step2Done?: boolean;
  step3Done?: boolean;
  step4Done?: boolean;

  observersData?: any;

  editMode?: boolean;
  packageId?: string;
  createdTxHash?: string;
  success?: string;
  projectTypes?: IMaster[];
  typeOfTasks?: IMaster[];
  levelOfExpertise?: IMaster[];
}

const initialState: IAddWorktask = {
  minimumCost: undefined,
  observerFees: undefined,
  bonus: undefined,
  memberLimit: 1,
  issueType: undefined,
  expertiseLevel: undefined,
  startDate: undefined,
  endDate: undefined,
  observers: [],
  tags: [],
  expectedTimeCommitment: 0,

  githubIssueURL: undefined,
  importName: undefined,
  importDescription: undefined,

  name: undefined,
  description: undefined,
  link: undefined,
  context: undefined,
  reference: undefined,
  acceptanceCriteria: undefined,
  resourceFiles: [],

  step1Done: false,
  step2Done: false,
  step3Done: false,
  step4Done: false,

  stepSubmit: () => false,

  observersData: [],

  editMode: false,
  packageId: "",
  createdTxHash: "",

  success: PROGRESS.IN_PROGRESS,

  projectTypes: [],
  typeOfTasks: [],
  levelOfExpertise: [],
};

export const addWorktaskSlice = createSlice({
  name: "addWorktask",
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<IAddWorktask>) => {
      state.project = action.payload.project;
    },
    setSessionBudget: (state, action: PayloadAction<IAddWorktask>) => {
      state.minimumCost = action.payload.minimumCost;
      state.observerFees = action.payload.observerFees;
      state.memberLimit = action.payload.memberLimit;
      state.bonus = action.payload.bonus;
      state.step3Done = true;
    },
    setWorktaskType: (state, action: PayloadAction<IAddWorktask>) => {
      state.projectType = action.payload.projectType;
      state.issueType = action.payload.issueType;
      state.expertiseLevel = action.payload.expertiseLevel;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.expectedTimeCommitment = action.payload.expectedTimeCommitment;
      state.tags = action.payload.tags;
      // state.observers = action.payload.observers;
      state.memberLimit = action.payload.memberLimit;
      state.step1Done = true;
    },
    setImportFromGithub: (state, action: PayloadAction<IAddWorktask>) => {
      state.githubIssueURL = action.payload.githubIssueURL;
      state.importName = action.payload.importName;
      state.importDescription = action.payload.importDescription;
      state.isCustom = false;
      state.step2Done = true;
    },
    setCreateCustomWorkTask: (state, action: PayloadAction<IAddWorktask>) => {
      state.isCustom = true;
      state.step2Done = true;
      state.name = action.payload.name;
      state.description = action.payload.description;
      state.link = action.payload.link;
      state.context = action.payload.context;
      state.reference = action.payload.reference;
      state.acceptanceCriteria = action.payload.acceptanceCriteria;
      state.resourceFiles = action.payload.resourceFiles;
    },
    setWorktaskObservers: (state, action) => {
      state.observers = action.payload;
    },
    setCompleteStepFour: (state) => {
      state.step4Done = true;
    },
    setTxHash: (state, action: PayloadAction<string>) => {
      state.createdTxHash = action.payload;
    },
    addStepSubmit: (state, action: PayloadAction<IAddWorktask>) => {
      state.stepSubmit = action.payload.stepSubmit;
    },
    setObserversData: (state, action: PayloadAction<IAddWorktask>) => {
      state.observersData = action.payload.observersData;
    },
    setMode: (state, action: PayloadAction<IAddWorktask>) => {
      state.editMode = action.payload.editMode;
    },
    setWorktaskDetails: (state, action: PayloadAction<IAddWorktask>) => {
      state.projectType = action.payload.projectType;
      state.issueType = action.payload.issueType;
      state.expertiseLevel = action.payload.expertiseLevel;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.expectedTimeCommitment = action.payload.expectedTimeCommitment;
      state.tags = action.payload.tags;
      state.observers = action.payload.observers;

      state.name = action.payload.name;
      state.description = action.payload.description;
      state.link = action.payload.link;
      state.context = action.payload.context;
      state.reference = action.payload.reference;
      state.acceptanceCriteria = action.payload.acceptanceCriteria;
      state.resourceFiles = action.payload.resourceFiles;

      state.githubIssueURL = action.payload.githubIssueURL;
      state.importName = action.payload.importName;
      state.importDescription = action.payload.importDescription;

      state.minimumCost = action.payload.minimumCost;
      state.observerFees = action.payload.observerFees;
      state.memberLimit = action.payload.memberLimit;
      state.bonus = action.payload.bonus;

      state.step1Done = true;
      state.step2Done = true;
      state.step3Done = true;
      state.step4Done = true;
      state.isCustom = action.payload.isCustom;
      state.packageId = action.payload.packageId;
    },
    setResourceFiles: (state, action: PayloadAction<IAddWorktask>) => {
      state.resourceFiles = action.payload.resourceFiles;
    },
    setPackageId: (state, action: PayloadAction<IAddWorktask>) => {
      state.packageId = action.payload.packageId;
    },
    setSuccess: (state, action: PayloadAction<IAddWorktask>) => {
      state.success = action.payload.success;
    },
    setMastersData: (state, action: PayloadAction<IAddWorktask>) => {
      state.projectTypes = action.payload.projectTypes;
      state.typeOfTasks = action.payload.typeOfTasks;
      state.levelOfExpertise = action.payload.levelOfExpertise;
    },
    setReset: (state) => {
      state.minimumCost = undefined;
      state.observerFees = undefined;
      state.bonus = undefined;
      state.memberLimit = 1;
      state.issueType = undefined;
      state.expertiseLevel = undefined;
      state.startDate = undefined;
      state.endDate = undefined;
      state.observers = [];
      state.tags = [];
      state.expectedTimeCommitment = 0;

      state.githubIssueURL = undefined;
      state.importName = undefined;
      state.importDescription = undefined;

      state.name = undefined;
      state.description = undefined;
      state.link = undefined;
      state.context = undefined;
      state.reference = undefined;
      state.acceptanceCriteria = undefined;
      state.resourceFiles = [];

      state.step1Done = false;
      state.step2Done = false;
      state.step3Done = false;
      state.step4Done = false;

      state.stepSubmit = () => false;
      state.editMode = false;
      state.createdTxHash = "";
      state.success = PROGRESS.IN_PROGRESS;
    },
  },
});

export const {
  setSessionBudget,
  setProject,
  addStepSubmit,
  setWorktaskType,
  setImportFromGithub,
  setCreateCustomWorkTask,
  setObserversData,
  setMode,
  setWorktaskDetails,
  setResourceFiles,
  setPackageId,
  setSuccess,
  setMastersData,
  setReset,
  setWorktaskObservers,
  setCompleteStepFour,
  setTxHash,
} = addWorktaskSlice.actions;

export default addWorktaskSlice.reducer;
