import FlexBox from "components/base/Flexbox";
import { Collapse, Form, Input } from "antd";
import styled from "styled-components";
import PrimaryButton from "components/base/primaryButton";

export const Container = styled.div`
  max-width: 1170px;
  margin: 18px auto 60px;
  font-family: "DM Sans";
  font-style: normal;
`;

export const ModalTitle = styled.p`
  font-size: 1.5rem;
  color: #080f6c;
  font-family: "DM Sans";
  font-weight: 700;
  line-height: 125%;
  font-style: "Bold";
  text-align: center;
  letter-spacing: -0.04em;
  margin-bottom: 0.75rem;
`;

export const InputEmail = styled(Input)`
  background: #ffffff;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  padding: 0.6875rem 0.75rem 0.6875rem 0.75rem;
  height: 2.25rem;
`;
export const FAQForm = styled(Form)`
  .ant-form-item-row {
    display: block;
  }
  label {
  }
`;

export const StyledCollapse = styled(Collapse)`
  border: 1px solid rgba(41, 46, 115, 0.12);
  margin-bottom: 100px;

  &.ant-collapse-borderless {
    background: rgba(156, 138, 193, 0.04);
    padding: 0 20px;
  }

  &.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 0;
  }

  .ant-collapse-header-text {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 20px;
    color: #080f6c;
    word-break: break-all;
  }

  // .ant-collapse-header {
  //   padding-bottom: 0 !important;
  //   font-weight: bold;
  //   padding-right: 0 !important;
  //   padding-top: 20px !important;

  //   .ant-collapse-header-text {
  //     font-family: "DM Sans";
  //     font-weight: 500;
  //     font-size: 20px;
  //     color: #080f6c;
  //   }
  // }

  // .ant-collapse-item {
  //   padding-bottom: 15px;
  // }

  .ant-collapse-expand-icon {
    svg {
  //     width: 1em;
  //     height: 1em;
      fill: rgba(41, 46, 115, 0.42);
      font-size: 19px;
  //     margin-top: 16px;
  //   }
  }

  // font-size: 1rem;
`;

export const ActionButtonGroup = styled(FlexBox)`
  margin-top: 1.2rem;
`;

export const AddFAQButton = styled(PrimaryButton)`
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 2rem;
`;

export const Answer = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  // max-width: 800px;
  word-break: break-all;
`;
