export const PortfolioIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#9C8AC1" fillOpacity="0.16" />
    <path
      d="M17.3998 18.5008H13.8578C13.4508 18.5008 13.0438 18.8528 13.0988 19.4248L14.8148 30.4138C14.8148 30.4138 16.8498 22.3178 17.2458 20.3708C17.3668 19.7878 17.9168 19.6008 18.3238 19.6008H23.9998C23.9998 19.6008 23.2298 17.3128 23.1528 17.0598C23.0208 16.5758 22.7788 16.3008 22.2948 16.3008H18.6538C18.2578 16.3008 17.8838 16.5538 17.7738 17.0048C17.6748 17.4448 17.3998 18.5008 17.3998 18.5008ZM22.7678 18.5008H18.3678C18.3678 18.5008 18.8298 17.4008 19.3248 17.4008H21.6678C22.1958 17.4008 22.7678 18.5008 22.7678 18.5008ZM15.9368 30.8758C15.5958 31.3928 15.1008 31.7008 14.5508 31.7008H31.8538C32.4478 31.7008 32.8658 31.3598 32.9868 30.7878C33.4708 28.3788 34.8348 21.5038 34.8348 21.5038C34.9118 20.9538 34.5048 20.7008 34.1528 20.7008H30.5998V19.0838C30.5998 18.9078 30.3138 18.5008 29.8738 18.5008H25.7378C25.1658 18.5008 24.7808 19.1388 24.7808 19.1388L23.9998 20.7008H19.1488C18.7968 20.7008 18.4558 20.9098 18.3898 21.2508C18.3898 21.2508 16.6408 28.6208 16.4978 29.3138C16.4208 29.7208 16.2558 30.4028 15.9368 30.8758ZM29.9178 20.7008H25.0998C25.0998 20.7008 25.7378 19.6008 26.3428 19.6008H28.8618C29.6428 19.6008 29.9178 20.7008 29.9178 20.7008Z"
      fill="#7E00FD"
    />
  </svg>
);
