import DOMPurify from "dompurify";
import { FC, memo } from "react";
import { IOverViewProps } from "./overView.props";
import { Container, CkContentWrapper } from "./overView.style";
import parse from "html-react-parser";

const OverView: FC<IOverViewProps> = ({ course }) => {
  return (
    <Container>
      <h1>Overview of course</h1>
      <CkContentWrapper className="ck-content">
        {parse(DOMPurify.sanitize(course.description))}
      </CkContentWrapper>
    </Container>
  );
};

export default memo(OverView);
