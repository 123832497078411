export function getOtherLink(link: string) {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `http://${link}`;
}

const SHARE_URL = {
  TWITTER: "https://twitter.com/intent/tweet?url=",
  LINKEDLN: "https://www.linkedin.com/shareArticle?mini=true&url=",
  FACEBOOK: "https://www.facebook.com/sharer/sharer.php?u=",
};

export const shareSocial = (
  platform: "Twitter" | "Facebook" | "Linkedln",
  url?: string
) => {
  const urlShare = url ? getOtherLink(url) : undefined;

  switch (platform) {
    case "Twitter":
      window.open(
        `${SHARE_URL.TWITTER}${urlShare || window.location.href}`,
        "_blank",
        "noopener,noreferrer"
      );
      break;
    case "Facebook":
      window.open(
        `${SHARE_URL.FACEBOOK}${urlShare || window.location.href}`,
        "_blank",
        "noopener,noreferrer"
      );
      break;
    case "Linkedln":
      window.open(
        `${SHARE_URL.LINKEDLN}${urlShare || window.location.href}`,
        "_blank",
        "noopener,noreferrer"
      );
      break;
    default:
  }
};
