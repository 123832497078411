import styled from "styled-components";
import { THEME } from "utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${THEME.MAX_WITH};
  margin: auto;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  background: rgba(156, 138, 193, 0.04);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
`;

export const TitleBlock = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1.25rem;
`;

export const Title = styled.div`
  color: #080f6c;
  font: 700 1.5rem/125% "DM Sans" !important;
  letter-spacing: -0.04em;
`;

export const ContainerItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 390px;
  min-height: 230px;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  padding-block: 16px;
`;

export const HeaderItem = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-inline: 1.25rem;
  flex-grow: 1;
`;

export const AvatarItem = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: rgba(156, 138, 193, 0.04);
  padding: 0.375rem;
`;

export const LeftItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const SchoolBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SchoolName = styled.div`
  color: #080f6c;
  font: 400 1rem/125% "Rubik" !important;
  width: 279px;
  word-break: break-all;
`;

export const Major = styled.div`
  color: rgba(41, 46, 115, 0.82);
  font: 400 0.875rem/125% "DM Sans" !important;
  width: 279px;
  word-break: break-all;
`;

export const StudySessionBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StudySession = styled.div`
  color: rgba(41, 46, 115, 0.42);
  font: 400 0.875rem/125% "DM Sans" !important;
  letter-spacing: -0.02em;
  margin-bottom: 0.25rem;
`;

export const StudySessionValue = styled.div``;

export const StartTime = styled.span`
  color: rgba(41, 46, 115, 0.82);
  font: 400 0.875rem/120% "DM Sans" !important;
`;

export const EndTime = styled.span`
  color: #080f6c;
  font: 400 0.875rem/120% "DM Sans" !important;
`;

export const LearnedBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Learned = styled.div`
  color: rgba(41, 46, 115, 0.82);
  font: 400 0.875rem/120% "DM Sans" !important;
`;

export const LearnedValue = styled.div`
  color: rgba(41, 46, 115, 0.42);
  font: 400 0.875rem/125% "DM Sans" !important;
  letter-spacing: -0.02em;
  margin-bottom: 0.25rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0rem;
  border: 0.0313rem solid rgba(41, 46, 115, 0.12);
  margin-top: 1.25rem;
`;

export const FooterItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5625rem;
  margin-top: 0.75rem;
  margin-left: 4.6875rem;
`;

export const LinkWebsite = styled.div`
  color: rgba(41, 46, 115, 0.82);
  font: 400 0.875rem/125% "DM Sans" !important;
`;
