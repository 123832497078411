import { Row, Upload } from "antd";
import styled from "styled-components";

const { Dragger } = Upload;

export const UploadContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  text-overflow: ellipsis;
  height: 310px;
  /* padding-right: 10px; */
  /* display: flex; */
  overflow: hidden;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #d9d9d9;
    border-radius: 12px;
    background-color: #f5f5f5;
    height: 4px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    height: 4px;
    border-radius: 12px;
    -webkit-box-shadow: inset 0 0 6px #d9d9d9;
    background-color: #f5f5f5;
  }
  &:hover {
    border-color: #40a9ff;
    cursor: pointer;
  }
`;

export const DraggerBlock = styled.div`
  height: inherit;

  .ant-upload-list {
    display: flex;
    gap: 24px;
    width: 100%;
    flex-wrap: wrap;
    padding: 12px;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  .ant-upload-list-picture-container {
    width: calc((100% - 24px) / 2);
    .ant-progress-outer {
      width: calc(100% - 35px);
    }
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 4px;
  }
  .ant-upload-list-item {
    margin-top: 0px !important;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
    &:hover {
      border-color: #7e00fd;
      background-color: #eedfff;
    }
    .ant-upload-list-item-file {
      svg {
        path {
          fill: #7e00fd;
        }
      }
    }
    .ant-upload-list-item-name {
      font: normal 500 14px/120% "DM Sans";
    }
    .ant-upload-list-item-card-actions {
      border-radius: 50%;
      padding: 5px;
      transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
      z-index: 1;
      &:hover {
        background: #fbf7ff;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
          rgba(0, 0, 0, 0.24) 0px 1px 2px;
      }
      span {
        font-size: 20px;
        color: #7e00fd;
      }
    }
  }
  .ant-upload-list-item-info {
    border-radius: 4px;
    img {
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 4px;
    }
    .ant-upload-list-item-file {
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 4px;
    }
  }
  .ant-upload-list-item-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const AntdDragger = styled(Dragger)<{ isHaveFile?: boolean }>`
  ${({ isHaveFile }) => {
    return isHaveFile
      ? `padding: 0px 20px 0px 20px !important;`
      : `padding: 40px 20px 20px 20px !important;`;
  }}
  text-align: left;
  &.ant-upload.ant-upload-drag {
    // height: 310px;
    display: flex;
    align-items: center;
    overflow: hidden;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #d9d9d9;
      border-radius: 12px;
      background-color: #f5f5f5;
      height: 4px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      height: 4px;
      border-radius: 12px;
      -webkit-box-shadow: inset 0 0 6px #d9d9d9;
      background-color: #f5f5f5;
    }
  }
  .ant-upload-drag-container {
    display: flex;
    align-items: center;
  }
  .ant-upload.ant-upload-select {
    border-radius: 4px;
    width: 100%;
    padding-right: 10px;
    margin: 8px 0px;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  }

  .anticon-check-circle,
  .ant-progress-text {
    display: none;
  }
`;

export const UploadBlock = styled.div`
  padding-right: 10px;
  .ant-upload-list {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    gap: 8px;
    padding: 0px 8px;
    &:before {
      display: none;
    }
  }
`;

export const AntdUpload = styled(Upload)<{ hide?: boolean }>`
  ${({ hide }) => (hide ? `display: none;` : "")}
  padding: 10px;
  height: 310px;
  padding: 10px;

  .ant-upload.ant-upload-select {
    border-radius: 4px;
    width: 100%;
    padding-right: 10px;
    margin: 8px 0px;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  }

  .anticon-check-circle,
  .ant-progress-text {
    display: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 4px;
  }
  .ant-upload-list-item {
    margin-top: 0px !important;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
    &:hover {
      border-color: #7e00fd;
      background-color: #eedfff;
      /* box-shadow: #7e00fd 0px 1px 4px; */
    }
    .ant-upload-list-item-file {
      svg {
        path {
          fill: #7e00fd;
        }
      }
    }
    .ant-upload-list-item-name {
      font: normal 500 14px/120% "DM Sans";
    }
    .ant-upload-list-item-card-actions {
      border-radius: 50%;
      padding: 5px;
      transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
      z-index: 1;
      &:hover {
        background: #fbf7ff;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
          rgba(0, 0, 0, 0.24) 0px 1px 2px;
      }
      span {
        font-size: 20px;
        color: #7e00fd;
      }
    }
  }
  .ant-upload-list-item-info {
    border-radius: 4px;
    img {
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 4px;
    }
    .ant-upload-list-item-file {
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 4px;
    }
  }
  .ant-upload-list-item-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const UploadWrapper = styled.div<{ isHaveFile: boolean }>`
  ${({ isHaveFile }) => {
    return isHaveFile
      ? `
        height: 80px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        &>div{
          margin: 0;
        }
      `
      : `
  height: 290px;
  padding: 10px;
  width: 100%;
  height: 100%;
  `;
  }}
`;

export const ImageBlock = styled.div<{ isHaveFile: boolean }>`
  ${({ isHaveFile }) => {
    return isHaveFile ? `display: none;` : `display: block;`;
  }}
  text-align: center;
`;

export const ImageUpload = styled.img``;

export const MainTitle = styled.div<{ isHaveFile: boolean }>`
  ${({ isHaveFile }) => {
    return isHaveFile ? `display: none;` : `display: block;`;
  }}
  text-align: center;
  margin: 18px 0px 24px 0px;
  font: normal 400 18px/120% "DM Sans";
  letter-spacing: -0.02em;
  color: #080f6c;
`;

export const PrimaryText = styled.span`
  color: #7e00fd;
  font-weight: bold;
`;

export const SubTitleIcon = styled.span`
  display: flex;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    top: 6px;
    width: 6px;
    height: 6px;
    background: rgba(8, 10, 37, 0.4);
  }
`;

export const SubTitleText = styled.span`
  text-align: left;
  padding-left: 10px;
  font: normal 400 12px/150% "DM Sans";
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const IndexIconBlock = styled.div`
  padding-bottom: 4px;
`;

export const ButtonUploadBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  height: 64px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  &:hover {
    border-color: #7e00fd;
    background-color: #eedfff;
    /* box-shadow: #7e00fd 0px 1px 4px; */
  }
  span {
    font: normal 400 16px/150% "DM Sans";
  }
  img {
    width: 30px;
    height: 30px;
  }
`;

export const DocumentBlock = styled.div`
  width: 635px;
  height: 700px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    max-height: 30px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #bec4c4;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const RowAntd = styled(Row)`
  display: flex !important;
`;
