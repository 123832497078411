import TableCard from "components/base/tableCard";
import { FC, memo, useState } from "react";
import { ITransactionsAndRewardsProps } from "./transactionsAndRewards.props";
import {
  ContainerFluidHeader,
  ContainerTransaction,
  ContainerTransactionBody,
  HeaderContainer,
  HeaderText,
} from "./transactionsAndRewards.style";
// import transaction1 from "assets/images/transaction-reward/ptransaction1.png";
// import transaction2 from "assets/images/transaction-reward/transaction2.png";
// import transaction3 from "assets/images/transaction-reward/transaction3.png";
// import transaction4 from "assets/images/transaction-reward/transaction4.png";
import { useQuery } from "@tanstack/react-query";
import { getAllTransactionAPI } from "api/users";
import { AxiosResponse } from "axios";
import { useAppSelector } from "redux/hooks";
import { EmptyText } from "pages/ProjectDetail/ProjectDetail.style";

const TransactionsAndRewards: FC<ITransactionsAndRewardsProps> = () => {
  const [transactions, setTransactions] = useState([] as any);
  const userId: string = useAppSelector((state) => state.auth.user?._id);
  useQuery(["getAllTransaction"], () => getAllTransactionAPI(), {
    onSuccess: async (res: AxiosResponse) => {
      // if (res.responseCode === 200) {
      setTransactions(
        res?.responseData?.filter(
          (transaction: any) => transaction.userId === userId
        )
      );
      // }
    },
  });

  return (
    <>
      <ContainerFluidHeader>
        <HeaderContainer>
          <HeaderText>Transactions & Rewards</HeaderText>
        </HeaderContainer>
      </ContainerFluidHeader>
      <ContainerTransactionBody>
        <div>
          <ContainerTransaction>
            {transactions.length ? (
              transactions.map((transaction: any, i: any) => (
                <TableCard transaction={transaction} key={i} />
              ))
            ) : (
              <EmptyText>You have no transaction.</EmptyText>
            )}
          </ContainerTransaction>
          {/* <SeeMore>
            See More <DownIcon />
          </SeeMore>{" "} */}
        </div>
      </ContainerTransactionBody>
    </>
  );
};

export default memo(TransactionsAndRewards);
