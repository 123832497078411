import { FC, memo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { dayNotification } from "utils/timer";
import {
  IDropBoxNotificationProps,
  INotification,
} from "./dropBoxNotification.props";
import {
  Container,
  Content,
  DropDownItem,
  HorizontalLine,
  NoNotification,
} from "./dropBoxNotification.style";

const DropBoxNotification: FC<IDropBoxNotificationProps> = ({
  notifications,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateOrReload = useCallback(
    (url: string) => {
      if (location.pathname === url) window.open(url, "_self");
      else navigate(url);
    },
    [location.pathname, navigate]
  );

  const handleOnclickNotification = (item: INotification) => {
    switch (item?.categoryType) {
      case "PROJECT":
        navigate(
          `${ROUTER_CONSTANTS.PROJECT.DETAIL.replace(":id", item.projectId)}`
        );
        break;
      case "PAYMENT":
        navigate(`${ROUTER_CONSTANTS.PROJECT.BOARD}`);
        break;
      case "SUPPORT_TICKET":
        navigate(`${ROUTER_CONSTANTS.PROJECT.BOARD}`);
        break;
      case "CARD_COMMENT":
        navigate(
          `${ROUTER_CONSTANTS.PROJECT.DELIVER_WORK.replace(
            ":projectId",
            item.projectId
          ).replace(":packageId", item.packageId)}`,
          {
            state: {
              toProjectBoardTab: true,
            },
          }
        );
        break;
      case "PACKAGE_REQUEST":
        navigate(
          `${ROUTER_CONSTANTS.PROJECT.DELIVER_WORK.replace(
            ":projectId",
            item.projectId || ""
          ).replace(":packageId", item.packageId || "")}`
        );
        break;
      case "NEW_JOIN_PACKAGE_REQUEST":
        navigate(
          `${ROUTER_CONSTANTS.PROJECT.REVIEW_WORKTASK.replace(
            ":projectId",
            item.projectId
          ).replace(":packageId", item.packageId)}`
        );
        break;
      case "MARK_DELIVERABLE":
        navigate(
          `${ROUTER_CONSTANTS.PROJECT.DELIVER_WORK.replace(
            ":projectId",
            item.projectId || ""
          ).replace(":packageId", item.packageId || "")}`,
          {
            state: {
              toDeliverTab: true,
            },
          }
        );
        break;
      case "REVISION":
        navigate(`${ROUTER_CONSTANTS.PROJECT.BOARD}`);
        break;
      case "UPDATE_COURSE":
      case "PUBLISH_COURSE":
        navigateOrReload(
          `${ROUTER_CONSTANTS.COURSE.COURSE_DETAIL.replace(
            ":id",
            item.courseId
          )}`
        );
        break;
      case "MARK_COURSE":
        navigateOrReload(ROUTER_CONSTANTS.COURSE.LIST_COURSE_FOR_LEARNER);
        break;
    }
  };

  return (
    <Container>
      {notifications.length > 0 ? (
        notifications.map((notification, i) => (
          <DropDownItem key={i}>
            <Content onClick={() => handleOnclickNotification(notification)}>
              <span>
                <strong>{notification.message}</strong>
                <span>{dayNotification(notification.createdAt)}</span>
              </span>
            </Content>
            <HorizontalLine />
          </DropDownItem>
        ))
      ) : (
        <DropDownItem>
          <NoNotification>You don't have any notifications</NoNotification>
        </DropDownItem>
      )}
    </Container>
  );
};

export default memo(DropBoxNotification);
