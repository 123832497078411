import { FC, Fragment, useEffect, useState } from "react";
import { ISubmitDeliverableProps } from "./SubmitDeliverable.props";
import SubmitDeliverableBackground from "assets/images/submit-deliverable/submit-deliverable-background.png";
// import { DocumentIcon } from "assets/images/submit-deliverable";
import {
  Container,
  BackgroundImage,
  TitleWrap,
  BodyWrap,
  ItemTitle1,
  ItemTitle2,
  Note,
  ItemTitle3,
  ButtonGroup,
  ModalTitle,
  SendButton,
  CopyElement,
  SecondaryButton,
  FileGrid,
  DeliverCollapse,
  MaxWidthWrapper,
  FileInfoWrapper,
  FileNameWrapper,
  ReviewDeliverTitle,
  RejectWrapper,
  // OpenDeliverWrapper,
} from "./SubmitDeliverable.style";
import PrimaryButton from "components/base/primaryButton";
import DeliverWork from "pages/SubmitNewDeliverable";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  approveDeliveryRequestAPI,
  getDataMastersAPI,
  getProjectDetails,
  getReviewDeliverablesAPI,
  IProject,
  updateRejectReasonAPI,
} from "api/users";
import { approveCollaboratorSC, handleMessageErrorSC } from "web3/contract";
import { Avatar, message } from "antd";
import { openFrom } from "utils/time";
import FlexBox from "components/base/Flexbox";
import { NewCopyIcon, DownFileIcon } from "assets/icons/common/copy.icon";
import CommonModal from "components/base/commonModal";
import { StyledTextArea } from "pages/CollaboratorRating/CollaboratorRating.style";
import {
  BlockchainNetworks,
  ROUTER_CONSTANTS,
  USER_WORK_STATUS,
} from "utils/constant";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { setPageLoading } from "redux/slices";
import { DownloadButton } from "pages/Feedback/Feeback.style";
import { ChevronDown, ChevronUp } from "assets/icons/collapse.icon";
import {
  useGetWorktaskDetailAndCheckIsStranger,
  useCheckWalletAndSwitchNetwork,
} from "hooks";
import BackToProjectDetail from "components/modules/backToDetail";
import { AxiosError } from "axios";
import CopyTextComponent from "components/base/copyText";

const ReviewDeliverables: FC<ISubmitDeliverableProps> = () => {
  const [popupIndex, setPopupIndex] = useState(0);
  // const [deliverableData, setDeliverableData] = useState({} as any);
  // const [loading, setLoading] = useState(false);
  const userInfo = useAppSelector((state) => state.auth.user);
  const userId: string = userInfo?._id; // useAppSelector((state) => state.auth.user?._id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { isStranger, isSuccess: wtDetailSuccess } =
    useGetWorktaskDetailAndCheckIsStranger(params);
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  useEffect(() => {
    if (wtDetailSuccess && isStranger) {
      message.error(
        "You are neither creator, collaborator, sponsor nor the observer!"
      );
      navigate("/");
    }
  }, [isStranger, navigate, wtDetailSuccess]);

  const { data: deliverRes } = useQuery(
    [
      `getReviewDeliverables${params.packageId || ""}-${
        params.projectId || ""
      }-${params?.userId || ""}`,
    ],
    () =>
      getReviewDeliverablesAPI({
        packageId: params.packageId || "",
        projectId: params.projectId || "",
        userId: params?.userId || "",
      }),
    {
      // onSuccess: async (res) => {
      //   if (res.responseCode === 200) {
      //     setDeliverableData(res.responseData);
      //   }
      // },
      refetchOnWindowFocus: false,
    }
  );
  const deliverableData = deliverRes?.responseData;

  const { data: projectDetailRes } = useQuery(
    [`getProjectDetail-${params.projectId}`],
    () => getProjectDetails(params.projectId || ""),
    {
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      refetchOnWindowFocus: false,
    }
  );
  const project: IProject | undefined = projectDetailRes?.responseData;

  const { mutate: approveDeliveryRequest } = useMutation(
    approveDeliveryRequestAPI,
    {
      onSuccess: (res) => {
        const { responseCode, responseMessage } = res;
        if (responseCode === 200) {
          setPopupIndex(2);
        } else {
          message.error(responseMessage);
        }
      },
      onSettled: () => {
        dispatch(setPageLoading(false));
      },
    }
  );

  const { mutate: updateRejectReason } = useMutation(updateRejectReasonAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        navigate(-1);
      } else {
        message.error(responseMessage);
      }
    },
    onSettled: () => {
      dispatch(setPageLoading(false));
    },
  });

  const handleApproveDeliverables = async () => {
    if (!checkWallet()) return;
    // if (!account) {
    //   message.error("Please connect to wallet");
    //   return;
    // }

    // if (userInfo?.walletAddress !== account) {
    //   message.error("You are connecting to a different wallet");
    //   return;
    // }

    try {
      dispatch(setPageLoading(true));

      const projectChainId =
        BlockchainNetworks[project?.blockchainNetwork?.name!];
      await handleSwitchNetwork(projectChainId);
      // if (!chainId) {
      //   activateBrowserWallet({ type: connectedWalletName });
      // }

      // if (chainId !== projectChainId) {
      //   await switchNetwork(projectChainId);
      // }
      // changeNetwork(projectChainId);

      const scParams = {
        projectId: deliverableData?.scProjectId,
        packageId: deliverableData?.scPackageId,
        collaborator: deliverableData?.userInfo?.walletAddress,
      };
      await approveCollaboratorSC(scParams);
      approveDeliveryRequest({
        projectId: params?.projectId || "",
        packageId: params?.packageId || "",
        userId: deliverableData?.userInfo?._id,
        deliveryId: deliverableData?.deliverables[0]?._id,
      });
      // setLoading(false);
    } catch (err: any) {
      // setLoading(false);
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
    dispatch(setPageLoading(false));
  };

  const handleRejectDeliverables = async () => {
    try {
      dispatch(setPageLoading(true));
      updateRejectReason({
        projectId: params?.projectId || "",
        packageId: params?.packageId || "",
        userId: deliverableData?.userInfo?._id,
        deliveryId: deliverableData?.deliverables[0]?._id,
        rejectReasonId: selectRevision._id,
        description,
      });
    } catch (err: any) {
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
    dispatch(setPageLoading(false));
    // updateRejectReason
  };

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  // const [revisionType, setRevisionType] = useState([] as any);
  const [selectRevision, setSelectRevision] = useState({} as any);
  const [description, setDescription] = useState("");
  // const [deliverOpen, setDeliverOpen] = useState(false);

  const { data: revisionRes } = useQuery(
    ["getMasterRejectReasons"],
    () => getDataMastersAPI({ type: "Reject reasons" }),
    {
      // onSuccess: (res) => {
      //   const { responseData, responseCode } = res;
      //   if (responseCode === 200) {
      //     setRevisionType(responseData?.values);
      //   }
      // },
      refetchOnWindowFocus: false,
    }
  );
  const revisionType: any[] = revisionRes?.responseData?.values;

  const isNotCompletedOrRejected =
    deliverableData?.deliverables &&
    deliverableData?.deliverables[0]?.status !==
      USER_WORK_STATUS.SUBMISSION_REVISON &&
    deliverableData?.deliverables &&
    deliverableData?.deliverables[0]?.status !==
      USER_WORK_STATUS.SUBMISSION_REJECTED &&
    deliverableData?.deliverables &&
    deliverableData?.deliverables[0]?.status !==
      USER_WORK_STATUS.SUBMISSION_APPROVED;

  const isProjectCreator = deliverableData?.initiator === userId;

  return (
    <Container>
      <DeliverWork popupIndex={popupIndex} setPopupIndex={setPopupIndex} />
      <TitleWrap>
        <BackToProjectDetail
          linkToGo={ROUTER_CONSTANTS.PROJECT.DELIVER_WORK.replace(
            ":projectId",
            params.projectId || ""
          ).replace(":packageId", params.packageId || "")}
          linkText="Back to worktask detail"
          routerState={{
            toDeliverTab: true,
          }}
        />
        <BackgroundImage
          src={SubmitDeliverableBackground}
          alt="SubmitDeliverable background"
        />
        <ReviewDeliverTitle>Review Deliverables</ReviewDeliverTitle>
      </TitleWrap>
      <BodyWrap>
        {deliverableData?.deliverables?.length > 0 && (
          <DeliverCollapse
            expandIcon={({ isActive }) =>
              isActive ? <ChevronUp /> : <ChevronDown />
            }
            expandIconPosition="end"
            defaultActiveKey={deliverableData?.deliverables?.map(
              (_: any, i: number) => i
            )}
          >
            {deliverableData?.deliverables?.map(
              (deliverable: any, i: number) => {
                return (
                  <DeliverCollapse.Panel
                    header={
                      <>
                        <ItemTitle1>
                          {deliverableData?.userInfo?.username ||
                            deliverableData?.userInfo?.emailId.split(
                              "@"
                            )[0]}{" "}
                          delivered your order
                        </ItemTitle1>
                        <Note>{`${openFrom(
                          deliverable?.submittedAt || Date.now()
                        )} ago`}</Note>
                      </>
                    }
                    key={i}
                  >
                    <div>
                      <FileInfoWrapper
                        align="flex-start"
                        justify="space-between"
                        gap="10px"
                      >
                        <FlexBox gap="10px">
                          <Avatar
                            size={36}
                            src={deliverableData?.userInfo?.profilePicture}
                          />
                          <ItemTitle2>
                            {deliverableData?.userInfo?.username ||
                              deliverableData?.userInfo?.emailId.split("@")[0]}
                          </ItemTitle2>
                          <div>
                            <Note>{deliverable?.notes}</Note>
                          </div>
                        </FlexBox>

                        <FileGrid>
                          {deliverable.postLink.map((link: any, index: any) => (
                            <FlexBox
                              key={link._id}
                              justify="flex-end"
                              gap="12px"
                            >
                              {link.link && (
                                <FileNameWrapper justify="center" gap="8px">
                                  <CopyTextComponent
                                    textToCopy={link.link}
                                    cpnForSetTimeout="Copied"
                                  >
                                    <CopyElement>
                                      <NewCopyIcon />
                                    </CopyElement>
                                  </CopyTextComponent>
                                  <a
                                    href={link.link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Note>{link.linkName ?? "Navigate"}</Note>
                                  </a>
                                </FileNameWrapper>
                              )}
                              <div style={{ minWidth: "180px" }}>
                                <DownloadButton>
                                  <a
                                    href={deliverable?.files[index].fileLink}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <FlexBox align="center" gap="12px">
                                      {deliverable?.files[index].fileName}
                                      <DownFileIcon />
                                    </FlexBox>
                                  </a>
                                </DownloadButton>
                              </div>
                            </FlexBox>
                          ))}
                        </FileGrid>
                      </FileInfoWrapper>

                      {!isProjectCreator &&
                        deliverable?.status ===
                          USER_WORK_STATUS.SUBMISSION_REVISON && (
                          <RejectWrapper>
                            Your deliverable has been rejected. <br /> Reason:{" "}
                            {deliverable?.description}
                          </RejectWrapper>
                        )}

                      {isNotCompletedOrRejected && isProjectCreator && (
                        <MaxWidthWrapper>
                          <ItemTitle3>
                            You received your delivery from{" "}
                            {deliverableData?.userInfo?.username ||
                              deliverableData?.userInfo?.emailId.split("@")[0]}
                            . Are you pleased with the delivery and ready to
                            approve it?
                          </ItemTitle3>
                          <FlexBox gap="15px" m="15px 0 15px">
                            <PrimaryButton
                              height="40px"
                              onClick={handleApproveDeliverables}
                            >
                              Yes, I approve delivery
                            </PrimaryButton>
                            <SecondaryButton height="40px" onClick={toggleOpen}>
                              No, I'm not ready yet
                            </SecondaryButton>
                            {selectRevision.name ===
                              "I didn't receive anything" && (
                              <Link to={ROUTER_CONSTANTS.USER.SUPPORT_TICKET}>
                                <SecondaryButton height="40px">
                                  Raise a Dispute / Request Deliverables
                                </SecondaryButton>
                              </Link>
                            )}
                          </FlexBox>
                        </MaxWidthWrapper>
                      )}

                      {selectRevision._id && (
                        <>
                          <ItemTitle3>{selectRevision.name}</ItemTitle3>
                          <StyledTextArea
                            placeholder="Have something to share?"
                            onChange={(e) => setDescription(e.target.value)}
                          />
                          <SendButton
                            height="40px"
                            onClick={handleRejectDeliverables}
                          >
                            Submit Reasons
                          </SendButton>
                        </>
                      )}
                    </div>
                  </DeliverCollapse.Panel>
                );
              }
            )}
          </DeliverCollapse>
        )}
      </BodyWrap>
      <CommonModal visible={open} onCancel={toggleOpen} padding="2rem 1rem">
        <ModalTitle>
          We're here to make sure you get the best possible experience. Why
          aren't you ready to accept this delivery?
        </ModalTitle>
        <ButtonGroup>
          {revisionType?.length > 0 &&
            revisionType?.map((revision: any) => (
              <SecondaryButton
                key={revision?.name}
                width="250px"
                height="42px"
                onClick={() => {
                  toggleOpen();
                  setSelectRevision(revision);
                }}
              >
                {revision?.name}
              </SecondaryButton>
            ))}
        </ButtonGroup>
      </CommonModal>
    </Container>
  );
};

export default ReviewDeliverables;

// updateRejectReason
// {
//   "deliveryId": "6347e23f2cf80e001acd58cb",
//   "rejectReasonId": "61d69313c7694a3367d988cd",
//   "userId": "63215191a3d76800193ce663",
//   "description": "huhuhu"
// }
