import { useMutation } from "@tanstack/react-query";
import { Avatar, Form, message, UploadProps } from "antd";
import { IRequestJoinWorktaskParams, requestJoinWorktaskAPI } from "api/users";
import { SendIcon } from "assets/images/express-interest";
import ExpressInterestBackground from "assets/images/express-interest/express-interest-background.png";
// import AvatarUrl from "assets/images/organization/avatar.png";
import { AxiosError, AxiosResponse } from "axios";
import Breadcrumb from "components/base/breadcrumb";
import PrimaryButton from "components/base/primaryButton";
import dayjs from "dayjs";
import {
  BackgroundImage,
  EndTime,
  HeadWrap,
  SponsoredBy,
  Wrap,
} from "pages/WorktasksInside/WorktasksInside.style";
import { FC, Fragment, ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ROUTER_CONSTANTS } from "utils/constant";
import { requiredRules } from "utils/validatorRules";
import { IExpressInterestProps } from "./ExpressInterest.props";
import {
  Body,
  CheckboxPurple,
  CheckboxWrap,
  Container,
  Gap,
  Head,
  Item,
  SendText,
  Status,
  StyledFormItem,
  StyledTextArea,
  Text,
  Title,
  AttachBtn,
  AttachModal,
  UploadWrapper,
  AttachTitle,
  BtnWrap,
  CancelBtn,
  AttachConfirmBtn,
  AttachUpload,
  UploadInner,
  ResourcesWrap,
  OrgName,
} from "./ExpressInterest.style";
import { RejectReasonInfoIcon } from "assets/icons/info.icon";
import { acceptDocument, validateDoc } from "utils/regex";
import type { RcFile } from "antd/lib/upload";
import { CloudSaveIcon, DownloadIcon } from "assets/images/Bounty";
import { PurpleAttachIcon2 } from "assets/images/deliver-work";
import { getUrlFromS3 } from "utils/getUrlFromS3";
import { setPageLoading } from "redux/slices";
import { useGetWorktaskDetailAndCheckIsStranger } from "hooks";

const ExpressInterest: FC<IExpressInterestProps> = ({
  ...props
}: IExpressInterestProps): ReactElement => {
  const [form] = Form.useForm();
  const location = useLocation();
  const pageParam = useParams();
  // const pathSplit = location.pathname.split("/");
  const navigate = useNavigate();
  // const [worktask, setWorktask] = useState<IWorktask>({} as IWorktask);
  const [conditionCheck1, setConditionCheck1] = useState<boolean>(false);
  const [conditionCheck2, setConditionCheck2] = useState<boolean>(false);
  const [conditionCheckErr, setConditionCheckErr] = useState<boolean>(false);
  const userId: string = useAppSelector((state) => state.auth.user?._id);
  const [attachModalOpen, setAttachModalOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState<RcFile[]>([]);
  const dispatch = useAppDispatch();
  const { worktaskDetail: worktask } =
    useGetWorktaskDetailAndCheckIsStranger(pageParam);

  // const { data } = useQuery(
  //   [`getPackageDetail-${pageParam.packageId}`],
  //   () =>
  //     getPackageDetailsAPI(
  //       pageParam.projectId || "",
  //       pageParam.packageId || ""
  //     ),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  // const worktask: IWorktask = data?.responseData || {};
  const upLoadProps: UploadProps = {
    accept: acceptDocument,
    multiple: true,
    showUploadList: false,
    fileList: uploadFile,
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        onSuccess?.("ok");
      }, 0);
    },
    beforeUpload: async (file, fileList) => {
      // console.log(fileList);

      fileList.forEach((file) => {
        const isImage = validateDoc.test(file.name);
        if (!isImage) {
          message.error("You can only upload Document files!");
          return false;
        }
        if (file.size > 5000000) {
          message.error("File is too big");
          return false;
        }
      });

      setUploadFile(fileList);
    },
  };

  const items = [
    {
      text: "Worktasks",
      to:
        // "/" +
        ROUTER_CONSTANTS.PROJECT.DETAIL.replace(
          ":id",
          pageParam.projectId || ""
        ),
    },
    {
      text: worktask?.name || "",
      to: ROUTER_CONSTANTS.PROJECT.DETAIL.replace(
        ":id",
        pageParam.projectId || ""
      ),
      // to: "/" + pathSplit[1] + "/" + pathSplit[2] + "/" + pathSplit[3],
    },
    {
      text: "Express Interest",
      to: location.pathname,
    },
  ];

  const isAlreadyCollaborator = worktask?.collaborators?.includes(userId);

  const isAlreadyRequest = worktask?.requests?.some(
    (request: any) => request.userId === userId
  );

  const isAlreadyCollaboratorParams = worktask?.collaborators?.includes(
    pageParam.id
  );

  const isAlreadyRequestParams = worktask?.requests?.some(
    (request: any) => request.userId === pageParam.id
  );

  useEffect(() => {
    if (
      isAlreadyRequest ||
      isAlreadyCollaborator ||
      isAlreadyCollaboratorParams ||
      isAlreadyRequestParams
    ) {
      message.error("You already expressed interest");
      navigate("/");
    }
  }, [
    isAlreadyCollaborator,
    isAlreadyRequest,
    isAlreadyCollaboratorParams,
    isAlreadyRequestParams,
    navigate,
  ]);

  const { mutate: requestJoinWorktask } = useMutation(requestJoinWorktaskAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        dispatch(setPageLoading(false));
        message.success(responseMessage);
        navigate(-1);
      } else {
        dispatch(setPageLoading(false));
        message.error(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      dispatch(setPageLoading(false));
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const onSubmit = async (values: IRequestJoinWorktaskParams["body"]) => {
    if (!conditionCheck1 || !conditionCheck2) {
      setConditionCheckErr(true);
      return;
    }

    try {
      dispatch(setPageLoading(true));
      const fileListWithLink: { fileName: string; fileLink: string }[] = [];

      if (uploadFile.length > 0) {
        for (let file of uploadFile) {
          const urlFromS3 = await getUrlFromS3(file);
          fileListWithLink.push({
            fileName: file.name,
            fileLink: urlFromS3,
          });
        }
      }

      const param = {
        id: pageParam.id || "",
        projectId: pageParam.projectId || "",
        packageId: pageParam.packageId || "",
        body: {
          ...values,
          files: fileListWithLink,
        },
      };
      requestJoinWorktask(param);
    } catch (error) {
      console.error(error);
      dispatch(setPageLoading(false));
    }
  };

  const handleCancelUploadFile = () => {
    setUploadFile([]);
    setAttachModalOpen(false);
  };

  return (
    <Container>
      <Wrap>
        <BackgroundImage
          src={ExpressInterestBackground}
          alt="Epress interest background"
        />
        <HeadWrap>
          <Breadcrumb items={items} />
          <Head>
            <div>
              <Title>
                <div>
                  Submission of a request to participate
                  <span> {worktask?.name}</span>
                </div>
              </Title>
              <Gap />
              <Wrap>
                <Status>Open</Status>
                <EndTime>
                  Ends on {dayjs(worktask?.endDate).format("DD MMM YYYY")}
                </EndTime>
              </Wrap>
              <Gap />
              <Wrap>
                <SponsoredBy>Sponsored by</SponsoredBy>
                {worktask?.organizations?.map((org) => (
                  <Fragment key={org?._id}>
                    <Avatar size={24} src={org?.avatar} alt="organization" />
                    <OrgName>{org?.name}</OrgName>
                  </Fragment>
                ))}
              </Wrap>
            </div>
          </Head>
        </HeadWrap>
      </Wrap>
      <Body
        form={form}
        onFinish={(values) =>
          onSubmit(values as IRequestJoinWorktaskParams["body"])
        }
      >
        <Text>Friend, please share more details about you :) Thank you!</Text>

        <StyledFormItem
          label={
            <Item>
              <div>
                Cover Letter <RejectReasonInfoIcon />
              </div>
              <div>
                Explain in bullet points your experience around this WorkTask,
                and when you are ideally positioned to deliver it successfully.
              </div>
            </Item>
          }
          name="coverLetter"
          rules={[requiredRules]}
        >
          <StyledTextArea placeholder="Explain here" />
        </StyledFormItem>
        <StyledFormItem
          label={
            <Item>
              <div>
                Action plan <RejectReasonInfoIcon />
              </div>
              <div>
                Describe briefly your action plan, how are you planning to
                approach the WorkTask.
              </div>
            </Item>
          }
          name="actionPlan"
          rules={[requiredRules]}
        >
          <StyledTextArea placeholder="Write a your action plan. Please note that this session will be publicly published" />
        </StyledFormItem>
        <StyledFormItem
          label={
            <Item>
              <div>
                Expected deliverables <RejectReasonInfoIcon />
              </div>
              <div>
                Describe the expected deliverables following your successful
                acceptance.
              </div>
            </Item>
          }
          name="expectedDeliverables"
          rules={[requiredRules]}
        >
          <StyledTextArea placeholder="Please prepare list with the expected deliverables." />
        </StyledFormItem>

        {!attachModalOpen && uploadFile.length > 0 && (
          <ResourcesWrap>
            {uploadFile.map((file) => (
              <div key={file.uid}>
                <PurpleAttachIcon2 />
                {file.name}
              </div>
            ))}
          </ResourcesWrap>
        )}

        <AttachBtn htmlType="button" onClick={() => setAttachModalOpen(true)}>
          Attach a file <DownloadIcon />
        </AttachBtn>

        <AttachModal
          visible={attachModalOpen}
          onCancel={() => setAttachModalOpen(false)}
          footer={null}
          closable={false}
          width={448}
        >
          <UploadWrapper>
            <AttachTitle>Upload a file</AttachTitle>
            <AttachUpload {...upLoadProps}>
              <UploadInner>
                {uploadFile.length > 0 ? (
                  uploadFile.map((file) => (
                    <span key={file.uid}>{file.name}</span>
                  ))
                ) : (
                  <>
                    <CloudSaveIcon />
                    <span>Click to upload or Drag & Drop</span>
                  </>
                )}
              </UploadInner>
            </AttachUpload>
            <BtnWrap>
              <CancelBtn onClick={handleCancelUploadFile}>Cancel</CancelBtn>
              <AttachConfirmBtn
                disabled={uploadFile.length === 0}
                onClick={() => setAttachModalOpen(false)}
              >
                Attach files
              </AttachConfirmBtn>
            </BtnWrap>
          </UploadWrapper>
        </AttachModal>

        <CheckboxWrap>
          <div
            onClick={() => {
              setConditionCheck1(!conditionCheck1);
              setConditionCheckErr(false);
            }}
          >
            <CheckboxPurple checked={conditionCheck1} />
            <span>
              I understand that this is an application, and I have to be
              approved before I will start working.
            </span>
          </div>
          <div
            onClick={() => {
              setConditionCheck2(!conditionCheck2);
              setConditionCheckErr(false);
            }}
          >
            <CheckboxPurple checked={conditionCheck2} />
            <span>
              I commit to provide frequent updates for the progress,
              alternatively I risk to be removed from this WorkTask. Collectes
              and stored.
            </span>
          </div>
          {conditionCheckErr && <span>Please accept the conditions!</span>}
        </CheckboxWrap>
        <PrimaryButton width="139px" height="48px" htmlType="submit">
          <SendIcon />
          <SendText>Submit a Plan</SendText>
        </PrimaryButton>
      </Body>
    </Container>
  );
};

export default ExpressInterest;
