import BaseButton from "components/base/baseButton";
import styled from "styled-components";
import headingBG from "assets/images/png/createProject/heading-bg.png";

import { Col, Form, Input, InputNumber, Select, Switch, Upload } from "antd";
const { Option } = Select;

export const Heading = styled.div`
  width: 100%;
  height: 8rem;
  background-image: url(${headingBG});
  div {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #080f6c;
    max-width: 73.125rem;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0rem 3.125rem;
    max-width: 1270px;
    margin: auto;
  }
`;

export const CoverImage = styled.img``;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin: 1.875rem auto 1.25rem;
`;

export const Container = styled.div`
  .upload-image {
    .ant-upload {
      width: 100%;
      height: unset;
    }
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
    }
  }
  .ant-picker {
    width: 100%;
  }
  .ant-upload-picture-card-wrapper {
    width: auto;
    img {
      max-height: 130px;
      width: 100%;
      border-radius: 8px;
    }
  }
`;

export const ModalWrap = styled.div`
  padding: 32px;
  position: relative;
  & > svg {
    position: absolute;
    top: 17px;
    right: 13px;
    cursor: pointer;
  }
`;

export const AvatarItem = styled(Form.Item)`
  // margin-bottom: 0;
`;

export const Left = styled.div`
  width: 12.5rem;
  border-right: 0.0625rem solid rgba(41, 46, 115, 0.12);
  height: 100%;

  .popup {
    animation-name: fadeIn;
    animation-duration: 0.4s;
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    left: 0px;
    list-style-type: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 0px;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .popup li {
    clear: both;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 5px 12px;
    transition: all 0.3s;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
  }

  .popup li:hover {
    background-color: #e6f7ff;
  }

  .popup li > i {
    margin-right: 8px;
  }
`;

export const Right = styled.div`
  padding-left: 3rem;
  padding-bottom: 6.25rem;
`;

export const StyledInput = styled(Input)`
  &:not(.ant-input-group-wrapper) {
    max-width: 100%;
    border: 0.0625rem solid rgba(41, 46, 115, 0.12);
    border-radius: 0.25rem;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #080f6c;
    height: 2.25rem;
    padding: 0.625rem;
    &::placeholder {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 100%;
      display: flex;
      align-items: center;
      color: rgba(41, 46, 115, 0.42);
    }
  }

  &.ant-input-group-wrapper-status-error .ant-input-group-addon {
    border-color: #d9d9d9;
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  max-width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;
export const StyledTextArea = styled(Input.TextArea)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 8.125rem !important;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
    padding-top: 0.375rem;
  }
`;

export const BigLabel = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-bottom: 0.3rem;
`;

export const LabelDescription = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  color: rgba(41, 46, 115, 0.82);
  opacity: 0.6;
  margin-bottom: 1rem;
`;

export const StyledUpload = styled(Upload)`
  border-radius: 8px;
  .ant-upload-list {
    width: 100%;
    .ant-upload-list-picture-card-container {
      display: block;
      margin: auto;
    }
    .ant-upload-list-picture-card-container {
      width: 18.75rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .ant-upload-list-item {
      border: 0.0625rem solid rgba(41, 46, 115, 0.22);
    }
  }
  .ant-upload {
    width: 100%;
    height: 8rem;
    &.ant-upload-select-picture-card {
      border-radius: 0.25rem;
      border: 0.0625rem dashed rgba(41, 46, 115, 0.12);
    }
    background: radial-gradient(
      100% 445.32% at 0% 100%,
      #7e00fd12 0%,
      #b93df312 42.35%,
      #a54bf412 57.95%,
      #00bffb12 100%
    );
  }
  &.noBorder {
    .ant-upload-select-picture-card {
      border: none;
    }
  }
`;

export const ProjectThumbWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 30px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  width: 150px;
  height: 64px;
  justify-content: center;
  align-items: center;
`;

export const AvatarUpload = styled(Upload)`
  .ant-upload-list {
    width: 150px;
    margin-top: 1.875rem;
    margin-bottom: 0.625rem;
    .ant-upload-list-picture-card-container {
      display: block;
      margin: auto;
    }
    .ant-upload-list-picture-card-container {
      width: 9.375rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .ant-upload-list-item {
      border: 0.0625rem solid rgba(41, 46, 115, 0.22);
    }
  }
  .ant-upload {
    width: 9.375rem;
    height: 9.375rem;
    &.ant-upload-select-picture-card {
      border-radius: 0.25rem;
      background-image: url(${headingBG});
      background-size: cover;
      border: 0.0625rem solid rgba(41, 46, 115, 0.07);
    }
  }
`;
export const NoFileText = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  margin-left: 0.9rem;
`;

export const LabelWithIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.3rem;
  }
`;

export const StyledSelect = styled(Select)`
  max-width: 100%;
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 2.25rem;
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  svg {
    margin-right: 0.3rem;
  }
  .ant-select-selection-placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #080f6c;
  }
  .ant-select-selection-item {
    padding-top: 0.1875rem !important;
  }
  .ant-select-arrow {
    svg {
      margin-right: 0;
    }
  }
  &.ant-select-open {
    border: 1px solid #7e00fd;
    border-radius: 4px 4px 0px 0px;
    .ant-select-arrow {
      transform: rotate(180deg);
      path {
        stroke: #7e00fd;
      }
    }
  }
  .ant-select-selector {
    height: -webkit-fill-available !important;
  }
`;
export const StyledSelectUser = styled(StyledSelect)`
  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(0);
    }
  }
`;

export const StyledOption = styled(Option)`
  /* background: red !important; */
`;

export const TokenSelectText = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 156%;
  letter-spacing: -0.01em;
  color: rgba(41, 46, 115, 0.82);
  margin-top: 0.3rem;
`;

export const ChooseImageButton = styled(BaseButton)`
  width: 9.5rem;
  height: 2.75rem;
`;

export const SubmitContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 24px;
  display: flex;
  justify-content: end;
`;

export const ExpireDateWrapper = styled(Col)`
  position: relative;
  .ant-switch-checked {
    background: #06c755;
  }
`;

export const ExpireSwitch = styled(Switch)`
  position: absolute;
  top: 0;
  right: 12px;
`;
