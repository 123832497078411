import styled from "styled-components";

export const Container = styled.div`
  // width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    94.38deg,
    #c58aff 7.87%,
    #00adfb 50.31%,
    #00d5c9 82.37%,
    #00de8c 107.9%
  );

  & > div {
    width: 820px;
    height: 820px;
    background: #f4e4d5;
    border-radius: 820px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #070d5e;

    svg {
      /* margin-bottom: 89px; */
      /* width: 674px; */
    }
    div {
      font-weight: 800;
      font-size: 42px;
      line-height: 52px;
      margin-bottom: 18px;
    }
    span {
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 89px;
    }
    a {
      color: #fff;
      text-decoration: none;
      font-size: 18px;
    }
  }
`;
