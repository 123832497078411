import { Empty } from "antd";
import { ICourse } from "api/course";
import { useGetListCourseForCreator } from "api/course/queries";
import { FC } from "react";
import { IListAssignmentForCreatorProps } from "./ListAssignmentForCreator.props";
import {
  Container,
  FakeTabWrapper,
  FakeTabContainer,
  TabPaneCustom,
  TabsContent,
} from "./listAssignmentForCreator.style";
import TabContentPage from "./TabContent";

const ListAssignmentForCreatorPage: FC<IListAssignmentForCreatorProps> = () => {
  const { data: courses, isLoading } = useGetListCourseForCreator();
  const courseList = courses?.responseData?.filter(
    (c) => c?.isDeleted === false
  );

  return (
    <Container>
      {isLoading || (!isLoading && courseList.length === 0) ? (
        <FakeTabContainer>
          <FakeTabWrapper>
            <h1>Courses</h1>
          </FakeTabWrapper>
          {!isLoading && courseList.length === 0 ? <Empty /> : null}
        </FakeTabContainer>
      ) : (
        courseList.length > 0 && (
          <TabsContent defaultActiveKey="0" tabPosition={"left"}>
            {courseList.map((item: ICourse, index: number) => (
              <TabPaneCustom tab={item.title} key={index}>
                <TabContentPage course={item} />
              </TabPaneCustom>
            ))}
          </TabsContent>
        )
      )}
    </Container>
  );
};

export default ListAssignmentForCreatorPage;
