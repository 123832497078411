import BaseButton from "components/base/baseButton";
import styled from "styled-components";
import headingBG from "assets/images/png/createProject/heading-bg.png";

import { Checkbox, Form, Input, InputNumber, Row, Select, Upload } from "antd";
const { Option } = Select;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin: 0 auto 1.25rem;
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
  .ant-btn {
    height: 36px;
  }
`;

export const StyledInput = styled(Input)`
  max-width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  max-width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledSelect = styled(Select)`
  max-width: 100%;
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 2.25rem;
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  svg {
    margin-right: 0.3rem;
  }
  .ant-select-selection-placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #080f6c;
  }
  .ant-select-selection-item {
    padding-top: 0.1875rem !important;
  }
  .ant-select-arrow {
    svg {
      margin-right: 0;
    }
  }
  &.ant-select-open {
    border: 1px solid #7e00fd;
    border-radius: 4px 4px 0px 0px;
    .ant-select-arrow {
      transform: rotate(180deg);
      path {
        stroke: #7e00fd;
      }
    }
  }
`;

export const StyledOption = styled(Option)``;

export const CheckBoxText = styled(Checkbox)`
  span {
    color: #292e73;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7e00fd;
    border-color: #7e00fd;
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: #7e00fd;
    }
  }
`;

export const HighlightText = styled.a`
  color: #7e00fd;
`;
