import { useQuery } from "@tanstack/react-query";
import {
  getCurrentWorktaskAPI,
  getTransactionsPaymentByTokenNameAPI,
  paymentDashboardCollaboratorObserver,
} from "api/users";
import { AddressWalletIcon } from "assets/icons/addressWallet.icon";
import { ArrowRightIcon } from "assets/icons/arrowRight.icon";
import { BlockChainIcon } from "assets/icons/blockchain.icon";
import Pagination from "components/base/pagination";
import TransactionCard2 from "pages/PaymentDashboardAnother/transactionCard2";
import { FC, memo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { supportedNetworks } from "utils/connectors";
import { IPaymentDashboardProps } from "./paymentDashboard.props";
import {
  Card,
  CardList,
  Container,
  ItemContainer,
  ItemTitle,
  DashboardTable,
  Title,
  Wallet,
  WalletRow,
  DashboardHeadingTable,
  TableColumn,
  TokenSymbol,
  TokenValue,
  Heading,
} from "./paymentDashboard.style";
import WorktaskCard from "./worktaskCard";

const PAGE_SIZE = 5;

const PaymentDashboard: FC<IPaymentDashboardProps> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const walletAddress: string = useAppSelector(
    (state) => state.auth.user?.walletAddress
  );
  // const [transactionCollaborator, setTransactionCollaborator] = useState(
  //   [] as any
  // );
  // const [currentWorkTask, setCurrentWorkTask] = useState([] as any);

  // const [incomesData, setIncomesData] = useState([] as any);
  const { data: currWtRes } = useQuery(
    ["getCurrentWorktask"],
    () => getCurrentWorktaskAPI(),
    {
      // onSuccess: async (res) => {
      //   const { responseData, responseCode } = res;
      //   if (responseCode === 200) {
      //     setCurrentWorkTask(responseData.records);
      //   }
      // },
      refetchOnWindowFocus: false,
    }
  );
  const currentWorkTasks = currWtRes?.responseData?.records || [];
  currentWorkTasks.sort(
    (x, y) => y.packages?.startTime - x.packages?.startTime
  );

  const { data: transacCollRes } = useQuery(
    ["getTransactionPaymentCollaborator"],
    () =>
      paymentDashboardCollaboratorObserver({
        role: "Collaborator",
        skip: 0,
        limit: 5,
      }),
    {
      // onSuccess: async (res) => {
      //   const { responseData, responseCode } = res;
      //   if (responseCode === 200) {
      //     try {
      //       dispatch(setPageLoading(true));
      //       setTransactionCollaborator(responseData);

      //       dispatch(setPageLoading(false));
      //     } catch {
      //       dispatch(setPageLoading(false));
      //     }
      //   }
      // },
      refetchOnWindowFocus: false,
    }
  );
  const transactionCollaborator = transacCollRes?.responseData || [];
  transactionCollaborator.sort((x, y) => y.createdAt - x.createdAt);

  const { data: transacPaymentRes } = useQuery(
    ["getTransactionsPaymentByTokenName"],
    () =>
      getTransactionsPaymentByTokenNameAPI({
        role: "Collaborator",
        skip: 0,
        limit: 10,
      }),
    {
      // onSuccess: async (res) => {
      //   const { responseData, responseCode } = res;
      //   if (responseCode === 200) {
      //     try {
      //       dispatch(setPageLoading(true));
      //       setIncomesData(responseData);

      //       dispatch(setPageLoading(false));
      //     } catch {
      //       dispatch(setPageLoading(false));
      //     }
      //   }
      // },
      refetchOnWindowFocus: false,
    }
  );
  const incomesData = transacPaymentRes?.responseData || [];

  const data = incomesData.map((transaction: any, key: number) => ({
    key,
    currencies: transaction.tokenName,
    bonus: transaction.typeBonus?.reduce(
      (sum: any, data: any) => sum + data.amount,
      0
    ),
    MGP: transaction.typeMGP?.reduce(
      (sum: any, data: any) => sum + data.amount,
      0
    ),
  }));

  return (
    <>
      <Heading>
        {/* <div>Collaborator Payment Dashboard</div> */}
        <div>Win to Awarded</div>
      </Heading>
      <Container>
        <div>
          <DashboardHeadingTable dataSource={data} pagination={false}>
            <TableColumn
              title="All Tokens"
              render={(record: any) => (
                <TokenSymbol>{record?.currencies}</TokenSymbol>
              )}
            />
            <TableColumn
              title="Bonus"
              render={(record: any) => (
                <TokenValue>{record?.bonus?.toFixed(2)}</TokenValue>
              )}
            />
            <TableColumn
              title="MGP"
              render={(record: any) => (
                <TokenValue>{record?.MGP?.toFixed(2)}</TokenValue>
              )}
            />
            <TableColumn
              title="Net Income	"
              render={(record: any) => (
                <TokenValue>
                  {(record.bonus + record?.MGP)?.toFixed(2)}
                </TokenValue>
              )}
            />
          </DashboardHeadingTable>
          <DashboardTable>
            <Title>My Wallets</Title>
            <Card>
              <Wallet>
                <ItemContainer>
                  <ItemTitle>
                    <BlockChainIcon />
                    <span>Blockchain Network</span>
                  </ItemTitle>
                  {supportedNetworks.map((network) => (
                    <WalletRow key={network.chainId}>
                      {network.chainName}
                    </WalletRow>
                  ))}
                  {/* <WalletRow>
                    <span>Polygon</span>
                  </WalletRow>{" "}
                  <WalletRow>
                    <span>Aurora</span>
                  </WalletRow>{" "} */}
                </ItemContainer>
                <ItemContainer>
                  <ItemTitle>
                    <AddressWalletIcon />
                    <span>Wallet Address</span>
                  </ItemTitle>
                  {supportedNetworks.map((network) => (
                    <WalletRow key={network.chainId}>
                      <span>{walletAddress}</span>
                      <ArrowRightIcon />
                    </WalletRow>
                  ))}
                  {/* <WalletRow>
                    <span>{walletAddress}</span>
                    <ArrowRightIcon />
                  </WalletRow>

                  <WalletRow>
                    <span>{walletAddress}</span>
                    <ArrowRightIcon />
                  </WalletRow>
                  <WalletRow>
                    <span>{walletAddress}</span>
                    <ArrowRightIcon />
                  </WalletRow> */}
                </ItemContainer>
              </Wallet>
            </Card>
          </DashboardTable>
          <DashboardTable>
            <Title>Transactions</Title>
            <CardList>
              {transactionCollaborator.length ? (
                <>
                  {transactionCollaborator
                    ?.slice(
                      (currentPage - 1) * PAGE_SIZE,
                      PAGE_SIZE * currentPage
                    )
                    ?.map((item: any) => (
                      <WorktaskCard key={item?._id} worktask={item} />
                    ))}
                  <Pagination
                    current={currentPage}
                    pageSize={PAGE_SIZE}
                    total={transactionCollaborator?.length}
                    onChange={(page: number) => setCurrentPage(page)}
                  />
                </>
              ) : (
                <ItemTitle>You have no transaction</ItemTitle>
              )}
            </CardList>
          </DashboardTable>
          <DashboardTable>
            <Title>Current WorkTasks</Title>
            <CardList>
              {currentWorkTasks?.length ? (
                currentWorkTasks?.map((item: any) => (
                  <TransactionCard2 key={item?._id} transaction={item} />
                ))
              ) : (
                <ItemTitle>You have no current worktask</ItemTitle>
              )}
            </CardList>
          </DashboardTable>
        </div>
      </Container>
    </>
  );
};

export default memo(PaymentDashboard);
