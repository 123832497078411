import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";
import { Input, Select, Modal } from "antd";
import Button from "components/base/button";

const { Option } = Select;

export const SubmitDeliverModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0rem 1rem 2rem -0.75rem rgba(56, 105, 178, 0.16);
  }

  .ant-modal-body {
    padding: 1.75rem 2rem 2rem;
  }
`;

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  padding: 0rem 3.125rem;
`;

export const Head = styled.div`
  padding: 3rem 0rem;
  max-width: 1170px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.0625rem #292e7312;
`;

export const HeadChildWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  & > img {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 7.5rem;
    object-fit: cover;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: #080f6c;
  margin: 0.625rem 0rem 1.25rem;
`;

export const SmallTitle = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 110%;
  color: rgba(41, 46, 115, 0.82);
`;

export const BodyWrap = styled.div`
  padding: 2rem 0rem 9.625rem;
  max-width: 1170px;
  margin: auto;
`;

export const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
`;

export const Description = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #080f6c;
  margin-bottom: 0.75rem;
  margin-top: 2rem;
`;

// export const SubmitPopupWrap = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: rgba(8, 15, 108, 0.6);
//   /* backdrop-filter: blur(0.3125rem); */
//   z-index: 100;

//   & > form {
//     padding: 1.75rem 2rem 2rem;
//     width: 39rem;
//     /* height: 41.25rem; */
//     background: #ffffff;
//     box-shadow: 0rem 1rem 2rem -0.75rem rgba(56, 105, 178, 0.16);
//     border-radius: 0.5rem;
//   }
// `;

export const TagText = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  letter-spacing: -0.03em;
  color: #080f6c;
  margin-bottom: 2rem;
`;
export const PTitle = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 100%;
  color: #080f6c;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
export const Wrap10 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-bottom: 2.25rem;
`;
export const FlexWrap = styled(Wrap10)`
  width: 100%;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  /* max-height: 11.25rem;
  overflow: auto; */
  color: rgba(41, 46, 115, 1);
  > div {
    border-bottom: 2px dashed #d9d6d6;
    padding-bottom: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(8, 15, 108, 0.05);
    height: 24.125rem;
  }
  &::-webkit-scrollbar {
    width: 0.4375rem;
    background-color: rgba(8, 15, 108, 0.08);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(8, 15, 108, 0.08);
    height: 2.5rem;
  }
`;
export const Wrap12 = styled.div`
  gap: 0.75rem;
  display: flex;
  & > * {
    width: calc((100% - 0.75rem) / 2);
  }
`;

export const WrapInput = styled.label`
  cursor: pointer;
  & > input {
    display: none;
  }
  .ant-input-affix-wrapper {
    margin-top: 6px !important  ;
  }
`;

export const BaseInput = styled(Input)<{ mt?: string }>`
  padding: 0.75rem 0.5rem;
  background: #ffffff;
  border: 0.0625rem solid rgba(156, 138, 193, 0.16) !important;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 100%;
  margin-top: ${(p) => p.mt};

  &::placeholder {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 100%;
    color: rgba(41, 46, 115, 0.82);
  }
`;
export const IconInput = styled(BaseInput)`
  padding: 0.75rem 0.625rem;
  height: 2.6rem;

  .ant-input {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 100%;
    color: rgba(41, 46, 115, 1);
    &::placeholder {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 100%;
      color: rgba(41, 46, 115, 0.82);
    }
  }
  .ant-input-prefix {
    margin-right: 0.5rem;
  }
`;
export const AddAnotherFile = styled.div`
  display: flex;
  gap: 0.375rem;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 115%;
  color: #7e00fd;
  cursor: pointer;
`;
export const SubmitBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
export const SubmitBtn = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 100%;
  color: #ffffff;
`;

export const SuccessfulWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(8, 15, 108, 0.6);
  z-index: 3985239084;

  & > div {
    padding: 4.1875rem 2.75rem 3.75rem;
    width: 34rem;
    height: 28rem;
    background: #ffffff;
    box-shadow: 0rem 1rem 2rem -0.75rem rgba(56, 105, 178, 0.16);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 125%;
      letter-spacing: -0.03em;
      color: #080f6c;
      width: 23.375rem;
      margin-top: 0.25rem;
      text-align: center;
    }
    & > p {
      font-weight: 400;
      font-size: 1rem;
      line-height: 156%;
      text-align: center;
      letter-spacing: -0.02em;
      color: rgba(41, 46, 115, 0.82);
      margin-bottom: 2.25rem;
      margin-top: 1rem;
    }
  }
`;
export const SuccessBtn = styled(Button)`
  border: 0.0625rem solid rgba(41, 46, 115, 0.42);
  border-radius: 0.25rem;
`;

export const StyledSelectWrap = styled.div`
  width: 100%;
  height: 2.25rem;
  overflow: visible;
  position: relative;
  top: 0;
`;
export const StyledSelect = styled(Select)`
  width: 100%;
  background: #ffffff;
  border: 0.0625rem solid rgba(156, 138, 193, 0.16);
  border-radius: 0.25rem;
  height: 42px;
  /* position: absolute; */
  top: 0;
  left: 0;
  overflow: hidden;
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  svg {
    margin-right: 0.3rem;
  }
  .ant-select-selector {
    padding: 0rem 0.5rem !important;
  }
  .ant-select-selection-placeholder {
    padding-top: 0.125rem !important;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 100%;
    color: rgba(41, 46, 115, 0.82);
  }
  .ant-select-selection-item {
    padding-top: 0.125rem !important;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 100%;
    color: rgba(41, 46, 115, 1);
  }
  .ant-select-arrow {
    svg {
      margin-right: 0;
    }
  }
  &.ant-select-open {
    border: 0.0625rem solid #7e00fd;
    border-radius: 0.25rem;
    .ant-select-arrow {
      transform: rotate(180deg);
      path {
        stroke: #7e00fd;
      }
    }
  }
`;

export const StyledOption = styled(Option)`
  /* background: red !important; */
`;
export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0;
  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
    display: none;
  }
  .ant-form-item-label {
    /* padding-bottom: 0.625rem; */
    display: none;
  }
  input {
    margin-bottom: 6px;
    margin-top: 6px;
  }
  .ant-upload input {
    margin: 0;
  }
`;
