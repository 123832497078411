import { Input } from "antd";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import styled from "styled-components";
import { ItemSection } from "../CreateSection/createSection.style";

export const WrapButton = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
`;

export const CancelButton = styled(OutlinedButton)`
  width: 100px;
  height: 48px;
`;
export const CloseButton = styled(OutlinedButton)`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 8px;
`;

export const SaveButton = styled(PrimaryButton)`
  width: 100px !important;
  height: 48px !important;
`;

export const WrapContent = styled.div`
  background: rgba(156, 138, 193, 0.06);
  border: 1px solid rgba(156, 138, 193, 0.12);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 32px;
  padding: 24px;
  display: grid;
  gap: 16px;
  form {
    .ant-form-item {
      margin-bottom: 10px;
    }
    .submission {
      display: flex;
      align-items: center;
      .ant-form-item {
        margin-bottom: unset;
        .ant-form-item-control-input {
          min-height: unset;
        }
      }
    }
  }
  .ant-row .ant-form-item {
    margin-bottom: unset;
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
  }
`;
export const Item = styled(ItemSection)`
  .question {
    .ck-content {
      height: 100px !important;
    }
  }
  .content {
    .ck-content {
      height: 200px !important;
    }
  }
  .ant-form-item-control-input {
    min-height: unset !important;
  }
`;

export const Label = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.42);
  user-select: none;
`;

export const InputStyled = styled(Input)`
  background: inherit;
  border: unset;
  border-bottom: 1px solid rgba(41, 46, 115, 0.12);
  padding: unset;
`;

export const TextAreaStyled = styled(Input.TextArea)`
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 4px;
`;

export const UploadButton = styled(OutlinedButton)`
  width: 100%;
  height: 40px;
  background: rgba(156, 138, 193, 0.16);
  border-radius: 4px;
`;

export const Hr = styled.hr`
  opacity: 0.2;
  border: 1px dashed #343c45;
`;
