import {
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Col, message, Modal, Upload } from "antd";
import type { UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { postUploadsAPI } from "api/users";
import picturesImage from "assets/images/addPortfolio/pictures.png";
import axios from "axios";
import { FC, useRef, useState } from "react";
import { IUploadFileProps } from "./projectFile.props";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import {
  AntdDragger,
  DocumentBlock,
  DraggerBlock,
  ImageBlock,
  ImageUpload,
  MainTitle,
  PrimaryText,
  RowAntd,
  SubTitleIcon,
  SubTitleText,
  UploadContainer,
  UploadWrapper,
} from "./projectFile.style";
import type { UploadRequestOption } from "rc-upload/lib/interface";
import { useParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ProjectFile: FC<IUploadFileProps> = ({
  onChangeFiles,
  projectFormData,
  setProjectFormData,
  setIsUploading,
  ...props
}) => {
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [preview, setPreview] = useState("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [previewType, setPreviewType] = useState<string>("");
  const [numPages, setNumPages] = useState(null);
  const { portfolioId } = useParams();
  const isEdit = !!portfolioId;

  const count = useRef(0);

  const videoLimit = 100;

  const fileLimit = 10;

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const fileUrls = projectFormData?.files?.map((file: any, index: number) => {
    return {
      uid: file?._id || `-${index}`,
      name: file?.fileName || file.name,
      url: file?.fileLink || file.url,
    };
  });

  const [fileList, setFileList] = useState<UploadFile[]>(fileUrls || []);

  const { mutateAsync: postUploads } = useMutation(postUploadsAPI);

  const handleCancel = () => {
    setPreview("");
    setPreviewType("");
    setPreviewOpen(false);
  };

  const onSaveFiles = (files: any) => {
    const projectFiles = files.map((item: any) => {
      return {
        ...item,
        url: (item?.url || item.xhr?.url) ?? "",
      };
    });
    if (onChangeFiles) {
      onChangeFiles(projectFiles);
    }
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    const allowImgType = ["png", "gif", "jpeg"];
    const allowedAudioType = ["mp3", "wav", "mpeg"];
    const allowedVideoType = ["mp4", "x-ms-wmv"];
    const allowedDocType = ["pdf"];
    const currentType = file.url || file?.type;

    if (allowImgType.some((type) => currentType?.includes(type))) {
      setPreview(file?.url || file?.xhr?.url || "");
      setPreviewType("image");
    }

    if (allowedAudioType.some((type) => currentType?.includes(type))) {
      setPreview(file?.url || file?.xhr?.url || "");
      setPreviewType("audio");
    }
    if (allowedVideoType.some((type) => currentType?.includes(type))) {
      setPreview(file?.url || file?.xhr?.url || "");
      setPreviewType("video");
    }

    if (allowedDocType.some((type) => currentType?.includes(type))) {
      setPreview(file?.url || file?.xhr?.url || "");
      setPreviewType("pdf");
    }
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  // TODO: Validate file
  const isValidFile = async (file: any, fileList?: any) => {
    try {
      const videoQuantity = fileList.reduce((acc: number, file: any) => {
        if (file.type && file.type.includes("video")) {
          return acc + 1;
        }
        return acc;
      }, 0);

      const audioQuantity = fileList.reduce((acc: number, file: any) => {
        if (file.type && file.type.includes("audio")) {
          return acc + 1;
        }
        return acc;
      }, 0);

      if (file.type && file.type.includes("image")) {
        // dimension
        if (file.size / 1024 / 1024 > fileLimit) {
          message.error(
            `File ${file.name} is too large, maximum file size is ${fileLimit}MB.`
          );
          return false;
        }
        const image = new Image();
        image.src = await getBase64(file);
        if (image.width > 4000 || image.height > 4000) {
          message.error(
            `File ${file.name} has a dimension is more than 4000px, maximum dimension is 4000px.`
          );
          return false;
        }
        return true;
      }

      if (file.type && file.type.includes("video")) {
        const duration: any = await getDurationTimeOfVideo(file);

        if (duration > 60) {
          message.error("Your video can only maximum 60 seconds");
          return false;
        }
        if (videoQuantity > 2) {
          if (count.current !== videoQuantity - 1) count.current++;
          else count.current = 0;

          if (count.current === 1) message.error("You can only upload 2 video");
          return false;
        }
        if (file.size / 1024 / 1024 > videoLimit) {
          message.error(
            `File ${file.name} is too large, maximum file size is ${videoLimit}MB.`
          );
          return false;
        }
        return true;
      }

      if (file.type && file.type.includes("audio")) {
        if (audioQuantity === 20) {
          message.error("You can only upload 20 audio");
          return false;
        }
        if (file.size / 1024 / 1024 > fileLimit) {
          message.error(
            `File ${file.name} is too large, maximum file size is ${fileLimit}MB.`
          );
          return false;
        }
        return true;
      }

      if (file.type && file.type.includes("application/pdf")) {
        if (file.size / 1024 / 1024 > fileLimit) {
          message.error(
            `File ${file.name} is too large, maximum file size is ${fileLimit}MB.`
          );
          return false;
        }
        return true;
      }
      message.error(`File ${file.name} has invalid file type.`);
      return false;
    } catch (error) {
      console.error("err", error);
    }
  };

  const customRequest = async ({
    onProgress,
    file,
    onSuccess,
  }: UploadRequestOption<any>) => {
    const rs = await postUploads({
      fileName: (file as any)?.name?.replace(" ", "_"),
      fileType: (file as any)?.type,
    });

    await axios
      .put(rs.signedRequest, file, {
        onUploadProgress: (e) => {
          onProgress?.(
            { percent: +((e.loaded / e.total) * 100).toFixed(2) }
            // file
          );
        },
      })
      .then((res) => {
        onSuccess?.(res.data, { ...(file as any), url: rs.url });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onChange = (info: any) => {
    setFileList(info.fileList.slice(0, 20));
    onSaveFiles(info.fileList.slice(0, 20));
    const isUploadSuccess = info.fileList.every(
      (file: any) => file.status === "done" || file.url
    );
    if (isUploadSuccess && setIsUploading) setIsUploading(false);
  };

  const onRemove = (file: any) => {
    const fileIndex = fileList.findIndex((item) => item.uid === file.uid);
    if (fileIndex >= 0) {
      const newFileList = [...fileList];
      newFileList.splice(fileIndex, 1);
      setFileList(newFileList);
      onSaveFiles(newFileList);
    }
  };

  const getDurationTimeOfVideo = async (file: any) => {
    const duration = await new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = async () => {
        const duration = await new Promise((resolve, reject) => {
          var audio = new Audio(reader.result?.toString());
          audio.onloadedmetadata = function () {
            resolve(audio.duration);
          };
          reader.onerror = reject;
        });

        resolve(duration);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
    return duration;
  };

  // TODO: beforeUpload;
  const beforeUpload: UploadProps["beforeUpload"] = async (file, newList) => {
    const fileListWithoutEdit = isEdit
      ? fileList?.filter((item) => item.type)
      : fileList;
    const newFileList = [...fileListWithoutEdit, ...newList];

    if (newFileList.length > 20) {
      message.error("You can only upload maximum 20 files.");
      return false;
    }

    if (!(await isValidFile(file, newFileList))) {
      return Upload.LIST_IGNORE;
    }

    if (!file) {
      return false;
    }
    if (setIsUploading) setIsUploading(true);
  };

  return (
    <>
      <UploadContainer {...props}>
        <DraggerBlock className={fileList.length > 0 ? "uploaded" : ""}>
          <AntdDragger
            isHaveFile={fileList.length > 0}
            multiple
            listType="picture"
            accept="image/jpg, image/gif, image/png, audio/mp3, audio/wav, .mp4, .mov, .webm, .ogm, .pdf"
            fileList={fileList}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
            onChange={onChange}
            onPreview={handlePreview}
            maxCount={20}
            onRemove={onRemove}
            progress={{
              strokeColor: {
                "0%": "#108ee9",
                "100%": "#7e00fd",
              },
              strokeWidth: 3,
            }}
            iconRender={(file) => {
              const fileType = file.type;
              return fileType?.includes("image") ||
                (file as any)?.url?.includes("image") ? (
                <FileImageOutlined />
              ) : file.type?.includes("pdf") ||
                (file as any)?.url?.includes("pdf") ? (
                <FilePdfOutlined />
              ) : (
                <FileOutlined />
              );
            }}
          >
            <UploadWrapper isHaveFile={fileList.length > 0}>
              <ImageBlock isHaveFile={fileList.length > 0}>
                <ImageUpload src={picturesImage} />
              </ImageBlock>
              <MainTitle isHaveFile={false}>
                Drag and drop or <PrimaryText> browse </PrimaryText>files
              </MainTitle>
              <RowAntd
                gutter={[16, 8]}
                className={fileList.length > 0 ? "upload-hide" : ""}
              >
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubTitleIcon>
                    <SubTitleText>
                      Images (.jpg, .gif, .png, up to 10 MB, no more than 4000px
                      in any dimension)
                    </SubTitleText>
                  </SubTitleIcon>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubTitleIcon>
                    <SubTitleText>
                      Videos (.mp4, .mov, .webm, .ogm, .ogv, up to 100 MB, 2
                      maximum, 60 seconds)
                    </SubTitleText>
                  </SubTitleIcon>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubTitleIcon>
                    <SubTitleText>
                      Audio (.mp3, .wav, up to 10 MB, 20 maximum)
                    </SubTitleText>
                  </SubTitleIcon>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubTitleIcon>
                    <SubTitleText>Document (.pdf, up to 10 MB)</SubTitleText>
                  </SubTitleIcon>
                </Col>
              </RowAntd>
            </UploadWrapper>
          </AntdDragger>
        </DraggerBlock>

        <Modal
          visible={previewOpen}
          title={previewTitle}
          footer={false}
          onCancel={handleCancel}
          width={previewType === "pdf" ? "660px" : "400px"}
        >
          {preview && previewType === "image" && (
            <img alt="example" style={{ width: "100%" }} src={preview} />
          )}
          {preview && previewType === "audio" && (
            <audio controls>
              <source src={preview} type="audio/mpeg" />
            </audio>
          )}
          {preview && previewType === "video" && (
            <video width="100%" height="100%" controls>
              <source src={preview} type="video/mp4" />
              <object data={preview} width="470" height="255">
                <embed src={preview} width="470" height="255" />
              </object>
            </video>
          )}
          {preview && previewType === "pdf" && (
            <>
              <DocumentBlock>
                <Document file={preview} onLoadSuccess={onDocumentLoadSuccess}>
                  {[...Array(numPages)].map((_, index) => (
                    <Page
                      key={`page_${index}`}
                      pageNumber={index + 1}
                      pageIndex={index}
                      renderTextLayer={false}
                    />
                  ))}
                </Document>
              </DocumentBlock>
            </>
          )}
        </Modal>
      </UploadContainer>
    </>
  );
};

export default ProjectFile;
