import { FC } from "react";

export const InfoIcon: FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM7.4375 9.95312C7.4375 10.0133 7.38828 10.0625 7.32812 10.0625H6.67188C6.61172 10.0625 6.5625 10.0133 6.5625 9.95312V6.23438C6.5625 6.17422 6.61172 6.125 6.67188 6.125H7.32812C7.38828 6.125 7.4375 6.17422 7.4375 6.23438V9.95312ZM7 5.25C6.82827 5.24649 6.66476 5.17581 6.54455 5.05312C6.42434 4.93044 6.35701 4.76551 6.35701 4.59375C6.35701 4.42199 6.42434 4.25706 6.54455 4.13438C6.66476 4.01169 6.82827 3.94101 7 3.9375C7.17173 3.94101 7.33524 4.01169 7.45545 4.13438C7.57566 4.25706 7.64299 4.42199 7.64299 4.59375C7.64299 4.76551 7.57566 4.93044 7.45545 5.05312C7.33524 5.17581 7.17173 5.24649 7 5.25Z"
        fill="#292E73"
        fillOpacity="0.42"
      />
    </svg>
  );
};

export const RejectReasonInfoIcon: FC = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11526_7398)">
        <path
          d="M5 10C6.32608 10 7.59785 9.47322 8.53553 8.53553C9.47322 7.59785 10 6.32608 10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 0 5 0C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5C0 6.32608 0.526784 7.59785 1.46447 8.53553C2.40215 9.47322 3.67392 10 5 10V10ZM5.58125 4.1175L4.95625 7.05813C4.9125 7.27063 4.97438 7.39125 5.14625 7.39125C5.2675 7.39125 5.45063 7.3475 5.575 7.2375L5.52 7.4975C5.34062 7.71375 4.945 7.87125 4.60438 7.87125C4.165 7.87125 3.97813 7.6075 4.09938 7.04687L4.56062 4.87938C4.60062 4.69625 4.56438 4.63 4.38125 4.58562L4.09938 4.535L4.15062 4.29688L5.58187 4.1175H5.58125ZM5 3.4375C4.83424 3.4375 4.67527 3.37165 4.55806 3.25444C4.44085 3.13723 4.375 2.97826 4.375 2.8125C4.375 2.64674 4.44085 2.48777 4.55806 2.37056C4.67527 2.25335 4.83424 2.1875 5 2.1875C5.16576 2.1875 5.32473 2.25335 5.44194 2.37056C5.55915 2.48777 5.625 2.64674 5.625 2.8125C5.625 2.97826 5.55915 3.13723 5.44194 3.25444C5.32473 3.37165 5.16576 3.4375 5 3.4375V3.4375Z"
          fill="#080A25"
        />
      </g>
      <defs>
        <clipPath id="clip0_11526_7398">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BudgetInfoIcon: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM7.4375 9.95312C7.4375 10.0133 7.38828 10.0625 7.32812 10.0625H6.67188C6.61172 10.0625 6.5625 10.0133 6.5625 9.95312V6.23438C6.5625 6.17422 6.61172 6.125 6.67188 6.125H7.32812C7.38828 6.125 7.4375 6.17422 7.4375 6.23438V9.95312ZM7 5.25C6.82827 5.24649 6.66476 5.17581 6.54455 5.05312C6.42434 4.93044 6.35701 4.76551 6.35701 4.59375C6.35701 4.42199 6.42434 4.25706 6.54455 4.13438C6.66476 4.01169 6.82827 3.94101 7 3.9375C7.17173 3.94101 7.33524 4.01169 7.45545 4.13438C7.57566 4.25706 7.64299 4.42199 7.64299 4.59375C7.64299 4.76551 7.57566 4.93044 7.45545 5.05312C7.33524 5.17581 7.17173 5.24649 7 5.25Z"
        fill="#292E73"
        fillOpacity="0.42"
      />
    </svg>
  );
};
