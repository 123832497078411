import styled from "styled-components";
import { THEME } from "utils/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${THEME.MAX_WITH};
  margin: auto;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  background: rgba(156, 138, 193, 0.04);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
`;

export const TitleBlock = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1.25rem;
`;

export const Title = styled.div`
  color: #080f6c;
  font: 700 1.5rem/125% "DM Sans" !important;
  letter-spacing: -0.04em;
`;

export const ContainerItem = styled.div`
  display: flex;
  column-gap: 0.5938rem;
  flex-wrap: wrap;
`;

export const ItemSocial = styled.div`
  display: flex;
  column-gap: 0.5625rem;
  align-items: center;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  padding: 1.0938rem 1.875rem 1.0938rem 1.3438rem;
  width: 390px;
  margin-top: 10px;

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
`;

export const LinksSocial = styled.div<{ hasLink: boolean }>`
  color: #080f6c;
  font: 400 1rem/125% "Rubik" !important;
  letter-spacing: -0.02em;
  cursor: ${({ hasLink }) => (hasLink ? "pointer" : "auto")};
`;
