import styled from "styled-components";

export const MainContent = styled.main`
  flex-grow: 1;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 73.125rem;
  margin: auto;
`;
