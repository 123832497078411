import { FC, memo } from "react";
import { IProfilePreviewProps } from "./profilePreview.props";
import {
  Container,
  AvatarProfile,
  ContainerFluidHeader,
  InfoProfile,
  UserName,
  TwitterName,
  ProfileBox,
  UserNameBlock,
  JobInfo,
  LocationBlock,
  Location,
  ShortBio,
  TitleHeader,
  Wrap,
  BioWrapper,
  PercentWrapper,
  PercentText,
  Progress,
  DividedLine,
  EditProfileBtn,
} from "./profilePreview.style";
import { LocationIcon } from "assets/images/profile-preview/index";
import PastExperience from "./components/PastExperience";
import Skill from "./components/Skill";
import JobPreference from "./components/JobPreference";
import Education from "./components/Education";
import SocialLink from "./components/SocialLink";
import { Portfolio } from "./components/Portfolio";
import { useQuery } from "@tanstack/react-query";
import {
  getProcessUpdateProfileAPI,
  ISocialLink,
  // updateWalletAddressAPI,
} from "api/users";
// import { message } from "antd";
import { AVATAR_FALLBACK_URL, ROUTER_CONSTANTS } from "utils/constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useEthers } from "@usedapp/core";
import { useAppSelector } from "redux/hooks";
import PrimaryButton from "components/base/primaryButton";
import { useUserInfo } from "hooks";
import { ProjectHistory } from "./components/ProjectHistory";
import { PencilIcon } from "assets/icons/pencil.icon";
import { Space } from "antd";

const ProfilePreview: FC<IProfilePreviewProps> = () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const profileId = new URLSearchParams(search).get("id");
  // const [profile, setProfile] = useState<IProfile>();
  // const { account } = useEthers();
  // const queryClient = useQueryClient();
  // const [isRequestUpdate, setIsRequestUpdate] = useState(false);
  const userInfo = useAppSelector((state) => state.auth.user);
  const userId: string | undefined = userInfo?._id;
  // const userId: string = useAppSelector((state) => state.auth.user?._id);
  const { data } = useQuery(
    ["processUpdateProfile"],
    () => getProcessUpdateProfileAPI(userId as string),
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
    }
  );
  const percentage = Math.round((data?.responseData?.step / 6) * 100 || 0);
  const profile = useUserInfo(profileId);
  const isViewOther = profileId !== null && profileId !== userId;

  const socialLink: ISocialLink = {
    personalBlogURL: profile?.personalBlogURL,
    linkedIn: profile?.linkedIn,
    personURL: profile?.personURL,
    twitterURL: profile?.twitterURL,
  };

  return (
    <Container>
      <ContainerFluidHeader>
        <ProfileBox>
          <BioWrapper>
            {profile?.profilePicture ? (
              <AvatarProfile src={profile?.profilePicture} alt="" />
            ) : (
              <AvatarProfile src={AVATAR_FALLBACK_URL} alt="" />
            )}

            <InfoProfile>
              <UserNameBlock>
                <UserName>{profile?.fullName}</UserName>
                <TwitterName>
                  @{profile?.username || profile?.emailId.split("@")[0]}
                </TwitterName>
              </UserNameBlock>
              {profile?.employmentHistory.map(
                (employmentHistory, key: number) => {
                  return (
                    employmentHistory.isCurrentWork && (
                      <JobInfo key={key}>
                        {employmentHistory?.designation} at{" "}
                        {employmentHistory?.company}
                      </JobInfo>
                    )
                  );
                }
              )}
              {profile?.country && (
                <LocationBlock>
                  <LocationIcon />
                  <Location>
                    Based in <span>{profile?.country}</span>
                  </Location>
                </LocationBlock>
              )}
              <DividedLine></DividedLine>
              {profile?.about && <ShortBio>{profile?.about}</ShortBio>}
              <Space align="center" size={10}>
                {!profileId ? (
                  <EditProfileBtn
                    onClick={() =>
                      navigate(ROUTER_CONSTANTS.USER.PROFILE_USERINNER)
                    }
                  >
                    <PencilIcon />
                    Edit Profile
                  </EditProfileBtn>
                ) : null}
                {!profileId ? (
                  profile?.organization ? (
                    <Link
                      to={ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(
                        ":id",
                        profile.organization
                      )}
                    >
                      <PrimaryButton>See Project Sponsor Profile</PrimaryButton>
                    </Link>
                  ) : (
                    <Link to={ROUTER_CONSTANTS.ORGANIZATION.CREATE}>
                      <PrimaryButton>Become a Project Sponsor</PrimaryButton>
                    </Link>
                  )
                ) : null}
                {!!profileId && userId !== profileId && (
                  <PrimaryButton width="70px" height="36px">
                    <Link to={`/conversation?id=${profileId}`}>Let's talk</Link>
                  </PrimaryButton>
                )}
              </Space>
            </InfoProfile>
          </BioWrapper>
          {!profileId && (
            <Progress
              type="circle"
              strokeColor={{
                "0%": "#7e00fd",
                "51.04%": "#b93df3",
                "100%": "#00bffb",
              }}
              trailColor="rgba(165, 75, 244, 0.2)"
              percent={percentage}
              format={(percent) => {
                return (
                  <PercentWrapper>
                    <PercentText cl="#E94600">{percent}%</PercentText>
                    <PercentText>Completed</PercentText>
                  </PercentWrapper>
                );
              }}
            />
          )}
        </ProfileBox>
      </ContainerFluidHeader>
      <Wrap>
        {!profileId && (
          <TitleHeader>
            You're now previewing how your profile appears to others.{" "}
            <Link to={ROUTER_CONSTANTS.USER.PROFILE_USERINNER}>
              Back to Editing
            </Link>
          </TitleHeader>
        )}
        <PastExperience pastExperienceData={profile?.employmentHistory} />
        <Skill
          skill={profile?.skills}
          profileId={profileId ? profileId : userId || ""}
        />
        <JobPreference jobPreference={profile?.jobPreference} />
        <Education education={profile?.education} />
        <SocialLink
          socialLink={socialLink}
          viewOtherUser={isViewOther}
          profile={profile}
        />
        <ProjectHistory
          profileId={profileId ? profileId : userId || ""}
          viewOtherUser={isViewOther}
          profile={profile}
        />
        <Portfolio
          mt="0 0 0 24px !important"
          profileId={profileId ? profileId : userId || ""}
          viewOtherUser={isViewOther}
          profile={profile}
        />
      </Wrap>
    </Container>
  );
};

export default memo(ProfilePreview);
