import { FileOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { message, Space } from "antd";
import { deletePortfolioAPI } from "api/users";
import { PurpleEarth } from "assets/icons/purpleEarth.icon";
import { allAllowedType } from "pages/AddPortfolio/addDetailStep";
import Page404 from "pages/Page404";
import { FC, Fragment, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { setPageLoading } from "redux/slices";
import { ROUTER_CONSTANTS } from "utils/constant";
import { elementing, splitDescription } from "utils/formatDescription";
import { getSkillTagColor } from "utils/getSkillTagColor";
import { acceptImage } from "utils/regex";
import {
  PreviewWrapper,
  PreviewHeadWrapper,
  PreviewTitle,
  PreviewChangePortfolio,
  PreviewDeletePortfolio,
  PreviewDescriptionWrapper,
  PreviewDescriptionTitle,
  PreviewDescriptionInfo,
  PreviewSkillWrapper,
  PreviewSkillTagList,
  PreviewSkillTag,
  PreviewProjectFilesWrapper,
  PreviewProjectFilesList,
  PreviewProjectFileItem,
  PreviewProjectFileItemImg,
  PreviewVideosWrapper,
  PreviewProjectUrlWrapper,
  PreviewProjectUrlList,
  PreviewProjectUrl,
  PreviewConfirmDeleteModal,
  PreviewConfirmDeleteModalTitle,
  PreviewConfirmDeleteModalActionWrapper,
  PreviewConfirmDeleteModalActionBtn,
} from "./portfolioPreview.style";

const PortfolioPreview: FC = () => {
  const { portfolioId } = useParams();
  const location = useLocation();
  const routerState = location.state as any;
  const selectedPortfolio = routerState?.selectedPortfolio;
  const navigate = useNavigate();
  const { mutate: deletePortfolio } = useMutation(deletePortfolioAPI);
  const dispatch = useAppDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const isViewOtherUser = routerState?.viewOtherUser === true;

  if (!selectedPortfolio) {
    return <Page404 />;
  }

  const handleClickEditPortfolio = () => {
    navigate(`${ROUTER_CONSTANTS.USER.EDIT_PORTFOLIO}/${portfolioId}`, {
      state: { ...(location.state as any) },
    });
  };

  const handleClickDeletePortfolio = async () => {
    dispatch(setPageLoading(true));
    try {
      deletePortfolio(portfolioId as string, {
        onSuccess: (res) => {
          const { responseCode, responseMessage } = res;
          if (responseCode === 200) {
            message.success(responseMessage);
            navigate(-1);
          } else {
            message.error(responseMessage);
          }

          dispatch(setPageLoading(false));
        },
        onError: (err: any) => {
          const rs: any = err?.response?.data;
          message.error(rs?.message);

          dispatch(setPageLoading(false));
        },
      });
    } catch (error) {}
  };

  const formatTextToNewType = (text: string) => {
    return (
      <PreviewDescriptionInfo>
        {splitDescription(text).map((str: string, i: number) => (
          <Fragment key={i}>
            {elementing(str)}
            <br />
          </Fragment>
        ))}
      </PreviewDescriptionInfo>
    );
  };

  const getIconByFileType = (fileLink: string) => {
    const type = allAllowedType.findIndex((allowType) =>
      allowType.some((t) => fileLink?.includes(t))
    );
    switch (type + 1) {
      case 1:
      case 4:
        return <FileOutlined />;
      case 3: // application
        return <FilePdfOutlined />;
    }
  };

  return (
    <PreviewWrapper>
      <PreviewHeadWrapper>
        <PreviewTitle>{selectedPortfolio?.projectTitle}</PreviewTitle>
        {!isViewOtherUser && (
          <Space align="center" size={10}>
            <PreviewChangePortfolio onClick={handleClickEditPortfolio}>
              Edit
            </PreviewChangePortfolio>
            <PreviewDeletePortfolio onClick={() => setOpenDelete(true)}>
              Delete
            </PreviewDeletePortfolio>
          </Space>
        )}
      </PreviewHeadWrapper>
      <PreviewDescriptionWrapper>
        <PreviewDescriptionTitle>Short description</PreviewDescriptionTitle>
        {selectedPortfolio?.description &&
          formatTextToNewType(selectedPortfolio?.description)}
      </PreviewDescriptionWrapper>
      {selectedPortfolio?.skills?.length > 0 && (
        <PreviewSkillWrapper>
          <PreviewDescriptionTitle>
            Skills and deliverables
          </PreviewDescriptionTitle>
          <PreviewSkillTagList>
            {selectedPortfolio?.skills?.map((sk: string) => (
              <PreviewSkillTag key={sk} tagColor={getSkillTagColor()}>
                {sk}
              </PreviewSkillTag>
            ))}
          </PreviewSkillTagList>
        </PreviewSkillWrapper>
      )}
      <PreviewProjectFilesWrapper>
        <PreviewDescriptionTitle>Project Files</PreviewDescriptionTitle>
        <PreviewProjectFilesList space="20">
          {selectedPortfolio?.files?.map((file: any) => {
            const chunks = file?.fileLink?.split(".");
            const fileType = chunks ? chunks[chunks.length - 1] : "";
            const isImgType = acceptImage
              .split(",")
              .some((imgT) => imgT.includes(fileType));

            return file && chunks ? (
              <PreviewProjectFileItem key={file?._id} href={file?.fileLink}>
                {isImgType ? (
                  <PreviewProjectFileItemImg
                    preview={false}
                    src={file?.fileLink}
                    alt={file?.fileName}
                    width={48}
                    height={48}
                  />
                ) : (
                  getIconByFileType(fileType)
                )}
                {file?.fileName}
              </PreviewProjectFileItem>
            ) : null;
          })}
        </PreviewProjectFilesList>
      </PreviewProjectFilesWrapper>
      {selectedPortfolio?.videos?.length > 0 && (
        <PreviewVideosWrapper>
          <PreviewDescriptionTitle>Video Link</PreviewDescriptionTitle>
          <PreviewProjectUrlList>
            {selectedPortfolio?.videos.map((vid: any) => (
              <Fragment key={vid._id}>
                <PurpleEarth />
                <PreviewProjectUrl href={vid.videoLink} target="_blank">
                  {vid.videoName}
                </PreviewProjectUrl>
              </Fragment>
            ))}
          </PreviewProjectUrlList>
        </PreviewVideosWrapper>
      )}
      {selectedPortfolio?.projectUrl && (
        <PreviewProjectUrlWrapper>
          <PreviewDescriptionTitle>Project URL</PreviewDescriptionTitle>
          <PreviewProjectUrlList>
            <PurpleEarth />
            <PreviewProjectUrl
              href={selectedPortfolio?.projectUrl}
              target="_blank"
            >
              {selectedPortfolio?.projectUrl}
            </PreviewProjectUrl>
          </PreviewProjectUrlList>
        </PreviewProjectUrlWrapper>
      )}
      <PreviewConfirmDeleteModal
        visible={openDelete}
        footer={null}
        width={468}
        closable={false}
        onCancel={() => setOpenDelete(false)}
      >
        <PreviewConfirmDeleteModalTitle>
          Are you sure to delete this portfolio?
        </PreviewConfirmDeleteModalTitle>
        <PreviewConfirmDeleteModalActionWrapper>
          <PreviewChangePortfolio onClick={handleClickDeletePortfolio}>
            OK
          </PreviewChangePortfolio>
          <PreviewConfirmDeleteModalActionBtn
            onClick={() => setOpenDelete(false)}
          >
            Cancel
          </PreviewConfirmDeleteModalActionBtn>
        </PreviewConfirmDeleteModalActionWrapper>
      </PreviewConfirmDeleteModal>
    </PreviewWrapper>
  );
};

export default PortfolioPreview;
