import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { getProfileAPI, getProfileInfoAPI, IProfile } from "api/users";
import type { AxiosError } from "axios";
import { useAppSelector } from "redux/hooks";

export const useUserInfo = (profileId?: string | null) => {
  const userInfo = useAppSelector((state) => state.auth.user);
  const userId = userInfo?._id;
  const { data: profileRes } = useQuery(
    [!!profileId ? `getProfileOther-${profileId}` : "getProfile"], // will update key based on project id if have bug
    () => (profileId ? getProfileAPI(profileId) : getProfileInfoAPI()),
    {
      onError: (err: AxiosError) => {
        message.error(err?.message);
      },
      refetchOnWindowFocus: false,
      enabled: !!userId,
    }
  );

  const profile: IProfile | undefined = profileRes?.responseData;

  return profile;
};
