import { Col, Row } from "antd";
import styled from "styled-components";
import { EMAIL_OCTOPUS_ID } from "utils/constant";

export const FooterContainer = styled.div`
  margin-top: 60px;
  background: #080a25;
  padding: 150px 0 32px 0;

  & > span,
  & > p,
  & > div {
    font-family: "DM Sans" !important;
  }
`;

export const FooterContent = styled(Row)`
  max-width: 73.125rem;
  margin: auto auto;
  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

export const LineDivider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
`;

export const LogoContainer = styled(Col)`
  display: flex;
  flex-direction: column;

  svg {
    width: 177px;
    height: 75px;
  }

  ${LineDivider} {
    max-width: 235px;
    margin: 19px 0 24px 0;
  }
`;

export const PageListContainer = styled(Col)``;

export const PageList = styled.ul`
  list-style-type: none;
`;

export const PageListItem = styled.li`
  margin-bottom: 1.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 156%;
  letter-spacing: -0.03em;
  color: #c3c3c3;
  cursor: pointer;
  a {
    color: inherit;
  }
`;

export const TopLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 100%;
  color: #ffffff;
`;

export const SearchEmailContainer = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const FooterBottom = styled.div`
  color: #c3c3c3;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 2.5rem;
  margin-top: 1.75rem;
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.1);
`;

export const CopyRightText = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 156%;
  letter-spacing: -0.03em;
  color: rgba(195, 195, 195, 0.75);

  strong {
    color: #fff;
  }
`;

export const TextGradient = styled.span`
  background: linear-gradient(74.04deg, #00de8c 56.51%, #fff 68.43%),
    linear-gradient(0deg, #fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ProductPresent = styled.div`
  max-width: 243px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  color: #fff;
  a {
    color: inherit;
  }
`;

export const NewsletterFormContainer = styled.div`
  [data-form="${EMAIL_OCTOPUS_ID}"] .modal-container-inner,
  [data-form="${EMAIL_OCTOPUS_ID}"].inline-container {
    background-color: transparent !important;
    border: none;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"].inline-container {
    padding: 0;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"] .text-block {
    display: none;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"] .main-form {
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid #fff;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"]
    .emailoctopus-form
    input:not([type="submit"]),
  [data-form="${EMAIL_OCTOPUS_ID}"] .emailoctopus-form input[type="submit"] {
    background-color: transparent;
    font-size: 18px;
    line-height: 156%;
    border: none;
    padding-block: 20px;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"]
    .emailoctopus-form
    input:not([type="submit"]) {
    border-right: 1px solid #fff;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"] .mb-2,
  [data-form="${EMAIL_OCTOPUS_ID}"] .my-2 {
    margin-bottom: 0 !important;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"] .emailoctopus-form input {
    font-family: "DM Sans";
  }

  [data-form="${EMAIL_OCTOPUS_ID}"] .form-control {
    height: auto;
    color: #c3c3c3;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"] .form-control::placeholder {
    color: #c3c3c3;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"] .form-control:focus {
    box-shadow: none;
    color: #c3c3c3;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"] .btn-primary.focus,
  [data-form="${EMAIL_OCTOPUS_ID}"] .btn-primary:focus {
    box-shadow: none;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"]
    .btn-primary:not(:disabled):not(.disabled):active {
    background-color: transparent;
    border-color: transparent;
  }

  [data-form="${EMAIL_OCTOPUS_ID}"] .emailoctopus-error-message,
  [data-form="${EMAIL_OCTOPUS_ID}"] .emailoctopus-success-message {
    color: #c3c3c3 !important;
  }
`;
