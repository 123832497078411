import { useQuery } from "@tanstack/react-query";
import { Avatar, Form, message, UploadProps } from "antd";
import {
  getDataMastersAPI,
  getOrganizationsAPI,
  IOrganization,
} from "api/users";
import { BackIcon, NextIcon } from "assets/icons/navArrow.icon";
import { SelectArrowDown } from "assets/icons/common/selectArrowDown.icon";
import { DeleteSponsorTagIcon } from "assets/icons/delete.icon";
import { UploadCloudIcon } from "assets/icons/upload.icon";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import { useEffect, useMemo, useRef, useState } from "react";
import type { FC } from "react";
import { useAppSelector } from "redux/hooks";
import { acceptImage, validateImage } from "utils/regex";
import {
  requiredRules,
  maxLength_60,
  numberRules,
  floatRules,
  stringRequired,
} from "utils/validatorRules";
import { ProjectStepOneProps } from "../createProject.props";
import {
  BigLabel,
  CoverImage,
  LabelDescription,
  SocialFormGrid,
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
  StyledOption,
  StyledSelect,
  StyledTextArea,
  StyledUpload,
  ProjectTitle,
  TitleWrapper,
  StepIndication,
} from "../createProject.style";
import {
  StepOneWrapper,
  ProjectDescription,
  ProjectThumbWrapper,
  OrganizationSection,
  OrganizationSelect,
  SelectWrapper,
  SelectedSponsorTag,
  CreateOrgWrapper,
  RegisterDesc,
  OrgCreateBtn,
  TypeSection,
  LabelText,
  TypeHint,
  OrgTypeGradient,
  OrgInner,
  OrgTypeWrapper,
  TypeDescription,
  ButtonWrapper,
  GrayBtn,
  MainActionBtn,
  TokenSelectText,
  UploadWrapper,
  AllFormFieldWrapper,
  ButtonFormItem,
  ExtraSection,
  CommonLabel,
  SelectedOrgWrapper,
  CreateTokenModal,
  TokenHighlightText,
} from "./projectStepOne.style";
import { useNavigate } from "react-router-dom";
import {
  BlockchainExplorers,
  BlockchainNetworks,
  ROUTER_CONSTANTS,
  SupportedChains,
} from "utils/constant";
import { isAddress } from "ethers/lib/utils";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import { CreateProjectArrowRightIcon } from "assets/icons/arrowRight.icon";
import type { SelectProps } from "antd";
import type { RuleObject } from "antd/lib/form";
import { getBase64 } from "utils/getBase64";
import { useEthers } from "@usedapp/core";
import CreateToken from "pages/CreateCourse/CourseOverview/CreateToken";
import {
  useCheckWalletAndSwitchNetwork,
  useHandleTokenContractChanged,
} from "hooks";
import { handleMessageErrorSC } from "web3/contract";

const ProjectStepOne: FC<ProjectStepOneProps> = ({
  form,
  handleSubmit,
  loading,
}) => {
  const [categoriesSelect, setCategoriesSelect] = useState([] as any);
  // const [isTokenExist, setIsTokenExist] = useState<number>(1);
  const isTokenExist = Form.useWatch("isExistingToken", form);
  const [blockchainNetworksSelect, setBlockchainNetworksSelect] = useState(
    [] as any
  );
  const [listOrgSelected, setListOrgSelected] = useState<
    Pick<IOrganization, "_id" | "name">[]
  >([]);
  const [orgType, setOrgType] = useState<any>();
  const [fileNameCover, setFileNameCover] = useState("");

  const spinCoverRef = useRef<any>(false);
  // const fileRef = useRef<any>(null);
  // const spinAvatarRef = useRef<any>(false);

  const userInfo = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { chainId: currentChain } = useEthers();
  const [visibleToken, setVisibleToken] = useState(false);
  // const [revalidateTokenAddr, setRevalidateTokenAddr] = useState(false);
  const [newChainIdToRevalidate, setNewChainIdToRevalidate] = useState(-1);
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();
  const handleTokenContractChanged = useHandleTokenContractChanged(form);
  // const lastWalletRef = useRef<number>();

  // const debouncedSearchTerm = useDebounce(
  //   form.getFieldValue("tokenName"),
  //   1000
  // );

  // const { data: orgRes } = useQuery(
  //   ["getOrganizationData"],
  //   () => getOrganizationInfoAPI(userInfo?.organization || ""),
  //   {
  //     onSuccess: (data) => {
  //       form.setFieldValue("currentOrganization", data?.responseData?.name);
  //     },
  //     onError: (err: AxiosError) => {
  //       const rs: any = err?.response?.data;
  //       message.error(rs?.message);
  //     },
  //     enabled: !!userInfo?.organization,
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const { data: listOrgResponse } = useQuery(
    ["getOrganizations"],
    () => getOrganizationsAPI(),
    {
      onSuccess: (res) => {
        const { responseData, responseCode } = res;
        if (responseCode === 200) {
          form.setFieldValue(
            "currentOrganization",
            responseData?.find(
              (org: any) => org?._id === userInfo?.organization
            )?.name
          );
          // setListOrg(responseData);
        }
      },
      enabled: userInfo?._id !== undefined,
      refetchOnWindowFocus: false,
    }
  );
  useQuery(
    ["getDataCategory"],
    () => getDataMastersAPI({ type: "Project Categories" }),
    {
      onSuccess: (res) => {
        const { responseData, responseCode } = res;
        if (responseCode === 200) {
          const values = (responseData?.values ?? []).filter((_value) => {
            return ["Open Bounties", "Seed"].includes(_value.name);
          });
          setCategoriesSelect(values);
          const currProjType = form.getFieldValue("category");
          const currSelectedOrg = responseData?.values?.find(
            (org: any) => org?._id === currProjType
          );

          setOrgType(currSelectedOrg || responseData?.values?.[0]);
          form.setFieldValue(
            "category",
            currProjType || responseData?.values?.[0]?._id
          );
        }
      },
      refetchOnWindowFocus: false,
    }
  );
  useQuery(
    ["getDataNetworks"],
    () => getDataMastersAPI({ type: "Blockchain Networks" }),
    {
      onSuccess: (res) => {
        const { responseData, responseCode } = res;
        if (responseCode === 200) {
          const networks = responseData?.values;
          if (networks && networks.length > 0) {
            setBlockchainNetworksSelect(networks);
          }
          return;
        }
        // console.log("getDataMasters", res);
      },
      refetchOnWindowFocus: false,
    }
  );
  // const { mutate: postUploads } = useMutation(postUploadsAPI, {
  //   onSuccess: async (rsUpload: any) => {
  //     dispatch(setPageLoading(true));
  //     await axios
  //       .put(rsUpload.signedRequest, fileRef.current)
  //       .then((rs: any) => {
  //         if (spinAvatarRef.current) {
  //           // setFileNameAvatar(rsUpload.url);
  //           form.setFieldValue("profilePicture", rsUpload.url);
  //           spinAvatarRef.current = false;
  //           dispatch(setPageLoading(false));
  //         } else if (spinCoverRef.current) {
  //           // setFileNameCover(rsUpload.url);
  //           form.setFieldValue("coverImage", rsUpload.url);
  //           spinCoverRef.current = false;
  //           dispatch(setPageLoading(false));
  //         }
  //       })
  //       .catch((err: AxiosError) => {
  //         message.error("Error uploading: " + err.message);
  //       });
  //   },
  //   onError: (err: AxiosError) => {
  //     message.error(err.message);
  //   },
  // });

  // const fileNameCover = form.getFieldValue("coverImage");
  const listOrg: IOrganization[] | undefined = useMemo(() => {
    return listOrgResponse?.responseData;
  }, [listOrgResponse?.responseData]);
  const filteredOrg = listOrg?.filter(
    (org: any) => org?._id !== userInfo?.organization
  );
  const currentOrg = listOrg?.find(
    (org: any) => org?._id === userInfo?.organization
  );
  const orgOptions: SelectProps["options"] = filteredOrg?.map((org) => {
    return {
      label: org?.name,
      value: org?._id,
    };
  });

  // const handleSelectSponsors = (value: any) => {
  //   const parsedValue = JSON.parse(value);
  //   const selected = [...listOrgSelected, parsedValue];

  //   setListOrgSelected(selected);
  //   setListOrg((prevOrg: any) => {
  //     return prevOrg.filter((org: any) => org?._id !== parsedValue?._id);
  //   });

  //   form.setFieldValue("organizations", selected);
  // };

  const handleDeleteSponsorTag = (
    selectedOrg: Pick<IOrganization, "_id" | "name">
  ) => {
    const newSelectedOrg = listOrgSelected.filter(
      (org) => org?._id !== selectedOrg?._id
    );

    // setListOrg((prev: any) => [...prev, selectedOrg]);

    setListOrgSelected(newSelectedOrg);
    form.setFieldValue(
      "organizations",
      newSelectedOrg?.map((org) => ({ value: org?._id, label: org?.name }))
    );
  };

  const handleChangeNetwork = async (value: any) => {
    const selectedNetwork = blockchainNetworksSelect.find(
      (nw: any) => nw?._id === value
    );

    if (!SupportedChains.includes(selectedNetwork?.name)) {
      // form.setFieldValue("tokenExplorer", undefined);
      form.setFields([
        {
          name: "tokenExplorer",
          value: undefined,
          errors: ["Token explorer not found"],
        },
      ]);
      return;
    }
    const newChainId = BlockchainNetworks[selectedNetwork?.name] as number;
    const selectedExplorer = BlockchainExplorers[selectedNetwork?.name];

    if (selectedExplorer) {
      // form.setFieldValue("tokenExplorer", selectedExplorer);
      form.setFields([
        {
          name: "tokenExplorer",
          value: selectedExplorer,
          errors: [],
        },
      ]);
    }

    if (!checkWallet()) return;
    try {
      await handleSwitchNetwork(newChainId);

      if (currentChain !== newChainId) {
        // setRevalidateTokenAddr(true);
        setNewChainIdToRevalidate(newChainId);
      }
    } catch (err: any) {
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  // const handleSelectCategory = (value: number | unknown) => {
  //   form.setFieldValue("category", value);
  //   form.validateFields(["category"]);
  // };

  // const handleSelectIsToken = (value: number | unknown) => {
  //   form.setFieldValue("isExistingToken", value === 1);
  //   form.validateFields(["isExistingToken"]);
  // };

  // const handleSelectBlockchainNetwork = (value: number | unknown) => {
  //   form.setFieldValue("blockchainNetwork", value);
  //   form.validateFields(["blockchainNetwork"]);
  //   // form.setFieldValue("tokenName", "fixed data");
  // };

  const upLoadCoverImage: UploadProps = {
    accept: acceptImage,
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isImage = validateImage.test(file.name);
      if (!isImage) {
        message.error("You can only upload Image!");
        return false;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        // const fileTag = file.name.split(".").pop();
        spinCoverRef.current = true;
        // fileRef.current = file;
        form.setFieldValue("coverImage", file);
        setFileNameCover(await getBase64(file));
        // postUploads({
        //   fileName: file.name,
        //   fileType: `image/${fileTag}`,
        // });

        return false;
      }
    },
  };

  const getCategoryContent = (selectedName?: string) => {
    switch (selectedName) {
      case "Seed":
        return (
          <TypeDescription>
            SEED projects are ideal for early stage Web3 communities that are
            seeking flexible teams of builders. SEED projects are offering high
            rewards but in illiquid forms of ownership such as governance
            tokens. Our collaborators act as co-founders to kickstart a
            decentralized community.
          </TypeDescription>
        );

      case "Open Bounties":
        return (
          <TypeDescription>
            Open Bounties are supporting builders to work individually or
            collaboratively to deliver value in Web3 communities. Rewards are
            paid either in stablecoins or liquid tokens.
          </TypeDescription>
        );

      default:
        return null;
    }
  };

  const validateNameAndSymbol: (
    _: RuleObject,
    value: string
  ) => Promise<void> = async (_, value) => {
    if (!value || value?.trim()?.length === 0) return Promise.resolve();

    if (isAddress(value)) {
      return Promise.resolve();
    }

    return Promise.reject("Not a valid token contract");
  };

  // const handleTokenContractChanged = useCallback(
  //   async (eventOrCurTokenContract: ChangeEvent<HTMLInputElement> | string) => {
  //     const curVal =
  //       typeof eventOrCurTokenContract === "string"
  //         ? eventOrCurTokenContract
  //         : eventOrCurTokenContract.target.value;

  //     if (isAddress(curVal)) {
  //       try {
  //         const { name, symbol } = await getERC20NameAndSymbol(curVal);

  //         if (name) {
  //           form.setFields([
  //             {
  //               name: "tokenName",
  //               value: name,
  //               errors: [],
  //             },
  //             {
  //               name: "tokenSymbol",
  //               value: symbol,
  //               errors: [],
  //             },
  //           ]);
  //           // form.setFieldValue("tokenName", res);
  //         } else {
  //           form.setFields([
  //             {
  //               name: "tokenName",
  //               value: TOKEN_NAME_NOT_FOUND,
  //               errors: [TOKEN_NAME_NOT_FOUND],
  //             },
  //             {
  //               name: "tokenSymbol",
  //               value: TOKEN_SYMBOL_NOT_FOUND,
  //               errors: [TOKEN_SYMBOL_NOT_FOUND],
  //             },
  //           ]);
  //         }
  //       } catch (error: any) {
  //         // console.log(error?.message);

  //         if (error?.message?.includes("account")) {
  //           message.error("Please connect your wallet");
  //           return;
  //         }

  //         form.setFields([
  //           {
  //             name: "tokenName",
  //             value: TOKEN_NAME_NOT_FOUND,
  //             errors: [TOKEN_NAME_NOT_FOUND],
  //           },
  //           {
  //             name: "tokenSymbol",
  //             value: TOKEN_SYMBOL_NOT_FOUND,
  //             errors: [TOKEN_SYMBOL_NOT_FOUND],
  //           },
  //         ]);
  //       }
  //     }
  //   },
  //   [form]
  // );

  useEffect(() => {
    const curSelectedOrgs: SelectProps["options"] =
      form.getFieldValue("organizations");

    if (curSelectedOrgs && curSelectedOrgs.length > 0) {
      setListOrgSelected(
        curSelectedOrgs.map((org) => {
          return {
            _id: org?.value as string,
            name: org?.label as string,
          };
        })
      );
    }

    (async () => {
      const curImg = form.getFieldValue("coverImage");

      if (curImg) {
        setFileNameCover(await getBase64(curImg));
      }
    })();
    // const currExistingToken = form.getFieldValue("isExistingToken");

    // if (currExistingToken !== undefined) {
    //   setIsTokenExist(currExistingToken);
    // }
  }, [form]);

  // revalidate "tokenContract" field if user change network from metamask
  useEffect(() => {
    (async () => {
      if (
        newChainIdToRevalidate !== -1 &&
        currentChain === newChainIdToRevalidate
      ) {
        try {
          // setRevalidateTokenAddr(false);
          setNewChainIdToRevalidate(-1);
          await form.validateFields(["tokenContract"]);
          await handleTokenContractChanged(form.getFieldValue("tokenContract"));
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [
    currentChain,
    form,
    handleTokenContractChanged,
    newChainIdToRevalidate,
    // revalidateTokenAddr,
  ]);

  // useEffect(
  //   () => {
  //     if (debouncedSearchTerm) {
  //       form.setFieldValue("tokenName", debouncedSearchTerm);
  //     } else {
  //     }
  //   },
  //   [debouncedSearchTerm, form] // Only call effect if debounced search term changes
  // );

  const listOrgWithAvatar = listOrgSelected?.map((item) => {
    const foundMappingWithCurrSelected = filteredOrg?.find(
      (filterItem) => filterItem?._id === item?._id
    );

    if (!!foundMappingWithCurrSelected) {
      return {
        ...item,
        avatar: foundMappingWithCurrSelected?.avatar,
      };
    }

    return item;
  });

  const handleCloseCustomTokenModal = () => {
    setVisibleToken(false);
  };

  const handleFormValuesChanged = (changed: any) => {
    if ("organizations" in changed) {
      setListOrgSelected(
        (changed?.organizations as NonNullable<SelectProps["options"]>).map(
          (org) => {
            return {
              _id: org?.value as string,
              name: org?.label as string,
            };
          }
        )
      );
    }
  };

  const handleFinishForm = async (values: any) => {
    const selectedNetwork = blockchainNetworksSelect.find(
      (nw: any) => nw?._id === values.blockchainNetwork
    );
    const tokenExplorer = values.tokenExplorer as string;
    if (!tokenExplorer || !selectedNetwork) {
      message.error("Please create token first");
      setVisibleToken(true);
      return;
    }

    values.blockchainNetwork = selectedNetwork?._id;
    values.selectedChainId = BlockchainNetworks[selectedNetwork?.name];

    if (tokenExplorer[tokenExplorer.length - 1] !== "/") {
      values.tokenExplorer += "/";
    }

    if (form.getFieldError("tokenName").length > 0) return;
    if (form.getFieldError("tokenSymbol").length > 0) return;
    if (form.getFieldError("tokenExplorer").length > 0) return;

    // const finalOrgs =
    //   values?.organizations?.length > 0
    //     ? values?.organizations?.map((org: any) => JSON.parse(org))
    //     : [];

    delete values.currentOrganization;

    const newOrgs = values?.organizations || [];

    handleSubmit({
      ...values,
      organizations: [
        ...(newOrgs as NonNullable<SelectProps["options"]>)?.map(
          (org) => org?.value
        ),
        currentOrg?._id,
      ],
    });
  };

  return (
    <StepOneWrapper>
      <TitleWrapper space="10px">
        <ProjectTitle>Project Description</ProjectTitle>
        <StepIndication>Step 1</StepIndication>
      </TitleWrapper>

      <ProjectDescription>
        BUILD projects are fast-forward, collaborative, and community-driven
        initiatives delivered by collaborators - indiependent software
        developers, designers, marketing experts, product and project managers,
        and community-gigs self-organised but distributed from all over the
        world.
        <br />
        <br />A project is a collection of WorkTasks that help your organisation
        to achieve an initiative.
      </ProjectDescription>

      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleFormValuesChanged}
        onFinish={handleFinishForm}
        scrollToFirstError={true}
        initialValues={{
          isExistingToken: 1,
        }}
      >
        <AllFormFieldWrapper>
          <StyledFormItem
            label="Your Project Name"
            name="projectName"
            rules={[stringRequired, maxLength_60]}
          >
            <StyledInput placeholder="What should we put at the top of your page?" />
          </StyledFormItem>
          <StyledFormItem
            label="Project description"
            name="tagline"
            rules={[stringRequired]}
          >
            <StyledTextArea placeholder="Tell us what your project is. Keep it short and sweet!" />
          </StyledFormItem>
          <StyledFormItem
          // name="coverImage"
          >
            <ProjectThumbWrapper space="16px">
              <StyledUpload
                {...upLoadCoverImage}
                listType="picture-card"
                maxCount={1}
                className={
                  fileNameCover ? "upload-image noBorder" : "upload-image"
                }
              >
                {fileNameCover ? (
                  <CoverImage src={fileNameCover} />
                ) : (
                  <UploadWrapper>
                    <UploadCloudIcon />
                  </UploadWrapper>
                )}
              </StyledUpload>
              <div>
                <BigLabel>Project thumbnail</BigLabel>
                <LabelDescription>
                  Recommended size: 376x128 .jpg .png | Max size: 5MB
                </LabelDescription>
              </div>
            </ProjectThumbWrapper>
          </StyledFormItem>

          <OrganizationSection>
            <SelectWrapper>
              <StyledFormItem
                label="Sponsoring Organization"
                name="organizations"
              >
                <OrganizationSelect
                  mode="multiple"
                  // onChange={handleSelectSponsors}
                  suffixIcon={<SelectArrowDown />}
                  placeholder="Please select a sponsoring organisation from the list"
                  options={orgOptions}
                  labelInValue
                />
                {/* {filteredOrg?.map((org: any) => (
                    <OrganizationSelect.Option
                      key={org?._id}
                      value={JSON.stringify(org)}
                    >
                      {org?.name}
                    </OrganizationSelect.Option>
                  ))}
                </OrganizationSelect> */}
              </StyledFormItem>
              {listOrgWithAvatar?.length > 0 && (
                <SelectedOrgWrapper space="8px">
                  {listOrgWithAvatar.map((selectedOrg) => {
                    return (
                      <SelectedSponsorTag
                        space="8px"
                        onClick={() => handleDeleteSponsorTag(selectedOrg)}
                        key={selectedOrg?._id}
                      >
                        {"avatar" in selectedOrg && (
                          <Avatar src={selectedOrg?.avatar} size={24} />
                        )}{" "}
                        {selectedOrg?.name} <DeleteSponsorTagIcon />
                      </SelectedSponsorTag>
                    );
                  })}
                </SelectedOrgWrapper>
              )}
            </SelectWrapper>
            <CreateOrgWrapper space="10px">
              {currentOrg?._id ? (
                <>
                  <RegisterDesc>Your organization:</RegisterDesc>
                  <StyledFormItem
                    style={{
                      width: "100%",
                      marginBottom: "0",
                    }}
                    name="currentOrganization"
                  >
                    <StyledInput disabled />
                  </StyledFormItem>
                </>
              ) : (
                <>
                  <RegisterDesc>
                    If you haven't registered an organisation, please create one
                    here.
                  </RegisterDesc>
                  <OrgCreateBtn
                    onClick={() =>
                      navigate(ROUTER_CONSTANTS.ORGANIZATION.CREATE)
                    }
                  >
                    Create New Organisation +
                  </OrgCreateBtn>
                </>
              )}
            </CreateOrgWrapper>
          </OrganizationSection>

          <TypeSection>
            <LabelText>Type</LabelText>
            <TypeHint>
              Selecting the right project type will help users discover your
              bounty
            </TypeHint>
            <OrgTypeWrapper space="15px">
              {categoriesSelect.map((category: any) => (
                <OrgTypeGradient
                  key={category?._id}
                  $active={category?._id === orgType?._id}
                  onClick={() => {
                    setOrgType(category);
                    form.setFieldValue("category", category?._id);
                  }}
                >
                  <OrgInner>{category?.name}</OrgInner>
                </OrgTypeGradient>
              ))}
            </OrgTypeWrapper>
            {/* {orgType?.name === "Seed" ? (
              <TypeDescription>
                SEED projects are ideal for early stage Web3 communities that
                are seeking flexible teams of builders. SEED projects are
                offering high rewards but in illiquid forms of ownership such as
                governance tokens. Our collaborators act as co-founders to
                kickstart a decentralized community.
              </TypeDescription> ? (
                orgType?.name === "Open Bounties"
              ) : (
                <TypeDescription>
                  Open Bounties are supporting builders to work individually or
                  collaboratively to deliver value in Web3 communities. Rewards
                  are paid either in stablecoins or liquid tokens.
                </TypeDescription>
              )
            ) : null} */}
            {getCategoryContent(orgType?.name)}
          </TypeSection>
          <SocialFormGrid>
            <StyledFormItem
              label="Total Project's Budget"
              name="totalBudget"
              rules={[requiredRules, numberRules]}
            >
              <StyledInputNumber
                type="number"
                placeholder="Input the total budget for your BUILD campaign"
              />
            </StyledFormItem>

            <StyledFormItem label="Token">
              {/* <StyledFormItem label="Sponsors" name="organizations">
                <StyledSelect
                  mode="multiple"
                  onChange={handleSelectSponsors}
                  suffixIcon={<SelectArrowDown />}
                  placeholder="Project Sponsors"
                >
                  {listOrg.map((org: any) => (
                    <StyledOption value={org?._id}>{org?.name}</StyledOption>
                  ))}
                </StyledSelect>
              </StyledFormItem> */}
              <StyledFormItem
                noStyle
                name="isExistingToken"
                rules={[requiredRules]}
              >
                <StyledSelect
                  suffixIcon={<SelectArrowDown />}
                  placeholder="Is there any existing token?"
                  // onChange={(value) => {
                  //   setIsTokenExist(typeof value === "number" ? value : 0);
                  //   // handleSelectIsToken(value);
                  // }}
                >
                  <StyledOption value={1}>Yes</StyledOption>
                  <StyledOption value={2}>No</StyledOption>
                </StyledSelect>
              </StyledFormItem>
              {isTokenExist === 2 && (
                <TokenSelectText onClick={() => setVisibleToken(true)}>
                  If your organisation hasn't issued a token yet that is
                  currently trading on decentralized or centralized markets,
                  please submit this form to receive support from{" "}
                  <TokenHighlightText>RBKD DAO</TokenHighlightText>.
                </TokenSelectText>
              )}
            </StyledFormItem>
            {isTokenExist === 1 && (
              <>
                <StyledFormItem label="Name of the currency" name="tokenName">
                  <StyledInput
                    placeholder={
                      form.getFieldValue("tokenName") || "Token Name"
                    }
                    disabled
                  />
                </StyledFormItem>

                {/* {orgType?.name === "Seed" && ( */}
                <StyledFormItem
                  label="Token Contract"
                  name="tokenContract"
                  rules={[
                    requiredRules,
                    () => {
                      return {
                        validator: validateNameAndSymbol,
                      };
                    },
                  ]}
                >
                  <StyledInput
                    onChange={handleTokenContractChanged}
                    placeholder="Input the token contract"
                  />
                </StyledFormItem>
                {/* )} */}

                <StyledFormItem
                  label="Token Explorer"
                  name="tokenExplorer"
                  // dependencies={["blockchainNetwork"]}
                  // rules={[
                  //   requiredRules,
                  //   {
                  //     type: "url",
                  //     message: "Not a valid url!",
                  //   },
                  //   ({ getFieldValue }) => ({
                  //     validator: () => {
                  //       const curNetwork = getFieldValue("blockchainNetwork");

                  //       console.log("in");

                  //       if (!SupportedChains.includes(curNetwork)) {
                  //         return Promise.reject("Token explorer not found");
                  //       }

                  //       return Promise.resolve();
                  //     },
                  //   }),
                  // ]}
                >
                  <StyledInput disabled placeholder="Token Explorer" />
                </StyledFormItem>

                <StyledFormItem
                  label="Blockchain Network"
                  name="blockchainNetwork"
                  rules={[
                    requiredRules,
                    () => ({
                      validator: (_, value?: string) => {
                        if (!value) return Promise.resolve();

                        const selectedNetwork = blockchainNetworksSelect.find(
                          (nw: any) => nw?._id === value
                        );

                        if (!SupportedChains.includes(selectedNetwork?.name)) {
                          return Promise.reject(
                            "Selected network is not yet supported!"
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <StyledSelect
                    placeholder="Blockchain Network"
                    onChange={handleChangeNetwork}
                  >
                    {blockchainNetworksSelect.map((network: any) => (
                      <StyledOption key={network?._id} value={network?.id}>
                        {network?.name}
                      </StyledOption>
                    ))}
                  </StyledSelect>
                </StyledFormItem>

                <StyledFormItem label="Token symbol" name="tokenSymbol">
                  <StyledInput
                    disabled
                    placeholder={
                      form.getFieldValue("tokenSymbol") || "Token Symbol"
                    }
                  />
                </StyledFormItem>

                {/* {orgType?.name === "Open Bounties" && (
                  <>
                    <StyledFormItem
                      label="Token Contract"
                      name="tokenContract"
                      rules={[
                        requiredRules,
                        () => {
                          return {
                            validator: validateNameAndSymbol,
                          };
                        },
                      ]}
                    >
                      <StyledInput placeholder="Input the token contract" />
                    </StyledFormItem>
                  </>
                )} */}
                <StyledFormItem label="Peg to USD">
                  <ExtraSection space="14px">
                    <CommonFlexColumn space="10px">
                      <CommonLabel>Amount of Tokens</CommonLabel>
                      <StyledInput disabled value={1} />
                    </CommonFlexColumn>
                    <CreateProjectArrowRightIcon />
                    <CommonFlexColumn space="10px">
                      <CommonLabel>Expected USD Value</CommonLabel>
                      <StyledFormItem
                        name="expectedUSD"
                        noStyle
                        rules={[floatRules, requiredRules]}
                      >
                        <StyledInputNumber type="number" />
                      </StyledFormItem>
                    </CommonFlexColumn>
                  </ExtraSection>
                </StyledFormItem>
              </>
            )}
          </SocialFormGrid>
        </AllFormFieldWrapper>
        <ButtonWrapper>
          <GrayBtn
            onClick={() => {
              navigate(-1);
            }}
            disabled={loading}
          >
            <BackIcon />
            Back
          </GrayBtn>
          <CommonFlexRow space="24px">
            <GrayBtn
              onClick={() => {
                navigate(-1);
              }}
              disabled={loading}
            >
              Cancel
            </GrayBtn>
            <ButtonFormItem>
              <MainActionBtn
                loading={loading}
                disabled={loading}
                htmlType="submit"
              >
                Next <NextIcon />
              </MainActionBtn>
            </ButtonFormItem>
          </CommonFlexRow>
        </ButtonWrapper>
      </Form>

      <CreateTokenModal
        visible={visibleToken}
        centered
        width={624}
        onCancel={handleCloseCustomTokenModal}
        destroyOnClose
        title="Create Token"
        closable
      >
        <CreateToken
          onClose={handleCloseCustomTokenModal}
          form={form}
          inModal={true}
          isCreateProject
        />
      </CreateTokenModal>
    </StepOneWrapper>
  );
};

export default ProjectStepOne;
