import { Avatar, Form, Input } from "antd";
import image from "components/base/image";
import PrimaryButton from "components/base/primaryButton";
import AvatarAntdGroup from "components/modules/avatarList";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div``;

export const CardContainer = styled.div`
  height: fit-content;
  background: #ffffff;
  margin: 2rem;
  padding: 1rem 1.5rem 0rem 1rem;
`;

export const CardItemAbove = styled.div`
  padding-bottom: 1rem;
  border-bottom: 0.0938rem solid #292e731f;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftCardItem = styled.div`
  display: flex;
  gap: 2.25rem;
  width: calc(100% - 120px);
`;

export const AvaAndSubItem = styled.div`
  display: flex;
  gap: 16px;
  width: calc(50% - 1.125rem);
  & > span {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sub-item {
    width: calc(100% - 16px - 48px);
  }
`;

export const SubItem = styled.div`
  height: 2.625rem;
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  width: calc(50% - 1.125rem);
`;
export const Label = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 125%;
  color: rgba(41, 46, 115, 0.42);
`;

export const ContentLabel = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(41, 46, 115, 0.82);
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
  & > span:nth-child(1) {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const LabelWrap = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(41, 46, 115, 0.82);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & > span:nth-child(1) {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Image = styled(image)`
  border-radius: 50%;
`;

export const AvatarAntd = styled(Avatar)``;

export const CardContent = styled.div`
  padding-top: 1rem;
  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 156%;
    color: rgba(41, 46, 115, 0.82);
    word-break: break-word;
  }
  padding-bottom: 1rem;
`;

export const CardItemAdminReply = styled.div`
  border-top: 0.0938rem solid #292e731f;
  padding-top: 2rem;
`;

export const CardItemUserReply = styled.div`
  padding-left: 20px;
  border-top: 0.0938rem solid #292e731f;
  padding-top: 2rem;
`;

export const FormReplyContainer = styled.div`
  display: flex;
  gap: 0.5625rem;
`;

export const ModalFormItem = styled(Form.Item)`
  margin: 0;
  width: 21.5625rem;
  height: 2.25rem;
`;

export const FormTextInput = styled(Input)`
  background: #ffffff;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  padding: 0.6875rem 0.75rem 0.6875rem 0.75rem;
  height: 2.25rem;
`;

export const ReplyButton = styled(PrimaryButton)`
  &:hover {
    color: #ede5e5;
  }
`;

export const Wrap = styled.div`
  max-height: 300px;
  overflow: auto;
  margin-bottom: 24px;

  &::-webkit-scrollbar-track {
    background-color: rgba(8, 15, 108, 0.05);
    height: 24.125rem;
  }
  &::-webkit-scrollbar {
    width: 0.4375rem;
    background-color: rgba(8, 15, 108, 0.08);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(8, 15, 108, 0.08);
    height: 2.5rem;
  }
`;

export const AvatarAntdGroupStyled = styled(AvatarAntdGroup)`
  cursor: pointer;
  .ant-avatar:hover {
    transform: none !important;
  }
`;

export const LinkStyled = styled(Link)`
  color: rgba(41, 46, 115, 0.82) !important;
`;
