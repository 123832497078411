import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { getFeedBackInfoAPI } from "api/users";
import { AxiosError } from "axios";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Content,
  StyledRate,
  RateGroup,
  RateTitle,
  StyledTextArea,
  ContentTitle,
  RateContainer,
  UserName,
  Description,
  LeftContainer,
  RightContainer,
  ContentHead,
  Score,
  ScoreText,
  ReviewTitle,
  HeadingWrapper,
  BackButton,
} from "./FeedbackInfo.style";

const FeedbackInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [feedbackInfo, setFeedbackInfo] = useState<any>({});

  // const {
  //   data: projectRes,
  //   isSuccess,
  //   isError,
  // } = useQuery(
  //   [`getProjectDetail-${params.projectId}`],
  //   () => getProjectDetails(params.projectId || ""),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useQuery(
    ["getFeedBackInfoAPI"],
    () =>
      getFeedBackInfoAPI({
        projectId: params.projectId || "",
        packageId: params.packageId || "",
      }),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          setFeedbackInfo(res?.responseData);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      // enabled: !!param.id,
      refetchOnWindowFocus: false,
    }
  );
  // useCheckProjectCreator(projectRes?.responseData, isSuccess, isError);

  // console.log(333, feedbackInfo);

  const feedbackType = {
    skills: {
      name: "Skills",
      values: feedbackInfo?.collaboratorReviews?.skills,
    },
    cooperation: {
      name: "Cooperation",
      values: feedbackInfo?.collaboratorReviews?.cooperation,
    },

    adherenceSchedule: {
      name: "Adherence to Schedule",
      values: feedbackInfo?.collaboratorReviews?.adherenceSchedule,
    },
    workQuality: {
      name: "Quality of Work",
      values: feedbackInfo?.collaboratorReviews?.workQuality,
    },

    communication: {
      name: "Communication",
      values: feedbackInfo?.collaboratorReviews?.communication,
    },
  };

  // const totalScore = 0;
  // Object.values(feedbackType).reduce((prev, curr) => prev + curr, 0) / 5;
  const data = (location?.state as any)?.data;

  return (
    <>
      <HeadingWrapper>
        <ReviewTitle>Feedback</ReviewTitle>
      </HeadingWrapper>
      <Container>
        <ContentTitle>
          Public Feedback for <UserName>{data?.fullName}</UserName>
        </ContentTitle>
        <Description>
          This feedback will be shared on your freelancer's profile only after
          they've left feedback for you.
        </Description>
        <Content>
          <LeftContainer>
            <RateContainer>
              <ContentHead>Feedback to the collaborator</ContentHead>
              {(Object.keys(feedbackType) as (keyof typeof feedbackType)[]).map(
                (key) => (
                  <RateGroup key={key}>
                    <RateTitle>{feedbackType[key].name}</RateTitle>
                    <StyledRate
                      value={feedbackType[key].values}
                      style={{ color: "#F57F20", fontSize: 28 }}
                      disabled={true}
                    />
                  </RateGroup>
                )
              )}
            </RateContainer>
            <BackButton
              onClick={() => navigate("/dashboard")}
              width="196px"
              height="48px"
            >
              Back
            </BackButton>
          </LeftContainer>
          <RightContainer>
            <ScoreText>Total Score: </ScoreText>{" "}
            <Score> {feedbackInfo?.totalScore / 5 || "00.00"}</Score>
            <Description>
              Share your experience with this collaborator the BUILD community
            </Description>
            <StyledTextArea
              placeholder="Share your experience with this collaborator the BUILD community"
              value={feedbackInfo?.collaboratorReviews?.description}
              disabled={true}
            />
          </RightContainer>
        </Content>
      </Container>
    </>
  );
};

export default FeedbackInfo;
