import CommonModal from "components/base/commonModal";
import { FC, memo } from "react";
import { IForgotPasswordProps } from "./forgotPassword.props";
import {
  InputEmail,
  ModalTitle,
  TextDecs,
  AskIcon,
  LabelInput,
  ModalFormItem,
  SubmitButton,
  BackToLogin,
} from "./forgotPassword.style";
import { ForgotPasswordIcon } from "assets/images/forgot-password/index";
import { requiredRules } from "utils/validatorRules";
import { Form, message } from "antd";
import { useMutation } from "@tanstack/react-query";
import { forgotPasswordAPI } from "api/auth";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword: FC<IForgotPasswordProps> = ({
  show,
  closeModal,
  ...props
}: IForgotPasswordProps) => {
  const navigate = useNavigate();

  const { mutate: forgotPassword } = useMutation(forgotPasswordAPI, {
    onSuccess: (res: any) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        closeModal();
        navigate("/");
      } else {
        message.error(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const onClickSubmit = (values: any) => {
    forgotPassword(values);
  };
  return (
    <CommonModal
      bodyStyle={{ height: 572 }}
      footer={null}
      visible={show}
      centered
      width={"462px"}
      closable={false}
    >
      <AskIcon>
        <ForgotPasswordIcon />
      </AskIcon>
      <ModalTitle>Forgot Password</ModalTitle>
      <TextDecs>
        Enter your email and we'll send you a link to reset your password
      </TextDecs>
      <LabelInput>Enter email</LabelInput>
      <Form name="Forgot Password" onFinish={onClickSubmit}>
        <ModalFormItem
          name="emailId"
          labelAlign="left"
          rules={[
            {
              type: "email",
              message: "Invalid email format (e.g. user@example.com)",
            },
            requiredRules,
          ]}
        >
          <InputEmail placeholder="Enter your email" />
        </ModalFormItem>
        {/* <ErrorEmail>We cannot find your email.</ErrorEmail> */}
        <SubmitButton height="40px" width="350px" htmlType="submit">
          Submit
        </SubmitButton>
      </Form>

      <BackToLogin onClick={() => closeModal()}>
        {"<"} Back to Login
      </BackToLogin>
    </CommonModal>
  );
};

export default memo(ForgotPassword);
