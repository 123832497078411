import { Col } from "antd";
import { Header } from "antd/lib/layout/layout";
import BaseButton from "components/base/baseButton";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled(Header)`
  background: linear-gradient(90deg, #7e00fd 0%, #c143fc 50.61%, #00bffb 100%);
  height: 4.5rem;
  width: 100%;
  padding-top: 0.25rem;
  .ant-row {
    max-width: 73.125rem;
    margin: auto;
  }
  & > * {
    font-family: "Rubik" !important;
  }

  @media only screen and (max-width: 1200px) {
    #large-item-size-desktop {
      display: none;
    }
  }
`;

const MenuItemStyled = css`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5625rem;
  color: #ffffff;
  position: relative;
  padding: 0.4375rem 1.1875rem;
  border-radius: 1.25rem;
  height: 2.5rem;
  display: inline-block;

  @media (max-width: 1200px) {
    margin-right: 1.5rem;
  }
  text-decoration: none;
  &:hover {
    color: #ede5e5;
  }
  &.active {
    background: rgba(255, 255, 255, 0.18);
    box-shadow: 0rem 0.125rem 0.75rem rgba(8, 10, 37, 0.05);
  }

  & > span {
    position: absolute;
    top: 0;
    left: calc(100% + 3px);
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #ff4d4f;
  }
`;

export const MenuItem = styled(Link)<{ active?: boolean }>`
  ${MenuItemStyled}
`;

export const ExternalMenuItem = styled.a`
  ${MenuItemStyled}
`;

export const LogoContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const LoginContainer = styled(Col)`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const ButtonLogin = styled(BaseButton)`
  margin-right: 1.25rem;
  border: none;
  background: transparent;
  color: white;
`;

export const ButtonSignup = styled(BaseButton)`
  font-weight: 600;
  font-size: 1rem;
`;

export const LoggedContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonLogout = styled.button`
  height: 1.875rem;
  width: auto;
`;

export const BellContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonBell = styled.button<{
  isRead: boolean;
}>`
  background: transparent;
  padding: 0;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  svg {
    height: 20px;
    width: 20px;
    color: white;
  }

  ${({ isRead }) =>
    !isRead
      ? `  ::after {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    background: red;
    border-radius: 50%;
    top: 8px;
    right: 10px;
  }`
      : ``}
`;
