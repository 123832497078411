import { Avatar } from "antd";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { getPluralText } from "utils/formatTimeCommitment";
import { nFormatter } from "utils/priceFormat";
import { ProjectCardItemProps } from "./projectCardItem.props";
import {
  ProjectCard,
  ProjectCardContent,
  ProjectImg,
  SponsorWrapper,
  ProjectSponsorText,
  ProjectName,
  ProjectWorktask,
  ProjectDesc,
  ProjectCardFooter,
  BuildBtn,
  BudgetWrapper,
  BudgetText,
  BudgetNumber,
} from "./projectCardItem.style";

const previewLimit = 3;

const ProjectCardItem: FC<ProjectCardItemProps> = ({ project, isLoading }) => {
  const navigate = useNavigate();
  const filteredOrg = project?.organizationDetails;
  const previewOrg =
    (filteredOrg?.length || 0) > previewLimit
      ? filteredOrg?.slice(0, previewLimit)
      : filteredOrg;
  const remainOrgToShowMore = filteredOrg?.slice(previewLimit);

  const getTextByProjectCategory = () => {
    switch (project?.category?.name) {
      case "Seed":
        switch (project?.adminVerification) {
          case "PENDING":
            return "Waiting for approval";
          case "ACCEPTED":
            return "BUILD with us!";
          default:
            return "DRAFT";
        }
      case "Open Bounties":
        switch (project?.adminVerification) {
          case "PENDING":
            return "Waiting for approval";
          case "ACCEPTED":
            return "Start Contributing";
          default:
            return "DRAFT";
        }

      default:
        return "";
    }
  };

  const handleClickCard = () => {
    if (isLoading) return;
    navigate(
      ROUTER_CONSTANTS.PROJECT.DETAIL.replace(":id", project?._id as string)
    );
  };

  return (
    <ProjectCard onClick={handleClickCard}>
      {isLoading ? (
        <Skeleton
          style={{
            aspectRatio: "2.9375",
            display: "flex",
          }}
          borderRadius="4px 4px 0 0"
        />
      ) : (
        <ProjectImg src={project?.coverImage} alt="project-thumb" />
      )}
      <ProjectCardContent>
        <SponsorWrapper space="10px">
          {isLoading ? (
            <Skeleton width="150px" />
          ) : (previewOrg?.length as number) > 0 ? (
            <>
              <ProjectSponsorText>Sponsored by</ProjectSponsorText>
              {previewOrg?.map((org) => {
                return (
                  <CommonFlexRow space="2px" key={org._id}>
                    <ProjectSponsorText>
                      <Avatar size={"small"} src={org.avatar} /> {org.name}
                    </ProjectSponsorText>
                  </CommonFlexRow>
                );
              })}
              {(remainOrgToShowMore?.length || 0) > 0 && (
                <ProjectSponsorText>
                  {getPluralText(
                    remainOrgToShowMore?.length as number,
                    "more organization"
                  )}
                </ProjectSponsorText>
              )}
            </>
          ) : (
            <ProjectSponsorText>No sponsor</ProjectSponsorText>
          )}
        </SponsorWrapper>
        <ProjectName>
          {isLoading ? <Skeleton width="60%" /> : project?.projectName}
        </ProjectName>
        <ProjectWorktask>
          {isLoading ? (
            <Skeleton width="40%" />
          ) : (project?.countPackages?.length || 0) > 0 ? (
            <>
              {getPluralText(
                project?.countPackages?.length as number,
                "worktask"
              )}{" "}
              included
            </>
          ) : (
            "No worktask"
          )}
        </ProjectWorktask>
        <ProjectDesc>
          {isLoading ? <Skeleton count={3} /> : project?.tagline}
        </ProjectDesc>
      </ProjectCardContent>
      <ProjectCardFooter space="10px">
        <BuildBtn
          style={
            project?.adminVerification === "PENDING"
              ? { background: "rgb(46, 182, 125)" }
              : {}
          }
        >
          {getTextByProjectCategory()}
        </BuildBtn>
        <BudgetWrapper>
          <BudgetText>Total Budget</BudgetText>
          <BudgetNumber>
            {isLoading ? (
              <Skeleton />
            ) : (
              <>
                {project?.totalBudget && project?.totalBudget > 10000
                  ? nFormatter(project?.totalBudget)
                  : project?.totalBudget}{" "}
                {project?.tokenSymbol?.slice(0, 5)}
              </>
            )}
          </BudgetNumber>
        </BudgetWrapper>
      </ProjectCardFooter>
    </ProjectCard>
  );
};

export default ProjectCardItem;
