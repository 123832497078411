import { Form, Input, Select, Modal } from "antd";
import PrimaryButton from "components/base/primaryButton";
import styled from "styled-components";

const { Item: FormItem } = Form;

export const RightSection = styled.div`
  flex-grow: 1;
`;

export const SavePreviewBtn = styled(PrimaryButton)`
  height: auto;
  padding: 12px 16px;
`;

export const SaveBtnWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
`;

export const WhiteBgWrapper = styled.div`
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid rgba(41, 46, 115, 0.12);
`;

export const GradientTopSection = styled.div`
  background: linear-gradient(
    90deg,
    rgba(126, 0, 253, 0.08) 0%,
    rgba(193, 67, 252, 0.08) 46.88%,
    rgba(0, 191, 251, 0.08) 100%
  );
  padding-block: 16px;
`;

export const Container = styled.div`
  // padding: 0px 50px;
  display: flex;

  & form > div {
    margin-left: 0;
  }

  // & > div {
  //   display: flex;
  //   margin: 0 auto;
  //   max-width: 73.125rem;
  //   gap: 24px;
  //   & form > div {
  //     margin-left: 0;
  //   }
  // }
`;

export const FormTab = styled.div`
  max-width: 800px;
  margin: 5px auto 100px auto;

  > .ant-form {
    display: flex;
    flex-direction: column;
    // gap: 48px;
  }
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0;

  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  .ant-form-item-label {
    padding-bottom: 0.35rem;
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  &.ant-input {
    min-height: 96px;
    resize: none;
    border: 0.0625rem solid rgba(41, 46, 115, 0.12);
    border-radius: 0.25rem;
    padding: 0.625rem;
    outline: none;
    font-size: 14px;
    line-height: 100%;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #d9d9d9;
  }

  &.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 34px;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    display: flex;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    // color: rgba(41, 46, 115, 0.42);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #f6f8fa;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: #f6f8fa;
  }

  .ant-select-item-option {
    padding-block: 14px;
  }

  .ant-select-item-option-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    // color: rgba(41, 46, 115, 0.42);
  }
`;

export const CommonGradientContainer = styled.div`
  width: 100%;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 4px;
  padding: 20px 24px;
  // background: rgba(156, 138, 193, 0.04);
  background: #fff;

  &:hover {
    background: linear-gradient(
      117deg,
      rgba(121, 0, 243, 0.08) 0%,
      rgba(191, 64, 255, 0.08) 60%,
      rgba(89, 140, 255, 0.08) 80%,
      rgba(13, 191, 255, 0.08) 100%
    );
    background-size: cover;
  }
`;

export const FormSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LeaveActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  > button:last-child {
    padding-block: 10px;
  }
`;

export const LeaveModal = styled(Modal)`
  h3,
  .ant-modal-body > span {
    color: #080f6c;
    text-align: center;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: -0.02em;
  }

  .ant-modal-body > span {
    display: block;
    color: rgba(41, 46, 115, 0.82);
    font-size: 18px;
    letter-spacing: 0;
    margin-bottom: 8px;
  }
`;
