import styled from "styled-components";
import headerBackground from "assets/dashboard/dashboard_header_bg.png";
import { Col, Row } from "antd";
import ContainerFluidCustom from "components/base/containerFluidCustom";

export const ContainerFluidHeader = styled.div`
  background-image: url(${headerBackground});
  height: 8rem;
  margin-bottom: 3.75rem;
  padding: 0rem 3.125rem;
`;

export const HeaderContainer = styled(Row)`
  max-width: 73.125rem;
  margin: auto;
  max-width: 73.125rem;
  margin: auto;
`;

export const DashboardNameContainer = styled(Col)`
  margin-left: 8.5625rem;
`;

export const HeaderText = styled.p`
  font: normal 700 2rem/115% "DM Sans";
  color: #080a25;
  cursor: pointer;
  padding: 3rem 0rem;
`;

export const ContainerTransactionBody = styled(ContainerFluidCustom)`
  padding: 32px 50px 0px;
  margin: 0;

  & > div {
    max-width: 73.125rem;
    margin: auto;
    max-height: 30.25rem; 
    padding-bottom:20px;
    border: 0.0625rem solid rgba(41, 46, 115, 0.12);
    background: rgba(156, 138, 193, 0.04);
    margin-bottom: 7.5rem;
  }
`;

export const ContainerTransaction = styled(ContainerFluidCustom)`
  margin: 0rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  max-height: 28.75rem;
  height:auto;
  overflow-x: auto;
  padding-top: 2.1875rem;

  & > div {
    padding: 0rem 2.1875rem;
  }

  & > div > div {
    width: 100%;
    padding-right: 1.375rem;
  }

  & > div:last-child > div {
    margin-bottom: 0rem;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(8, 15, 108, 0.05);
    height: 24.125rem;
  }

  &::-webkit-scrollbar {
    width: 0.4375rem;
    background-color: rgba(8, 15, 108, 0.08);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(8, 15, 108, 0.08);
    height: 14.9375rem;
  }
`;

export const SeeMore = styled.div`
  justify-content: center;
  margin-top: 0.9375rem;
  margin-bottom: 1.5625rem;
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: #7e00fd;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 115%;
  font-style: "Medium";
  font-family: "Rubik";
`;
