import { Avatar } from "antd";
import styled from "styled-components";

export const AvatarAntdWrap = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
  height: max-content;
  position: relative;
  width: max-content;

  & > div {
    display: flex;
    /* padding-left: 8px; */

    & > span:nth-child(1) {
      margin-left: 0px;
    }
    .ant-avatar {
      width: ${(p) => p.size + "px"};
      height: ${(p) => p.size + "px"};
      border-radius: ${(p) => p.size + "px"};
      margin-left: ${(p) => p.size * -0.3 + "px"};
      transition: all 0.4s ease;
      border: 1px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        transform: scale(1.25);
        z-index: 12;
      }
    }

    & > label {
      width: ${(p) => p.size + "px"};
      height: ${(p) => p.size + "px"};
      border-radius: ${(p) => p.size + "px"};
      border: 2px solid #ffffff;
      background: #f57f20;
      margin-left: ${(p) => p.size * -0.3 + "px"};
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: ${(p) => p.size * 0.4 + "px"};
      line-height: 100%;
      letter-spacing: -0.02em;
      color: #ffffff;
      z-index: 11;
      transition: all 0.4s ease;
      &:hover {
        transform: scale(1.25);
        z-index: 12;
      }
    }
  }
  & > span {
    width: max-content;
    position: absolute;
    left: calc(100% + 2px);
  }
`;

export const AvatarAntd = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PopoverAvatarWrap = styled.div`
  border-radius: 4px;
  max-height: 292px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: rgba(8, 15, 108, 0.05);
    height: 386px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(8, 15, 108, 0.08);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(8, 15, 108, 0.08);
    height: 239px;
  }
  /* background-color: #fff; */
`;
export const PopoverAvatarItem = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  height: max-content;
  padding: 4px 12px;
  gap: 8px;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    text-indent: 24px;
    color: rgba(41, 46, 115, 0.82);
    & > a {
      width: 48px;
      height: 48px;
      display: flex;
      & > span {
        width: 48px;
        height: 48px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    & > div {
      width: max-content;
      display: block;
      height: max-content;
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      user-select: none;
    }
  }
`;
