import React, { FC, useEffect, useRef, useState } from "react";
import {
  ChatBoxProps,
  // IConversationProps,
  IRoomChatProps,
  MessageTextProps,
  ServedFile,
} from "../../Conversation.props";
import {
  // Icon,
  // MenuIcon,
  // MicIcon,
  PaperPlaneIcon,
  PictureIcon,
  // SeachIcon,
  UploadIcon,
} from "assets/images/conversation";
import {
  ActionWrap,
  AvatarWrap,
  ChatBoxSC,
  // ChaterWrap,
  ChatInput,
  ChatInputSuffix,
  ChatInputWrap,
  FlexWrap,
  ImageAntd,
  ImageItem,
  Message,
  MessageBoardBody,
  MessageBoardHead,
  MessageChunk,
  MessageTextSC,
  MessageWrap,
  ProjectInfo,
  RightSide,
  // SearchInput,
  ServedImages,
  // SpindAntd,
  UploadAntd,
  UploadImages,
  UploadedFile,
  HeadDivider,
  CollaboratorWrap,
  // ExtendAccepted,
} from "../../Conversation.style";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  // getChatListAPI,
  getPackageDetailsAPI,
  getProjectDetails,
  // ILastMessageObj,
  IProject,
  // IRoom,
  // IRoomDetail,
  postUploadsAPI,
} from "api/users";
import { MESSAGE_TYPES, ROOM_TYPES } from "utils/constant";
import moment from "moment";
import { SmileyIcon } from "assets/images/project-board";
// import { EmojiSelectionWrap } from "pages/ProjectBoard/Components/CardModal/CardModal.style";
import Picker, { IEmojiData } from "emoji-picker-react";
import { message, Tooltip, UploadProps } from "antd";
import { CloseIcon } from "assets/images/deliver-work";
// import image from "components/base/image";
// import {
//   AvatarsWrap,
//   SmallAvatar,
// } from "pages/WorktasksInside/WorktasksInside.style";
import { AvatarAntd } from "components/modules/avatarList/avatarList.style";
import { UserOutlined } from "@ant-design/icons";
import AvatarAntdGroup from "components/modules/avatarList";
import { validateDoc } from "utils/regex";
import { formatBytes } from "utils/formatBytes";
import { setPageLoading } from "redux/slices";

const asyncArray: ServedFile[] = [];
const RoomChat: FC<IRoomChatProps> = ({
  roomDetail,
  unread,
  marginTop,
  openNewPrivateRoom,
  newPrivateChater,
  isOnline,
}) => {
  const [newMessage, setNewMessage] = useState<string>("");

  const { socket, user } = useAppSelector((state: any) => {
    return state.auth;
  });

  const handleResetData = () => {
    setNewMessage("");
    setFiles([]);
    asyncArray.splice(0, asyncArray.length);
    setFiles([...asyncArray]);
  };

  function sendMessage() {
    handleResetData();
    if (!newMessage.trim() && !files.length) return;

    let saveMessage = "<<embedded-images:[";
    let saveFile = "<<embedded-files:[";
    let imageCount = 0;
    let fileCount = 0;

    if (files.length) {
      files.forEach((file) => {
        if (file.type === "image") {
          saveMessage += file.fileName + "||";
          imageCount++;
        } else {
          saveFile +=
            file.fileName + "|" + file.originName + "|" + file.size + "||";
          fileCount++;
        }
      });
      saveMessage += "]>>";
      saveFile += "]>>";
    }

    if (!imageCount) saveMessage = "";
    if (!fileCount) saveFile = "";

    let data: any = {
      sender: user._id,
      message: newMessage.trim() + saveMessage + saveFile,
      category: MESSAGE_TYPES.TEXT,
    };
    if (openNewPrivateRoom) {
      data.receiver = [newPrivateChater?._id];
      data.roomName = "Private Room";
    } else {
      data.roomName = roomDetail?.roomName;
      data.roomId = roomDetail?._id;
    }

    socket.emit("sendMessage", data);
  }

  const [openEmojiSelection, setOpenEmojiSelection] = useState<boolean>(false);
  const emojiSelctionWrapE = useRef<HTMLDivElement>(null);

  const messageInputE = useRef() as any;

  const handleClickOutside = (event: Event) => {
    if (
      emojiSelctionWrapE.current &&
      !emojiSelctionWrapE.current.contains(event.target as Node)
    ) {
      setOpenEmojiSelection(false);
    }
  };

  const onEmojiClick = (event: any, emojiObject: IEmojiData) => {
    const text = emojiObject.emoji;
    setOpenEmojiSelection(false);
    setNewMessage(newMessage + text);
    event.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const [files, setFiles] = useState<ServedFile[]>([]);
  // const [fileName, setFileName] = useState<string>();
  // const [imageValidate, setImageValidate] = useState(false);
  // const fileNameFromAPI = form.getFieldValue("profilePicture");

  const dispatch = useAppDispatch();

  const fileRef = useRef<any>(null);
  const spinCoverRef = useRef<any>(false);
  // const validFile = ".png,.jpg,.zip,.txt,.pdf,.docs";

  const upLoadProps: UploadProps = {
    accept: ".png,.jpg,.zip,.txt,.pdf,.docs",
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isImage = validateDoc.test(file.name);
      if (!isImage) {
        message.error("File is invalid!");
        return false;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        spinCoverRef.current = true;
        fileRef.current = file;

        postUploads({
          fileName: file.name,
          fileType: file.type,
        });

        return false;
      }
    },
  };

  const { mutate: postUploads } = useMutation(postUploadsAPI, {
    onSuccess: async (rsUpload: any) => {
      dispatch(setPageLoading(true));
      await axios
        .put(rsUpload.signedRequest, fileRef.current)
        .then((rs: any) => {
          const file: ServedFile = {
            fileName: rsUpload.url,
            size: fileRef.current.size,
            type: fileRef.current.type,
            originName: rsUpload.file_name,
          };

          asyncArray.push(file);
          setFiles([...asyncArray]);
          // setFileName(rsUpload.url);
          dispatch(setPageLoading(false));
          spinCoverRef.current = false;
        })
        .catch((err: AxiosError) => {
          message.error("Error uploading: " + err.message);
        });
    },
    onError: (err: AxiosError) => {
      message.error(err.message);
    },
  });

  function removeServedImage(index: number) {
    asyncArray.splice(index, 1);
    setFiles([...asyncArray]);
  }

  useEffect(() => {
    messageInputE?.current?.focus();
  }, [newMessage, files]);

  const [worktaskDetail, setWorktaskDetail] = useState<any>();
  const { _id } = useAppSelector((state) => state.auth.user);

  useQuery(
    ["getPackageDetails", roomDetail],
    () =>
      getPackageDetailsAPI(
        roomDetail?.project || "",
        roomDetail?.worktask || ""
      ),
    {
      onSuccess: async (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setWorktaskDetail(res.responseData);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      enabled: !!roomDetail?.worktask,
    }
  );

  const [project, setProject] = useState<IProject>();
  useQuery(
    ["getProjectDetails"],
    () => getProjectDetails(roomDetail?.project || ""),
    {
      onSuccess: (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setProject(res.responseData);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      enabled: !!roomDetail?.worktask,
    }
  );

  const [roomAvatar, setRoomAvatar] = useState<{
    url: string;
    roomName: string;
  }>();
  useEffect(() => {
    if (roomDetail && roomDetail.roomType !== ROOM_TYPES.GROUP) {
      if (openNewPrivateRoom) {
        setRoomAvatar({
          url: roomDetail?.profilePicture || "",
          roomName:
            roomDetail.username || roomDetail.emailId.split("@").shift() || "",
        });
        return;
      }
      const user: any = roomDetail?.participantDetailsList?.find(
        (partner: any) => partner?._id !== _id
      );
      if (user) {
        setRoomAvatar({
          url: user?.profilePicture || "",
          roomName: user?.username || user?.emailId.split("@").shift(),
        });
        return;
      }
    }

    setRoomAvatar({
      url: roomDetail?.roomAvatar || "",
      roomName: roomDetail?.roomName || "",
    });
  }, [_id, openNewPrivateRoom, roomDetail]);

  const totalBudget =
    +(worktaskDetail?.minimumCost || 0) * +(worktaskDetail?.memberLimit || 0) +
    +(worktaskDetail?.observerFees || 0) +
    +(worktaskDetail?.bonus || 0);
  const truncatedSymbol = project?.tokenSymbol?.slice(0, 5);

  return (
    <RightSide marginTop={marginTop}>
      {roomDetail !== undefined && (
        <>
          <MessageBoardHead>
            <FlexWrap>
              <AvatarWrap>
                <div>
                  <AvatarAntd src={roomAvatar?.url} icon={<UserOutlined />} />
                  {isOnline && <label />}
                </div>
                <div>
                  <div>{roomAvatar?.roomName}</div>
                  {roomDetail?.roomType === ROOM_TYPES.DIRECT ? (
                    <p>{isOnline ? "Active" : "Offline"}</p>
                  ) : (
                    <></>
                  )}
                </div>
              </AvatarWrap>
              {roomDetail?.roomType === ROOM_TYPES.GROUP && (
                <>
                  <HeadDivider />
                  <CollaboratorWrap>
                    {roomDetail?.participantDetailsList.length +
                      " total collaborator"}
                    <AvatarAntdGroup
                      collabs={
                        roomDetail?.participantDetailsList?.map(
                          (profile: any) => {
                            return {
                              url: profile?.profilePicture,
                              username:
                                profile?.username ||
                                profile?.emailId.split("@").shift(),
                              id: profile?._id,
                            };
                          }
                        ) || []
                      }
                      size={20}
                      max={5}
                    />
                  </CollaboratorWrap>
                </>
              )}
            </FlexWrap>
            <ActionWrap>
              {/* <SeachIcon /> */}
              {/* <MenuIcon /> */}
            </ActionWrap>
          </MessageBoardHead>
          <MessageBoardBody
            className={
              roomDetail?.roomType === ROOM_TYPES.GROUP ? "group-case" : ""
            }
          >
            {roomDetail?.roomType === ROOM_TYPES.GROUP && (
              <ProjectInfo>
                <div>
                  <div>
                    <span>Total Budget</span>
                    <div>
                      {totalBudget} {truncatedSymbol || project?.tokenName}
                    </div>
                  </div>
                  <div>
                    <span>Bonus</span>
                    <div>
                      {!!worktaskDetail?.bonus
                        ? `${worktaskDetail?.bonus} ${truncatedSymbol}`
                        : ""}
                    </div>
                  </div>
                  <div>
                    <span>Type of Task</span>
                    <div>
                      {!!worktaskDetail?.issueType
                        ? worktaskDetail.issueType?.name
                        : ""}
                    </div>
                  </div>
                  <div>
                    <span>Deadline</span>
                    <div>
                      {!!worktaskDetail?.endDate
                        ? moment(worktaskDetail?.endDate).format("Do MMM YYYY")
                        : ""}
                    </div>
                  </div>
                </div>
              </ProjectInfo>
            )}
            <ChatBox
              messages={roomDetail?.data || []}
              isGroup={roomDetail.roomType === ROOM_TYPES.GROUP}
              userId={user._id}
              participantDetailsList={roomDetail?.participantDetailsList}
            />
            <ChatInputWrap served={!!files.length}>
              {!!files.length && (
                <ServedImages>
                  {files.map((file: ServedFile, i) => (
                    <ImageItem key={i}>
                      <CloseIcon
                        callback={() => {
                          removeServedImage(i);
                        }}
                      />
                      {file.type && file.type.includes("image") ? (
                        <ImageAntd
                          src={file.fileName}
                          // preview={false}
                          width={150}
                        />
                      ) : (
                        <div className="file-frame">
                          <span>
                            <UploadIcon />
                          </span>
                          <div>{file?.originName}</div>
                          <p>{formatBytes(file?.size)}</p>
                        </div>
                      )}
                    </ImageItem>
                  ))}
                </ServedImages>
              )}
              <ChatInput
                ref={messageInputE}
                className="Type Message"
                placeholder="Type Something"
                value={newMessage}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewMessage(e.target.value)
                }
                onPressEnter={(e: React.KeyboardEvent) => {
                  sendMessage();
                }}
                prefix={
                  <label
                    className="emoji-wrap"
                    onClick={() => setOpenEmojiSelection(true)}
                  >
                    <SmileyIcon />
                    {openEmojiSelection && (
                      <div className="emoji-position" ref={emojiSelctionWrapE}>
                        <Picker onEmojiClick={onEmojiClick} />
                      </div>
                    )}
                  </label>
                }
                suffix={
                  <ChatInputSuffix>
                    <span onClick={() => {}}>
                      <label className="select-file-wrap" htmlFor="input-file">
                        <UploadAntd {...upLoadProps} listType="picture-card">
                          <PictureIcon />
                        </UploadAntd>
                      </label>
                    </span>
                    <div />
                    <span onClick={sendMessage}>
                      <PaperPlaneIcon />
                    </span>
                  </ChatInputSuffix>
                }
              />
            </ChatInputWrap>
          </MessageBoardBody>
        </>
      )}
    </RightSide>
  );
};

export default RoomChat;

const ChatBox: FC<ChatBoxProps> = ({
  messages,
  isGroup,
  userId,
  participantDetailsList,
}) => {
  const chatBoxE = useRef() as React.MutableRefObject<HTMLInputElement>;
  const messagesWrapE = useRef() as React.MutableRefObject<HTMLInputElement>;
  useEffect(() => {
    // const offsetBottom =
    //   chatBoxE.current.offsetTop + chatBoxE.current.offsetHeight
    // chatBoxE.current.scrollTo(0, offsetBottom)
  }, [messages]);

  return (
    <ChatBoxSC isGroup={isGroup} ref={chatBoxE}>
      <MessageWrap>
        <MessageChunk ref={messagesWrapE}>
          {messages.map((message, i) => (
            <Message
              key={i}
              side={message.sender !== userId ? "left" : "right"}
              marginBottom={
                messages[i + 1] && messages[i + 1].sender === message.sender
                  ? 8
                  : 16
              }
            >
              <div>
                {(messages[i + 1] &&
                  messages[i + 1].sender === message.sender) || (
                  <Tooltip
                    placement={"bottomLeft"}
                    title={() => {
                      const user = participantDetailsList?.find(
                        (partner) => partner._id === message.sender
                      );
                      if (user) {
                        const { emailId, username } = user;
                        return username || emailId?.split("@").shift();
                      } else return "";
                    }}
                    overlayInnerStyle={{ width: "fit-content" }}
                  >
                    <AvatarAntd
                      src={
                        participantDetailsList?.find(
                          (partner) => partner._id === message.sender
                        )?.profilePicture
                      }
                      icon={<UserOutlined />}
                    />
                  </Tooltip>
                )}
              </div>
              <div>
                {message?.category === MESSAGE_TYPES.FILE ? (
                  <UploadedFile
                    side={message.sender !== userId ? "left" : "right"}
                  >
                    <UploadIcon />
                    <div>
                      <div>{message?.fileName}</div>
                      <span>{message?.size}</span>
                    </div>
                  </UploadedFile>
                ) : (
                  <MessageText
                    text={message?.message}
                    side={message.sender !== userId ? "left" : "right"}
                  />
                )}
                {(messages[i + 1] &&
                  messages[i + 1]?.sender === message?.sender) || (
                  <span>{moment(message?.time).format("h:mm a")}</span>
                )}
              </div>
            </Message>
          ))}
        </MessageChunk>
      </MessageWrap>
    </ChatBoxSC>
  );
};
const MessageText: FC<MessageTextProps> = ({ text, side }) => {
  const element = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (element.current?.offsetHeight > 50)
      if (side === "left")
        element.current.style.borderRadius = "17px 17px 17px 0px";
      else element.current.style.borderRadius = "17px 17px 0px 17px";
  }, [side]);

  function generateText() {
    const regexImages = /<<embedded-images:\[[^<>]*\]>>/g;
    const regexFiles = /<<embedded-files:\[[^<>]*\]>>/g;

    const imagesText = text?.match(regexImages) || [];
    const filesText = text?.match(regexFiles) || [];

    let images: any = [];
    let files: any = [];

    let message = text;
    if (imagesText.length) {
      images = imagesText[0]?.slice(19, -4).split("||");
      message = text?.replace(imagesText[0] || "", "");
    }
    if (filesText.length) {
      files = filesText[0]?.slice(18, -4).split("||");
      message = message?.replace(filesText[0] || "", "");
    }

    const imageComponent: any = {
      isComponent: false,
    };

    const fileComponent: any = {
      isComponent: false,
    };

    function groupSize(images: any) {
      const length = images.length;
      if (length < 5) return Math.floor(100 / length) + "%";
      return "25%";
    }

    if (images.length) {
      const width = groupSize(images);
      imageComponent.isComponent = true;
      imageComponent.Images = (
        <UploadImages side={side}>
          <ImageAntd.PreviewGroup>
            {images.map((imageName: string, i: number) => (
              <ImageAntd width={width} src={imageName} key={i} />
            ))}
          </ImageAntd.PreviewGroup>
        </UploadImages>
      );
    }

    files = files.map((file: string) => {
      const arr = file.split("|");
      return {
        fileName: arr[0],
        originalName: arr[1],
        size: arr[2],
      };
    });

    if (files.length) {
      // const width = groupSize(images);
      fileComponent.isComponent = true;
      fileComponent.Files = (
        <>
          {files.map((file: any, i: number) => (
            <UploadedFile side={side} key={i}>
              <a href={file?.fileName} target="_blank" rel="noreferrer">
                <UploadIcon />
              </a>
              <div>
                <div>{file?.originalName}</div>
                <span>{formatBytes(file?.size)}</span>
              </div>
            </UploadedFile>
          ))}
        </>
      );
    }

    return [imageComponent, fileComponent, message];
  }

  const [imageComponent, fileComponent, message] = generateText();

  return (
    <>
      {message && (
        <MessageTextSC side={side} ref={element}>
          {message}
        </MessageTextSC>
      )}
      {imageComponent.isComponent && imageComponent.Images}
      {fileComponent.isComponent && fileComponent.Files}
    </>
  );
};
