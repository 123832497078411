import {
  ArbitrumGoerli,
  AuroraTestnet,
  Goerli,
  Mumbai,
} from "@usedapp/core/dist/esm/src/model/chain";

export const REBAKED_ADDRESS = {
  [Goerli.chainId]: process.env.REACT_APP_GOERLI_REBAKEDDAO || "",
  [Mumbai.chainId]: process.env.REACT_APP_MUMBAI_REBAKEDDAO || "",
  [AuroraTestnet.chainId]: process.env.REACT_APP_AURORA_TEST_REBAKEDDAO || "",
  [ArbitrumGoerli.chainId]:
    process.env.REACT_APP_ARBITRUM_GOERLI_REBAKEDDAO || "",
};

// export const LEARN_TO_EARN_ADDRESS =
//   "0x9b075656A75a7656749B20B3Edca1F05c6fc272B";
// export const TOKEN_FACTORY_ADDRESS =
//   "0xf0c39397540324d22C44f0e7583D5C03a25d352b";

export const LEARN_TO_EARN_ADDRESS = {
  [Goerli.chainId]: process.env.REACT_APP_GOERLI_LEARN_TO_EARN || "",
  [Mumbai.chainId]: process.env.REACT_APP_MUMBAI_LEARN_TO_EARN || "",
  [AuroraTestnet.chainId]:
    process.env.REACT_APP_AURORA_TEST_LEARN_TO_EARN || "",
  [ArbitrumGoerli.chainId]:
    process.env.REACT_APP_ARBITRUM_GOERLI_LEARN_TO_EARN || "",
};

export const TOKEN_FACTORY_ADDRESS = {
  [Goerli.chainId]: process.env.REACT_APP_GOERLI_TOKEN_FACTORY || "",
  [Mumbai.chainId]: process.env.REACT_APP_MUMBAI_TOKEN_FACTORY || "",
  [AuroraTestnet.chainId]:
    process.env.REACT_APP_AURORA_TEST_TOKEN_FACTORY || "",
  [ArbitrumGoerli.chainId]:
    process.env.REACT_APP_ARBITRUM_GOERLI_TOKEN_FACTORY || "",
};

export const BlockchainNetworks: { [key: string]: number } = {
  Ethereum: Goerli.chainId,
  Polygon: Mumbai.chainId,
  Aurora: AuroraTestnet.chainId,
  Arbitrum: ArbitrumGoerli.chainId,
};

export const ChainIdToBlockChainName = {
  [Goerli.chainId]: "Ethereum",
  [Mumbai.chainId]: "Polygon",
  [AuroraTestnet.chainId]: "Aurora",
  [ArbitrumGoerli.chainId]: "Arbitrum",
};

export const SupportedChains = ["Ethereum", "Polygon", "Aurora", "Arbitrum"];

export const BlockchainExplorers: {
  [key: keyof typeof BlockchainNetworks]: string;
} = {
  Ethereum: "https://goerli.etherscan.io/tx/",
  Polygon: "https://mumbai.polygonscan.com/tx/",
  Aurora: "https://testnet.aurorascan.dev/tx/",
  Arbitrum: "https://goerli.arbiscan.io/tx/",
};

export const ROUTER_CONSTANTS = {
  HOME: "/",
  USER: {
    SIGNUP: "/sign-up",
    LOGIN: "/log-in",
    SUPPORT_TICKET: "/support-ticket",
    PAYMENT_DASHBOARD: "/payment-dashboard",
    PAYMENT_DASHBOARD_INITIATOR: "/payment-dashboard-initiator",
    PAYMENT_DASHBOARD_OBSERVER: "/payment-dashboard-observer",

    PROFILE_PREVIEW: "/profile-preview",

    PROFILE_USERINNER: "/profile/user-inner",
    RESET_PASSWORD: "/reset-password/:resetToken",
    PORTFOLIO: "/portfolio",
    ADD_PORTFOLIO: "/add-portfolio",
    EDIT_PORTFOLIO: "/edit-portfolio",
  },
  ORGANIZATION: {
    VIEW: "/organization/:id",
    WORKTASKS: "/organization/:id/worktasks",
    CREATE: "/create-organization",
    EDIT: "/edit-organization/:id",
  },
  PROJECT: {
    CREATE_PROJECT: "/create-project",
    BOUNTY: "/bounty/:projectId",
    DELIVER_WORK: "/deliver-work/:projectId/:packageId",
    REVIEW_WORKTASK: "/review-worktask/:projectId/:packageId",
    BOARD: "/project-board/:projectId/:packageId",
    DETAIL: "/project-detail/:id",
    EXPRESS_INTEREST:
      "/project/worktasks/inside/express-interest/:projectId/:packageId",
    WORKTASKS_INSIDE: "/project/worktasks/inside/:projectId/:packageId",
    ADD_WORKTASK: "/add-worktask/:projectId",
    UPDATE_WORK_TASK: "/edit-worktask/:projectId/:packageId",
    COLLABORATORS_REQUEST:
      "/project-detail/collaborators/request/:projectId/:packageId",
    FEEDBACK: "/feedback/:projectId/:packageId",
    FEEDBACK_INFO: "/feedback-info/:projectId/:packageId",
    COLLABORATOR_RATING: "/collaborator-rating",
    UPDATE_PROJECT: "/update-project/:id",
  },
  COURSE: {
    CREATE_COURSE: "/create-course",
    COURSE_DETAIL: "/course/:id",
    LIST_ASSIGNMENT_FOR_CREATOR: "/assignments",
    LIST_COURSE_FOR_LEARNER: "/courses-for-learner",
    IDE_SUBMIT: "/submit-code",
  },
  BLOG: {
    LIST_BLOG: "/blog",
    BLOG_DETAIL: "/blog/:id",
  },
  DASHBOARD: "/dashboard",
  TRANSACTIONS_REWARDS: "/transactions-rewards",
  SUBMIT_DELIVERABLE: "/submit-deliverable/:projectId/:packageId/:type",
  REVIEW_DELIVERABLE: "/review-deliverable/:projectId/:packageId/:userId",
  CONVERSATION: "/conversation",
  ERROR_404: "/error/404",
  ABOUT_BUIDLFI: "/about-buidlfi",
  TERMS_OF_SERVICE: "/terms-of-service",
};

export const KEY_COOKIE = {
  ACCESS_TOKEN: "token",

  ADD_PORTFOLIO_STEP: "add-portfolio-step",
};

export const UPLOAD_URL =
  "http://localhost:4401/ReBaked/v1/api/upload/file/ori/";

export const AVATAR_FALLBACK_URL =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzPb_pSj-ir-9eB6mi0lVJdQP1KKHiB8fRBS1CbmOXGd9Z1FEGMJHbEKhahwhWLGSaEXY&usqp=CAU";

export const VERIFICATION_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export const PACKAGE_WORK_STATUS = {
  OPEN: "OPEN", // when package is created
  INPROGRESS: "INPROGRESS", // when any 1st collaborator starts work
  EXPIRED: "EXPIRED", // when submission is delayed
  COMPLETED: "COMPLETED", // when all user's submissions are approved
  DELIVERED: "DELIVERED", // when all user's have submitted their code and it is under review
  SUBMITTED: "SUBMITTED",
};

export const USER_WORK_STATUS = {
  INPROGRESS: "INPROGRESS", // when any collaborator starts work
  SUBMITTED: "SUBMITTED", // when collaborator submits his/her work (individually)
  SUBMISSION_APPROVED: "SUBMISSION_APPROVED", // whole package submission approved
  SUBMISSION_REJECTED: "SUBMISSION_REJECTED", // whole package submission rejected
  SUBMISSION_REVISON: "SUBMISSION_REVISON",
};

export const PROJECT_STATUS = {
  OPEN: "OPEN", // when project is created and ongoing
  COMPLETED: "COMPLETED", // when all packages are submitted and approved
  CLOSE: "CLOSE", // when project is closed and payment is also done
  INPROGRESS: "INPROGRESS", // when any package is in progress
};

export const MASTER_TYPES = {
  PROJECT_TYPES: "Project Types",
  ISSUE_TYPES: "Issue Types",
  COLLABORATOR_LEVEL: "Collaborator Levels",
  SKILLS: "Skills",
  PROJECT_CATEGORY: "Project Categories",
  BLOCKCHAIN_NETWORK: "Blockchain Networks",
  OBSERVERS_ROLE: "Observers Roles",
  CARD_TYPES: "Card Types",
  REJECT_REASONS: "Reject reasons",
  COUNTRY: "Country",
};

export const NOTIFICATION_CATEGORIES = {
  PROJECT: "PROJECT",
  PAYMENT: "PAYMENT",
  CHAT: "CHAT",
  SUPPORT_TICKET: "SUPPORT_TICKET",
  CARD_COMMENT: "CARD_COMMENT",
  REVISION: "REVISION",
  PACKAGE_REQUEST: "PACKAGE_REQUEST",
};

export const MESSAGE_TYPES = {
  TEXT: "TEXT",
  FILE: "FILE",
};

export const ROOM_TYPES = {
  DIRECT: "DIRECT",
  GROUP: "GROUP",
};
export const MARK_AS_COMPLETED_STATUS = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};

export const SKILL_LEVEL = {
  BEGINNER: "Beginner",
  INTERMEDIATE: "Intermediate",
  PROFESSIONAL: "Professional",
  EXPERT: "Expert",
};

export const STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const SUPPORT_TICKET_STATUS = {
  OPEN: "OPEN",
  COMPLETED: "COMPLETED",
  INPROGRESS: "INPROGRESS",
};

export const DELIVERY_STATUS = {
  DELIVERED: "DELIVERED",
  REVISION: "REVISION",
  COMPLETED: "COMPLETED",
};

export const PROJECT_BOARD_ROLE = {
  CREATOR: "CREATOR",
  COLLABORATOR: "COLLABORATOR",
  OBSERVER: "OBSERVER",
};

export const LIKE_COMMENT = {
  LIKE: "LIKE",
  UNLIKE: "UNLIKE",
};

export const PROGRESS = {
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const TABS_CONTENT = {
  PROJECT_DETAILS: "Project Details",
  DELIVERABLES: "Deliverables",
  PROJECT_BOARD: "Project Boards",
  COMPANY_INFOMATION: "Company Information",
  MESSAGE: "Message",
  COLLABORATORS: "Collaborators",
  OBSERVERS: "Observers",
};

export const validFiles =
  ".gif,.jpg,.jpeg,.tif,.tiff,.png,.webp,.bmp,.pdf,.docs,.doc,.txt,.xlsx,.xls,.ppt,.pptx,.zip,.rar";

export const TOKEN_NAME_NOT_FOUND = "Token name not found";
export const TOKEN_SYMBOL_NOT_FOUND = "Token symbol not found";
export const EMAIL_OCTOPUS_ID = "23c8621a-a6e3-11ed-98fc-f7d524f637f7";
