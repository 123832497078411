import { ICreatePortfolioReq } from "api/users";
import { EyeCheckIcon } from "assets/images/Bounty";
import React, { FC, useEffect, useState } from "react";
import { PROGRESS } from "utils/constant";
import AddDetailStep from "./addDetailStep";
import {
  Container,
  Frame,
  HeaderWrap,
  Line,
  MenuStep,
} from "../Bounty/Bounty.style";
import Completed from "../Bounty/components/Completed";
import { IAddPortfolioProps } from "./AddPortfolio.props";
import { CompleteIcon, DetailsIcon } from "assets/images/addPortfolio";
import { Header, MainTitleWrap } from "./AddPortfolio.style";
import Preview from "./portfolioPreview";
import { useLocation, useParams } from "react-router-dom";
import Page404 from "pages/Page404";

const AddPortfolio: FC<IAddPortfolioProps> = () => {
  const location = useLocation();
  const { portfolioId } = useParams();
  const routerState = location.state as any;
  const selectedPortfolio = routerState?.selectedPortfolio;
  const finishedSubmitting = routerState?.finishedSubmitting;

  const isEdit = !!portfolioId;

  const step = [
    {
      title: "Add project details",
      icon: <DetailsIcon />,
    },
    {
      title: "Preview",
      icon: <EyeCheckIcon />,
    },
    {
      title: "Completed",
      icon: <CompleteIcon />,
    },
  ];

  const [projectFormData, setProjectFormData] = useState<ICreatePortfolioReq>(
    selectedPortfolio || {
      projectTitle: "",
      image: "",
      completionDate: undefined,
      description: "",
      files: [],
      skills: [],
      projectUrl: undefined,
      videos: [],
    }
  );

  const [stepIndex, setStepIndex] = useState(0);
  const [detailStep, setDetailStep] = useState(false);
  const [success, setSuccess] = useState(PROGRESS.IN_PROGRESS);

  useEffect(() => {
    if (finishedSubmitting) {
      setSuccess(PROGRESS.SUCCESS);
      setStepIndex(2);
    }
  }, [finishedSubmitting]);

  async function nextStep() {
    if (stepIndex === step.length - 1) return;

    setStepIndex(stepIndex + 1);
  }
  function backStep() {
    if (stepIndex === 0) return;
    setStepIndex(stepIndex - 1);
  }

  useEffect(() => {
    if (projectFormData.projectTitle) {
      setDetailStep(true);
    }
  }, [projectFormData]);

  useEffect(() => {
    if (success === PROGRESS.SUCCESS) {
      setStepIndex(step.length - 1);
    }
  }, [step.length, success]);

  function switchStepValidate(step: number) {
    switch (step) {
      case 1:
        if (!detailStep) return false;
        return true;
      case 2:
        return false;

      default:
        return true;
    }
  }

  if (isEdit && !selectedPortfolio) {
    return <Page404 />;
  }

  return (
    <>
      <Container>
        <Frame>
          <HeaderWrap>
            <Header>
              <MenuStep
                className={"active"}
                onClick={() => {
                  if (success === PROGRESS.SUCCESS) return;
                  setStepIndex(0);
                }}
              >
                {step[0].icon}
                <div className="step-title">{step[0].title}</div>
              </MenuStep>
              {step.slice(1).map((content: any, i: number) => (
                <React.Fragment key={i}>
                  <Line
                    className={
                      i + 1 <= stepIndex
                        ? i + 2 === step.length && stepIndex === step.length - 1
                          ? ""
                          : "line-active"
                        : ""
                    }
                  />
                  <MenuStep
                    key={i}
                    className={
                      i + 1 <= stepIndex
                        ? i + 2 === step.length && stepIndex === step.length - 1
                          ? "complete-active"
                          : "active"
                        : ""
                    }
                    onClick={() => {
                      if (success === PROGRESS.SUCCESS) return;
                      if (!switchStepValidate(i + 1)) return;
                      setStepIndex(i + 1);
                    }}
                    paddingX={i === 0 || i === 3 || i === 4 ? 20 : 0}
                  >
                    {content.icon}
                    <div className="step-title">{content.title}</div>
                  </MenuStep>
                </React.Fragment>
              ))}
            </Header>
          </HeaderWrap>
          <MainTitleWrap>
            <div>{step[stepIndex].title}</div>
            <span>
              <p>Step {" " + (stepIndex + 1)}</p>
              <span>of 3</span>
            </span>
          </MainTitleWrap>

          {stepIndex === 0 && (
            <AddDetailStep
              projectFormData={projectFormData}
              setProjectFormData={setProjectFormData}
              nextStep={nextStep}
            />
          )}
          {stepIndex === 1 && (
            <Preview
              projectFormData={projectFormData}
              nextStep={nextStep}
              backStep={backStep}
              setSuccess={setSuccess}
            />
          )}
          {stepIndex === 2 && <Completed portfolioEdit={isEdit} />}
        </Frame>
      </Container>
    </>
  );
};

export default AddPortfolio;
