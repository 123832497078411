import { LinkedInIcon } from "assets/icons/common/linkedin.icon";
import { TwitterIcon } from "assets/icons/common/twitter.icon";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import { FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { shareSocial } from "utils/shareUrlPlatform";
import { IFinishedModalProps } from "./finishedModal.props";
import { BaseModalCustom, Buttons } from "./finishedModal.style";
import { shorten } from "turl";

const FinishedModal: FC<IFinishedModalProps> = ({
  course,
  ...props
}: IFinishedModalProps) => {
  const navigate = useNavigate();

  return (
    <BaseModalCustom
      centered
      width={"524px"}
      destroyOnClose
      closable
      {...props}
    >
      <h1>
        My friend, you went from zero to shipped! Show to the public what you
        have achieved, so we can retweet you!
      </h1>
      <span className="score">{course?.participant_count ?? 0}</span>
      <Buttons>
        <PrimaryButton
          className="twitter"
          icon={<TwitterIcon />}
          onClick={() => {
            shorten(window.location.href).then((res: string) => {
              shareSocial(
                "Twitter",
                `${res.trim()}&text=🎉Big%20ups%21%20I%27ve%20crushed%20the%20%40buidlfi%20course%20%26%20unleashed%20my%20Web3%20creation%20into%20the%20endless%20abyss%20of%20Web3🚀.%0A%0AJoin%20me%20at%20%40buidlfi%20to%20start%20your%20%23Web3%20learning%20journey%2C%20claim%20bounties%2C%20and%20connect%20with%20code%20warriors%20to%20unleash%20the%20true%20power%20of%20blockchain%21%0A%0A`
              );
            });
          }}
        >
          Show Da People
        </PrimaryButton>
        <PrimaryButton
          className="linkedin"
          icon={<LinkedInIcon />}
          onClick={() => {
            shareSocial("Linkedln");
          }}
        >
          Show Da People
        </PrimaryButton>
        <OutlinedButton
          onClick={() => {
            navigate(ROUTER_CONSTANTS.HOME);
          }}
        >
          Go Back Home
        </OutlinedButton>
      </Buttons>
    </BaseModalCustom>
  );
};

export default memo(FinishedModal);
