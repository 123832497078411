import styled from "styled-components";

export const TitleBlack = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #080f6c;
  display: flex;
  margin-bottom: 8px;
`;

export const TitleColor = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #080f6c;
  text-transform: uppercase;
  background: linear-gradient(to right, #992ffc 0%, #3fb3f8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 4px;
`;
export const Wrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  margin-bottom: 12px;
`;
