import { FC, Fragment, useState } from "react";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import { IProjDescProps } from "pages/ProjectDetail/ProjectDetail.props";
import {
  ProjDescWrapper,
  ProjDescTitle,
  ProjDescription,
  StatNumber,
  StatHint,
} from "./projectDescription.style";
import { InfoIcon } from "assets/icons/info.icon";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import { splitDescription, elementing } from "utils/formatDescription";
import { InfoModal } from "components/modules/InfoModal";

const ProjectDescription: FC<IProjDescProps> = ({ project }) => {
  const [popupIndex, setPopupIndex] = useState(0);

  const formatTextToNewType = (text: string) => {
    return (
      <ProjDescription>
        {splitDescription(text).map((str, i) => (
          <Fragment key={i}>
            {elementing(str)}
            <br />
          </Fragment>
        ))}
      </ProjDescription>
    );
  };

  const infoList = [
    {
      title: "What is Total Contributor?",
      description:
        "This shows the total number of contributors currently involved in the project. It indicates how many individuals or collaborators are actively participating in the WorkTasks.",
    },
    {
      title: "What is Total Worktasks?",
      description:
        "This displays the total count of WorkTasks associated with the project. It provides an overview of the number of tasks created and being worked on.",
    },
  ];

  return (
    <ProjDescWrapper>
      <CommonFlexColumn space="12px">
        <ProjDescTitle>Project description</ProjDescTitle>
        {project?.tagline && formatTextToNewType(project?.tagline)}
        {/* <ProjDescription>{project?.tagline}</ProjDescription> */}
      </CommonFlexColumn>
      <CommonFlexColumn space="30px">
        <CommonFlexColumn>
          <StatNumber>{project?.totalContributorIds?.length}</StatNumber>
          <CommonFlexRow space="4px">
            <StatHint>Total contributor</StatHint>
            <InfoIcon onClick={() => setPopupIndex(1)} cursor={"pointer"} />
          </CommonFlexRow>
        </CommonFlexColumn>
        <CommonFlexColumn>
          <StatNumber>{project?.totalPackages?.length}</StatNumber>
          <CommonFlexRow space="4px">
            <StatHint>Total Worktasks</StatHint>
            <InfoIcon onClick={() => setPopupIndex(2)} cursor={"pointer"} />
          </CommonFlexRow>
        </CommonFlexColumn>
      </CommonFlexColumn>

      <InfoModal
        visible={popupIndex !== 0}
        onCancel={() => setPopupIndex(0)}
        description={
          infoList[popupIndex - 1] ? infoList[popupIndex - 1].description : ""
        }
        title={infoList[popupIndex - 1] ? infoList[popupIndex - 1].title : ""}
      />
    </ProjDescWrapper>
  );
};

export default ProjectDescription;
