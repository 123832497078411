import { useQuery } from "@tanstack/react-query";
import { useEthers, useTokenBalance } from "@usedapp/core";
// import { EthereumIcon } from "assets/icons/common/coins/ethereum.icon";
import { SecondaryCopyIcon } from "assets/icons/common/copy.icon";
import { formatEther } from "ethers/lib/utils";
import type { FC } from "react";
import { getERC20NameAndSymbol } from "web3/contract";
import { TransactionProps } from "../OrganizationPage.props";
import { CommonFlexRow } from "../OrganizationPage.style";
import {
  WalletWrapper,
  WalletTitleAndAction,
  SectionTitle,
  CommonFlexColumn,
  WalletItem,
  WalletItemInfo,
  WalletItemDescWrapper,
  WalletItemTitle,
  WalletItemText,
  CopyWrapper,
} from "./transaction.style";

const Wallet: FC<TransactionProps> = ({ project }) => {
  const { account, isLoading: walletLoading } = useEthers();
  const customToken = useTokenBalance(project?.tokenContract, account);
  const { data: customTokenInfo, isLoading: nameAndSymbolLoading } = useQuery(
    ["getERC20NameAndSymbol"],
    () => getERC20NameAndSymbol(project?.tokenContract),
    {
      refetchOnWindowFocus: false,
      enabled: !!account && !!project?.tokenContract,
    }
  );
  const walletActive = !walletLoading && !!account;

  return (
    <WalletWrapper>
      <WalletTitleAndAction>
        <SectionTitle>My Wallets</SectionTitle>
        {/* <AddWalletBtn>
          <AddWalletIcon /> Add Wallet
        </AddWalletBtn> */}
      </WalletTitleAndAction>
      {!walletActive ? (
        <CommonFlexColumn space="12px">
          <WalletItem>
            <WalletItemTitle>Please connect your wallet</WalletItemTitle>
          </WalletItem>
        </CommonFlexColumn>
      ) : (
        <CommonFlexColumn space="12px">
          {nameAndSymbolLoading ? (
            <WalletItem>
              <WalletItemTitle>Loading...</WalletItemTitle>
            </WalletItem>
          ) : !!customToken ? (
            <WalletItem space="12px">
              {/* <EthereumIcon /> */}
              <WalletItemInfo space="8px">
                <WalletItemDescWrapper>
                  <WalletItemTitle textSize="16px">
                    {customTokenInfo?.name}
                  </WalletItemTitle>
                  <CommonFlexRow space="8px">
                    <WalletItemText textSize="14px">{account}</WalletItemText>
                    <CopyWrapper
                      onClick={() =>
                        navigator.clipboard.writeText(account || "")
                      }
                    >
                      <SecondaryCopyIcon />
                    </CopyWrapper>
                  </CommonFlexRow>
                  <WalletItemTitle textSize="16px">
                    {formatEther(customToken)}{" "}
                    {customTokenInfo?.symbol?.slice(0, 5)}
                  </WalletItemTitle>
                </WalletItemDescWrapper>
              </WalletItemInfo>
            </WalletItem>
          ) : (
            <WalletItem>
              <WalletItemTitle>Please connect your wallet</WalletItemTitle>
            </WalletItem>
          )}
        </CommonFlexColumn>
      )}
    </WalletWrapper>
  );
};

export default Wallet;
