import { useQuery } from "@tanstack/react-query";
import { Col, Form, Row } from "antd";
import { getDataMastersAPI } from "api/users";
import { FC, useState } from "react";
import { requiredRules, stringRequired } from "utils/validatorRules";
import {
  StyledInput,
  StyledSelect,
  StyledOption,
  BtnContainer,
  StyledInputNumber,
  Title,
  CheckBoxText,
  HighlightText,
} from "./newTokenForm.style";
import { ICreateTokenProps } from "./newTokenForm.props";
import { IBlockchainNetwork, ITokenForm } from "api/course";
import { handleMessageErrorSC } from "web3/contract";
import { parseUnits } from "ethers/lib/utils";
import PrimaryButton from "components/base/primaryButton";
import { createTokenSC } from "web3/learnToEarnContract";
import OutlinedButton from "components/base/outlinedButton";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import { BlockchainExplorers, BlockchainNetworks } from "utils/constant";
import { scrollToFirstErrorSmooth } from "utils/scrollToSection";

const NewTokenForm: FC<ICreateTokenProps> = ({ courseForm, onClose }) => {
  const [tokenForm] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const { data: dataNetworks } = useQuery(
    ["getDataNetworks"],
    () => getDataMastersAPI({ type: "Blockchain Networks" }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { handleSwitchNetwork, checkWallet } = useCheckWalletAndSwitchNetwork();

  const blockchainNetworksSelectd: IBlockchainNetwork[] =
    dataNetworks?.responseData ? dataNetworks.responseData.values : [];
  const currSelectedNetworkId = Form.useWatch("blockchainNetwork", tokenForm);
  const currNetworkName =
    blockchainNetworksSelectd.find((net) => net._id === currSelectedNetworkId)
      ?.name || "Polygon";

  const handleCreateToken = async (values: ITokenForm) => {
    // console.log("values", values);
    const selectedNetwork = blockchainNetworksSelectd.find(
      (nw) => nw?._id === values.blockchainNetwork
    );
    const curChainId = BlockchainNetworks[selectedNetwork?.name!];

    setLoading(true);

    if (!checkWallet()) {
      setLoading(false);
      return;
    }

    try {
      await handleSwitchNetwork(curChainId);

      let { event } = await createTokenSC({
        totalSupply: parseUnits(values.totalSupply.toString(), 18),
        name: values.tokenName,
        symbol: values.tokenSymbol,
      });
      const contractAddress = event[0]?.token;
      courseForm.setFieldsValue({
        tokenContract: contractAddress,
        blockchainNetwork: values.blockchainNetwork,
        isExistingToken: true,
        tokenName: values.tokenName,
        tokenSymbol: values.tokenSymbol,
        tokenExplorer: BlockchainExplorers[selectedNetwork?.name!],
      });
      setLoading(false);
      onClose();
    } catch (err: any) {
      setLoading(false);

      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  return (
    <Form
      layout="vertical"
      form={tokenForm}
      onFinish={handleCreateToken}
      scrollToFirstError={scrollToFirstErrorSmooth}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Title>Issue a New Token for your project</Title>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Your token name"
            name="tokenName"
            rules={[stringRequired]}
          >
            <StyledInput placeholder="Eg. My Awesome token" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Token symbol"
            name="tokenSymbol"
            rules={[stringRequired]}
          >
            <StyledInput placeholder="Eg. MAT" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Token decimals"
            name="decimal"
            rules={[requiredRules]}
            initialValue={18}
          >
            <StyledInputNumber readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Network"
            name="blockchainNetwork"
            rules={[requiredRules]}
          >
            <StyledSelect placeholder="Blockchain Network">
              {blockchainNetworksSelectd.map((network) => (
                <StyledOption value={network?._id} key={network?._id}>
                  {network?.name}
                </StyledOption>
              ))}
            </StyledSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Total Supply"
            name="totalSupply"
            rules={[stringRequired]}
          >
            <StyledInput placeholder="Input the total supply" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="agree"
        rules={[
          () => ({
            validator: (_, value) => {
              if (!value) return Promise.reject("Can't be blank");

              return Promise.resolve();
            },
          }),
        ]}
        valuePropName="checked"
      >
        <CheckBoxText>
          I agree to terms to {currNetworkName} ERC20 Token Generators{" "}
          <HighlightText>Terms of use</HighlightText>
        </CheckBoxText>
      </Form.Item>
      <BtnContainer>
        <OutlinedButton>Cancel</OutlinedButton>
        <PrimaryButton htmlType="submit" loading={loading}>
          Confirm and use the token
        </PrimaryButton>
      </BtnContainer>
    </Form>
  );
};

export default NewTokenForm;
