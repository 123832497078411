import styled from "styled-components";

export const CkContentWrapper = styled.div`
  word-break: break-word;
`;

export const Container = styled.div`
  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #080f6c;
  }
`;
