import styled from "styled-components";
import bg from "assets/images/bg-course-detail.png";
import { Button } from "antd";

export const Container = styled.div``;

export const ProjectBg = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
    linear-gradient(
      90deg,
      rgba(126, 0, 253, 0.1) 0%,
      rgba(193, 67, 252, 0.1) 46.88%,
      rgba(0, 191, 251, 0.1) 100%
    );
  border-radius: 4px;
`;

export const Heading = styled.div`
  padding: 32px 0px;
  background-image: url(${bg});
  background-size: cover;
  > div {
    max-width: 1170px;
    margin: 0 auto;
    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 100%;
      letter-spacing: -0.04em;
      color: #080f6c;
    }
  }
`;

export const BlogDetailTime = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  color: rgba(41, 46, 115, 0.82);
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  background: #080f6c;
  opacity: 0.3;
  border-radius: 50%;
  margin: unset !important;
`;

export const Content = styled.div`
  max-width: 1170px;
  margin: 20px auto;
  display: flex;
  gap: 48px;
`;

export const BlogContent = styled.div`
  width: 815px;
  img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  .title {
    font: normal 700 20px/120% "DM Sans";
    color: #080f6c;
  }
  .content {
    font: normal 400 16px/156% "DM Sans";
    color: rgba(41, 46, 115, 0.82);
    word-break: break-word;
    position: relative;
    overflow: hidden;
  }
`;
export const MenuAndTabs = styled.div`
  width: 307px;
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 20px;
  top: 20px;
  h2 {
    font: normal 700 16px/28px "DM Sans";
    color: #080f6c;
  }

  h3 {
    font: normal 500 16px/28px "DM Sans";
    color: #080f6c;
    margin-bottom: unset;
  }
  height: fit-content;
`;
export const Menu = styled.div`
  padding: 24px 0px;
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 8px;
  height: fit-content;
  h2 {
    padding-left: 20px;
  }
`;

export const Section = styled.div``;

export const SubSectionIcon = styled.div`
  margin-left: 20px;
`;

export const Tabs = styled.div`
  padding: 24px 20px;
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 8px;
  height: fit-content;
  div {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    > div {
      background: #080f6c;
      color: #ffffff;
      line-height: 100%;
    }
  }
  .react-loading-skeleton {
    width: 90px;
    height: 27.38px;
    border-radius: 20px;
  }
`;

export const ButtonStyled = styled(Button)<{ $highlight: boolean }>`
  font: normal 400 16px/156% "DM Sans";
  color: rgba(41, 46, 115, 0.82);
  display: flex;
  padding-left: unset;
  transition: margin 0.3s ease-in-out;
  width: 100%;
  border: unset;
  padding-left: 20px;
  height: fit-content;
  ${(p) =>
    p.$highlight
      ? ` background: linear-gradient(
      90deg,
      rgba(126, 0, 253, 0.1) 0%,
      rgba(126, 0, 253, 0) 100%
    );`
      : ""}
  h3, span {
    transition: inherit;
    margin-left: 15px;
    white-space: break-spaces;
    word-break: break-word;
    text-align: left;
  }
  div {
    transition: inherit;
  }
  :hover {
    background: linear-gradient(
      90deg,
      rgba(126, 0, 253, 0.1) 0%,
      rgba(126, 0, 253, 0) 100%
    );
    > div {
      margin-left: 29px;
    }
    > span {
      margin-left: 6px;
    }
  }
`;

export const SkeletonWrapper = styled.div<{ w?: string }>`
  width: ${({ w }) => w || "50%"};
  line-height: 1;
`;
