import { FC, ReactElement, useMemo, useState } from "react";
import { IWorktasksInsideProps } from "./WorktasksInside.props";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "components/base/breadcrumb";
import WorktasksInsideBackground from "assets/images/worktasks-inside/worktasks-inside-background.png";
import {
  InterestIcon,
  Background,
  GitIcon,
  GitBookIcon,
  PendingIcon,
} from "assets/images/worktasks-inside";
import {
  Container,
  BackgroundImage,
  HeadWrap,
  Head,
  Wrap,
  Title,
  Status,
  EndTime,
  SponsoredBy,
  Gap,
  BodyWrap,
  GitWrap,
  Git,
  GitBtns,
  DesTitle,
  DesText,
  WorkPlansTitle,
  WorkPlansWrap,
  WorkPlansHead,
  PersonWrap,
  Techs,
  WorkPlans,
  ApplicantsWrap,
  Item,
  AvatarWrap,
  ItemTextWrap,
  ItemText,
  AvatarSponsor,
} from "./WorktasksInside.style";
import PrimaryButton from "components/base/primaryButton";
import Button from "components/base/button";
import { Applied, WrapTag } from "pages/Worktasks/Worktask/Worktask.style";
import { BlockchainNetworks, ROUTER_CONSTANTS } from "utils/constant";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  finishPackageAPI,
  getPackageBonusScoresAPI,
  getPackageDetailsAPI,
  getUserRequestReceivedAPI,
  PaidCollaboratorEvent,
  PaidObserverEvent,
  startWorkAPI,
  updateFinishStatusAPI,
} from "api/users";
import { AxiosResponse } from "axios";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import dayjs from "dayjs";
import { message, Avatar as AvatarAntd } from "antd";
import FlexBox from "components/base/Flexbox";
import CollaboratorRating from "pages/CollaboratorRating";
import { finishPackageSC, handleMessageErrorSC } from "web3/contract";
import { setPageLoading } from "redux/slices";
import { handleLongText } from "utils/text";
import { formatUnits } from "ethers/lib/utils";
import { useCheckWalletAndSwitchNetwork } from "hooks";

const WorktasksInside: FC<IWorktasksInsideProps> = ({
  project,
}): ReactElement => {
  const userId: string = useAppSelector((state) => state.auth.user?._id);
  const location = useLocation();
  const pathSplit = location.pathname.split("/");
  const [openRate, setOpenRate] = useState(false);
  const toggleOpenRate = () => setOpenRate(!openRate);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const param = useParams();
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();
  const [worktaskDetail, setWorktaskDetail] = useState([] as any);
  const [joinRequests, setJoinRequest] = useState([] as any);
  const [packageBonusScores, setPackageBonusScores] = useState([] as number[]);

  useQuery(
    ["getPackageDetails"],
    () => getPackageDetailsAPI(param.projectId || "", param.packageId || ""),
    {
      onSuccess: async (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setWorktaskDetail(res.responseData);
        }
      },
    }
  );

  useQuery(
    ["getRequestPackage"],
    () =>
      getUserRequestReceivedAPI(
        param.projectId || "",
        param?.packageId || "",
        "ACTIVE"
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setJoinRequest(res.responseData?.records);
        }
      },
    }
  );

  const { mutateAsync: getPackageBonusScores } = useMutation(
    getPackageBonusScoresAPI,
    {
      onSuccess: (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setPackageBonusScores(
            res.responseData?.paymentCalculation?.collaboratorBonusScores
          );
        }
      },
    }
  );

  const { mutate: startWork } = useMutation(startWorkAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        queryClient.invalidateQueries(["getPackageDetails"]);
      } else {
        message.error(responseMessage);
      }
    },
  });

  const handleStartWork = () => {
    startWork({
      projectId: param.projectId || "",
      packageId: param.packageId || "",
    });
  };

  const { mutate: updateFinishStatus } = useMutation(updateFinishStatusAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        queryClient.invalidateQueries(["getPackageDetails"]);
      } else {
        message.error(responseMessage);
      }
    },
  });

  const { mutate: finishPackage } = useMutation(finishPackageAPI, {
    onSuccess: (res: AxiosResponse) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        queryClient.invalidateQueries(["getPackageDetails"]);
      } else {
        message.error(responseMessage);
      }
    },
  });

  const handleFinishWorktask = async () => {
    if (!isCollaboratorReviewFinish) {
      message.error(
        "Please review each collaborators before completing the Work Task"
      );
      return;
    }

    if (!isCollaboratorRatingFinish) {
      message.error(
        "All collaborators need to rate each other before completing the Work Task"
      );
      return;
    }

    if (!checkWallet()) return;

    await getPackageBonusScores({
      projectId: param.projectId || "",
      packageId: param.packageId || "",
    });

    try {
      const projectChainId =
        BlockchainNetworks[project?.blockchainNetwork?.name!];

      await handleSwitchNetwork(projectChainId);
      const scParams = {
        projectId: worktaskDetail?.scProjectId,
        packageId: worktaskDetail?.scPackageId,
        collaborators: worktaskDetail?.collaboratorWalletAddress,
        observers: worktaskDetail?.observerWalletAddress,
        scores: packageBonusScores,
      };

      dispatch(setPageLoading(true));
      let { tx, events } = await finishPackageSC(scParams);
      const budgetLeft = (+formatUnits(
        events.FinishedPackage[0][2],
        project?.decimal
      )).toFixed(1);
      const paidCollaboratorEvents: PaidCollaboratorEvent[] =
        events.PaidCollaboratorRewards.map((args: any) => {
          return {
            walletAddress: args[2],
            mgp: +formatUnits(args[3], project?.decimal),
            bonus: +formatUnits(args[4], project?.decimal),
          };
        });
      const paidObserverEvents: PaidObserverEvent[] =
        events.PaidObserverFee.map((args: any) => {
          return {
            walletAddress: args[2],
            amount: +formatUnits(args[3], project?.decimal),
          };
        });

      updateFinishStatus({
        projectId: param?.projectId || "",
        packageId: param?.packageId || "",
        status: "SC_SUCCESS",
      });
      finishPackage({
        projectId: param?.projectId || "",
        packageId: param?.packageId || "",
        budgetLeft,
        txHash: tx.hash,
        paidCollaboratorEvents,
        paidObserverEvents,
      });
      dispatch(setPageLoading(false));
    } catch (err: any) {
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const items = [
    {
      text: worktaskDetail.projectName,
      to: ROUTER_CONSTANTS.PROJECT.DETAIL.replace(":id", pathSplit[5]),
    },
    {
      text: "Worktasks",
      to: ROUTER_CONSTANTS.PROJECT.DETAIL.replace(":id", pathSplit[5]),
    },
    {
      text: worktaskDetail?.name,
      to: location.pathname,
    },
  ];
  const collaboratorsLength = worktaskDetail.collaborators?.length;

  const removeDuplicateCollaboratorRating =
    worktaskDetail.collaboratorRatings?.filter(
      (v: any, i: any, a: any) =>
        a.findIndex(
          (v2: any) => v2.fromUser === v.fromUser && v2.user === v.user
        ) === i
    );

  const isCollaboratorReviewFinish =
    collaboratorsLength === worktaskDetail.collaboratorReviews?.length;
  const isCollaboratorRatingFinish =
    collaboratorsLength * (collaboratorsLength - 1) ===
    removeDuplicateCollaboratorRating?.length;

  const isProjectCreator = worktaskDetail?.initiator === userId;
  const isCanSubmitDeliverables =
    worktaskDetail?.progressHistory?.inProgressUsers.includes(userId);
  const isSubmitedDeliverables =
    worktaskDetail?.progressHistory?.submittedUsers.includes(userId);
  const isEveryCollabSubmitted =
    worktaskDetail?.progressHistory?.submittedUsers.filter((item: any) => item)
      .length === worktaskDetail?.collaborators?.length ||
    worktaskDetail?.workProgress?.filter(
      (item: any) => item.status === "SUBMISSION_APPROVED"
    ).length === worktaskDetail?.collaborators?.length;
  // const isWorktaskCompleted = worktaskDetail?.workStatus === "COMPLETED";
  const isAlreadyCollaborator = worktaskDetail?.collaborators?.includes(userId);

  const isAlreadyRequest = worktaskDetail?.requests?.some(
    (request: any) => request.userId === userId
  );
  const isUserGotApprovedDeliverables = worktaskDetail?.workProgress?.some(
    (progress: any) =>
      progress.user === userId && progress.status === "SUBMISSION_APPROVED"
  );
  const isShowRateCollaborator =
    // isUserGotApprovedDeliverables &&
    isEveryCollabSubmitted &&
    !(worktaskDetail?.isSubmittedRatings?.length > 0) &&
    !isCollaboratorRatingFinish &&
    !isProjectCreator;
  const isShowFinishPackage =
    worktaskDetail.workStatus === "SUBMITTED" &&
    worktaskDetail.finishStatus === "OPEN" &&
    isProjectCreator;
  const isRateOther =
    worktaskDetail?.isSubmittedRatings?.length > 0 ? true : false;
  const isFinish = worktaskDetail?.finishStatus === "SUCCESS";
  const isObserver = worktaskDetail?.observers?.includes(userId);

  // const getStatusBtn = () => {
  //   if (
  //     isAlreadyRequest &&
  //     !isProjectCreator &&
  //     !isAlreadyCollaborator &&
  //     !isObserver
  //   )
  //     return (
  //       <PrimaryButton width="158px" height="32px">
  //         <InterestIcon />
  //         Express Interest
  //       </PrimaryButton>
  //     );
  //   return <Applied>Applied</Applied>;

  //   // return (
  //   //   <Button width="152px" height="32px" color="#080F6C" background="#FFCE07">
  //   //     <PendingIcon />
  //   //     Pending Review
  //   //   </Button>
  //   // );
  //   // return <NotAccepted>Not accepted</NotAccepted>;
  // };

  const isStartWork = useMemo(() => {
    const workTaskAvailable =
      worktaskDetail?.workStatus === "INPROGRESS" ||
      worktaskDetail?.workStatus === "OPEN";
    return (
      worktaskDetail?.startDate <= new Date() &&
      workTaskAvailable &&
      isAlreadyCollaborator &&
      !isSubmitedDeliverables &&
      !isCanSubmitDeliverables &&
      !isUserGotApprovedDeliverables &&
      !isFinish &&
      !isObserver
    );
  }, [
    isAlreadyCollaborator,
    isCanSubmitDeliverables,
    isFinish,
    isObserver,
    isSubmitedDeliverables,
    isUserGotApprovedDeliverables,
    worktaskDetail?.startDate,
    worktaskDetail?.workStatus,
  ]);

  return (
    <Container>
      <CollaboratorRating visible={openRate} setVisible={toggleOpenRate} />
      <Wrap>
        <BackgroundImage
          src={WorktasksInsideBackground}
          alt="WorktasksInside background"
        />
        <HeadWrap>
          <Breadcrumb items={items} />
          <Head>
            <div>
              <Wrap>
                <Title title={worktaskDetail?.name}>
                  {handleLongText(worktaskDetail?.name, 60)}
                </Title>
                <Status>{worktaskDetail?.workStatus}</Status>
                {isFinish || (
                  <EndTime>
                    Ends on {dayjs(worktaskDetail?.endDate).format("DD MMM YY")}
                  </EndTime>
                )}
              </Wrap>
              <Gap />
              <Wrap>
                <SponsoredBy>Sponsored by</SponsoredBy>
                <FlexBox gap="5px">
                  {worktaskDetail?.organizations?.map((org: any, i: number) => (
                    <Link
                      to={ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(
                        ":id",
                        org._id
                      )}
                      title={org?.name}
                      key={i}
                    >
                      <AvatarSponsor src={org?.avatar} alt="Avatar" />
                      {/* <OrgName>{org?.name}</OrgName> */}
                    </Link>
                  ))}
                </FlexBox>
              </Wrap>
            </div>
            <FlexBox gap="10px">
              {isShowRateCollaborator && (
                <PrimaryButton onClick={toggleOpenRate}>
                  Rate Collaborators
                </PrimaryButton>
              )}
              {isRateOther && (
                <PrimaryButton>
                  You have rated other collaborators
                </PrimaryButton>
              )}
              {isShowFinishPackage && (
                <PrimaryButton onClick={handleFinishWorktask}>
                  Finish Worktask
                </PrimaryButton>
              )}
              {/* {isProjectCreator &&
                !isAlreadyRequest &&
                !isWorktaskCompleted &&
                !isFinish && (
                  <SecondaryButton width="158px" height="48px">
                    Withdraw Worktask
                  </SecondaryButton>
                )} */}
              `
              {isStartWork && (
                <PrimaryButton onClick={handleStartWork}>
                  Start Work
                </PrimaryButton>
              )}
              {isCanSubmitDeliverables && !isObserver && (
                <>
                  <Link
                    to={ROUTER_CONSTANTS.PROJECT.DELIVER_WORK.replace(
                      ":projectId",
                      param.projectId || ""
                    ).replace(":packageId", param.packageId || "")}
                  >
                    <PrimaryButton>Deliver Work</PrimaryButton>
                  </Link>
                </>
              )}
            </FlexBox>
          </Head>
        </HeadWrap>
      </Wrap>
      <BodyWrap>
        <GitWrap>
          <Background />
          <Git>
            <GitBtns>
              {worktaskDetail?.githubURL && (
                <a
                  href={worktaskDetail?.githubURL}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button
                    width="159px"
                    height="32px"
                    background="rgba(84, 162, 88, 0.16)"
                    color="#54A258"
                  >
                    <GitIcon />
                    View on GitHub
                  </Button>
                </a>
              )}

              {worktaskDetail?.gitbookUrl && (
                <a
                  href={worktaskDetail?.gitbookUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button
                    width="159px"
                    height="32px"
                    background="rgba(100, 102, 217, 0.16)"
                    color="#6466D9"
                  >
                    <GitBookIcon />
                    View on GitBook
                  </Button>
                </a>
              )}
            </GitBtns>
            {(isAlreadyCollaborator || isProjectCreator || isObserver) && (
              <Link
                to={ROUTER_CONSTANTS.PROJECT.BOARD.replace(
                  ":projectId",
                  param.projectId || ""
                ).replace(":packageId", param.packageId || "")}
              >
                <PrimaryButton width="150px" height="36px">
                  Project Board
                </PrimaryButton>
              </Link>
            )}

            <label />
            {!isAlreadyRequest &&
              !isProjectCreator &&
              !isAlreadyCollaborator &&
              !isObserver && (
                <Link
                  to={ROUTER_CONSTANTS.PROJECT.EXPRESS_INTEREST.replace(
                    ":projectId",
                    param.projectId || ""
                  ).replace(":packageId", param.packageId || "")}
                >
                  <PrimaryButton width="158px" height="36px">
                    <InterestIcon />
                    Express Interest
                  </PrimaryButton>
                </Link>
              )}

            {isAlreadyRequest && !isProjectCreator && !isObserver && (
              <Button
                width="152px"
                height="32px"
                color="#080F6C"
                background="#FFCE07"
              >
                <PendingIcon />
                Pending Review
              </Button>
            )}

            {worktaskDetail?.startDate <= new Date() &&
              worktaskDetail?.workStatus !== "DELIVERED" &&
              isAlreadyCollaborator &&
              // !isSubmitedDeliverables &&
              // !isCanSubmitDeliverables &&
              // !isUserGotApprovedDeliverables &&
              !isFinish &&
              !isObserver && <Applied>Applied</Applied>}

            {/* {getStatusBtn("Applied")} */}
          </Git>
        </GitWrap>
        <div>
          <DesTitle>Description</DesTitle>
          <DesText>{worktaskDetail?.description}</DesText>
          <DesTitle>Context</DesTitle>
          <DesText>{worktaskDetail?.context}</DesText>
          <DesTitle>Acceptance Criteria</DesTitle>
          <DesText>{worktaskDetail?.acceptanceCriteria}</DesText>
          <DesTitle>Reference </DesTitle>
          <DesText>{worktaskDetail?.reference}</DesText>
        </div>
        {(isObserver || isProjectCreator) && (
          <div>
            <WorkPlansTitle>
              Requested Collaborators - Work Plans
            </WorkPlansTitle>
            {joinRequests?.userRequestsWithDetail?.length ? (
              joinRequests?.userRequestsWithDetail?.map(
                (item: any, i: number) => (
                  <WorkPlansWrap key={i}>
                    <WorkPlansHead>
                      <PersonWrap>
                        <AvatarAntd src={item?.userDetails?.profilePicture} />
                        <div>
                          <div>
                            {item?.userDetails?.fullName ||
                              item?.userDetails?.emailId}{" "}
                            <WrapTag background="#E94600" color="#FFF">
                              {item?.userDetails?.skills?.level} Contributor
                            </WrapTag>
                          </div>
                          <span>An Hour Ago</span>
                        </div>
                      </PersonWrap>
                      <Techs>
                      </Techs>
                    </WorkPlansHead>
                    <WorkPlans>
                      <div>Work Plan</div>
                      <p>{item.coverLetter}</p>
                      <p>{item.actionPlan}</p>
                      <p>{item.expectedDeliverables}</p>
                    </WorkPlans>
                  </WorkPlansWrap>
                )
              )
            ) : (
              <DesText>Worktask does not have any request.</DesText>
            )}
          </div>
        )}

        <div>
          <WorkPlansTitle>Accepted Applicants: </WorkPlansTitle>
          {worktaskDetail?.collaboratorsDetails?.length ? (
            <ApplicantsWrap>
              {worktaskDetail?.collaboratorsDetails?.map(
                (item: any, i: number) => (
                  <Item key={i}>
                    <AvatarWrap>
                      <AvatarAntd shape="square" src={item?.profilePicture} />
                      <div>{item?.fullName || item?.emailId}</div>
                    </AvatarWrap>
                    <ItemTextWrap>Contributor Applied</ItemTextWrap>
                    <ItemText>An Hour Ago</ItemText>
                  </Item>
                )
              )}
            </ApplicantsWrap>
          ) : (
            <DesText>Worktask does not have any accepted applicants.</DesText>
          )}
        </div>
      </BodyWrap>
    </Container>
  );
};

export default WorktasksInside;
