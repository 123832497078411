export const InviteIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#9C8AC1" fillOpacity="0.16" />
    <path
      d="M29 15C29.7652 15 30.5015 15.2923 31.0583 15.8173C31.615 16.3422 31.9501 17.0601 31.995 17.824L32 18V22.35L32.594 22.086C33.208 21.813 33.916 22.236 33.994 22.884L34 23V31C34.0002 31.5046 33.8096 31.9906 33.4665 32.3605C33.1234 32.7305 32.6532 32.9572 32.15 32.995L32 33H16C15.4954 33.0002 15.0094 32.8096 14.6395 32.4665C14.2695 32.1234 14.0428 31.6532 14.005 31.15L14 31V23C14 22.328 14.675 21.853 15.297 22.045L15.407 22.086L16 22.35V18C16 17.2348 16.2923 16.4985 16.8173 15.9417C17.3422 15.385 18.0601 15.0499 18.824 15.005L19 15H29ZM29 17H19C18.7348 17 18.4804 17.1054 18.2929 17.2929C18.1054 17.4804 18 17.7348 18 18V23.239L24 25.906L30 23.239V18C30 17.7348 29.8946 17.4804 29.7071 17.2929C29.5196 17.1054 29.2652 17 29 17ZM24 20C24.2549 20.0003 24.5 20.0979 24.6854 20.2728C24.8707 20.4478 24.9822 20.687 24.9972 20.9414C25.0121 21.1958 24.9293 21.4464 24.7657 21.6418C24.6021 21.8373 24.3701 21.9629 24.117 21.993L24 22H22C21.7451 21.9997 21.5 21.9021 21.3146 21.7272C21.1293 21.5522 21.0178 21.313 21.0028 21.0586C20.9879 20.8042 21.0707 20.5536 21.2343 20.3582C21.3979 20.1627 21.6299 20.0371 21.883 20.007L22 20H24Z"
      fill="#7E00FD"
    />
  </svg>
);
