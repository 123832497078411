import FlexBox from "components/base/Flexbox";
import {
  ProjectHistoryContainer,
  PortfolioCard,
  ProjectAvatar,
  ProjectName,
  ProjectDate,
  // Review,
  EditButton,
  PaymentText,
  BoxButton,
} from "./projectHistory.style";
// import { RatingStarIcon } from "assets/icons/ratingStar.icon";
import { EyeIcon } from "assets/icons/eye.icon";
import { HiddenTransactionIcon } from "assets/icons/hiddenTransaction.icon";
import { SeeMore } from "components/modules/projectGroup/projectGroup.style";
import { DownIcon } from "assets/icons/down.icon";
import { FC, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  getTransactionHistoryProfileAPI,
  updateHiddenTransactionAPI,
} from "api/users";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { BlockchainExplorers, ROUTER_CONSTANTS } from "utils/constant";
import { Avatar, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { EmptyText } from "pages/ProjectDetail/ProjectDetail.style";
import SectionHeader from "../SectionHeader";
import { ProjectHistoryIcon } from "assets/icons/common/projectHistory.icon";
import { setPageLoading } from "redux/slices";
import { IProjectHistoryProps } from "./projectHistory.props";

export const ProjectHistory: FC<IProjectHistoryProps> = ({ profileId }) => {
  const [skip, setSkip] = useState(0);
  const [limit] = useState(10);
  const [transactions, setTransactions] = useState<any>([]);
  const [chunks, setchunks] = useState<any>([]);
  const [seeMoreOff, setSeeMoreOff] = useState(false);
  const [hiddenStatus, setHiddenStatus] = useState<boolean>(false);
  const userId: string = useAppSelector(
    (state: { auth: { user: { _id: any } } }) => state.auth.user?._id
  );
  const location = useLocation();
  const dispatch = useAppDispatch();

  useQuery(
    ["getTransactionHistoryHistory", skip],
    () =>
      getTransactionHistoryProfileAPI({
        userId: userId,
        skip,
        limit,
      }),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          const newChunks = [...chunks];
          newChunks[skip] = res.responseData.records;
          const newTransactions: any = [];
          newChunks.forEach((chunk: any) =>
            chunk?.forEach((trans: any) => newTransactions.push(trans))
          );
          setchunks(newChunks);
          setTransactions(newTransactions);
          if (
            res.responseData.records.length < limit ||
            res.responseData.recordsTotal === newTransactions.length
          )
            setSeeMoreOff(true);
        } else message.error(res.responseMessage);
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
    }
  );

  const { mutate: updateHiddenTransaction } = useMutation(
    updateHiddenTransactionAPI,
    {
      onSuccess: (res) => {
        const { responseCode, responseMessage } = res;
        if (responseCode === 200) {
          message.success(responseMessage);
        } else {
          message.error(responseMessage);
          dispatch(setPageLoading(false));
        }
      },
      onError: (err: AxiosError) => {
        dispatch(setPageLoading(false));
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
    }
  );
  const handelUpdateTransaction = (item: any) => {
    setHiddenStatus(item?.transactions.hidden);
    updateHiddenTransaction({
      txId: item.transactions._id,
      hidden: hiddenStatus ? false : true,
    });
    setHiddenStatus(hiddenStatus ? false : true);
  };

  const navigate = useNavigate();

  return (
    <ProjectHistoryContainer
      $isPreview={location.pathname === ROUTER_CONSTANTS.USER.PROFILE_PREVIEW}
      id="Project History"
    >
      <SectionHeader icon={<ProjectHistoryIcon />} title="Project History" />
      {transactions?.length ? (
        transactions?.map((item: any, index: number) => {
          const selectedBlockchainExplorer =
            BlockchainExplorers[item?.blockchainNetworkDetail?.name];
          return (
            <PortfolioCard key={index}>
              <FlexBox gap="20px">
                <ProjectAvatar>
                  <Avatar src={item.coverImage} alt="avatar" />
                </ProjectAvatar>
                <div>
                  <ProjectName
                    onClick={() =>
                      navigate(
                        ROUTER_CONSTANTS.PROJECT.DETAIL.replace(":id", item._id)
                      )
                    }
                  >
                    {item.projectName}
                  </ProjectName>
                  <ProjectDate>
                    By{"  "}
                    {item.initiatorDetail.username ||
                      item.initiatorDetail.emailId.split("@")[0]}
                  </ProjectDate>
                </div>
              </FlexBox>
              <div style={{ minWidth: "127px" }}>
                <ProjectDate>Transaction Type</ProjectDate>
                <div>
                  <PaymentText>{item?.transactions?.type}</PaymentText>
                </div>
              </div>

              <div>
                <ProjectDate>Payment</ProjectDate>
                <div>
                  <PaymentText>
                    {parseFloat(item?.transactions?.amount.toFixed(1))}{" "}
                    {item?.transactions?.tokenSymbol?.slice(0, 5)}
                  </PaymentText>
                </div>
              </div>
              <FlexBox justify="flex-end">
                <BoxButton>
                  <a
                    href={`${selectedBlockchainExplorer}${item?.transactions?.txHash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <EditButton>
                      <EyeIcon />
                    </EditButton>
                  </a>
                  {profileId === userId && (
                    <EditButton onClick={() => handelUpdateTransaction(item)}>
                      <HiddenTransactionIcon />
                    </EditButton>
                  )}
                </BoxButton>
              </FlexBox>
            </PortfolioCard>
          );
        })
      ) : (
        <EmptyText>You have no project history</EmptyText>
      )}
      {transactions?.length > 0 && !seeMoreOff && (
        <SeeMore
          onClick={() => {
            setSkip(skip + 1);
          }}
          style={{ color: "#7e00fd" }}
        >
          See more <DownIcon />
        </SeeMore>
      )}
    </ProjectHistoryContainer>
  );
};
