export const getPluralText = (currNumber: number, text: string) => {
  const baseWord = `${currNumber} ${text}`;

  return currNumber > 1 ? `${baseWord}s` : baseWord;
};

export const formatTimeCommitment = (timeToFormat: number) => {
  if (timeToFormat < 24) return getPluralText(timeToFormat, "hour");
  const limitHourTransfer = [720, 168, 24]; // 1 month = 30 days = 720 hours, 1 week = 7 days = 168 hours, 1 day = 24 hours
  const dates = ["month", "week", "day", "hour"];
  const output: { name: string; value: number }[] = [];
  let initialTime = timeToFormat;

  for (let i = 0; i < limitHourTransfer.length; i++) {
    const quotient = Math.floor(initialTime / limitHourTransfer[i]);
    output.push({ name: dates[i], value: quotient });
    initialTime -= quotient * limitHourTransfer[i];
  }

  return [...output, { name: "hour", value: initialTime }]
    .filter((cur) => cur.value !== 0)
    .map((cur) => getPluralText(cur.value, cur.name))
    .join(" ");
};
