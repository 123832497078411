import { useQuery } from "@tanstack/react-query";
import { getMasterByTypeAPI } from "api/users";
import { BuilderSkillIcon } from "assets/icons/common/builderSkill.icon";
import {
  FormSectionWrapper,
  StyledFormItem,
  StyledSelect,
  StyledTextArea,
} from "pages/ProfileUser/profileUserInner.style";
import { FC } from "react";
import { MASTER_TYPES } from "utils/constant";
import { maxLength_1000, stringRequired } from "utils/validatorRules";
import SectionHeader from "../SectionHeader";
import { IBuilderProfileProps } from "./aboutYourself.props";
import { Container } from "./aboutYourself.style";

const requiredMark = <span></span>;

const AboutYourself: FC<IBuilderProfileProps> = () => {
  const { data: countriesRes } = useQuery(
    ["getMasterSkills"],
    () => getMasterByTypeAPI(MASTER_TYPES.COUNTRY),
    {
      refetchOnWindowFocus: false,
    }
  );

  const countries = countriesRes?.responseData?.values || [];

  return (
    <FormSectionWrapper id="About Yourself">
      <SectionHeader icon={<BuilderSkillIcon />} title="About Yourself" />
      <Container>
        <StyledFormItem
          name={["builderProfile", "about"]}
          label={<span>About {requiredMark}</span>}
          rules={[stringRequired, maxLength_1000]}
        >
          <StyledTextArea placeholder="Details about yourself" />
        </StyledFormItem>
        <StyledFormItem
          name={["builderProfile", "description"]}
          label={<span>What describes you the best? {requiredMark}</span>}
          rules={[stringRequired, maxLength_1000]}
        >
          <StyledTextArea placeholder="Describes" />
        </StyledFormItem>
        <StyledFormItem
          label={<span>Based in</span>}
          name={["builderProfile", "country"]}
        >
          <StyledSelect
            showSearch
            placeholder="Based in"
            getPopupContainer={(node) => node?.parentNode as HTMLElement}
          >
            {countries.map((country: any, key: number) => (
              <StyledSelect.Option key={key} value={country.name}>
                {country.name}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
        </StyledFormItem>
      </Container>
    </FormSectionWrapper>
  );
};

export default AboutYourself;
