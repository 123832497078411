import { FC } from "react";

export const HiddenTransactionIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.57199 3.42829H9.42913C9.42913 3.04941 9.27862 2.68605 9.01071 2.41814C8.7428 2.15023 8.37944 1.99972 8.00056 1.99972C7.62168 1.99972 7.25831 2.15023 6.99041 2.41814C6.7225 2.68605 6.57199 3.04941 6.57199 3.42829V3.42829ZM5.71484 3.42829C5.71484 2.82208 5.95566 2.2407 6.38431 1.81205C6.81297 1.38339 7.39435 1.14258 8.00056 1.14258C8.60677 1.14258 9.18815 1.38339 9.6168 1.81205C10.0455 2.2407 10.2863 2.82208 10.2863 3.42829H13.8577C13.9714 3.42829 14.0804 3.47345 14.1607 3.55382C14.2411 3.63419 14.2863 3.7432 14.2863 3.85686C14.2863 3.97053 14.2411 4.07954 14.1607 4.15991C14.0804 4.24028 13.9714 4.28544 13.8577 4.28544H13.1091L12.4137 12.63C12.3631 13.237 12.0862 13.8029 11.638 14.2154C11.1897 14.6279 10.6028 14.8568 9.9937 14.8569H6.00741C5.39827 14.8568 4.81138 14.6279 4.36313 14.2154C3.91488 13.8029 3.63801 13.237 3.58742 12.63L2.89199 4.28544H2.14342C2.02975 4.28544 1.92074 4.24028 1.84037 4.15991C1.76 4.07954 1.71484 3.97053 1.71484 3.85686C1.71484 3.7432 1.76 3.63419 1.84037 3.55382C1.92074 3.47345 2.02975 3.42829 2.14342 3.42829H5.71484ZM7.14341 6.71401C7.14341 6.60034 7.09826 6.49133 7.01789 6.41096C6.93752 6.33059 6.82851 6.28543 6.71484 6.28543C6.60118 6.28543 6.49217 6.33059 6.4118 6.41096C6.33143 6.49133 6.28627 6.60034 6.28627 6.71401V11.5711C6.28627 11.6848 6.33143 11.7938 6.4118 11.8742C6.49217 11.9546 6.60118 11.9997 6.71484 11.9997C6.82851 11.9997 6.93752 11.9546 7.01789 11.8742C7.09826 11.7938 7.14341 11.6848 7.14341 11.5711V6.71401ZM9.28627 6.28543C9.17261 6.28543 9.0636 6.33059 8.98323 6.41096C8.90285 6.49133 8.8577 6.60034 8.8577 6.71401V11.5711C8.8577 11.6848 8.90285 11.7938 8.98323 11.8742C9.0636 11.9546 9.17261 11.9997 9.28627 11.9997C9.39994 11.9997 9.50895 11.9546 9.58932 11.8742C9.66969 11.7938 9.71484 11.6848 9.71484 11.5711V6.71401C9.71484 6.60034 9.66969 6.49133 9.58932 6.41096C9.50895 6.33059 9.39994 6.28543 9.28627 6.28543Z"
        fill="#E94600"
      />
    </svg>
  );
};
