import { useMutation, useQuery } from "@tanstack/react-query";
import { Col, Form, message, Row, UploadProps } from "antd";
import { getDataMastersAPI, postUploadsAPI } from "api/users";
import { CreateProjectIcon } from "assets/images/createProject";
import PrimaryButton from "components/base/primaryButton";
import React, { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  maxLength_255,
  maxLength_60,
  requiredRules,
  stringRequired,
  // urlRules,
} from "utils/validatorRules";
import {
  StyledInput,
  StyledTextArea,
  BigLabel,
  LabelDescription,
  StyledUpload,
  StyledSelect,
  NoFileText,
  Container,
  ChooseImageButton,
  CoverImage,
  AddAttributeBtn,
  RemoveBtn,
} from "./createNFt.style";
import axios, { AxiosError } from "axios";
import { setPageLoading } from "redux/slices";
import { useEthers } from "@usedapp/core";
import { acceptImage, validateImage } from "utils/regex";
import { ICourseOverviewProps } from "./createNFt.props";
// import OutlinedButton from "components/base/outlinedButton";
import { handleMessageErrorSC } from "web3/contract";
import { IBlockchainNetwork, INFTForm } from "api/course";
import { getIPFSUrlFromPath } from "utils/ipfs";
import * as ipfs from "utils/ipfs";
import { createNFTSC } from "web3/learnToEarnContract";
import type { SelectProps } from "antd";
// import { DeleteSponsorTagIcon } from "assets/icons/delete.icon";
import { TrashIcon } from "assets/icons/trash.icon";
import { BtnContainer } from "../CreateToken/createToken.style";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import { BlockchainNetworks } from "utils/constant";
import { scrollToFirstErrorSmooth } from "utils/scrollToSection";

const options: SelectProps["options"] = [];

const CreateNFT: FC<ICourseOverviewProps> = ({ courseForm, onClose }) => {
  const [form] = Form.useForm();
  const { account } = useEthers();
  const dispatch = useAppDispatch();

  const fileRef = useRef<any>(null);
  const spinCoverRef = useRef<any>(false);
  const walletAddress: string = useAppSelector(
    (state) => state.auth.user?.walletAddress
  );
  const { handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  const [loading, setLoading] = useState(false);
  const [fileNameCover, setFileNameCover] = useState<string>();

  const { data: dataNetworks } = useQuery(
    ["getDataNetworks"],
    () => getDataMastersAPI({ type: "Blockchain Networks" }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const blockchainNetworksSelected: IBlockchainNetwork[] =
    dataNetworks?.responseData ? dataNetworks.responseData.values : [];

  const upLoadCoverImage: UploadProps = {
    accept: acceptImage,
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isImage = validateImage.test(file.name);
      if (!isImage) {
        message.error("You can only upload Image!");
        return false;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        const fileTag = file.name.split(".").pop();
        spinCoverRef.current = true;
        fileRef.current = file;
        postUploads({
          fileName: file.name,
          fileType: `image/${fileTag}`,
        });

        return false;
      }
    },
  };

  const { mutate: postUploads } = useMutation(postUploadsAPI, {
    onSuccess: async (rsUpload: any) => {
      dispatch(setPageLoading(true));
      await axios
        .put(rsUpload.signedRequest, fileRef.current)
        .then((rs: any) => {
          if (spinCoverRef.current) {
            setFileNameCover(rsUpload.url);
            spinCoverRef.current = false;
            dispatch(setPageLoading(false));
          }
        })
        .catch((err: AxiosError) => {
          message.error("Error uploading: " + err.message);
        });
    },
    onError: (err: AxiosError) => {
      message.error(err.message);
    },
  });

  useEffect(() => {
    form.setFieldValue("NFTImage", fileNameCover);
  }, [fileNameCover, form]);

  const handleSubmit = async (values: INFTForm) => {
    const selectedNetwork = blockchainNetworksSelected.find(
      (nw) => nw?._id === values.blockchainNetwork
    );
    const curChainId = BlockchainNetworks[selectedNetwork?.name!];

    const NFTAttributes = values.attributes.map((item) => {
      return {
        trait_type: item.name,
        value: item.value,
      };
    });
    values.tags.forEach((tag) =>
      NFTAttributes.push({
        trait_type: "tag",
        value: tag,
      })
    );
    setLoading(true);
    if (!account) {
      setLoading(false);
      message.error("Please connect to wallet");
      return;
    }
    if (walletAddress !== account) {
      setLoading(false);
      message.error("You are connecting to a different wallet");
      return;
    }
    const imageUrl = fileNameCover
      ? getIPFSUrlFromPath(
          fileNameCover
          // "https://soulbound-bucket.s3.amazonaws.com/bored-ape-nfts_497979_jynvqa--1670583007185.jpg"
        )
      : "";
    const nftMetadata = {
      name: values.title,
      attributes: NFTAttributes,
      description: values.description,
      image: imageUrl,
    };
    const nftMetadataUrl = await ipfs
      .uploadNFTMetadata(nftMetadata)
      .then((rs) => rs.ipfsUrl)
      .catch(() => {});
    if (!nftMetadataUrl) {
      setLoading(false);
      return message.error("Please try again, upload cover image failed.");
    }
    try {
      await handleSwitchNetwork(curChainId);

      let res = await createNFTSC({
        name: values.title,
        uri: nftMetadataUrl,
        symbol: values.symbol,
      });
      const contractAddress = (res as any).event?.events[0]
        ? (res as any).event?.events[0]?.address
        : "";

      courseForm.setFieldsValue({
        contractAddress,
        isExistingNFT: true,
        nftName: values.title,
        nftSymbol: values.symbol,
        blockchainNetwork: selectedNetwork?._id,
      });
      setLoading(false);
      onClose();
    } catch (err: any) {
      setLoading(false);
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  return (
    <>
      <Container>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          scrollToFirstError={scrollToFirstErrorSmooth}
          initialValues={{
            attributes: [
              {
                name: "",
                value: "",
              },
            ],
          }}
        >
          <Form.Item
            name="NFTImage"
            rules={[requiredRules]}
            label={
              <div>
                <BigLabel>Upload NFT image</BigLabel>
                <LabelDescription>
                  Recommended size: 500x1200 .jpg .png | Max size: 10MB
                </LabelDescription>
              </div>
            }
          >
            <StyledUpload
              {...upLoadCoverImage}
              listType="picture-card"
              maxCount={1}
              className={
                fileNameCover ? "upload-image noBorder" : "upload-image"
              }
            >
              {fileNameCover ? (
                <CoverImage height="100px" width="100px" src={fileNameCover} />
              ) : (
                <div>
                  <ChooseImageButton>Choose Image</ChooseImageButton>
                  <NoFileText>No File Chosen</NoFileText>
                </div>
              )}
            </StyledUpload>
          </Form.Item>
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <Form.Item
                label="Title"
                name="title"
                rules={[stringRequired, maxLength_60]}
              >
                <StyledInput placeholder="NFT title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Symbol"
                name="symbol"
                rules={[stringRequired, maxLength_60]}
              >
                <StyledInput placeholder="NFT symbol" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Description"
            name="description"
            rules={[stringRequired, maxLength_255]}
          >
            <StyledTextArea placeholder="Tell us what your course is. Keep it short and sweet!" />
          </Form.Item>
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <Form.Item
                label="Network"
                name="blockchainNetwork"
                rules={[requiredRules]}
              >
                <StyledSelect placeholder="Blockchain Network">
                  {blockchainNetworksSelected.map((network) => (
                    <StyledSelect.Option
                      value={network?._id}
                      key={network?._id}
                    >
                      {network?.name}
                    </StyledSelect.Option>
                  ))}
                </StyledSelect>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tags" name="tags" rules={[requiredRules]}>
                <StyledSelect mode="tags" options={options} />
              </Form.Item>
            </Col>
          </Row>
          <BigLabel>Attributes</BigLabel>
          <Form.List name="attributes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={[16, 16]}>
                    <Col span={10}>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[stringRequired]}
                        label="Name"
                      >
                        <StyledInput placeholder="Name" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[stringRequired]}
                        label="Value"
                      >
                        <StyledInput placeholder="value" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <RemoveBtn
                        icon={<TrashIcon />}
                        onClick={() => remove(name)}
                      >
                        Remove
                      </RemoveBtn>
                    </Col>
                  </Row>
                ))}
                <AddAttributeBtn onClick={() => add()}>
                  + Add Attribute
                </AddAttributeBtn>
              </>
            )}
          </Form.List>
          <BtnContainer>
            <Form.Item>
              <PrimaryButton
                htmlType="submit"
                loading={loading}
                icon={<CreateProjectIcon />}
              >
                Create NFT
              </PrimaryButton>
            </Form.Item>
          </BtnContainer>
        </Form>
      </Container>
    </>
  );
};

export default CreateNFT;
