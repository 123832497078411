import {
  AlphaIcon,
  CollaboratorIcon,
  DeadlineIcon,
  ExpectedEffortRequiredIcon,
  ExperienceIcon,
  FlatIcon,
  PackageIcon,
  TaskIcon,
  TypeOfTaskIcon,
} from "assets/images/worktasks-inside";
import AvatarAntdGroup from "components/modules/avatarList";
import moment from "moment";
import { FC, useState } from "react";
import { timeRemaining } from "utils/time";
import { IWorktaskInfoProps } from "../WorktasksInside.props";
import {
  AcceptCollabWrapper,
  Bg,
  BgItem,
  Deadline,
  Infor,
  InforItem,
  InforItemWrap,
} from "../WorktasksInside.style";
import { InfoModal } from "components/modules/InfoModal";

const WorktaskInfo: FC<IWorktaskInfoProps> = ({ worktaskDetail, project }) => {
  const [popupIndex, setPopupIndex] = useState(0);

  function getTime(startDate: number, hourDuration: number) {
    function getAbsoluteMonths(momentDate: moment.Moment) {
      const days = Number(momentDate.format("DD"));
      const months = Number(momentDate.format("MM"));
      const years = Number(momentDate.format("YYYY"));
      return [months + years * 12, days];
    }
    if (!startDate) {
      return [0, 0, 0, 0];
    } else {
      const start = moment(startDate);
      const end = moment(startDate).add(hourDuration, "hours");

      const startTimes = getAbsoluteMonths(start);
      const endTimes = getAbsoluteMonths(end);

      const gap = startTimes[1] - endTimes[1] > 0 ? 1 : 0;

      const months = endTimes[0] - startTimes[0] - gap;

      const momentWithMonths = moment(startDate).add(months, "months");

      const duration = moment.duration(end.diff(momentWithMonths));
      const diffInHours = duration.asHours();
      const weeks = Math.floor(duration.asWeeks());
      const days = Math.floor(duration.asDays()) - weeks * 7;
      const hours = diffInHours - weeks * 7 * 24 - days * 24;

      return [months, weeks, days, hours];
    }
  }

  function expectedTimeCommitment(startDate: number, hourDuration: number) {
    const times = getTime(startDate, hourDuration);
    let date = "";
    if (times[0]) date += times[0] + " months ";
    if (times[1]) date += times[1] + " weeks ";
    if (times[2]) date += times[2] + " days ";
    if (times[3]) date += times[3] + " hours ";

    return date.slice(0, -1);
  }

  const totalBudget =
    +(worktaskDetail?.minimumCost || 0) * +(worktaskDetail?.memberLimit || 0) +
    +(worktaskDetail?.observerFees || 0) +
    +(worktaskDetail?.bonus || 0);
  const truncatedSymbol = project?.tokenSymbol?.slice(0, 5);

  const infoList = [
    {
      title: "What is Total Budget?",
      description:
        "This refers to the overall budget allocated for the WorkTask. It represents the total amount of funds available to compensate contributors for their work on this task.",
    },
    {
      title: "What is Minimum Guaranteed Payment?",
      description:
        "This represents the minimum amount that will be paid to contributors upon successful completion of the WorkTask. It ensures that contributors receive at least a certain level of compensation for their efforts.",
    },
    {
      title: "What is Bonus?",
      description:
        "A bonus is an additional incentive that can be offered to contributors for exceptional performance or going above and beyond the task requirements. It is an extra reward on top of the guaranteed payment, encouraging contributors to strive for excellence.",
    },
  ];
  return (
    <>
      <Bg>
        <BgItem marginRight={66}>
          <div>
            {totalBudget} {truncatedSymbol || project?.tokenName}
          </div>
          <span>
            Total Budget <AlphaIcon onclick={() => setPopupIndex(1)} />
          </span>
        </BgItem>
        <BgItem marginRight={48.87}>
          <div>
            {worktaskDetail?.minimumCost +
              " " +
              (truncatedSymbol || project?.tokenName)}
          </div>
          <span>
            Minimum Guaranteed Payment
            <AlphaIcon onclick={() => setPopupIndex(2)} />
          </span>
        </BgItem>
        <BgItem marginRight={0}>
          <div>
            {worktaskDetail?.bonus +
              " " +
              (truncatedSymbol || project?.tokenName)}
          </div>
          <span>
            Bonus <AlphaIcon onclick={() => setPopupIndex(3)} />
          </span>
        </BgItem>
      </Bg>
      <Infor>
        <InforItemWrap>
          <InforItem>
            <div>
              <TypeOfTaskIcon />
              <span>Type of Work</span>
            </div>

            <div>{project?.category?.name.toUpperCase()}</div>
          </InforItem>
          <InforItem>
            <div>
              <CollaboratorIcon />
              <span>Collaborator</span>
            </div>
            <div>
              {worktaskDetail?.memberLimit ||
                worktaskDetail?.collaborators?.length}{" "}
              total collaborator
              <AcceptCollabWrapper>
                {worktaskDetail?.collaboratorsDetails?.length > 0 && (
                  <AvatarAntdGroup
                    collabs={
                      worktaskDetail?.collaboratorsDetails?.map(
                        (collab: any) => {
                          return {
                             url: collab?.profilePicture,
                            username:
                              collab?.username ||
                              (collab?.emailId ?? "").split("@")[0],
                            id: collab?._id,
                          };
                        }
                      ) || []
                    }
                    size={20}
                    max={6}
                  />
                )}
                <span>
                  {worktaskDetail?.collaborators?.length || 0} accepted
                </span>
              </AcceptCollabWrapper>
            </div>
          </InforItem>
        </InforItemWrap>
        <InforItemWrap>
          <InforItem>
            <div>
              <ExperienceIcon />
              <span>Experience Level</span>
            </div>
            <div>{worktaskDetail?.expertiseLevel?.name}</div>
          </InforItem>
          <InforItem>
            <div>
              <PackageIcon />
              <span>Worktask Type</span>
            </div>
            <div>{worktaskDetail?.projectType?.name}</div>
          </InforItem>
        </InforItemWrap>
        <InforItemWrap>
          <InforItem>
            <div>
              <TaskIcon />
              <span>Type of Task</span>
            </div>
            <div>{worktaskDetail?.issueType?.name}</div>
          </InforItem>
          <InforItem>
            <div>
              <ExpectedEffortRequiredIcon />
              <span>Expected Effort Required</span>
            </div>
            <div>
              {expectedTimeCommitment(
                worktaskDetail?.startDate,
                worktaskDetail?.expectedTimeCommitment as number
              )}
            </div>
          </InforItem>
        </InforItemWrap>

        <InforItemWrap>
          <InforItem>
            <div>
              <FlatIcon />
              <span>Opened</span>
            </div>
            <div>{moment(worktaskDetail?.startDate).fromNow()}</div>
          </InforItem>
          <InforItem>
            <div>
              <DeadlineIcon />
              <span>Deadline</span>
            </div>
            <div>
              <Deadline
                dangerouslySetInnerHTML={{
                  __html: timeRemaining(
                    worktaskDetail?.endDate as number,
                    worktaskDetail?.startDate
                  ),
                }}
              ></Deadline>
            </div>
          </InforItem>
        </InforItemWrap>
      </Infor>

      <InfoModal
        visible={popupIndex !== 0}
        onCancel={() => setPopupIndex(0)}
        description={
          infoList[popupIndex - 1] ? infoList[popupIndex - 1].description : ""
        }
        title={infoList[popupIndex - 1] ? infoList[popupIndex - 1].title : ""}
      />
    </>
  );
};

export default WorktaskInfo;
