import { Button } from "antd";
import styled from "styled-components";

export const ButtonAntd = styled(Button)<{
  width?: string | undefined;
  height?: string | undefined;
  color?: string | undefined;
  background?: string | undefined;
  border?: string | undefined;
}>`
  border-radius: 0.25rem;
  background: ${(p) => p.background || "rgba(156, 138, 193, 0.16)"};
  color: ${(p) => p.color};
  /* outline: none; */
  border: ${(p) => p.border || "none"};
  font-weight: 500;
  line-height: 115%;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.width || "auto"};
  height: ${(p) => p.height || "auto"};
  padding: 8px 16px;
  &:hover,
  &:focus {
    background: ${(p) => p.background || "rgba(156, 138, 193, 0.16)"};
    color: ${(p) => p.color};
    /* filter: brightness(80%); */
    border: ${(p) => p.border || "none"};
  }

  svg {
    margin-right: 0.25rem;
  }
`;
