import {
  CenteredText,
  Container,
  Content,
  ContentHeader,
  ContentWrapper,
  GeneralItem,
  GeneralWrapper,
  SectionWrapper,
} from "./general.style";
import {
  SectionDescriptionStyled,
  SectionHeader,
  SectionTitle,
} from "../Introduction/introduction.style";
import { GENERAL_INFO } from "utils/homepage";

const General = () => {
  return (
    <Container>
      <CenteredText>
        <SectionHeader>
          <SectionTitle>Radically Inclusive Web3 Ecosystem</SectionTitle>
          <SectionDescriptionStyled>
            Whether you're a developer, marketer, or designer,{" "}
            <span>BUIDL</span> has everything you need to take your skills to
            the next level and contribute to the future of the web.
          </SectionDescriptionStyled>
        </SectionHeader>
      </CenteredText>
      <SectionWrapper>
        {GENERAL_INFO.map((item, id) => (
          <GeneralItem key={id} bgColor={item.bgColor}>
            <GeneralWrapper>
              <img src={item.icon} alt={`${item.title}-img`} />
              <ContentWrapper>
                <ContentHeader>{item.title}</ContentHeader>
                <Content>{item.content}</Content>
              </ContentWrapper>
            </GeneralWrapper>
          </GeneralItem>
        ))}
      </SectionWrapper>
      {/* <GeneralFully>
        <CenteredText>
          <SectionHeader>
            <SectionTitle>
              Fully Gamified Processes <br /> to Infuse Fun & Rewards in
              Collaboration
            </SectionTitle>
          </SectionHeader>
        </CenteredText>
        <SectionWrapper>
          {GENERAL_FULLY.map((item, id) => (
            <GeneralItemFully key={id}>
              <GeneralWrapper>
                <img src={item.icon} alt={`${item.title}-img`} />
                <ContentWrapper>
                  <ContentHeader>{item.title}</ContentHeader>
                  <Content>{item.content}</Content>
                </ContentWrapper>
              </GeneralWrapper>
            </GeneralItemFully>
          ))}
        </SectionWrapper>
      </GeneralFully> */}
    </Container>
  );
};

export default General;
