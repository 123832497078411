import { Form, message, UploadProps } from "antd";
import { CameraIcon } from "assets/icons/common/camera.icon";
import { CoinGeckoIcon } from "assets/icons/common/coingecko.icon";
import { CMCIcon } from "assets/icons/common/coinmarketcap.icon";
import { GitbookIcon } from "assets/icons/common/gitbook.icon";
import { GithubIcon } from "assets/icons/common/github.icon";
import { LinkedInIcon } from "assets/icons/common/linkedin.icon";
import { MediumIcon } from "assets/icons/common/mediumOrgan.icon";
import { TwitterIcon } from "assets/icons/common/twitter.icon";
import { WebsiteIcon } from "assets/icons/common/website.icon";
import PrimaryButton from "components/base/primaryButton";
import { ButtonText } from "pages/HomePage/components/Banner/banner.style";
import { FC, useRef, useState } from "react";
import {
  ICreateOrganizationPage,
  ICreateOrganizationProps,
} from "./createOrganization.props";
import {
  Heading,
  Title,
  StyledInput,
  StyledTextArea,
  StyledFormItem,
  BigLabel,
  LabelDescription,
  SocialFormGrid,
  LabelWithIcon,
  Container,
  Left,
  Right,
  AvatarUpload,
  AvatarContainer,
  BtnActionWrapper,
  CancelButton,
} from "./createOrganization.style";
import {
  linkedinUrlRules,
  mediumUrlRules,
  requiredRules,
  twitterUrlRules,
  urlRules,
} from "utils/validatorRules";
import { AxiosError } from "axios";
import {
  createOrganisationAPI,
  getOrganizationInfoAPI,
  IOrganization,
  updateOrganizationAPI,
} from "api/users";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AvatarImage } from "pages/ProfileUser/components/BuilderProfile/builderProfile.style";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { acceptImage, validateImage } from "utils/regex";
import { setPageLoading, setUserInfo } from "redux/slices";
import type { RcFile } from "antd/lib/upload";
import { getBase64 } from "utils/getBase64";
import { getUrlFromS3 } from "utils/getUrlFromS3";

const socialUrls = [
  {
    name: ["socialLinks", "personURL"],
    label: (
      <>
        <WebsiteIcon /> Website
      </>
    ),
    placeholder: "Enter your website URL",
    isRequired: true,
  },
  {
    name: ["socialLinks", "mediumURL"],
    label: (
      <>
        <MediumIcon /> Medium
      </>
    ),
    placeholder: "Enter Medium URL",
    isRequired: false,
  },
  {
    name: ["socialLinks", "twitterURL"],
    label: (
      <>
        <TwitterIcon /> Twitter
      </>
    ),
    placeholder: "Enter Twitter URL",
    isRequired: false,
  },
  {
    name: ["socialLinks", "linkedIn"],
    label: (
      <>
        <LinkedInIcon /> LinkedIn
      </>
    ),
    placeholder: "Enter LinkedIn URL",
    isRequired: false,
  },
];

const CreateOrganization: FC<ICreateOrganizationProps> = () => {
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState<string>();
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currUserInfo = useAppSelector((state) => state.auth.user);

  const { mutate: createOrganisation } = useMutation(createOrganisationAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage, responseData } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        form.resetFields();
        const _id = responseData?._id;

        dispatch(
          setUserInfo({
            ...currUserInfo,
            organization: _id,
          })
        );
        if (_id) {
          dispatch(setPageLoading(false));
          navigate(ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(":id", _id));
        } else {
          dispatch(setPageLoading(false));
          navigate("/");
        }
      } else {
        dispatch(setPageLoading(false));
        message.error(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      dispatch(setPageLoading(false));
      const rs: any = err?.response?.data;
      message.error(rs?.responseMessage);
    },
  });

  const { data: orgRes } = useQuery(
    ["getOrganizationData", param.id],
    () => getOrganizationInfoAPI(param.id || ""),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          const curOrg = res?.responseData;

          form.setFieldValue("crypto", curOrg?.crypto);
          form.setFieldValue("name", curOrg?.name);
          form.setFieldValue("overview", curOrg?.overview);
          form.setFieldValue("socialLinks", curOrg?.socialLinks);
          form.setFieldValue("devResources", curOrg?.devResources);
          setFileName(curOrg?.avatar);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      enabled: !!param.id,
      refetchOnWindowFocus: false,
    }
  );
  const organization: IOrganization | undefined = orgRes?.responseData;

  const spinAvatarRef = useRef<any>(false);
  const fileRef = useRef<RcFile | null>(null);

  const upLoadAvatarOrg: UploadProps = {
    accept: acceptImage,
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isImage = validateImage.test(file.name);
      if (!isImage) {
        message.error("You can only upload Image!");
        return false;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        spinAvatarRef.current = true;
        fileRef.current = file;
        setFileName(await getBase64(file));
        return false;
      }
    },
  };

  async function onSubmit(param: ICreateOrganizationPage) {
    dispatch(setPageLoading(true));

    if (!organization?._id && fileRef.current === null) {
      message.error("Please upload your organization image");
      dispatch(setPageLoading(false));
      return;
    }

    const urlFromS3 = fileRef.current
      ? await getUrlFromS3(fileRef.current)
      : organization?.avatar;

    if (!!organization?._id) {
      const organizationData = {
        ...param,
        avatar: urlFromS3,
      };
      const params = {
        organizationId: organization?._id || "",
        organization: organizationData,
      };
      updateOrganization(params);
      return;
    }
    const organizationData = {
      ...param,
      avatar: urlFromS3,
    };
    const payload = { organization: organizationData };
    createOrganisation(payload);
  }

  const { mutate: updateOrganization } = useMutation(updateOrganizationAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage, responseData } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        form.resetFields();
        const _id = responseData?._id;
        if (_id) {
          dispatch(setPageLoading(false));
          navigate(ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(":id", _id));
        } else {
          dispatch(setPageLoading(false));
          navigate("/");
        }
      } else {
        message.error(responseMessage);
        dispatch(setPageLoading(false));
      }
    },
    onError: (err: AxiosError) => {
      dispatch(setPageLoading(false));
      const rs: any = err?.response?.data;
      message.error(rs?.responseMessage);
    },
  });

  return (
    <>
      <Heading>
        {organization ? (
          <div>Update an Organisation</div>
        ) : (
          <div>Create an Organisation</div>
        )}
      </Heading>
      <Container>
        <Left>
          <AvatarContainer>
            <AvatarUpload {...upLoadAvatarOrg} listType="picture-card">
              {fileName ? (
                <AvatarImage height="100%" width="100%" src={fileName} />
              ) : (
                <div>
                  <CameraIcon />
                </div>
              )}
            </AvatarUpload>
            <BigLabel>Logo</BigLabel>
            <LabelDescription>
              Maximum sizes: 240x240 .jpg .png | Max size: 5MB
            </LabelDescription>
          </AvatarContainer>
        </Left>

        <Right>
          <Title>Organisation</Title>
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <StyledFormItem
              label="Your Organisation Name"
              name="name"
              rules={[requiredRules]}
            >
              <StyledInput placeholder="What should we put at the top of your page?" />
            </StyledFormItem>
            <StyledFormItem
              label="Overview"
              name="overview"
              rules={[requiredRules]}
            >
              <StyledTextArea placeholder="Tell us what your organisation is. Keep it short and sweet!" />
            </StyledFormItem>
            <BigLabel>Social Visibility</BigLabel>
            <SocialFormGrid>
              {socialUrls.map((item) => {
                let rules = item?.isRequired
                  ? [requiredRules, urlRules]
                  : [urlRules];
                switch (item.name[1]) {
                  case "mediumURL":
                    rules.push(mediumUrlRules);
                    break;
                  case "twitterURL":
                    rules.push(twitterUrlRules);
                    break;
                  case "linkedIn":
                    rules.push(linkedinUrlRules);
                    break;
                  default:
                    break;
                }
                return (
                  <StyledFormItem
                    key={item.placeholder}
                    label={<LabelWithIcon>{item.label} URL</LabelWithIcon>}
                    name={item.name}
                    rules={rules}
                  >
                    <StyledInput placeholder={item.placeholder} />
                  </StyledFormItem>
                );
              })}
            </SocialFormGrid>

            <BigLabel>Dev Resources</BigLabel>
            <SocialFormGrid>
              <StyledFormItem
                label={
                  <LabelWithIcon>
                    <GithubIcon /> Github URL
                  </LabelWithIcon>
                }
                name={["devResources", "githubURL"]}
                rules={[requiredRules, urlRules]}
              >
                <StyledInput placeholder="Enter Github URL" />
              </StyledFormItem>
              <StyledFormItem
                label={
                  <LabelWithIcon>
                    <GitbookIcon /> Gitbook URL
                  </LabelWithIcon>
                }
                name={["devResources", "documentURL"]}
                rules={[urlRules]}
              >
                <StyledInput placeholder="Enter Gitbook URL" />
              </StyledFormItem>
            </SocialFormGrid>

            <BigLabel>Crypto</BigLabel>
            <SocialFormGrid>
              <StyledFormItem
                label={
                  <LabelWithIcon>
                    <CMCIcon /> CoinMarketCap link
                  </LabelWithIcon>
                }
                name={["crypto", "coinMarketCapLink"]}
                rules={[urlRules]}
              >
                <StyledInput placeholder="Enter your CoinMarketCap link if applicable " />
              </StyledFormItem>
              <StyledFormItem
                label={
                  <LabelWithIcon>
                    <CoinGeckoIcon /> CoinGecko
                  </LabelWithIcon>
                }
                name={["crypto", "coinGeckoLink"]}
                rules={[urlRules]}
              >
                <StyledInput placeholder="Enter your CoinGecko link if applicable" />
              </StyledFormItem>
            </SocialFormGrid>
            <BtnActionWrapper>
              <PrimaryButton width="180px" height="48px" htmlType="submit">
                <ButtonText>
                  {organization
                    ? "Update an Organisation"
                    : "Create an Organisation"}
                </ButtonText>
              </PrimaryButton>

              <CancelButton onClick={() => navigate(-1)}>Cancel</CancelButton>
            </BtnActionWrapper>
          </Form>
        </Right>
      </Container>
    </>
  );
};

export default CreateOrganization;
