import styled from "styled-components";

import { Form, Input, Modal } from "antd";
import { CSSProperties } from "styled-components";
import { Descriptions } from "antd";

export const Container = styled.div``;

export const StyledModal = styled(Modal)<{ padding?: string }>`
  .ant-modal-content {
    border-radius: 0.5rem;
    .ant-modal-body {
      padding: ${(p) => p.padding || "4.5rem 3.5rem"};
    }
  }
`;

export const ModalTitle = styled(Descriptions)`
  .ant-descriptions-header {
    margin-bottom: 0.75rem;

    .ant-descriptions-title {
      font-size: 1.5rem;
    }
  }
`;

export const ModalFormItem = styled(Form.Item)`
  margin-bottom: 4.125rem;

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }

  .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    color: #ff4d4f;
    font-size: 0.875rem;
    line-height: 1;
    content: "*";
  }
`;

export const StyledTextInput = styled(Input)`
  border-radius: 0.25rem;
  padding: 0.6875rem 0.75rem;
`;

export const StyledPasswordInput = styled(Input.Password)`
  border-radius: 0.25rem;
  padding: 0.6875rem 0.75rem;
`;

export const ModalMaskStyle = {
  backgroundColor: "rgba(8, 10, 37, 0.8)",
  // mixBlendMode: "multiply",
  // opacity: 0.9,
} as CSSProperties;
