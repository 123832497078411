import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FC, useRef } from "react";
import { EditorWrapper, ErrorMessage } from "./textEditor.style";
import { FileLoader } from "@ckeditor/ckeditor5-upload/src/filerepository";
import { postUploadsAPI } from "api/users";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { ICkEditor } from "./type";
import "./custom.css";
import CustomEditorBuild from "ckeditor5-custom-build/build/ckeditor";
import { setPageLoading } from "redux/slices";
import { useAppDispatch } from "redux/hooks";

interface TextEditorProps extends Omit<ICkEditor, "editor" | "config"> {
  placeholder?: string;
  border?: string;
  error?: boolean;
  errorMessage?: string;
  witdh?: string;
}

const TextEditor: FC<TextEditorProps> = ({
  placeholder,
  error,
  border,
  errorMessage,
  witdh,
  ...rest
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const { mutateAsync: uploadToS3Mutation } = useMutation(postUploadsAPI);
  const dispatch = useAppDispatch();

  const uploadAdapter = (loader: FileLoader) => {
    return {
      upload: async () => {
        try {
          dispatch(setPageLoading(true));
          const file = await loader.file;
          if (file) {
            return uploadToS3Mutation({
              fileName: file.name,
              fileType: file.type,
            })
              .then(async (res) => {
                return axios.put(res.signedRequest, file).then((response) => {
                  dispatch(setPageLoading(false));
                  return { default: res.url };
                });
              })
              .catch((error) => {
                dispatch(setPageLoading(false));
                console.log(error);
              });
          } else {
            return { default: "" };
          }
        } catch (error: any) {
          console.log(error);
          return { default: error?.message };
        }
      },
      abort: () => {},
    };
  };

  return (
    <EditorWrapper
      border={border!}
      ref={editorRef}
      placeholder={placeholder}
      width={witdh}
    >
      <CKEditor
        editor={CustomEditorBuild}
        onReady={(e: any) => {
          e.plugins.get("FileRepository").createUploadAdapter = (
            loader: any
          ) => {
            return uploadAdapter(loader);
          };
        }}
        config={{
          toolbar: [
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "blockQuote",
            "heading",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "alignment",
            "|",
            "link",
            "insertImage",
            "insertTable",
            "mediaEmbed",
            "subscript",
            "superscript",
            "|",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "|",
            "code",
            "codeBlock",
          ],
          mediaEmbed: {
            previewsInData: true,
          },
        }}
        {...rest}
      />
      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </EditorWrapper>
  );
};

export default TextEditor;
