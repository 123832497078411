export const ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "collaborator",
        type: "address",
      },
      { indexed: false, internalType: "uint256", name: "mgp", type: "uint256" },
    ],
    name: "AddedCollaborator",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "observers",
        type: "address[]",
      },
    ],
    name: "AddedObservers",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "collaborator",
        type: "address",
      },
    ],
    name: "ApprovedCollaborator",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
    ],
    name: "ApprovedProject",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "revertedBudget",
        type: "uint256",
      },
    ],
    name: "CanceledPackage",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "budget",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bonus",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "observerBudget",
        type: "uint256",
      },
    ],
    name: "CreatedPackage",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "budget",
        type: "uint256",
      },
    ],
    name: "CreatedProject",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "budgetLeft",
        type: "uint256",
      },
    ],
    name: "FinishedPackage",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "budgetLeft",
        type: "uint256",
      },
    ],
    name: "FinishedProject",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint8", name: "version", type: "uint8" },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "collaborator",
        type: "address",
      },
      { indexed: false, internalType: "uint256", name: "mgp", type: "uint256" },
      {
        indexed: false,
        internalType: "uint256",
        name: "bonus",
        type: "uint256",
      },
    ],
    name: "PaidCollaboratorRewards",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "collaborator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "PaidObserverFee",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId_",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId_",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "collaborator_",
        type: "address",
      },
    ],
    name: "RemovedCollaborator",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packageId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "observers",
        type: "address[]",
      },
    ],
    name: "RemovedObservers",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "projectId",
        type: "bytes32",
      },
    ],
    name: "StartedProject",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "oldTreasury",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newTreasury",
        type: "address",
      },
    ],
    name: "UpdatedTreasury",
    type: "event",
  },
  {
    inputs: [],
    name: "PCT_PRECISION",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ROYALTY_FEE",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address", name: "_collaborator", type: "address" },
      { internalType: "uint256", name: "_mgp", type: "uint256" },
    ],
    name: "addCollaborator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address[]", name: "_observers", type: "address[]" },
    ],
    name: "addObservers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address", name: "_collaborator", type: "address" },
    ],
    name: "approveCollaborator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address[]", name: "_collaborators", type: "address[]" },
      { internalType: "address[]", name: "_observers", type: "address[]" },
      { internalType: "bool", name: "_workStarted", type: "bool" },
    ],
    name: "cancelPackage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "uint256", name: "_budget", type: "uint256" },
      { internalType: "uint256", name: "_bonus", type: "uint256" },
      { internalType: "uint256", name: "_observerBudget", type: "uint256" },
      { internalType: "uint256", name: "_collaboratorsLimit", type: "uint256" },
      { internalType: "address[]", name: "_observers", type: "address[]" },
    ],
    name: "createPackage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "token_", type: "address" },
      { internalType: "uint256", name: "budget_", type: "uint256" },
    ],
    name: "createProject",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address[]", name: "_collaborators", type: "address[]" },
      { internalType: "address[]", name: "_observers", type: "address[]" },
      { internalType: "uint256[]", name: "_scores", type: "uint256[]" },
    ],
    name: "finishPackage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_projectId", type: "bytes32" }],
    name: "finishProject",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address", name: "_collaborator", type: "address" },
    ],
    name: "getCollaboratorData",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "mgp", type: "uint256" },
          { internalType: "uint256", name: "bonus", type: "uint256" },
          { internalType: "uint256", name: "timeCreated", type: "uint256" },
          { internalType: "uint256", name: "timeMgpApproved", type: "uint256" },
          { internalType: "uint256", name: "timeMgpPaid", type: "uint256" },
          { internalType: "uint256", name: "timeBonusPaid", type: "uint256" },
          { internalType: "bool", name: "isRemoved", type: "bool" },
        ],
        internalType: "struct Collaborator",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address", name: "_collaborator", type: "address" },
    ],
    name: "getCollaboratorRewards",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address", name: "_observer", type: "address" },
    ],
    name: "getObserverData",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "timeCreated", type: "uint256" },
          { internalType: "uint256", name: "timePaid", type: "uint256" },
          { internalType: "bool", name: "isRemoved", type: "bool" },
        ],
        internalType: "struct Observer",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address", name: "_observer", type: "address" },
    ],
    name: "getObserverFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
    ],
    name: "getPackageData",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "budget", type: "uint256" },
          { internalType: "uint256", name: "budgetAllocated", type: "uint256" },
          { internalType: "uint256", name: "budgetPaid", type: "uint256" },
          { internalType: "uint256", name: "budgetObservers", type: "uint256" },
          {
            internalType: "uint256",
            name: "budgetObserversPaid",
            type: "uint256",
          },
          { internalType: "uint256", name: "bonus", type: "uint256" },
          { internalType: "uint256", name: "bonusPaid", type: "uint256" },
          {
            internalType: "uint256",
            name: "collaboratorsPaidBonus",
            type: "uint256",
          },
          { internalType: "uint256", name: "timeCreated", type: "uint256" },
          { internalType: "uint256", name: "timeFinished", type: "uint256" },
          { internalType: "uint256", name: "totalObservers", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalCollaborators",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "collaboratorsLimit",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "approvedCollaborators",
            type: "uint256",
          },
          { internalType: "uint256", name: "timeCanceled", type: "uint256" },
          { internalType: "bool", name: "isActive", type: "bool" },
        ],
        internalType: "struct Package",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "_projectId", type: "bytes32" }],
    name: "getProjectData",
    outputs: [
      {
        components: [
          { internalType: "address", name: "initiator", type: "address" },
          { internalType: "address", name: "token", type: "address" },
          { internalType: "uint256", name: "budget", type: "uint256" },
          { internalType: "uint256", name: "budgetAllocated", type: "uint256" },
          { internalType: "uint256", name: "budgetPaid", type: "uint256" },
          { internalType: "uint256", name: "timeCreated", type: "uint256" },
          { internalType: "uint256", name: "timeFinished", type: "uint256" },
          { internalType: "uint256", name: "totalPackages", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalFinishedPackages",
            type: "uint256",
          },
        ],
        internalType: "struct Project",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "treasury_", type: "address" }],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address", name: "_collaborator", type: "address" },
      { internalType: "bool", name: "_shouldPayMgp", type: "bool" },
    ],
    name: "removeCollaborator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address[]", name: "_observers", type: "address[]" },
    ],
    name: "removeObservers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
    ],
    name: "selfRemove",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "treasury",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_projectId", type: "bytes32" },
      { internalType: "bytes32", name: "_packageId", type: "bytes32" },
      { internalType: "address[]", name: "_observersIn", type: "address[]" },
      { internalType: "address[]", name: "_observersOut", type: "address[]" },
    ],
    name: "updateObservers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "treasury_", type: "address" }],
    name: "updateTreasury",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ERC20ABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "name_",
        type: "string",
      },
      {
        internalType: "string",
        name: "symbol_",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "subtractedValue",
        type: "uint256",
      },
    ],
    name: "decreaseAllowance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "addedValue",
        type: "uint256",
      },
    ],
    name: "increaseAllowance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ERC721ABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "symbol",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
