import { FC } from "react";
import { IButtonPrimaryProps } from "./primaryButton.props";
import { ButtonPrimaryAntd } from "./primaryButton.style";

const PrimaryButton: FC<IButtonPrimaryProps> = ({
  children,
  width,
  height,
  ...props
}: IButtonPrimaryProps) => {
  return (
    <ButtonPrimaryAntd width={width} height={height} {...props}>
      {children}
    </ButtonPrimaryAntd>
  );
};

export default PrimaryButton;
