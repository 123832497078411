export const GitbookIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8047_8457)">
      <path
        d="M7.20036 11.85C7.45036 11.85 7.67536 12.05 7.67536 12.325C7.67536 12.575 7.47536 12.8 7.20036 12.8C6.95036 12.8 6.72536 12.6 6.72536 12.325C6.72536 12.05 6.95036 11.85 7.20036 11.85ZM14.5504 8.94996C14.3004 8.94996 14.0754 8.74996 14.0754 8.47496C14.0754 8.22496 14.2754 7.99996 14.5504 7.99996C14.8004 7.99996 15.0254 8.19996 15.0254 8.47496C15.0254 8.72496 14.8004 8.94996 14.5504 8.94996ZM14.5504 7.02496C13.7504 7.02496 13.1004 7.67496 13.1004 8.47496C13.1004 8.62496 13.1254 8.77496 13.1754 8.92496L8.40036 11.475C8.12536 11.075 7.67536 10.85 7.20036 10.85C6.65036 10.85 6.15036 11.175 5.90036 11.65L1.60036 9.39996C1.15036 9.14996 0.800361 8.42496 0.850361 7.72496C0.875361 7.37496 1.00036 7.09996 1.17536 6.99996C1.30036 6.92496 1.42536 6.94996 1.60036 7.02496L1.62536 7.04996C2.77536 7.64996 6.50036 9.59996 6.65036 9.67496C6.90036 9.77496 7.02536 9.82496 7.45036 9.62496L15.1504 5.62496C15.2754 5.57496 15.4004 5.47496 15.4004 5.29996C15.4004 5.07496 15.1754 4.97496 15.1754 4.97496C14.7254 4.77496 14.0504 4.44996 13.4004 4.14996C12.0004 3.49996 10.4004 2.74996 9.70036 2.37496C9.10036 2.04996 8.60036 2.32496 8.52536 2.37496L8.35036 2.44996C5.17536 4.04996 0.975361 6.12496 0.725361 6.27496C0.300361 6.52496 0.0253605 7.04996 0.000360535 7.69996C-0.0496395 8.72496 0.475361 9.79996 1.22536 10.175L5.77536 12.525C5.87536 13.225 6.50036 13.775 7.20036 13.775C8.00036 13.775 8.62536 13.15 8.65036 12.35L13.6504 9.64996C13.9004 9.84996 14.2254 9.94996 14.5504 9.94996C15.3504 9.94996 16.0004 9.29996 16.0004 8.49996C16.0004 7.67496 15.3504 7.02496 14.5504 7.02496Z"
        fill="#4285FD"
      />
    </g>
    <defs>
      <clipPath id="clip0_8047_8457">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
