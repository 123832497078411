import { useQuery } from "@tanstack/react-query";
import { getListCourseForGuestAPI } from "api/course/request";
import {
  exploreProjectAPI,
  getDataMastersAPI,
  IFilterData,
  IProject,
} from "api/users";
import ProjectGroup from "components/modules/projectGroup";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Banner from "./components/Banner";
import {
  Wrapper,
  ProjectWrapper,
  ProjectWrapperItem,
  HightLightText,
  ContainerWrapper,
  IntroContainer,
} from "./homepage.style";
import Introduction from "./components/Introduction";
import ProjectIntro from "assets/images/homepage/project-intro.png";
import ThunderIntro from "assets/images/homepage/thunder.png";
import { GROUP_DATA } from "utils/homepage";
import { IIntroductionProps } from "./components/Introduction/introduction.props";
import General from "./components/General";
import Revolves from "./components/Revolves";
import { setFilter } from "redux/slices";
import { LaunchYourProject } from "./components/LaunchYourProject";
import { OurRecentBlogs } from "./components/OurRecentBlogs";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [categories, setCategories] = useState([] as any);

  const {
    seedSkip,
    openBountiesSkip,
    projectStatus,
    collaboratorLevel,
    deliveryDate,
    membersRange,
    issueTypes,
    search,
    limit,
    learnAndEarnSkip,
  } = useAppSelector((state) => state.filter);

  const paramsCourse = useAppSelector((state) => state.filterCourse);
  const [filterData, setFilterData] = useState<IFilterData>({
    projectStatus: projectStatus || "",
    collaboratorLevel: collaboratorLevel || "",
    deliveryDate: deliveryDate || "",
    membersRange: membersRange || [],
    issueTypes: issueTypes || [],
  });

  const userID: string = useAppSelector((state) => state.auth.user?._id);

  useEffect(() => {
    dispatch(
      setFilter({
        seedSkip: 0,
        openBountiesSkip: 0,
        learnAndEarnSkip: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setFilterData({
      projectStatus: projectStatus || "",
      collaboratorLevel: collaboratorLevel || "",
      deliveryDate: deliveryDate || "",
      membersRange: membersRange || [],
      issueTypes: issueTypes || [],
    });
  }, [
    projectStatus,
    collaboratorLevel,
    deliveryDate,
    membersRange,
    issueTypes,
  ]);

  useQuery(
    ["getDataCategory"],
    () => getDataMastersAPI({ type: "Project Categories" }),
    {
      onSuccess: (res) => {
        const { responseData, responseCode } = res;
        if (responseCode === 200) {
          setCategories(
            responseData?.values?.sort((a: any, b: any) => a.id - b.id)
          );
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: seedRes,
    isFetching: isFetchingSeed,
    isLoading: isLoadingSeed,
  } = useQuery(
    ["getSeed", categories, filterData, search, seedSkip],
    () =>
      exploreProjectAPI(
        categories[0]?._id,
        userID,
        search || "",
        seedSkip || 0,
        filterData
      ),
    {
      enabled: categories.length > 0 || !userID,
      refetchOnWindowFocus: false,
    }
  );
  const newSeedProjects: IProject[] = seedRes?.responseData?.records || [];
  const newSeedProjectsTotalLength: number =
    seedRes?.responseData?.recordsTotal || 0;
  const {
    data: openBountiesRes,
    isFetching: isFetchingOpenBounties,
    isLoading: isLoadingOpenBounties,
  } = useQuery(
    ["getOpenBounties", categories, filterData, search, openBountiesSkip],
    () =>
      exploreProjectAPI(
        categories[1]?._id,
        userID,
        search || "",
        openBountiesSkip || 0,
        filterData
      ),
    {
      enabled: categories.length > 0 || !userID,
      refetchOnWindowFocus: false,
    }
  );
  const newOpenBountiesProjects: IProject[] =
    openBountiesRes?.responseData?.records || [];
  const newOpenBountiesProjectsTotalLength: number =
    openBountiesRes?.responseData?.recordsTotal || 0;

  const {
    isFetching: isFetchingLearnAndEarn,
    isLoading: isLoadingLearnAndEarn,
    data: learnAndEarnRes,
  } = useQuery(
    ["getListCourse", { ...paramsCourse }, learnAndEarnSkip],
    () =>
      getListCourseForGuestAPI({
        ...paramsCourse,
        page_size: limit ?? paramsCourse.page_size,
        page:
          learnAndEarnSkip && learnAndEarnSkip !== 0
            ? learnAndEarnSkip + 1
            : paramsCourse.page,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const learnAndEarnResult = useMemo(() => {
    if (learnAndEarnRes?.responseData) return learnAndEarnRes.responseData;
  }, [learnAndEarnRes]);

  const firstSection: IIntroductionProps = {
    title:
      "A Suite of Tools and Incentives to Power Web3 Ecosystem and Fuel Community-Driven Growth",
    description: (
      <>
        With <HightLightText>BuidlFi</HightLightText>, you can access a
        community of developers, designers, and entrepreneurs passionate about
        crowd-building successful ecosystems around Web3 projects.
      </>
    ),
    img: ProjectIntro,
    btn: "Join Our Movement",
    redirectBtn: "https://discord.gg/Ng4vPFbjq4",
    bg: "#fff",
  };

  const secondSection: IIntroductionProps = {
    title: (
      <>
        Boost your portfolio <br /> while building amazing projects.
      </>
    ),
    description:
      "Get to work on groundbreaking Web3 projects, boost your reputation and leave an impact in the Web3 ecosystems.",
    img: ThunderIntro,
    btn: "",
    redirectBtn: "",
    bg: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 64.66%), linear-gradient(90deg, hsl(270deg 100% 50% / 30%) 0%, hsl(281deg 97% 63% / 30%) 46.88%, hsl(194deg 100% 49% / 30%) 100%)",
    mgr: "115px",
  };

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <Wrapper>
      <Banner>
        <IntroContainer>
          <Introduction {...firstSection} />
        </IntroContainer>
      </Banner>
      <ProjectWrapper>
        <ProjectWrapperItem bgColor="#080F6C">
          <ProjectGroup
            data={GROUP_DATA[0]}
            projects={newSeedProjects}
            skipType="seedSkip"
            isFetching={isFetchingSeed || isLoadingSeed}
            totalProjectsLength={newSeedProjectsTotalLength}
            haveChildren
          />
        </ProjectWrapperItem>
        <ProjectWrapperItem bgColor="#08486C">
          <ProjectGroup
            data={GROUP_DATA[1]}
            projects={newOpenBountiesProjects}
            skipType="openBountiesSkip"
            isFetching={isFetchingOpenBounties || isLoadingOpenBounties}
            totalProjectsLength={newOpenBountiesProjectsTotalLength}
            haveChildren
          />
        </ProjectWrapperItem>
        <ProjectWrapperItem bgColor="#6C085C">
          <ProjectGroup
            data={GROUP_DATA[2]}
            projects={learnAndEarnResult?.data ?? []}
            skipType="learnAndEarnSkip"
            isFetching={isFetchingLearnAndEarn || isLoadingLearnAndEarn}
            totalProjectsLength={learnAndEarnResult?.total ?? 0}
          />
        </ProjectWrapperItem>
      </ProjectWrapper>
      <ContainerWrapper>
        <OurRecentBlogs />
        <Introduction {...secondSection} />
      </ContainerWrapper>
      <General />
      <LaunchYourProject />
      <ContainerWrapper>
        <Revolves />
      </ContainerWrapper>
    </Wrapper>
  );
};

export default HomePage;
