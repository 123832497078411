export const getSkillTagColor = () => {
  const colors = [
    "#E94600",
    "#7E00FD",
    "#3B6CB4",
    "#49B67A",
    "rgba(41, 46, 115, 0.82)",
  ];

  return colors[Math.floor(colors.length * Math.random())];
};
