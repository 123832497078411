import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import BaseButton from "components/base/baseButton";
import styled from "styled-components";
import headingBG from "assets/images/png/createProject/heading-bg.png";

import { Input, InputNumber, Select, Upload } from "antd";
import FormItem from "antd/lib/form/FormItem";
import OutlinedButton from "components/base/outlinedButton";
const { Option } = Select;

export const Heading = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(208, 234, 255, 0.58) 0%,
    rgba(248, 233, 245, 0.75) 40%,
    rgba(246, 244, 241, 0.83) 60%,
    rgba(233, 247, 251, 0.92) 81%,
    rgba(233, 247, 250, 0.92) 100%
  );
  padding: 99px;
`;

export const CoverImage = styled.img``;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin: 1.875rem auto 1.25rem;
`;
export const Container = styled.div`
  display: grid;
  max-width: 73.125rem;
  margin: auto;
  grid-template-columns: 12.5rem auto;
  max-width: 1170px;
  margin: auto;
  padding: 0rem 3.125rem;
`;

export const Left = styled.div`
  width: 12.5rem;
  border-right: 0.0625rem solid rgba(41, 46, 115, 0.12);
  height: 100%;
`;
export const Right = styled.div`
  padding-left: 3rem;
  padding-bottom: 6.25rem;
`;
export const AvatarContainer = styled.div`
  width: 9.375rem;
  text-align: center;
  padding-top: 35px;
  .ant-form-item {
    margin-bottom: 0;
  }
`;
export const StyledInput = styled(Input)`
  max-width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  max-width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;
export const StyledTextArea = styled(Input.TextArea)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 8.125rem !important;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledFormItem = styled(FormItem)`
  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  .ant-form-item-label {
    padding-bottom: 0.625rem;
  }
`;

export const BigLabel = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-bottom: 0.3rem;
`;

export const LabelDescription = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 120%;
  color: rgba(41, 46, 115, 0.82);
  opacity: 0.6;
  margin-bottom: 1rem;
`;

export const StyledUpload = styled(Upload)`
  .ant-upload-list {
    width: 100%;
    .ant-upload-list-picture-card-container {
      display: block;
      margin: auto;
    }
    .ant-upload-list-picture-card-container {
      width: 18.75rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .ant-upload-list-item {
      border: 0.0625rem solid rgba(41, 46, 115, 0.22);
    }
  }
  .ant-upload {
    width: 100%;
    height: 8rem;
    &.ant-upload-select-picture-card {
      border-radius: 0.25rem;
      border: 0.0625rem dashed rgba(41, 46, 115, 0.12);
    }
  }
`;

export const AvatarUpload = styled(Upload)`
  .ant-upload-list {
    width: 9.375rem;
    margin-top: 1.875rem;
    margin-bottom: 0.625rem;
    .ant-upload-list-picture-card-container {
      display: block;
      margin: auto;
    }
    .ant-upload-list-picture-card-container {
      width: 9.375rem;
      height: auto;
      margin-bottom: 1rem;
    }
    .ant-upload-list-item {
      border: 0.0625rem solid rgba(41, 46, 115, 0.22);
    }
  }
  .ant-upload {
    width: 9.375rem;
    height: 9.375rem;
    &.ant-upload-select-picture-card {
      border-radius: 0.25rem;
      background-image: url(${headingBG});
      background-size: cover;
      border: 0.0625rem solid rgba(41, 46, 115, 0.07);
    }
  }
`;
export const NoFileText = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  margin-left: 0.9rem;
`;
export const SocialFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 0 1.25rem;
  margin-top: 2rem;
`;

export const LabelWithIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.3rem;
  }
`;

export const StyledSelect = styled(Select)`
  max-width: 100%;
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 2.25rem;
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  svg {
    margin-right: 0.3rem;
  }
  .ant-select-selection-placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #080f6c;

    margin-left: 0.5rem;
  }
  .ant-select-selection-item {
    padding-top: 0.1875rem !important;
  }
  .ant-select-arrow {
    svg {
      margin-right: 0;
    }
  }
  &.ant-select-open {
    border: 1px solid #7e00fd;
    border-radius: 4px 4px 0px 0px;
    .ant-select-arrow {
      transform: rotate(180deg);
      path {
        stroke: #7e00fd;
      }
    }
  }
`;

export const StyledOption = styled(Option)`
  /* background: red !important; */
`;

export const TokenSelectText = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 156%;
  letter-spacing: -0.01em;
  color: rgba(41, 46, 115, 0.82);
  margin-top: 0.3rem;
`;

export const ChooseImageButton = styled(BaseButton)`
  width: 9.5rem;
  height: 2.75rem;
`;

export const GroupButton = styled(CommonFlexRow)`
  margin-block: 24px;
  justify-content: center;
  column-gap: 10px;
`;

export const CancelButton = styled(OutlinedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: auto;
  height: 42px;
  background: #ff4d4f;
  border-radius: 4px;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  color: #ffffff;
`;
