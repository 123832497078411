import { StyledModal } from "components/base/commonModal/commonModal.style";
import PrimaryButton from "components/base/primaryButton";
import styled, { CSSProperties } from "styled-components";
import OutlinedButton from "components/base/outlinedButton";

export const ContainerModalConnectWallet = styled(StyledModal)`
  .ant-modal-content .ant-modal-body {
    padding: 2.625rem 1.25rem 3.0625rem 1.25rem;
  }
`;

export const ModalWalletTitle = styled.p`
  font-size: 1.25rem;
  color: #080f6c;
  font-family: "DM Sans";
  font-weight: 700;
  line-height: 120%;
  font-style: "Bold";
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 2.75rem;
`;

export const ConnectIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.925rem;
`;

export const ContainerPanel = styled.div`
  height: 4.625rem;
  width: 25.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  column-gap: 0.0625rem;
  border: 0.0625rem solid rgba(156, 138, 193, 0.16);
  &:hover {
    background: rgba(156, 138, 193, 0.16);
  }
`;

export const ContainerLeftPanel = styled.div`
  height: 4.625rem;
  width: 22.5rem;
  display: flex;
  align-items: center;
  column-gap: 0.875rem;
`;

export const WalletAvatar = styled.div`
  height: 3.625rem;
  width: 3.625rem;
  border-radius: 50%;
  margin: 0.5rem 0rem 0.5rem 0.8125rem;
`;

export const WalletName = styled.div`
  text-align: center;
  font-size: 1rem;
  letter-spacing: -0.02em;
  font-family: "DM Sans";
  line-height: 120%;
  font-weight: 700;
  font-style: "Bold";
  color: #080f6c;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.625rem;
  gap: 10px;
`;

export const ConnectButton = styled(PrimaryButton)`
  span {
    text-align: center;
    font-size: 1.125rem;
    letter-spacing: -0.01em;
    font-family: "Rubik";
    line-height: 100%;
    font-weight: 500;
    font-style: "Medium";
  }
`;

export const CancelButton = styled(OutlinedButton)`
  height: 48px;
  width: auto;
`;

export const ActiveWallet = styled.div`
  display: flex;
  align-items: center;
  height: 1.375rem;
  width: 1.375rem;
  margin-right: 1.5625rem;
`;

export const ModalMaskStyle = {
  backgroundColor: "#080A25",
  mixBlendMode: "multiply",
  opacity: 0.9,
} as CSSProperties;
