import image from "components/base/image";
import OutlinedButton from "components/base/outlinedButton";
import styled from "styled-components";

export const Container = styled.div<{ column: number }>`
  height: fit-content;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  background: white;
  padding: 20px 24px 20px 24px;
  display: grid;
  grid-template-columns: ${(p) => `repeat(${p.column}, 1fr)`};
  grid-column-gap: 36px;
  align-items: center;
  &:hover {
    background: linear-gradient(
      180deg,
      rgba(126, 0, 253, 0.0672) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border: 1px solid rgba(156, 138, 193, 0.16);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const ViewButton = styled(OutlinedButton)`
  width: fit-content;
  padding: 8px;
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 115%;
    color: #7e00fd;
    display: flex;
    align-items: center;
  }
`;

export const AvaAndContext = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const ImageChainNetwork = styled(image)`
  border-radius: 50%;
`;

export const TitleAndContextChain = styled.div`
  width: auto;
  column-gap: 4px;
  display: grid;
`;

export const Label = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  color: #080f6c;
  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: rgba(41, 46, 115, 0.42);
  }
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const ContentLabel = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: rgba(41, 46, 115, 0.42);
`;
