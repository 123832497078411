import { DownIcon } from "assets/icons/down.icon";
import { EmptyText } from "pages/ProjectDetail/ProjectDetail.style";
import PortfolioCard from "../portfolioCard";
import { CardsContainer, SeeMore } from "../projectGroup/projectGroup.style";

export const PortfolioCardContainer = ({
  itemsShow,
  portfolios,
  skipType,
  mt,
  isFetching,
  isLoadMore,
  loadMore,
  viewOtherUser,
  profile,
}: any) => {
  const currUser = viewOtherUser
    ? profile?.username || profile?.emailId.split("@")[0]
    : `You`;

  function seeMore() {
    loadMore();
  }

  return (
    <>
      {portfolios?.length > 0 ? (
        <CardsContainer itemsShow={itemsShow} mt={mt}>
          {portfolios?.map((pf: any, i: number) => (
            <PortfolioCard
              portfolio={pf}
              key={i}
              isFetching={isFetching}
              viewOtherUser={viewOtherUser}
            />
          ))}
        </CardsContainer>
      ) : (
        <EmptyText>{currUser} have no portfolio yet</EmptyText>
      )}
      {isLoadMore && !isFetching && (
        <SeeMore onClick={seeMore} style={{ color: "#7e00fd" }}>
          See more <DownIcon />
        </SeeMore>
      )}
    </>
  );
};
