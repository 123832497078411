import PrimaryButton from "components/base/primaryButton";
import styled from "styled-components";

// export const DropdownWrapper = styled.div`
//   .ant-dropdown-menu {
//     border-radius: 4px;

//     .ant-dropdown-menu-item {
//       padding-inline: 14px;
//     }

//     .ant-dropdown-menu-item:first-child {
//       padding-top: 10px;
//     }

//     .ant-dropdown-menu-item:last-child {
//       padding-bottom: 10px;
//     }
//   }
// `;

export const InfoWrapper = styled.a`
  display: flex;
  align-items: center;
`;

export const ProfileName = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 0 0.5rem;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const Container = styled.div`
  height: auto;
  width: 278px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid rgba(41, 46, 115, 0.12);
  padding-bottom: 20px;
`;

export const ConnectWalletBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  margin: 6px 0px;
  padding-inline: 21px;
`;

export const DropBoxText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.02em;
  font-family: "Rubik";
  font-style: "Regular";
  color: rgba(41, 46, 115, 0.82);
  cursor: pointer;
  // &:hover {
  //   color: #b93df3;
  // }
`;

export const ConnectWalletButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  padding: 8px auto;
  span {
    text-align: center;
    font-size: 14px;
    font-family: "Rubik";
    line-height: 115%;
    font-weight: 500;
    font-style: "Medium";
  }
  svg {
    width: 16px;
    height: 16px;
  }
  width: 100%;
`;

export const DividerHorizontal = styled.div`
  border: 0.5px solid rgba(156, 138, 193, 0.16);
  height: 0px;
  width: 100%;
`;

export const FooterDropBoxBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 14px;
  padding-left: 21px;
`;

export const LogoutText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.02em;
  font-family: "Rubik";
  font-style: "Regular";
  color: rgba(233, 70, 0, 0.7);
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

export const AccountText = styled.div`
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -0.02em;
  // font-family: "Rubik";
  // font-style: "Regular";
  color: rgba(41, 46, 115, 0.82);
`;
