import styled from "styled-components";
import { Input, Table } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FlexBox from "components/base/Flexbox";
import CopyToClipboard from "react-copy-to-clipboard";
// import bg from "assets/images/profile/builder-bg.png";
import { CommonGradientContainer } from "pages/ProfileUser/profileUserInner.style";

export const Heading = styled(FlexBox)`
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 6px;
`;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-left: 1rem;
`;
export const Description = styled.div<{ color?: string }>`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 115%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin: auto;
`;

export const StyledInput = styled(Input)`
  width: 333px;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  margin-right: 0.8rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledFormItem = styled(FormItem)`
  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  .ant-form-item-label {
    padding-bottom: 0.35rem;
  }
  margin-bottom: 18px;
`;

export const Divider = styled.div<{ m?: string }>`
  width: 193px;
  height: 1px;
  background-color: rgba(156, 138, 193, 0.16);
  margin: ${(p) => p.m};
`;

export const SocialGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 20px;
  padding: 0 30px;
`;

export const MiddleText = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: rgba(41, 46, 115, 0.42);
  margin: 0 15px;
`;

export const CopyText = styled.div`
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #7e00fd;
  margin: 30px auto 0;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 10px;
  }
`;

export const StyledCopy = styled(CopyToClipboard)`
  width: 200px;
  margin: 30px auto 0;
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.3rem;
  }
`;

export const InviteContainer = styled(CommonGradientContainer)`
  .ant-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
`;

export const UserTable = styled(Table)`
  p {
    margin: 0;
  }
  .ant-table-content {
    overflow: auto;
  }
  th {
    font-weight: 600 !important;
  }
  .ant-table table {
    border-radius: none;
  }
  .ant-table-thead > tr > th {
    border-bottom: none;
    h1 {
      font-weight: 600 !important;
      font-size: 16px;
      color: #080f6c;
      display: inline;
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px rgba(41, 46, 115, 0.12) solid;
  }
  .ant-table-thead {
    background: linear-gradient(
      135deg,
      rgba(126, 0, 253, 0.17),
      rgba(193, 67, 252, 0.17),
      rgba(0, 191, 251, 0.17)
    ) !important;
  }

  .ant-table-tbody {
    // &:hover {
    //   background: linear-gradient(
    //     135deg,
    //     rgba(126, 0, 253, 0.06),
    //     rgba(193, 67, 252, 0.06),
    //     rgba(0, 191, 251, 0.06)
    //   ) !important;
    // }
  }

  thead {
    tr {
      th {
        height: 49px;
        padding: 20px 32px 18px 32px;
        color: rgba(8, 15, 108, 1);
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
        font-family: "Rubik";
        font-style: "Medium";
        background: none !important;
      }
      .ant-table-cell:before {
        content: none !important;
      }
    }
  }

  tbody {
    .ant-table-row {
      height: 54px;
    }
    tr {
      .ant-table-cell {
        width: 25%;
        padding-left: 32px;
      }
      a {
        color: rgb(0 0 0 / 85%);
      }
      td {
        vertical-align: middle;
        &:first-child {
        }
      }
    }
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: linear-gradient(
      135deg,
      rgba(126, 0, 253, 0.06),
      rgba(193, 67, 252, 0.06),
      rgba(0, 191, 251, 0.06)
    );
  }

  .ant-table-placeholder {
    &:hover {
      border: none !important;
      background: none !important;
    }
    td {
      &:first-child {
        border-left: none !important;
      }
    }
  }
`;

export const ReferralTitle = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  color: #080f6c;
  font-family: "DM Sans";
  letter-spacing: -0.64px;
`;
