export const DropBoxLogoutIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0508 5.62461C16.6508 6.22461 16.6508 7.19961 16.0508 7.72461L13.9508 9.82461L8.10078 3.97461L10.2008 1.87461C10.8008 1.27461 11.7758 1.27461 12.3008 1.87461L13.6508 3.22461L15.9008 0.974609L16.9508 2.02461L14.7008 4.27461L16.0508 5.62461ZM11.7008 9.97461L10.6508 8.92461L8.55078 11.0246L6.97578 9.44961L9.07578 7.34961L8.02578 6.29961L5.92578 8.39961L4.80078 7.34961L2.70078 9.44961C2.10078 10.0496 2.10078 11.0246 2.70078 11.5496L4.05078 12.8996L1.05078 15.8996L2.10078 16.9496L5.10078 13.9496L6.45078 15.2996C7.05078 15.8996 8.02578 15.8996 8.55078 15.2996L10.6508 13.1996L9.60078 12.1496L11.7008 9.97461Z"
      fill="white"
    />
    <rect
      x="5.625"
      y="1.40918"
      width="2.25"
      height="2.25"
      transform="rotate(45 5.625 1.40918)"
      fill="white"
    />
    <rect
      x="12.0918"
      y="13.875"
      width="2.25"
      height="2.25"
      rx="1.125"
      transform="rotate(45 12.0918 13.875)"
      fill="white"
    />
  </svg>
);
