import { PlusOutlined, RightOutlined } from "@ant-design/icons";
// import { getPortfolioAdd } from "api/users/portfolio";
import { IGetPortfolioAddRes } from "api/users/types";
import errorProjectImage from "assets/images/addPortfolio/error-project.png";
import PrimaryButton from "components/base/primaryButton";
import ResultsNotFound from "components/modules/resultsNotFound";
// import MasterLayout from "layout/users/masterLayout";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAddProjectEmptyProps } from "./addProjectEmpty.props";
import { ButtonBlock, Heading, Wrap } from "./addProjectEmpty.style";

const AddProjectEmpty: FC<IAddProjectEmptyProps> = () => {
  let navigate = useNavigate();
  const [portfolioAddRes, setPortfolioAddRes] = useState<IGetPortfolioAddRes>({
    suggested_skills: [],
    file_types: [],
    opensoul_projects: [],
  });

  // const { mutate: fetchGetPortfolioAdd } = useMutation(getPortfolioAdd, {
  //   onSuccess: async (res: IGetPortfolioAddRes) => {
  //     setPortfolioAddRes(res);
  //   },
  //   onError: (err: AxiosError) => {
  //     message.error(err.message);
  //   },
  // });
  useEffect(() => {
    // fetchGetPortfolioAdd();
    // eslint-disable-next-line
  }, []);

  return (
    // <MasterLayout>
    <>
      <Heading>
        <div>Portfolio</div>
      </Heading>
      {portfolioAddRes?.opensoul_projects.length === 0 ? (
        <Wrap>
          <ResultsNotFound
            img={errorProjectImage}
            content={{
              text: "Talent who add portfolios to their profile are more likely to win work. (20%)",
            }}
          />
          <ButtonBlock>
            <PrimaryButton
              height="40px"
              width="166px"
              onClick={() => {
                navigate("/add-project");
              }}
            >
              <PlusOutlined />
              <span>Add a portfolio</span>
            </PrimaryButton>
          </ButtonBlock>
        </Wrap>
      ) : (
        <Wrap>
          <ResultsNotFound
            img={errorProjectImage}
            content={{
              text: "You have never participated in any project. Get involved in the project to have a basis for creating a portfolio.",
            }}
          />
          <ButtonBlock>
            <PrimaryButton
              height="40px"
              width="170px"
              onClick={() => {
                navigate("/");
              }}
            >
              <span>Go to home page</span>
              <RightOutlined />
            </PrimaryButton>
          </ButtonBlock>
        </Wrap>
      )}
    </>
    // </MasterLayout>
  );
};

export default AddProjectEmpty;
