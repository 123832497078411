import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 0px;
  max-width: 1170px;
  margin: 5rem auto 6rem;
`;

export const CenteredText = styled.div`
  text-align: center;
`;

export const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(41, 46, 115, 0) 0%,
      rgba(41, 46, 115, 0.12) 50.75%,
      rgba(41, 46, 115, 0) 98.96%
    );
  }
  ::before {
    content: "";
    position: absolute;
    height: 100%;
    right: 50%;
    transform: translateX(50%);
    width: 2px;
    background: linear-gradient(
      90deg,
      rgba(41, 46, 115, 0) 0%,
      rgba(41, 46, 115, 0.12) 50.75%,
      rgba(41, 46, 115, 0) 98.96%
    );
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
    ::after,
    ::before {
      display: none;
    }
  }
`;

export const GeneralItem = styled.div<{ bgColor?: string }>`
  background: ${(p) => p.bgColor};
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding-inline: 48px;
  border-radius: 4px;

  @media screen and (max-width: 992px) {
    min-height: 360px;
  }

  @media screen and (max-width: 720px) {
    min-height: 220px;
  }

  img {
    height: 65px;
    object-fit: contain;
  }
`;

export const GeneralItemFully = styled(GeneralItem)`
  align-items: baseline;
`;

export const GeneralWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const ContentWrapper = styled.div``;

export const ContentHeader = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 115%;
  letter-spacing: -0.01em;
  color: #080f6c;
  margin-bottom: 10px;
`;

export const Content = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 164%;
  color: rgba(41, 46, 115, 0.82);
  opacity: 0.7;
  span {
    color: rgba(126, 0, 253, 1);
  }
`;

export const GeneralFully = styled.div`
  margin-top: 9.25rem;
  ${CenteredText} {
    margin-bottom: 3rem;
  }
  ${SectionWrapper} {
    grid-template-columns: repeat(3, minmax(24rem, 1fr));
    ${GeneralItem} {
      height: 20.5rem;
      padding: 24px;
      background: linear-gradient(
        211.31deg,
        rgba(0, 222, 140, 0.0232) 18.93%,
        rgba(255, 255, 255, 0) 116.01%
      );
      border: 1px solid rgba(41, 46, 115, 0.12);
      img {
        height: 60px;
      }
    }
    ${GeneralWrapper} {
      flex-wrap: wrap;
    }
  }
`;
