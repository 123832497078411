import {
  WrapButton,
  WrapSubInfo,
  DivideGroup,
} from "./courseDetailNew.style";
import PrimaryButton from "components/base/primaryButton";
import { Button } from "antd";
import {
  ClockTransparentIcon,
  ParticipantIcon,
  ExerciseIcon,
} from "assets/icons/clock.icon";
import Skeleton from "react-loading-skeleton";

export const CourseDetailNewLoading = () => {
  return (
    <div>
      <h1>
        <Skeleton width="300px" />
      </h1>
      <WrapButton>
        <PrimaryButton width="150px">
          <Skeleton width={100} />
        </PrimaryButton>
      </WrapButton>
      <WrapSubInfo>
        <DivideGroup>
          <div>
            <ClockTransparentIcon />
            <span>
              <Skeleton width={50} />
            </span>
          </div>
          <div>
            <ExerciseIcon />
            <span>
              <Skeleton width={60} />
            </span>
          </div>
          <div>
            <ParticipantIcon />
            <span>
              <Skeleton width={90} />
            </span>
          </div>
        </DivideGroup>
        <DivideGroup>
          <div>
            <Button type="link">
              <span>
                Reward: <Skeleton width={60} />
              </span>
            </Button>
          </div>
          <div>
            <span>
              Remaining budget: <Skeleton width={60} />
            </span>
          </div>
          <Skeleton width={220} />
        </DivideGroup>
      </WrapSubInfo>
    </div>
  );
};
