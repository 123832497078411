import { Link } from "react-router-dom";
import styled from "styled-components";

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  color: rgba(41, 46, 115, 0.82);

  :hover {
    color: #080f6c;
  }
`;
