import { OutlineButton } from "pages/IDESubmit/IDESubmit.style";
import styled from "styled-components";

export const CourseContainer = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 100%;
`;

export const Heading = styled.div`
  padding: 0 99px;
  background: linear-gradient(
    90deg,
    rgba(208, 234, 255, 0.58) 0%,
    rgba(248, 233, 245, 0.75) 40%,
    rgba(246, 244, 241, 0.83) 60%,
    rgba(233, 247, 251, 0.92) 81%,
    rgba(233, 247, 250, 0.92) 100%
  );
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:before {
    content: "";
    position: absolute;
    bottom: -82px;
    left: 0;
    width: 100%;
    height: 42%;
    background: linear-gradient(
      90deg,
      rgba(208, 234, 255, 1) 0%,
      rgba(248, 233, 245, 1) 40%,
      rgba(246, 244, 241, 1) 60%,
      rgba(233, 247, 251, 1) 81%,
      rgba(233, 247, 250, 1) 100%
    );
    filter: blur(20px);
  }
`;

export const Title = styled.h1`
  color: #080f6c;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.875rem;
  letter-spacing: -0.04em;
`;

export const StepDesc = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
`;

export const StepContentWrapper = styled.div`
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  padding: 24px;
  position: relative;
  margin-top: 24px;
`;

export const ContentWrapper = styled.div`
  padding: 18px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: 18px;
`;

export const StepHeader = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #080a25;
  display: flex;
  align-items: baseline;
`;

export const StepText = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.02em;
  font-size: 16px;
  color: rgba(8, 10, 37, 0.5);
`;

export const CurrentStep = styled(StepText)`
  margin: 0 0 0 17px;
  color: #7e00fd;
`;

export const Container = styled.div`
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  max-width: 73.125rem;
  margin: auto;
  .ant-steps {
    max-width: 862px;
    margin: 18px auto 0;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .ant-btn {
    width: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    gap: 4px;
    height: 36px;
  }
  .ant-divider {
    height: unset;
    margin: 0 28px;
  }
`;

export const RightAction = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  > .ant-btn:last-child {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const DraftBtn = styled(OutlineButton)`
  border: 2px solid;
  border-color: #7e00fd !important;
  border-radius: 4px;
  :hover {
    background: none;
  }
`;
