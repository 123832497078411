import { Input } from "antd";
import OutlinedButton from "components/base/outlinedButton";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import styled from "styled-components";

export const FileBtn = styled(OutlinedButton)`
  max-width: 100%;
  width: auto;
  height: auto;
  padding-block: 7px;

  svg {
    flex-shrink: 0;
  }

  span {
    width: 100%;
    white-space: normal;
    word-break: break-all;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
`;

export const DeliverStatus = styled.div<{ bg: string }>`
  padding: 8px 12px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: ${({ bg }) => bg};
  border-radius: 4px;
`;

export const FileWrapper = styled(CommonFlexRow)`
  flex-wrap: wrap;
`;

export const EmptyText = styled.div`
  width: 100%;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: rgba(41, 46, 115, 0.82);
`;

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  padding: 0rem 3.125rem;
  overflow: hidden;
`;
export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  object-fit: cover;
  z-index: -1;
  height: 128px;
`;
export const TitleWrap = styled.div`
  width: 100%;
  padding: 3rem 0rem;
  max-width: 1170px;
  margin: auto;
  position: relative;
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #080f6c;
  overflow: visible;
`;
export const BodyWrap = styled.div`
  width: 100%;
  padding: 0rem 0rem 8.4375rem;
  max-width: 1170px;
  margin: auto;
`;
export const SubmitNew = styled.div`
  margin: 2rem 0rem;
  background: rgba(156, 138, 193, 0.16);
  border-radius: 0.5rem;
  padding: 2.375rem 0rem;
  display: flex;
  justify-content: center;
  cursor: pointer;

  & > div {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: rgba(41, 46, 115, 0.82);
    width: max-content;
    position: relative;
    display: flex;
    align-items: center;
    & > svg {
      position: absolute;
      left: calc(100% + 1.125rem);
    }
  }
`;
export const DeliverableWrap = styled.div`
  background: rgba(156, 138, 193, 0.04);
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  padding: 0rem 2rem 1.875rem;
`;
export const HeadSection = styled.div`
  border-bottom: 0.0625rem solid rgba(41, 46, 115, 0.12);
  padding: 1.25rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > div {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #080f6c;
    margin-bottom: 0.25rem;
  }
`;
export const SearchInput = styled(Input)`
  width: 16.5625rem;
  height: 2.5rem;
  padding: 0.5625rem 1rem;
  background: rgba(255, 255, 255, 0.8);
  border: 0.0625rem solid rgba(8, 10, 37, 0.1);
  border-radius: 0.375rem;

  .ant-input {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 156%;
    color: rgba(8, 10, 37, 1);

    &::placeholder {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 156%;
      color: rgba(8, 10, 37, 0.6);
    }
  }
`;
export const FieldWrap = styled.div`
  padding: 2.25rem 3.5rem 1.0625rem 1.5rem;
  display: flex;
`;
export const Column1 = styled.div`
  width: 12.3125rem;
`;
export const Column2 = styled.div`
  width: 25.875rem;
`;
export const Column3 = styled.div`
  width: 25.75rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export const Field1 = styled(Column1)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  margin-right: 20px;
`;
export const Field2 = styled(Column2)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
`;
export const Field3 = styled(Column3)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
`;
export const ItemWrap = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;
export const Item = styled.div`
  background: #ffffff;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  width: 100%;
  padding: 1.5rem 3.5rem 1.625rem 1.5rem;
  display: flex;
`;
export const Element1 = styled(Column1)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  margin-right: 20px;
`;
export const Element2 = styled(Column2)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
`;
export const Element3 = styled(Column3)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  button {
    margin-top: 6px;
    margin-right: 0;
    margin-left: auto;
  }
  > div {
    display: flex;
    gap: 10px;
  }
`;
export const ItemTitle3 = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 110%;
  color: #080f6c;
`;
export const ItemTitle1 = styled(ItemTitle3)`
  font-size: 1.125rem;
  word-break: break-all;
`;
export const ItemTitle2 = styled(ItemTitle1)`
  font-weight: 500;
  letter-spacing: -0.02em;
  max-width: 23.3125rem;
  word-break: break-all;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
`;
export const ItemBody1 = styled.div`
  display: flex;
  align-items: center;
  // height: calc(100% - 1.625rem);
  & > div {
    padding: 12px 0px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    & > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 156%;
      letter-spacing: -0.02em;
      color: rgba(41, 46, 115, 0.82);
    }
  }
`;
export const ItemBody2 = styled.div`
  display: flex;
  padding-top: 0.75rem;
  & > span {
    width: 1.5625rem;
  }
  & > div {
    width: calc(100% - 1.5625rem);
    & > div {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 156%;
      letter-spacing: -0.02em;
      color: rgba(41, 46, 115, 0.82);
      margin-bottom: 1.125rem;
      word-break: break-all;

      strong {
        color: rgba(41, 46, 115, 1);
        margin-left: 0.625rem;
      }
    }
  }
`;
