import axios from "axios";

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_GITHUB_API_BASE_URL,
  headers: {
    Accept: "application/vnd.github+json",
    Authorization: `token ${process.env.REACT_APP_GITHUB_TOKEN}`,
  },
});

export const githubRequest = async (githubIssueUrl: string) => {
  return await AxiosInstance({
    url: `/repos/${githubIssueUrl}`,
    method: "GET",
    params: { state: "open" },
  });
};
