import { FC, memo, useEffect, useState } from "react";
import { IModalConnectWalletProps } from "./modalConnectWallet.props";
import {
  ConnectIcon,
  ContainerModalConnectWallet,
  ModalWalletTitle,
  ConnectButton,
  ModalMaskStyle,
  ButtonWrapper,
  CancelButton,
} from "./modalConnectWallet.style";
import { ConnectWalletIcon } from "assets/images/connect-wallet/connectWallet";
import {
  MetamaskIcon,
  WalletConnectIcon,
  CoinbaseWalletIcon,
} from "assets/images/connect-wallet";
import { useConfig, useEthers } from "@usedapp/core";
import { message } from "antd";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setConnectWalletOpen, setUserInfo, setWalletName } from "redux/slices";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateWalletAddressAPI } from "api/users";
import WalletSection from "./WalletSection";

const ModalConnectWallet: FC<IModalConnectWalletProps> = ({ show }) => {
  const [selectedWallet, setSelectedWallet] = useState("");
  const {
    account,
    activateBrowserWallet,
    isLoading: walletLoading,
    deactivate,
  } = useEthers();
  const dispatch = useAppDispatch();
  const [hasClickConnect, setHasClickConnect] = useState(false);
  const { connectors } = useConfig();
  const queryClient = useQueryClient();
  const { user } = useAppSelector((state) => state.auth);

  const { mutateAsync: updateWalletAddress } = useMutation(
    updateWalletAddressAPI,
    {
      onSuccess: async (res) => {
        const { responseCode, responseMessage } = res;
        if (responseCode === 200) {
          message.success("Wallet successfully connected");
          await queryClient.invalidateQueries(["getProfile"]);
          dispatch(
            setUserInfo({
              ...user,
              walletAddress: account,
            })
          );
        } else {
          deactivate();
          message.error(responseMessage);
        }
      },
    }
  );
  const walletIcons = [
    <MetamaskIcon />,
    <CoinbaseWalletIcon />,
    <WalletConnectIcon />,
  ];

  const handleSelectWallet = (wallet: string) => setSelectedWallet(wallet);

  const handleCloseConnectModal = () => dispatch(setConnectWalletOpen(false));

  const handleConnectSelectedWallet = () => {
    if (selectedWallet === "") {
      message.error("Please choose a wallet to connect");
      return;
    }

    activateBrowserWallet({ type: selectedWallet });
    dispatch(setWalletName(selectedWallet));
    setHasClickConnect(true);
    dispatch(setConnectWalletOpen(false));
  };

  useEffect(() => {
    if (hasClickConnect && !walletLoading && account) {
      if (!user?.walletAddress) updateWalletAddress({ walletAddress: account });
      else if (user?.walletAddress !== account) {
        deactivate();
        message.error(
          <span>
            We have identified that you are using different wallet from the one
            you have setup/ verified your account.
            <br />
            Please switch back to your verified wallet to proceed.
          </span>
        );
      } else message.success("Wallet successfully connected");

      setHasClickConnect(false);
    }
  }, [
    account,
    deactivate,
    hasClickConnect,
    updateWalletAddress,
    user?.walletAddress,
    walletLoading,
  ]);

  return (
    <ContainerModalConnectWallet
      // bodyStyle={{ height: 553 }}
      footer={null}
      visible={show}
      centered
      width={448}
      closable={false}
      maskStyle={ModalMaskStyle}
      onCancel={handleCloseConnectModal}
    >
      <ConnectIcon>
        <ConnectWalletIcon />
      </ConnectIcon>
      <ModalWalletTitle>Connect Your Wallet</ModalWalletTitle>
      {Object.entries(connectors).map(([connectorName], idx) => {
        return (
          <WalletSection
            key={connectorName}
            connectorName={connectorName}
            walletIcon={walletIcons[idx]}
            selectedWallet={selectedWallet}
            handleSelectWallet={handleSelectWallet}
          />
        );
      })}
      <ButtonWrapper>
        <ConnectButton height="48px" onClick={handleConnectSelectedWallet}>
          Connect
        </ConnectButton>
        <CancelButton onClick={handleCloseConnectModal}>Cancel</CancelButton>
      </ButtonWrapper>
    </ContainerModalConnectWallet>
  );
};

export default memo(ModalConnectWallet);
