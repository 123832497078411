import { FC } from "react";

export const UploadCloudIcon: FC = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11073_9693)">
        <path
          d="M18.6654 19.2956L13.9987 14.6289L9.33203 19.2956"
          stroke="#080F6C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 14.6289V25.1289"
          stroke="#080F6C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.788 22.0849C24.9259 21.4646 25.8248 20.483 26.3429 19.295C26.8609 18.107 26.9686 16.7804 26.6489 15.5244C26.3293 14.2685 25.6004 13.1547 24.5775 12.359C23.5545 11.5632 22.2957 11.1308 20.9997 11.1299H19.5297C19.1766 9.76406 18.5184 8.496 17.6046 7.4211C16.6909 6.34621 15.5454 5.49244 14.2542 4.92399C12.963 4.35554 11.5597 4.0872 10.1498 4.13914C8.74001 4.19109 7.36029 4.56196 6.11442 5.22389C4.86854 5.88581 3.78894 6.82157 2.95677 7.96079C2.1246 9.10002 1.56151 10.4131 1.30985 11.8012C1.05819 13.1894 1.1245 14.6166 1.50379 15.9754C1.88308 17.3343 2.56549 18.5895 3.49971 19.6466"
          stroke="#080F6C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6654 19.2956L13.9987 14.6289L9.33203 19.2956"
          stroke="#080F6C"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11073_9693">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0 0.628906)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const UploadCloudWhiteIcon = () => {
  return (
    <svg
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6663 21.6673H7.66634C5.64412 21.6673 3.91656 20.9673 2.48367 19.5673C1.0499 18.1673 0.333008 16.4562 0.333008 14.434C0.333008 12.7007 0.85523 11.1562 1.89967 9.80065C2.94412 8.4451 4.31079 7.57843 5.99967 7.20065C6.55523 5.15621 7.66634 3.50065 9.33301 2.23398C10.9997 0.967318 12.8886 0.333984 14.9997 0.333984C17.5997 0.333984 19.805 1.23932 21.6157 3.04998C23.4272 4.86154 24.333 7.06732 24.333 9.66732C25.8663 9.8451 27.1388 10.506 28.1503 11.65C29.161 12.7949 29.6663 14.134 29.6663 15.6673C29.6663 17.334 29.0832 18.7509 27.917 19.918C26.7499 21.0842 25.333 21.6673 23.6663 21.6673H16.333V12.134L18.4663 14.2007L20.333 12.334L14.9997 7.00065L9.66634 12.334L11.533 14.2007L13.6663 12.134V21.6673Z"
        fill="white"
      />
    </svg>
  );
};
