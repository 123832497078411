import { Tabs, Avatar } from "antd";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import styled from "styled-components";

export const OrgContainer = styled.div`
  font-family: "DM Sans";
  // padding: 0px 50px;
  overflow: hidden;
`;

export const OrgHeadContainer = styled.div<{ paddingTop?: boolean }>`
  width: 100%;
  position: relative;
  padding-top: ${(p) => (p.paddingTop ? "32px" : "")};
  background: linear-gradient(
    90deg,
    rgba(208, 234, 255, 0.58) 0%,
    rgba(248, 233, 245, 0.75) 40%,
    rgba(246, 244, 241, 0.83) 60%,
    rgba(233, 247, 251, 0.92) 81%,
    rgba(233, 247, 250, 0.92) 100%
  );
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  object-fit: cover;
  z-index: -1;
`;

export const OrgBodyWrap = styled.div`
  width: 100%;
  padding: 0px;
  max-width: 1170px;
  margin: auto;
  // margin-top: 24px;
  margin-bottom: 255px;
  position: relative;
  z-index: 1;
`;
export const OrgBody = styled.div`
  width: 100%;
  background: transparent;

  padding: 65px 96px 97px 149px;
  position: relative;
  display: flex;
`;

export const OrgBodyBackground = styled.div`
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
    linear-gradient(
      90deg,
      rgba(126, 0, 253, 0.1) 0%,
      rgba(193, 67, 252, 0.1) 46.88%,
      rgba(0, 191, 251, 0.1) 100%
    );
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

export const OrgBodyNumbersWrap = styled.div<{ marginRight: number }>`
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-right: ${(p) => p.marginRight + "px"};
`;
export const OrgWrap = styled.div`
  & > div {
    width: max-content;
  }
`;

export const OrgBodyNumber = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-bottom: 4px;
`;

export const OrgBodyName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const OrgBodyTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #080f6c;
  margin-bottom: 12px;
`;

export const OrgBodySubtitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 172%;
  color: rgba(41, 46, 115, 0.82);
  width: 502px;
`;

export const EditBtn = styled(OutlinedButton)`
  width: unset;
  height: unset;
  padding: 8px 12px;
  gap: 4px;

  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 115%;
    color: #7e00fd;
  }

  svg {
    position: relative;
    top: -1px;
  }
`;

export const CreateProjectBtn = styled(PrimaryButton)`
  width: unset;
  height: unset;
`;

export const OrganActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 30px;
`;

export const OrgAvatar = styled(Avatar)`
  width: 190px;
  height: 190px;
  border-radius: 50%;
  object-fit: cover;
`;

export const OrganImageWrapper = styled.div`
  margin-right: 100px;
`;

export const CommonDescription = styled.span`
  font-family: "Rubik";
  font-style: normal;
  font-size: 16px;
  line-height: 156%;
`;

export const OrganWebsite = styled(CommonDescription)`
  font-weight: 400;
  color: rgba(41, 46, 115, 0.656);
`;

export const OrganName = styled.h1`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin: 0;
  text-transform: capitalize;
`;

export const OrganNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const OrganInfoWrapper = styled.div<{ hasMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ hasMargin }) =>
    hasMargin === true &&
    `
    margin-left: 100px;
  `}
`;

export const MainHeader = styled.div`
  max-width: 1170px;
  margin: auto;
  padding: 55px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TabTitle = styled(CommonDescription)`
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
`;

export const OrganTabs = styled(Tabs)`
  &.ant-tabs > .ant-tabs-nav,
  &.ant-tabs > div > .ant-tabs-nav {
    background: #fff;

    &:before {
      border-bottom: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      height: 100%;
      border-radius: 50%;
      background: linear-gradient(
        94.38deg,
        rgba(197, 138, 255, 0.6) 7.87%,
        rgba(0, 173, 251, 0.6) 50.31%,
        rgba(0, 213, 201, 0.6) 82.37%,
        rgba(0, 222, 140, 0.6) 107.9%
      );
      filter: blur(22px);
      z-index: -1;
    }
  }

  .ant-tabs-tab {
    padding-block: 22px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    ${TabTitle} {
      color: #7e00fd;
    }
  }
`;

export const CommonFlexRow = styled.div<{ space?: string }>`
  display: flex;
  align-items: center;
  ${({ space }) => space !== undefined && `gap: ${space}`};
`;
