import { FC, useEffect, useMemo, useState } from "react";
import { IConversationProps } from "../../Conversation.props";
import { PictureIcon } from "assets/images/conversation";
import {
  ChaterWrap,
  ConversationWrap,
  Divider,
  Item,
  ItemBackground,
  ItemEnd,
  ItemSpin,
  LastImageMessage,
  LastMessage,
  LastUnreadMessage,
  LeftSide,
  SearchInput,
  SpindAntd,
} from "../../Conversation.style";
import { SearchIcon } from "assets/icons/search.icon";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { getChatGroupAPI, getChatListAPI, IRoom, IRoomDetail } from "api/users";
import { ROOM_TYPES } from "utils/constant";
import moment from "moment";
import RoomChat from "../RoomChat";
import { GrayAttachIcon } from "assets/images/project-board";
import { useLocation } from "react-router-dom";
import { AvatarAntd } from "components/modules/avatarList/avatarList.style";
import { UserOutlined } from "@ant-design/icons";
import { setConversation } from "redux/slices/conversationSlice";

let onlineStatus = "";
const Conversation: FC<IConversationProps> = ({
  marginTop,
  groupRoomId,
  status,
  packageId,
}) => {
  const [itemSelectedIndex, setItemSelectedIndex] = useState(-1);

  const [chunks, setChunks] = useState<IRoom[][]>([]);
  const [chunksHistory, setChunksHistory] = useState<IRoom[][]>([]);
  const [getNewMessageRefresh, setGetNewMessageRefresh] =
    useState<boolean>(false);
  const [chatList, setChatList] = useState<IRoom[]>([]);
  const [checkList, setCheckList] = useState<IRoom[]>([]);
  const [roomId, setRoomId] = useState<string>("");
  const [roomDetail, setRoomDetail] = useState<IRoomDetail>();
  const [isOnline, setIsOnline] = useState<boolean>(false);

  const [search, setSearch] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit] = useState(10);

  const [enabled, setEnabled] = useState(false);

  const [newPrivateChater, setNewPrivateChater] = useState<any>();
  const [openNewPrivateRoom, setOpenNewPrivateRoom] = useState<boolean>(false);

  const loadMore = useMemo(
    () => ({
      LOADED: "LOADED",
      LOADING: "LOADING",
      ENDLIST: "ENDLIST",
    }),
    []
  );
  const [loadMoreList, setLoadMoreList] = useState(loadMore.LOADED);

  const { socket, user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useQuery(
    ["getChatList", search, skip],
    () =>
      status === ROOM_TYPES.DIRECT
        ? getChatListAPI({
            id: user._id,
            search: search,
            skip: skip,
            limit: limit,
          })
        : getChatGroupAPI({
            id: user._id,
            search: search,
            skip: skip,
            limit: limit,
            packageId,
          }),
    {
      onSuccess: (res) => {
        const { responseData, responseCode } = res;
        if (responseCode === 200) {
          const data = responseData.records;
          // console.log("getchatlist", responseData, skip);

          if (data.length < 10) {
            setLoadMoreList(loadMore.ENDLIST);
          } else setLoadMoreList(loadMore.LOADED);

          const newChunks = [...chunks];
          newChunks[skip] = data;
          setChunks(newChunks);
          setChunksHistory(newChunks);
        }
      },
      onError: (res) => {
        setLoadMoreList(loadMore.LOADED);
      },
      enabled: enabled && !getNewMessageRefresh,
    }
  );

  useQuery(
    ["getChatListRefresh", skip, getNewMessageRefresh],
    () =>
      status === ROOM_TYPES.DIRECT
        ? getChatListAPI({
            id: user._id,
            search: search,
            skip: skip,
            limit: limit,
          })
        : getChatGroupAPI({
            id: user._id,
            search: search,
            skip: skip,
            limit: limit,
            packageId,
          }),
    {
      onSuccess: (res) => {
        const { responseData, responseCode } = res;
        if (responseCode === 200) {
          const data = responseData.records;
          // console.log("getchatlistRefresh", responseData);

          if (data.length < 10) {
            setLoadMoreList(loadMore.ENDLIST);
          } else setLoadMoreList(loadMore.LOADED);

          const newChunks = [...chunks];
          newChunks[skip] = data;
          setChunks(newChunks);
        }
      },
      onError: (res) => {
        setLoadMoreList(loadMore.LOADED);
      },
      enabled: getNewMessageRefresh,
    }
  );

  useEffect(() => {
    dispatch(setConversation({ isNewMessage: false }));
  }, [dispatch]);

  useEffect(() => {
    if (!socket) return;
    socket.removeAllListeners("getAllMessages");
    socket.removeAllListeners("openPrivateRoom");
    socket.removeAllListeners("initPrivateRoomError");
    socket.removeAllListeners("newRoomCreated");

    if (user !== null && user !== undefined) {
      socket.on("getAllMessages", (data: IRoomDetail) => {
        // console.log("getAllMessages", data);
        setGetNewMessageRefresh(true);
        setOpenRoomLoop(true);
        setLoadMoreList(loadMore.LOADED);
        if (skip > 0) setSkip(0);
        // 10
        setRoomDetail(data);
        // console.log("checkroom", data);
      });
      socket.on("openPrivateRoom", (data: any) => {
        // console.log("openPrivateRoom", data);
        if (data?.roomId) {
          setOpenRoomId(data.roomId);
          setOpenRoomLoop(true);
        }
        if (data?._id) {
          setNewPrivateChater(data);
          setOpenNewPrivateRoom(true);
          setRoomDetail({ ...data, data: [] });
          setRoomId("");
          setIsOnline(!data?.isLoggedOut);
        }
        setEnabled(true);
      });
      socket.on("initPrivateRoomError", (data: any) => {
        setEnabled(true);
      });
      socket.on("newUserStatus", (data: any) => {
        // console.log("newUserStatus", data);
        const newStatus = data?.userId + data?.status;
        if (newStatus === onlineStatus) return;
        onlineStatus = data?.userId + data?.status;

        setGetNewMessageRefresh(true);
        if (skip > 0) setSkip(0);
        setOpenRoomLoop(true);
        setLoadMoreList(loadMore.LOADED);
      });

      socket.on("newRoomCreated", (data: any) => {
        // console.log("newRoomCreated", data);
        setNewPrivateChater(undefined);
        setOpenNewPrivateRoom(false);
        setRoomId(data?.roomId || "");
      });
    }
    return () => {
      // socket.emit("disconnect", "offline");
    };
  }, [user, socket, loadMore.LOADED, skip]);

  useEffect(() => {
    if (!socket) return;

    if (user !== null && user !== undefined) {
      socket.removeAllListeners("getNewMessages");
      socket.on("getNewMessages", (data: IRoomDetail) => {
        // console.log("getNewMessages", data);
        const messages = data.data;
        if (
          messages[messages.length - 1].sender !== user._id &&
          roomDetail &&
          roomDetail._id === data._id
        ) {
          const data = { roomId: roomId, sender: user._id };
          socket.emit("checkRoom", data);
          return;
        }

        if (messages[messages.length - 1].sender === user._id)
          setGetNewMessageRefresh(true);
        if (skip > 0) setSkip(0);
        setOpenRoomLoop(true);
        setLoadMoreList(loadMore.LOADED);

        if (!roomDetail || roomDetail._id !== data._id) return;

        setRoomDetail(data);
      });
    }
  }, [roomDetail, skip, roomId, user, loadMore.LOADED, socket]);
  //Socket end

  function getIsLoggedOut(room: IRoom | IRoomDetail | undefined) {
    if (!room) return true;
    if (room.roomType === ROOM_TYPES.DIRECT) {
      return room.participantDetailsList.find(
        (chatUser) => chatUser._id !== user._id
      )?.isLoggedOut;
    } else return true;
  }

  useEffect(() => {
    if (!socket || !roomId) return;
    const data = { roomId: roomId, sender: user._id };
    socket.emit("checkRoom", data);
  }, [roomId]);

  useEffect(() => {
    if (loadMoreList === loadMore.LOADED || loadMoreList === loadMore.ENDLIST)
      return;
    setSkip((prevSkip) => prevSkip + 1);
  }, [loadMore.ENDLIST, loadMore.LOADED, loadMoreList]);

  function lastMessage(text: string) {
    if (text?.includes("<<embedded-files:[")) {
      return (
        <LastImageMessage>
          <GrayAttachIcon /> file
        </LastImageMessage>
      );
    }

    if (text?.includes("<<embedded-images:[")) {
      // const regexImages = /<<embedded-images:\[[^<>]+\]>>/g;

      // const imagesText = text?.match(regexImages) || [];
      // const imageText = imagesText[0] || ""
      // const message = text.replace(imageText,"") || false

      return (
        <LastImageMessage>
          <PictureIcon /> photo
        </LastImageMessage>
      );
    }

    return text;
  }

  const locationSearch = useLocation().search;
  let privateUserId = new URLSearchParams(locationSearch).get("id");
  const [openRoomId, setOpenRoomId] = useState<string>("");
  const [openRoomLoop, setOpenRoomLoop] = useState<boolean>(false);

  useEffect(() => {
    if (privateUserId && socket) {
      socket.emit("initPrivateRoom", {
        userId: user._id,
        partnerId: privateUserId,
      });
    }
  }, [socket, privateUserId, user._id]);

  useEffect(() => {
    if (!privateUserId && !groupRoomId) setEnabled(true);
  }, [privateUserId, groupRoomId]);

  useEffect(() => {
    if (!groupRoomId) return;

    setOpenRoomId(groupRoomId);
    setOpenRoomLoop(true);
    setEnabled(true);
  }, [groupRoomId]);

  function refreshChatList(list: IRoom[]) {
    if (list.length)
      if (openRoomLoop) {
        if (getNewMessageRefresh) {
          let check = false;
          let count = 0;
          list.forEach((room: IRoom, i: number) => {
            if (roomId === room._id) {
              if (!check) {
                setItemSelectedIndex(i);
                check = true;
              }
            }
            checkList.find((roomToCheck: IRoom) => {
              if (room._id === roomToCheck._id) {
                count++;
                return true;
              }
              return false;
            });
          });
          if (loadMoreList === loadMore.ENDLIST) {
            setOpenRoomLoop(false);
            setChunksHistory(chunks);
            setGetNewMessageRefresh(false);
            return true;
          }

          if (count === checkList.length) {
            setOpenRoomLoop(false);
            setGetNewMessageRefresh(false);
            setChunksHistory(chunks);
          } else {
            setSkip(skip + 1);
            return false;
          }
        } else {
          let check = false;
          list.find((room: IRoom, i: number) => {
            if (room._id === openRoomId) {
              setOpenRoomLoop(false);
              setItemSelectedIndex(i);
              setRoomId(openRoomId);
              check = true;
              return true;
            }
            return false;
          });
          if (!check)
            if (loadMoreList === loadMore.ENDLIST) {
              setOpenRoomLoop(false);
            } else {
              setSkip(skip + 1);
              return false;
            }
        }
      }

    return true;
  }

  useEffect(() => {
    const newList: any = [];
    chunks.find((chunk: any, i: number) => {
      if (skip < i) {
        return true;
      }
      chunk?.forEach((p: any) => {
        newList.push(p);
      });
      return false;
    });

    const isUpdateChatList = refreshChatList(newList);
    if (isUpdateChatList) setChatList(newList);
  }, [chunks]);

  useEffect(() => {
    const newList: any = [];
    chunksHistory.find((chunk: any, i: number) => {
      if (skip < i) return true;
      chunk?.forEach((p: any) => {
        newList.push(p);
      });
      return false;
    });
    setCheckList(newList);
  }, [chunksHistory]);

  useEffect(() => {
    if (newPrivateChater) return;
    const selectedRoom = chatList.find(
      (room: any) => room._id === roomDetail?._id
    );
    if (selectedRoom) setIsOnline(!getIsLoggedOut(selectedRoom));
  }, [chatList]);

  function getRoomAvatar(room: any) {
    if (room?.roomType !== ROOM_TYPES.GROUP) {
      const partner: any = room?.participantDetailsList?.find(
        (partner: any) => partner?._id !== user._id
      );
      if (partner)
        return {
          url: partner?.profilePicture,
          roomName: partner?.username || partner?.emailId.split("@").shift(),
        };
    }
    return {
      url: room?.roomAvatar || "",
      roomName: room?.roomName,
    };
  }
  function checkRoom(room: any, i: number) {
    setItemSelectedIndex(i);
    setRoomId(room._id);
    setOpenNewPrivateRoom(false);
  }

  return (
    <ConversationWrap>
      <LeftSide marginTop={marginTop}>
        <SearchInput
          className="search"
          type="search"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (getNewMessageRefresh) return;
            setLoadMoreList(loadMore.LOADED);
            setSkip(0);
            setSearch(e.target.value);
          }}
          value={search}
          placeholder="Search"
          suffix={<SearchIcon />}
        />
        <ChaterWrap
          onScroll={(ev: any) => {
            if (
              ev.target.scrollHeight - ev.target.scrollTop ===
              ev.target.clientHeight
            ) {
              if (loadMore.LOADED === loadMoreList) {
                setLoadMoreList(loadMore.LOADING);
              }
            }
          }}
        >
          {newPrivateChater && (
            <Item
              style={openNewPrivateRoom ? { border: "none" } : {}}
              onClick={() => {
                setItemSelectedIndex(-1);
                setOpenNewPrivateRoom(true);
                setRoomDetail({ ...newPrivateChater, data: [] });
                setRoomId("");
              }}
            >
              {openNewPrivateRoom && <ItemBackground />}
              <div>
                <div>
                  <AvatarAntd
                    src={newPrivateChater?.profilePicture || ""}
                    icon={<UserOutlined />}
                  />
                </div>
                <div>
                  <div>
                    <div>{newPrivateChater.fullName}</div>
                    <LastMessage>Let's talk</LastMessage>
                  </div>
                  <p></p>
                </div>
              </div>
            </Item>
          )}
          {chatList.map((room, i) => {
            const roomAvatar = getRoomAvatar(room);
            return (
              <Item
                key={room._id}
                style={
                  itemSelectedIndex === i || itemSelectedIndex - 1 === i
                    ? { border: "none" }
                    : {}
                }
                onClick={() => checkRoom(room, i)}
              >
                {itemSelectedIndex === i && <ItemBackground />}
                <div>
                  <div>
                    <AvatarAntd
                      src={roomAvatar?.url || ""}
                      icon={<UserOutlined />}
                    />
                    {!getIsLoggedOut(room) && <label />}
                  </div>
                  <div>
                    <div>
                      <div>{roomAvatar.roomName}</div>
                      {room.unreadMessage ? (
                        <LastUnreadMessage>
                          {lastMessage(
                            room.lastMessageObj[room.lastMessageObj.length - 1]
                              ?.message
                          )}
                        </LastUnreadMessage>
                      ) : (
                        <LastMessage>
                          {lastMessage(
                            room.lastMessageObj[room.lastMessageObj.length - 1]
                              ?.message
                          )}
                        </LastMessage>
                      )}
                    </div>
                    <p>
                      {room.lastMessageTime
                        ? moment(room.lastMessageTime).format("MMM DD")
                        : ""}
                    </p>
                    {room?.unreadMessage ? (
                      <span>{room?.unreadMessage}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Item>
            );
          })}
          {loadMoreList === loadMore.LOADING ? (
            <ItemSpin>
              <SpindAntd />
            </ItemSpin>
          ) : (
            <ItemEnd />
          )}
        </ChaterWrap>
      </LeftSide>
      <Divider />
      <RoomChat
        roomDetail={roomDetail}
        unread={chatList[itemSelectedIndex]?.unreadMessage || 0}
        openNewPrivateRoom={openNewPrivateRoom}
        newPrivateChater={newPrivateChater}
        marginTop={marginTop}
        isOnline={isOnline}
      />
    </ConversationWrap>
  );
};

export default Conversation;
