import { Form, message, Upload, UploadProps } from "antd";
import { AttachIcon, DeleteAttachIcon } from "assets/icons/attach.icon";
import { JobPreferencesIcon } from "assets/icons/common/jobPreferences.icon";
import {
  JobPreferencesContainer,
  StyledFormItem,
  StyledInput,
  JobPreferencesContainerTop,
  UploadContainer,
  UploadButton,
  UploadImage,
  UploadImageContainer,
  FormContainer,
  StyledSelect,
} from "./jobPreferences.style";
import { FC, useEffect, useRef, useState } from "react";
import uploadThumb from "assets/images/uploadThumb.png";
import { IJobPreferencesProps } from "./jobPreferences.props";
import {
  formatterNumber,
  maxLength_60,
  requiredRules,
} from "utils/validatorRules";
import { useAppDispatch } from "redux/hooks";
import { validatePdf } from "utils/regex";
import { postUploadsAPI } from "api/users";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { setPageLoading } from "redux/slices";
import SectionHeader from "../SectionHeader";
import { FormSectionWrapper } from "pages/ProfileUser/profileUserInner.style";

const workingModeOptions = [
  "Full-Time Employment",
  "Part-Time Employment",
  "Freelancing",
  "Bounties",
  "Contract",
  "Consulting",
];

export const JobPreferences: FC<IJobPreferencesProps> = ({
  form,
  setLeaveVisible,
}) => {
  const [fileName, setFileName] = useState<string>();
  const fileRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { mutate: postUploads } = useMutation(postUploadsAPI, {
    onSuccess: async (rsUpload: any) => {
      dispatch(setPageLoading(true));
      await axios
        .put(rsUpload.signedRequest, fileRef.current)
        .then((rs: any) => {
          setFileName(rsUpload.url);
          dispatch(setPageLoading(false));
          setLeaveVisible(true);
          // message.success(`${rsUpload.url} file uploaded successfully`);
        })
        .catch((err: AxiosError) => {
          message.error("Error uploading: " + err.message);
        });
    },
    onError: (err: AxiosError) => {
      message.error(err.message);
    },
  });

  const upLoadProps: UploadProps = {
    accept: ".pdf,.doc,.docs",
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isDoc = validatePdf.test(file.name);
      if (!isDoc) {
        message.error("You can only upload Document!");
        return false;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        const fileTag = file.name.split(".").pop();
        fileRef.current = file;
        postUploads({
          fileName: file.name,
          fileType: `pdf/${fileTag}`,
        });

        return false;
      }
    },
  };

  // Set new url
  useEffect(() => {
    form.setFieldValue(["jobPreference", "attachmentURL"], fileName);
  }, [fileName, form]);

  const fileNameWatch = Form.useWatch(["jobPreference", "attachmentURL"], form);

  return (
    <FormSectionWrapper id="Job Preferences">
      <SectionHeader icon={<JobPreferencesIcon />} title="Job Preferences" />
      <JobPreferencesContainer>
        <JobPreferencesContainerTop>
          <FormContainer>
            <StyledFormItem
              name={["jobPreference", "expectJob"]}
              label={<span>What role you are looking for?</span>}
              rules={[
                maxLength_60,
                form.getFieldValue(["jobPreference", "expectation"])
                  ? requiredRules
                  : {},
              ]}
            >
              <StyledInput placeholder="Ex. Software Engineer" />
            </StyledFormItem>
            <StyledFormItem
              name={["jobPreference", "expectation"]}
              label={<span>Expected Compensation monthly (In USD)</span>}
            >
              <StyledInput
                placeholder="Ex. 10,000"
                value={formatterNumber(
                  +form.getFieldValue(["jobPreference", "expectation"])
                )}
                onChange={(e) =>
                  form.setFieldValue(
                    ["jobPreference", "expectation"],
                    e.target.value.replace(/[^0-9]/g, "")
                  )
                }
              />
            </StyledFormItem>
            <StyledFormItem
              name={["jobPreference", "workingMode"]}
              label={<span>What is your preferred working mode?</span>}
            >
              <StyledSelect
                getPopupContainer={(node) => node?.parentNode as HTMLElement}
              >
                {workingModeOptions.map((item) => (
                  <StyledSelect.Option key={item}>{item}</StyledSelect.Option>
                ))}
              </StyledSelect>
            </StyledFormItem>
            <UploadContainer>
              <StyledFormItem
                hidden
                name={["jobPreference", "attachmentURL"]}
              ></StyledFormItem>
              {(fileName || fileNameWatch) && (
                <UploadImageContainer>
                  <UploadImage height="54px" width="42px" src={uploadThumb} />
                  <div
                    onClick={() => {
                      form.setFieldValue(
                        ["jobPreference", "attachmentURL"],
                        undefined
                      );

                      setFileName(undefined);
                      setLeaveVisible(true);
                    }}
                  >
                    <DeleteAttachIcon />
                  </div>
                </UploadImageContainer>
              )}
              <Upload {...upLoadProps}>
                <UploadButton>
                  <AttachIcon></AttachIcon>
                  <span>Attach Resume</span>
                </UploadButton>
              </Upload>
            </UploadContainer>
          </FormContainer>
        </JobPreferencesContainerTop>
      </JobPreferencesContainer>
    </FormSectionWrapper>
  );
};
