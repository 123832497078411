import PrimaryButton from "components/base/primaryButton";
import styled from "styled-components";
import { CommonFlexRow } from "../OrganizationPage.style";
import OutlinedButton from "components/base/outlinedButton";

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CommonFlexColumn = styled.div<{ space?: string }>`
  display: flex;
  flex-direction: column;
  ${({ space }) => space !== undefined && `gap: ${space}`};
`;

export const OverviewWrapper = styled(CommonFlexColumn)`
  margin-bottom: 32px;
`;

export const FundItem = styled(CommonFlexColumn)`
  padding: 20px 28px;
  justify-content: flex-end;
`;

export const FundItemTitle = styled.span<{ textSize?: string }>`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: ${({ textSize }) => textSize || "24px"};
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #080f6c;
`;

export const FundItemText = styled.span<{ textSize?: string }>`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: ${({ textSize }) => textSize || "14px"};
  line-height: 125%;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const FundInner = styled.div`
  background: #fff;
  border-radius: 7px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;

  ${FundItem}:first-child {
    border-right: 1px solid rgba(156, 138, 193, 0.16);
    border-bottom: 1px solid rgba(156, 138, 193, 0.16);
  }

  ${FundItem}:nth-child(2) {
    border-bottom: 1px solid rgba(156, 138, 193, 0.16);
  }

  ${FundItem}:nth-child(3) {
    border-right: 1px solid rgba(156, 138, 193, 0.16);
  }
`;

export const GradientBorder = styled.div`
  padding: 1px;
  border-radius: 8px;
  background: radial-gradient(
    100% 445.32% at 0% 100%,
    #7e00fd 0%,
    #b93df3 42.35%,
    #a54bf4 57.95%,
    #00bffb 100%
  );
  position: relative;
  margin-top: 30px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(
      130.66deg,
      rgb(126, 0, 253, 0.6) 0%,
      rgb(193, 67, 252, 0.6) 46.87%,
      rgb(0, 191, 251, 0.6) 100%
    );
    filter: blur(18px);
    z-index: -1;
  }
`;

export const FundWrapper = styled.div``;

export const WalletItemText = styled(FundItemTitle)`
  font-weight: 400;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  letter-spacing: normal;
`;

export const WalletItemTitle = styled(FundItemTitle)`
  font-weight: 500;
  letter-spacing: normal;
`;

export const WalletItemDescWrapper = styled(CommonFlexRow)`
  justify-content: space-between;

  > ${WalletItemTitle}:first-child {
    flex: 0 0 41%;
    word-break: break-all;
  }
`;

export const WalletItemInfo = styled(CommonFlexColumn)`
  flex-grow: 1;
`;

export const WalletItem = styled(CommonFlexRow)`
  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  padding: 20px;
`;

export const WalletTitleAndAction = styled(CommonFlexRow)`
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const WalletWrapper = styled.div`
  flex-grow: 1;
`;

export const AddWalletBtn = styled(PrimaryButton)``;

export const SectionTitle = styled.h4`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-bottom: 0;
`;

export const FundAndWalletWrapper = styled(CommonFlexColumn)`
  margin-bottom: 64px;
`;

export const BlurText = styled(FundItemTitle)`
  color: rgba(41, 46, 115, 0.42);
  font-weight: 400;
  letter-spacing: normal;
`;

export const BlockchainValue = styled(FundItemTitle)`
  letter-spacing: normal;
`;

export const WorktaskName = styled(FundItemText)`
  letter-spacing: normal;
  overflow-wrap: break-word;

  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
  }
`;

export const BlockChainName = styled(FundItemText)`
  letter-spacing: -0.01em;
  color: #080f6c;
`;

export const ViewProjectBtn = styled(OutlinedButton)`
  margin-left: auto;
  width: unset;
  height: unset;
  padding: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  color: #7e00fd;
`;

export const TagStatus = styled.div<{
  bg?: string;
  cl?: string;
}>`
  padding: 6px 12px;
  border-radius: 20px;
  background: ${({ bg }) => bg || "rgba(255, 87, 34, 0.16)"};
  border: 1px solid ${({ bg }) => bg || "rgba(255, 87, 34, 0.16)"};
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: ${({ cl }) => cl || "#FF5722"};
`;

export const TransactionWorktaskWrapper = styled(CommonFlexRow)`
  padding-left: 40px;
  flex-grow: 1;
  gap: 10px;
  min-width: 0;
  // justify-content: space-between;

  > div:first-child {
    // flex-basis: 50%;
    flex: 0 0 50%;
    min-width: 0;
  }
`;

export const TransactionInfo = styled(CommonFlexRow)`
  border-right: 1px solid rgba(156, 138, 193, 0.16);
  flex-basis: 36%;
  flex-shrink: 0;
`;

export const OverviewItem = styled(WalletItem)`
  justify-content: space-between;

  > span {
    flex-basis: 45%;
  }

  > div:nth-last-child(2) {
    flex-basis: 25%;
  }

  > div:last-child {
    flex-basis: 30%;
  }
`;

export const TransactionItem = styled(WalletItem)`
  align-items: flex-start;
`;

export const TransactionList = styled(CommonFlexColumn)`
  margin-top: 20px;
`;

export const TransactionsWrapper = styled.div``;

export const TransactionWrapper = styled.section`
  max-width: 981px;
  padding-top: 48px;
`;

export const PaginationWrapper = styled.div`
  margin-block: 20px;
`;
