import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { handleLongText } from "utils/text";
import { IPortfolioCardProps } from "./portfolioCard.props";
import {
  CardContainer,
  CardFooter,
  CardImage,
  Title,
} from "./portfolioCard.style";

const PortfolioCard: FC<IPortfolioCardProps> = ({
  portfolio,
  isFetching,
  viewOtherUser,
}) => {
  const navigate = useNavigate();

  const handleClickCard = () => {
    navigate(
      `${ROUTER_CONSTANTS.USER.PORTFOLIO}/${portfolio?.portfolio?._id}`,
      {
        state: {
          viewOtherUser,
          selectedPortfolio: portfolio?.portfolio,
          finishedSubmitting: false,
        },
      }
    );
  };

  return (
    <CardContainer>
      <div onClick={handleClickCard}>
        {!isFetching ? (
          <CardImage bg={portfolio?.portfolio?.image}></CardImage>
        ) : (
          <Skeleton
            style={{
              height: "245px",
              paddingTop: 0,
              marginBottom: "-5px",
              borderRadius: "0.25rem 0.25rem 0 0",
              transform: "translateY(-5px)",
            }}
          />
        )}
        <CardFooter>
          {!isFetching ? (
            <Title>
              {handleLongText(portfolio?.portfolio?.projectTitle, 60)}
            </Title>
          ) : (
            <Skeleton
              count={1}
              height={30}
              width={210}
              style={{
                transform: "translateY(-10px)",
              }}
            />
          )}
        </CardFooter>
      </div>
    </CardContainer>
  );
};

export default PortfolioCard;
