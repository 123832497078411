import RewardIcon from "assets/images/png/general-reward.png";
import CommunityIcon from "assets/images/png/general-community.png";
import PortfolioIcon from "assets/images/png/general-portfolio.png";
import CollaboratorIcon from "assets/images/png/general-collaborator.png";
import BlockChainIcon from "assets/images/png/block-chain.png";
import ChainIcon from "assets/images/png/off_chain.png";
import ChartIcon from "assets/images/png/bar_chart.png";
import DartTableIcon from "assets/images/png/dart-table.png";
import CryptoIcon from "assets/images/png/crypto.png";
import DigitalWallet from "assets/images/png/digital_wallet.png";
import { IProjectGroupData } from "components/modules/projectGroup/projectGroup.props";
import { ROUTER_CONSTANTS } from "./constant";

export const GROUP_DATA: IProjectGroupData[] = [
  {
    title: "SEED PROJECTS 🌱",
    description:
      "Join a community of collaborators working with distributed teams on early-stage projects with high potential. Influence project direction and earn governance and token allocations that grow with your contributions.",
    btn: "BUILD with us!",
    extendBtn: true,
    btnRedirect: "",
  },
  {
    title: "OPEN BOUNTIES 🚀",
    description:
      "Earn tokens while working on the most exciting Web3 projects.",
    btn: "Start Contributing",
    extendBtn: true,
    btnRedirect: ROUTER_CONSTANTS.USER.LOGIN,
    btnKey: "BECOME_A_CONTRIBUTOR",
  },
  {
    title: "LEARN2EARN 📚",
    description:
      "Learn & Earn is a platform that allows you to earn token rewards while learning new skills on DAOs, Smart Contracts, Blockchain, and Web 3.0.",
    btn: "Start Learning",
    extendBtn: true,
    btnRedirect: "",
  },
];

export const GENERAL_INFO = [
  {
    title: "The Web3 Ecosystem That’s Truly Yours",
    content: (
      <div>
        The <span>BuidlFi</span> ecosystem promotes transparency,
        community-bonding and collaboration between project sponsors and
        contributors.
      </div>
    ),
    icon: RewardIcon,
    bgColor: "rgb(126 0 252 / 10%)",
  },
  {
    title: "Data-driven Insights on project performance",
    content: (
      <div>
        <span>BuidlFi</span> uses on-chain metrics focused on results and
        objectives. This helps you, the sponsor, with the rewards
        decision-making process.
      </div>
    ),
    icon: PortfolioIcon,
    bgColor: "rgb(0 71 253 / 10%)",
  },
  {
    title: "Tri-Party Evaluation Algorithm",
    content: (
      <div>
        Observation from other collaborators, project team, and{" "}
        <span>BuidlFi</span> observers guarantees a democratic evaluation
        process.
      </div>
    ),
    icon: CommunityIcon,
    bgColor: "rgb(35 253 0 / 10%)",
  },
  {
    title: "Web3 Project Management Made Easy Truly Yours",
    content: (
      <div>
        <span>BuidlFi</span> provides project teams with collaboration
        infrastructure contributing to their success, creating a network effect
        and promoting communities.
      </div>
    ),
    icon: CollaboratorIcon,
    bgColor: "rgb(253 46 0 / 10%)",
  },
];

export const GENERAL_FULLY = [
  {
    title: "Progressive Decentralization",
    content:
      "BuidlFi helps you progressively decentralize by inviting your participation, helping you build towards product/market fit, and ultimately handing control to you.",
    icon: ChainIcon,
  },
  {
    title: "Sustainable Fee Margins",
    content:
      "BuidlFi helps you achieve sustainability through minimally extractive fee margins and distribution to active community contributors.",
    icon: ChartIcon,
  },
  {
    title: "Community Participation Options",
    content:
      "You can participate in the BuidlFi community through bounties, grants, rough consensus, and permissioned voting.",
    icon: DartTableIcon,
  },
  {
    title: "Token Distribution Progression",
    content:
      "Token distribution progresses to promissory or permissioned distribution, and finally, airdropping to you and other decentralized contributors.",
    icon: BlockChainIcon,
  },
  {
    title: "Token Status",
    content:
      "Tokens are deemed governance shares until their control is adequately decentralized.",
    icon: CryptoIcon,
  },
  {
    title: "Next Objective Attainment:",
    content:
      "You'll reach the next objective when there are network effects and the community is self-sufficient.",
    icon: DigitalWallet,
  },
];
