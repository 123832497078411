import { Button } from "antd";
import styled from "styled-components";

export const ButtonWhiteAntd = styled(Button)`
  background: #ffffff;
  box-shadow: 0 0.5rem 1rem rgba(17, 17, 17, 0.06);
  border-radius: 0.25rem;
  color: #080f6c;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  height: 2rem;
  padding: auto 1.25rem;

  &:hover,
  &:focus {
    color: black;
    border: 0.0625rem solid rgba(0, 209, 150, 0.5);
    svg {
      fill:black;
    }
  }
`;
