import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  padding: 0px;
  position: relative;
`;

export const A = styled(Link)<{ color: string | undefined }>`
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: ${(p) => p.color || "rgba(0, 0, 0, 0.5)"};
  word-break: break-all;

  &:hover {
    color: ${(p) => p.color || "rgba(0, 0, 0, 0.5)"};
  }
`;

export const Wrap = styled.div`
  padding: 10px 0px;
  max-width: 1170px;
  margin: auto;
  display: flex;
  align-items: center;
  // height: 44px;
  > ${A}:last-child {
    flex-shrink: 0;
  }
`;
export const Background = styled.div`
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) rotate(-180deg);
  width: 100%;
  bottom: 0;
  right: 0;
`;

export const Separator = styled.div<{ color: string | undefined }>`
  color: ${(p) => p.color || "rgba(0, 0, 0, 0.5)"};
  margin: 0px 19px;
  display: flex;
  align-items: center;
`;
