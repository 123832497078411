import styled from "styled-components";

export const ProjectList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(376px, 1fr));
  gap: 20px;
`;

export const ProjectTitle = styled.h3`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #080f6c;
`;

export const ProjectWrapper = styled.section`
  padding-top: 48px;
`;
