export const ClockIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 14.5C10.0376 14.5 12.5 12.0376 12.5 9C12.5 5.96243 10.0376 3.5 7 3.5C3.96243 3.5 1.5 5.96243 1.5 9C1.5 12.0376 3.96243 14.5 7 14.5Z"
        stroke="#FF5722"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7 9.00026L9.47487 6.52539"
        stroke="#FF5722"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 1.5H8.5"
        stroke="#FF5722"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ClockIconStatus = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.817 8.48479L11.4107 8.48413L11.4083 14.1095H17.0357V12.7032H12.8151L12.817 8.48479Z"
        fill="white"
      ></path>
      <path
        d="M17.9148 6.34748L19.1426 4.59408L17.9906 3.7875L16.7637 5.53973C15.5906 4.84364 14.2496 4.40189 12.817 4.29239V2.85938H14.2232V1.45312H12.817H11.4107H10.0045V2.85938H11.4107V4.29239C9.97809 4.40189 8.63714 4.84364 7.464 5.53973L6.23709 3.7875L5.08519 4.59408L6.31294 6.34748C5.16863 7.28958 4.25672 8.50406 3.67678 9.89062L3.21937 11.2969C3.11109 11.7535 3.0367 12.2231 3.00005 12.7031L3 14.1094C3.0367 14.5894 3.11105 15.059 3.21933 15.5156L3.67713 16.9219C5.05741 20.222 8.31886 22.5469 12.1139 22.5469C17.154 22.5469 21.2545 18.4464 21.2545 13.4062C21.2545 10.5666 19.9528 8.02528 17.9148 6.34748ZM12.1139 21.1406C9.11423 21.1406 6.50798 19.4242 5.22567 16.9219H7.19199V15.5156H4.67189C4.54322 15.0623 4.45552 14.5921 4.41178 14.1094H5.78573V12.7031H4.41178C4.45552 12.2204 4.54322 11.7502 4.67189 11.2969H7.19199V9.89062H5.22567C6.50798 7.38834 9.11423 5.67188 12.1139 5.67188C16.3786 5.67188 19.8482 9.14152 19.8482 13.4062C19.8482 17.671 16.3786 21.1406 12.1139 21.1406Z"
        fill="white"
      ></path>
    </svg>
  );
};

export const ClockTransparentIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327Z"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5V10L13.3333 11.6667"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ParticipantIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13605_10937)">
        <path
          d="M14.1663 17.5V15.8333C14.1663 14.9493 13.8152 14.1014 13.19 13.4763C12.5649 12.8512 11.7171 12.5 10.833 12.5H4.16634C3.28229 12.5 2.43444 12.8512 1.80932 13.4763C1.1842 14.1014 0.833008 14.9493 0.833008 15.8333V17.5"
          stroke="#292E73"
          strokeOpacity="0.42"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.50033 9.16667C9.34127 9.16667 10.8337 7.67428 10.8337 5.83333C10.8337 3.99238 9.34127 2.5 7.50033 2.5C5.65938 2.5 4.16699 3.99238 4.16699 5.83333C4.16699 7.67428 5.65938 9.16667 7.50033 9.16667Z"
          stroke="#292E73"
          strokeOpacity="0.42"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.167 17.4991V15.8324C19.1664 15.0939 18.9206 14.3764 18.4681 13.7927C18.0156 13.209 17.3821 12.7921 16.667 12.6074"
          stroke="#292E73"
          strokeOpacity="0.42"
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M13.333 2.60742C14.05 2.79101 14.6855 3.20801 15.1394 3.79268C15.5932 4.37736 15.8395 5.09645 15.8395 5.83659C15.8395 6.57673 15.5932 7.29582 15.1394 7.8805C14.6855 8.46517 14.05 8.88217 13.333 9.06576"
          stroke="#292E73"
          strokeOpacity="0.42"
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13605_10937">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ExerciseIcon = () => {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 11L16 6L11 1M6 1L1 6L6 11"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
