import { Col, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;

  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.5rem;
  padding: 1.25rem 0rem 1.5rem 1.5rem;
  display: flex;
  margin-bottom: 10px;
  transition: 0.3s;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 9px;
  }
`;
export const Right = styled.div`
  padding-right: 4.125rem;
  width: 65.7%;
`;
export const Title = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 115%;
  letter-spacing: -0.03em;
  color: #080f6c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const WorktaskDesc = styled(Subtitle)`
  margin-bottom: 18px;
  width: auto;
  overflow-wrap: break-word;
`;

export const WrapperFlex = styled.div<{ space?: string }>`
  display: flex;
  align-items: center;
  ${({ space }) =>
    space !== undefined &&
    `
    gap: ${space};
  `}
`;

export const SponsorText = styled(Subtitle)`
  margin-bottom: 5px;
  width: auto;
  font-family: "Rubik";
  font-size: 14px;
  line-height: 110%;
`;

export const InforWrap = styled.div`
  background: rgba(156, 138, 193, 0.08);
  border-radius: 0.25rem;
  padding: 0.6875rem 0.75rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;
export const Devider = styled.div`
  border: 0.0313rem solid rgba(156, 138, 193, 0.16);
  margin: 0rem 1.25rem;
  height: 1.25rem;
  width: 0rem;
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
`;
export const Property = styled.div<{
  marginRight?: number;
  width?: string;
}>`
  font-weight: 400;
  font-size: 0.875rem;
  /* line-height: 140%; */
  color: rgba(41, 46, 115, 0.82);
  margin-right: ${(p) => (p.marginRight || "0") + "px"};
  width: ${(p) => p.width || "auto"};
`;

export const Budget = styled.div`
  font-family: "Rubik";
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  color: #080f6c;
  max-width: 134px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Money = styled(Budget)`
  max-width: 95px;
`;

export const Prifix = styled.div`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 115%;
  color: rgba(41, 46, 115, 0.656);
  margin-left: 0.3125rem;
`;
export const Icon = styled.div`
  margin-right: 0.5313rem;
  height: 1.5rem;
`;
export const AvatarsWrap = styled.div`
  display: flex;
  align-items: center;
  & > img:nth-child(0) {
    margin-left: 0rem;
  }
  span {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-weight: 500;
    font-size: 9px;
    line-height: 10px;
    background: #f57f20;
    border: 1px solid #ffffff;
    border-radius: 20px;
  }
`;
export const Avatar = styled.img`
  object-fit: cover;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  border: 0.0625rem solid #ffffff;
  margin-left: -0.25rem;
`;
export const WrapCategory = styled.div<{
  color?: string;
  background?: string;
}>`
  padding: 0.1875rem 0.5rem;
  border-radius: 1.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 115%;
  letter-spacing: -0.03em;
  background: ${(p) => p.background || "rgba(156, 138, 193, 0.16)"};
  color: ${(p) => p.color || "#080f6c"};
  width: max-content;
  height: max-content;

  &.category-tag {
    flex-shrink: 0;
  }
`;
export const WrapTag = styled(WrapCategory)<{
  color: string | undefined;
  background: string | undefined;
}>`
  line-height: 100%;
  letter-spacing: -0.01em;
  background: ${(p) => p.background || "transparent"};
  color: ${(p) => p.color || "#ffffff"};
`;
export const WrapTimeRemaining = styled(WrapCategory)`
  color: #7e00fd;
  line-height: 100%;
  letter-spacing: -0.01em;
  border: 0.0625rem solid #7e00fd;
`;
export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 115%;
  height: 2rem;
`;
export const NotAccepted = styled(Center)`
  color: #f52020;
  border-radius: 0.25rem;
  border: 0.0625rem solid #f52020;
  width: 7.1875rem;
  background: #fff;
`;
export const Applied = styled(Center)`
  width: 4.75rem;
  background: rgba(156, 138, 193, 0.16);
  color: rgba(41, 46, 115, 0.42);
  height: 36px;
`;
export const Value = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  color: #080f6c;
`;

export const Left = styled.div`
  padding-left: 3.125rem;
  width: calc(100% - 65.7%);
  border-left: 0.0625rem solid rgba(41, 46, 115, 0.12);
  display: flex;
  flex-direction: column;
  gap: 1.1875rem;
  justify-content: center;
`;

export const TitleModal = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin: 5px auto 1.25rem;
`;

export const SponsorWrapper = styled(WrapperFlex)`
  margin-block: 0.625rem;
  flex-wrap: wrap;

  ${SponsorText} {
    margin-block: 0;
    margin-right: 0.5rem;
  }

  > span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 110%;
    margin-right: 6px;
  }
`;

export const CollabWrapper = styled(WrapperFlex)``;

export const RequestNumber = styled.span`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  color: rgba(41, 46, 115, 0.82);
`;

export const BtnActionsWrapper = styled(WrapperFlex)``;

export const NumberInforWrap = styled(Row)`
  padding: 0.75rem 0rem;
  display: flex;
  column-gap: 2.875rem;
`;

export const NumberItem = styled(Col)``;

export const PaymentTitle = styled.div`
  display: flex;
  color: rgba(41, 46, 115, 0.82);
  align-items: center;
  cursor: pointer;
  font-style: "Regular";
  font-weight: 400;
  font-size: 0.875rem;
  font-family: "Rubik";
  line-height: 1.225rem;
`;

export const PaymentValue = styled.p`
  cursor: pointer;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  font-style: "Medium";
  color: #080f6c;
  margin: 0.0625rem 0rem;
  letter-spacing: -0.04em;
`;

export const DeliverableWrap = styled.div<{ marginBottom?: string }>`
  border: 0.0313rem solid rgba(156, 138, 193, 0.16);
  margin-right: 17.6875rem;
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom};` : ``}
`;
