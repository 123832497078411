import { useMutation } from "@tanstack/react-query";
import {
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  Upload,
  UploadProps,
} from "antd";
import { postUploadsAPI } from "api/users";
import { SelectArrowDown } from "assets/icons/common/selectArrowDown.icon";
import { FC, ReactElement, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import {
  maxLength_60,
  numberRulesWithZero,
  requiredDateRules,
  requiredRules,
  stringRequired,
} from "utils/validatorRules";
import {
  StyledInput,
  StyledTextArea,
  BigLabel,
  LabelDescription,
  StyledUpload,
  StyledSelect,
  StyledOption,
  Container,
  CoverImage,
  ProjectThumbWrapper,
  UploadWrapper,
  ExpireDateWrapper,
  ExpireSwitch,
} from "./basicInformation.style";
import axios, { AxiosError } from "axios";
import { nextStep, setCourseInfo } from "redux/slices";
import { acceptImage, validateImage } from "utils/regex";
import DatePicker from "components/base/datePicker";
import dayjs from "dayjs";
import OutlinedButton from "components/base/outlinedButton";
import { UploadCloudIcon } from "assets/icons/upload.icon";
import {
  ContentWrapper,
  StepContentWrapper,
  StepDesc,
} from "../createCourse.style";
import { COURSE_DURATION_TYPE } from "utils/course";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import type { NamePath } from "antd/lib/form/interface";
import { IBasicInformationProps } from "./basicInformation.props";
import { COURSE_STATUS, COURSE_TYPE, ICategory, ICourseForm } from "api/course";
import {
  CreateOrgWrapper,
  OrgCreateBtn,
  RegisterDesc,
} from "pages/CreateProject/ProjectStepOne/projectStepOne.style";
import { ROUTER_CONSTANTS } from "utils/constant";
import { useGetCourseCategories } from "api/course/queries";
import type { UploadRequestOption } from "rc-upload/lib/interface";
import { useDispatch } from "react-redux";
import { HeadingCourse } from "../components/HeadingCourse";
import { scrollToFirstErrorSmooth } from "utils/scrollToSection";
import { convertTimeTo, TimeUnit } from "utils/time";
import { useWatch } from "antd/lib/form/Form";

const BasicInformation: FC<IBasicInformationProps> = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const user: any = useAppSelector((state) => state.auth.user);
  const { step, course } = useAppSelector((state) => state.course);
  const [rangeUnit, setRangeUnit] = useState<TimeUnit>(
    ((course.info?.range ?? 0) / 3600) % 24 === 0
      ? TimeUnit.DAYS
      : TimeUnit.HOURS
  );
  const [estUnit, setEstUnit] = useState<TimeUnit>(
    ((course.info?.estimatedTime ?? 0) / 3600) % 24 === 0
      ? TimeUnit.DAYS
      : TimeUnit.HOURS
  );

  const fileNameCover = Form.useWatch("coverImage", form);
  const [categoryName, setCategoryName] = useState<string>("");
  const [noExpire, setNoExpire] = useState<boolean>(!!course.info?.noExpire);
  const [imageUploading, setImageUploading] = useState(false);
  const dispatch = useDispatch();

  const { data } = useGetCourseCategories();
  const categories: ICategory[] = data?.responseData ?? [];

  const { mutateAsync: postUploads } = useMutation(postUploadsAPI);
  const customRequest = ({ file }: UploadRequestOption<any>) => {
    setImageUploading(true);
    postUploads({
      fileName: (file as any).name,
      fileType: (file as any).type,
    }).then((rsUpload) => {
      axios
        .put(rsUpload.signedRequest, file)
        .then(() => {
          form.setFieldValue("coverImage", rsUpload.url);
          setImageUploading(false);
        })
        .catch((err: AxiosError) => {
          message.error("Error uploading: " + err.message);
        });
    });
  };

  const upLoadCoverImageProps: UploadProps = {
    listType: "picture-card",
    accept: acceptImage,
    multiple: false,
    showUploadList: false,
    customRequest: customRequest,
    beforeUpload: async (file: File) => {
      const isImage = validateImage.test(file.name);
      if (!isImage) {
        message.error("You can only upload Image!");
        return Upload.LIST_IGNORE;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return Upload.LIST_IGNORE;
      }
    },
    fileList: [],
  };

  useEffect(() => {
    if (!course.info) {
      form.resetFields();
    }
  }, [course.info, form]);

  const handleSubmit = async (values: ICourseForm) => {
    if (!user?.organization) {
      message.error("Please become an organization before proceeding");
      return;
    }
    dispatch(
      setCourseInfo({
        title: values.title,
        description: values.description,
        category: values.category,
        coverImage: values.coverImage ?? "",
        startDate:
          values.type === COURSE_TYPE.CUSTOM_DAY_PLAN
            ? null
            : dayjs((values as any).startDate.utc()).startOf("date"),
        endDate:
          values.type === COURSE_TYPE.CUSTOM_DAY_PLAN
            ? null
            : dayjs((values as any).endDate.utc()).endOf("date"),
        type: values.type as COURSE_TYPE,
        range:
          values.type === COURSE_TYPE.CUSTOM_DAY_PLAN
            ? convertTimeTo({
                value: values.range,
                convertFromDBToForm: false,
                type: rangeUnit,
              }).value
            : 0,
        estimatedTime: convertTimeTo({
          value: values.estimatedTime,
          convertFromDBToForm: false,
          type: estUnit,
        }).value,
        noExpire,
        status: course.info?.status ?? COURSE_STATUS.DRAFT,
      })
    );
    dispatch(nextStep());
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    return current < dayjs().subtract(1, "day").endOf("day");
  };

  const dateValidator = (
    getFieldValue: (name: NamePath) => any,
    value: any
  ) => {
    const curStartDate = getFieldValue("startDate");

    if (!value || !curStartDate) return Promise.resolve();

    if (noExpire) return Promise.resolve();

    if (dayjs(value).diff(dayjs(curStartDate)) < 0)
      return Promise.reject("End date must be bigger than start date");

    return Promise.resolve();
  };

  const handleAddCate = () => {
    const limitChars = 50;

    if (categoryName.trim().length === 0) {
      message.error(`Must have not whitespace character`);

      return;
    }

    if (categoryName.trim().length > limitChars) {
      message.error(`Limit is ${limitChars} characters`);

      return;
    }

    categories.push({
      name: categoryName,
    });
    setCategoryName("");
  };

  const handleSwitchToNoExpire = () => {
    form.setFields([
      {
        name: "endDate",
        value: form.getFieldValue("endDate"),
        errors: [],
      },
      {
        name: "estimatedTime",
        value: form.getFieldValue("estimatedTime"),
        errors: [],
      },
    ]);
    setNoExpire(!noExpire);
  };

  const disableAddCate =
    categories.some((cate) => cate.name === categoryName) || !categoryName;

  const [placement, setPlacement] = useState(false);
  const handlePlaceDatePicker = (e: any) => {
    if (e.screenY > window.screen.height * 0.6) setPlacement(true);
    else setPlacement(false);
  };

  const estimatedTimeValidator = (
    getFieldValue: (name: NamePath) => any,
    value: any
  ) => {
    const currentDurationType = getFieldValue("type");
    const multipleEst = estUnit === TimeUnit.DAYS ? 24 : 1;
    const multipleRage = rangeUnit === TimeUnit.DAYS ? 24 : 1;
    const currRange = getFieldValue("range");
    const currType = getFieldValue("type");

    if (!currType) return Promise.resolve();

    if (currentDurationType === COURSE_TYPE.START_AND_END_DATE) {
      const currStartTime = dayjs(getFieldValue("startDate")?.utc()).startOf(
        "date"
      );
      const currEndTime = dayjs(getFieldValue("endDate")?.utc()).endOf("date");

      if (!value || !currStartTime || !currEndTime) return Promise.resolve();
      if (noExpire) return Promise.resolve();
      //  compare est with endtime-starttime
      const hourDiff = Math.round(currEndTime.diff(currStartTime, "h"));
      if (multipleEst * value <= hourDiff) return Promise.resolve();

      return Promise.reject(
        "The estimate time must be smaller than time range"
      );
    }

    if (+currRange * multipleRage >= value * multipleEst)
      return Promise.resolve();

    return Promise.reject("The estimate time must be smaller than time range");
  };

  const type = useWatch("type", form);
  useEffect(() => {
    const estTime = form.getFieldValue("estimatedTime");
    if (estTime && type === COURSE_TYPE.CUSTOM_DAY_PLAN)
      form.validateFields(["estimatedTime", "range"]);
    else if (estTime && type === COURSE_TYPE.START_AND_END_DATE)
      form.validateFields(["estimatedTime"]);
  }, [estUnit, form, rangeUnit, type]);

  useEffect(() => {
    form.setFieldsValue({
      estUnit,
      rangeUnit,
      noExpire,
    });
  }, [estUnit, rangeUnit, noExpire, form]);

  const disableSensitiveField = course.info?.status === COURSE_STATUS.PUBLISHED;

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        scrollToFirstError={scrollToFirstErrorSmooth}
        initialValues={{
          ...course.info,
          estimatedTime: convertTimeTo({
            value: course.info?.estimatedTime,
            convertFromDBToForm: true,
          }).value,
          range: convertTimeTo({
            value: course.info?.range,
            convertFromDBToForm: true,
          }).value,
        }}
      >
        <HeadingCourse step={step} loading={imageUploading} form={form} />
        <ContentWrapper>
          <StepDesc>
            LEARN2EARN courses are immersive and interactive learning
            experiences designed for individuals seeking to acquire valuable
            skills in the Web3 ecosystem. Led by industry experts, these courses
            provide comprehensive knowledge of blockchain programming, DAOs,
            smart contracts, and more.
            <br /> <br />
            Each course is carefully curated to empower learners with practical
            skills and understanding to unlock exciting opportunities in the
            decentralised web.
          </StepDesc>
          {user?.organization ? null : (
            <CreateOrgWrapper space="10px">
              <RegisterDesc>
                If you haven't registered an organisation, please create one
                here.
              </RegisterDesc>
              <OrgCreateBtn
                onClick={() => navigate(ROUTER_CONSTANTS.ORGANIZATION.CREATE)}
              >
                Create New Organisation +
              </OrgCreateBtn>
            </CreateOrgWrapper>
          )}
          <StepContentWrapper>
            <Container>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item
                    label="Name"
                    name="title"
                    rules={[stringRequired, maxLength_60]}
                  >
                    <StyledInput placeholder="What should we put at the top of your page?" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Category"
                    name="category"
                    rules={[requiredRules]}
                  >
                    <StyledSelect
                      suffixIcon={<SelectArrowDown />}
                      placeholder="Course's Category"
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8,
                            }}
                          >
                            <StyledInput
                              size="small"
                              value={categoryName}
                              onChange={(e) => setCategoryName(e.target.value)}
                            />
                            <OutlinedButton
                              disabled={disableAddCate}
                              type="link"
                              onClick={handleAddCate}
                            >
                              + Add category
                            </OutlinedButton>
                          </div>
                        </div>
                      )}
                    >
                      {categories.map((category) => (
                        <StyledOption value={category.name} key={category.name}>
                          {category?.name}
                        </StyledOption>
                      ))}
                    </StyledSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[stringRequired]}
                  >
                    <StyledTextArea placeholder="Tell us what your course is. Keep it short and sweet!" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <ProjectThumbWrapper>
                    <Form.Item name="coverImage" rules={[requiredRules]}>
                      <StyledUpload
                        {...upLoadCoverImageProps}
                        className={
                          fileNameCover
                            ? "upload-image noBorder"
                            : "upload-image"
                        }
                      >
                        {fileNameCover && !imageUploading ? (
                          <CoverImage src={fileNameCover} />
                        ) : (
                          <UploadWrapper>
                            {imageUploading ? (
                              <LoadingOutlined />
                            ) : (
                              <UploadCloudIcon />
                            )}
                          </UploadWrapper>
                        )}
                      </StyledUpload>
                    </Form.Item>
                    <div>
                      <BigLabel>Course thumbnail</BigLabel>
                      <LabelDescription>
                        Recommended size: 376x128 .jpg .png | Max size: 5MB
                      </LabelDescription>
                    </div>
                  </ProjectThumbWrapper>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item
                    name="estimatedTime"
                    dependencies={["range", "startDate", "endDate"]}
                    rules={[
                      requiredRules,
                      numberRulesWithZero,
                      ({ getFieldValue }) => ({
                        validator: (_, value) => {
                          return estimatedTimeValidator(getFieldValue, value);
                        },
                      }),
                    ]}
                    label="Estimated time of completion"
                  >
                    <StyledInput
                      type="number"
                      placeholder="Estimated time of completion"
                      min={0}
                      addonBefore={
                        <Select
                          style={{ width: 100 }}
                          defaultValue={estUnit}
                          onChange={(value) => setEstUnit(value)}
                        >
                          <StyledSelect.Option value={TimeUnit.HOURS}>
                            {TimeUnit.HOURS}
                          </StyledSelect.Option>
                          <StyledSelect.Option value={TimeUnit.DAYS}>
                            {TimeUnit.DAYS}
                          </StyledSelect.Option>
                        </Select>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="type"
                    rules={[requiredRules]}
                    label="Reward Allocation"
                  >
                    <StyledSelect
                      suffixIcon={<SelectArrowDown />}
                      placeholder="Reward Allocation"
                      onChange={() => {
                        form.setFields([
                          {
                            name: "estimatedTime",
                            value: form.getFieldValue("estimatedTime"),
                            errors: [],
                          },
                        ]);
                      }}
                      disabled={disableSensitiveField}
                    >
                      {COURSE_DURATION_TYPE.map((item) => (
                        <StyledOption value={item.value} key={item.value}>
                          {item.title}
                        </StyledOption>
                      ))}
                    </StyledSelect>
                  </Form.Item>
                </Col>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.type !== currentValues.type
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue("type") === COURSE_TYPE.START_AND_END_DATE ? (
                      <>
                        <Col span={12}>
                          <Form.Item
                            name="startDate"
                            rules={[requiredDateRules]}
                            label="Start Date"
                          >
                            <DatePicker
                              placeholder="Start Date"
                              disabledDate={disabledDate}
                              format="YYYY-MM-DD"
                              disabled={disableSensitiveField}
                              onMouseEnter={handlePlaceDatePicker}
                              placement={placement ? "topRight" : "bottomRight"}
                            />
                          </Form.Item>
                        </Col>
                        <ExpireDateWrapper span={12}>
                          <Form.Item
                            name="endDate"
                            dependencies={["startDate"]}
                            rules={
                              noExpire
                                ? []
                                : [
                                    requiredDateRules,
                                    ({ getFieldValue }) => ({
                                      validator: (_, value) => {
                                        return dateValidator(
                                          getFieldValue,
                                          value
                                        );
                                      },
                                    }),
                                  ]
                            }
                            label="Expiry Date"
                          >
                            <DatePicker
                              placeholder="End Date"
                              disabledDate={disabledDate}
                              disabled={noExpire || disableSensitiveField}
                              format="YYYY-MM-DD"
                              onMouseEnter={handlePlaceDatePicker}
                              placement={placement ? "topRight" : "bottomRight"}
                            />
                          </Form.Item>

                          <ExpireSwitch
                            disabled={disableSensitiveField}
                            defaultChecked={!course.info?.noExpire}
                            onChange={handleSwitchToNoExpire}
                          />
                        </ExpireDateWrapper>
                      </>
                    ) : getFieldValue("type") ===
                      COURSE_TYPE.CUSTOM_DAY_PLAN ? (
                      <Col span={12}>
                        <Form.Item
                          label="Time range"
                          name="range"
                          rules={[requiredRules, numberRulesWithZero]}
                        >
                          <StyledInput
                            type="number"
                            placeholder="Input course's time range!"
                            disabled={disableSensitiveField}
                            min={0}
                            addonBefore={
                              <Select
                                style={{ width: 100 }}
                                disabled={disableSensitiveField}
                                defaultValue={rangeUnit}
                                onChange={(value) => setRangeUnit(value)}
                              >
                                <StyledSelect.Option value={TimeUnit.HOURS}>
                                  {TimeUnit.HOURS}
                                </StyledSelect.Option>
                                <StyledSelect.Option value={TimeUnit.DAYS}>
                                  {TimeUnit.DAYS}
                                </StyledSelect.Option>
                              </Select>
                            }
                          />
                        </Form.Item>
                      </Col>
                    ) : null
                  }
                </Form.Item>
              </Row>
            </Container>
          </StepContentWrapper>
        </ContentWrapper>
        <Form.Item noStyle name="estUnit" />
        <Form.Item noStyle name="rangeUnit" />
        <Form.Item noStyle name="noExpire" />
      </Form>
    </>
  );
};

export default BasicInformation;
