import { FC, useState } from "react";
import { ISubmitDeliverableProps } from "./SubmitDeliverable.props";
// import SubmitDeliverableBackground from "assets/images/submit-deliverable/submit-deliverable-background.png";
import {
  DocumentIcon,
  // MailIcon,
  NoteIcon,
  UploadIcon,
} from "assets/images/submit-deliverable";
import {
  // Container,
  // BackgroundImage,
  // TitleWrap,
  BodyWrap,
  SubmitNew,
  DeliverableWrap,
  HeadSection,
  SearchInput,
  FieldWrap,
  Field1,
  Field2,
  Field3,
  ItemWrap,
  Item,
  ItemTitle1,
  Element1,
  Element2,
  ItemTitle2,
  ItemBody1,
  ItemBody2,
  Element3,
  EmptyText,
  FileWrapper,
  DeliverStatus,
  FileBtn,
} from "./SubmitDeliverable.style";
import { SearchIcon } from "assets/icons/search.icon";
import Button from "components/base/button";
// import { GitIcon } from "assets/images/worktasks-inside";
import SubmitNewDeliverable from "pages/SubmitNewDeliverable";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAllDeliverablesAPI } from "api/users";
import dayjs from "dayjs";
import { ROUTER_CONSTANTS } from "utils/constant";
import AvatarAntdGroup from "components/modules/avatarList";
import { useAppSelector } from "redux/hooks";
import { message } from "antd";

const SubmitDeliverable: FC<ISubmitDeliverableProps> = ({
  packageId,
  type,
  projectId,
  worktaskDetail,
}) => {
  const userId: string = useAppSelector((state) => state.auth.user?._id);

  function getStatusBtn(key: string) {
    if (key === "Review")
      return (
        <Button background="#a322ff" color="#FFF">
          Detail
        </Button>
      );
    if (key === "REVISION")
      return <DeliverStatus bg="#FF5722">revision</DeliverStatus>;
    if (key === "DELIVERED")
      return <DeliverStatus bg="#5786C5">Delivered</DeliverStatus>;
    if (key === "COMPLETED")
      return <DeliverStatus bg="#00DE8C">Completed</DeliverStatus>;
  }

  const [popupIndex, setPopupIndex] = useState(0);
  const [deliverSearch, setDeliverSearch] = useState("");
  const { data: allDeliverRes, isLoading: allDeliverLoading } = useQuery(
    ["getAllDeliverables"],
    () =>
      getAllDeliverablesAPI({
        packageId: packageId || "",
        type: type || "",
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!worktaskDetail,
    }
  );

  const deliverables: any[] = allDeliverRes?.responseData?.records || [];
  const searchedDeliverables = deliverables?.filter((deliver) => {
    const byNameOrEmail = (
      (deliver?.userDetails?.fullName || deliver?.userDetails?.emailId) as
        | string
        | undefined
    )?.includes(deliverSearch);
    const byPackagesName = deliver?.packages?.name?.includes(deliverSearch);
    const byDescription =
      deliver?.packages?.description?.includes(deliverSearch);
    const byDate = dayjs(deliver?.packages?.deliverables?.submittedAt)
      .format("DD MMM YYYY")
      ?.includes(deliverSearch);
    const byStatus =
      deliver?.packages?.deliverables?.deliveryStatus?.includes(deliverSearch);

    return (
      byNameOrEmail || byPackagesName || byDescription || byDate || byStatus
    );
  });
  const isCanSubmitDeliverables =
    worktaskDetail?.progressHistory?.inProgressUsers.includes(userId);
  const isObserver = worktaskDetail?.observers?.includes(userId);
  const isRejectedAfterDeliver = worktaskDetail?.deliverables?.some(
    (item) => item?.user === userId
  );
  const isCompletedDeliverable = deliverables?.some(
    (deli) => deli?.packages?.deliverables?.deliveryStatus === "COMPLETED"
  );

  const handleOpenDeliverPopup = () => {
    const isCollab = worktaskDetail?.collaborators?.some(
      (val) => val === userId
    );

    if (isCollab) {
      setPopupIndex(1);
    } else {
      message.error("You're not collaborator of this worktask!");
    }
  };

  return (
    <>
      <SubmitNewDeliverable
        popupIndex={popupIndex}
        setPopupIndex={setPopupIndex}
      />
      <BodyWrap>
        {((isRejectedAfterDeliver && !isCompletedDeliverable) ||
          (isCanSubmitDeliverables && !isObserver && type !== "initiator")) && (
          <SubmitNew onClick={handleOpenDeliverPopup}>
            <div>
              Submit a new deliverable <UploadIcon />
            </div>
          </SubmitNew>
        )}
        {allDeliverLoading ? (
          <EmptyText>Loading...</EmptyText>
        ) : (
          <DeliverableWrap>
            <HeadSection>
              <div>Deliverables</div>
              <SearchInput
                className="search"
                type="search"
                placeholder="Search Deliverables"
                suffix={<SearchIcon />}
                value={deliverSearch}
                onChange={(e) => setDeliverSearch(e.target.value)}
              />
            </HeadSection>
            <FieldWrap>
              <Field1>INITIATED BY</Field1>
              <Field2>TASK</Field2>
              <Field3>
                <div>SUBMISSION DATE</div>
                <div>STATUS</div>
              </Field3>
            </FieldWrap>
            <ItemWrap>
              {searchedDeliverables?.length !== 0 ? (
                searchedDeliverables?.map((deliverable: any, i) => (
                  <Item key={i}>
                    <Element1>
                      <ItemTitle1>
                        {deliverable?.userDetails?.username ||
                          deliverable?.userDetails?.emailId.split("@")[0]}
                      </ItemTitle1>
                      <ItemBody1>
                        <div>
                          <AvatarAntdGroup
                            collabs={
                              deliverable?.packages?.deliverables?.coWorkers
                                ?.filter(
                                  (collab: any) =>
                                    collab._id !== deliverable?.userDetails?._id
                                )
                                ?.map((collab: any) => {
                                  return {
                                    url: collab?.profilePicture,
                                    username:
                                      collab?.username ||
                                      (collab?.emailId ?? "").split("@")[0],
                                    id: collab?._id,
                                  };
                                }) || []
                            }
                            size={32}
                            max={5}
                          />
                          {deliverable?.packages?.deliverables?.coWorkers?.filter(
                            (collab: any) =>
                              collab._id !== deliverable?.userDetails?._id
                          )?.length > 0 ? (
                            <span>Your Collaborators</span>
                          ) : (
                            <span>No Collaborator</span>
                          )}
                        </div>
                      </ItemBody1>
                    </Element1>
                    <Element2>
                      <ItemTitle2>{deliverable?.packages?.name}</ItemTitle2>
                      <ItemBody2>
                        <span>
                          <NoteIcon />
                        </span>
                        <div>
                          <div>
                            {deliverable?.packages?.deliverables?.notes ||
                              deliverable?.packages?.description}
                            <Link
                              to={ROUTER_CONSTANTS.REVIEW_DELIVERABLE.replace(
                                ":projectId",
                                projectId || ""
                              )
                                .replace(":packageId", packageId || "")
                                .replace(":userId", deliverable.user)}
                            >
                              <strong>read more</strong>
                            </Link>
                          </div>
                          {deliverable?.packages?.deliverables?.files?.length >
                            0 && (
                            <FileWrapper space="10px">
                              {deliverable?.packages?.deliverables?.files?.map(
                                (item: any) => (
                                  <FileBtn
                                    key={item?.fileLink}
                                    color="#7E00FD"
                                    onClick={() => {
                                      window.open(item?.fileLink, "_blank");
                                    }}
                                  >
                                    <DocumentIcon />
                                    {item?.fileName}
                                  </FileBtn>
                                )
                              )}
                            </FileWrapper>
                          )}
                        </div>
                      </ItemBody2>
                    </Element2>
                    <Element3>
                      <span>
                        {dayjs(
                          deliverable?.packages?.deliverables?.submittedAt
                        ).format("DD MMM YYYY")}
                      </span>
                      <div>
                        {getStatusBtn(
                          deliverable?.packages?.deliverables?.deliveryStatus
                        )}
                      </div>
                    </Element3>
                  </Item>
                ))
              ) : (
                <EmptyText>No deliverables</EmptyText>
              )}
            </ItemWrap>
          </DeliverableWrap>
        )}
      </BodyWrap>
    </>
  );
};

export default SubmitDeliverable;
