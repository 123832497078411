import { FC } from "react";
import IStyledModalProps from "./baseModal.props";
import { StyledModal } from "./baseModal.style";
import { ModalMaskStyle } from "./baseModal.style";

const BaseModal: FC<IStyledModalProps> = ({ children, footer, ...props }) => {
  return (
    <StyledModal
      maskStyle={ModalMaskStyle}
      footer={footer ?? null}
      closable={false}
      {...props}
    >
      {children}
    </StyledModal>
  );
};

export default BaseModal;
