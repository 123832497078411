import Breadcrumb from "components/base/breadcrumb";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { ICourseDetailNewProps } from "./courseDetailNew.props";
import {
  Body,
  Container,
  Head,
  OutlinedButtonStyled,
  WrapButton,
  WrapSubInfo,
  DeleteCourseBtn,
  ConfirmDeleteModal,
  DivideGroup,
  TimeRemain,
  TagTimeRemain,
} from "./courseDetailNew.style";
import PrimaryButton from "components/base/primaryButton";
import { BookmarkIcon } from "assets/icons/bookmark.icon";
import { Button, message, Popconfirm, Tabs } from "antd";
import OverView from "./overView";
import Path from "./path";
import Learners from "./learners";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCourseDetailForGuestAndLearner } from "api/course/queries";
import {
  BlockchainExplorers,
  ChainIdToBlockChainName,
  ROUTER_CONSTANTS,
} from "utils/constant";
import {
  ClockTransparentIcon,
  ParticipantIcon,
  ExerciseIcon,
} from "assets/icons/clock.icon";
import {
  COURSE_STATUS,
  COURSE_TYPE,
  ICourse,
  ICourseBasicInfo,
} from "api/course";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  bookmarkCourseAPI,
  deleteCourseAPI,
  getCourseByIdForCreatorAPI,
  canDeleteCourseAPI,
  joinCourseAPI,
} from "api/course/request";
import { AxiosError } from "axios";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import {
  resetStep,
  setCourseInfo,
  setCourseRewards,
  setCourseSections,
  setPageLoading,
} from "redux/slices";
import CreateCoursePage from "pages/CreateCourse";
import dayjs from "dayjs";
import {
  formatTimeCommitment,
  getPluralText,
} from "utils/formatTimeCommitment";
import {
  PreviewChangePortfolio,
  PreviewConfirmDeleteModalActionBtn,
  PreviewConfirmDeleteModalActionWrapper,
  PreviewConfirmDeleteModalTitle,
} from "pages/PorfolioPreview/portfolioPreview.style";
import {
  claimCourseBudgetSC,
  getCourseIdFromTx,
  removeCourseSC,
} from "web3/learnToEarnContract";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import { handleMessageErrorSC } from "web3/contract";
import Page404 from "pages/Page404";
import Countdown from "antd/lib/statistic/Countdown";
import { CourseDetailNewLoading } from "./courseDetailNewLoading";
import TabContentPage from "pages/ListAssignmentForCreator/TabContent";
import { convertTimeTo } from "utils/time";

const CourseDetailNew: FC<ICourseDetailNewProps> = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const getTimeFormat = useCallback((value: number) => {
    const dateTime = dayjs.tz(value * 1000);
    return `${dateTime.format("DD MMM YYYY")}`;
  }, []);

  const {
    data: courseRes,
    refetch: refetchCourse,
    isLoading,
    error: errGetCourse,
  } = useGetCourseDetailForGuestAndLearner(params.id!, {
    retry: 1,
  });
  const course: ICourse = useMemo(() => {
    if (courseRes) return courseRes.responseData;
  }, [courseRes]);

  const estTime = useMemo(() => {
    if (!course) return "";
    const { value, unit } = convertTimeTo({
      value: course.estimatedTime,
      convertFromDBToForm: true,
    });
    return getPluralText(value, unit!);
  }, [course]);

  const [countDownToAwardAvailable, setCountDownToAwardAvailable] =
    useState<number>();

  useEffect(() => {
    if (course) {
      setBookmarkIds(course.bookmarkUserIds);
      const dateNow = dayjs(new Date()).unix();
      // Statified with 2 type: CUSTOM_DAY_PLAN and START_END_DATE
      if (dateNow < course.startDate)
        setCountDownToAwardAvailable(
          Date.now() + course.startDate * 1000 - new Date().valueOf()
        );
      else setCountDownToAwardAvailable(undefined);
    }
  }, [course]);

  const deadline = useMemo(() => {
    if (!course) return "";
    if (course.type === COURSE_TYPE.CUSTOM_DAY_PLAN)
      return formatTimeCommitment(course.range / 3600);
    return `${getTimeFormat(course.startDate)} - ${
      course.noExpire ? "unlimited" : getTimeFormat(course.endDate)
    }`;
  }, [course, getTimeFormat]);

  // const availableAward = useMemo(() => {
  //   const dateNow = dayjs(new Date()).unix();
  //   if (course && course.remainReward > 0) {
  //     if (course.type === COURSE_TYPE.CUSTOM_DAY_PLAN) return true;
  //     else if (
  //       dateNow >= course.startDate &&
  //       (course.noExpire === true || dateNow < course.endDate)
  //     )
  //       return true;
  //   }
  //   return false;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [course, countDownToAwardAvailable]);

  const user: any = useAppSelector((state) => state.auth.user);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [bookmarkIds, setBookmarkIds] = useState<Array<string>>([]);
  const isCreator = !isLoading && course?.createdBy === user?._id;
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  const items = [
    {
      text: "Home",
      to: ROUTER_CONSTANTS.HOME,
    },
    {
      text: "Courses",
    },
    {
      text: course?.title,
    },
  ];

  const tabs = useMemo(() => {
    const tabs_ = [
      {
        label: `Course introduction`,
        children: (
          <OverView isLoading={isLoading} content={course?.description} />
        ),
      },
      {
        label: `Path`,
        children: (
          <Path
            course={courseRes?.responseData}
            refetchCourse={refetchCourse}
          />
        ),
      },
      {
        label: `Learners`,
        children: <Learners course={courseRes?.responseData} />,
      },
    ];
    if (isCreator)
      tabs_.push({
        label: `Manage`,
        children: (
          <TabContentPage
            course={courseRes?.responseData}
            inCourseDetails={true}
            refetchCourse={refetchCourse}
          />
        ),
      });

    return tabs_;
  }, [
    course?.description,
    courseRes?.responseData,
    isCreator,
    isLoading,
    refetchCourse,
  ]);
  const [activeTab, setActiveTab] = useState<string>("0");

  const { mutate: joinCourseMutate } = useMutation(joinCourseAPI);
  const { mutateAsync: bookmarkCourseMutate } = useMutation(bookmarkCourseAPI);
  const { mutate: deleteCourseMutation } = useMutation(deleteCourseAPI, {
    onSuccess: (res) => {
      if (res.responseCode === 200) {
        setOpenDelete(false);
        dispatch(setPageLoading(false));
        message.success("Course deleted successfully");
        navigate("/");
      }
    },
    onError: () => {
      dispatch(setPageLoading(false));
    },
  });
  const canDeleteCourseMutation = useMutation(canDeleteCourseAPI);
  const [joinLoading, setJoinLoading] = useState(false);
  const navigate = useNavigate();

  useQuery(
    ["getCourseDetailForCreator", params.id],
    () => getCourseByIdForCreatorAPI(params.id as string),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          const courseForCreator = res.responseData;

          const startEndate =
            courseForCreator.type === COURSE_TYPE.START_AND_END_DATE
              ? {
                  startDate: dayjs.unix(courseForCreator.startDate),
                  endDate: dayjs.unix(courseForCreator.endDate),
                }
              : {};

          dispatch(
            setCourseInfo({
              ...courseForCreator,
              ...startEndate,
            } as ICourseBasicInfo)
          );
          dispatch(setCourseSections(courseForCreator.sections));
          if (courseForCreator.rewards.totalCourseBudget) {
            dispatch(
              setCourseRewards({
                ...courseForCreator.rewards,
                prizeTotal:
                  courseForCreator.rewards.prizeTotal |
                  (courseForCreator.totalCourseBudget /
                    courseForCreator.rewardPerLearner),
              })
            );
          }
        }
      },
      enabled: isCreator,
      refetchOnWindowFocus: false,
    }
  );

  if (
    (errGetCourse?.response?.data as any)?.responseMessage?.includes(
      "not found"
    )
  ) {
    return <Page404 />;
  }

  const handleJoinCourse = async () => {
    setJoinLoading(true);

    joinCourseMutate(
      { courseId: course._id!, startDate: Date.now() },
      {
        onSuccess: async () => {
          setJoinLoading(false);
          await refetchCourse();
        },
        onError: (err: any) => {
          const rs: any = err?.response?.data;
          message.error(rs.responseMessage);
          setJoinLoading(false);
        },
      }
    );
  };

  const handleEditCourse = () => {
    dispatch(resetStep());
    setIsEdit(true);
  };

  const handleClaimBudget = async () => {
    try {
      if (!checkWallet()) return;
      await handleSwitchNetwork(+course.chainId!);
      dispatch(setPageLoading(true));

      const courseId = await getCourseIdFromTx(course.txHash);

      if (!courseId) {
        message.error("Your courseId not valid");
        return;
      }

      await claimCourseBudgetSC(courseId);

      dispatch(setPageLoading(false));
    } catch (err: any) {
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
      dispatch(setPageLoading(false));
    }
  };

  const handleDeleteCourse = async () => {
    try {
      if (course.status === COURSE_STATUS.DRAFT)
        return deleteCourseMutation(params.id!);
      if (!checkWallet()) return;
      await handleSwitchNetwork(+course.chainId!);
      dispatch(setPageLoading(true));

      const canDelResponse = await canDeleteCourseMutation.mutateAsync(
        params.id!
      );

      if (!canDelResponse.responseData) {
        dispatch(setPageLoading(false));
        message.error("You must review the remaining learner's submissions");
        setOpenDelete(false);
        return;
      }

      const courseId = await getCourseIdFromTx(course.txHash);

      if (!courseId) {
        dispatch(setPageLoading(false));
        message.error("Your courseId not valid");
        setOpenDelete(false);
        return;
      }

      await removeCourseSC(courseId);

      return deleteCourseMutation(params.id!);
    } catch (err: any) {
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
      dispatch(setPageLoading(false));
    }
  };

  const courseExercise = course?.sections.reduce((init, item) => {
    return (init += item.activities.length);
  }, 0);

  const availableClaim =
    course &&
    !course.noExpire &&
    course.remainReward > 0 &&
    course.rewards.token &&
    course.type !== COURSE_TYPE.CUSTOM_DAY_PLAN &&
    dayjs.unix(course?.endDate).diff(dayjs(), "d") < 0; // whether end date is less than current date

  return isEdit ? (
    <CreateCoursePage />
  ) : (
    <Container>
      <Breadcrumb items={items} />
      <Head>
        {!isLoading && (
          <div>
            <h1>{course.title}</h1>
            <WrapButton>
              {isCreator ? (
                <>
                  <PrimaryButton width="150px" onClick={handleEditCourse}>
                    Edit Course
                  </PrimaryButton>
                  <DeleteCourseBtn onClick={() => setOpenDelete(true)}>
                    Delete course
                  </DeleteCourseBtn>
                  {availableClaim && (
                    <PrimaryButton width="200px" onClick={handleClaimBudget}>
                      Claim Unspent Budget
                    </PrimaryButton>
                  )}
                </>
              ) : (
                <>
                  {countDownToAwardAvailable ? (
                    <Popconfirm
                      title="You will not receive award for this course if joining in this time. Sure to agree?"
                      onConfirm={
                        !course.isJoinCourse ? handleJoinCourse : () => {}
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <PrimaryButton width="150px" loading={joinLoading}>
                        {course.isJoinCourse ? "Joined" : " Start Free Course"}
                      </PrimaryButton>
                    </Popconfirm>
                  ) : (
                    <PrimaryButton
                      width="150px"
                      onClick={
                        !course.isJoinCourse ? handleJoinCourse : () => {}
                      }
                      loading={joinLoading}
                    >
                      {course.isJoinCourse ? "Joined" : " Start Free Course"}
                    </PrimaryButton>
                  )}
                </>
              )}
              <OutlinedButtonStyled
                $isBookmark={bookmarkIds.includes(user?._id)}
                icon={<BookmarkIcon />}
                onClick={() =>
                  bookmarkCourseMutate({ courseId: course._id! })
                    .then((res) => setBookmarkIds(res.responseData))
                    .catch((err: AxiosError) => {
                      const rs: any = err?.response?.data;
                      message.error(rs.responseMessage);
                    })
                }
                className="bookmark"
              />
              <TimeRemain>
                {countDownToAwardAvailable && (
                  <>
                    <div>Available reward in:</div>
                    <TagTimeRemain>
                      <Countdown
                        value={countDownToAwardAvailable}
                        onFinish={() => setCountDownToAwardAvailable(undefined)}
                        format={`D[d]: HH[h]: mm[m]: ss[s]`}
                      />
                    </TagTimeRemain>
                  </>
                )}
              </TimeRemain>
            </WrapButton>
            <WrapSubInfo>
              <DivideGroup>
                <div>
                  <ClockTransparentIcon />
                  <span>{estTime}</span>
                </div>
                <div>
                  <ExerciseIcon />
                  <span>{getPluralText(courseExercise, "exercise")}</span>
                </div>
                <div>
                  <ParticipantIcon />
                  <span>
                    {typeof course.participant_count === "number"
                      ? getPluralText(
                          course.participant_count as number,
                          "participant"
                        )
                      : "-- participant"}
                  </span>
                </div>
              </DivideGroup>
              <DivideGroup>
                <div>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open(
                        `${
                          BlockchainExplorers[
                            ChainIdToBlockChainName[course.chainId as number]
                          ]
                        }${course.txHash}`,
                        "_blank"
                      )
                    }
                  >
                    <span>
                      Reward:{" "}
                      <>
                        {course.rewards.rewardPerLearner}{" "}
                        {course.rewards.token?.symbol?.slice(0, 5) ??
                          course.rewards.nft?.symbol?.slice(0, 5)}
                      </>
                    </span>
                  </Button>
                </div>
                <div>
                  <span>
                    Remaining budget:{" "}
                    <>
                      {course.remainReward * course.rewards.rewardPerLearner}{" "}
                      {course.rewards.token?.symbol?.slice(0, 5) ??
                        course.rewards.nft?.symbol?.slice(0, 5)}
                    </>
                  </span>
                </div>
                <div>
                  <span>
                    Deadline to complete the training to be eligible for a
                    reward: {deadline}
                  </span>
                </div>
              </DivideGroup>
            </WrapSubInfo>
          </div>
        )}
        {isLoading && <CourseDetailNewLoading />}
      </Head>
      <Body>
        <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
          {tabs.map((item: any, index: number) => (
            <Tabs.TabPane tab={item.label} key={index}>
              {item.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Body>
      <ConfirmDeleteModal
        visible={openDelete}
        footer={null}
        width={468}
        closable={false}
        onCancel={() => setOpenDelete(false)}
        destroyOnClose
      >
        <PreviewConfirmDeleteModalTitle>
          Are you sure to delete this course?
        </PreviewConfirmDeleteModalTitle>
        <PreviewConfirmDeleteModalActionWrapper>
          <PreviewChangePortfolio onClick={handleDeleteCourse}>
            OK
          </PreviewChangePortfolio>
          <PreviewConfirmDeleteModalActionBtn
            onClick={() => setOpenDelete(false)}
          >
            Cancel
          </PreviewConfirmDeleteModalActionBtn>
        </PreviewConfirmDeleteModalActionWrapper>
      </ConfirmDeleteModal>
    </Container>
  );
};

export default memo(CourseDetailNew);
