import styled from "styled-components";

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const SocialIconWrapper = styled(FlexRow)`
  gap: 14px;
`;

export const SocialTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.656);
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(156, 138, 193, 0.16);
`;

export const SocialSection = styled.div``;

export const SocialWrapper = styled(FlexRow)`
  gap: 40px;
`;

export const OrgBodyNumber = styled.div`
  font-weight: 700;
  font-size: 24px;
  font-family: "DM Sans";
  font-style: "Bold";
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-bottom: 4px;
`;

export const OrgBodyName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const OrgBodyWrapper = styled.div``;

export const OverviewStatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 37px;
  padding: 72px 82px 58px 72px;
  background: linear-gradient(
    180deg,
    rgba(236, 223, 253, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /* linear-gradient(
      90deg,
      rgba(126, 0, 253, 0.1) 0%,
      rgba(193, 67, 252, 0.1) 46.88%,
      rgba(0, 191, 251, 0.1) 100%
    ); */
`;

export const OverviewDesc = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 172%;
  color: rgba(41, 46, 115, 0.82);
  word-break: break-word;
  width: 100%;
  white-space: pre-wrap;

  .text-bold {
    font-weight: 600;
  }
  .child-text {
    margin: 0px 0px 0px 12px;
  }

  // @supports (-webkit-line-clamp: 11) {
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: initial;
  //   display: -webkit-box;
  //   -webkit-line-clamp: 11;
  //   -webkit-box-orient: vertical;
  // }
`;

export const OverviewTitle = styled.h3`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #080f6c;
  text-transform: capitalize;
`;

export const OverviewInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
`;

export const OverviewWrapper = styled.section`
  display: flex;
  align-items: flex-start;
  padding: 63px 101px;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
    linear-gradient(
      90deg,
      rgba(126, 0, 253, 0.1) 0%,
      rgba(193, 67, 252, 0.1) 46.88%,
      rgba(0, 191, 251, 0.1) 100%
    );
`;
