import BaseModal from "components/base/baseModal";
import OutlinedButton from "components/base/outlinedButton";
import PrimaryButton from "components/base/primaryButton";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import { StyledSelect } from "pages/ProfileUser/profileUserInner.style";
import styled from "styled-components";
import { StyledFormItem } from "../createProject.style";

export const CreateTokenModal = styled(BaseModal)`
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }

  .ant-modal-content .ant-modal-body {
    padding: 24px;
  }
`;

export const SelectedOrgWrapper = styled(CommonFlexRow)`
  flex-wrap: wrap;
`;

export const CommonLabel = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080f6c;
`;

export const ExtraSection = styled(CommonFlexRow)`
  padding: 16px;
  background: rgba(115, 137, 149, 0.04);
  border-radius: 4px;

  > ${CommonFlexColumn} {
    flex-grow: 1;
  }

  > svg {
    position: relative;
    top: 12px;
  }
`;

export const ButtonFormItem = styled(StyledFormItem)`
  margin-bottom: 0;
`;

export const AllFormFieldWrapper = styled.div`
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  padding: 32px;
`;

export const UploadWrapper = styled(CommonFlexRow)`
  width: 150px;
  height: 64px;
  justify-content: center;
`;

export const TokenSelectText = styled.div`
  background: rgba(233, 70, 0, 0.07);
  border-radius: 8px;
  padding: 8px 12px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  margin-top: 20px;
  cursor: pointer;
`;

export const TokenHighlightText = styled.span`
  color: #1890ff;
`;

export const MainActionBtn = styled(PrimaryButton)`
  svg {
    margin-right: 0;
    position: relative;
    top: -1px;
  }
`;

export const GrayBtn = styled(OutlinedButton)`
  width: unset;
  height: unset;
  background: #fff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 6px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.42);
`;

export const ButtonWrapper = styled(CommonFlexRow)`
  margin-block: 24px;
  justify-content: space-between;
`;

export const TypeDescription = styled.p`
  background: radial-gradient(
    100% 445.32% at 0% 100%,
    rgba(126, 0, 253, 0.07) 0%,
    rgba(185, 61, 243, 0.07) 42.35%,
    rgba(165, 75, 244, 0.07) 57.95%,
    rgba(0, 191, 251, 0.07) 100%
  );
  padding: 10px 13px;
  border-radius: 8px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  max-width: 790px;
`;

export const OrgInner = styled(CommonFlexRow)`
  flex-grow: 1;
  background: #fff;
  border-radius: 7px;
  padding: 16px 36px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 115%;
  text-transform: uppercase;
  color: #080f6c;
  justify-content: center;
  cursor: pointer;
`;

export const OrgTypeGradient = styled(CommonFlexRow)<{ $active?: boolean }>`
  padding: 2px;
  border-radius: 9px;
  background: ${({ $active }) =>
    $active === true
      ? `radial-gradient(
    100% 445.32% at 0% 100%,
    #7e00fd 0%,
    #b93df3 42.35%,
    #a54bf4 57.95%,
    #00bffb 100%
  )`
      : `rgba(41, 46, 115, 0.21)`};
  position: relative;
  flex-grow: 1;
  max-width: 200px;

  ${({ $active }) =>
    $active === true &&
    `
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: linear-gradient(
        94.38deg,
        rgba(197, 138, 255, 0.6) 7.87%,
        rgba(0, 173, 251, 0.6) 50.31%,
        rgba(0, 213, 201, 0.6) 82.37%,
        rgba(0, 222, 140, 0.6) 107.9%
        );
        filter: blur(22px);
        z-index: -1;
    }
  `}
`;

export const OrgTypeWrapper = styled(CommonFlexRow)`
  margin-top: 23px;
  margin-bottom: 16px;
`;

export const LabelText = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080f6c;
`;

export const TypeSection = styled.div`
  margin-top: 24px;
`;

export const OrgCreateBtn = styled.button`
  max-width: 229px;
  width: 100%;
  padding: 12px;
  background: #7e00fd;
  border-radius: 6px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #fff;
  cursor: pointer;
`;

export const RegisterDesc = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  text-align: center;
`;

export const TypeHint = styled(RegisterDesc)`
  text-align: left;
`;

export const CreateOrgWrapper = styled(CommonFlexColumn)`
  background: rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  align-items: center;
  padding: 14px 40px;
`;

export const SelectWrapper = styled(CommonFlexColumn)`
  .ant-form-item {
    margin-bottom: 12px;
  }
`;

export const SelectedSponsorTag = styled(CommonFlexRow)`
  background: rgba(41, 46, 115, 0.05);
  border-radius: 20px;
  padding: 3px 6px;
  cursor: pointer;
`;

export const OrganizationSelect = styled(StyledSelect)`
  width: 100%;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
  }

  .ant-select-selection-overflow {
    /* visibility: hidden; */
    display: none;
  }
`;

export const OrganizationSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-block: 24px;
  border-top: 1px solid rgba(41, 46, 115, 0.12);
  border-bottom: 1px solid rgba(41, 46, 115, 0.12);
`;

export const ProjectThumbWrapper = styled(CommonFlexRow)``;

export const ProjectDescription = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  margin-bottom: 32px;
`;

export const StepOneWrapper = styled.div`
  margin-top: 56px;
`;
