export const GithubIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8047_8453)">
      <path
        d="M7.96906 0C3.56844 0 0 3.66757 0 8.19188C0 11.8113 2.28338 14.882 5.44975 15.9652C5.848 16.041 5.99425 15.7875 5.99425 15.5711C5.99425 15.3758 5.98681 14.7304 5.98344 14.0459C3.76638 14.5415 3.29856 13.0794 3.29856 13.0794C2.93606 12.1325 2.41375 11.8808 2.41375 11.8808C1.69075 11.3723 2.46825 11.3827 2.46825 11.3827C3.2685 11.4406 3.68987 12.2269 3.68987 12.2269C4.40062 13.4793 5.55412 13.1172 6.00887 12.908C6.08037 12.3784 6.28694 12.0171 6.51481 11.8126C4.74481 11.6054 2.88406 10.9029 2.88406 7.76406C2.88406 6.86973 3.19537 6.13892 3.70519 5.56525C3.62244 5.35889 3.34969 4.52572 3.78238 3.39735C3.78238 3.39735 4.45156 3.17717 5.97444 4.23706C6.61006 4.0555 7.29181 3.96452 7.96906 3.96144C8.64631 3.96452 9.32856 4.0555 9.96544 4.23706C11.4865 3.17717 12.1547 3.39735 12.1547 3.39735C12.5885 4.52572 12.3156 5.35889 12.2329 5.56525C12.7438 6.13892 13.053 6.86967 13.053 7.76406C13.053 10.9104 11.1888 11.6032 9.41425 11.806C9.70006 12.0602 9.95475 12.5588 9.95475 13.323C9.95475 14.4191 9.9455 15.3013 9.9455 15.5711C9.9455 15.7891 10.0889 16.0445 10.4929 15.9641C13.6576 14.8797 15.9381 11.8101 15.9381 8.19188C15.9381 3.66757 12.3701 0 7.96906 0Z"
        fill="#161614"
      />
      <path
        d="M2.98493 11.6694C2.96743 11.7101 2.90505 11.7223 2.84836 11.6944C2.79055 11.6677 2.75805 11.6122 2.7768 11.5713C2.79399 11.5294 2.85636 11.5177 2.91405 11.5459C2.97199 11.5725 3.00499 11.6286 2.98493 11.6694ZM3.37693 12.029C3.33893 12.0652 3.26461 12.0484 3.21418 11.9911C3.16205 11.934 3.1523 11.8577 3.19086 11.8209C3.23005 11.7847 3.30211 11.8016 3.35436 11.8588C3.40649 11.9165 3.41661 11.9924 3.37686 12.029M3.64586 12.489C3.59699 12.5239 3.51711 12.4912 3.4678 12.4183C3.41899 12.3455 3.41899 12.2581 3.46886 12.2231C3.51836 12.1881 3.59699 12.2196 3.64699 12.2919C3.69574 12.3659 3.69574 12.4533 3.6458 12.489M4.10061 13.0218C4.05693 13.0712 3.96393 13.058 3.8958 12.9904C3.82618 12.9244 3.80674 12.8306 3.85055 12.7811C3.89474 12.7315 3.9883 12.7454 4.05693 12.8125C4.12611 12.8784 4.14724 12.9728 4.10068 13.0218M4.68836 13.2017C4.66918 13.2658 4.57955 13.295 4.4893 13.2677C4.39918 13.2396 4.34018 13.1645 4.35836 13.0996C4.37711 13.0351 4.46711 13.0047 4.55805 13.0339C4.64805 13.0618 4.70718 13.1364 4.68843 13.2017M5.35736 13.2779C5.35961 13.3455 5.28305 13.4015 5.1883 13.4028C5.09299 13.4049 5.01593 13.3502 5.01493 13.2838C5.01493 13.2155 5.08974 13.16 5.18499 13.1584C5.27974 13.1565 5.35736 13.2108 5.35736 13.2779ZM6.01449 13.252C6.02586 13.318 5.95999 13.3857 5.86593 13.4037C5.77343 13.421 5.6878 13.3803 5.67599 13.315C5.66449 13.2474 5.73161 13.1798 5.82393 13.1622C5.91818 13.1454 6.00249 13.185 6.01449 13.252Z"
        fill="#161614"
      />
    </g>
    <defs>
      <clipPath id="clip0_8047_8453">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
