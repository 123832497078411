import { Form, message, Tabs } from "antd";
import { FC, memo, useState } from "react";
import { ISignUpProps } from "./signUp.props";
import {
  ButtonLoginHere,
  Container,
  ForgotPasswordButton,
  FormPasswordInput,
  FormTextInput,
  FormTitle,
  HasAccountContainer,
  Image,
  ImageContainer,
  ModalFormItem,
  SignUpFormContainer,
  SignUpItemContainer,
  SignUpLabel,
  SignUpLabelForgotPassword,
  SubmitButton,
  TabContainner,
} from "./signUp.style";
import image from "assets/images/signup-image.png";
import { maxLength_128, requiredRules } from "utils/validatorRules";
import { ROUTER_CONSTANTS } from "utils/constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ISignUpParams, signupAPI } from "api/auth";
import { useMutation } from "@tanstack/react-query";
import type { NamePath } from "antd/lib/form/interface";
import type { RuleObject } from "antd/lib/form";

const SignUp: FC<ISignUpProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [signUpLoading, setSignUpLoading] = useState(false);

  const referralCode = searchParams?.get("referralCode");

  const { mutate: signup } = useMutation(signupAPI, {
    onSuccess: (res: any) => {
      const { responseMessage } = res;
      // setIsOTP(true);
      // setUserID(res.responseData._id);
      message.success(responseMessage);
      navigate(ROUTER_CONSTANTS.USER.LOGIN);
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.responseMessage);
      setSignUpLoading(false);
    },
  });

  const onClickSignUp = (values: ISignUpParams) => {
    setSignUpLoading(true);
    signup({ ...values, referralCode } as ISignUpParams);
  };

  const passwordRule = (getFieldValue: (name: NamePath) => any) => {
    return {
      validator(_: RuleObject, value: string) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("Re-enter Password is not the same"));
      },
    };
  };
  return (
    <Container>
      <ImageContainer>
        <Image src={image} alt="" />
      </ImageContainer>
      <SignUpFormContainer>
        <FormTitle>Sign Up</FormTitle>
        <TabContainner defaultActiveKey="1">
          <Tabs.TabPane tab={"Email"} key="1">
            <Form
              name="signUp"
              wrapperCol={{ span: 32 }}
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onClickSignUp}
              form={form}
            >
              <SignUpItemContainer>
                <SignUpLabel>Enter Email</SignUpLabel>
                <ModalFormItem
                  name="emailId"
                  labelAlign="left"
                  rules={[
                    {
                      type: "email",
                      message: "Invalid email format (e.g. user@example.com)",
                    },
                    requiredRules,
                  ]}
                >
                  <FormTextInput placeholder="Enter your email" />
                </ModalFormItem>
              </SignUpItemContainer>

              <SignUpItemContainer>
                <SignUpLabel>Create Password</SignUpLabel>
                <ModalFormItem
                  name="password"
                  labelAlign="left"
                  hasFeedback
                  rules={[
                    maxLength_128,
                    { min: 8, message: "Minimum 8 characters" },
                    requiredRules,
                    {
                      validator: (_: any, value: string) => {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!/(?=.*?[A-Z])/.test(value)) {
                          return Promise.reject(
                            new Error(
                              "At least one capital (uppercase) letter!"
                            )
                          );
                        }
                        if (!/(?=.*?[a-z])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one lowercase letter!")
                          );
                        }
                        if (!/(?=.*?[0-9])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one digit!")
                          );
                        }
                        if (!/(?=.*?[#?!@$%^&*-])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one special character!")
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <FormPasswordInput placeholder="Create Password" />
                </ModalFormItem>
              </SignUpItemContainer>

              <SignUpItemContainer>
                <SignUpLabel>Re-enter Password</SignUpLabel>
                <ModalFormItem
                  name="reEnterPassword"
                  labelAlign="left"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    maxLength_128,
                    { min: 8, message: "Minimum 8 characters" },
                    requiredRules,
                    ({ getFieldValue }) => passwordRule(getFieldValue),
                    {
                      validator: (_: any, value: string) => {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!/(?=.*?[A-Z])/.test(value)) {
                          return Promise.reject(
                            new Error(
                              "At least one capital (uppercase) letter!"
                            )
                          );
                        }
                        if (!/(?=.*?[a-z])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one lowercase letter!")
                          );
                        }
                        if (!/(?=.*?[0-9])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one digit!")
                          );
                        }
                        if (!/(?=.*?[#?!@$%^&*-])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one special character!")
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <FormPasswordInput placeholder="Re-enter Password" />
                </ModalFormItem>
                <ForgotPasswordButton>
                  <SignUpLabelForgotPassword>
                    {/* Forgot password? */}
                  </SignUpLabelForgotPassword>
                </ForgotPasswordButton>
              </SignUpItemContainer>

              <SignUpItemContainer>
                <SubmitButton
                  disabled={signUpLoading}
                  loading={signUpLoading}
                  htmlType="submit"
                >
                  Sign Up
                </SubmitButton>
                <HasAccountContainer>
                  <div>Already have an account?</div>
                  <ButtonLoginHere
                    onClick={() => navigate(ROUTER_CONSTANTS.USER.LOGIN)}
                  >
                    Login here
                  </ButtonLoginHere>
                </HasAccountContainer>
              </SignUpItemContainer>
            </Form>
          </Tabs.TabPane>
        </TabContainner>
      </SignUpFormContainer>
    </Container>
  );
};

export default memo(SignUp);
