import { Form, message } from "antd";
import {
  ButtonLoginHere,
  FormPasswordInput,
  FormTextInput,
  HasAccountContainer,
  ModalFormItem,
  SignInItemContainer,
  SignInLabel,
  SignInLabelForgotPassword,
  SubmitButton,
} from "./signIn.style";
import { ROUTER_CONSTANTS } from "utils/constant";
import ForgotPassword from "./ForgotPassword";
import { maxLength_128, requiredRules } from "utils/validatorRules";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { END_POINT, loginAPI } from "api/auth";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
export const SignInForm = ({ setUserLogin }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const { mutate: login, isLoading } = useMutation(loginAPI, {
    onSuccess: (res: any) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        setUserLogin(res.responseData);
      } else {
        message.error(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      console.log(err);
    },
  });

  return (
    <Form
      name="signIn"
      wrapperCol={{ span: 32 }}
      layout="vertical"
      initialValues={{ remember: true }}
      autoComplete="off"
      onFinish={(value) => login(value)}
    >
      <SignInItemContainer>
        <SignInLabel>Enter Email</SignInLabel>
        <ModalFormItem
          name="emailId"
          labelAlign="left"
          rules={[
            {
              type: "email",
              message: "Invalid email format (e.g. user@example.com)",
            },
            requiredRules,
          ]}
        >
          <FormTextInput placeholder="Enter your email" />
        </ModalFormItem>
      </SignInItemContainer>
      <SignInItemContainer>
        <SignInLabel>Enter Password</SignInLabel>
        <ModalFormItem
          name="password"
          labelAlign="left"
          hasFeedback
          rules={[
            maxLength_128,
            { min: 8, message: "Minimum 8 characters" },
            {
              required: true,
              message: "Please enter your password!",
            },
            {
              validator: (_: any, value: string) => {
                if (!value) {
                  return Promise.resolve();
                }
                if (!/(?=.*?[A-Z])/.test(value)) {
                  return Promise.reject(
                    new Error("At least one capital (uppercase) letter!")
                  );
                }
                if (!/(?=.*?[a-z])/.test(value)) {
                  return Promise.reject(
                    new Error("At least one lowercase letter!")
                  );
                }
                if (!/(?=.*?[0-9])/.test(value)) {
                  return Promise.reject(new Error("At least one digit!"));
                }
                if (!/(?=.*?[#?!@$%^&*-])/.test(value)) {
                  return Promise.reject(
                    new Error("At least one special character!")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <FormPasswordInput placeholder="Enter your password" />
        </ModalFormItem>
        <SignInLabelForgotPassword onClick={toggleModal}>
          Forgot password?
        </SignInLabelForgotPassword>
        <ForgotPassword show={isModalOpen} closeModal={toggleModal} />
      </SignInItemContainer>
      <SignInItemContainer>
        <SubmitButton htmlType="submit" loading={isLoading}>
          Sign In
        </SubmitButton>

        <SubmitButton
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_API_URI}/${END_POINT.LOGIN_GITLAB}`,
              "_self"
            )
          }
        >
          Sign In With GitLab
        </SubmitButton>
        <HasAccountContainer>
          <div>Don't have an account?</div>
          <ButtonLoginHere
            onClick={() => navigate(ROUTER_CONSTANTS.USER.SIGNUP)}
          >
            Sign Up here
          </ButtonLoginHere>
        </HasAccountContainer>
      </SignInItemContainer>
    </Form>
  );
};
