import styled from "styled-components";
import { CommonGradientContainer } from "pages/ProfileUser/profileUserInner.style";

export const Container = styled(CommonGradientContainer)`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-bottom: 48px;
`;

export const FormItemsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 22px;
`;
