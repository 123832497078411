import { MediumIcon } from "assets/icons/common/mediumOrgan.icon";
import {
  // FacebookIcon,
  TwitterIcon,
  // LinkedinIcon,
  CoinmarketcapIcon,
  CoinmarketcapIcon2,
  GithubIcon,
  AnonymousIcon,
  LinkedinIcon,
} from "assets/images/organization/organizationImages";
import { WebsiteIcon } from "assets/images/profile-preview";
import { FC, Fragment } from "react";
import { splitDescription, elementing } from "utils/formatDescription";
import { OverviewProps } from "../OrganizationPage.props";
import {
  OverviewDesc,
  OverviewInfoWrapper,
  OverviewTitle,
  OverviewWrapper,
  OverviewStatWrapper,
  OrgBodyWrapper,
  OrgBodyNumber,
  OrgBodyName,
  SocialWrapper,
  SocialSection,
  SocialTitle,
  SocialIconWrapper,
  SocialLink,
} from "./overview.style";

const Overview: FC<OverviewProps> = ({ organization }) => {
  const socialKeys = organization?.socialLinks
    ? Object.keys(organization?.socialLinks)
    : [];
  const cryptoKeys = organization?.crypto
    ? Object.keys(organization?.crypto)
    : [];
  const resourceKeys = organization?.devResources
    ? Object.keys(organization?.devResources)
    : [];

  const getIconIfHave = (link?: string | null, icon?: JSX.Element) => {
    return !!link ? (
      <SocialLink href={link} target="_blank">
        {icon}
      </SocialLink>
    ) : null;
  };

  const formatTextToNewType = (text: string) => {
    return (
      <OverviewDesc>
        {splitDescription(text).map((str, i) => (
          <Fragment key={i}>
            {elementing(str)}
            <br />
          </Fragment>
        ))}
      </OverviewDesc>
    );
  };

  return (
    <OverviewWrapper>
      <OverviewInfoWrapper>
        <OverviewTitle>{organization?.name} Overview</OverviewTitle>
        {organization?.overview && formatTextToNewType(organization?.overview)}
        {/* <OverviewDesc>{organization?.overview}</OverviewDesc> */}
        <SocialWrapper>
          {socialKeys?.filter(
            (val) => !!(organization?.socialLinks as any)?.[val]
          )?.length > 0 && (
            <SocialSection>
              <SocialTitle>Social Visibility</SocialTitle>
              <SocialIconWrapper>
                {getIconIfHave(
                  organization?.socialLinks?.mediumURL,
                  <MediumIcon />
                )}
                {getIconIfHave(
                  organization?.socialLinks?.personURL,
                  <WebsiteIcon />
                )}
                {getIconIfHave(
                  organization?.socialLinks?.twitterURL,
                  <TwitterIcon />
                )}
                {getIconIfHave(
                  organization?.socialLinks?.linkedIn,
                  <LinkedinIcon />
                )}
              </SocialIconWrapper>
            </SocialSection>
          )}
          {cryptoKeys?.filter((val) => !!(organization?.crypto as any)?.[val])
            ?.length > 0 && (
            <SocialSection>
              <SocialTitle>Crypto</SocialTitle>
              <SocialIconWrapper>
                {getIconIfHave(
                  organization?.crypto?.coinMarketCapLink,
                  <CoinmarketcapIcon />
                )}
                {getIconIfHave(
                  organization?.crypto?.coinGeckoLink,
                  <CoinmarketcapIcon2 />
                )}
              </SocialIconWrapper>
            </SocialSection>
          )}
          {resourceKeys?.filter(
            (val) => !!(organization?.devResources as any)?.[val]
          )?.length > 0 && (
            <SocialSection>
              <SocialTitle>Dev resources</SocialTitle>
              <SocialIconWrapper>
                {getIconIfHave(
                  organization?.devResources?.githubURL,
                  <GithubIcon />
                )}
                {getIconIfHave(
                  organization?.devResources?.documentURL,
                  <AnonymousIcon />
                )}
                {/* <SocialLink
                href={organization?.devResources?.githubURL}
                target="_blank"
              >
                <GithubIcon />
              </SocialLink>
              <SocialLink
                href={organization?.devResources?.documentURL}
                target="_blank"
              >
                <AnonymousIcon />
              </SocialLink> */}
              </SocialIconWrapper>
            </SocialSection>
          )}
        </SocialWrapper>
      </OverviewInfoWrapper>
      <OverviewStatWrapper>
        <OrgBodyWrapper>
          <OrgBodyNumber>
            {organization?.totalContributors?.length}
          </OrgBodyNumber>
          <OrgBodyName>Contributors</OrgBodyName>
        </OrgBodyWrapper>
        <OrgBodyWrapper>
          <OrgBodyNumber>{organization?.totalOpenPackages}</OrgBodyNumber>
          <OrgBodyName>Open Worktasks</OrgBodyName>
        </OrgBodyWrapper>
        <OrgBodyWrapper>
          <OrgBodyNumber>{organization?.totalPackages}</OrgBodyNumber>
          <OrgBodyName>Total Worktasks</OrgBodyName>
        </OrgBodyWrapper>
      </OverviewStatWrapper>
    </OverviewWrapper>
  );
};

export default Overview;
