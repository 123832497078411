import styled from "styled-components";

export const PopupWrapStyle = styled.div`
  svg {
    flex-shrink: 0;
  }

  & > div {
    padding: 50px 30px 45px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      margin: 27px 0px 12px;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #080f6c;
      text-align: center;
      padding: 0px;
    }

    & > p {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      text-align: center;
      color: rgba(41, 46, 115, 0.82);
      margin-bottom: 20px;
      word-break: break-word;
    }
  }
`;

export const SpanText = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
`;
