export const scrollToSection = (idToScroll: string) => {
  const ele = document.getElementById(idToScroll);

  ele?.scrollIntoView({
    behavior: "smooth",
  });
};

export const scrollToFirstErrorSmooth: boolean | any | undefined = {
  behavior: "smooth",
  block: "center",
  inline: "center",
};
