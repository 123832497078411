import axios from "api/axios";
import { END_POINT } from "./endpoints";
import {
  ITransactionByTokenName,
  ITransactionByProject,
  IWorktasksByStatusItem,
  IFeedbackByUserId,
  IFeedbackDetail,
} from "./types";

export const getProjectTransactionsSummary = async (projectId: string) => {
  return await axios.get<ITransactionByTokenName>(
    `${END_POINT.GET_PROJECT_TRANSACTIONS_SUMMARY}?projectId=${projectId}`
  );
};

export const getTransactionsByProject = async (projectId: string) => {
  return await axios.get<ITransactionByProject[]>(
    `${END_POINT.GET_TRANSACTIONS_BY_PROJECT}/${projectId}`
  );
};

export const getWorkTasksByProject = async (projectId: string) => {
  return await axios.get<IWorktasksByStatusItem[]>(
    `${END_POINT.GET_WORKTASKS_BY_PROJECT}/${projectId}`
  );
};

export const getAllFeedbackByUser = async (userId: string, page = 1) => {
  return await axios.post<IFeedbackByUserId>(
    `${END_POINT.GET_FEEDBACKS}/${userId}`,
    {
      page,
    }
  );
};

export const getFeedbackDetail = async (feedbackId: string) => {
  return await axios.get<IFeedbackDetail>(
    `${END_POINT.GET_FEEDBACK_DETAIL}/${feedbackId}`
  );
};
