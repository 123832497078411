import styled from "styled-components";

export const Container = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 20px;
  height: 724px;
  overflow-y: auto;
`;

export const CardContainer = styled.div<{ isSelected: boolean }>`
  height: fit-content;
  position: relative;
  width: 100%;
  border-radius: 0.25rem;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  grid-gap: 1.25rem 1.25rem;
  background: white;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 9px;
  }
  ${({ isSelected }) =>
    isSelected ? `box-shadow: rgb(38 159 213) 0px 0px 5px 2px !important` : ``}
`;

export const CardImage = styled.img`
  width: 100%;
  height: 8rem;
  object-fit: cover;
  border-radius: 0.25rem 0.25rem 0 0;
`;

export const Title = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #080f6c;
  /* width: 16.3125rem; */
  margin: 0.5rem 0;
  word-break: break-word;
`;
export const Content = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  height: 95px;
  word-break: break-word;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
`;

export const CardContent = styled.div`
  padding: 0 0.5rem;
`;
