import { FC, ReactElement, useEffect, useState } from "react";
import {
  AwardDesc,
  AwardWrapper,
  AwardTag,
  Container,
  TagWrapper,
  AwardTagWrapper,
  AwardBg,
  AwardModalDesc,
  AwardModalWrapper,
  ModalFooter,
  FormWrapper,
  BudgetNumber,
  TotalBudget,
} from "./prize&AwardSetupProps.style";
import { IPrizeAndAwardSetupProps } from "./prize&AwardSetupProps.props";
import { COURSE_AWARDS } from "utils/course";
import { Col, Divider, Form, Radio, Row } from "antd";
import {
  ContentWrapper,
  RightAction,
  StepContentWrapper,
} from "../createCourse.style";
import { useAppSelector } from "redux/hooks";
import OutlinedButton from "components/base/outlinedButton";
import { NextIcon } from "assets/icons/navArrow.icon";
import { nextStep, setCourseNetwork, setCourseRewards } from "redux/slices";
import { useDispatch } from "react-redux";
import PrimaryButton from "components/base/primaryButton";
import { useParams } from "react-router-dom";
import { ModalCustom } from "../CourseCustomisation/courseCustomisation.style";
import {
  COURSE_STATUS,
  IBlockchainNetwork,
  ICourseForm,
  KIND_TYPE,
} from "api/course";
import CreateToken from "../CourseOverview/CreateToken";
import { numberRules, requiredRules } from "utils/validatorRules";
import {
  StyledSelect,
  StyledOption,
  StyledInputNumber,
  StyledInput,
} from "../CourseOverview/CreateToken/createToken.style";
import NewTokenForm from "./Token/NewTokenForm";
import { useQuery } from "@tanstack/react-query";
import { getDataMastersAPI } from "api/users";
import { isAddress } from "ethers/lib/utils";
import CreateNFT from "../CourseOverview/CreateNFT";
import { TOKEN_SYMBOL_NOT_FOUND } from "./Token/NFTToken/ExistingNFT";
import { useEthers } from "@usedapp/core";
import { BlockchainNetworks, ChainIdToBlockChainName } from "utils/constant";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import {
  checkIfIsNFT,
  getERC721NameAndSymbol,
  handleMessageErrorSC,
} from "web3/contract";
import { HeadingCourse } from "../components/HeadingCourse";
import { scrollToFirstErrorSmooth } from "utils/scrollToSection";

const PrizeAndAwardSetup: FC<IPrizeAndAwardSetupProps> = (): ReactElement => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { step, course } = useAppSelector((state) => state.course);

  const savedReward = course.rewards;

  const [awardType, setAwardType] = useState<KIND_TYPE | null>(
    savedReward ? savedReward.kind : null
  );
  const [newAward, setNewAward] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(
    savedReward ? !savedReward.kind : false
  );

  const { data: dataNetworks } = useQuery(
    ["getDataNetworks"],
    () => getDataMastersAPI({ type: "Blockchain Networks" }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const blockchainNetworksSelected: IBlockchainNetwork[] =
    dataNetworks?.responseData ? dataNetworks.responseData.values : [];

  const handleSelectType = (award: KIND_TYPE, checked: boolean) => {
    if (checked) {
      form.setFieldValue("kind", award);
      setAwardType(award);
      setVisible(true);
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    if (!savedReward) return;

    form.setFieldsValue({
      nftName: savedReward?.nft?.name,
      nftSymbol: savedReward?.nft?.symbol,
    });
  }, [form, savedReward]);

  useEffect(() => {
    const value = blockchainNetworksSelected.find(
      (net) =>
        net?._id === savedReward?.token?.network ||
        net?._id === savedReward?.nft?.network
    )?._id;
    if (value) form.setFieldValue("blockchainNetwork", value);
  }, [
    blockchainNetworksSelected,
    form,
    savedReward?.nft?.network,
    savedReward?.token?.network,
  ]);

  const handleSelectNewAward = (newAward: string, checked: boolean) => {
    if (checked) {
      setNewAward(newAward);
    }
  };

  const onClose = () => {
    setAwardType(null);
    setVisible(false);
  };

  const modalType = COURSE_AWARDS[awardType === KIND_TYPE.NFT ? 1 : 0];

  const haveReward = +Form.useWatch("rewardPerLearner", form);
  const tokenSymbol = Form.useWatch("tokenSymbol", form);
  const haveTotal = +Form.useWatch("prizeTotal", form);

  const budget = `${haveReward * haveTotal} ${tokenSymbol}`;

  const showBudget =
    !isNaN(haveReward) &&
    haveReward > 0 &&
    haveTotal > 0 &&
    !isNaN(haveTotal) &&
    tokenSymbol &&
    tokenSymbol !== TOKEN_SYMBOL_NOT_FOUND;

  const onFinish = (values: ICourseForm) => {
    if (Object.keys(values).length === 0) return;

    // TODO: add course reward value here
    const selectedNetwork = blockchainNetworksSelected.find(
      (nw) => nw?._id === values.blockchainNetwork
    );

    if (awardType) {
      const token = {
        network: values.blockchainNetwork,
        name: values.tokenName,
        explorer: values.tokenExplorer,
        symbol: values.tokenSymbol,
        contractAddress: values.tokenContract,
      };

      const rewardTotal =
        awardType === KIND_TYPE.TOKEN ? values.rewardPerLearner : 1;

      const awardInfo = {
        prizeTotal: values.prizeTotal,
        kind: awardType,
        rewardPerLearner: rewardTotal,
      };

      // blockchainNetwork: string, contractAddress: string, kind: KIND_TYPE, prizeTotal: number
      dispatch(
        setCourseRewards(
          awardType === KIND_TYPE.TOKEN
            ? { ...awardInfo, token }
            : {
                ...awardInfo,
                nft: {
                  contractAddress: values.contractAddress,
                  name: values.nftName,
                  symbol: values.nftSymbol,
                  network: values.blockchainNetwork,
                },
              }
        )
      );

      // blockchainNetwork: string, contractAddress: string, kind: KIND_TYPE, prizeTotal: number
      dispatch(setCourseNetwork(BlockchainNetworks[selectedNetwork?.name!]));
      dispatch(nextStep());
    }
  };

  const handleShowNftAddress = () => {
    setNewAward(modalType.options[0]);
  };

  const formInitialValues = {
    rewardPerLearner: savedReward?.rewardPerLearner,
    kind: savedReward?.kind,
    tokenExplorer: savedReward?.token?.explorer,
    tokenSymbol: savedReward?.token?.symbol,
    tokenName: savedReward?.token?.name,
    blockchainNetwork: blockchainNetworksSelected.find(
      (net) =>
        net?.name ===
        ChainIdToBlockChainName[
          (savedReward?.token?.network ||
            course.network ||
            course?.info?.chainId) as number
        ]
    )?._id,
    prizeTotal: savedReward?.prizeTotal,
    tokenContract:
      savedReward?.kind === "TOKEN"
        ? savedReward?.token?.contractAddress
        : savedReward?.nft?.contractAddress,
    contractAddress: savedReward?.nft?.contractAddress,
  };

  const handleShowTokenAddress = () => {
    setNewAward(modalType.options[0]);
  };
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();
  const { chainId: currentChain } = useEthers();

  const handleChangeNetwork = async (value: any) => {
    const selectedNetwork = blockchainNetworksSelected.find(
      (nw: any) => nw?._id === value
    );
    const newChainId = BlockchainNetworks[selectedNetwork?.name!] as number;
    if (currentChain !== newChainId) {
      if (!checkWallet()) return;
      try {
        await handleSwitchNetwork(newChainId);
      } catch (err: any) {
        handleMessageErrorSC(err?.reason || err?.message || err.toString());
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!course.rewards?.nft?.contractAddress) return;

      if (id) return;

      const { name, symbol } = await getERC721NameAndSymbol(
        course.rewards?.nft?.contractAddress
      );

      form.setFieldsValue({
        nftName: name,
        nftSymbol: symbol,
      });
    })();
  }, [course.rewards?.nft?.contractAddress, form, id]);

  const disableSensitiveField = course.info?.status === COURSE_STATUS.PUBLISHED;

  return (
    <>
      <HeadingCourse step={step} action={() => form.submit()} form={form} />
      <ContentWrapper>
        <StepContentWrapper>
          <Container>
            <AwardWrapper>
              <AwardDesc>
                Do you want to offer a prize to the users who complete
                successfully the course?
              </AwardDesc>
              <AwardTagWrapper>
                {COURSE_AWARDS.map((award) => (
                  <AwardBg
                    key={award.value}
                    checked={awardType === award.value}
                  >
                    <TagWrapper disable={disableSensitiveField}>
                      <AwardTag
                        onChange={(checked) =>
                          handleSelectType(award.value, checked)
                        }
                        checked={awardType === award.value}
                        key={award.value}
                      >
                        {award.icon}
                        Yes
                        <br />
                        {"With a " + award.title}
                        {awardType === award.value ? (
                          <Radio checked={true} />
                        ) : null}
                      </AwardTag>
                    </TagWrapper>
                  </AwardBg>
                ))}
              </AwardTagWrapper>
            </AwardWrapper>
            {!visible && awardType ? (
              <>
                <Divider type="horizontal" />
                <FormWrapper>
                  <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                      ...formInitialValues,
                    }}
                    onFinish={onFinish}
                    disabled={disableSensitiveField}
                    scrollToFirstError={scrollToFirstErrorSmooth}
                  >
                    {!newAward.includes("new") && (
                      <Row gutter={[24, 0]}>
                        <Col span={12}>
                          <Form.Item
                            label="Type of Award"
                            name="kind"
                            rules={[requiredRules]}
                          >
                            <StyledSelect disabled placeholder="Type of Award">
                              {COURSE_AWARDS.map((award) => (
                                <StyledOption
                                  value={award.value}
                                  key={award.value}
                                >
                                  {award?.title}
                                </StyledOption>
                              ))}
                            </StyledSelect>
                          </Form.Item>
                        </Col>
                        {awardType === KIND_TYPE.NFT ? (
                          <>
                            <Col span={12}>
                              <Form.Item
                                label="Network"
                                name="blockchainNetwork"
                                rules={[requiredRules]}
                              >
                                <StyledSelect
                                  placeholder="Blockchain Network"
                                  onChange={handleChangeNetwork}
                                >
                                  {blockchainNetworksSelected.map((network) => (
                                    <StyledOption
                                      value={network?._id}
                                      key={network?._id}
                                    >
                                      {network?.name}
                                    </StyledOption>
                                  ))}
                                </StyledSelect>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="Contract Address"
                                name="contractAddress"
                                rules={[
                                  requiredRules,
                                  () => {
                                    return {
                                      async validator(_, value) {
                                        if (!value) return Promise.resolve();

                                        if (!isAddress(value)) {
                                          form.setFields([
                                            {
                                              name: "nftName",
                                              value: "",
                                              errors: [],
                                            },
                                            {
                                              name: "nftSymbol",
                                              value: "",
                                              errors: [],
                                            },
                                          ]);

                                          return Promise.reject(
                                            "Not a valid address"
                                          );
                                        }

                                        try {
                                          await checkIfIsNFT(value);
                                          const { name, symbol } =
                                            await getERC721NameAndSymbol(value);

                                          if (!name) {
                                            form.setFields([
                                              {
                                                name: "nftName",
                                                value: "",
                                                errors: [],
                                              },
                                              {
                                                name: "nftSymbol",
                                                value: "",
                                                errors: [],
                                              },
                                            ]);

                                            return Promise.reject(
                                              "Not a valid nft address"
                                            );
                                          }

                                          form.setFields([
                                            {
                                              name: "nftName",
                                              value: name,
                                              errors: [],
                                            },
                                            {
                                              name: "nftSymbol",
                                              value: symbol,
                                              errors: [],
                                            },
                                          ]);
                                        } catch (error) {
                                          form.setFields([
                                            {
                                              name: "nftName",
                                              value: "",
                                              errors: [],
                                            },
                                            {
                                              name: "nftSymbol",
                                              value: "",
                                              errors: [],
                                            },
                                          ]);

                                          return Promise.reject(
                                            "Not a valid nft address"
                                          );
                                        }

                                        return Promise.resolve();
                                      },
                                    };
                                  },
                                ]}
                              >
                                <StyledInput placeholder="Text here" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label="Nft Name" name="nftName">
                                <StyledInput disabled placeholder="Nft Name" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item label="Nft Symbol" name="nftSymbol">
                                <StyledInput
                                  disabled
                                  placeholder="Nft Symbol"
                                />
                              </Form.Item>
                            </Col>
                          </>
                        ) : (
                          <CreateToken form={form} />
                        )}
                        {awardType === KIND_TYPE.TOKEN ? (
                          <Col span={12}>
                            <Form.Item
                              label="Reward per learner"
                              name="rewardPerLearner"
                              rules={[
                                requiredRules,
                                numberRules,
                                {
                                  type: "number",
                                },
                              ]}
                            >
                              <StyledInputNumber placeholder="Reward per learner" />
                            </Form.Item>
                          </Col>
                        ) : null}
                        <Col span={12}>
                          <Form.Item
                            label="Total Number of Eligible Prize Recipients"
                            name="prizeTotal"
                            rules={[
                              requiredRules,
                              numberRules,
                              {
                                type: "number",
                              },
                            ]}
                          >
                            <StyledInputNumber placeholder="Total Number of Eligible Prize Recipients" />
                          </Form.Item>
                        </Col>
                        {awardType === KIND_TYPE.TOKEN && showBudget ? (
                          <AwardWrapper>
                            <TotalBudget>
                              Total Course's Budget
                              <BudgetNumber>{budget} </BudgetNumber>
                            </TotalBudget>
                          </AwardWrapper>
                        ) : null}
                      </Row>
                    )}
                  </Form>
                  {newAward.includes("new") ? (
                    awardType === KIND_TYPE.NFT ? (
                      <CreateNFT
                        courseForm={form}
                        onClose={handleShowNftAddress}
                      />
                    ) : (
                      <NewTokenForm
                        courseForm={form}
                        onClose={handleShowTokenAddress}
                      />
                    )
                  ) : null}
                </FormWrapper>
              </>
            ) : null}
          </Container>
        </StepContentWrapper>
        <ModalCustom
          visible={visible}
          centered
          destroyOnClose
          closable
          width={490}
          onCancel={onClose}
          title={"With a " + modalType.title}
        >
          <AwardModalDesc>{modalType.desc}</AwardModalDesc>
          <AwardModalWrapper>
            {modalType.options.map((option) => (
              <AwardBg key={option} checked={newAward === option}>
                <AwardTag
                  onChange={(checked) => handleSelectNewAward(option, checked)}
                  checked={newAward === option}
                  key={option}
                >
                  {option}
                  {newAward === option ? <Radio checked={true} /> : null}
                </AwardTag>
              </AwardBg>
            ))}
            <ModalFooter>
              <RightAction>
                <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
                <PrimaryButton
                  icon={<NextIcon />}
                  onClick={() => setVisible(false)}
                >
                  Confirm
                </PrimaryButton>
              </RightAction>
            </ModalFooter>
          </AwardModalWrapper>
        </ModalCustom>
      </ContentWrapper>
    </>
  );
};

export default PrizeAndAwardSetup;
