export const OnlineIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="5.25"
        fill="#00DE8C"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};
export const OfflineIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="5.25"
        fill="#080F6C"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};
