import styled from "styled-components";

export const ErrorMessage = styled.p`
  color: #e33353;
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1;
  margin-top: 0.3125rem;
`;

export const EditorWrapper = styled.div<{
  width?: string;
  border?: string;
}>`
  .ck-editor__top {
    width: ${(p) => p.width}!important;
  }
  .ck-editor__main {
    width: ${(p) => p.width}!important;
    max-height: 300px;
    overflow-y: auto;
  }
  .ck-toolbar {
    background: #fff;
    border-top-left-radius: ${(p) => p.border}!important;
    border-top-right-radius: ${(p) => p.border}!important;
  }
  .ck-content {
    border-bottom-left-radius: ${(p) => p.border}!important;
    border-bottom-right-radius: ${(p) => p.border}!important;
  }

  .ck-toolbar__items {
    flex-wrap: wrap;
  }
`;
