import { FC, memo } from "react";
import { SectionHeaderProps } from "./sectionHeader.props";
import { SectionTitle, Wrapper } from "./sectionHeader.style";

const SectionHeader: FC<SectionHeaderProps> = ({ title, icon }) => {
  return (
    <Wrapper>
      {icon}
      <SectionTitle level={3}>{title}</SectionTitle>
    </Wrapper>
  );
};

export default memo(SectionHeader);
