import ResultsNotFound from "components/modules/resultsNotFound";
import { FC, memo, useCallback, useState } from "react";
import {
  ICollaboratorProps,
  // IWorkTaskDashboardData,
  IWorkTaskData,
} from "./collaborator.props";
import {
  Container,
  ContainerBodyInitiator,
  PanelCollapse,
  ContainerProjectSummary,
  ProjectSummaryContent,
  ProjectSummaryItem,
  ContentHeading,
  TitleHeading,
  SeeMore,
} from "./collaborator.style";
import { DownIcon } from "assets/icons/down.icon";
import imgNotFound from "assets/dashboard/result_not_found.png";
import {
  completedIcon,
  expiredIcon,
  openIcon,
  underApprovalIcon,
} from "assets/dashboard/index";
import { useQueries } from "@tanstack/react-query";
import {
  getAllCompletedWorkTasksCollaborator,
  getAllInProgressWorkTasksCollaborator,
  getAllSubmittedWorkTasksCollaborator,
  getAllWaitingApprovalProjectsColAPI,
} from "api/users";
import Worktask from "pages/Worktasks/Worktask";
import { AxiosResponse } from "axios";
import { useAppSelector } from "redux/hooks";

const Collaborator: FC<ICollaboratorProps> = () => {
  const userId = useAppSelector((state) => state.auth.user?._id);
  const [waitingApprovalLimit, setWaitingApprovalLimit] = useState(2);
  const [inProgressLimit, setInProgressLimit] = useState(2);
  const [submittedLimit, setSubmittedLimit] = useState(2);
  const [completedLimit, setCompletedLimit] = useState(2);
  // const [workTaskCols, setWorkTaskCols] = useState<IWorkTaskDashboardData>({});
  const headerCards = [
    {
      text: "Waiting Approval",
      limit: waitingApprovalLimit,
    },
    {
      text: "In Progress",
      limit: inProgressLimit,
    },
    {
      text: "Delivered",
      limit: submittedLimit,
    },
    {
      text: "Completed",
      limit: completedLimit,
    },
  ];
  const filterWtWithoutRemoveCollab = useCallback(
    (data: AxiosResponse<IWorkTaskData>) => {
      const newProjs = data?.responseData?.records?.map((curProj) => {
        const newWts = curProj?.packages?.filter((pack: any) => {
          const isAlreadyCollaborator = pack?.collaborators?.some(
            (collaborator: any) => collaborator === userId
          );
          const isAlreadyRequest = pack?.requests?.some(
            (request: any) => request.userId === userId
          );
          const isObserver = pack?.observersIds?.includes(userId);
          const isAlreadyRequestAndApprove = pack?.requests?.some(
            (item: any) =>
              item?.userId === userId && item?.status === "INACTIVE"
          );
          return !(
            !isAlreadyCollaborator &&
            isAlreadyRequest &&
            !isObserver &&
            isAlreadyRequestAndApprove
          );
        });

        return {
          ...curProj,
          packages: newWts,
        };
      });
      const newPackagesCount = newProjs?.reduce((prev, cur) => {
        return prev + (cur.packages?.length || 0);
      }, 0);

      return {
        ...data,
        responseData: {
          ...data?.responseData,
          packagesCount: newPackagesCount,
          records: newProjs,
        },
      };
    },
    [userId]
  );

  const allworktasksQueries = useQueries({
    queries: [
      {
        queryKey: ["getAllWaitingApproval", `limit-${waitingApprovalLimit}`],
        queryFn: () =>
          getAllWaitingApprovalProjectsColAPI({
            skip: 0,
            limit: waitingApprovalLimit,
          }),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["getAllInProgress", `limit-${inProgressLimit}`],
        queryFn: () =>
          getAllInProgressWorkTasksCollaborator({
            skip: 0,
            limit: inProgressLimit,
          }),
        select: filterWtWithoutRemoveCollab,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["getAllSubmitted", `limit-${submittedLimit}`],
        queryFn: () =>
          getAllSubmittedWorkTasksCollaborator({
            skip: 0,
            limit: submittedLimit,
          }),
        select: filterWtWithoutRemoveCollab,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["getAllCompleted", `limit-${completedLimit}`],
        queryFn: () =>
          getAllCompletedWorkTasksCollaborator({
            skip: 0,
            limit: completedLimit,
          }),
        refetchOnWindowFocus: false,
      },
    ],
  });

  const workTaskCols: (IWorkTaskData | undefined)[] = allworktasksQueries.map(
    (wtQuery) => wtQuery.data?.responseData
  );

  // const { mutateAsync: getAllWaitingApprovalWorkTasksColAsync } = useMutation(
  //   getAllWaitingApprovalProjectsColAPI,
  //   {
  //     onError: (err: AxiosError) => {
  //       const data: any = err.response?.data;
  //       message.error(data?.message);
  //     },
  //   }
  // );

  // const { mutateAsync: getInProgressWorkTasksColAsync } = useMutation(
  //   getAllInProgressWorkTasksCollaborator,
  //   {
  //     onError: (err: AxiosError) => {
  //       const data: any = err.response?.data;
  //       message.error(data?.message);
  //     },
  //   }
  // );

  // const { mutateAsync: getSubmittedWorkTasksColAsync } = useMutation(
  //   getAllSubmittedWorkTasksCollaborator,
  //   {
  //     onError: (err: AxiosError) => {
  //       const data: any = err.response?.data;
  //       message.error(data?.message);
  //     },
  //   }
  // );

  // const { mutateAsync: getCompletedWorkTasksColAsync } = useMutation(
  //   getAllCompletedWorkTasksCollaborator,
  //   {
  //     onError: (err: AxiosError) => {
  //       const data: any = err.response?.data;
  //       message.error(data?.message);
  //     },
  //   }
  // );

  // useEffect(() => {
  //   (async () => {
  //     const [open, inProgress, submitted, completed] = await Promise.all([
  //       getAllWaitingApprovalWorkTasksColAsync({ skip: 0, limit: 2 }),
  //       getInProgressWorkTasksColAsync({ skip: 0, limit: 2 }),
  //       getSubmittedWorkTasksColAsync({ skip: 0, limit: 2 }),
  //       getCompletedWorkTasksColAsync({ skip: 0, limit: 2 }),
  //     ]);
  //     setWorkTaskCols({
  //       ...workTaskCols,
  //       openWorkTasks: open.responseData,
  //       inProgressWorkTasks: inProgress.responseData,
  //       submittedWorkTasks: submitted.responseData,
  //       completedWorkTasks: completed.responseData,
  //     });
  //   })();
  // }, []);

  // click more button open
  const handleShowMoreCol = async (key: number) => {
    switch (key) {
      case 0:
        // const open = await getAllWaitingApprovalWorkTasksColAsync({
        //   skip: 0,
        //   limit: waitingApprovalLimit + 2,
        // });
        setWaitingApprovalLimit((prev) => prev + 2);
        // setWorkTaskCols({
        //   ...workTaskCols,
        //   openWorkTasks: { ...open.responseData },
        // });
        break;
      case 1:
        // const inProgress = await getInProgressWorkTasksColAsync({
        //   skip: 0,
        //   limit: inProgressLimit + 2,
        // });
        setInProgressLimit((prev) => prev + 2);
        // setWorkTaskCols({
        //   ...workTaskCols,
        //   inProgressWorkTasks: { ...inProgress.responseData },
        // });
        break;
      case 2:
        // const submitted = await getSubmittedWorkTasksColAsync({
        //   skip: 0,
        //   limit: submittedLimit + 2,
        // });
        setSubmittedLimit((prev) => prev + 2);
        // setWorkTaskCols({
        //   ...workTaskCols,
        //   submittedWorkTasks: { ...submitted.responseData },
        // });
        break;
      case 3:
        // const completed = await getCompletedWorkTasksColAsync({
        //   skip: 0,
        //   limit: completedLimit + 2,
        // });
        setCompletedLimit((prev) => prev + 2);
        // setWorkTaskCols({
        //   ...workTaskCols,
        //   completedWorkTasks: { ...completed.responseData },
        // });
        break;
    }
  };
  const titleHeading = [
    <TitleHeading color="#F57F20">{openIcon()} Waiting Approval</TitleHeading>,
    <TitleHeading color="#1CC98A">{completedIcon()} In Progress</TitleHeading>,
    <TitleHeading color="#00ADFB">
      {underApprovalIcon()} Submitted
    </TitleHeading>,
    <TitleHeading color="#FF6577">{expiredIcon()} Completed</TitleHeading>,
  ];

  return (
    <Container>
      <ContainerProjectSummary>
        <div>
          <ProjectSummaryContent>
            {workTaskCols.map((wtCol, id) => (
              <ProjectSummaryItem key={id}>
                {titleHeading[id]}
                <ContentHeading>{wtCol?.packagesCount}</ContentHeading>
              </ProjectSummaryItem>
            ))}
          </ProjectSummaryContent>
        </div>
      </ContainerProjectSummary>
      <ContainerBodyInitiator expandIconPosition="end">
        {workTaskCols.map((item, key) => (
          <PanelCollapse
            header={`${headerCards?.[key]?.text} (${item?.packagesCount || 0})`}
            key={key}
          >
            {(item?.packagesCount || 0) > 0 ? (
              item?.records?.map((project: any) =>
                project?.packages?.map((workTask: any, key: number) =>
                  workTask?.workStatus === "COMPLETED" ? (
                    <Worktask
                      data={workTask}
                      category={project?.category?.name || ""}
                      totalBudget={project?.totalBudget || 0}
                      key={key}
                      projectId={project._id}
                      hideButton={true}
                      worktaskOtherInfo={project}
                      hideNumber={true}
                      projectExpectedUsd={project?.expectedUSD}
                    />
                  ) : (
                    <Worktask
                      data={workTask}
                      category={project?.category?.name || ""}
                      totalBudget={project?.totalBudget || 0}
                      key={key}
                      projectId={project._id}
                      hideButton={true}
                      isDashboard={true}
                      worktaskOtherInfo={project}
                      projectExpectedUsd={project?.expectedUSD}
                    />
                  )
                )
              )
            ) : (
              <ResultsNotFound
                content={{
                  text: `You have no ${headerCards?.[key]?.text} packages`,
                }}
                img={imgNotFound}
                title="dashboard"
              />
            )}
            {(item?.packagesCount || 0) > 0 &&
            (item?.packagesCount || 0) > (headerCards?.[key]?.limit || 0) ? (
              <>
                <SeeMore onClick={() => handleShowMoreCol(key)}>
                  See more <DownIcon />
                </SeeMore>
              </>
            ) : (
              <></>
            )}
          </PanelCollapse>
        ))}
      </ContainerBodyInitiator>
    </Container>
  );
};

export default memo(Collaborator);
