import { FC, memo } from "react";
import { Link } from "react-router-dom";
import { camelToFormal } from "utils/camelToFormal";
import { ROUTER_CONSTANTS } from "utils/constant";
import TitleAndContext from "./titleAndContext";
import { IWorktaskCardProps } from "./worktaskCard.props";
import { Container, ViewButton } from "./worktaskCard.style";

const WorktaskCardObserver: FC<IWorktaskCardProps> = ({
  transaction,
}) => {
  const worktaskTransform = {
    worktaskName: transaction.project?.packages?.name,
    observerFees: transaction.project?.packages?.observerFees,
    transactionType: transaction.type,
    token: transaction.tokenName,
  };

  return (
    <Container column={Object.keys(worktaskTransform).length + 1}>
      {Object.keys(worktaskTransform).map((key, index) => (
        <TitleAndContext
          key={index}
          title={camelToFormal(key)}
          context={(worktaskTransform as any)[key]}
        />
      ))}
      <Link
        to={ROUTER_CONSTANTS.PROJECT.DETAIL.replace(":id", transaction?.projectId)}
      >
        <ViewButton>
          <span>View Project</span>
        </ViewButton>
      </Link>
    </Container>
  );
};

export default memo(WorktaskCardObserver);
