import { FC, useState } from "react";
import { IDashboardProps } from "./dashboard.props";

import {
  Container,
  ContainerFluidHeader,
  ContainerHeaderFilter,
  DashboardContainer,
  DashboardName,
  DashboardNameContainer,
  HeaderFilter,
  HeaderFilterTabs,
  DashboardMenuItem,
  DashboardMenuTabs,
  Wrap,
} from "./dashboard.style";
import Initiator from "./initiator";
import Collaborator from "./collaborator";

const Dashboard: FC<IDashboardProps> = () => {
  const [dashboardTabKey, setDashboardTabKey] = useState<
    "initiator" | "collaborator"
  >("initiator");
  const [dashboardPageTabs, setDashboardPageTabs] = useState<any>([
    {
      name: "Initiator",
      keyName: "initiator",
      active: true,
    },
    {
      name: "Collaborator",
      keyName: "collaborator",
      active: false,
    },
  ]);

  return (
    <Container>
      <ContainerFluidHeader>
        <DashboardContainer>
          <DashboardNameContainer span={24}>
            <DashboardName>Dashboard</DashboardName>
          </DashboardNameContainer>
          <ContainerHeaderFilter span={24}>
            <HeaderFilter>
              <HeaderFilterTabs>
                <DashboardMenuTabs>
                  {dashboardPageTabs?.map((item: any, key: number) => (
                    <DashboardMenuItem
                      key={key}
                      active={item.active}
                      onClick={() => {
                        const newDashboardPageTabs = [...dashboardPageTabs];
                        newDashboardPageTabs.map(
                          (item: any, index: number) =>
                            (item.active = key === index ? true : false)
                        );
                        setDashboardPageTabs(newDashboardPageTabs);
                        setDashboardTabKey(item.keyName);
                      }}
                    >
                      {item.name}
                    </DashboardMenuItem>
                  ))}
                </DashboardMenuTabs>
              </HeaderFilterTabs>
            </HeaderFilter>
          </ContainerHeaderFilter>
        </DashboardContainer>
      </ContainerFluidHeader>
      <Wrap>
        {dashboardTabKey === "initiator" ? <Initiator /> : <Collaborator />}
      </Wrap>
    </Container>
  );
};

export default Dashboard;
