import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  height: 302px;
  border-radius: 0.25rem;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  grid-gap: 1.25rem 1.25rem;
  background: white;
  cursor: pointer;

  margin-top: 10px;
  margin-bottom: 10px;
  transition: 0.3s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 9px;
  }
`;

export const CardImage = styled.div<{ bg: string }>`
  height: 15rem;
  background-color: #cacaca;
  background-image: url(${(p) => p.bg});
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem 0.25rem 0 0;
`;

export const CardFooter = styled.div`
  border-top: 1px solid rgba(8, 10, 37, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 1.2188rem 1rem;
  align-items: center;
`;

export const Title = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  font-size: 1.25rem;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #080f6c;
  word-break: break-word;
  margin-bottom: unset;
`;
