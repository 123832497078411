import { FC, useEffect, useState } from "react";
import { IProjectBoardProps } from "./ProjectBoard.props";
// import ProjectBoardBackground from 'assets/images/project-board/project-board-background.svg'
import {} from "assets/images/project-board";
// import { BodyWrap, Container } from "./ProjectBoard.style";
import CardModal from "./Components/CardModal";
// import {
//   BackgroundImage,
//   TitleWrap,
// } from "pages/SubmitDeliverable/SubmitDeliverable.style";
// import SubmitDeliverableBackground from "assets/images/submit-deliverable/submit-deliverable-background.png";
import { Board } from "./Components/Board/Board";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { getProjectBoardDetailsAPI } from "api/users";
import { AxiosResponse } from "axios";
import { PROJECT_BOARD_ROLE } from "utils/constant";

const ProjectBoard: FC<IProjectBoardProps> = ({
  packageId,
  projectId,
  worktaskDetail,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [cardData, setCardData] = useState<any>({});

  const userId: string = useAppSelector((state) => state.auth.user?._id);
  // const param = useParams();
  const projectIdentity = {
    userId,
    projectId: projectId || "",
    packageId: packageId || "",
  };

  const [projectBoardDetails, setProjectBoardDetails] = useState<any>();
  const [role, setRole] = useState<string>("");
  const [lanes, setLanes] = useState<any>();
  const navigate = useNavigate();

  const { refetch } = useQuery(
    ["getProjectBoardDetails"],
    () => getProjectBoardDetailsAPI(projectId || "", packageId || ""),
    {
      onSuccess: async (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setProjectBoardDetails(res.responseData);
          // console.log("setProjectBoardDetails", res.responseData);

          const role = getRole(res.responseData);
          if (!role) {
            navigate("/");
            return;
          }
          setRole(role);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (projectBoardDetails) {
      setLanes(projectBoardDetails?.lanes);
      if (cardData) {
        projectBoardDetails?.lanes?.find((land: any) =>
          land?.cards?.find((card: any) => {
            if (card._id === cardData._id) {
              setCardData(card);
              return true;
            }
            return false;
          })
        );
      }
    }
  }, [projectBoardDetails]);

  function getRole(projectBoardDetails: any) {
    if (projectBoardDetails?.initiator === userId)
      return PROJECT_BOARD_ROLE.CREATOR;
    if (projectBoardDetails?.observers?.find((ob: string) => ob === userId))
      return PROJECT_BOARD_ROLE.OBSERVER;
    if (
      projectBoardDetails?.packageMembers?.find(
        (collab: any) => collab?._id === userId
      )
    )
      return PROJECT_BOARD_ROLE.COLLABORATOR;
    return "";
  }

  return (
    <>
      {/* <Container>
      <TitleWrap>
        <BackgroundImage
          src={SubmitDeliverableBackground}
          alt="SubmitDeliverable background"
        />
        Worktask
      </TitleWrap> */}

      {/* <BodyWrap> */}
      <Board
        setCardData={setCardData}
        setOpenModal={setOpenModal}
        projectBoardDetails={projectBoardDetails}
        refetch={refetch}
        role={role}
        worktaskDetail={worktaskDetail}
      />
      {/* </BodyWrap> */}
      <CardModal
        open={openModal}
        data={cardData}
        closeModel={() => setOpenModal(false)}
        projectIdentity={projectIdentity}
        refetch={refetch}
        lanes={lanes}
        role={role}
      />
      {/* </Container> */}
    </>
  );
};

export default ProjectBoard;
