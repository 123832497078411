export const FacebookIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 12.0586C1.5 17.2789 5.29138 21.6197 10.25 22.5V14.9164H7.625V12H10.25V9.66638C10.25 7.04138 11.9414 5.58362 14.3336 5.58362C15.0914 5.58362 15.9086 5.7 16.6664 5.81638V8.5H15.325C14.0414 8.5 13.75 9.14137 13.75 9.95862V12H16.55L16.0836 14.9164H13.75V22.5C18.7086 21.6197 22.5 17.2798 22.5 12.0586C22.5 6.25125 17.775 1.5 12 1.5C6.225 1.5 1.5 6.25125 1.5 12.0586Z"
      fill="#292E73"
      fillOpacity="0.82"
    />
  </svg>
);

export const TwitterIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM17.0461 9.41484C17.0531 9.525 17.0531 9.63984 17.0531 9.75234C17.0531 13.193 14.4328 17.1562 9.64453 17.1562C8.16797 17.1562 6.79922 16.7273 5.64609 15.9891C5.85703 16.0125 6.05859 16.0219 6.27422 16.0219C7.49297 16.0219 8.61328 15.6094 9.50625 14.9109C8.3625 14.8875 7.40156 14.1375 7.07344 13.1063C7.47422 13.1648 7.83516 13.1648 8.24766 13.0594C7.65873 12.9397 7.12939 12.6199 6.74957 12.1542C6.36974 11.6885 6.16286 11.1056 6.16406 10.5047V10.4719C6.50859 10.6664 6.91406 10.7859 7.33828 10.8023C6.98166 10.5647 6.6892 10.2427 6.48682 9.86491C6.28445 9.48715 6.17841 9.06528 6.17813 8.63672C6.17813 8.15156 6.30469 7.70859 6.53203 7.32422C7.18571 8.12891 8.0014 8.78705 8.92609 9.25586C9.85078 9.72466 10.8638 9.99364 11.8992 10.0453C11.5312 8.27578 12.8531 6.84375 14.4422 6.84375C15.1922 6.84375 15.8672 7.15781 16.343 7.66406C16.9312 7.55391 17.4937 7.33359 17.9953 7.03828C17.8008 7.64062 17.393 8.14922 16.8516 8.47031C17.3766 8.41406 17.8828 8.26875 18.3516 8.06484C17.9977 8.58516 17.5547 9.04688 17.0461 9.41484Z"
      fill="#292E73"
      fillOpacity="0.82"
    />
  </svg>
);

export const LinkedInIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 3.4325C2 2.64125 2.6575 2 3.46875 2H20.5312C21.3425 2 22 2.64125 22 3.4325V20.5675C22 21.3587 21.3425 22 20.5312 22H3.46875C2.6575 22 2 21.3587 2 20.5675V3.4325ZM8.17875 18.7425V9.71125H5.1775V18.7425H8.17875ZM6.67875 8.4775C7.725 8.4775 8.37625 7.785 8.37625 6.9175C8.3575 6.03125 7.72625 5.3575 6.69875 5.3575C5.67125 5.3575 5 6.0325 5 6.9175C5 7.785 5.65125 8.4775 6.65875 8.4775H6.67875ZM12.8138 18.7425V13.6988C12.8138 13.4288 12.8337 13.1587 12.9137 12.9662C13.13 12.4275 13.6238 11.8687 14.4538 11.8687C15.54 11.8687 15.9738 12.6962 15.9738 13.9113V18.7425H18.975V13.5625C18.975 10.7875 17.495 9.4975 15.52 9.4975C13.9275 9.4975 13.2137 10.3725 12.8138 10.9888V11.02H12.7938C12.8004 11.0096 12.8071 10.9991 12.8138 10.9888V9.71125H9.81375C9.85125 10.5588 9.81375 18.7425 9.81375 18.7425H12.8138Z"
      fill="#292E73"
      fillOpacity="0.82"
    />
  </svg>
);

export const WebsiteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 2C6.7103 2 2 6.7103 2 12.5C2 18.2897 6.7103 23 12.5 23C18.2897 23 23 18.2897 23 12.5C23 6.7103 18.2897 2 12.5 2ZM20.8275 11.45H17.9253C17.7958 9.15181 17.1529 6.91186 16.0437 4.89485C17.3292 5.4955 18.4418 6.41147 19.2782 7.55756C20.1146 8.70365 20.6476 10.0426 20.8275 11.45ZM13.0565 4.12835C14.1432 5.56055 15.6048 8.09735 15.8148 11.45H9.3815C9.52745 8.7242 10.4252 6.1706 11.955 4.1273C12.1356 4.1168 12.3162 4.1 12.5 4.1C12.6879 4.1 12.8717 4.1168 13.0565 4.12835ZM9.0224 4.86335C7.9892 6.8489 7.3928 9.1001 7.2815 11.45H4.17245C4.35386 10.0302 4.89446 8.68021 5.74314 7.52764C6.59181 6.37507 7.72048 5.45806 9.0224 4.86335ZM4.17245 13.55H7.29515C7.43795 16.0479 7.9934 18.2519 8.92895 20.0915C7.65025 19.4887 6.54411 18.5734 5.71276 17.43C4.8814 16.2867 4.35165 14.9522 4.17245 13.55ZM11.9225 20.8716C10.4514 19.0887 9.5831 16.5908 9.39305 13.55H15.8117C15.5933 16.4616 14.6388 19.0058 13.0785 20.8706C12.8874 20.8832 12.6963 20.9 12.5 20.9C12.3047 20.9 12.1146 20.8832 11.9225 20.8716ZM16.134 20.061C17.1368 18.1773 17.749 15.965 17.9096 13.55H20.8265C20.6493 14.9405 20.1272 16.2646 19.3076 17.4018C18.488 18.539 17.3971 19.4531 16.134 20.061Z"
      fill="#292E73"
      fillOpacity="0.82"
    />
  </svg>
);

export const PersonalBlogIcon = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM4 4V8H8V4H4ZM4 10V12H14V10H4ZM4 14V16H14V14H4ZM10 5V7H14V5H10Z"
      fill="#292E73"
      fillOpacity="0.82"
    />
  </svg>
);
