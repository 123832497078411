import { FC } from "react";
import {
  TitleWrapper,
  ProjectTitle,
  StepIndication,
} from "../createProject.style";
import {
  StepThreeWrapper,
  CompleteSection,
  CongratImgWrapper,
  CongratTitle,
  CongratDesc,
  SeeProjectBtn,
  CongratInfo,
  BackHomeBtn,
  BackBtnWrapper,
} from "./projectStepThree.style";
import CongratImg from "assets/images/party-popper-with-confetti.png";
import { BlurArrowLeftIcon } from "assets/icons/arrowRight.icon";
import { ProjectStepThreeProps } from "../createProject.props";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";

const ProjectStepThree: FC<ProjectStepThreeProps> = ({ createdProjectId }) => {
  const navigate = useNavigate();

  return (
    <StepThreeWrapper>
      <TitleWrapper space="10px">
        <ProjectTitle>Completed</ProjectTitle>
        <StepIndication>Step Completed</StepIndication>
      </TitleWrapper>
      <CompleteSection>
        <CongratImgWrapper src={CongratImg} alt="congrat" />
        <CongratInfo space="16px">
          <CongratTitle>Your project was successfully created!</CongratTitle>
          <CongratDesc>
            Now it's time to spread the words and invite collaborators to join
            you in this BUILD journey! Request resources from our friendly
            community managers to help you get started with your project.
          </CongratDesc>
          <SeeProjectBtn
            onClick={() => {
              if (createdProjectId.length > 0)
                navigate(
                  ROUTER_CONSTANTS.PROJECT.DETAIL.replace(
                    ":id",
                    createdProjectId
                  )
                );
              else navigate("/");
            }}
          >
            See the project
          </SeeProjectBtn>
        </CongratInfo>
      </CompleteSection>
      <BackBtnWrapper>
        <BackHomeBtn onClick={() => navigate("/")}>
          <BlurArrowLeftIcon />
          go back home
        </BackHomeBtn>
      </BackBtnWrapper>
    </StepThreeWrapper>
  );
};

export default ProjectStepThree;
