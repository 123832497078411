import styled from "styled-components";
import { Typography } from "antd";

const { Title } = Typography;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SectionTitle = styled(Title)`
  &.ant-typography {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    color: #080f6c;
    font-family: "DM Sans";
    letter-spacing: -0.64px;
  }
`;
