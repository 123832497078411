import { CalendarIcon } from "assets/icons/calender.icon";
import {
  BigCardContainer,
  BigCardTitle,
  Content,
  DateAndAuthor,
  DateAndCategory,
  SmallCardContainer,
} from "./ourRecentBlogs.styles";
import { TagName } from "components/modules/recentUpdatesCard/recentUpdatesCard.style";
import { IBlog } from "api/blog";
import dayjs from "dayjs";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
const { Paragraph } = Typography;

export const BigCard = ({ blog }: { blog: IBlog }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(ROUTER_CONSTANTS.BLOG.BLOG_DETAIL.replace(":id", blog._id));
  };
  return (
    <BigCardContainer onClick={handleNavigate}>
      <img src={blog.coverImage} alt="" />
      <DateAndCategory>
        <div>
          <CalendarIcon />
          <h1>{dayjs(blog.updatedAt).format("MMMM DD, YYYY")}</h1>
        </div>
        <TagName color="#7E00FD">{blog.categories[0].name} </TagName>
      </DateAndCategory>
      <Paragraph ellipsis={{ rows: 2 }}>
        <BigCardTitle>{blog.title}</BigCardTitle>
      </Paragraph>
    </BigCardContainer>
  );
};

export const SmallCard = ({ blog }: { blog: IBlog }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(ROUTER_CONSTANTS.BLOG.BLOG_DETAIL.replace(":id", blog._id));
  };
  return (
    <SmallCardContainer>
      <img src={blog.coverImage} alt="" onClick={handleNavigate} />
      <Content>
        <div className="above">
          <span>{blog.categories[0].name}</span>
          <Paragraph ellipsis={{ rows: 3 }} onClick={handleNavigate}>
            <h1>{blog.title}</h1>
          </Paragraph>
        </div>
        <DateAndAuthor>
          <h1 style={{ display: "inline" }}>
            By: <strong>Admin</strong>
          </h1>
          {"|"}
          <span>{dayjs(blog.updatedAt).format("MMMM - DD - YYYY")}</span>
        </DateAndAuthor>
      </Content>
    </SmallCardContainer>
  );
};
