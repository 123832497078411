import React, { FC, useEffect, useRef, useState } from "react";
import {
  AttachFilesInterface,
  CommentItem,
  ICommentComponentProps,
  ITagsSelectionBySearchComponentProps,
  ITagsSelectionComponentProps,
  TagsArrayType,
} from "../CardModal.props";
import {
  ArrowDownIcon,
  AtIcon,
  CrosshairIcon,
  FileIcon,
  GrayAttachIcon,
  LikeIcon,
  PaperClipIcon,
  SmileyIcon,
  TrashIcon,
  UrlLinkIcon,
} from "assets/images/project-board";
import {
  AttachItem,
  AttachWrap,
  GlobalStyle,
  DescriptionWrap,
  ImageAttach,
  Name,
  PopoverAntd,
  ShowedCommentActions,
  ShowedCommentAttached,
  ShowedCommentContent,
  ShowedCommentItem,
  ShowedCommentTime,
  ShowedCommentWrap,
  Text,
  PopoverSelectItem,
  CommentWrap,
  CommentBoxWrap,
  CommentActionsWrap,
  CommentTextarea,
  TagSelectWrap,
  SearchInput,
  TagSelectItem,
  TagSelectionBySearchWrap,
  TagSelectionWrap,
  EmojiSelectionWrap,
  UploadWrap,
  BadgeAntd,
  WrapDropdownIcon,
} from "../CardModal.style";
import PrimaryButton from "components/base/primaryButton";

import Pic1 from "assets/images/project-board/pic1.png";
import { CloseIcon } from "assets/images/deliver-work";

import Picker, { IEmojiData } from "emoji-picker-react";
import { useMutation } from "@tanstack/react-query";
import { deleteCommentAPI, postUploadsAPI, updateCommentAPI } from "api/users";
import axios, { AxiosError } from "axios";
import moment from "moment";
import { confirmModal } from "components/modules/confirmModal";
import { message, UploadProps } from "antd";
import OutlinedButton from "components/base/outlinedButton";
import { ServedFile } from "pages/Conversation/Conversation.props";
import { ImageAntd, UploadAntd } from "pages/Conversation/Conversation.style";
import { AvatarAntd } from "components/modules/avatarList/avatarList.style";
import { UserOutlined } from "@ant-design/icons";
import { regUrl, validateDoc } from "utils/regex";
import { LIKE_COMMENT, PROJECT_BOARD_ROLE, validFiles } from "utils/constant";
import { setPageLoading } from "redux/slices";
import { useAppDispatch } from "redux/hooks";

const record: {
  cursorPosition: number;
  isUpdate: boolean;
  tagSearchedindex: number;
  isDisableKey: boolean;
  setIndex: (index: number) => void;
  validTagsLength: number;
  validTags: TagsArrayType[];
  appendText: (text: string) => void;
  closeTagSelection: () => void;
} = {
  cursorPosition: 0,
  isUpdate: false,
  tagSearchedindex: 0,
  isDisableKey: false,
  setIndex: (index: number) => {},
  validTagsLength: 0,
  validTags: [],
  appendText: (text: string) => {},
  closeTagSelection: () => {},
};

const CommentComponent: FC<ICommentComponentProps> = ({
  children,
  projectIdentity,
  data,
  refetch,
  updateCard,
  role,
}) => {
  const [comment, setComment] = useState<string>("");
  const textAreaE = useRef() as React.MutableRefObject<HTMLTextAreaElement>;
  const [attachFiles, setAttachFiles] = useState<AttachFilesInterface[]>([]);
  const dispatch = useAppDispatch();

  const [commentList, setCommentList] = useState<CommentItem[]>([]);
  const [tagsArray, setTagsArray] = useState<TagsArrayType[]>([]);
  const onEmojiClick = (event: any, emojiObject: IEmojiData) => {
    const text = emojiObject.emoji;
    setOpenEmojiSelection(false);
    appendText(text);
    event.stopPropagation();
  };

  function generateCommentList(comments: any) {
    const newAttachFiles: AttachFilesInterface[] = [];

    const pinToTopList: CommentItem[] = [];
    const notPinToTopList: CommentItem[] = [];

    comments.forEach((data: any) => {
      const result = generateComment(data?.commentText || "", data?._id);

      const object = {
        name: data?.commentedBy?.fullName,
        time: moment(data?.commentedAt).fromNow(),
        avatar: data?.commentedBy?.profilePicture,
        comment: result.comment,
        isAttach: result.isAttach,
        attachFiles: result.attachFiles,
        id: data?._id,
        rawComment: data?.commentText,
        sender: data?.commentedBy?._id,
        pinToTop: data?.pinToTop,
        likedBy: data?.likedBy,
      };
      if (typeof data?.pinToTop === "number") {
        pinToTopList.push(object);
      } else notPinToTopList.push(object);
      if (result.isAttach)
        result.attachFiles.forEach((e) => newAttachFiles.push(e));
    });

    pinToTopList.sort((a: any, b: any) => {
      if (b.pinToTop - a.pinToTop > 0) return 1;
      return -1;
    });

    setCommentList([...pinToTopList, ...notPinToTopList]);
    setAttachFiles(newAttachFiles);
  }

  function generateComment(comment: string, commentId: string) {
    const result: {
      comment: string;
      isAttach: boolean;
      attachFiles: AttachFilesInterface[];
    } = {
      comment: comment,
      isAttach: false,
      attachFiles: [],
    };
    const regexScript = /\[[^[\]]*\]\([^()]*\)/g;
    const regexData = /(\[[^[\]]*\]|\([^()]*\))/g;
    const regexTag = /@[\S]+/g;
    const tags = comment.match(regexTag) || [];
    const replacedTags: {
      tag: string;
      replaceTag: string;
    }[] = [];
    tags.forEach((tag) => {
      const replaceText = getTagName(tag);

      if (replaceText)
        replacedTags.push({
          tag,
          replaceTag: `<span>@${replaceText}</span>`,
        });
    });

    const scripts = comment.match(regexScript);
    const replacedScripts: {
      script: string;
      replacedScript: string;
    }[] = [];
    const attachFiles: AttachFilesInterface[] = [];

    if (scripts) {
      scripts?.forEach((script) => {
        const datas =
          script
            .match(regexData)
            ?.map((data) => data.slice(1, data.length - 1)) || [];
        const replace = {
          script,
          replacedScript: script,
        };

        if (datas[0] && datas[0].includes("file:")) {
          const url = datas[1] || "";
          const fileName = datas[0].slice(5, datas[0].length);

          attachFiles.push({
            url,
            fileName,
            commentText: comment,
            commentId,
            embeddedScript: script,
          });
          replace.replacedScript = `<a href="${url}" download>[${fileName}]</a>`;
        } else {
          const url = datas[1] || "";
          const text = datas[0] || "";
          replace.replacedScript = `<a href="${url}" >${text}</a>`;
        }
        replacedScripts.push(replace);
      });
    }

    let replacedComment = comment;
    replacedTags.forEach(
      (obj) =>
        (replacedComment = replacedComment.replaceAll(obj.tag, obj.replaceTag))
    );
    replacedScripts.forEach(
      (obj) =>
        (replacedComment = replacedComment.replace(
          obj.script,
          obj.replacedScript
        ))
    );

    const t = replacedComment.replaceAll(/href="[^"]+"/g, "");
    const plainUrlTexts = t.match(regUrl);

    plainUrlTexts &&
      plainUrlTexts.forEach(
        (url) =>
          (replacedComment = replacedComment.replace(
            url,
            `<a href="${url}">${url}</a>`
          ))
      );

    const emojiRegex = /^[\p{Extended_Pictographic}\s]+$/u;
    if (emojiRegex.test(replacedComment)) {
      replacedComment = `<span style="font-size: 30px; line-height: 140%"}>${replacedComment}</span>`;
    }

    result.comment = replacedComment;
    if (attachFiles.length > 0) {
      result.isAttach = true;
      result.attachFiles = attachFiles;
    }

    return result;
  }

  function getTagName(tag: string) {
    return tagsArray.find((e) => tag.includes(e.idTag))?.name || "";
  }

  function attachCommentFile(file: ServedFile) {
    const objectFileFromBE = {
      name: file.originName.replaceAll(/[[\]]/g, ""),
      url: file.fileName,
    };
    const attachText = `[file:${objectFileFromBE.name}](${objectFileFromBE.url}) `;
    appendText(attachText);
  }

  function appendText(appendedText: string) {
    const index = textAreaE.current.selectionStart;
    let firstChunk = comment.slice(0, index);
    const restChunk = comment.slice(index);

    let space =
      firstChunk[firstChunk.length - 1] !== " " && firstChunk.length ? " " : "";

    let removeLength = 0;
    if (appendedText[0] === "@") {
      const result = firstChunk.match(/@[^@\s]+$/);

      if (result) {
        firstChunk = firstChunk.slice(0, firstChunk.length - result[0].length);
        removeLength = result[0].length;
      }
      space = "";
    }

    const emojiRegex = /^[\p{Extended_Pictographic}\s]+$/u;
    if (emojiRegex.test(appendedText)) {
      space = "";
    }

    const updatedComment = firstChunk + space + appendedText + restChunk;

    setComment(updatedComment);
    record.cursorPosition =
      index + space.length + appendedText.length - removeLength;
    record.isUpdate = true;
  }
  record.appendText = appendText;

  function onLinkClick() {
    appendText("[](url) ");
  }

  function saveComment() {
    if (!comment.trim()) return;

    const replaceEnter = comment.trim().replaceAll("\n", "<br/>");

    if (isEditing) {
      const params = {
        projectId: projectIdentity.projectId || "",
        packageId: projectIdentity.packageId || "",
        cardId: data._id,
        commentId: isEditing,
        body: {
          commentText: replaceEnter,
          attachment: [],
        },
      };

      updateComment(params);
      return;
    }

    const params = {
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data._id,
      body: {
        commentText: replaceEnter,
        attachment: [],
      },
    };
    updateCard(params);
  }

  const [openTagSelection, setOpenTagSelection] = useState<boolean>(false);
  const [openEmojiSelection, setOpenEmojiSelection] = useState<boolean>(false);

  useEffect(() => {
    // generateCommentList();
    textAreaE.current.addEventListener("keydown", (e) => {
      if (record.isDisableKey)
        if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Enter") {
          e.preventDefault();
          return false;
        }
    });

    window.onkeyup = onKeyUp;
    return window.removeEventListener("keyup", onKeyUp);
  }, []);

  useEffect(() => {
    if (data) {
      const tags = data?.profiles.map((profile: any) => {
        return {
          idTag: profile?.emailId.split("@")[0],
          name: profile?.fullName,
          avatar: profile?.profilePicture,
          id: profile?._id,
        };
      });
      setTagsArray(tags);
      record.validTags = tags;
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      generateCommentList(data?.comments);
      setComment("");
    }
  }, [tagsArray]);

  function onKeyUp(e: KeyboardEvent) {
    e.preventDefault();

    if (record.isDisableKey) {
      if (e.key === "ArrowUp") {
        if (record.tagSearchedindex === 0) {
          record.setIndex(record.validTagsLength - 1);
        } else {
          record.setIndex(record.tagSearchedindex - 1);
        }
      }

      if (e.key === "ArrowDown") {
        if (record.tagSearchedindex === record.validTagsLength - 1) {
          record.setIndex(0);
        } else {
          record.setIndex(record.tagSearchedindex + 1);
        }
      }

      if (e.key === "Enter") {
        record.appendText(
          "@" + record.validTags[record.tagSearchedindex].idTag + " "
        );

        record.closeTagSelection();
      }
    }
  }

  useEffect(() => {
    if (record.isUpdate) {
      textAreaE.current.selectionStart = record.cursorPosition;
      textAreaE.current.selectionEnd = record.cursorPosition;
      textAreaE.current.focus();
      record.isUpdate = false;
    }

    isSearchForTag();
  }, [comment]);

  const [searchTagText, setSearchTagText] = useState<string>("");
  function isSearchForTag() {
    if (!comment.trim()) {
      setSearchTagText("");
      return;
    }
    const selectionStart = textAreaE.current.selectionStart;
    const text = comment.slice(0, selectionStart);
    const reg = /(\s@[^\s@]+$|^@[^\s@]+$)/g;

    const result = text.match(reg);
    if (!result) {
      setSearchTagText("");
      return;
    }
    const searchText = result[0]?.split("@")[1];

    setSearchTagText(searchText);
  }

  const emojiSelctionWrapE = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: Event) => {
    if (
      emojiSelctionWrapE.current &&
      !emojiSelctionWrapE.current.contains(event.target as Node)
    ) {
      setOpenEmojiSelection(false);
    }
    if (popoverCommentOptionsIndex > -1) setPopoverCommentOptionsIndex(-1);
    if (popoverAttachFileOptionsIndex > -1)
      setPopoverAttachFileOptionsIndex(-1);
  };

  const [popoverCommentOptionsIndex, setPopoverCommentOptionsIndex] =
    useState<number>(-1);
  const [popoverAttachFileOptionsIndex, setPopoverAttachFileOptionsIndex] =
    useState<number>(-1);

  useEffect(() => {
    document.onclick = null;
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [popoverCommentOptionsIndex, popoverAttachFileOptionsIndex]);

  function showDeleteCommentConfirm(index: number) {
    if (isEditing) cancelEdit();
    setPopoverCommentOptionsIndex(-1);
    confirmModal({
      title: "Are you sure delete this Comment?",
      content: "This action can not be undo!",
      onOk: () => onDeleteComment(index),
    });
  }

  function onDeleteComment(index: number) {
    const params = {
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data._id,
      commentId: commentList[index].id || "",
    };

    deleteComment(params);
  }
  const { mutate: deleteComment } = useMutation(deleteCommentAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        refetch();
      } else message.warning(responseMessage);
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const [isEditing, setIsEditing] = useState<string>("");
  function editComment(commentId: string, index: number) {
    setPopoverCommentOptionsIndex(-1);
    setIsEditing(commentId);

    const replaceBr = commentList[index].rawComment
      .trim()
      .replaceAll("<br/>", "\n");
    setComment(replaceBr);
  }
  function cancelEdit() {
    setIsEditing("");
    setComment("");
  }

  const { mutate: updateComment } = useMutation(updateCommentAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        setComment("");
        setIsEditing("");
        refetch();
      } else message.warning(responseMessage);
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  function pinToTop(id: string) {
    if (isEditing) cancelEdit();
    setPopoverCommentOptionsIndex(-1);
    const params = {
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data?._id || "",
      commentId: id || "",
      body: {
        pinToTop: Date.now(),
      },
    };

    updateComment(params);
  }

  // function copyCommentList(index: number) {
  //   if (isEditing) cancelEdit();
  //   setPopoverCommentOptionsIndex(-1);
  // }

  const fileRef = useRef<any>(null);
  const upLoadProps: UploadProps = {
    name: "single-file",
    accept: validFiles,
    // multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isImage = validateDoc.test(file.name);
      if (!isImage) {
        message.error("File is invalid!");
        return false;
      }
      if (file.size > 5000000) {
        message.error("File is too big");
        return false;
      } else {
        fileRef.current = file;

        postUploads({
          fileName: file.name.replaceAll(/[[\]()]/gi, ""),
          fileType: file.type,
        });
      }

      return false;
    },
  };
  const { mutate: postUploads } = useMutation(postUploadsAPI, {
    onSuccess: async (rsUpload: any) => {
      dispatch(setPageLoading(true));
      await axios
        .put(rsUpload.signedRequest, fileRef.current)
        .then((rs: any) => {
          const file: ServedFile = {
            fileName: rsUpload.url,
            size: fileRef.current.size,
            type: fileRef.current.type?.includes("image/") ? "image" : "file",
            originName: rsUpload.file_name,
          };
          attachCommentFile(file);
          dispatch(setPageLoading(false));
        })
        .catch((err: AxiosError) => {
          message.error("Error uploading: " + err.message);
        });
    },
    onError: (err: AxiosError) => {
      message.error(err.message);
    },
  });
  function showDeleteAttachConfirm(file: AttachFilesInterface) {
    if (isEditing) cancelEdit();
    setPopoverCommentOptionsIndex(-1);
    confirmModal({
      title: `Are you sure delete ${file?.fileName}?`,
      content: "This action can not be undo!",
      onOk: () => deleteAttachFile(file),
    });
  }
  function deleteAttachFile(file: AttachFilesInterface) {
    setPopoverAttachFileOptionsIndex(-1);
    let replacedComment = file.commentText.replace(
      " " + file.embeddedScript + " ",
      " "
    );
    if (replacedComment === file.commentText)
      replacedComment = file.commentText.replace(file.embeddedScript, "");

    replacedComment = replacedComment.replaceAll(/^\s*<br\/>/g, "");
    replacedComment = replacedComment.replaceAll(/<br\/>\s*$/g, "");
    replacedComment = replacedComment.replaceAll(/<br\/>\s*<br\/>/g, "<br/>");
    replacedComment = replacedComment.trim();

    if (!!replacedComment) {
      const params = {
        projectId: projectIdentity.projectId || "",
        packageId: projectIdentity.packageId || "",
        cardId: data?._id || "",
        commentId: file.commentId || "",
        body: {
          commentText: replacedComment,
          attachment: [],
        },
      };

      updateComment(params);
    } else {
      const params = {
        projectId: projectIdentity.projectId || "",
        packageId: projectIdentity.packageId || "",
        cardId: data?._id || "",
        commentId: file.commentId,
      };

      deleteComment(params);
    }
  }

  function checkCommentChange(newValue: string) {
    // check code
    setComment(newValue);
  }

  function likeComment(id: string, likeBy: string[]) {
    const like =
      likeBy?.indexOf(projectIdentity?.userId || "") > -1
        ? LIKE_COMMENT.UNLIKE
        : LIKE_COMMENT.LIKE;
    const params = {
      projectId: projectIdentity.projectId || "",
      packageId: projectIdentity.packageId || "",
      cardId: data?._id || "",
      commentId: id || "",
      body: {
        like,
      },
    };

    updateComment(params);
  }

  function pinToTopPermission() {
    if (!role) return false;
    if (role !== PROJECT_BOARD_ROLE.COLLABORATOR) return true;
    if (projectIdentity.userId === data?.createdBy) return true;
    return false;
  }

  function deletePermission(commentCreator: string) {
    if (!role) return false;
    if (role !== PROJECT_BOARD_ROLE.COLLABORATOR) return true;
    if (projectIdentity.userId === commentCreator) return true;
    return false;
  }

  return (
    <>
      <DescriptionWrap>
        <GlobalStyle />
        <Name>Description</Name>
        <Text>{data?.descText}</Text>
        <AttachWrap>
          {attachFiles.map((file, i) => (
            <AttachItem key={i}>
              <FileIcon />
              <Text>{file.fileName}</Text>
              <PopoverAntd
                placement="bottomLeft"
                title={null}
                content={
                  <>
                    <PopoverSelectItem
                      onClick={() => setPopoverAttachFileOptionsIndex(-1)}
                    >
                      <CrosshairIcon />
                      <a href={file.url}>Download</a>
                    </PopoverSelectItem>
                    <PopoverSelectItem
                      onClick={() => showDeleteAttachConfirm(file)}
                    >
                      <TrashIcon />
                      Delete attachment
                    </PopoverSelectItem>
                  </>
                }
                visible={popoverAttachFileOptionsIndex === i}
                trigger="click"
                overlayClassName={"popover-bottom-left"}
              >
                <span onClick={() => setPopoverAttachFileOptionsIndex(i)}>
                  <ArrowDownIcon />
                </span>
              </PopoverAntd>
            </AttachItem>
          ))}
        </AttachWrap>
        <ShowedCommentWrap>
          {commentList?.map((comment, iCom: number) => (
            <ShowedCommentItem key={iCom}>
              <a href={`/profile-preview?id=${comment?.sender}`}>
                {/* <img src={comment.avatar} alt="avatar" /> */}
                <AvatarAntd
                  src={comment?.avatar}
                  alt="avatar"
                  icon={<UserOutlined />}
                />
              </a>

              <div>
                <div>
                  <div>
                    <div>{comment.name}</div>
                    {comment.isAttach ? (
                      <ShowedCommentAttached>
                        <PaperClipIcon />
                        attached
                      </ShowedCommentAttached>
                    ) : (
                      <ShowedCommentTime>{comment.time}</ShowedCommentTime>
                    )}
                  </div>
                  <ShowedCommentContent
                    dangerouslySetInnerHTML={{ __html: comment.comment }}
                  />
                  {comment.attachFiles.map((file, iAtt: number) => (
                    <ImageAttach key={iAtt}>
                      {file.url?.match(/(.png|.jpg)$/gi) ? (
                        <ImageAntd.PreviewGroup>
                          <ImageAntd
                            // width="100%"
                            // height="73px"
                            // max-height="200px"
                            src={file.url}
                          />
                        </ImageAntd.PreviewGroup>
                      ) : (
                        <>
                          <img src={Pic1} alt="file preview" />
                          <div>
                            {file.fileName}
                            <a className="anchor" href={file.url}>
                              • Download
                            </a>
                          </div>
                        </>
                      )}
                    </ImageAttach>
                  ))}
                </div>
                <ShowedCommentActions>
                  <BadgeAntd
                    count={comment?.likedBy?.length}
                    size="small"
                    color={
                      comment?.likedBy?.indexOf(projectIdentity.userId || "") >
                      -1
                        ? "#7e00fd"
                        : "#FFF"
                    }
                  >
                    <span
                      onClick={() => likeComment(comment.id, comment.likedBy)}
                    >
                      <LikeIcon />
                    </span>
                  </BadgeAntd>
                  <PopoverAntd
                    placement="bottomRight"
                    title={null}
                    content={
                      <>
                        {pinToTopPermission() && (
                          <PopoverSelectItem
                            onClick={(e) => {
                              // e.stopPropagation();
                              pinToTop(comment.id);
                            }}
                          >
                            Pin to top
                          </PopoverSelectItem>
                        )}
                        {projectIdentity.userId === comment.sender && (
                          <PopoverSelectItem
                            onClick={(e) => {
                              // e.stopPropagation();
                              editComment(comment.id, iCom);
                            }}
                          >
                            Edit comment
                          </PopoverSelectItem>
                        )}
                        {/* <PopoverSelectItem
                          onClick={(e) => {
                            // e.stopPropagation();
                            copyCommentList(iCom);
                          }}
                        >
                          Copy comment link
                        </PopoverSelectItem> */}
                        {deletePermission(comment.sender) && (
                          <PopoverSelectItem
                            onClick={(e) => {
                              // e.stopPropagation();
                              showDeleteCommentConfirm(iCom);
                            }}
                          >
                            Delete comment
                          </PopoverSelectItem>
                        )}
                      </>
                    }
                    visible={popoverCommentOptionsIndex === iCom}
                    trigger="click"
                    overlayClassName={"popover-bottom-right"}
                  >
                    {!pinToTopPermission() &&
                    !deletePermission(comment.sender) &&
                    projectIdentity.userId !== comment.sender ? (
                      <WrapDropdownIcon />
                    ) : (
                      <WrapDropdownIcon
                        onClick={() => setPopoverCommentOptionsIndex(iCom)}
                      >
                        <ArrowDownIcon width={20} color={"#A5A7C4"} />
                      </WrapDropdownIcon>
                    )}
                  </PopoverAntd>
                </ShowedCommentActions>
              </div>
            </ShowedCommentItem>
          ))}
        </ShowedCommentWrap>
      </DescriptionWrap>
      <CommentWrap>
        <Name>Comment</Name>
        <CommentBoxWrap>
          <CommentTextarea>
            <textarea
              value={comment}
              onChange={(e) => {
                checkCommentChange(e.target.value);
              }}
              placeholder="Controlled autosize"
              ref={textAreaE}
            />
          </CommentTextarea>
          <CommentActionsWrap key={"key1"}>
            <div>
              {/* <label>
                <TextIcon />
              </label> */}
              <label onClick={() => setOpenEmojiSelection(true)}>
                <SmileyIcon />
                {openEmojiSelection && (
                  <EmojiSelectionWrap ref={emojiSelctionWrapE}>
                    <Picker onEmojiClick={onEmojiClick} />
                  </EmojiSelectionWrap>
                )}
              </label>
              <UploadWrap>
                <UploadAntd {...upLoadProps} listType="picture-card">
                  <GrayAttachIcon />
                </UploadAntd>
              </UploadWrap>
              <label onClick={onLinkClick}>
                <UrlLinkIcon />
              </label>
              <label onClick={() => setOpenTagSelection(true)}>
                <AtIcon />
                <TagSelectionWrap>
                  <TagsSelectionComponent
                    selectTag={() => {}}
                    visible={openTagSelection}
                    setOpenTagSelection={setOpenTagSelection}
                    tagsArray={tagsArray}
                  />
                </TagSelectionWrap>
                <TagSelectionBySearchWrap>
                  <TagsSelectionBySearchComponent
                    searchText={searchTagText}
                    selectTag={() => {}}
                    visible={true}
                    tagsArray={tagsArray}
                  />
                </TagSelectionBySearchWrap>
              </label>
            </div>
            <div>
              {isEditing ? (
                <>
                  <OutlinedButton
                    width="90px"
                    height="32px"
                    onClick={() => cancelEdit()}
                  >
                    Cancel
                  </OutlinedButton>
                  <PrimaryButton
                    width="90px"
                    height="32px"
                    onClick={saveComment}
                  >
                    Edit
                  </PrimaryButton>
                </>
              ) : (
                <>
                  <span>{tagsArray.length + " people will notifed"}</span>
                  <PrimaryButton
                    width="90px"
                    height="32px"
                    onClick={saveComment}
                  >
                    Comment
                  </PrimaryButton>
                </>
              )}
            </div>
          </CommentActionsWrap>
        </CommentBoxWrap>
        {children}
      </CommentWrap>
    </>
  );
};

export default CommentComponent;

const TagsSelectionComponent: FC<ITagsSelectionComponentProps> = ({
  selectTag,
  visible,
  setOpenTagSelection,
  tagsArray,
}) => {
  const [inSearchText, setInSearchText] = useState<string>("");
  const [validTags, setValidTags] = useState(tagsArray);

  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    setValidTags(tagsArray);
  }, [tagsArray]);

  useEffect(() => {
    setValidTags(filterTag(inSearchText));
  }, [inSearchText]);

  function filterTag(searchText: string) {
    const text = searchText?.trim().toLocaleLowerCase();
    if (!text) return tagsArray;
    return tagsArray.filter((tag: TagsArrayType) => {
      const length = text.length;
      if (tag.idTag.slice(0, length) === text) return true;
      return false;
    });
  }

  useEffect(() => {
    record.setIndex = setIndex;
    record.validTagsLength = validTags.length;
    record.tagSearchedindex = index;
    record.isDisableKey = visible;
    record.validTags = validTags;
    record.closeTagSelection = () => setOpenTagSelection(false);
  }, [index, visible]);

  useEffect(() => {
    if (visible) {
      const searchE = document.getElementById("searchTagInput");

      if (searchE) {
        // searchE.focus()
        searchE.addEventListener("keydown", (e) => {
          if (record.isDisableKey) {
            if (
              e.key === "ArrowDown" ||
              e.key === "ArrowUp" ||
              e.key === "Enter"
            ) {
              e.preventDefault();
              return false;
            }
          }
        });
      }
    } else {
      setInSearchText("");
      setIndex(0);
    }
  }, [visible]);

  return (
    <>
      {visible && (
        <TagSelectWrap>
          <div>
            Mention...
            <span
              onClick={(e) => {
                e.stopPropagation();
                setOpenTagSelection(false);
              }}
            >
              <CloseIcon callback={() => {}} />
            </span>
          </div>
          <div>
            <SearchInput
              placeholder="Search members..."
              value={inSearchText}
              onChange={(e) => setInSearchText(e.target.value)}
              id="searchTagInput"
              autoComplete="off"
            />
            <div className="max-height">
              {validTags.map((tag, i) => (
                <TagSelectItem
                  key={i}
                  className={index === i ? "selected" : ""}
                  onClick={(e) => {
                    e.stopPropagation();
                    record.appendText(
                      "@" +
                        record.validTags[record.tagSearchedindex].idTag +
                        " "
                    );
                    record.closeTagSelection();
                  }}
                  onMouseOver={() => setIndex(i)}
                >
                  <AvatarAntd src={tag.avatar} alt="avatar" />
                  <label>{tag.name + " (" + tag.idTag + ")"}</label>
                </TagSelectItem>
              ))}
            </div>
          </div>
        </TagSelectWrap>
      )}
    </>
  );
};

const TagsSelectionBySearchComponent: FC<
  ITagsSelectionBySearchComponentProps
> = ({ searchText, selectTag, visible, tagsArray }) => {
  const [validTags, setValidTags] = useState(tagsArray.slice(0, 0));
  const [openTagSelectionBySearch, setOpenTagSelectionBySearch] =
    useState<boolean>(true);

  const [index, setIndex] = useState<number>(0);
  const wrapE = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setValidTags(tagsArray.slice(0, 0));
  }, [tagsArray]);

  useEffect(() => {
    if (!searchText) {
      setOpenTagSelectionBySearch(false);
      return;
    }

    const result = filterTag(searchText);
    if (result.length) {
      setOpenTagSelectionBySearch(true);
      setValidTags(result);
    } else {
      setOpenTagSelectionBySearch(false);
    }
  }, [searchText]);

  useEffect(() => {
    record.setIndex = setIndex;
    record.validTagsLength = validTags.length;
    record.tagSearchedindex = index;
    record.isDisableKey = openTagSelectionBySearch;
    record.validTags = validTags;
    record.closeTagSelection = () => setOpenTagSelectionBySearch(false);
  }, [openTagSelectionBySearch, index]);

  useEffect(() => {
    setIndex(0);
  }, [openTagSelectionBySearch]);

  function filterTag(searchText: string) {
    searchText = searchText.toLocaleLowerCase();
    return tagsArray.filter((tag) => {
      const length = searchText.length;
      if (tag.idTag.slice(0, length) === searchText) return true;
      return false;
    });
  }
  const handleClickOutside = (event: Event) => {
    if (wrapE.current && !wrapE.current.contains(event.target as Node)) {
      setOpenTagSelectionBySearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      {openTagSelectionBySearch && (
        <TagSelectWrap ref={wrapE} onClick={(e) => e.stopPropagation()}>
          <div>
            Mention...
            <span>
              <CloseIcon callback={() => setOpenTagSelectionBySearch(false)} />
            </span>
          </div>
          <div>
            <div className="max-height">
              {validTags.map((tag, i) => (
                <TagSelectItem
                  key={i}
                  className={index === i ? "selected" : ""}
                  onClick={(e) => {
                    e.stopPropagation();
                    record.appendText(
                      "@" +
                        record.validTags[record.tagSearchedindex].idTag +
                        " "
                    );

                    record.closeTagSelection();
                  }}
                  onMouseOver={() => setIndex(i)}
                >
                  <img src={tag.avatar} alt="avatar" />
                  <span>{tag.name + " (" + tag.idTag + ")"}</span>
                </TagSelectItem>
              ))}
            </div>
          </div>
        </TagSelectWrap>
      )}
    </>
  );
};
