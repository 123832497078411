import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ILoading {
  pageLoading: boolean;
}

const initialState: ILoading = {
  pageLoading: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setPageLoading: (state, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
    },
  },
});

export const { setPageLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
