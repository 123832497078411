import { Rule } from "antd/lib/form";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const requiredRules: Rule = {
  required: true,
  message: "Can't be blank!",
};

export const requiredDateRules: Rule = {
  type: "object" as const,
  required: true,
  message: "Can't be blank!",
};

export const stringRequired: Rule = {
  whitespace: true,
  required: true,
  message: "Can't be blank!",
};

export const selectRequiredRules: Rule = {
  required: true,
  message: "Please select at least 1",
};

export const maxLength_30: Rule = {
  max: 30,
  message: "This is too long (maximum 30 characters)",
};

export const maxLength_40: Rule = {
  max: 40,
  message: "This is too long (maximum 40 characters)",
};

export const maxLength_50: Rule = {
  max: 50,
  message: "This is too long (maximum 50 characters)",
};

export const maxLength_60: Rule = {
  max: 60,
  message: "This is too long (maximum 60 characters)",
};

export const maxLength_80: Rule = {
  max: 80,
  message: "This is too long (maximum 80 characters)",
};

export const maxLength_128: Rule = {
  max: 128,
  message: "This is too long (maximum 128 characters)",
};

export const maxLength_140: Rule = {
  max: 140,
  message: "This is too long (maximum 140 characters)",
};

export const maxLength_180: Rule = {
  max: 180,
  message: "This is too long (maximum 180 characters)",
};

export const maxLength_200: Rule = {
  max: 200,
  message: "This is too long (maximum 200 characters)",
};
export const maxLength_255: Rule = {
  max: 255,
  message: "This is too long (maximum 255 characters)",
};

export const maxLength_1000: Rule = {
  max: 1000,
  message: "This is too long (maximum 1000 characters)",
};

export const maxLength_15: Rule = {
  max: 15,
  message: "Max length is 15 characters!",
};

export const maxLength_2000: Rule = {
  max: 1000,
  message: "This is too long (maximum 2000 characters)",
};

export const maxLength_1500: Rule = {
  max: 1000,
  message: "This is too long (maximum 1500 characters)",
};

export const numberTextAndUnderscore: Rule = {
  validator: (_: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }
    if (!/^[A-Za-z_][A-Za-z0-9_]*$/.test(value)) {
      return Promise.reject(
        new Error("Only letters, numbers, and underscores are allowed.")
      );
    }
    return Promise.resolve();
  },
};

export const numberAndText: Rule = {
  validator: (_: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }
    if (!/^[A-Za-z ][A-Za-z0-9 ]*$/.test(value)) {
      return Promise.reject(new Error("Only text and number"));
    }
    return Promise.resolve();
  },
};

export const maxLengthNumber_15: Rule = {
  validator: (_: any, value: number) => {
    if ((value + "").length > 15) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
};

export const divideBy2and3: Rule = {
  validator: (_: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }
    if (+value % 6 !== 0) {
      return Promise.reject(
        new Error("Observer Fees must be divided by 2 and 3")
      );
    }
    return Promise.resolve();
  },
};

export const urlRules: Rule = {
  type: "url",
  message: "Must be a valid URL!",
};
export const floatRules: Rule = {
  pattern: new RegExp(/^(\d+|\d+\.\d+)$/),
  message: "Must be a valid positive number!",
};
export const emailRules: Rule = {
  type: "email",
  message: "The input is not valid E-mail!",
};
export const numberRules: Rule = {
  pattern: new RegExp("^[1-9]\\d*$"),
  message: "Must be a positive integer number",
};
export const numberRulesWithZero: Rule = {
  pattern: new RegExp("^[0-9]\\d*$"),
  message: "Must be a positive integer number",
};

// formatter and parser input number
export const formatterNumber = (val: any) => {
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .replace(/\.(?=\d{0,2}$)/g, ",");
};

export const parserNumber = (val: any) => {
  if (!val) return "";
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, ".")
  ).toFixed(2);
};
export const formatByMonths = "MM/YYYY";

export const profileImageRequire: Rule = {
  required: true,
  message: "Profile Picture is required!",
};

export const coverImageRequire: Rule = {
  required: true,
  message: "Cover Picture is required!",
};

export const projectNameRequire: Rule = {
  required: true,
  message: "Project Name is required!",
};

export const taglineRequire: Rule = {
  required: true,
  message: "Tagline is required!",
};

export const categoryRequire: Rule = {
  required: true,
  message: "Category is required!",
};

export const websiteURLRequire: Rule = {
  required: true,
  message: "Website URL is required!",
};

export const mediumURLRequire: Rule = {
  required: true,
  message: "Website URL is required!",
};

export const githubURLRequire: Rule = {
  required: true,
  message: "Github URL is required!",
};

export const projectTypeRequire: Rule = {
  required: true,
  message: "Project Type is required!",
};

export const totalExplorerRequire: Rule = {
  required: true,
  message: "Token Explorer is required!",
};

export const totalProjectBudget: Rule = {
  validator: (_: any, value: number) => {
    const text = value + "";
    const decimal = text.split(".")[1];
    if (decimal && decimal.length > 6)
      return Promise.reject(new Error("Please rounds to 6 decimal places!"));
    return Promise.resolve();
  },
};

export const existingTokenRequire: Rule = {
  required: true,
  message: "Please select anyone!",
};

export const mediumUrlRules: Rule = {
  validator: (_: any, value: string) => {
    if (!value) return Promise.resolve();
    if (!/http(?:s)?:\/\/(?:www\.)?medium\.com\/([a-zA-Z0-9_]+)/.test(value)) {
      return Promise.reject(new Error("Must be a Medium URL"));
    }
    return Promise.resolve();
  },
};

export const twitterUrlRules: Rule = {
  validator: (_: any, value: string) => {
    if (!value) return Promise.resolve();
    if (!/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/.test(value)) {
      return Promise.reject(new Error("Must be a Twitter URL"));
    }
    return Promise.resolve();
  },
};

export const linkedinUrlRules: Rule = {
  validator: (_: any, value: string) => {
    if (!value) return Promise.resolve();
    if (
      !/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/.test(value)
    ) {
      return Promise.reject(new Error("Must be a LinkedIn URL"));
    }
    return Promise.resolve();
  },
};
