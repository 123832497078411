import { IBlog } from "api/blog";
import {
  BlogContent,
  BlogImage,
  BlogInfoSection,
  FirstBlog,
  PrimaryButtonStyled,
} from "./blog.style";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { ROUTER_CONSTANTS } from "utils/constant";
import Tags from "./tags";
import Skeleton from "react-loading-skeleton";
import { decodeEntities } from "utils/regex";
const { Paragraph } = Typography;

const FirstBlogCard = ({
  blog,
  loading,
  ...props
}: {
  blog: IBlog | undefined;
  loading: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <>
      {loading && (
        <FirstBlog>
          <div
            style={{
              lineHeight: 1,
            }}
          >
            <Skeleton className="skeleton-img" />
          </div>
          <BlogContent>
            <BlogInfoSection>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            </BlogInfoSection>
            <Paragraph ellipsis={{ rows: 2 }} style={{ marginTop: "14px" }}>
              <h3>
                <Skeleton />
              </h3>
            </Paragraph>
            <Paragraph ellipsis={{ rows: 8 }} className="paragraph">
              <Skeleton count={8} />
            </Paragraph>
          </BlogContent>
        </FirstBlog>
      )}
      {!loading && !!blog && (
        <FirstBlog
          onClick={() =>
            navigate(
              ROUTER_CONSTANTS.BLOG.BLOG_DETAIL.replace(":id", blog._id!)
            )
          }
        >
          <BlogImage className="first-blog--img" src={blog.coverImage} />
          <BlogContent style={{ width: "min-content" }}>
            <BlogInfoSection>
              <Tags blog={blog}></Tags>
              <div>{blog.estTime} min read</div>
            </BlogInfoSection>
            <Paragraph ellipsis={{ rows: 2 }} style={{ marginTop: "14px" }}>
              <h3>{blog.title}</h3>
            </Paragraph>
            <Paragraph
              ellipsis={{
                rows: 8,
                expandable: true,
                symbol: blog.description ? "more" : "",
              }}
              className="paragraph"
            >
              {decodeEntities(blog.description)}
            </Paragraph>
            <PrimaryButtonStyled>Read more</PrimaryButtonStyled>
          </BlogContent>
        </FirstBlog>
      )}
    </>
  );
};

export default FirstBlogCard;
