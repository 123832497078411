import { FC } from "react";
import { ProjectCardContainer } from "../projectCardContainer";
import { IProjectGroupProps } from "./projectGroup.props";
import {
  GroupContainer,
  GroupTitle,
  GroupHeader,
  GroupDescription,
  GroupWrapper,
  GroupRecent,
} from "./projectGroup.style";
// import { useNavigate } from "react-router-dom";
// import { useAppSelector } from "redux/hooks";
// import PrimaryOutlineBtn from "components/base/primaryOutlineBtn";

const ProjectGroup: FC<IProjectGroupProps> = ({
  data,
  projects,
  skipType,
  isFetching,
  haveChildren = false,
  totalProjectsLength,
}) => {
  // const navigate = useNavigate();
  // const { token } = useAppSelector((state) => {
  //   return state.auth;
  // });

  return (
    <GroupWrapper>
      <GroupContainer id={skipType ?? "recent-updates"}>
        {data?.extendBtn ? (
          <GroupHeader>
            <GroupTitle>{data?.title}</GroupTitle>
            <GroupDescription>{data?.description}</GroupDescription>
            {/* {data.btnKey === "BECOME_A_CONTRIBUTOR" ? (
              <>
                {!token ? (
                  <PrimaryOutlineBtn
                    height="56px"
                    width="max-content"
                    onClick={() => {
                      if (data.btnRedirect) navigate(data.btnRedirect);
                    }}
                  >
                    {data.extendBtn}
                  </PrimaryOutlineBtn>
                ) : null}
              </>
            ) : (
              <PrimaryOutlineBtn
                height="56px"
                width="max-content"
                onClick={() => {
                  if (data.btnRedirect) navigate(data.btnRedirect);
                }}
              >
                {data.extendBtn}
              </PrimaryOutlineBtn>
            )} */}
          </GroupHeader>
        ) : (
          <GroupRecent>
            <GroupTitle>{data?.title}</GroupTitle>
            <GroupDescription>{data?.description}</GroupDescription>
          </GroupRecent>
        )}

        <ProjectCardContainer
          btnName={data?.btn}
          projects={projects}
          skipType={skipType}
          isFetching={isFetching}
          itemsShow={3}
          totalProjectsLength={totalProjectsLength}
          haveChildren={haveChildren}
        />
      </GroupContainer>
    </GroupWrapper>
  );
};

export default ProjectGroup;
