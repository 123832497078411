import styled from "styled-components";

export const IntroductionContainer = styled.div<{ bg?: string }>`
  width: 100%;
  background: ${(p) =>
    p.bg ??
    `linear-gradient(
      180deg,
      rgb(255 255 255 / 6%) 0%,
      rgb(255 255 255 / 6%) 60%
    ),
    linear-gradient(
      90deg,
      rgb(126 0 253 / 6%) 0%,
      rgb(193 67 252 / 6%) 46.88%,
      rgb(0 191 251 / 6%) 100%
    )`};
  background-size: cover;
  padding: 0;
  padding-left: ${(p) => (p.bg ? "75px" : "0px")};
  & > div {
    /* max-width: 1170px; */
    margin: auto;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 48px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .ant-btn {
    border: 2px solid #7e00fd;
    border-radius: 4px;
    :hover {
      background: none;
    }
  }
`;

export const IntroductionContent = styled.div<{ mgr?: string }>`
  max-width: 73.125rem;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 5rem;
  @media (max-width: 1200px) {
    padding: 6rem 20px 0;
  }
  img {
    margin-right: ${(p) => (p.mgr ? p.mgr : "0px")};
    margin-bottom: ${(p) => (p.mgr ? "35px" : "0px")};
    height: fit-content;
  }
`;

export const SectionHeader = styled.div`
  width: 100%;
  margin-bottom: 5rem;
`;

export const SectionTitle = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 115%;
  letter-spacing: -0.02em;
  color: #080f6c;
`;

export const SectionDescription = styled(SectionTitle)`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  margin-top: 12px;
  width: 619px;
`;

export const SectionDescriptionStyled = styled.div`
  margin: 12px auto;
  width: 810px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 144%;
  text-align: center;
  color: #080f6c;
  span {
    color: rgba(126, 0, 253, 1);
  }
`;
