import { Tabs } from "antd";
import styled from "styled-components";

export const ResourcesWrap = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  padding: 0rem 3.125rem;
  overflow: hidden;
  #head-wrap {
    padding-top: 0px;
  }
`;

export const StyledTabs = styled(Tabs)`
  user-select: none;
  .ant-tabs-tab-btn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: rgba(41, 46, 115, 0.82);
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #080f6c;
  }
  .ant-tabs-ink-bar {
    background: #080f6c;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tab {
    padding: 17px 0;
  }
`;
