import axios from "api/axios";
import { END_POINT } from "./endpoints";
import {
  IAddEducationParams,
  IAddEmploymentHistoryParams,
  IDeleteEducationParams,
  IDeleteEmploymentHistoryParams,
  IGetDataMastersParams,
  // IFormDataProfilePage,
  IUpdateEducationParams,
  IUpdateEmploymentHistoryParams,
  ICreateProject,
  ICreateOrganizationParams,
  ICreateWorktaskParams,
  IRequestJoinWorktaskParams,
  ICreateFAQParams,
  IUpdateFAQParams,
  IDeleteFAQParams,
  IStartWorkParams,
  IUpdateJoinRequestParams,
  ISubmitDeliverableParams,
  IGetAllDeliverables,
  IApproveDeliveryRequestParams,
  IUpdateFinishStatusParams,
  IFinishPackageParams,
  IGetPackageCollaboratorsParams,
  ISubmitCollaboratorRatingParams,
  IUpdateStatusFinishProjectParams,
  IAddCardParams,
  IDragdropCardParams,
  ICardParams,
  IFinishProjectParams,
  IGetRevliewDeliverablesParams,
  IUpdateRejectReasonParams,
  IGetWorkTaskParams,
  IGetTransactionHistoryParams,
  IUpdateProject,
  // IUpdateProjectParams,
  IUpdateWorkTask,
  IUpdateCardParams,
  ICommentParams,
  IUpdateCommentParams,
  IAddSubtaskParams,
  IUpdateSubtaskParams,
  ICancelWorktaskParams,
  IGetNotificationParams,
  IUpdateNotification,
  IFilterData,
  ICreateTicketParams,
  IAddTicketCommentParams,
  IAddTransactionParams,
  IUploadsReq,
  IUpdateOrganizationParams,
  IFormDataProfileOtherPage,
  IMarkAsCompletedParams,
  IPortfolioParams,
  ICommonPackageParams,
  IParamsUpdateHidden,
  IParamsListPortfolios,
  ICommonProjectParams,
  IParamsTransactionHistoryProfile,
  IRemoveCollaboratorParams,
} from "./types";

/**===========================  checkUniqueOfUsername   ============================= */

export const checkUniqueOfUsernameAPI = async (text: String) => {
  return await axios({
    url: `${END_POINT.CHECK_UNIQUE_USERNAME}`,
    method: "POST",
    data: { text },
  });
};

/**===========================  Get Profile Info   ============================= */

export const getProfileInfoAPI = async () => {
  return await axios({
    url: `${END_POINT.GET_PROFILE}`,
    method: "GET",
  });
};

export const getProfileAPI = async (id: string) => {
  return await axios({
    url: `${END_POINT.GET_PROFILE_FROM_OTHER.replace(":id", id)}`,
    method: "GET",
  });
};

/**===========================  Education   ============================= */

export const getListPortfoliosAPI = async ({
  id,
  ...params
}: IParamsListPortfolios) => {
  return await axios({
    url: `${END_POINT.GET_LIST_PORTFOLIO}/${id}?search=${params.search}&skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

/**===========================  Education   ============================= */

export const addEducationAPI = async (params: IAddEducationParams) => {
  return await axios({
    url: `${END_POINT.ADD_EDUCATION}`,
    method: "POST",
    data: params.education,
  });
};

export const deleteEducationAPI = async (params: IDeleteEducationParams) => {
  return await axios({
    url: `${END_POINT.DELETE_EDUCATION.replace(
      ":educationId",
      params.educationId
    )}`,
    method: "DELETE",
  });
};

export const updateEducationAPI = async (params: IUpdateEducationParams) => {
  return await axios({
    url: `${END_POINT.UPDATE_EDUCATION.replace(
      ":educationId",
      params.educationId ? params.educationId : ""
    )}`,
    method: "PUT",
    data: params.education,
  });
};

/**========================  Employment History  ========================== */

export const addEmploymentHistoryAPI = async (
  params: IAddEmploymentHistoryParams
) => {
  return await axios({
    url: `${END_POINT.ADD_EMPLOYMENT_HISTORY}`,
    method: "POST",
    data: params.employmentHistory,
  });
};

export const deleteEmploymentHistoryAPI = async (
  params: IDeleteEmploymentHistoryParams
) => {
  return await axios({
    url: `${END_POINT.DELETE_EMPLOYMENT_HISTORY.replace(
      ":employmentHistoryId",
      params.employmentHistoryId
    )}`,
    method: "DELETE",
  });
};

export const updateEmploymentHistoryAPI = async (
  params: IUpdateEmploymentHistoryParams
) => {
  return await axios({
    url: `${END_POINT.UPDATE_EMPLOYMENT_HISTORY.replace(
      ":employmentHistoryId",
      params.employmentHistoryId ? params.employmentHistoryId : ""
    )}`,
    method: "PUT",
    data: params.employmentHistory,
  });
};

/**========================  Builder Profile / Job Preferences  ========================== */

export const updateAnotherFieldAPI = async (
  body: IFormDataProfileOtherPage
) => {
  return await axios.put(END_POINT.UPDATE_ANOTHER_FIELD_PROFILE, body);
  // return await axios({
  //   url: `${END_POINT.UPDATE_ANOTHER_FIELD_PROFILE.replace(
  //     ":id",
  //     params.id || ""
  //   )}`,
  //   method: "PUT",
  //   data: {
  //     ...params.builderProfile,
  //     jobPreference: params.jobPreference,
  //     skills: params.builderSkill,
  //     ...params.socialLink,
  //   },
  // });
};

export const getTransactionHistoryProfileAPI = async (
  params: IParamsTransactionHistoryProfile
) => {
  return await axios({
    url: `${END_POINT.GET_TRANSACTION_HISTORY_PROFILE}?userId=${params.userId}&skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const updateHiddenTransactionAPI = async ({
  txId,
  ...params
}: IParamsUpdateHidden) => {
  return await axios({
    url: `${END_POINT.UPDATE_HIDDEN_TRANSACTION.replace(":txId", txId)}`,
    method: "PUT",
    data: params,
  });
};

/**========================  Skills  ========================== */

export const getSkillsByIdAPI = async (id: string) => {
  return await axios({
    url: `${END_POINT.GET_SKILLS}${id}`,
    method: "GET",
  });
};

export const getDataMastersAPI = async (params: IGetDataMastersParams) => {
  const qs =
    "?" +
    Object.keys(params)
      .map(
        (key: string) =>
          `${key}=${encodeURIComponent(
            params[key as keyof IGetDataMastersParams]
          )}`
      )
      .join("&");
  return await axios({
    url: `${END_POINT.GET_DATA_MASTER + qs}`,
    method: "GET",
  });
};

export const getProcessUpdateProfileAPI = async (id: string) => {
  return await axios({
    url: `${END_POINT.GET_PROCESS_UPDATE_PROFILE}`,
    method: "GET",
  });
};

export const getAllProjectsByOrganization = async (id: string) => {
  return await axios.get(
    END_POINT.GET_PROJECT_BY_ORGANIZATION.replace(":orgId", id)
  );
};

/**========================  Create Project  ========================== */

export const createProjectAPI = async ({ ...params }: ICreateProject) => {
  return await axios({
    url: `${END_POINT.CREATE_PROJECT}`,
    method: "POST",
    data: params,
  });
};

/**===========================  Update Project  ============================= */

export const updateProjectAPI = async ({
  projectId,
  ...params
}: IUpdateProject) => {
  return await axios({
    url: `${END_POINT.UPDATE_PROJECT.replace(":projectId", projectId)}`,
    method: "PUT",
    data: params,
  });
};

/**===========================  Organisation   ============================= */

export const getOrganizationInfoAPI = async (organizationId: string) => {
  return await axios({
    url: `${END_POINT.GET_ORGANIZATION_INFO.replace(
      ":organizationId",
      organizationId
    )}`,
    method: "GET",
  });
};

export const getOrganizationsAPI = async () => {
  return await axios({
    url: `${END_POINT.GET_ORGANIZATIONS}`,
    method: "GET",
  });
};

export const createOrganisationAPI = async (
  params: ICreateOrganizationParams
) => {
  return await axios({
    url: `${END_POINT.CREATE_ORGANIZATION}`,
    method: "POST",
    data: params.organization,
  });
};

export const updateOrganizationAPI = async ({
  organizationId,
  organization,
}: IUpdateOrganizationParams) => {
  return await axios({
    url: `${END_POINT.UPDATE_ORGANIZATIONS.replace(
      ":organizationId",
      organizationId
    )}`,
    method: "PUT",
    data: organization,
  });
};

/**===========================  Organisation End  ============================= */

export const toggleFollowAPI = async (organizationId: string) => {
  return await axios({
    url: `${END_POINT.TOGGLE_FOLLOW.replace(
      ":organizationId",
      organizationId
    )}`,
    method: "GET",
  });
};

/**===========================  Add a New Worktask   ============================= */

export const getCreateWorktaskMasters = async () => {
  return await axios({
    url: `${END_POINT.GET_CREATEWORKTASKMASTERS}`,
    method: "GET",
  });
};

export const createWorktaskAPI = async ({
  projectId,
  ...params
}: ICreateWorktaskParams) => {
  // console.log("createWorktaskAPI", params);

  return await axios({
    url: `${END_POINT.CREATE_WORKTASK.replace(":projectId", projectId || "")}`,
    method: "POST",
    data: params,
  });
};

export const getListUserWillBecomeObserverAPI = async (
  projectId: string,
  packageId: string
) => {
  return await axios({
    url: `${END_POINT.GET_LIST_USER_WILL_BECOME_OBSERVER.replace(
      ":projectId/:packageId",
      projectId + "/" + packageId
    )}`,
    method: "GET",
  });
};

/**===========================  Update a Worktask   ============================= */

export const updateWorkTaskAPI = async ({
  projectId,
  workTaskId,
  ...params
}: IUpdateWorkTask) => {
  return await axios({
    url: `${END_POINT.UPDATE_WORK_TASK.replace(
      ":projectId/:packageId",
      projectId + "/" + workTaskId
    )}`,
    method: "PUT",
    data: params,
  });
};

/**===========================  Project detail   ============================= */

export const getAllPackages = async (projectId: string) => {
  return await axios({
    url: `${END_POINT.GET_ALLPACKAGES.replace(":projectId", projectId)}`,
    method: "GET",
  });
};

export const getAllPackagesOrganization = async (orgId: string) => {
  return await axios({
    url: `${END_POINT.GET_ALLPACKAGES_ORG.replace(":orgId", orgId)}`,
    method: "GET",
  });
};

export const getProjectDetails = async (projectId: string) => {
  return await axios({
    url: `${END_POINT.GET_PROJECTDETAILS.replace(":projectId", projectId)}`,
    method: "GET",
  });
};

/**===========================  Project detail   ============================= */

export const requestJoinWorktaskAPI = async (
  params: IRequestJoinWorktaskParams
) => {
  return await axios({
    url: `${END_POINT.REQUEST_JOIN_PACKAGE.replace(
      ":projectId/:packageId",
      params.projectId + "/" + params.packageId
    )}`,
    method: "POST",
    data: params.body,
  });
};

/**===========================  Project detail FAQ   ============================= */

export const createFAQAPI = async (params: ICreateFAQParams) => {
  return await axios({
    url: `${END_POINT.CREATE_FAQ.replace(":projectId", params.projectId)}`,
    method: "POST",
    data: params.body,
  });
};

export const updateFAQAPI = async (params: IUpdateFAQParams) => {
  return await axios({
    url: `${END_POINT.UPDATE_FAQ.replace(
      ":projectId/:FAQId",
      params.projectId + "/" + params.faqId
    )}`,
    method: "PUT",
    data: params.body,
  });
};

export const getFAQsAPI = async (projectId: string) => {
  return await axios({
    url: `${END_POINT.GET_FAQ.replace(":projectId", projectId)}`,
    method: "GET",
  });
};

export const exploreProjectAPI = async (
  category: string,
  id: string,
  search: string,
  skip: number,
  data: IFilterData | undefined
) => {
  return await axios({
    url: `${
      END_POINT.EXPLORE_PROJECTS
    }?skip=${skip}&limit=3&column=&dir=&search=${search}${
      id ? `&id=${id}` : ""
    }`,
    method: "POST",
    data: {
      category,
      ...data,
    },
  });
};

export const getMyInitiatedProjectsAPI = async (
  search: string,
  skip: number,
  data: IFilterData | undefined
) => {
  return await axios({
    url: `${END_POINT.GET_INITIATED_PROJECTS}?skip=${skip}&limit=3&column=&dir=&search=${search}`,
    method: "POST",
    data: data,
  });
};

export const getInitiatorDashboardStatsAPI = async (
  id: string
  // search: string,
  // skip: number,
  // data: IFilterData | undefined
) => {
  return await axios({
    url: `${END_POINT.GET_INITIATOR_DASHBOARD_STATS.replace(
      ":id",
      id
    )}?skip=${0}&limit=300&column=&dir=&search=`,
    method: "POST",
    data: {},
  });
};

export const getUserRequestReceivedAPI = async (
  projectId: string,
  packageId: string,
  status: string
) => {
  return await axios({
    url: `${END_POINT.GET_USER_REQUEST_RECEIVED.replace(
      ":projectId",
      projectId
    ).replace(":packageId", packageId)}?status=${status}`,
    method: "GET",
  });
};

export const deleteFAQAPI = async (params: IDeleteFAQParams) => {
  return await axios({
    url: `${END_POINT.DELETE_FAQ.replace(
      ":projectId/:FAQId",
      params.projectId + "/" + params.faqId
    )}`,
    method: "DELETE",
  });
};

export const updateJoinRequestAPI = async (
  params: IUpdateJoinRequestParams
) => {
  return await axios({
    url: `${END_POINT.UPDATE_JOIN_REQUEST.replace(":userId", params.userId)
      .replace(":projectId", params.projectId)
      .replace(":packageId", params.packageId)
      .replace(":verificationStatus", params.verificationStatus)}`,
    method: "PATCH",
  });
};

export const removeCollab = async ({
  projectId,
  packageId,
  collabId,
  txHash,
}: IRemoveCollaboratorParams) => {
  return await axios({
    url: `${END_POINT.REMOVE_COLLAB}/${projectId}/${packageId}/${collabId}`,
    method: "DELETE",
    data: { txHash },
  });
};

export const getPackageDetailsAPI = async (
  projectId: string,
  packageId: string
) => {
  return await axios({
    url: `${END_POINT.GET_PACKAGE_DETAILS.replace(
      ":projectId",
      projectId
    ).replace(":packageId", packageId)}`,
    method: "GET",
  });
};

export const startWorkAPI = async (params: IStartWorkParams) => {
  return await axios({
    url: `${END_POINT.START_WORK.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}`,
    method: "PATCH",
  });
};

export const getTaskListAPI = async (projectId: string) => {
  return await axios({
    url: `${END_POINT.GET_TASK_LIST.replace(":projectId", projectId)}`,
    method: "GET",
  });
};

export const submitDeliverableAPI = async ({
  projectId,
  packageId,
  params,
}: ISubmitDeliverableParams) => {
  return await axios({
    url: `${END_POINT.SUBMIT_DELIVERABLE.replace(
      ":projectId",
      projectId
    ).replace(":packageId", packageId)}`,
    method: "POST",
    data: { ...params },
  });
};

export const getAllDeliverablesAPI = async ({
  packageId,
  type,
}: IGetAllDeliverables) => {
  // console.log("type", type);
  return await axios({
    url: `${
      END_POINT.GET_ALL_DELIVERABLES
    }?packageId=${packageId}&skip=0&limit=5&view=${
      type === "collaborator" ? "collaborator" : ""
    }`,
    method: "GET",
  });
};

export const approveDeliveryRequestAPI = async (
  params: IApproveDeliveryRequestParams
) => {
  return await axios({
    url: `${END_POINT.APPROVE_DELIVERY_REQUEST.replace(":userId", params.userId)
      .replace(":projectId", params.projectId)
      .replace(":packageId", params.packageId)
      .replace(":deliveryId", params.deliveryId)}`,
    method: "GET",
  });
};

/**===========================  Chat  ============================= */

export const getChatListAPI = async ({ id, search, skip, limit }: any) => {
  return await axios({
    url: `${END_POINT.GET_CHATLIST}?search=${search}&skip=${skip}&limit=${limit}`,
    method: "GET",
  });
};

export const getChatGroupAPI = async ({
  id,
  search,
  skip,
  limit,
  packageId,
}: any) => {
  return await axios({
    url: `${END_POINT.GET_CHATGROUP.replace(
      ":packageId",
      packageId
    )}?search=${search}&skip=${skip}&limit=${limit}`,
    method: "GET",
  });
};

export const updateFinishStatusAPI = async (
  params: IUpdateFinishStatusParams
) => {
  return await axios({
    url: `${END_POINT.UPDATE_FINISH_STATUS.replace(
      ":projectId",
      params.projectId
    )
      .replace(":packageId", params.packageId)
      .replace(":status", params.status)}`,
    method: "GET",
  });
};

export const getPackageBonusScoresAPI = async (
  params: ICommonPackageParams
) => {
  return await axios({
    url: `${END_POINT.GET_PACKAGE_BONUS_SCORES.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}`,
    method: "GET",
  });
};

export const finishPackageAPI = async (params: IFinishPackageParams) => {
  return await axios({
    url: `${END_POINT.FINISH_PACKAGE.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}`,
    method: "POST",
    data: {
      txHash: params.txHash,
      budgetLeft: params.budgetLeft,
      paidCollaboratorEvents: params.paidCollaboratorEvents,
      paidObserverEvents: params.paidObserverEvents,
    },
  });
};

export const getPackageCollaboratorsAPI = async (
  params: IGetPackageCollaboratorsParams
) => {
  return await axios({
    url: `${END_POINT.GET_PACKAGE_COLLABORATORS.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}${params.collabId}`,
    method: "GET",
  });
};

export const submitCollaboratorRatingAPI = async ({
  projectId,
  packageId,
  params,
}: ISubmitCollaboratorRatingParams) => {
  return await axios({
    url: `${END_POINT.SUBMIT_COLLABORATOR_RATING.replace(
      ":projectId",
      projectId
    ).replace(":packageId", packageId)}`,
    method: "POST",
    data: params,
  });
};

export const submitCollaboratorReviewAPI = async ({
  projectId,
  packageId,
  params,
}: ISubmitCollaboratorRatingParams) => {
  return await axios({
    url: `${END_POINT.SUBMIT_COLLABORATOR_REVIEW.replace(
      ":projectId",
      projectId
    ).replace(":packageId", packageId)}`,
    method: "POST",
    data: params,
  });
};

export const getFeedBackInfoAPI = async (params: ICommonPackageParams) => {
  return await axios({
    url: `${END_POINT.GET_FEEDBACK_INFO_COLLABORATOR.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}`,
    method: "GET",
  });
};

export const updateFinishStatusProjectAPI = async (
  params: IUpdateStatusFinishProjectParams
) => {
  return await axios({
    url: `${END_POINT.UPDATE_FINISH_STATUS_PROJECT.replace(
      ":projectId",
      params.projectId
    ).replace(":status", params.status)}`,
    method: "GET",
  });
};

export const finishProjectAPI = async (params: IFinishProjectParams) => {
  return await axios({
    url: `${END_POINT.FINISH_PROJECT.replace(":projectId", params.projectId)}`,
    method: "POST",
    data: { budgetReturned: params.budgetReturned, txHash: params.txHash },
  });
};

export const deleteProjectAPI = async (params: IFinishProjectParams) => {
  return await axios({
    url: `${END_POINT.DELETE_PROJECT.replace(":projectId", params.projectId)}`,
    method: "POST",
    data: { budgetReturned: params.budgetReturned, txHash: params.txHash },
  });
};

/**========================  Get Master  ========================== */

// export const getMasterByTypeAPI = async (type: string) => {
//   return await axios({
//     url: `${END_POINT.GET_MASTERBYTYPE}?type=${type}`,
//     method: "GET",
//   });
// };

export const getAllMastersAPI = async () => {
  return await axios({
    url: END_POINT.GET_ALLMASTERS,
    method: "GET",
  });
};

/**========================  Project Board  ========================== */

export const addCardAPI = async ({
  projectId,
  packageId,
  body,
}: IAddCardParams) => {
  return await axios({
    url: `${END_POINT.ADD_CARD.replace(":projectId", projectId).replace(
      ":packageId",
      packageId
    )}`,
    method: "POST",
    data: body,
  });
};

export const getProjectBoardDetailsAPI = async (
  projectId: string,
  packageId: string
) => {
  return await axios({
    url: `${END_POINT.GET_PROJECT_BOARD_DETAILS.replace(
      ":projectId",
      projectId
    ).replace(":packageId", packageId)}`,
    method: "GET",
  });
};

export const dragdropCardAPI = async ({
  projectId,
  packageId,
  cardId,
  body,
}: IDragdropCardParams) => {
  return await axios({
    url: `${END_POINT.DRAG_DROP_CARD.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)}`,
    method: "PUT",
    data: body,
  });
};

export const deleteCardAPI = async ({
  projectId,
  packageId,
  cardId,
}: ICardParams) => {
  return await axios({
    url: `${END_POINT.DELETE_CARD.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)}`,
    method: "DELETE",
  });
};

export const cardMarkAsCompleteAPI = async ({
  projectId,
  packageId,
  cardId,
  body,
}: IMarkAsCompletedParams) => {
  return await axios({
    url: `${END_POINT.CARD_MARK_AS_COMPLETE.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)}`,
    method: "PUT",
    data: body,
  });
};

export const updateCardAPI = async ({
  projectId,
  packageId,
  cardId,
  body,
}: IUpdateCardParams) => {
  return await axios({
    url: `${END_POINT.UPDATE_CARD.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)}`,
    method: "PUT",
    data: body,
  });
};

export const getCardDetailsAPI = async ({
  projectId,
  packageId,
  cardId,
}: ICardParams) => {
  return await axios({
    url: `${END_POINT.GET_CARD_DETAILS.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)}`,
    method: "GET",
  });
};

export const deleteCommentAPI = async ({
  projectId,
  packageId,
  cardId,
  commentId,
}: ICommentParams) => {
  return await axios({
    url: `${END_POINT.DELETE_COMMENT.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)
      .replace(":commentId", commentId)}`,
    method: "DELETE",
  });
};

export const updateCommentAPI = async ({
  projectId,
  packageId,
  cardId,
  commentId,
  body,
}: IUpdateCommentParams) => {
  return await axios({
    url: `${END_POINT.UPDATE_COMMENT.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)
      .replace(":commentId", commentId)}`,
    method: "PUT",
    data: body,
  });
};

export const addSubtaskAPI = async ({
  projectId,
  packageId,
  cardId,
  body,
}: IAddSubtaskParams) => {
  return await axios({
    url: `${END_POINT.ADD_SUBTASK.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)}`,
    method: "POST",
    data: body,
  });
};

export const editSubTaskAPI = async ({
  projectId,
  packageId,
  cardId,
  subTaskId,
  body,
}: IUpdateSubtaskParams) => {
  return await axios({
    url: `${END_POINT.UPDATE_SUBTASK.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)
      .replace(":subTaskId", subTaskId)}`,
    method: "PUT",
    data: body,
  });
};

export const deleteCardSubtaskAPI = async ({
  projectId,
  packageId,
  cardId,
  subTaskId,
}: IUpdateSubtaskParams) => {
  return await axios({
    url: `${END_POINT.DELETE_SUBTASK.replace(":projectId", projectId)
      .replace(":packageId", packageId)
      .replace(":cardId", cardId)
      .replace(":subTaskId", subTaskId)}`,
    method: "DELETE",
  });
};

/**========================  Project Board End ========================== */

export const updateWalletAddressAPI = async (params: {
  walletAddress: string;
}) => {
  return await axios({
    url: `${END_POINT.UPDATE_WALLET}`,
    method: "PUT",
    data: {
      walletAddress: params.walletAddress,
    },
  });
};

export const getReviewDeliverablesAPI = async (
  params: IGetRevliewDeliverablesParams
) => {
  return await axios({
    url: `${END_POINT.GET_REVIEW_DELIVERABLES.replace(
      ":projectId",
      params.projectId
    )
      .replace(":packageId", params.packageId)
      .replace(":userId", params.userId)}`,
    method: "GET",
  });
};

export const updateRejectReasonAPI = async (
  params: IUpdateRejectReasonParams
) => {
  return await axios({
    url: `${END_POINT.UPDATE_REJECT_REASON.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}`,
    method: "POST",
    data: params,
  });
};

export const getAllUsersAPI = async () => {
  return await axios({
    url: `${END_POINT.GET_ALL_USERS}?search=`,
    method: "GET",
  });
};

/**===========================  Dashboard work task Initiator  ============================= */

export const getAllOpenWorkTasks = async (params: IGetWorkTaskParams) => {
  return await axios({
    url: `${END_POINT.GET_ALL_OPEN_WORK_TASKS}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getAllCompletedWorkTasks = async (params: IGetWorkTaskParams) => {
  return await axios({
    url: `${END_POINT.GET_ALL_COMPLETED_WORK_TASKS}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getAllInprogressProjectsInitiatorAPI = async (
  params: IGetWorkTaskParams
) => {
  return await axios({
    url: `${END_POINT.GET_ALL_INPROGRESS_WORK_TASKS_INITIATOR}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getAllDeliveredWorkTasks = async (params: IGetWorkTaskParams) => {
  return await axios({
    url: `${END_POINT.GET_ALL_DELIVERED_WORK_TASKS}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getAllWorkTaskRequestReceived = async (
  params: IGetWorkTaskParams
) => {
  return await axios({
    url: `${END_POINT.GET_ALL_WORK_TASKS_REQUEST_RECEIVED}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

/**===========================  Dashboard work task Collaborator  ============================= */

export const getAllOpenWorkTasksCollaborator = async (
  params: IGetWorkTaskParams
) => {
  return await axios({
    url: `${END_POINT.GET_ALL_OPEN_WORK_TASKS_COLLABORATOR}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getAllWaitingApprovalProjectsColAPI = async (
  params: IGetWorkTaskParams
) => {
  return await axios({
    url: `${END_POINT.GET_ALL_WAITING_APPROVAL_WORK_TASKS_COLLABORATOR}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getAllInProgressWorkTasksCollaborator = async (
  params: IGetWorkTaskParams
) => {
  return await axios({
    url: `${END_POINT.GET_ALL_IN_PROGRESS_WORK_TASKS_COLLABORATOR}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getAllSubmittedWorkTasksCollaborator = async (
  params: IGetWorkTaskParams
) => {
  return await axios({
    url: `${END_POINT.GET_ALL_SUBMITTED_WORK_TASKS_COLLABORATOR}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getAllCompletedWorkTasksCollaborator = async (
  params: IGetWorkTaskParams
) => {
  return await axios({
    url: `${END_POINT.GET_ALL_COMPLETED_WORK_TASKS_COLLABORATOR}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

/**===========================  Transaction History API  ============================= */

export const getTransactionHistoryAPI = async (
  params: IGetTransactionHistoryParams
) => {
  return await axios({
    url: `${END_POINT.GET_TRANSACTION_HISTORY}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getTransactionHistoryObserverAPI = async (
  params: IGetTransactionHistoryParams
) => {
  return await axios({
    url: `${END_POINT.GET_TRANSACTION_HISTORY_OBSERVERS.replace(
      ":id",
      params.id
    )}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const getCollaboratorListAPI = async (params: ICommonProjectParams) => {
  return await axios({
    url: `${END_POINT.GET_COLLABORATOR_LIST.replace(
      ":projectId",
      params.projectId
    )}?skip=0&limit=5`,
    method: "GET",
  });
};

export const getCoWorkersAPI = async (
  params: IGetPackageCollaboratorsParams
) => {
  return await axios({
    url: `${END_POINT.GET_COWORKERS_API.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}${params.collabId}`,
    method: "GET",
  });
};

export const getEarningListAPI = async () => {
  return await axios({
    url: `${END_POINT.GET_EARNING_LIST}`,
    method: "GET",
  });
};

export const getCurrentWorktaskAPI = async () => {
  return await axios({
    url: `${END_POINT.GET_CURRENT_WORKTASK}?skip=0&limit=10`,
    method: "GET",
  });
};

export const getCurrentWorktaskInitiatorAPI = async () => {
  return await axios({
    url: `${END_POINT.GET_CURRENT_WORKTASK_INITIATOR}?skip=0&limit=10`,
    method: "GET",
  });
};
export const getDeliveredWorktaskInitiatorAPI = async (id: string) => {
  return await axios({
    url: `${END_POINT.GET_DELIVERED_WORKTASK_INITIATOR.replace(
      ":id",
      id
    )}?skip=0&limit=10`,
    method: "GET",
  });
};

export const cancelPackageAPI = async (params: ICancelWorktaskParams) => {
  return await axios({
    url: `${END_POINT.CANCEL_PACKAGE.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}`,
    method: "POST",
    data: {
      txHash: params.txHash,
      paidCollaboratorEvents: params.paidCollaboratorEvents,
      paidObserverEvents: params.paidObserverEvents,
    },
  });
};

/**===========================  Notifications   ============================= */

export const getAllNotifications = async (params: IGetNotificationParams) => {
  return await axios({
    url: `${END_POINT.GET_NOTIFICATIONS}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });
};

export const updateNotificationStatus = async (params: IUpdateNotification) => {
  return await axios({
    url: `${END_POINT.UPDATE_NOTIFICATION}`,
    data: params,
    method: "PUT",
  });
};

/**===========================  support ticket   ============================= */

export const getMyProjectListAPI = async (id: string) => {
  return await axios({
    url: `${END_POINT.GET_MY_PROJECTS}`,
    method: "GET",
  });
};

export const getPackagesByProjectIdAPI = async (projectId: string) => {
  return await axios({
    url: `${END_POINT.GET_BACKAGE_BY_PROJECTID.replace(
      ":projectId",
      projectId
    )}`,
    method: "GET",
  });
};

export const getCollaboratorsByPackageIdAPI = async (
  projectId: string,
  packageId: string
) => {
  return await axios({
    url: `${END_POINT.GET_COLLABORATORS_BY_PACKAGEID.replace(
      ":projectId",
      projectId
    ).replace(":packageId", packageId)}`,
    method: "GET",
  });
};

export const createSupportTicketAPI = async ({ data }: ICreateTicketParams) => {
  return await axios({
    url: `${END_POINT.CREATE_SUPPORT_TICKET}`,
    data: data,
    method: "POST",
  });
};

export const addCommentAPI = async ({
  ticketId,
  data,
}: IAddTicketCommentParams) => {
  return await axios({
    url: `${END_POINT.ADD_SUPPORT_TICKET_COMMENT.replace(
      ":ticketId",
      ticketId
    )}`,
    data: data,
    method: "POST",
  });
};

export const getAllSupportTicketAPI = async (skip: number, limit: number) => {
  return await axios({
    url: `${END_POINT.GET_ALL_SUPPORT_TICKETS}?skip=${skip}&limit=${limit}`,
    method: "GET",
  });
};

/**===========================  support ticket end   ============================= */
export const updateObserverAPI = async (params: {
  projectId: string;
  packageId: string;
  observers: { role: string; id: string }[];
}) => {
  return await axios({
    url: `${END_POINT.UPDATE_OBSERVER.replace(":projectId", params.projectId)}`,
    data: {
      packageId: params.packageId,
      observers: params.observers,
    },
    method: "PUT",
  });
};

export const addObserversAPI = async (params: any) => {
  return await axios.post(
    `${END_POINT.ADD_OBSERVER.replace(":projectId", params.projectId)}`,
    {
      users: params.users,
      // role: params.role,
      packageId: params.packageId,
    }
  );
  // return await axios({
  //   url: `${END_POINT.ADD_OBSERVER.replace(":projectId", params.projectId)}`,
  //   data: {
  //     users: params.users,
  //     // role: params.role,
  //     packages: params.packages,
  //   },
  //   method: "POST",
  // });
};

export const getObserverListAPI = async (params: any) => {
  return await axios({
    url: `${END_POINT.GET_OBSERVER_LIST.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}?skip=0&limit=10`,
    method: "GET",
  });
};

export const removeObserverAPI = async (params: any) => {
  return await axios.delete(
    `${END_POINT.REMOVE_OBSERVER.replace(
      ":projectId",
      params.projectId
    ).replace(":packageId", params.packageId)}`,
    {
      data: {
        userIds: params.userIds,
      },
    }
  );
  // return await axios({
  //   url: `${END_POINT.REMOVE_OBSERVER.replace(
  //     ":projectId",
  //     params.projectId
  //   ).replace(":userId", params.userId)}`,
  //   method: "DELETE",
  // });
};

export const getAllTransactionAPI = async () => {
  return await axios({
    url: `${END_POINT.GET_TRANSACTIONS}`,
    method: "GET",
  });
};

export const getProjectHistoryAPI = async (id: any) => {
  return await axios({
    url: `${END_POINT.GET_PROJECT_HISTORY}/${id}`,
    method: "GET",
  });
};

export const addTransactionAPI = async (params: IAddTransactionParams) => {
  return await axios({
    url: `${END_POINT.ADD_TRANSACTION}`,
    method: "POST",
    data: { ...params },
  });
};

export const startProjectAPI = async (params: any) => {
  return await axios({
    url: `${END_POINT.START_PROJECT.replace(":projectId", params.projectId)}`,
    method: "POST",
    data: { addressContract: params.walletContract },
  });
};

export const inviteFriendAPI = async (params: { userEmails: string }) => {
  return await axios.post(END_POINT.INVITE, {
    userEmails: [params.userEmails],
  });
  // return await axios({
  //   url: `${END_POINT.INVITE}`,
  //   method: "POST",
  //   data: { userEmails: [params.userEmails] },
  // });
};

export const getReferralListAPI = async () =>
  await axios({
    url: `${END_POINT.REFERRAL}`,
    method: "GET",
  });

export const getMasterByTypeAPI = async (type: string) => {
  return await axios({
    url: `${END_POINT.GETMASTERBYTYPE + type}`,
    method: "GET",
  });
};

/**===========================  Upload single   ============================= */

export const postUploadsAPI = async (params: IUploadsReq) => {
  const data = await axios({
    url: END_POINT.UPLOADS,
    method: "POST",
    data: params,
  });

  return data;
};

/**===========================  Upload single end  ============================= */

/**===========================  Add Portfolio  ============================= */

export const addPortfolioAPI = async (params: IPortfolioParams) => {
  const data = await axios({
    url: END_POINT.ADD_PORTFOLIO,
    method: "POST",
    data: params,
  });
  return data;
};

export const editPortfolioAPI = async (params: IPortfolioParams) => {
  const data = await axios.put(
    `${END_POINT.EDIT_PORTFOLIO}/${params?._id}`,
    params
  );
  return data;
};

export const deletePortfolioAPI = async (idToDelete: string) => {
  const data = await axios.delete(
    `${END_POINT.DELETE_PORTFOLIO}/${idToDelete}`
  );
  return data;
};

/**===========================  Add Portfolio end  ============================= */

/**===========================  Payment Dashboard  ============================= */

export const paymentDashboardCollaboratorObserver = async (params: any) => {
  const data = await axios({
    url: `${END_POINT.PAYMENT_DASHBOARD_COLLABORATOR_OBSERVER}?role=${params.role}&skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });

  return data;
};

export const getTransactionsPaymentByTokenNameAPI = async (params: any) => {
  const data = await axios({
    url: `${END_POINT.PAYMENT_DASHBOARD_BY_TOKEN_COLLABORATOR_OBSERVER}?role=${params.role}&skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });

  return data;
};

export const getTransactionsInitiatorByTokenNameAPI = async (params: any) => {
  const data = await axios({
    url: `${END_POINT.PAYMENT_DASHBOARD_BY_TOKEN_INITIATOR}?skip=${params.skip}&limit=${params.limit}`,
    method: "GET",
  });

  return data;
};

export const getRecentUpdates = async () => {
  const data = await axios({
    url: `${END_POINT.GET_RECENT_UPDATES}`,
    method: "GET",
  });
  return data;
};
