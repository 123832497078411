import styled, { css } from "styled-components";
import { Image, Modal } from "antd";
import PrimaryButton from "components/base/primaryButton";
import OutlinedButton from "components/base/outlinedButton";

const CommonPreviewTitleAndDesc = css`
  font-family: "DM Sans";
  font-style: normal;
`;

const CommonPreviewTitle = css`
  ${CommonPreviewTitleAndDesc}
  font-weight: 500;
  color: #080f6c;
`;

const CommonPreviewSecondaryText = css`
  ${CommonPreviewTitleAndDesc}
  font-weight: 400;
  color: rgba(41, 46, 115, 0.82);
`;

const CommonWrapperWithPadding = styled.div`
  padding-inline: 32px;
`;

const CommonFlexRowCenter = css`
  display: flex;
  align-items: center;
`;

const CommonPreviewList = styled.div<{ space?: string | number }>`
  ${CommonFlexRowCenter}
  flex-wrap: wrap;
  gap: ${({ space }) => space || 8}px;
`;

export const PreviewProjectUrl = styled.a`
  ${CommonPreviewSecondaryText}
  font-size: 16px;
  line-height: 125%;
`;

export const PreviewProjectUrlList = styled(CommonPreviewList)`
  margin-top: 18px;
`;

export const PreviewVideosWrapper = styled(CommonWrapperWithPadding)`
  margin-bottom: 40px;
`;

export const PreviewProjectUrlWrapper = styled(CommonWrapperWithPadding)``;

export const PreviewProjectFileItemImg = styled(Image)`
  border-radius: 6px;
  object-fit: cover;
`;

export const PreviewProjectFileItem = styled.a`
  display: block;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  padding: 12px;
  ${CommonFlexRowCenter}
  gap: 14px;

  ${CommonPreviewTitle}
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.02em;

  svg {
    width: 48px;
    height: 48px;
  }
`;

export const PreviewProjectFilesList = styled(CommonPreviewList)``;

export const PreviewProjectFilesWrapper = styled(CommonWrapperWithPadding)`
  margin-bottom: 40px;
`;

export const PreviewSkillTag = styled.div<{ tagColor: string }>`
  padding: 4px 12px;
  border-radius: 40px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${({ tagColor }) => tagColor};
  border: 1px solid ${({ tagColor }) => tagColor};
`;

export const PreviewSkillTagList = styled(CommonPreviewList)``;

export const PreviewSkillWrapper = styled(CommonWrapperWithPadding)`
  margin-bottom: 40px;
`;

export const PreviewDescriptionInfo = styled.p`
  ${CommonPreviewSecondaryText}
  font-size: 16px;
  line-height: 130%;
  overflow: hidden;
  white-space: pre-wrap;
  margin-bottom: 0;

  .text-bold {
    font-weight: 600;
  }
  .child-text {
    margin: 0px 0px 0px 12px;
  }
  span {
  }
`;

export const PreviewDescriptionTitle = styled.h4`
  ${CommonPreviewTitle}
  font-size: 20px;
  line-height: 120%;
  margin-bottom: 8px;
`;

export const PreviewDescriptionWrapper = styled(CommonWrapperWithPadding)`
  padding-block: 32px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ),
    linear-gradient(
      90deg,
      rgba(126, 0, 253, 0.1) 0%,
      rgba(193, 67, 252, 0.1) 46.88%,
      rgba(0, 191, 251, 0.1) 100%
    );
`;

export const PreviewTitle = styled.h2`
  ${CommonPreviewTitle}
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.04em;
  margin-bottom: 0;
`;

export const PreviewChangePortfolio = styled(PrimaryButton)``;

export const PreviewDeletePortfolio = styled(OutlinedButton)`
  width: auto;
  height: auto;
  padding-block: 9px;
  border: 1px solid red;
  color: red;

  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    color: red;
    border-color: red;
  }
`;

export const PreviewHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const PreviewWrapper = styled.div`
  max-width: 1170px;
  margin: 20px auto;
`;

export const PreviewConfirmDeleteModalActionBtn = styled(OutlinedButton)`
  width: auto;
  height: auto;
  padding-block: 10px;
`;

export const PreviewConfirmDeleteModalActionWrapper = styled.div`
  ${CommonFlexRowCenter}
  justify-content: center;
  gap: 14px;
`;

export const PreviewConfirmDeleteModalTitle = styled.h3`
  ${CommonPreviewTitle}
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 160%;
  text-align: center;
`;

export const PreviewConfirmDeleteModal = styled(Modal)``;
