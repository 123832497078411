export const BuilderIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="rgba(156, 138, 193, 0.16)" />
    <path
      d="M33.3438 13.8438H14.6562C14.2068 13.8438 13.8438 14.2068 13.8438 14.6562V33.3438C13.8438 33.7932 14.2068 34.1562 14.6562 34.1562H33.3438C33.7932 34.1562 34.1562 33.7932 34.1562 33.3438V14.6562C34.1562 14.2068 33.7932 13.8438 33.3438 13.8438ZM20.6484 28.6719C20.0873 28.6719 19.6328 28.2174 19.6328 27.6562C19.6328 27.0951 20.0873 26.6406 20.6484 26.6406C21.2096 26.6406 21.6641 27.0951 21.6641 27.6562C21.6641 28.2174 21.2096 28.6719 20.6484 28.6719ZM20.6484 25.0156C20.0873 25.0156 19.6328 24.5611 19.6328 24C19.6328 23.4389 20.0873 22.9844 20.6484 22.9844C21.2096 22.9844 21.6641 23.4389 21.6641 24C21.6641 24.5611 21.2096 25.0156 20.6484 25.0156ZM20.6484 21.3594C20.0873 21.3594 19.6328 20.9049 19.6328 20.3438C19.6328 19.7826 20.0873 19.3281 20.6484 19.3281C21.2096 19.3281 21.6641 19.7826 21.6641 20.3438C21.6641 20.9049 21.2096 21.3594 20.6484 21.3594ZM28.3672 28.2656C28.3672 28.3773 28.2758 28.4688 28.1641 28.4688H23.4922C23.3805 28.4688 23.2891 28.3773 23.2891 28.2656V27.0469C23.2891 26.9352 23.3805 26.8438 23.4922 26.8438H28.1641C28.2758 26.8438 28.3672 26.9352 28.3672 27.0469V28.2656ZM28.3672 24.6094C28.3672 24.7211 28.2758 24.8125 28.1641 24.8125H23.4922C23.3805 24.8125 23.2891 24.7211 23.2891 24.6094V23.3906C23.2891 23.2789 23.3805 23.1875 23.4922 23.1875H28.1641C28.2758 23.1875 28.3672 23.2789 28.3672 23.3906V24.6094ZM28.3672 20.9531C28.3672 21.0648 28.2758 21.1562 28.1641 21.1562H23.4922C23.3805 21.1562 23.2891 21.0648 23.2891 20.9531V19.7344C23.2891 19.6227 23.3805 19.5312 23.4922 19.5312H28.1641C28.2758 19.5312 28.3672 19.6227 28.3672 19.7344V20.9531Z"
      fill="#7E00FD"
    />
  </svg>
);
