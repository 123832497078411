import { PageLoading } from "components/base/PageLoading/PageLoading";
import Header from "components/layout/header";
import ModalConnectWallet from "components/modules/modalConnectWallet";
import { FC, memo } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { ILayoutProps } from "./layoutWithoutFooter.props";

const LayoutWithoutFooter: FC<ILayoutProps> = ({ children }: ILayoutProps) => {
  const { connectWalletOpen } = useAppSelector((state) => state.modal);
  return (
    <>
      <Header />
      <Outlet />
      <ModalConnectWallet show={connectWalletOpen} />
      <PageLoading />
    </>
  );
};

export default memo(LayoutWithoutFooter);
