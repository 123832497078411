import { Tooltip } from "antd";

export function AwardStophyIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 3.79167C6.5 3.64801 6.55707 3.51023 6.65865 3.40865C6.76023 3.30707 6.89801 3.25 7.04167 3.25H18.9583C19.102 3.25 19.2398 3.30707 19.3414 3.40865C19.4429 3.51023 19.5 3.64801 19.5 3.79167V4.33333H22.2083C22.352 4.33333 22.4898 4.3904 22.5914 4.49198C22.6929 4.59357 22.75 4.73134 22.75 4.875V8.125C22.75 8.8433 22.4647 9.53217 21.9567 10.0401C21.4488 10.548 20.76 10.8333 20.0417 10.8333H19.13C18.7429 11.9259 18.071 12.8953 17.1837 13.6413C16.2965 14.3873 15.2262 14.8829 14.0833 15.0767V18.4167H17.3333C17.477 18.4167 17.6148 18.4737 17.7164 18.5753C17.8179 18.6769 17.875 18.8147 17.875 18.9583V22.2083C17.875 22.352 17.8179 22.4898 17.7164 22.5914C17.6148 22.6929 17.477 22.75 17.3333 22.75H8.66667C8.52301 22.75 8.38523 22.6929 8.28365 22.5914C8.18207 22.4898 8.125 22.352 8.125 22.2083V18.9583C8.125 18.8147 8.18207 18.6769 8.28365 18.5753C8.38523 18.4737 8.52301 18.4167 8.66667 18.4167H11.9167V15.0767C10.7738 14.8829 9.70348 14.3873 8.81626 13.6413C7.92904 12.8953 7.25713 11.9259 6.86996 10.8333H5.95833C5.24004 10.8333 4.55116 10.548 4.04325 10.0401C3.53534 9.53217 3.25 8.8433 3.25 8.125V4.875C3.25 4.73134 3.30707 4.59357 3.40865 4.49198C3.51023 4.3904 3.64801 4.33333 3.79167 4.33333H6.5V3.79167ZM19.5 8.66667V5.41667H21.6667V8.125C21.6667 8.55598 21.4955 8.9693 21.1907 9.27405C20.886 9.5788 20.4726 9.75 20.0417 9.75H19.5V8.66667ZM6.5 5.41667H4.33333V8.125C4.33333 8.55598 4.50454 8.9693 4.80928 9.27405C5.11403 9.5788 5.52736 9.75 5.95833 9.75H6.5V5.41667Z"
        fill="white"
      />
    </svg>
  );
}
export function DetailsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59922 7.20156H20.3992V9.60156H9.59922V7.20156ZM9.59922 3.60156H22.7992V6.00156H9.59922V3.60156ZM1.19922 3.60156H8.39922V10.8016H1.19922V3.60156ZM9.59922 16.8016H20.3992V19.2016H9.59922V16.8016ZM9.59922 13.2016H22.7992V15.6016H9.59922V13.2016ZM1.19922 13.2016H8.39922V20.4016H1.19922V13.2016Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
}
export function ProfileIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM6 15V17H18V15H6ZM6 7V13H12V7H6ZM14 7V9H18V7H14ZM14 11V13H18V11H14ZM8 9H10V11H8V9Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
}
export function DollarIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10707_16145)">
        <path
          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM8.5 14V16H11V18H13V16H14C14.663 16 15.2989 15.7366 15.7678 15.2678C16.2366 14.7989 16.5 14.163 16.5 13.5C16.5 12.837 16.2366 12.2011 15.7678 11.7322C15.2989 11.2634 14.663 11 14 11H10C9.86739 11 9.74021 10.9473 9.64644 10.8536C9.55268 10.7598 9.5 10.6326 9.5 10.5C9.5 10.3674 9.55268 10.2402 9.64644 10.1464C9.74021 10.0527 9.86739 10 10 10H15.5V8H13V6H11V8H10C9.33696 8 8.70107 8.26339 8.23223 8.73223C7.76339 9.20107 7.5 9.83696 7.5 10.5C7.5 11.163 7.76339 11.7989 8.23223 12.2678C8.70107 12.7366 9.33696 13 10 13H14C14.1326 13 14.2598 13.0527 14.3536 13.1464C14.4473 13.2402 14.5 13.3674 14.5 13.5C14.5 13.6326 14.4473 13.7598 14.3536 13.8536C14.2598 13.9473 14.1326 14 14 14H8.5Z"
          fill="currentColor"
          fillOpacity="1"
        />
      </g>
      <defs>
        <clipPath id="clip0_10707_16145">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const MultipleUsersIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 14.6C24 15.2 22.8 15.6 21.4 15.8C20.5 14.1 18.7 12.8 16.6 11.9C16.8 11.6 17 11.4 17.2 11.1H18C21.1 11 24 12.9 24 14.6ZM6.8 11H6C2.9 11 0 12.9 0 14.6C0 15.2 1.2 15.6 2.6 15.8C3.5 14.1 5.3 12.8 7.4 11.9L6.8 11ZM12 12C14.2 12 16 10.2 16 8C16 5.8 14.2 4 12 4C9.8 4 8 5.8 8 8C8 10.2 9.8 12 12 12ZM12 13C7.9 13 4 15.6 4 18C4 20 12 20 12 20C12 20 20 20 20 18C20 15.6 16.1 13 12 13ZM17.7 10H18C19.7 10 21 8.7 21 7C21 5.3 19.7 4 18 4C17.5 4 17.1 4.1 16.7 4.3C17.5 5.3 18 6.6 18 8C18 8.7 17.9 9.4 17.7 10ZM6 10H6.3C6.1 9.4 6 8.7 6 8C6 6.6 6.5 5.3 7.3 4.3C6.9 4.1 6.5 4 6 4C4.3 4 3 5.3 3 7C3 8.7 4.3 10 6 10Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
};

export function EyeCheckIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9ZM12 17C12.5 17 12.97 16.93 13.42 16.79C13.15 17.5 13 18.22 13 19V19.45L12 19.5C7 19.5 2.73 16.39 1 12C2.73 7.61 7 4.5 12 4.5C17 4.5 21.27 7.61 23 12C22.75 12.64 22.44 13.26 22.08 13.85C21.18 13.31 20.12 13 19 13C18.22 13 17.5 13.15 16.79 13.42C16.93 12.97 17 12.5 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
}
export function CorruptionIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10704_15773)">
        <path
          d="M0 12.6094V22.4531H4.40625V12.6094H0Z"
          fill="currentColor"
          fillOpacity="1"
        />
        <path
          d="M14.4871 0L8.28906 9.82317C9.27569 9.7958 10.2577 9.93366 11.3171 10.3077C12.3678 8.75972 14.2054 8.14275 15.4848 8.95181C16.7838 9.77203 17.0129 11.7477 16.0126 13.3778L18.1881 15.1755L18.2 15.1894L24.0012 6.01087L14.4871 0ZM17.4252 8.51372L18.1757 7.32375L15.7972 5.82342L15.0474 7.01198L13.8581 6.26147L15.3591 3.88294L20.1148 6.88566L18.6138 9.26353L17.4252 8.51372Z"
          fill="currentColor"
          fillOpacity="1"
        />
        <path
          d="M14.8967 12.4572C15.3754 11.5397 15.331 10.5193 14.7335 10.1419C14.1555 9.77476 13.2404 10.1271 12.6172 10.9052C13.0332 11.1395 13.0898 11.1303 14.704 12.298L14.8967 12.4572Z"
          fill="currentColor"
          fillOpacity="1"
        />
        <path
          d="M17.2094 16.1867L13.8766 13.4305C13.2156 12.9523 12.5406 12.4882 12.5406 12.4882C11.3594 11.6586 9.98125 11.2227 8.51875 11.2227C8.22339 11.2227 7.92817 11.2367 7.67505 11.2789C6.64994 11.4038 6.05308 11.688 5.875 11.7458V22.0968L10.7407 24.0008L16.9141 19.182C17.4063 18.8024 17.6875 18.2258 17.7156 17.607C17.7297 17.0867 17.5469 16.5805 17.2094 16.1867Z"
          fill="currentColor"
          fillOpacity="1"
        />
      </g>
      <defs>
        <clipPath id="clip0_10704_15773">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function FinishFlagIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 3V21H5.25V15H20.25V3H3.75ZM5.25 4.5H7.5V6.75H9.75V4.5H12V6.75H14.25V4.5H16.5V6.75H18.75V9H16.5V11.25H18.75V13.5H16.5V11.25H14.25V13.5H12V11.25H9.75V13.5H7.5V11.25H5.25V9H7.5V6.75H5.25V4.5ZM7.5 9V11.25H9.75V9H7.5ZM9.75 9H12V6.75H9.75V9ZM12 9V11.25H14.25V9H12ZM14.25 9H16.5V6.75H14.25V9Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
}
export function ArrowLeftIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6641 8H3.33073"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.33203L3.33333 7.9987L8 12.6654"
        stroke="#292E73"
        strokeOpacity="0.42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function ArrowRightIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33594 8H12.6693"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.33203L12.6667 7.9987L8 12.6654"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function InfoIcon({ title }: { title?: string }) {
  return (
    <Tooltip title={title ?? ""} color="">
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "pointer" }}
      >
        <g clipPath="url(#clip0_10807_16467)">
          <path
            d="M5 10C6.32608 10 7.59785 9.47322 8.53553 8.53553C9.47322 7.59785 10 6.32608 10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 0 5 0C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5C0 6.32608 0.526784 7.59785 1.46447 8.53553C2.40215 9.47322 3.67392 10 5 10ZM5.58125 4.1175L4.95625 7.05813C4.9125 7.27063 4.97438 7.39125 5.14625 7.39125C5.2675 7.39125 5.45062 7.3475 5.575 7.2375L5.52 7.4975C5.34062 7.71375 4.945 7.87125 4.60437 7.87125C4.165 7.87125 3.97813 7.6075 4.09937 7.04688L4.56063 4.87937C4.60063 4.69625 4.56437 4.63 4.38125 4.58562L4.09937 4.535L4.15062 4.29688L5.58187 4.1175H5.58125ZM5 3.4375C4.83424 3.4375 4.67527 3.37165 4.55806 3.25444C4.44085 3.13723 4.375 2.97826 4.375 2.8125C4.375 2.64674 4.44085 2.48777 4.55806 2.37056C4.67527 2.25335 4.83424 2.1875 5 2.1875C5.16576 2.1875 5.32473 2.25335 5.44194 2.37056C5.55915 2.48777 5.625 2.64674 5.625 2.8125C5.625 2.97826 5.55915 3.13723 5.44194 3.25444C5.32473 3.37165 5.16576 3.4375 5 3.4375Z"
            fill="#080A25"
          />
        </g>
        <defs>
          <clipPath id="clip0_10807_16467">
            <rect width="10" height="10" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Tooltip>
  );
}

export function CrossIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 3L3 9"
        stroke="#292E73"
        strokeOpacity="0.82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3L9 9"
        stroke="#292E73"
        strokeOpacity="0.82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function GithubIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10690_15043)">
        <path
          d="M18 3C9.7125 3 3 9.7125 3 18C2.9983 21.1489 3.98822 24.2184 5.82933 26.773C7.67043 29.3276 10.2692 31.2376 13.257 32.232C14.007 32.3625 14.2875 31.9125 14.2875 31.518C14.2875 31.1625 14.268 29.982 14.268 28.725C10.5 29.4195 9.525 27.807 9.225 26.9625C9.0555 26.5305 8.325 25.2 7.6875 24.843C7.1625 24.5625 6.4125 23.868 7.668 23.85C8.85 23.8305 9.693 24.9375 9.975 25.3875C11.325 27.6555 13.482 27.018 14.343 26.625C14.475 25.65 14.868 24.9945 15.3 24.6195C11.9625 24.2445 8.475 22.95 8.475 17.2125C8.475 15.5805 9.0555 14.232 10.0125 13.1805C9.8625 12.8055 9.3375 11.268 10.1625 9.2055C10.1625 9.2055 11.418 8.8125 14.2875 10.7445C15.5086 10.4056 16.7703 10.235 18.0375 10.2375C19.3125 10.2375 20.5875 10.4055 21.7875 10.743C24.6555 8.793 25.9125 9.207 25.9125 9.207C26.7375 11.2695 26.2125 12.807 26.0625 13.182C27.018 14.232 27.6 15.5625 27.6 17.2125C27.6 22.9695 24.0945 24.2445 20.757 24.6195C21.3 25.0875 21.7695 25.9875 21.7695 27.3945C21.7695 29.4 21.75 31.0125 21.75 31.5195C21.75 31.9125 22.032 32.3805 22.782 32.2305C25.7595 31.2252 28.3469 29.3115 30.1799 26.7587C32.0128 24.206 32.9992 21.1427 33 18C33 9.7125 26.2875 3 18 3Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10690_15043">
          <rect width="36" height="36" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function StarIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9974 2.66797L20.1174 11.0146L29.3307 12.3613L22.6641 18.8546L24.2374 28.028L15.9974 23.6946L7.7574 28.028L9.33073 18.8546L2.66406 12.3613L11.8774 11.0146L15.9974 2.66797Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DownloadIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10817_17618)">
        <path
          d="M4 19H20V12H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V12H4V19ZM14 9H19L12 16L5 9H10V3H14V9Z"
          fill="#7E00FD"
        />
      </g>
      <defs>
        <clipPath id="clip0_10817_17618">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CloudSaveIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10817_17580)">
        <path
          d="M25.7402 8.82387H30.5993L22.6367 0.878906V5.72042C22.6385 7.43356 24.027 8.82214 25.7402 8.82387Z"
          fill="#9C8AC1"
          fillOpacity="0.16"
        />
        <path
          d="M14.6579 7.95376C14.6579 8.91839 13.8758 9.70049 12.9111 9.70049C11.9462 9.70049 11.1641 8.91839 11.1641 7.95376C11.1641 6.98913 11.9462 6.20703 12.9111 6.20703C13.8758 6.20703 14.6579 6.98913 14.6579 7.95376Z"
          fill="#9C8AC1"
          fillOpacity="0.16"
        />
        <path
          d="M21.3954 5.71921V0H7.62493C5.9118 0.00173183 4.52322 1.39032 4.52148 3.10345V32.8966C4.52322 34.6097 5.9118 35.9983 7.62493 36H28.356C30.0692 35.9983 31.4574 34.6097 31.4595 32.8966V10.064H25.7403C23.3424 10.0602 21.3993 8.11711 21.3954 5.71921ZM12.9098 4.97452C14.5613 4.97452 15.8993 6.31393 15.8979 7.9654C15.8962 9.61688 14.555 10.9539 12.9036 10.9507C11.2521 10.9476 9.9165 9.60511 9.92135 7.95363C9.92723 6.307 11.2632 4.97522 12.9098 4.97452ZM8.74231 16.2356C8.74231 13.934 10.6082 12.0681 12.9098 12.0681C15.2114 12.0681 17.0773 13.934 17.0773 16.2356V16.945C17.0773 17.2875 16.7992 17.5657 16.4566 17.5657C16.1137 17.5657 15.8359 17.2875 15.8359 16.945V16.2356C15.8359 14.6195 14.5256 13.3095 12.9098 13.3095C11.2937 13.3095 9.98369 14.6195 9.98369 16.2356V16.945C9.98369 17.2875 9.70556 17.5657 9.363 17.5657C9.0201 17.5657 8.74231 17.2875 8.74231 16.945V16.2356ZM9.363 21.6888H20.4111C20.754 21.6888 21.0318 21.9666 21.0318 22.3095C21.0318 22.6521 20.754 22.9302 20.4111 22.9302H9.363C9.0201 22.9302 8.74231 22.6521 8.74231 22.3095C8.74231 21.9666 9.0201 21.6888 9.363 21.6888ZM27.2387 29.0218C27.2397 29.1866 27.1746 29.3449 27.0582 29.4616C26.9415 29.5784 26.7828 29.6435 26.618 29.6424H9.363C9.0201 29.6424 8.74231 29.3643 8.74231 29.0218C8.74231 28.6788 9.0201 28.4011 9.363 28.4011H26.618C26.9605 28.4021 27.2376 28.6792 27.2387 29.0218Z"
          fill="#9C8AC1"
          fillOpacity="0.16"
        />
        <ellipse
          cx="17.625"
          cy="26.625"
          rx="10.875"
          ry="7.875"
          fill="#EFECF5"
        />
        <g clipPath="url(#clip1_10817_17580)">
          <path
            d="M22.2205 32.4574H19.7961H19.1432H19.0022V29.2054H20.0658C20.3355 29.2054 20.4949 28.8989 20.3355 28.6782L18.2667 25.8155C18.1349 25.6316 17.8621 25.6316 17.7303 25.8155L15.6614 28.6782C15.502 28.8989 15.6584 29.2054 15.9311 29.2054H16.9947V32.4574H16.8537H16.2009H13.3903C11.7812 32.3685 10.5 30.8636 10.5 29.233C10.5 28.1082 11.1099 27.1274 12.0141 26.5971C11.9313 26.3734 11.8884 26.1343 11.8884 25.883C11.8884 24.7336 12.8171 23.8049 13.9665 23.8049C14.2148 23.8049 14.4538 23.8478 14.6776 23.9306C15.3427 22.5207 16.7771 21.543 18.4444 21.543C20.6022 21.546 22.3799 23.1981 22.5821 25.3037C24.2403 25.5887 25.5 27.1243 25.5 28.8621C25.5 30.7195 24.0533 32.3286 22.2205 32.4574Z"
            fill="#7E00FD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10817_17580">
          <rect width="36" height="36" fill="white" />
        </clipPath>
        <clipPath id="clip1_10817_17580">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(10.5 19.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ArrowrightIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.666 8H3.33268"
        stroke="#080A25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.33203L3.33333 7.9987L8 12.6654"
        stroke="#080A25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function HeightArrowrightIcon() {
  return (
    <svg
      width="20"
      height="48"
      viewBox="0 0 20 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16602 24H15.8327"
        stroke="#102548"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18.168L15.8333 24.0013L10 29.8346"
        stroke="#102548"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ConnectionIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.8008 15.0016C44.4008 16.6016 44.4008 19.2016 42.8008 20.6016L37.2008 26.2016L21.6008 10.6016L27.2008 5.00156C28.8008 3.40156 31.4008 3.40156 32.8008 5.00156L36.4008 8.60156L42.4008 2.60156L45.2008 5.40156L39.2008 11.4016L42.8008 15.0016ZM31.2008 26.6016L28.4008 23.8016L22.8008 29.4016L18.6008 25.2016L24.2008 19.6016L21.4008 16.8016L15.8008 22.4016L12.8008 19.6016L7.20078 25.2016C5.60078 26.8016 5.60078 29.4016 7.20078 30.8016L10.8008 34.4016L2.80078 42.4016L5.60078 45.2016L13.6008 37.2016L17.2008 40.8016C18.8008 42.4016 21.4008 42.4016 22.8008 40.8016L28.4008 35.2016L25.6008 32.4016L31.2008 26.6016Z"
        fill="#7E00FD"
      />
      <rect
        x="15"
        y="3.75781"
        width="6"
        height="6"
        transform="rotate(45 15 3.75781)"
        fill="#292E73"
        fillOpacity="0.42"
      />
      <rect
        x="32.2422"
        y="37"
        width="6"
        height="6"
        rx="3"
        transform="rotate(45 32.2422 37)"
        fill="#292E73"
        fillOpacity="0.42"
      />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.625 4.375L4.375 15.625"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.625 15.625L4.375 4.375"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
