import { Tabs } from "antd";
import styled from "styled-components";
import tab_bg from "assets/images/png/tab_vertical_bg.png";

export const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: auto;
  padding: 32px 0px;
`;

export const FakeTabContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  position: relative;
  display: flex;
  min-height: 500px;

  .ant-empty {
    flex-grow: 1;
  }
`;

export const FakeTabWrapper = styled.div`
  width: 202px;
  background-image: url(${tab_bg});
  background-size: cover;
  border-radius: inherit;

  h1 {
    margin: 24px 0 0 24px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: -0.04em;
    color: #080f6c;
  }
`;

export const TabsContent = styled(Tabs)`
  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  position: relative;
  min-height: 500px;

  ::after {
    left: 24px;
    top: 24px;
    position: absolute;
    content: "Courses";
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: -0.04em;
    color: #080f6c;
  }
  .ant-tabs-nav {
    width: 202px;
    padding-top: 74px;
    background-image: url(${tab_bg});
    background-size: cover;
    .ant-tabs-tab-active {
      background: linear-gradient(
        270deg,
        rgba(126, 0, 253, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      border-right: 2px solid #7e00fd;
    }
    .ant-tabs-tab {
      margin-top: 0px !important;
      height: 27px;
      .ant-tabs-tab-btn {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 125%;
        letter-spacing: -0.02em;
        color: #080f6c !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const TabPaneCustom = styled(Tabs.TabPane)`
  padding-left: unset !important;
`;

export const WrapFileAndInput = styled.div`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
