import { FC, useEffect, useMemo, useState } from "react";
import { IProjectDetailProps } from "./ProjectDetail.props";
import {
  Container,
  BackgroundImage,
  OrgHeadWrapper,
  OrgHeadCenter,
  OrgHeadInfo,
  ProjectTitle,
  TagItem,
  SponsorText,
  SponsorName,
  CreateWorkTaskBtn,
  ProjectInfoLeft,
  TotalBudgetText,
  TotalBudgetNumber,
  OrgContentWrapper,
  ProjectActionBtn,
  SponsorWrapper,
} from "./ProjectDetail.style";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Avatar, Tooltip, message } from "antd";
import {
  deleteProjectAPI,
  finishProjectAPI,
  getAllPackages,
  getProjectDetails,
  IProject,
} from "api/users";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FAQ from "./components/FAQ";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { finishProjectSC, handleMessageErrorSC } from "web3/contract";
import { setPageLoading, setReset } from "redux/slices";
import { BlockchainNetworks, ROUTER_CONSTANTS } from "utils/constant";
import {
  CommonFlexRow,
  OrganTabs,
  TabTitle,
} from "pages/OrganizationPage/OrganizationPage.style";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import { SecondaryTrashIcon } from "assets/icons/trash.icon";
import { EditProjectIcon } from "assets/icons/editOrgan.icon";
import ProjectDescription from "./components/ProjectDescription";
import AllWorktasks from "./components/AllWorkTask";
import Grants from "pages/OrganizationPage/Grants";
import Transaction from "pages/OrganizationPage/Transaction";
import { IWorktasksByStatusItem, WORK_STATUS } from "api/projectDetail";
import Skeleton from "react-loading-skeleton";
import { formatUnits } from "ethers/lib/utils";
import { useCheckWalletAndSwitchNetwork } from "hooks";

const ProjectDetail: FC<IProjectDetailProps> = () => {
  const param = useParams();
  const userInfo = useAppSelector((state) => state.auth.user);
  const userId = userInfo?._id;
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: worktaskRes } = useQuery(
    [`getAllPackagesByProjectId-${param.id}`],
    () => getAllPackages(param.id || ""),
    {
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      refetchOnWindowFocus: false,
    }
  );
  const worktasks: IWorktasksByStatusItem[] = worktaskRes?.responseData || [];

  const { data: projectDetailRes, isLoading: projectDetailLoading } = useQuery(
    [`getProjectDetail-${param.id}`],
    () => getProjectDetails(param.id || ""),
    {
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      refetchOnWindowFocus: false,
    }
  );
  const project: IProject | undefined = useMemo(
    () => projectDetailRes?.responseData,
    [projectDetailRes?.responseData]
  );
  const formattedProject = useMemo(() => [project as IProject], [project]);

  const { mutate: finishProject } = useMutation(finishProjectAPI, {
    onSuccess: (res: any) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        queryClient.invalidateQueries([
          `getAllPackagesByProjectId-${param.id}`,
        ]);
        queryClient.invalidateQueries([`getProjectDetail-${param.id}`]);
        dispatch(setPageLoading(false));
      } else {
        message.error(responseMessage);
      }
    },
  });

  const { mutate: deleteProject } = useMutation(deleteProjectAPI, {
    onSuccess: (res: any) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        queryClient.invalidateQueries([
          `getAllPackagesByProjectId-${param.id}`,
        ]);
        queryClient.invalidateQueries([`getProjectDetail-${param.id}`]);
        dispatch(setPageLoading(false));
        navigate(
          ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(
            ":id",
            userInfo?.organization
          )
        );
      } else {
        message.error(responseMessage);
      }
    },
  });

  const handleFinishProject = async () => {
    if (!checkWallet()) return;

    dispatch(setPageLoading(true));

    try {
      const projectChainId =
        BlockchainNetworks[project?.blockchainNetwork?.name!];

      await handleSwitchNetwork(projectChainId);

      const scParams = { projectId: project?.scProjectId };
      const { tx, event } = await finishProjectSC(scParams);

      const budgetReturned = formatUnits(event[0][1], project?.decimal);

      if (worktasks?.length === 0) {
        deleteProject({
          projectId: project?._id || "",
          txHash: tx.hash,
          budgetReturned,
        });
      } else {
        finishProject({
          projectId: project?._id || "",
          txHash: tx.hash,
          budgetReturned,
        });
      }
    } catch (err: any) {
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
    dispatch(setPageLoading(false));
  };

  const isProjectCreator = project?.initiator === userId;
  const isProjectCompleted = project?.projectStatus === "COMPLETED";
  const isProjectStart =
    project?.adminVerification === "ACCEPTED" &&
    project.statusApprovedByInitiator !== "PENDING";

  let navigate = useNavigate();

  const handleClickCreateWorkTask = () => {
    if (project?.remainingBudget === 0) {
      message.warning("Project has no budget left");
      return;
    }

    dispatch(setReset());

    navigate(
      ROUTER_CONSTANTS.PROJECT.ADD_WORKTASK.replace(
        ":projectId",
        param?.id || ""
      )
    );
  };

  useEffect(() => {
    if (project?.status === "INACTIVE") {
      message.error("Project is deleted");
      navigate("/");
    }
  }, [navigate, project?.status]);

  const tabsTitle = [
    {
      name: "Project Description",
      content: <ProjectDescription project={project} />,
    },
    {
      name: "All WorkTasks",
      content: (
        <>
          <AllWorktasks project={project} worktasksByStatus={worktasks} />
        </>
      ),
    },
    {
      name: "Grants",
      content: (
        <>
          <Grants allProjects={formattedProject} />
        </>
      ),
    },
    {
      name: "Transactions",
      content: (
        <>
          <Transaction project={project} />
        </>
      ),
    },
    {
      name: "FAQs",
      content: <FAQ isProjectCreator={isProjectCreator} />,
    },
  ];
  const [activeTab, setActiveTab] = useState(tabsTitle[0].name);

  const filteredOrg = project?.organizationDetails;

  useEffect(() => {
    const routerState = location.state;

    if (
      typeof routerState === "object" &&
      routerState != null &&
      "toAllWorktaskTab" in routerState
    ) {
      setActiveTab("All WorkTasks");
    }
  }, [location.state]);

  return (
    <Container>
      <OrgHeadWrapper>
        <OrgHeadCenter>
          {projectDetailLoading ? (
            <Skeleton
              style={{
                aspectRatio: "5.85",
              }}
            />
          ) : (
            <BackgroundImage
              src={project?.coverImage}
              alt="Worktasks background"
            />
          )}
          <OrgHeadInfo space="60px">
            <ProjectInfoLeft space="12px">
              <CommonFlexRow space="10px">
                <ProjectTitle>
                  {projectDetailLoading ? (
                    <Skeleton width="160px" />
                  ) : (
                    project?.projectName
                  )}
                </ProjectTitle>
                <TagItem>
                  {projectDetailLoading ? (
                    <Skeleton width="40px" />
                  ) : (
                    project?.category?.name
                  )}
                </TagItem>
                <TagItem $bg="#080f6c">
                  {projectDetailLoading ? (
                    <Skeleton width="40px" />
                  ) : project?.adminVerification === "PENDING" ? (
                    "PENDING"
                  ) : project?.projectStatus === WORK_STATUS.INPROGRESS ? (
                    "In progress"
                  ) : (
                    project?.projectStatus?.toLowerCase()
                  )}
                </TagItem>
              </CommonFlexRow>
              <SponsorWrapper space="8px">
                <SponsorText>Sponsor by</SponsorText>
                {(filteredOrg?.length || 0) > 0 ? (
                  filteredOrg?.map((orgItem) => {
                    return (
                      <SponsorName key={orgItem?._id}>
                        <Avatar size={"small"} src={orgItem?.avatar} />
                        <Link
                          style={{ color: "#080f6c", fontWeight: "500" }}
                          to={ROUTER_CONSTANTS.ORGANIZATION.VIEW.replace(
                            ":id",
                            orgItem?._id
                          )}
                        >
                          {orgItem?.name}
                        </Link>
                      </SponsorName>
                    );
                  })
                ) : (
                  <SponsorName>No sponsor</SponsorName>
                )}
              </SponsorWrapper>
              {isProjectCreator && isProjectStart && !isProjectCompleted && (
                <Tooltip
                  title={
                    "Click this button to create a new WorkTask. It allows you to initiate a new task and define its details for collaboration."
                  }
                >
                  <CreateWorkTaskBtn onClick={handleClickCreateWorkTask}>
                    Create a new WorkTask
                  </CreateWorkTaskBtn>
                </Tooltip>
              )}
            </ProjectInfoLeft>
            <CommonFlexColumn space="4px">
              <TotalBudgetText>Total budget</TotalBudgetText>
              <TotalBudgetNumber>
                {projectDetailLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    {project?.totalBudget}{" "}
                    {project?.tokenSymbol?.slice(0, 5) || project?.tokenName}
                  </>
                )}
              </TotalBudgetNumber>
            </CommonFlexColumn>
          </OrgHeadInfo>
        </OrgHeadCenter>
      </OrgHeadWrapper>
      <OrgContentWrapper>
        <OrganTabs
          activeKey={activeTab}
          onChange={setActiveTab}
          tabBarExtraContent={
            isProjectCreator && isProjectStart && !isProjectCompleted ? (
              <CommonFlexRow>
                <ProjectActionBtn onClick={handleFinishProject}>
                  {worktasks.length ? (
                    "Finish Project"
                  ) : (
                    <>
                      Delete this project <SecondaryTrashIcon />
                    </>
                  )}
                </ProjectActionBtn>
                <ProjectActionBtn
                  $cl="#080F6C"
                  onClick={() =>
                    navigate(
                      ROUTER_CONSTANTS.PROJECT.UPDATE_PROJECT.replace(
                        ":id",
                        project?._id || ""
                      )
                    )
                  }
                >
                  Edit project <EditProjectIcon />
                </ProjectActionBtn>
              </CommonFlexRow>
            ) : undefined
          }
        >
          {tabsTitle.map((tab) => {
            return (
              <OrganTabs.TabPane
                key={tab.name}
                tab={<TabTitle>{tab.name}</TabTitle>}
              >
                {tab.content}
              </OrganTabs.TabPane>
            );
          })}
        </OrganTabs>
      </OrgContentWrapper>
    </Container>
  );
};

export default ProjectDetail;
