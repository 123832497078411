import styled from "styled-components";
import { IFlexboxProps } from "./flexbox.props";

export const StyledFlexbox = styled.div<IFlexboxProps>`
  display: flex;
  align-items: ${p => p.align || 'center'};
  margin: ${(p) => p.m};
  gap: ${(p) => p.gap};
  flex-wrap: wrap;
  justify-content: ${p => p.justify || 'start'};
`;
