import { BigNumber, ethers, utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { ABI, ERC20ABI } from "./ABI";
import { message } from "antd";
import { LEARN_TO_EARN_ADDRESS, TOKEN_FACTORY_ADDRESS } from "utils/constant";
import { LearnToEarnABI } from "gen/types";
import JSONLearnToEarnABI from "./learnToEarnABI.json";
import JSONTokenABI from "./TokenABI.json";
import { updateCourseAPI } from "api/course/request";
import { getCurrentNetwork } from "./contract";

export const MAX_UINT256 =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export const getContract = () => {
  try {
    const wethInterface = new utils.Interface(JSONLearnToEarnABI);
    const wethContractAddress = LEARN_TO_EARN_ADDRESS[getCurrentNetwork()];
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const signer = provider.getSigner();

    return new Contract(wethContractAddress, wethInterface, signer);
  } catch {
    message.error("Please connect to metamask");
    throw Error();
  }
};

export const getCourseIdFromTx = async (txHash: string) => {
  try {
    const wethInterface = new utils.Interface(JSONLearnToEarnABI);
    const wethContractAddress = LEARN_TO_EARN_ADDRESS[getCurrentNetwork()];
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const signer = provider.getSigner();

    const learnToEarn = new Contract(
      wethContractAddress,
      wethInterface,
      signer
    );

    const receipt = await provider.getTransactionReceipt(txHash);

    const event =
      learnToEarn.interface.events[
        "CreatedCourse(bytes32,address,address,uint256,uint256)"
      ];

    const courseIdFromSc = receipt.logs
      .filter((log) => log.address === wethContractAddress)
      .map((log) => {
        const decodeData = learnToEarn.interface.decodeEventLog(
          event,
          log.data,
          log.topics
        );

        return "courseId" in decodeData ? decodeData.courseId : "";
      })
      .find((val) => val?.length > 0);

    return courseIdFromSc;

    // const decodeData = learnToEarn.interface.decodeEventLog(
    //   event,
    //   receipt.logs[1].data,
    //   receipt.logs[1].topics
    // );

    // return decodeData.courseId;
  } catch (err) {
    console.error(err);
    message.error("Please connect to metamask");
    throw Error();
  }
};

export const getTokenContract = () => {
  try {
    const wethInterface = new utils.Interface(JSONTokenABI);
    const wethContractAddress = TOKEN_FACTORY_ADDRESS[getCurrentNetwork()];
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const signer = provider.getSigner();

    return new Contract(wethContractAddress, wethInterface, signer);
  } catch {
    message.error("Please connect to metamask");
    throw Error();
  }
};

export const getContractForRead = () => {
  try {
    const wethInterface = new utils.Interface(ABI);
    const wethContractAddress = LEARN_TO_EARN_ADDRESS[getCurrentNetwork()];
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    return new Contract(wethContractAddress, wethInterface, provider);
  } catch {
    message.error("Please connect to metamask");
    throw Error();
  }
};

export const getContractERC20 = () => {
  try {
    const wethInterface = new utils.Interface(ERC20ABI);
    const wethContractAddress = LEARN_TO_EARN_ADDRESS[getCurrentNetwork()];
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const signer = provider.getSigner();
    return new Contract(
      wethContractAddress,
      wethInterface,
      signer
    ) as LearnToEarnABI;
  } catch {
    message.error("Please connect to metamask");
    throw Error();
  }
};

export interface ISCCreateTokenParams {
  totalSupply: string | number | BigNumber;
  name: string;
  symbol: string;
}

export const createTokenSC = async ({
  totalSupply,
  name,
  symbol,
}: ISCCreateTokenParams) => {
  const contract = getTokenContract();
  if (contract) {
    let tx = await contract.deployToken(totalSupply, name, symbol);

    const receipt = await tx.wait();
    let event = receipt.events
      ?.filter((ev: any) => ev.event === "DeployedToken")
      ?.map((ev: any) => ev.args);
    return { tx, event };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCCreateNFTParams {
  name: string;
  symbol: string;
  uri: string;
}

export const createNFTSC = async ({
  name,
  symbol,
  uri,
}: ISCCreateNFTParams) => {
  const contract = getTokenContract();
  if (contract) {
    let tx = await contract.deployNFT(name, symbol, uri);
    const event = await tx.wait();
    return { tx, event };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCCreateCourseParams {
  rewardAddress: string;
  budget: string;
  bonus: string;
  timeStart: number;
  timeEndBonus: string | number;
  isUsingDuration: boolean;
  isBonusToken: boolean;
  courseId?: string;
}

export const createCourseSC = async ({
  rewardAddress,
  budget,
  bonus,
  timeStart,
  timeEndBonus,
  isUsingDuration,
  isBonusToken,
  courseId,
}: ISCCreateCourseParams) => {
  const contract = getContract();
  if (contract) {
    let tx = await contract.createCourse(
      rewardAddress,
      budget,
      bonus,
      timeStart,
      timeEndBonus,
      isUsingDuration,
      isBonusToken
    );
    const chainId = await contract.provider
      .getNetwork()
      .then((network) => network.chainId);
    // call API update
    updateCourseAPI({
      courseId: courseId ?? "",
      txHash: tx.hash ?? "",
      chainId,
    });

    const event = await tx.wait();
    return { tx, event };
  }
  message.error("Please connect to metamask");
  throw Error();
};

export const claimCourseBudgetSC = async (scCourseId: string) => {
  const contract = getContract();
  if (contract) {
    const tx = await contract.withdrawBudget(scCourseId);
    const event = await tx.wait();
    return { tx, event };
  }

  message.error("Please connect to metamask");
  throw Error();
};

export const removeCourseSC = async (scCourseId: string) => {
  const contract = getContract();

  if (contract) {
    const tx = await contract.removeCourse(scCourseId);
    const event = await tx.wait();
    return { tx, event };
  }

  message.error("Please connect to metamask");
  throw Error();
};

export const getCourseDataSC = async (courseId: string) => {
  const contract = getContract();
  if (contract) {
    return await contract.getCourseData(courseId);
  }

  message.error("Please connect to metamask");
  throw Error();
};

export interface ISCCompleteCourseParams {
  courseId: string;
  learner: string;
  timeStarted: number;
  timeCompleted: number;
  nftIds: string[];
}
export const completedCourseSC = async ({
  courseId,
  learner,
  timeStarted,
  timeCompleted,
  nftIds,
}: ISCCompleteCourseParams) => {
  const contract = getContract();
  if (contract) {
    return await contract.completeCourse(
      courseId,
      learner,
      timeStarted,
      timeCompleted,
      nftIds
    );
  }
  message.error("Please connect to metamask");
  throw Error();
};
