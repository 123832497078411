import { Steps } from "antd";
import styled from "styled-components";

export const BaseSteps = styled(Steps)`
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
    background: #c3c6cf;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #7e00fd;
  }

  .ant-steps-item:nth-last-child(2) {
    &.ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-tail:after {
      background: linear-gradient(
        90deg,
        #7e00fd 27.03%,
        #7e00fd 50.83%,
        #00de8c 102.5%
      );
    }
  }

  .ant-steps-item:not(:last-child) {
    &.ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-icon {
      background: #7e00fd;

      svg path {
        fill: #fff;
        fill-opacity: 1;
      }
    }

    &.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
      svg path {
        fill: #fff;
        fill-opacity: 1;
      }
    }
  }

  .ant-steps-item:last-child {
    &.ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-icon {
      background: #00de8c;
    }
  }

  .ant-steps-item-tail:after {
    height: 2px;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail:after {
    background-color: #c3c6cf;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-tail:after {
    background-color: #c3c6cf;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail:after {
    background: #7e00fd;
  }

  .ant-steps-item-icon .ant-steps-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-steps-item-icon {
    padding: 8px;
  }

  .ant-steps-item-tail {
    top: 20px;
    margin-left: 66px;
  }

  &.ant-steps-label-vertical .ant-steps-item-content {
    margin-left: 8px;
  }

  .ant-steps-item-title {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 115%;
    text-align: center;
    color: #080a25;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #080a25;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #080a2580;
  }
`;
