import { Collapse } from "antd";
import styled from "styled-components";

export const WorkTaskCollapse = styled(Collapse)`
  &.ant-collapse {
    margin-top: 16px;
  }

  &.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    padding-block: 28px;
  }

  .ant-collapse-header-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #080f6c;
  }
`;

export const WorktaskWrapper = styled.div`
  margin-block: 16px;
`;
