import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, message } from "antd";
import {
  createSupportTicketAPI,
  getAllSupportTicketAPI,
  getCollaboratorsByPackageIdAPI,
  getMyProjectListAPI,
  getPackagesByProjectIdAPI,
} from "api/users";
import { SendIcon } from "assets/icons/send.icon";
import { AxiosError } from "axios";
import {
  StyledOption,
  StyledSelect,
} from "pages/CreateProject/createProject.style";
import { FC, memo, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { requiredRules } from "utils/validatorRules";
// import CardItem from "./cardItem";
import CardItemAdmin from "./cardItemAdmin";
import { ISupportTicketProps } from "./supportTicket.props";
import {
  CardContainer,
  Container,
  Content,
  FormAboveContainer,
  FormBelowContainer,
  FormContainer,
  FormItemContainer,
  FormTextAreaInput,
  FormTextInput,
  Heading,
  ModalFormItem,
  SendButton,
  SendButtonContainer,
  SupportTicketLabel,
} from "./supportTicket.style";

const SupportTicket: FC<ISupportTicketProps> = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [projects, setProjects] = useState<any>([]);
  const [packages, setPackages] = useState<any>([]);
  const [collaborators, setCollaborators] = useState<any>([]);
  const [selectedpackage, setSelectedPackage] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const [supportTikets, setSupportTikets] = useState<any>([]);

  const [skip, setSkip] = useState<number>(0);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("collaborator", undefined);
  }, [form, selectedpackage]);

  useEffect(() => {
    let isExist = false;
    projects.forEach((project: any) => {
      if (project?._id === selectedProject)
        if (project?.initiatorDetails?._id === user._id) {
          setIsCreator(true);
          isExist = true;
        }
    });
    if (!isExist) setIsCreator(false);
    form.setFieldValue("package", undefined);
    form.setFieldValue("collaborator", undefined);
  }, [selectedProject]);

  useQuery(["getMyProjectList"], () => getMyProjectListAPI(user._id), {
    onSuccess: (res) => {
      if (res.responseCode === 200) {
        setProjects(res.responseData);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  useQuery(
    ["getPackagesByProjectId", selectedProject],
    () => getPackagesByProjectIdAPI(selectedProject),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          setPackages(res.responseData);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      enabled: !!selectedProject,
    }
  );

  useQuery(
    ["getCollaboratorsByPackageId", selectedpackage],
    () => getCollaboratorsByPackageIdAPI(selectedProject, selectedpackage),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          setCollaborators(res.responseData);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      enabled: !!selectedpackage,
    }
  );

  const { refetch } = useQuery(
    ["getAllSupportTicket", skip],
    () => getAllSupportTicketAPI(skip, 15),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          setSupportTikets(res.responseData.records);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
    }
  );

  const { mutate: createSupportTicket } = useMutation(createSupportTicketAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        message.success("Support ticket successfully sent!");
        setSelectedPackage("");
        setSelectedProject("");
        form.resetFields();
        refetch();
      } else {
        message.error(responseMessage);
      }
    },
  });

  function onSubmit() {
    const subject = form.getFieldValue("subject");
    const details = form.getFieldValue("details");
    const reason = form.getFieldValue("reason");
    const packageId = selectedpackage;
    const userId = form.getFieldValue("collaborator");
    const projectId = selectedProject;

    const params = {
      data: {
        subject,
        details,
        reason,
        packageId,
        userId,
        projectId,
      },
    };
    createSupportTicket(params);
  }

  return (
    <>
      <Heading>
        <div>Support Ticket</div>
      </Heading>
      <Container>
        <Content>
          <FormContainer>
            <Form
              name="signIn"
              wrapperCol={{ span: 32 }}
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              form={form}
              onFinish={onSubmit}
            >
              <FormAboveContainer>
                <FormItemContainer>
                  <SupportTicketLabel>Subject</SupportTicketLabel>
                  <ModalFormItem
                    name="subject"
                    labelAlign="left"
                    rules={[requiredRules]}
                  >
                    <FormTextInput placeholder="Enter Subject" />
                  </ModalFormItem>
                </FormItemContainer>
                <FormItemContainer>
                  <SupportTicketLabel>Project</SupportTicketLabel>
                  <ModalFormItem
                    name="project"
                    labelAlign="left"
                    rules={[requiredRules]}
                  >
                    {/* <FormTextInput placeholder="Project" /> */}
                    <StyledSelect
                      placeholder="Project"
                      onChange={(value: any) => {
                        setSelectedProject(value);
                      }}
                    >
                      {projects.map((project: any, i: number) => (
                        <StyledOption value={project._id} key={i}>
                          {project?.projectName}
                        </StyledOption>
                      ))}
                    </StyledSelect>
                  </ModalFormItem>
                </FormItemContainer>
                <FormItemContainer>
                  <SupportTicketLabel>Package</SupportTicketLabel>
                  <ModalFormItem
                    name="package"
                    labelAlign="left"
                    rules={[requiredRules]}
                  >
                    {/* <FormTextInput placeholder="Project" /> */}
                    <StyledSelect
                      placeholder="package"
                      onChange={(value: any) => {
                        setSelectedPackage(value);
                      }}
                    >
                      {packages.map((pk: any, i: number) => (
                        <StyledOption value={pk?._id} key={i}>
                          {pk?.name}
                        </StyledOption>
                      ))}
                    </StyledSelect>
                  </ModalFormItem>
                </FormItemContainer>
                {isCreator && (
                  <FormItemContainer>
                    <SupportTicketLabel>Collaborator</SupportTicketLabel>
                    <ModalFormItem
                      name="collaborator"
                      labelAlign="left"
                      rules={[requiredRules]}
                    >
                      {/* <FormTextInput placeholder="Project" /> */}
                      <StyledSelect
                        placeholder="Collaborator"
                        // onChange={handleSelectBlockchainNetwork}
                      >
                        {collaborators.map((coll: any, i: number) => (
                          <StyledOption value={coll?._id} key={i}>
                            {coll?.fullName ? coll?.fullName : coll?.emailId}
                          </StyledOption>
                        ))}
                      </StyledSelect>
                    </ModalFormItem>
                  </FormItemContainer>
                )}

                <FormItemContainer>
                  <SupportTicketLabel>Reason</SupportTicketLabel>
                  <ModalFormItem
                    name="reason"
                    labelAlign="left"
                    rules={[requiredRules]}
                  >
                    <FormTextInput placeholder="Enter reason" />
                  </ModalFormItem>
                </FormItemContainer>
              </FormAboveContainer>
              <FormBelowContainer>
                <FormItemContainer>
                  <SupportTicketLabel>Details</SupportTicketLabel>
                  <ModalFormItem name="details" labelAlign="left">
                    <FormTextAreaInput placeholder="Write details" />
                  </ModalFormItem>
                </FormItemContainer>
              </FormBelowContainer>
              <SendButtonContainer>
                <SendButton htmlType="submit" width={"81px"} height={"32px"}>
                  <SendIcon></SendIcon>
                  <span>Send</span>
                </SendButton>
              </SendButtonContainer>
            </Form>
          </FormContainer>
          {supportTikets?.map((supportTiket: any, i: number) => (
            <CardContainer key={i}>
              <CardItemAdmin
                supportTiket={supportTiket}
                refresh={refetch}
              ></CardItemAdmin>
            </CardContainer>
          ))}
        </Content>
      </Container>
    </>
  );
};

export default memo(SupportTicket);
