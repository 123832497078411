import { FC } from "react";
import { IButtonProps } from "./button.props";
import { ButtonAntd } from "./button.style";

const Button: FC<IButtonProps> = ({
  children,
  width,
  height,
  color,
  background,
  border,
  ...props
}: IButtonProps) => {
  return (
    <ButtonAntd
      {...props}
      width={width}
      height={height}
      color={color}
      background={background}
      border={border}
    >
      {children}
    </ButtonAntd>
  );
};

export default Button;
