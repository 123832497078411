import { FC } from "react";
import { IResultsNotFoundProps } from "./resultsNotFound.props";
import { Content, ErrorContainer } from "./resultsNotFound.style";

const ResultsNotFound: FC<IResultsNotFoundProps> = ({
  title,
  content,
  img,
  ...props
}: IResultsNotFoundProps) => {
  return (
    <ErrorContainer>
      <img src={img} alt={title} />
      <Content>{content?.text}</Content>
    </ErrorContainer>
  );
};

export default ResultsNotFound;
