import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Editor from "@monaco-editor/react";
import { message, Select } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { IIDESubmitProps, IResponseCode } from "./IDESubmit.props";
import {
  Border,
  ButtonRun,
  ButtonSubmit,
  Container,
  CustomInput,
  Heading,
  Label,
  OutlineButton,
  ShowOutPut,
  WrapEditor,
  WrapLabel,
  WrapOption,
  WrapRequest,
  WrapResponse,
} from "./IDESubmit.style";
import { defineTheme, monacoThemes } from "./loadTheme";
import { languageOptions } from "./languageOptions";
import { useMutation } from "@tanstack/react-query";
import { submitCodeAPI } from "api/course/request";
import { loader } from "@monaco-editor/react";

const IDESubmitPage: FC<IIDESubmitProps> = ({ ...props }: IIDESubmitProps) => {
  const [monacoLanguages, setMonacoLanguages] = useState<Array<any>>([]);
  useEffect(() => {
    loader.init().then((monaco) => {
      setMonacoLanguages(
        monaco.languages.getLanguages().map((item: any, index: number) => {
          return {
            id: 1000 + index,
            value: 1000 + index,
            name: item.id,
            label: item.id,
            valueCode: item.id,
          };
        })
      );
    });
  }, []);

  const optionLanguages = useMemo(() => {
    return monacoLanguages.filter((item_) =>
      languageOptions.every((item) => item.valueCode !== item_.valueCode)
    );
  }, [monacoLanguages]);

  const [option, setOption] = useState<{
    code: string;
    theme: string;
    language: number;
    customInput: string;
  }>({
    code: `// some comment`,
    theme: "light",
    language: 63,
    customInput: "",
  });

  const [responseSubmit, setResponseSubmit] = useState<IResponseCode>();
  const handleThemeChange = (theme: any) => {
    if (["light", "vs-dark"].includes(theme)) {
      setOption((prev: any) => {
        return { ...prev, theme: theme };
      });
    } else {
      defineTheme(theme).then((_) =>
        setOption((prev: any) => {
          return { ...prev, theme: theme };
        })
      );
    }
  };
  const handleLanguageChange = (value: any) => {
    setOption((prev) => {
      return { ...prev, language: value };
    });
  };

  const languageValue = useMemo(() => {
    return languageOptions
      .concat(optionLanguages)
      .find((item) => item.value == option.language)?.valueCode;
  }, [option]);

  const { mutate: submitCodeMutate, isLoading } = useMutation(submitCodeAPI, {
    onSuccess: (res) => {
      setResponseSubmit(res.responseData);
    },
  });
  const handleSubmitCode = () => {
    const params = {
      language_id: option.language,
      source_code: btoa(option.code),
      stdin: btoa(option.customInput),
    };
    if (!params.language_id)
      return message.warning("Please choose a programming language");
    submitCodeMutate(params);
  };
  const [showModal, setShowModal] = useState(false);
  return (
    <Container>
      <Heading>
        <div>
          <WrapOption>
            <Select
              placeholder={`Select Language`}
              options={languageOptions.concat(optionLanguages)}
              onChange={handleLanguageChange}
              defaultValue={option.language}
              allowClear={true}
              showSearch
              filterOption={(inputValue, option: any) => {
                return option?.label
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }}
            ></Select>
            <Select
              placeholder={`Select Theme`}
              options={Object.entries(monacoThemes).map(
                ([themeId, themeName]) => ({
                  label: themeName,
                  value: themeId,
                  key: themeId,
                })
              )}
              onChange={handleThemeChange}
              allowClear={true}
              showSearch
              filterOption={(inputValue, option: any) => {
                return option?.label
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }}
            ></Select>
            <ButtonRun loading={isLoading} onClick={() => handleSubmitCode()}>
              <span>Run</span>
              <RightOutlined />
            </ButtonRun>
          </WrapOption>
          <OutlineButton onClick={() => setShowModal(true)}>
            <LeftOutlined />
            <span>Back to course page</span>
          </OutlineButton>
        </div>
      </Heading>
      <WrapEditor>
        <WrapRequest>
          <Editor
            language={languageValue}
            value={option.code}
            theme={option.theme}
            defaultValue={option.code}
            onChange={(value) =>
              setOption((prev: any) => {
                return { ...prev, code: value };
              })
            }
          />
          <ButtonSubmit>
            <span>Submit Answer</span>
            <RightOutlined />
          </ButtonSubmit>
        </WrapRequest>
        <WrapResponse>
          <ShowOutPut
            placeholder="Output"
            value={responseSubmit?.stdout}
          ></ShowOutPut>
          <CustomInput
            value={option.customInput}
            onChange={(e) =>
              setOption((prev: any) => {
                return { ...prev, customInput: e.target.value };
              })
            }
            placeholder={"Input"}
          ></CustomInput>

          {responseSubmit && (
            <WrapLabel>
              <Label>
                <span>Status: </span>
                <Border>{responseSubmit?.status.description} </Border>
              </Label>
              <Label>
                <span>Memory: </span> <Border>{responseSubmit?.memory} </Border>
              </Label>
              <Label>
                <span>Time: </span> <Border>{responseSubmit?.time} </Border>
              </Label>
            </WrapLabel>
          )}
        </WrapResponse>
      </WrapEditor>
    </Container>
  );
};

export default IDESubmitPage;
