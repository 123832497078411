import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import styled from "styled-components";

export const WalletInfo = styled(CommonFlexRow)`
  flex-grow: 1;
  justify-content: space-between;
`;

export const WalletItem = styled(CommonFlexRow)<{ $active?: boolean }>`
  border: 1px solid
    ${({ $active }) =>
      $active === true ? "#7E00FD" : "rgba(156, 138, 193, 0.16)"};
  background: ${({ $active }) =>
    $active === true ? "rgba(126, 0, 253, 0.1)" : "#fff"};
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
`;

export const WalletSection = styled(CommonFlexColumn)`
  flex-basis: 42%;
`;

export const BudgetSection = styled(CommonFlexColumn)`
  flex-basis: 33%;
`;

export const WalletBalance = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 125%;
  color: #080f6c;
`;

export const BudgetTotal = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #080f6c;
`;

export const TextDesc = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const NetworkName = styled(TextDesc)`
  font-family: "Rubik";
  font-size: 16px;
  letter-spacing: normal;
`;

export const PartTitle = styled.span`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #080f6c;
`;

export const PaymentSectionInner = styled.section`
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  padding: 33px 60px;
  display: flex;
  gap: 30px;
`;

export const StepTwoWrapper = styled.div``;
