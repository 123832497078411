import { ethers } from "ethers";
import axios from "axios";
import { ERC721ABI } from "./ABI";
import {
  ArbitrumGoerli,
  AuroraTestnet,
  Ether,
  Goerli,
  Mumbai,
} from "@usedapp/core";

const NETWORK = {
  ETH_MAINNET: "eth-mainnet",
  ETH_GOERLI: "eth-goerli",
  POLYGON_MUMBAI: "polygon-mumbai",
  AURORA_TESTNET: "aurora-testnet",
  ARBITRUM_GOERLI: "arb-goerli",
};

export const NETWORK_KEY = {
  [Ether.chainId]: "eth-mainnet",
  [Goerli.chainId]: "eth-goerli",
  [Mumbai.chainId]: "polygon-mumbai",
  [AuroraTestnet.chainId]: "aurora-testnet",
  [ArbitrumGoerli.chainId]: "arb-goerli",
};

const NETWORK_RPC_URL = {
  [Ether.chainId]: `https://${NETWORK.ETH_MAINNET}.alchemyapi.io/v2/lIui4Z1fgyWQiBk4gD84YTbD6qmWiYy9`,
  [Goerli.chainId]: `https://${NETWORK.ETH_GOERLI}.g.alchemy.com/v2/lIui4Z1fgyWQiBk4gD84YTbD6qmWiYy9`,
  [Mumbai.chainId]: `https://${NETWORK.POLYGON_MUMBAI}.g.alchemy.com/v2/lIui4Z1fgyWQiBk4gD84YTbD6qmWiYy9`,
  [AuroraTestnet.chainId]: "https://testnet.aurora.dev",
  [ArbitrumGoerli.chainId]: `https://${NETWORK.ARBITRUM_GOERLI}.g.alchemy.com/v2/lIui4Z1fgyWQiBk4gD84YTbD6qmWiYy9`,
};

export const getTokensOfAccount = async (
  network,
  contractAddress,
  accountAddress
) => {
  const RPC_URL = NETWORK_RPC_URL[network];
  const provider = new ethers.providers.JsonRpcProvider(RPC_URL);

  const contract = new ethers.Contract(contractAddress, ERC721ABI, provider);

  const contractName = await contract.name();

  const fromFilter = contract.filters.Transfer(null, accountAddress);
  const sendFilter = contract.filters.Transfer(accountAddress, null);

  const eventsReceived = await contract.queryFilter(fromFilter, 0);
  const eventsSend = await contract.queryFilter(sendFilter, 0);

  const receiveTokenIds = eventsReceived.map((item: ethers.Event) => {
    return item.args?.tokenId.toNumber();
  });

  const sendTokenIds = eventsSend.map((item) => {
    return item.args?.tokenId.toNumber();
  });

  const accountTokens: any[] = [];
  for (let i = 0; i < receiveTokenIds.length; i++) {
    if (!sendTokenIds.includes(receiveTokenIds[i])) {
      const tokenUri = await contract.tokenURI(receiveTokenIds[i]);
      const { data } = await axios.get(
        tokenUri.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
      );
      accountTokens.push({
        tokenId: receiveTokenIds[i],
        tokenUri,
        title: `${contractName} #${receiveTokenIds[i]}`,
        ...data,
      });
    }
  }
  return accountTokens;
};

export const getByAlchemyAPI = async (
  network,
  contractAddress,
  accountAddress
) => {
  const url = NETWORK_RPC_URL[network];

  const options = {
    method: "GET",
    url,
    params: {
      owner: accountAddress,
      contractAddresses: [contractAddress],
    },
    headers: {
      accept: "application/json",
      "X-API-Key":
        "weE1x8tU9IecncVSIK3t2SGLRu9edvIUuXjNAXze4WopAlXspAYAEuZju0xgJbnF",
    },
  };

  const { data } = await axios.request(options);
  return data?.ownedNfts;
};
