import { Fragment } from "react";
import { regUrl } from "./regex";

export function splitDescription(text: string) {
  let replaced = text.replaceAll("\r", "");
  return replaced.split("\n");
}

export function urlText(text: string) {
  const regexText = text.match(regUrl);
  if (regexText) {
    regexText.forEach((t: string) => (text = text.replace(t, "-url-")));
    const textArr = text.split("-url-");
    return (
      <>
        {textArr[0]}
        {regexText.map((url: string, i: number) => (
          <Fragment key={i}>
            <a href={url}>{url}</a>
            {textArr[i + 1]}
          </Fragment>
        ))}
      </>
    );
  }
  return text;
}

export function elementing(text: string) {
  if (/^#+/.test(text))
    return <span className="text-bold">{text.replace(/^#+/, "")}</span>;
  if (/^[0-9]+\./.test(text))
    return <span className="child-text">{urlText(text)}</span>;
  if (/^- \[X\]/.test(text))
    return (
      <span className="child-text">
        <input type="checkbox" disabled checked />
        {urlText(text.slice(5))}
      </span>
    );
  if (/^- /.test(text))
    return <span className="child-text">{urlText(text.slice(2))}</span>;
  return urlText(text);
}
