import { Form, message, Tabs } from "antd";
import { FC, memo } from "react";
import { IResetPasswordProps } from "./resetPassword.props";
import {
  Container,
  ForgotPasswordButton,
  FormPasswordInput,
  FormTitle,
  Image,
  ImageContainer,
  ModalFormItem,
  SignUpFormContainer,
  SignUpItemContainer,
  SignUpLabel,
  SignUpLabelForgotPassword,
  SubmitButton,
  TabContainner,
} from "./resetPassword.style";
import image from "assets/images/signup-image.png";
import { maxLength_128 } from "utils/validatorRules";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { IResetPasswordParams, resetPasswordAPI } from "api/auth";
import { useMutation } from "@tanstack/react-query";

const ResetPassword: FC<IResetPasswordProps> = (props: IResetPasswordProps) => {
  const navigate = useNavigate();
  let { resetToken } = useParams();

  const { mutate: resetPassword } = useMutation(resetPasswordAPI, {
    onSuccess: (res: any) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 408) {
        message.error(responseMessage);
      } else {
        navigate("/log-in");
        message.success(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const onClickReset = (values: IResetPasswordParams) => {
    resetPassword({
      ...(values as IResetPasswordParams),
      resetToken: resetToken || "",
    });
  };

  return (
    <Container>
      <ImageContainer>
        <Image src={image} alt="" />
      </ImageContainer>
      <SignUpFormContainer>
        <FormTitle>Reset Password</FormTitle>
        <TabContainner defaultActiveKey="1">
          <Tabs.TabPane tab={"Reset Password"} key="1">
            <Form
              name="signUp"
              wrapperCol={{ span: 32 }}
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onClickReset}
            >
              <SignUpItemContainer>
                <SignUpLabel>Create New Password</SignUpLabel>
                <ModalFormItem
                  name="password"
                  labelAlign="left"
                  hasFeedback
                  rules={[
                    maxLength_128,
                    { min: 8, message: "Minimum 8 characters" },
                    { required: true, message: "Required" },
                    {
                      validator: (_: any, value: string) => {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!/(?=.*?[A-Z])/.test(value)) {
                          return Promise.reject(
                            new Error(
                              "At least one capital (uppercase) letter!"
                            )
                          );
                        }
                        if (!/(?=.*?[a-z])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one lowercase letter!")
                          );
                        }
                        if (!/(?=.*?[0-9])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one digit!")
                          );
                        }
                        if (!/(?=.*?[#?!@$%^&*-])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one special character!")
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <FormPasswordInput placeholder="Create Password" />
                </ModalFormItem>
              </SignUpItemContainer>

              <SignUpItemContainer>
                <SignUpLabel>Re-enter Password</SignUpLabel>
                <ModalFormItem
                  name="reEnterPassword"
                  labelAlign="left"
                  hasFeedback
                  rules={[
                    maxLength_128,
                    { min: 8, message: "Minimum 8 characters" },
                    { required: true, message: "Required" },
                    {
                      validator: (_: any, value: string) => {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!/(?=.*?[A-Z])/.test(value)) {
                          return Promise.reject(
                            new Error(
                              "At least one capital (uppercase) letter!"
                            )
                          );
                        }
                        if (!/(?=.*?[a-z])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one lowercase letter!")
                          );
                        }
                        if (!/(?=.*?[0-9])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one digit!")
                          );
                        }
                        if (!/(?=.*?[#?!@$%^&*-])/.test(value)) {
                          return Promise.reject(
                            new Error("At least one special character!")
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <FormPasswordInput placeholder="Re-enter Password" />
                </ModalFormItem>
                <ForgotPasswordButton>
                  <SignUpLabelForgotPassword>
                    {/* Forgot password? */}
                  </SignUpLabelForgotPassword>
                </ForgotPasswordButton>
              </SignUpItemContainer>

              <SignUpItemContainer>
                <SubmitButton htmlType="submit">
                  Create New Password
                </SubmitButton>
              </SignUpItemContainer>
            </Form>
          </Tabs.TabPane>
        </TabContainner>
      </SignUpFormContainer>
    </Container>
  );
};

export default memo(ResetPassword);
