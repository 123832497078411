import { Button } from "antd";
import OutlinedButton from "components/base/outlinedButton";
import TagWithIcon from "components/base/tagWithIcon";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 38px;
  .ant-tree {
    min-width: 280px;
    width: 280px;
  }
  .ant-tree-treenode::before {
    height: 100%;
  }
  .ant-tree-treenode-selected:before {
    background: rgba(156, 138, 193, 0.16) !important;
    height: 100%;
    .ant-tree-node-selected {
      background-color: unset;
    }
  }
  .ant-tree-treenode-selected .ant-tree-switcher {
    color: unset !important;
  }

  .ant-tree-node-selected {
    color: #7e00fd !important;
  }

  .section,
  .activity {
    /* padding-bottom: unset; */
    padding: 11px 12px;
    width: 100%;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    cursor: pointer;
    .ant-tree-iconEle {
      display: none;
    }
  }
  .section {
    .ant-tree-switcher {
      display: flex;
      align-items: center;
      width: fit-content;
      .anticon > svg {
        height: 15px;
        width: 15px;
      }
    }
    .ant-tree-node-content-wrapper {
      flex-grow: 1;
      min-width: 0;
      display: flex;
      align-items: center;

      span.ant-tree-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 115%;
        color: #080f6c;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .activity {
    padding-left: 24px;
  }
  .activity > .ant-tree-node-content-wrapper {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    align-items: center;

    span.ant-tree-title {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: -0.02em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .activity {
    .ant-tree-indent {
      display: none;
    }
  }
`;

export const WrapBlock = styled.div`
  height: 22px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(8, 15, 108, 0.12);
  border-radius: 20px;
`;

export const Content = styled.div`
  min-height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #080f6c;
    word-break: break-all;
  }
`;

export const Previous = styled(OutlinedButton)`
  gap: 10px;
  height: 40px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  &[disabled],
  &[disabled]:hover {
    color: #9c8ac1;
  }
  width: fit-content;
`;

export const SubmitOrNext = styled(OutlinedButton)`
  gap: 10px;
  height: 40px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  &[disabled],
  &[disabled]:hover {
    color: #9c8ac1;
  }
  width: fit-content;
  display: flex;
  flex-direction: row-reverse;
`;

export const TagWithIconStyled = styled(TagWithIcon)``;

export const WrapFoot = styled.div`
  /* display: flex; */
  /*justify-content: space-between;
  align-items: center; */
  & > ${Previous} {
    float: left;
  }
  position: relative;
  & > ${TagWithIconStyled} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > ${SubmitOrNext} {
    float: right;
  }
`;

export const EditButton = styled(OutlinedButton)`
  color: #7e00fd !important;
  font-weight: 500;
  line-height: 24px;
  svg {
    margin-right: 4px;
  }
  width: min-content;
`;

export const WrapTree = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WrapButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  position: relative;
  ::after {
    position: absolute;
    width: 24px;
    height: 0px;
    border: 1px solid rgba(156, 138, 193, 0.16);
    transform: rotate(90deg);
    content: "";
    left: 73px;
    top: 50%;
  }
`;

export const BackAndNext = styled.div`
  display: flex;
  button {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #080f6c;
    width: min-content;
    span {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 115%;
    }
  }
`;

export const PreviousActive = styled(Button)<{ disabled: boolean }>`
  ${(p) => {
    return p.disabled
      ? `  svg>path {
    stroke: rgba(0,0,0,.25);
  }`
      : `  svg>path {
    stroke: #080f6c;
  }`;
  }}
`;
export const NextActive = styled(Button)`
  ${(p) => {
    return p.disabled
      ? `  svg>path {
    stroke: rgba(0,0,0,.25);
  }`
      : `  svg>path {
    stroke: #080f6c;
  }`;
  }}
  flex-direction: row-reverse;
`;

export const CkContentWrapper = styled.div`
  word-break: break-all;
`;
