import { FC } from 'react'
import IStyledModalProps from './commonModal.props'
import { StyledModal } from './commonModal.style'
import { ModalMaskStyle } from './commonModal.style'

const CommonModal: FC<IStyledModalProps> = ({
  children,
  ...props
}: IStyledModalProps) => {
  return (
    <StyledModal
      maskStyle={ModalMaskStyle}
      footer={null}
      closable={false}
      {...props}
    >
      {children}
    </StyledModal>
  )
}

export default CommonModal
