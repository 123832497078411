import styled from "styled-components";

export const Container = styled.div<{ show: boolean }>`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${({ show }: any) => {
    return show
      ? `
      opacity: 1;
      z-index: 100;
    `
      : `
      opacity: 0;
      z-index: -1;
    `;
  }}
  background: radial-gradient( 100% 445.32% at 0% 100%, #7e00fd 0%, #b93df3 42.35%, #a54bf4 57.95%, #00bffb 100% );
  transition: all 2s;
`;
