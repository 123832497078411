import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Avatar, message } from "antd";
import {
  getProjectDetails,
  getUserRequestReceivedAPI,
  IProject,
  updateJoinRequestAPI,
} from "api/users";
import { AxiosError, AxiosResponse } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { BlockchainNetworks, VERIFICATION_STATUS } from "utils/constant";
import { addCollaboratorSC, handleMessageErrorSC } from "web3/contract";
import {
  ActionPlan,
  BlockHead,
  BlockWrap,
  CollaboratorRequestWrap,
  EmptyText,
  EndContractButton,
  Item,
  ItemWrap,
  Name,
  SendMessageButton,
} from "../../ProjectDetail.style";
import { ICollaboratorRequestProps } from "./CollaboratorRequest.props";
import { setPageLoading } from "redux/slices";
import { Heading } from "pages/CreateProject/createProject.style";
import { handleLongText } from "utils/text";
import { parseUnits } from "ethers/lib/utils";
import { useCheckWalletAndSwitchNetwork } from "hooks";

const CollaboratorRequest: FC<ICollaboratorRequestProps> = ({
  ...props
}: ICollaboratorRequestProps): ReactElement => {
  const userId: string = useAppSelector((state) => state.auth.user?._id);
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();
  const [joinRequests, setJoinRequest] = useState([] as any);
  const [project, setProject] = useState<IProject>();
  // const [statusRequest, setStatusRequest] = useState("ACTIVE");

  useQuery(
    [`getProjectDetail-${param.projectId}`],
    () => getProjectDetails(param.projectId || ""),
    {
      onSuccess: (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setProject(res.responseData);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
    }
  );

  useEffect(() => {
    if (!project) return;

    if (project?.initiator !== userId) {
      message.error("You are not the project creator!");
      navigate("/");
    }
  }, [navigate, project, userId]);

  useQuery(
    [`getPackageRequest-${param.packageId}`],
    () =>
      getUserRequestReceivedAPI(
        param.projectId || "",
        param.packageId || "",
        "ACTIVE"
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        if (res.responseCode === 200) {
          setJoinRequest(res.responseData?.records);
        }
      },
      refetchOnWindowFocus: false,
    }
  );
  const queryClient = useQueryClient();

  const { mutate: updateJoinRequest } = useMutation(updateJoinRequestAPI, {
    onSuccess: (res: any) => {
      const { responseCode, responseMessage } = res;
      if (responseCode === 200) {
        queryClient.invalidateQueries(["getPackageRequest"]);
        message.success(responseMessage);
      } else {
        message.error(responseMessage);
      }
    },
  });

  const handleClickApprove = async (
    userRequestId: string,
    walletAddress?: string
  ) => {
    if (!checkWallet()) return;
    // if (!account) {
    //   message.error("Please connect to wallet");
    //   return;
    // }
    // if (account !== userInfo?.walletAddress) {
    //   message.error("You are connecting to a different wallet");
    //   return;
    // }

    try {
      dispatch(setPageLoading(true));

      const projectChainId =
        BlockchainNetworks[project?.blockchainNetwork?.name!];
      await handleSwitchNetwork(projectChainId);
      // if (!chainId) {
      //   activateBrowserWallet({ type: connectedWalletName });
      // }

      // if (chainId !== projectChainId) {
      //   await switchNetwork(projectChainId);
      // }
      // changeNetwork(projectChainId);

      const scParams = {
        projectId: joinRequests?.scProjectId,
        packageId: joinRequests?.packages?.scPackageId,
        mgp: parseUnits(
          "" + +joinRequests?.packages?.minimumCost,
          joinRequests.decimal
        ).toString(),
        collaborator: walletAddress,
      };

      await addCollaboratorSC(scParams);
      updateJoinRequest({
        userId: userRequestId,
        projectId: param.projectId || "",
        packageId: param?.packageId || "",
        verificationStatus: VERIFICATION_STATUS.ACCEPTED,
      });
      dispatch(setPageLoading(false));
    } catch (err: any) {
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
    }
  };

  const handleClickReject = (userRequestId: string) => {
    updateJoinRequest({
      userId: userRequestId,
      projectId: param.projectId || "",
      packageId: param?.packageId || "",
      verificationStatus: VERIFICATION_STATUS.REJECTED,
    });
  };

  return (
    <>
      <Heading>
        <div>Review Applicants</div>
      </Heading>
      <CollaboratorRequestWrap>
        <BlockWrap>
          <BlockHead>
            <div>{joinRequests?.packages?.name}</div>
          </BlockHead>
          <ItemWrap>
            {joinRequests?.requestUsers?.length ? (
              joinRequests?.requestUsers?.map((item: any, i: any) => (
                <Item key={i}>
                  <div>
                    <Name>
                      <Avatar src={item?.user?.profilePicture} />
                      <div>{item?.user?.fullName || item?.user?.emailId}</div>
                    </Name>

                    <ActionPlan title={item?.actionPlan}>
                      {handleLongText(item?.actionPlan, 70)}
                    </ActionPlan>
                  </div>
                  <div>
                    <SendMessageButton
                      mr="20px"
                      onClick={() =>
                        handleClickApprove(
                          item.userId,
                          item?.user?.walletAddress
                        )
                      }
                    >
                      Approve Request
                    </SendMessageButton>
                    <EndContractButton
                      onClick={() => handleClickReject(item.userId)}
                    >
                      Reject Request
                    </EndContractButton>
                  </div>
                </Item>
              ))
            ) : (
              <EmptyText>No applicant requested</EmptyText>
            )}
          </ItemWrap>
        </BlockWrap>
      </CollaboratorRequestWrap>
    </>
  );
};

export default CollaboratorRequest;
