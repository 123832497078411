import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IFilter {
  seedSkip?: number;
  openBountiesSkip?: number;
  learnAndEarnSkip?: number;
  portfolioSkip?: number;
  limit?: number;
  search?: string;
  minPrice?: string;
  maxPrice?: string;
  projectStatus?: string;
  collaboratorLevel?: string;
  deliveryDate?: string;
  membersRange?: string[];
  issueTypes?: string[];
  filterType?: boolean;
}

export const initialFilterState: IFilter = {
  seedSkip: 0,
  openBountiesSkip: 0,
  learnAndEarnSkip: 0,
  portfolioSkip: 0,
  limit: 3,
  search: "",
  minPrice: "",
  maxPrice: "",
  projectStatus: "",
  collaboratorLevel: "",
  deliveryDate: "",
  membersRange: [],
  issueTypes: [],
  filterType: true,
};

export const filterSlice = createSlice({
  name: "filter",
  initialState: initialFilterState,
  reducers: {
    setFilter: (state, action: PayloadAction<IFilter>) => {
      if (typeof action.payload?.portfolioSkip === "number")
        state.portfolioSkip = action.payload?.portfolioSkip;

      if (typeof action.payload?.seedSkip === "number")
        state.seedSkip = action.payload?.seedSkip;
      if (typeof action.payload?.openBountiesSkip === "number")
        state.openBountiesSkip = action.payload?.openBountiesSkip;
      if (typeof action.payload?.learnAndEarnSkip === "number")
        state.learnAndEarnSkip = action.payload?.learnAndEarnSkip;
      if (typeof action.payload?.limit === "number")
        state.limit = action.payload?.limit;
      if (typeof action.payload?.search === "string")
        state.search = action.payload?.search;
      if (typeof action.payload?.minPrice === "string")
        state.minPrice = action.payload?.minPrice;
      if (typeof action.payload?.maxPrice === "string")
        state.maxPrice = action.payload?.maxPrice;
      if (typeof action.payload?.projectStatus === "string")
        state.projectStatus = action.payload?.projectStatus;
      if (typeof action.payload?.collaboratorLevel === "string")
        state.collaboratorLevel = action.payload?.collaboratorLevel;
      if (action.payload?.membersRange)
        state.membersRange = action.payload?.membersRange;
      if (action.payload?.issueTypes)
        state.issueTypes = action.payload?.issueTypes;
      if (typeof action.payload?.deliveryDate === "string")
        state.deliveryDate = action.payload?.deliveryDate;
      if (typeof action.payload?.filterType === "boolean")
        state.filterType = action.payload?.filterType;
    },
  },
});

export const { setFilter } = filterSlice.actions;

export default filterSlice.reducer;
