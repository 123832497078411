import { IBlog } from "api/blog";
import { BlogTag, TooltipStyled } from "./blog.style";

const Tags = ({ blog }: { blog: IBlog }) => {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {blog.categories.length! < 3 &&
        blog.categories.map((category) => (
          <BlogTag key={category._id}>{category.name}</BlogTag>
        ))}
      {blog.categories.length! > 2 && (
        <>
          <BlogTag>{blog.categories[0].name}</BlogTag>
          <BlogTag>{blog.categories[1].name}</BlogTag>
          <TooltipStyled
            title={
              <>
                {blog.categories.slice(2).map((category) => (
                  <BlogTag key={category._id}>{category.name}</BlogTag>
                ))}
              </>
            }
          >
            <BlogTag>+ {blog.categories.length! - 2}</BlogTag>
          </TooltipStyled>
        </>
      )}
    </div>
  );
};

export default Tags;
