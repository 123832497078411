import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, message, Popconfirm } from "antd";
import {
  createFAQAPI,
  deleteFAQAPI,
  getFAQsAPI,
  updateFAQAPI,
} from "api/users";
// import { AddFAQIcon, CloseFAQIcon } from "assets/icons/common/addFaq.icon";
import { AxiosError } from "axios";
import CommonModal from "components/base/commonModal";
import PrimaryButton from "components/base/primaryButton";
import {
  DeleteButton,
  EditButton,
} from "pages/ProfileUser/components/ProjectHistory/projectHistory.style";
import { EmptyText } from "pages/ProjectDetail/ProjectDetail.style";
import { FC, memo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { requiredRules } from "utils/validatorRules";
import { IFAQParams, IFAQProps } from "./FAQ.props";
import {
  ActionButtonGroup,
  AddFAQButton,
  Answer,
  Container,
  FAQForm,
  InputEmail,
  ModalTitle,
  StyledCollapse,
} from "./FAQ.style";

const FAQ: FC<IFAQProps> = ({ isProjectCreator }) => {
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [updateFAQ, setUpdateFAQ] = useState<IFAQParams>();
  const pageParams = useParams();
  const [form] = Form.useForm();

  const toggleShow = () => {
    setShow(!show);
    if (show) form.resetFields();
    else form.resetFields();
  };
  const handleSubmit = (params: any) => {
    if (isEdit) {
      const payload = {
        id: id,
        projectId: pageParams?.id || "",
        faqId: updateFAQ?._id as string,
        body: params,
      };

      updatingFAQ(payload);
    } else {
      const payload = {
        id: id,
        projectId: pageParams?.id || "",
        body: params,
      };

      createFAQ(payload);
    }
  };

  const handleDelete = (faqId: string) => {
    const param = {
      id: id,
      projectId: pageParams?.id || "",
      faqId,
    };

    deleteFAQ(param);
  };

  const [faqData, setFaqData] = useState<IFAQParams[]>([]);

  const id: string = useAppSelector((state) => state.auth.user?._id);

  const { mutate: createFAQ } = useMutation(createFAQAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage, responseData } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        form.resetFields();
        setFaqData(responseData);
        toggleShow();
        setIsEdit(false);
      } else {
        message.error(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  const { mutate: updatingFAQ } = useMutation(updateFAQAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage, responseData } = res;
      if (responseCode === 200) {
        message.success(responseMessage);
        form.resetFields();

        setFaqData(responseData);

        toggleShow();
        setIsEdit(false);
      } else {
        message.error(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  useQuery(["getFAQs"], () => getFAQsAPI(pageParams?.id || ""), {
    onSuccess: (res) => {
      setFaqData(res.responseData.records);
      // console.log("skldflsfl", res.responseData.records);
    },
    refetchOnWindowFocus: false,
  });

  const { mutate: deleteFAQ } = useMutation(deleteFAQAPI, {
    onSuccess: (res) => {
      const { responseCode, responseMessage, responseData } = res;
      if (responseCode === 200) {
        message.success("Delete FAQ successfully");
        setFaqData(responseData);
      } else {
        message.error(responseMessage);
      }
    },
    onError: (err: AxiosError) => {
      const rs: any = err?.response?.data;
      message.error(rs?.message);
    },
  });

  return (
    <Container>
      {isProjectCreator && (
        <AddFAQButton
          width="138px"
          height="48px"
          onClick={() => {
            toggleShow();
            setIsEdit(false);
          }}
        >
          Add a new FAQ
        </AddFAQButton>
      )}
      {faqData.length > 0 ? (
        <StyledCollapse bordered={false} expandIconPosition="end">
          {faqData.map((faq) => {
            return (
              <StyledCollapse.Panel
                header={faq?.question}
                key={faq?._id + faq?.question + faq?.answer}
              >
                <Answer>{faq?.answer}</Answer>
                <ActionButtonGroup>
                  <EditButton
                    onClick={() => {
                      const item = faqData.find(
                        (item) => item._id === faq?._id
                      );
                      setUpdateFAQ(item);
                      toggleShow();
                      form.setFieldValue("question", item?.question);
                      form.setFieldValue("answer", item?.answer);
                      setIsEdit(true);
                    }}
                    disabled={!isProjectCreator}
                  >
                    Edit FAQ
                  </EditButton>
                  <Popconfirm
                    title="Are you sure you want to remove the FAQ?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleDelete(faq?._id)}
                  >
                    <DeleteButton disabled={!isProjectCreator}>
                      Remove FAQ
                    </DeleteButton>
                  </Popconfirm>
                </ActionButtonGroup>
              </StyledCollapse.Panel>
            );
          })}
        </StyledCollapse>
      ) : (
        <EmptyText>No FAQ added</EmptyText>
      )}

      <CommonModal
        footer={null}
        visible={show}
        centered
        width={462}
        closable
        onCancel={toggleShow}
      >
        <ModalTitle>{isEdit ? "Edit FAQ" : "Add FAQ"}</ModalTitle>
        <FAQForm form={form} onFinish={handleSubmit}>
          <Form.Item
            name="question"
            label={<div>Questions</div>}
            rules={[requiredRules]}
          >
            <InputEmail placeholder={"Describe your questions..."} />
          </Form.Item>
          <Form.Item
            name="answer"
            label={<div>Answers</div>}
            rules={[requiredRules]}
          >
            <InputEmail placeholder={"Describe your answer..."} />
          </Form.Item>
          <PrimaryButton height="40px" width="350px" htmlType="submit">
            {isEdit ? "Edit FAQ" : "Add as a new FAQ"}
          </PrimaryButton>
        </FAQForm>
      </CommonModal>
    </Container>
  );
};

export default memo(FAQ);
