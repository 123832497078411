import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { getDataMastersAPI, ISkill } from "api/users";
import { BuilderSkillIcon } from "assets/icons/common/builderSkill.icon";
import { AxiosError } from "axios";
import { FormSectionWrapper } from "pages/ProfileUser/profileUserInner.style";
import { FC, useEffect, useState } from "react";
import { maxLength_255, stringRequired } from "utils/validatorRules";
import SectionHeader from "../SectionHeader";
import { IBuilderSkillProps } from "./builderSkill.props";
import {
  BuilderKindGrid,
  BuilderLeft,
  BuilderRight,
  BuilderSkillContainer,
  BuilderWrapper,
  CustomSkillBtn,
  CustomSkillWrapper,
  Description,
  KindSelect,
  KindText,
  LevelRow,
  LevelSelect,
  StyledFormItem,
  StyledInput,
  StyledSelect,
  StyledTextArea,
} from "./builderSkill.style";

const levels = ["Beginner", "Intermediate", "Professional", "Expert"];
const buildingExpOptions = [
  "1 Year",
  "2 Years",
  "3 Years",
  "4 Years",
  "5 Years",
  "More than 5 years",
];

export const BuilderSkill: FC<IBuilderSkillProps> = ({
  form,
  profile,
  setLeaveVisible,
}) => {
  // const builderSkillFromApi = form.getFieldsValue(["builderSkill"]);
  // const [isLoadingSkillIdsDone, setIsLoadingSkillIdsDone] = useState(false);
  const [kinds, setKinds] = useState<
    (ISkill & {
      level: string;
    })[]
  >([]);
  const [customSkillOpen, setCustomSkillOpen] = useState(false);
  const [customSkillName, setCustomSKillName] = useState("");
  const [customSkillData, setCustomSkillData] = useState<string[]>([]);
  const [customKinds, setCustomKinds] = useState<
    { skillName: string; level: string }[]
  >([]);
  const { data: masterSkillRes } = useQuery(
    ["getDataSkills"],
    () => getDataMastersAPI({ type: "Skills" }),
    {
      onSuccess: (res) => {
        if (res.responseCode === 200) {
          const masterSkillData = res.responseData.values;
          const selectedSkills:
            | {
                _id: string;
                skillId: string;
                level: string;
              }[]
            | undefined = form.getFieldValue(["builderSkill", "skillIds"]);

          if (selectedSkills && selectedSkills?.length > 0) {
            const formattedSkills = selectedSkills.map((item) => {
              const foundSkillInMasterSkillData = masterSkillData?.find(
                (masterSkill: any) => masterSkill?._id === item.skillId
              );

              return {
                ...foundSkillInMasterSkillData,
                level: item.level,
              };
            });

            setKinds(formattedSkills);
          }
          // setBuilderKinds(masterSkillData);
        }
      },
      onError: (err: AxiosError) => {
        const rs: any = err?.response?.data;
        message.error(rs?.message);
      },
      refetchOnWindowFocus: false,
      enabled: !!profile,
    }
  );

  const builderKinds: ISkill[] = masterSkillRes?.responseData?.values || [
    {
      _id: "6343c2e7492eab96099f691a",
      name: "Founder",
    },
    {
      _id: "6343c2e7492eab96099f691b",
      name: "Front End Engineer",
    },
    {
      _id: "6343c2e7492eab96099f691c",
      name: "Back End Engineer",
    },
    {
      _id: "6343c2e7492eab96099f691d",
      name: "Solana Engineer",
    },
    {
      _id: "6343c2e7492eab96099f691e",
      name: "Full Stack Web3 Engineer",
    },
    {
      _id: "6343c2e7492eab96099f691f",
      name: "Data Engineer",
    },
    {
      _id: "6343c2e7492eab96099f6920",
      name: "DevOps",
    },
    {
      _id: "6343c2e7492eab96099f6921",
      name: "Product Designer",
    },
    {
      _id: "6343c2e7492eab96099f6922",
      name: "UI/UX",
    },
    {
      _id: "6343c2e7492eab96099f6923",
      name: "Solidity Engineer",
    },
    {
      _id: "6343c2e7492eab96099f6924",
      name: "Full Stack Engineer",
    },
    {
      _id: "6343c2e7492eab96099f6925",
      name: "Marketing / Growth",
    },
    {
      _id: "6343c2e7492eab96099f6926",
      name: "DAO Contributor",
    },
    {
      _id: "6343c2e7492eab96099f6927",
      name: "Game Developer",
    },
    {
      _id: "6343c2e7492eab96099f6928",
      name: "Blockchain Developer",
    },
  ];

  // Get skill list from table User
  // useEffect(() => {
  //   const { builderSkill } = builderSkillFromApi;
  //   if (
  //     builderSkill?.skillIds?.length > 0 &&
  //     builderKinds.length > 0 &&
  //     !isLoadingSkillIdsDone
  //   ) {
  //     const kinds = builderKinds.filter((kind) =>
  //       builderSkill.skillIds.includes(kind._id)
  //     );
  //     setKinds(kinds);
  //     setLevel(builderSkill.level);
  //     setIsLoadingSkillIdsDone(true);
  //   }
  // }, [builderSkillFromApi, builderKinds, isLoadingSkillIdsDone]);

  // Set fields values for skillIds and level for submiting
  // useEffect(() => {
  //   const values = {
  //     builderSkill: {
  //       skillIds: kinds.map((kind) => kind._id),
  //       level: level,
  //     },
  //   };
  //   form.setFieldsValue(values);
  // }, [kinds, level, form]);

  const handleSelectKind = (kind: ISkill) => {
    if (kinds.some((k) => k._id === kind._id)) {
      const newKinds = kinds.filter((item) => item._id !== kind._id);
      const values = {
        builderSkill: {
          skillIds: newKinds.map((kind) => ({
            skillId: kind._id,
            level: kind.level,
          })),
        },
      };

      setKinds(newKinds);
      form.setFieldsValue(values);
      setLeaveVisible(true);
    } else if (customKinds.length + kinds.length !== 3) {
      const newKinds = [
        ...kinds,
        {
          ...kind,
          level: "Beginner",
        },
      ];
      const values = {
        builderSkill: {
          skillIds: newKinds.map((kind) => ({
            skillId: kind._id,
            level: kind.level,
          })),
        },
      };

      setKinds(newKinds);
      form.setFieldsValue(values);
      setLeaveVisible(true);
    }
    // if (kinds.includes(kind)) {
    //   setKinds(kinds.filter((item) => item !== kind));
    // } else {
    //   if (kinds.length !== 3) setKinds([...kinds, kind]);
    // }
  };

  const handleSelectCustomKind = (cusKind: string) => {
    if (customKinds.some((sk) => sk?.skillName && sk.skillName === cusKind)) {
      const newCustomKinds = customKinds.filter(
        (watchedSk) => watchedSk?.skillName !== cusKind
      );
      const values = {
        builderSkill: {
          customSkillIds: newCustomKinds,
        },
      };

      setCustomKinds(newCustomKinds);
      form.setFieldsValue(values);
      setLeaveVisible(true);
    } else if (customKinds.length + kinds.length !== 3) {
      const newCustomKinds = [
        ...customKinds,
        {
          skillName: cusKind,
          level: "Beginner",
        },
      ];
      const values = {
        builderSkill: {
          customSkillIds: newCustomKinds,
        },
      };

      setCustomKinds(newCustomKinds);
      form.setFieldsValue(values);
      setLeaveVisible(true);
    }
  };

  const handleSelectCustomLevel = (
    chosenLevel: string,
    selectedKind: string
  ) => {
    const newCustomKinds = customKinds.map((k) => {
      if (k.skillName === selectedKind) {
        return {
          ...k,
          level: chosenLevel,
        };
      }

      return k;
    });
    const values = {
      builderSkill: {
        customSkillIds: newCustomKinds,
      },
    };

    setCustomKinds(newCustomKinds);
    form.setFieldsValue(values);
    setLeaveVisible(true);
  };

  const handleSelectLevel = (chosenLevel: string, selectedKind: string) => {
    const newKinds = kinds.map((k) => {
      if (k._id === selectedKind) {
        return {
          ...k,
          level: chosenLevel,
        };
      }

      return k;
    });
    const values = {
      builderSkill: {
        skillIds: newKinds.map((kind) => ({
          skillId: kind._id,
          level: kind.level,
        })),
      },
    };

    setKinds(newKinds);
    form.setFieldsValue(values);
    setLeaveVisible(true);
  };

  const handleAddCustomSkill = () => {
    const currSkillNameLen = customSkillName.trim().length;
    const nameToCompare = customSkillName.trim().toLowerCase();

    if (currSkillNameLen === 0) {
      message.error("Can't be blank");
      return;
    }

    if (currSkillNameLen > 30) {
      message.error("Maximum is 30 characters");
      return;
    }

    if (
      customSkillData.some((data) => data.toLowerCase() === nameToCompare) ||
      builderKinds.some((val) => val.name.toLowerCase() === nameToCompare)
    ) {
      message.error("Your skill is duplicated");
      return;
    }

    setCustomSkillData((prev) => [...prev, customSkillName]);
    setCustomSKillName("");
    setCustomSkillOpen(false);
  };

  const handleOpenCustomSkill = () => {
    if (customSkillData.length < 3) {
      setCustomSkillOpen(true);
    }
  };

  useEffect(() => {
    if (profile?._id) {
      const customSkills = form.getFieldValue([
        "builderSkill",
        "customSkillIds",
      ]);

      if (customSkills) {
        setCustomSkillData(customSkills.map((cusSk) => cusSk.skillName));
        setCustomKinds(customSkills);
      }
    }
  }, [form, profile?._id]);

  return (
    <FormSectionWrapper id="Your skills as builder">
      <SectionHeader
        icon={<BuilderSkillIcon />}
        title="Your skills as builder"
      />
      <BuilderSkillContainer>
        <div>
          <StyledFormItem
            name={["builderSkill", "time"]}
            label={<span>How long have you been building?</span>}
          >
            <StyledSelect
              getPopupContainer={(node) => node?.parentNode as HTMLElement}
            >
              {buildingExpOptions.map((val) => (
                <StyledSelect.Option key={val}>{val}</StyledSelect.Option>
              ))}
            </StyledSelect>
          </StyledFormItem>
          <StyledFormItem
            name={["builderSkill", "specialty"]}
            label={<span>What are your specialities?</span>}
            rules={[maxLength_255, stringRequired]}
          >
            <StyledTextArea placeholder="Please put a comma between your specialties. Ex. Figma, HTML" />
          </StyledFormItem>
        </div>
        <BuilderWrapper>
          <BuilderLeft>
            <Description color="#080f6c">
              What kind of builder are you?
            </Description>
            <Description>
              This is what's gonna show up on your profile! Select up to 3
            </Description>
          </BuilderLeft>
          <BuilderRight>
            <CustomSkillBtn
              disabled={customSkillData.length === 3}
              onClick={handleOpenCustomSkill}
            >
              Add custom skill
            </CustomSkillBtn>
          </BuilderRight>
        </BuilderWrapper>
        {customSkillOpen && (
          <CustomSkillWrapper>
            <StyledInput
              value={customSkillName}
              onChange={(e) => {
                setCustomSKillName(e.target.value);
                setLeaveVisible(true);
              }}
              placeholder="Enter your custom skill name"
            />
            <CustomSkillBtn onClick={handleAddCustomSkill}>Add</CustomSkillBtn>
          </CustomSkillWrapper>
        )}
        <StyledFormItem name={["builderSkill", "skillIds"]}>
          <BuilderKindGrid>
            {builderKinds.map((kind) => (
              <KindSelect
                key={kind._id}
                onClick={() => handleSelectKind(kind)}
                active={kinds.some((k) => k._id === kind._id)}
              >
                {kind.name}
              </KindSelect>
            ))}
            {customSkillData.map((customK) => (
              <KindSelect
                onClick={() => handleSelectCustomKind(customK)}
                active={customKinds.some((k) => k.skillName === customK)}
                key={customK}
              >
                {customK}
              </KindSelect>
            ))}
          </BuilderKindGrid>
        </StyledFormItem>
        {(kinds?.length > 0 || customKinds.length > 0) && (
          <>
            <Description color="#080f6c">So, how good are you?</Description>
            {kinds.map((k: any, i: number) => {
              return (
                <LevelRow key={k._id || i}>
                  <div>
                    <KindText>{k.name}</KindText>
                  </div>
                  <div>
                    {levels.map((item) => (
                      <LevelSelect
                        key={item}
                        active={item === k.level}
                        onClick={() => handleSelectLevel(item, k._id)}
                      >
                        {item}
                      </LevelSelect>
                    ))}
                  </div>
                </LevelRow>
              );
            })}
            {customKinds.map((k, i) => {
              return (
                <LevelRow key={i}>
                  <div>
                    <KindText>{k.skillName}</KindText>
                  </div>
                  <div>
                    {levels.map((item) => (
                      <LevelSelect
                        key={item}
                        active={item === k.level}
                        onClick={() =>
                          handleSelectCustomLevel(item, k.skillName)
                        }
                      >
                        {item}
                      </LevelSelect>
                    ))}
                  </div>
                </LevelRow>
              );
            })}
          </>
        )}
      </BuilderSkillContainer>
    </FormSectionWrapper>
  );
};
