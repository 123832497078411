export const CameraIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.3337 31.6667C38.3337 32.5507 37.9825 33.3986 37.3573 34.0237C36.7322 34.6488 35.8844 35 35.0003 35H5.00033C4.11627 35 3.26842 34.6488 2.6433 34.0237C2.01818 33.3986 1.66699 32.5507 1.66699 31.6667V13.3333C1.66699 12.4493 2.01818 11.6014 2.6433 10.9763C3.26842 10.3512 4.11627 10 5.00033 10H11.667L15.0003 5H25.0003L28.3337 10H35.0003C35.8844 10 36.7322 10.3512 37.3573 10.9763C37.9825 11.6014 38.3337 12.4493 38.3337 13.3333V31.6667Z"
      stroke="#080F6C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9997 28.3333C23.6816 28.3333 26.6663 25.3486 26.6663 21.6667C26.6663 17.9848 23.6816 15 19.9997 15C16.3178 15 13.333 17.9848 13.333 21.6667C13.333 25.3486 16.3178 28.3333 19.9997 28.3333Z"
      stroke="#080F6C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
