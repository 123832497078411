import { Form, message } from "antd";
import { InviteIcon } from "assets/icons/common/invite.icon";
import FlexBox from "components/base/Flexbox";
import PrimaryButton from "components/base/primaryButton";
import {
  InviteContainer,
  StyledInput,
  Description,
  Divider,
  MiddleText,
  CopyText,
  UserTable,
  ReferralTitle,
} from "./invite.style";
import { CopyIcon } from "assets/icons/common/copy.icon";
import { getReferralListAPI, inviteFriendAPI } from "api/users";
import { useMutation, useQuery } from "@tanstack/react-query";
import { emailRules, requiredRules } from "utils/validatorRules";
import SectionHeader from "../SectionHeader";
import { FormSectionWrapper } from "pages/ProfileUser/profileUserInner.style";
import { IReferralUser } from "api/auth";
import { ColumnsType, TableProps } from "antd/lib/table";
import { useState } from "react";
import moment from "moment";
import { useAppSelector } from "redux/hooks";

const { Item: FormItem } = Form;

const PAGE_SIZE = 10;

export const Invite = () => {
  const [form] = Form.useForm();
  const referralCode: string = useAppSelector(
    (state) => state.auth.user?.myReferralCode
  );
  const { mutate: inviteFriend } = useMutation(inviteFriendAPI);

  const { data } = useQuery(["getReferralListAPI"], getReferralListAPI, {
    refetchOnWindowFocus: false,
  });

  const [page, setPage] = useState<number>(1);

  const copyText = (event: any, link: string) => {
    const el = document.createElement("input");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    message.success("Copied");
    // setCopiedLink(el);
  };

  const handleSend = async (values: { userEmails: string }) => {
    inviteFriend(
      { ...values },
      {
        onSuccess: (res) => {
          const { responseCode, responseMessage } = res;
          if (responseCode === 200) {
            message.success(responseMessage);
            form.resetFields();
          } else {
            message.error(responseMessage);
          }
        },
        onError: (err) => {
          console.error(err);
        },
      }
    );
  };

  const listReferralUser: IReferralUser[] = data?.responseData;

  const columns: ColumnsType<any> = [
    {
      title: <h1>No. </h1>,
      ellipsis: true,
      render: (value, record: IReferralUser, index) => index + 1,
      width: "15%",
    },
    {
      title: <h1>Email / Username </h1>,
      dataIndex: "emailId",
      key: "emailColumn",
      ellipsis: true,
      render: (value, record: IReferralUser) => (
        <a href={`/profile-preview?id=${record?._id}`}>
          {record?.fullName ?? value}
        </a>
      ),
      width: "50%",
    },
    {
      title: <h1>Register at</h1>,
      dataIndex: "registerAt",
      render: (value) => <>{moment(value).format("YYYY-MM-DD")}</>,
      key: "registerAt",
    },
  ];

  const onChange: TableProps<any>["onChange"] = (pagination) => {
    setPage(pagination.current ? pagination.current : 1);
  };

  return (
    <FormSectionWrapper id="Invite Friends">
      <SectionHeader icon={<InviteIcon />} title="Invite Friends" />
      <InviteContainer>
        <Description>Invite your</Description>
        <Description>friends or family members here.</Description>
        <Form layout="horizontal" form={form} onFinish={handleSend}>
          <FormItem
            name="userEmails"
            rules={[requiredRules, emailRules]}
            label=""
          >
            <StyledInput placeholder="Enter email address" />
          </FormItem>
          <FormItem>
            <PrimaryButton width="87px" height="36px" htmlType="submit">
              Invite
            </PrimaryButton>
          </FormItem>
        </Form>
        <FlexBox m="40px 0 0" justify="center">
          <Divider />
          <MiddleText>or</MiddleText>
          <Divider />
        </FlexBox>
        <CopyText
          onClick={() =>
            copyText(
              "a",
              `${window.location.protocol}//${window.location.host}/sign-up?referralCode=${referralCode}`
            )
          }
        >
          Copy referral link <CopyIcon />
        </CopyText>
      </InviteContainer>
      <ReferralTitle>Referral Tracking</ReferralTitle>
      <UserTable
        dataSource={listReferralUser?.slice(
          (page - 1) * PAGE_SIZE,
          PAGE_SIZE * page
        )}
        columns={columns}
        pagination={{
          current: page,
          pageSize: PAGE_SIZE,
          total: listReferralUser?.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        rowKey="_id"
        onChange={onChange}
      />
    </FormSectionWrapper>
  );
};
