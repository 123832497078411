import { FC, memo } from "react";
import { IEducationProps } from "./education.props";
import {
  AvatarItem,
  Container,
  ContainerItem,
  HeaderItem,
  Item,
  SchoolBlock,
  LeftItem,
  Title,
  TitleBlock,
  SchoolName,
  Major,
  StudySessionBlock,
  StudySession,
  StudySessionValue,
  StartTime,
  EndTime,
  LearnedBlock,
  Learned,
  LearnedValue,
  Divider,
  FooterItem,
  LinkWebsite,
} from "./education.style";
import {
  PastExperienceIcon,
  WebsiteIcon,
} from "assets/images/profile-preview/index";
import { handleLongText } from "utils/text";
import { useAppSelector } from "redux/hooks";
import { Avatar } from "antd";

const Education: FC<IEducationProps> = ({ education }) => {
  const userInfo = useAppSelector((state) => state.auth.user);

  return (
    <Container>
      <TitleBlock>
        <PastExperienceIcon />
        <Title>Education</Title>
      </TitleBlock>
      <ContainerItem>
        {education?.map((item, i: number) => (
          <Item key={i}>
            <HeaderItem>
              <AvatarItem>
                <Avatar
                  shape="circle"
                  size={28}
                  src={userInfo?.profilePicture}
                />
              </AvatarItem>
              <LeftItem>
                <SchoolBlock>
                  <SchoolName>{item.college}</SchoolName>
                  <Major>{item.degree}</Major>
                </SchoolBlock>
                <StudySessionBlock>
                  <StudySession>Study Session</StudySession>
                  <StudySessionValue>
                    {item.isCurrentStudy ? (
                      <>
                        <StartTime>
                          {new Date(item.startDate).getFullYear()}
                        </StartTime>{" "}
                        - <StartTime>Present</StartTime>
                      </>
                    ) : (
                      <EndTime>
                        {new Date(item.startDate).getFullYear()} -{" "}
                        {new Date(item.endDate).getFullYear()}
                      </EndTime>
                    )}
                  </StudySessionValue>
                </StudySessionBlock>
                <LearnedBlock>
                  <Learned>Learned</Learned>
                  <LearnedValue>{item.experienceFromCollege}</LearnedValue>
                </LearnedBlock>
              </LeftItem>
            </HeaderItem>
            <Divider />
            <FooterItem>
              <WebsiteIcon />
              {item.collegeWebsite !== "" && (
                <a href={item.collegeWebsite} target="_blank" rel="noreferrer">
                  <LinkWebsite>
                    {handleLongText(item.collegeWebsite, 35)}
                  </LinkWebsite>
                </a>
              )}
            </FooterItem>
          </Item>
        ))}
      </ContainerItem>
    </Container>
  );
};

export default memo(Education);
