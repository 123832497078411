import { Modal } from "antd";
import styled from "styled-components";
import { CSSProperties } from "styled-components";

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-body {
      padding: 0rem;
    }
  }
  .ant-modal-title {
    color: #080f6c;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
  }
`;

export const ModalMaskStyle = {
  backgroundColor: "rgb(8 10 38 / 60%)",
} as CSSProperties;
