import { useQuery } from "@tanstack/react-query";
import { RadioChangeEvent, Switch } from "antd";
import { getAllMastersAPI } from "api/users";
import { AxiosResponse } from "axios";
import PrimaryButton from "components/base/primaryButton";
import { FC, useEffect, useMemo, useState } from "react";
import { PROJECT_STATUS } from "utils/constant";
import { IFilterProps } from "./filter.props";
import {
  CheckBoxAntd,
  DraweAtnd,
  ItemWrap,
  ItemWrapStart,
  RadioAntd,
  SpaceAntd,
} from "./filter.style";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  initialCourseState,
  initialFilterState,
  setCourseFilter,
  setFilter,
} from "redux/slices";
import { useGetAllCourseCategories } from "api/course/queries";
import { COURSE_FOR_USER } from "api/course";

const Filter: FC<IFilterProps> = ({ open, onClose }) => {
  const [projectType, setProjecttype] = useState<string>("");
  const [collaboratorLevel, setCollaboratorLevel] = useState<string>("");
  const [deliveryTime, setDeliveryTime] = useState<string>("");
  const [issueTypes, setIssueTypes] = useState<CheckboxValueType[]>([]);
  const [membersRange, setMembersRange] = useState<CheckboxValueType[]>([]);
  const [masters, setMasters] = useState<any>();

  const dispatch = useAppDispatch();

  const onProjectTypeChange = (e: RadioChangeEvent) => {
    setProjecttype(e.target.value);
  };
  const onCollaboratorLevelChange = (e: RadioChangeEvent) => {
    setCollaboratorLevel(e.target.value);
  };
  const onDeliveryTimeChange = (e: RadioChangeEvent) => {
    setDeliveryTime(e.target.value);
  };

  const onTypeOfTasksChange = (checkedValues: CheckboxValueType[]) => {
    setIssueTypes(checkedValues);
  };

  // const onNumberMembersChange = (checkedValues: CheckboxValueType[]) => {
  //   setMembersRange(checkedValues);
  // };

  useQuery(["getAllMasters"], () => getAllMastersAPI(), {
    onSuccess: async (res: AxiosResponse) => {
      if (res.responseCode === 200) {
        setMasters(res.responseData);
      }
    },
  });

  useEffect(() => {
    const dayAmount = 24 * 60 * 60 * 1000;
    let deliveryDate = "";
    if ("Express 3 days" === deliveryTime)
      deliveryDate = Date.now() + dayAmount * 3 + "";
    if ("Up to 7 days" === deliveryTime)
      deliveryDate = Date.now() + dayAmount * 7 + "";
    if ("Up to 15 days" === deliveryTime)
      deliveryDate = Date.now() + dayAmount * 15 + "";
    if ("Up to 30 days" === deliveryTime)
      deliveryDate = Date.now() + dayAmount * 30 + "";

    const data = {
      seedSkip: 0,
      openBountiesSkip: 0,
      learnAndEarnSkip: 0,
      portfolioSkip: 0,

      projectStatus: projectType,
      collaboratorLevel: collaboratorLevel,
      deliveryDate: deliveryDate,
      membersRange: membersRange as string[],
      issueTypes: issueTypes as string[],
    };

    dispatch(setFilter(data));
  }, [
    projectType,
    collaboratorLevel,
    deliveryTime,
    issueTypes,
    membersRange,
    dispatch,
  ]);

  function reset() {
    setCollaboratorLevel("");
    setDeliveryTime("");
    setIssueTypes([]);
    setMembersRange([]);
    setProjecttype("");
  }
  const { filterType } = useAppSelector((state) => state.filter);

  const { data: result } = useGetAllCourseCategories();
  const categories = useMemo(() => {
    if (result) return result.responseData;
  }, [result]);

  const paramsCourse = useAppSelector((state) => state.filterCourse);

  const onBookmarkChange = (e: RadioChangeEvent) => {
    dispatch(setCourseFilter({ ...paramsCourse, type: e.target.value }));
    dispatch(setFilter({ learnAndEarnSkip: 0 }));
  };

  const onCategoriesChange = (checkedValues: CheckboxValueType[]) => {
    dispatch(
      setCourseFilter({ ...paramsCourse, categories: checkedValues as any })
    );
    dispatch(setFilter({ learnAndEarnSkip: 0 }));
  };

  function resetCourse() {
    dispatch(setCourseFilter(initialCourseState));
  }
  return (
    <DraweAtnd
      title={
        <>
          <span>{filterType ? "Project Filter" : "Course Filter"}</span>
          <Switch
            className="switch-component"
            checkedChildren="Project"
            unCheckedChildren="Course"
            checked={filterType}
            onChange={(value) => {
              dispatch(setFilter({ ...initialFilterState, filterType: value }));
              dispatch(setCourseFilter(initialCourseState));
            }}
          />
        </>
      }
      placement="right"
      onClose={onClose}
      visible={open}
    >
      {filterType && (
        <>
          <PrimaryButton width="100px" height="40px" onClick={reset}>
            Reset
          </PrimaryButton>
          <ItemWrapStart>
            <div>Project Type</div>
            <RadioAntd.Group onChange={onProjectTypeChange} value={projectType}>
              <SpaceAntd direction="vertical">
                <RadioAntd value={PROJECT_STATUS.COMPLETED}>
                  Completed
                </RadioAntd>
                <RadioAntd value={PROJECT_STATUS.OPEN}>Ongoing</RadioAntd>
              </SpaceAntd>
            </RadioAntd.Group>
          </ItemWrapStart>
          <ItemWrap>
            <div>Collaborator Level</div>
            <RadioAntd.Group
              onChange={onCollaboratorLevelChange}
              value={collaboratorLevel}
            >
              <SpaceAntd direction="vertical">
                {masters
                  ?.find((m: any) => m.type === "Collaborator Levels")
                  ?.values?.map((level: any, iLevel: number) => (
                    <RadioAntd value={level?._id} key={iLevel}>
                      {level?.name}
                    </RadioAntd>
                  ))}
              </SpaceAntd>
            </RadioAntd.Group>
          </ItemWrap>
          <ItemWrap>
            <div>Delivery Time</div>
            <RadioAntd.Group
              onChange={onDeliveryTimeChange}
              value={deliveryTime}
            >
              <SpaceAntd direction="vertical">
                <RadioAntd value={"Express 3 days"}>Express 3 days</RadioAntd>
                <RadioAntd value={"Up to 7 days"}>Up to 7 days</RadioAntd>
                <RadioAntd value={"Up to 15 days"}>Up to 15 days</RadioAntd>
                <RadioAntd value={"Up to 30 days"}>Up to 30 days</RadioAntd>
                <RadioAntd value={"Anytime"}>Anytime</RadioAntd>
              </SpaceAntd>
            </RadioAntd.Group>
          </ItemWrap>
          <ItemWrap>
            <div>Type of Tasks</div>
            <CheckBoxAntd.Group
              onChange={onTypeOfTasksChange}
              value={issueTypes}
            >
              {masters
                ?.find((m: any) => m.type === "Issue Types")
                ?.values?.map((level: any, iLevel: number) => (
                  <CheckBoxAntd value={level?._id} key={iLevel}>
                    {level?.name}
                  </CheckBoxAntd>
                ))}
            </CheckBoxAntd.Group>
          </ItemWrap>
          {/* <ItemWrap>
            <div>Number of Members</div>
            <CheckBoxAntd.Group
              onChange={onNumberMembersChange}
              value={membersRange}
            >
              <CheckBoxAntd value={"0-5"}>Less than 5 members</CheckBoxAntd>
              <CheckBoxAntd value={"5-10"}>5 to 10 members</CheckBoxAntd>
              <CheckBoxAntd value={"10-15"}>10 to 15 members</CheckBoxAntd>
              <CheckBoxAntd value={"15-20"}>15 to 20 members</CheckBoxAntd>
            </CheckBoxAntd.Group>
          </ItemWrap> */}
        </>
      )}
      {!filterType && (
        <>
          <PrimaryButton width="100px" height="40px" onClick={resetCourse}>
            Reset
          </PrimaryButton>
          <ItemWrapStart>
            <div>Course Category</div>
            <CheckBoxAntd.Group
              onChange={onCategoriesChange}
              value={paramsCourse.categories}
            >
              {categories.map((cate: string, index: number) => (
                <CheckBoxAntd value={cate} key={index}>
                  {cate}
                </CheckBoxAntd>
              ))}
            </CheckBoxAntd.Group>
          </ItemWrapStart>
          <ItemWrap>
            <div>Filter by</div>
            <RadioAntd.Group
              onChange={onBookmarkChange}
              value={paramsCourse.type}
            >
              <SpaceAntd direction="vertical">
                <RadioAntd value={COURSE_FOR_USER.ALL}>Get all</RadioAntd>
                <RadioAntd value={COURSE_FOR_USER.BOOKMARK}>
                  Just course interest
                </RadioAntd>
                <RadioAntd value={COURSE_FOR_USER.OWNER}>Owner</RadioAntd>
                <RadioAntd value={COURSE_FOR_USER.PARTICIPATE}>
                  Participated
                </RadioAntd>
              </SpaceAntd>
            </RadioAntd.Group>
          </ItemWrap>
        </>
      )}
    </DraweAtnd>
  );
};

export default Filter;
