import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IConfirmModalProps } from "./confirmModal.props";

const { confirm } = Modal;
export const confirmModal = (props: IConfirmModalProps) => {
  confirm({
    title: props?.title,
    icon: props?.icon || <ExclamationCircleOutlined />,
    content: props?.content,
    okText: props?.okText || "Yes",
    okType: "danger",
    cancelText: props?.cancelText || "No",
    onOk() {
      props?.onOk && props.onOk();
    },
    onCancel() {
      props?.onCancel && props.onCancel();
    },
  });
};
