export const ConnectWalletIcon = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.8 13.0001C42.4 14.6001 42.4 17.2001 40.8 18.6001L35.2001 24.2001L19.6 8.6001L25.2001 3.0001C26.8001 1.4001 29.4 1.4001 30.8 3.0001L34.4001 6.6001L40.4001 0.600098L43.2001 3.4001L37.2001 9.4001L40.8 13.0001ZM29.2001 24.6001L26.4 21.8001L20.8 27.4001L16.6 23.2001L22.2001 17.6001L19.4 14.8001L13.8 20.4001L10.8 17.6001L5.20005 23.2001C3.60005 24.8001 3.60005 27.4001 5.20005 28.8001L8.80005 32.4001L0.800049 40.4001L3.60005 43.2001L11.6 35.2001L15.2 38.8001C16.8 40.4001 19.4 40.4001 20.8 38.8001L26.4 33.2001L23.6 30.4001L29.2001 24.6001Z"
      fill="#7E00FD"
    />
  </svg>
);

export const ActiveIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM15.768 9.14C15.8558 9.03964 15.9226 8.92274 15.9646 8.79617C16.0065 8.6696 16.0227 8.53591 16.0123 8.40298C16.0018 8.27005 15.9648 8.14056 15.9036 8.02213C15.8423 7.90369 15.758 7.79871 15.6555 7.71334C15.5531 7.62798 15.4346 7.56396 15.3071 7.52506C15.1796 7.48616 15.0455 7.47316 14.9129 7.48683C14.7802 7.50049 14.6517 7.54055 14.5347 7.60463C14.4178 7.66872 14.3149 7.75554 14.232 7.86L9.932 13.019L7.707 10.793C7.5184 10.6108 7.2658 10.51 7.0036 10.5123C6.7414 10.5146 6.49059 10.6198 6.30518 10.8052C6.11977 10.9906 6.0146 11.2414 6.01233 11.5036C6.01005 11.7658 6.11084 12.0184 6.293 12.207L9.293 15.207C9.39126 15.3052 9.50889 15.3818 9.63842 15.4321C9.76794 15.4823 9.9065 15.505 10.0453 15.4986C10.184 15.4923 10.32 15.4572 10.4444 15.3954C10.5688 15.3337 10.6791 15.2467 10.768 15.14L15.768 9.14Z"
      fill="#7E00FD"
    />
  </svg>
);
