import { useMutation } from "@tanstack/react-query";
import { message, Avatar } from "antd";
import { createWorktaskAPI, IMaster, updateWorkTaskAPI } from "api/users";
import { PurpleAttachIcon2 } from "assets/images/deliver-work";
import { formatEther, parseUnits } from "ethers/lib/utils";
import { IReviewWorktaskProps } from "pages/Bounty/Bounty.props";
import {
  ButtonsWrap,
  FooterWrap,
  ObserverPreviewWrapper,
} from "pages/Bounty/Bounty.style";
import {
  ButtonAntd,
  ResourcesWrap,
} from "pages/DeliverWork/components/DeliverCollaborators/DeliverCollaborators.style";
import {
  ObserverInfoWrapper,
  ObserverName,
  ObserverRole,
} from "pages/DeliverWork/components/Observers/Observers.style";
import { CommonFlexRow } from "pages/OrganizationPage/OrganizationPage.style";
import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import WorktaskHead from "pages/WorktasksInside/WorktaskHead";
import WorktaskInfo from "pages/WorktasksInside/WorktaskInfo";
import {
  BodyWrap,
  DesText,
  DesTitle,
} from "pages/WorktasksInside/WorktasksInside.style";
import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setMode,
  // addStepSubmit,
  setPackageId,
  setPageLoading,
  setSuccess,
  setTxHash,
} from "redux/slices";
import {
  BlockchainNetworks,
  PACKAGE_WORK_STATUS,
  PROGRESS,
} from "utils/constant";
import { elementing, splitDescription } from "utils/formatDescription";
import {
  approveERC20,
  createWorktaskSC,
  getERC20Allowance,
  getERC20Decimal,
  handleMessageErrorSC,
  updateObserversSC,
} from "web3/contract";
import Button from "components/base/button";
import { ArrowLeftIcon, ArrowRightIcon } from "assets/images/Bounty";
import { Link } from "react-router-dom";
import PrimaryButton from "components/base/primaryButton";
import { useCheckWalletAndSwitchNetwork } from "hooks";
import { useEthers } from "@usedapp/core";

const ReviewWorktask: FC<IReviewWorktaskProps> = ({
  editMode,
  worktaskDetails: wtDetailsFromApi,
  stepIndex,
  setStepIndex,
}) => {
  const { account } = useEthers();

  const {
    minimumCost,
    projectType,
    observerFees,
    memberLimit,
    bonus,
    issueType,
    expertiseLevel,
    startDate,
    endDate,
    observers,
    tags,
    expectedTimeCommitment,
    githubIssueURL,
    importName,
    importDescription,
    name,
    description,
    link,
    context,
    reference,
    acceptanceCriteria,
    resourceFiles,
    isCustom,
    project,
    packageId,
    projectTypes,
    typeOfTasks,
    levelOfExpertise,
  } = useAppSelector((state: any) => {
    return state.addWorktask;
  });

  const [worktaskDetails] = useState({
    workStatus: PACKAGE_WORK_STATUS.OPEN,
    projectType,
    minimumCost,
    observerFees,
    memberLimit,
    bonus,
    issueType,
    expertiseLevel,
    startDate,
    endDate,
    tags,
    expectedTimeCommitment,
    githubIssueURL,
    link,
    context,
    reference,
    acceptanceCriteria,
    resourceFiles,
    name: isCustom ? name : importName,
    description: isCustom ? description : importDescription,
    observers: observers.map((obs: any) => ({
      id: obs?._id,
      role: obs?.role?._id,
    })),
  });
  const { checkWallet, handleSwitchNetwork } = useCheckWalletAndSwitchNetwork();

  const dispatch = useAppDispatch();

  const { mutate: createWorktask } = useMutation(createWorktaskAPI);

  const { mutate: updateWorktask } = useMutation(updateWorkTaskAPI);

  const handleGoBack = () => {
    let prevStepIndex = stepIndex - 1;
    if (observerFees === 0) prevStepIndex--;
    setStepIndex(prevStepIndex);
  };

  const onSubmitWorkTask = async () => {
    if (!checkWallet()) return;

    try {
      dispatch(setPageLoading(true));

      const projectChainId =
        BlockchainNetworks[project?.blockchainNetwork.name];
      await handleSwitchNetwork(projectChainId);

      const decimal = await getERC20Decimal(project.tokenContract);

      const allowance = (
        await getERC20Allowance({
          walletAddress: account,
          erc20Address: project.tokenContract,
        })
      )?.toString();

      const worktaskBudget = observerFees + bonus + memberLimit * minimumCost;

      const allowanceEnough =
        +allowance < +parseUnits("" + worktaskBudget, decimal).toString();

      if (allowance === "0" || allowanceEnough) {
        await approveERC20({
          erc20Address: project.tokenContract,
        });
      }

      if (editMode) {
        const payload = {
          workTaskId: packageId,
          projectId: project._id,
          issueType,
          expertiseLevel,
          startDate,
          endDate,
          tags,
          expectedTimeCommitment,
          githubIssueURL,
          link,
          context,
          reference,
          acceptanceCriteria,
          resourceFiles,
          name,
          description,
        };

        // get the removed observers
        const observersRemoved = wtDetailsFromApi?.observers?.filter(
          (ob: any) =>
            !observers?.some((apiOb: any) => apiOb?._id === ob?.user?._id)
        );

        // get the added observers
        const newObserversAdded = observers?.filter(
          (ob: any) =>
            !wtDetailsFromApi?.observers?.some(
              (apiOb) => apiOb?.user?._id === ob?._id
            )
        );

        // run SC function if observer list changed
        if (
          (observersRemoved?.length || 0) > 0 ||
          newObserversAdded?.length > 0
        ) {
          const observersIn = newObserversAdded?.map(
            (item: any) => item?.walletAddress
          );
          const observersOut = observersRemoved?.map(
            (item: any) => item?.user?.walletAddress
          );

          await updateObserversSC({
            projectId: wtDetailsFromApi?.scProjectId || "",
            packageId: wtDetailsFromApi?.scPackageId || "",
            observersIn,
            observersOut,
          });
        }

        // check for updated role in observers
        const updatedRoleObservers = observers?.filter((ob: any) =>
          wtDetailsFromApi?.observers?.some(
            (apiOb) =>
              apiOb?.user?._id === ob?._id && apiOb?.role?._id !== ob?.role?._id
          )
        );

        const mapToRequestFormat = (ob: any) => ({
          id: ob?.user?._id || ob?._id,
          role: ob?.role?._id,
        });

        const payloadWithObservers = {
          ...payload,
          observersData: {
            observersRemoved:
              observersRemoved?.map((ob: any) => ob?.user?._id || ob?._id) ||
              [],
            newObserversAdded: newObserversAdded?.map(mapToRequestFormat) || [],
            updatedRoleObservers:
              updatedRoleObservers?.map(mapToRequestFormat) || [],
          },
        };

        updateWorktask(payloadWithObservers, {
          onSuccess: (res) => {
            const { responseCode, responseMessage } = res;
            if (responseCode === 200) {
              message.success(responseMessage);
              dispatch(setSuccess({ success: PROGRESS.SUCCESS }));
              dispatch(setMode({ editMode: true }));
            } else {
              message.error(responseMessage);
              dispatch(setSuccess({ success: PROGRESS.ERROR }));
            }
            dispatch(setPageLoading(false));
          },
          onError: (err: any) => {
            const rs: any = err?.response?.data;
            message.error(rs?.message);
            dispatch(setPageLoading(false));
            dispatch(setSuccess({ success: PROGRESS.ERROR }));
          },
        });
      } else {
        const scProjectId = project?.scProjectId;

        const { tx, event } = await createWorktaskSC({
          projectId: scProjectId || "",
          budget: parseUnits(
            "" + worktaskDetails.minimumCost * worktaskDetails.memberLimit,
            project?.decimal || 0
          ).toString(),
          bonus: parseUnits(
            "" + worktaskDetails.bonus,
            project?.decimal || 0
          ).toString(),
          observerBudget: parseUnits(
            "" + worktaskDetails.observerFees,
            project?.decimal || 0
          ).toString(),
          collaboratorsLimit: worktaskDetails.memberLimit,
          observers: observers.map((obs: any) => obs.walletAddress) || [],
        });

        const scPackageId = event[0][1];

        const payload = {
          projectId: project._id,
          ...worktaskDetails,
          scPackageId,
          txHash: tx.hash,
        };
        dispatch(setTxHash(tx.hash));
        createWorktask(
          { ...payload },
          {
            onSuccess: (res) => {
              const { responseCode, responseMessage, responseData } = res;
              if (responseCode === 200) {
                message.success(responseMessage);
                dispatch(setPackageId({ packageId: responseData._id }));
                dispatch(setSuccess({ success: PROGRESS.SUCCESS }));
              } else {
                message.error(responseMessage);
                dispatch(setSuccess({ success: PROGRESS.ERROR }));
              }

              dispatch(setPageLoading(false));
            },
            onError: (err: any) => {
              const rs: any = err?.response?.data;
              message.error(rs?.message);
              dispatch(setPageLoading(false));
              dispatch(setSuccess({ success: PROGRESS.ERROR }));
            },
          }
        );
      }

      return false;
    } catch (err: any) {
      dispatch(setPageLoading(false));
      handleMessageErrorSC(err?.reason || err?.message || err.toString());
      return false;
    }
  };

  const handlePaymentStep = async () => {
    const result = await onSubmitWorkTask();
    if (!result) return;

    setStepIndex(stepIndex + 1);
  };

  const worktaskDetailsData = {
    ...worktaskDetails,
    issueType: typeOfTasks.find((t: IMaster) => t._id === issueType),
    projectType: projectTypes.find((t: IMaster) => t._id === projectType),
    expertiseLevel: levelOfExpertise.find(
      (t: IMaster) => t._id === expertiseLevel
    ),
  };

  const formatTextToNewType = (text: string) => {
    return (
      <DesText>
        {text
          ? splitDescription(text).map((str: string, i: number) => (
              <React.Fragment key={i}>
                {elementing(str)}
                <br />
              </React.Fragment>
            ))
          : null}
      </DesText>
    );
  };

  return (
    <>
      <WorktaskHead
        worktaskDetail={worktaskDetailsData}
        project={project}
        hideBtnsOption
      />
      <BodyWrap>
        <WorktaskInfo worktaskDetail={worktaskDetailsData} project={project} />
        <DesTitle>Description</DesTitle>
        {formatTextToNewType(worktaskDetails.description)}
        {isCustom && (
          <>
            <DesTitle>What you will be working on</DesTitle>
            {formatTextToNewType(worktaskDetails?.context)}
            <DesTitle>Acceptance Criteria</DesTitle>
            {formatTextToNewType(worktaskDetails?.acceptanceCriteria)}
            <DesTitle>Resources</DesTitle>
            <DesText>{worktaskDetails?.reference}</DesText>
            <DesTitle>
              <ResourcesWrap>
                {resourceFiles?.map((file: any, i: number) => (
                  <ButtonAntd key={i}>
                    <div>
                      <PurpleAttachIcon2 />
                      {file.fileName}
                    </div>
                  </ButtonAntd>
                ))}
              </ResourcesWrap>
            </DesTitle>
            {observers?.length > 0 && (
              <>
                <DesTitle>WorkTask Observer's</DesTitle>
                <ObserverPreviewWrapper>
                  {observers?.map((item: any) => {
                    return (
                      <ObserverInfoWrapper key={item?._id}>
                        <Avatar
                          shape="square"
                          src={item?.profilePicture}
                          size={52}
                        />
                        <CommonFlexColumn space="14px">
                          <CommonFlexRow space="14px">
                            <ObserverName>
                              {item?.username || item?.emailId.split("@")[0]}
                            </ObserverName>
                          </CommonFlexRow>
                          <ObserverRole>{item?.role?.name}</ObserverRole>
                        </CommonFlexColumn>
                      </ObserverInfoWrapper>
                    );
                  })}
                </ObserverPreviewWrapper>
              </>
            )}
          </>
        )}
      </BodyWrap>
      <FooterWrap>
        <Button
          width="89px"
          height="48px"
          border="1px solid rgba(156, 138, 193, 0.16)"
          color="rgba(41, 46, 115, 0.42)"
          background="#FFF"
          onClick={handleGoBack}
        >
          <ArrowLeftIcon />
          Back
        </Button>
        <ButtonsWrap>
          <Button
            width="83px"
            height="48px"
            border="1px solid rgba(156, 138, 193, 0.16)"
            color="rgba(41, 46, 115, 0.42)"
            background="#FFF"
          >
            <Link to="/">Cancel</Link>
          </Button>
          <PrimaryButton
            width={stepIndex === 4 ? "181px" : "76px"}
            height="48px"
            onClick={() => handlePaymentStep()}
          >
            {editMode ? "Proceed to Edit" : "Proceed to Payment"}
            <ArrowRightIcon />
          </PrimaryButton>
        </ButtonsWrap>
      </FooterWrap>
    </>
  );
};

export default ReviewWorktask;
