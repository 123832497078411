import { Form } from "antd";
import { useWatch } from "antd/lib/form/Form";
import TextEditor from "components/base/textEditor";
import { FC, useEffect } from "react";
import { requiredRules, stringRequired } from "utils/validatorRules";
import { ICreateSectionProps } from "./createSection.props";
import {
  CancelButton,
  InputStyled,
  ItemSection,
  Label,
  SaveButton,
  WrapButton,
  WrapContent,
} from "./createSection.style";

const CreateSection: FC<ICreateSectionProps> = ({
  handleSubmitModal,
  handleCancel,
  section,
}) => {
  const [form] = Form.useForm();
  const description = useWatch("description", form);

  useEffect(() => {
    if (section) {
      form.setFieldsValue({ ...section });
    }
  }, [form, section]);

  return (
    <>
      <WrapContent>
        <Form form={form} onFinish={handleSubmitModal}>
          <ItemSection>
            <Label>Section name</Label>
            <Form.Item name={"title"} rules={[stringRequired]}>
              <InputStyled></InputStyled>
            </Form.Item>
          </ItemSection>
          <ItemSection>
            <Label>Body</Label>
            <Form.Item name={"description"} rules={[requiredRules]}>
              <TextEditor
                placeholder={"Content"}
                border={"4px"}
                witdh="525px"
                errorMessage={"Description is required"}
                data={description}
                onChange={(_, editor) =>
                  form.setFieldValue("description", editor.getData())
                }
              />
            </Form.Item>
          </ItemSection>
        </Form>
      </WrapContent>
      <WrapButton>
        <CancelButton onClick={() => handleCancel?.()}>Cancel</CancelButton>
        <SaveButton onClick={() => form.submit()}>Save</SaveButton>
      </WrapButton>
    </>
  );
};

export default CreateSection;
