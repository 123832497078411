import PrimaryButton from "components/base/primaryButton";
import styled from "styled-components";
import { Table } from "antd";

export const Container = styled.div`
  min-height: 70vh;
  font-family: "DM Sans";
  padding: 0px 50px;
  width: 100%;

  & > div {
    max-width: 1170px;
    margin: 48px auto;
    font-style: normal;
    display: grid;
    gap: 24px;
  }
`;
export const HeadingTable = styled.div`
  width: 100%;
  height: fit-content;
  background: rgba(156, 138, 193, 0.04);
  border: 1px solid rgba(41, 46, 115, 0.12);
  padding: 32px 47px 32px 32px;
  display: grid;
  gap: 24px;
`;

export const CardList = styled.div`
  display: grid;
  gap: 10px;
`;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: #080f6c;
`;

export const Card = styled.div`
  height: fit-content;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  background: white;
  padding: 20px 24px 20px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

export const Wallet = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const ItemContainer = styled.div`
  display: grid;
  gap: 8px;
  width: 50%;
`;

export const AddWalletButton = styled(PrimaryButton)`
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 115%;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
`;

export const ItemTitle = styled.div`
  color: #080f6c;
  display: flex;
  align-items: center;
  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
  }
  gap: 9px;
  margin-bottom: 3px;
`;

export const AddWalletButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const DashboardHeadingTable = styled(Table)`
  p {
    margin: 0;
  }
  .ant-table-content {
    max-height: 400px;
    overflow: auto;
  }
  th {
    font-weight: 600 !important;
  }
  .ant-table table {
    border-radius: none;
  }
  .ant-table-thead > tr > th {
    border-bottom: none;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px rgba(41, 46, 115, 0.12) solid;
  }
  .ant-table-thead {
    background: linear-gradient(
      135deg,
      rgba(126, 0, 253, 0.17),
      rgba(193, 67, 252, 0.17),
      rgba(0, 191, 251, 0.17)
    ) !important;
  }

  .ant-table-tbody {
    background: linear-gradient(
      135deg,
      rgba(126, 0, 253, 0.06),
      rgba(193, 67, 252, 0.06),
      rgba(0, 191, 251, 0.06)
    ) !important;
  }

  thead {
    tr {
      th {
        height: 49px;
        padding: 20px 32px 18px 32px;
        color: rgba(8, 15, 108, 1);
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
        font-family: "Rubik";
        font-style: "Medium";
        background: none !important;
      }
      .ant-table-cell:before {
        content: none !important;
      }
    }
  }

  tbody {
    .ant-table-row {
      height: 54px;
    }
    tr {
      .ant-table-cell {
        width: 25%;
        padding-left: 32px;
      }
      // cursor: pointer;
      td {
        vertical-align: middle;
        &:first-child {
        }
      }
    }
  }

  .ant-table-placeholder {
    &:hover {
      border: none !important;
      background: none !important;
    }
    td {
      &:first-child {
        border-left: none !important;
      }
    }
  }
`;

export const TableColumn = styled(Table.Column)``;

export const TokenSymbol = styled.p`
  color: rgba(8, 15, 108, 1);
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  font-family: "Rubik";
  font-style: "SemiBold";
`;

export const TokenValue = styled.p`
  color: rgba(41, 46, 115, 0.82);
  font-size: 18px;
  font-weight: 400;
  line-height: 120%;
  font-family: "Rubik";
  font-style: "Regular";
  letter-spacing: 0.01;
`;

export const WalletRow = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  height: 36px;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: rgba(41, 46, 115, 0.82);
  }
  padding: 11px 13.5px 11px 16px;
`;
