import { Badge, Button, Input, Popover } from "antd";
import OutlinedButton from "components/base/outlinedButton";
import {
  StyledSelect,
  StyledSelectWrap,
} from "pages/SubmitNewDeliverable/SubmitNewDeliverable.style";
import { CheckboxPurple } from "pages/ExpressInterest/ExpressInterest.style";
import { ItemBody1 } from "pages/SubmitDeliverable/SubmitDeliverable.style";
import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.div`
  font-family: "DM Sans";
`;
export const ModalWrap = styled.div`
  padding: 32px;
  position: relative;
  & > svg {
    position: absolute;
    top: 17px;
    right: 13px;
    cursor: pointer;
  }
`;

export const ButtonAntd = styled(Button)`
  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #080f6c;
  height: 30px;
  width: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 0;

  &:hover,
  &:focus {
    color: #080f6c;
    border: 1px solid rgba(156, 138, 193, 0.16);
  }

  label {
    margin-right: 4px;
    margin-left: -3px;
    /* height: 20px;
    width: 20px; */
  }
`;
export const SubtitleWrap = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: rgba(41, 46, 115, 0.82);
  margin: 4px 0px;

  & > svg {
    margin-left: 4px;
    margin-top: 2px;
  }
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #080f6c;

  width: 529px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 25px;
`;
export const Name = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.82);
  width: 125px;
`;
export const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: rgba(41, 46, 115, 0.82);
  width: max-content;
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
`;
export const DueDate = styled(Flex)`
  margin-bottom: 16px;
  svg {
    margin-right: 8px;
  }
`;

export const PropertiesWrap = styled(Flex)`
  gap: 16px;
`;
export const PropertiesWrap1 = styled(Flex)`
  margin-bottom: 20px;
  svg {
    margin-left: 6px;
  }
  div {
    width: 132px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: linear-gradient(135deg, #3f8ef7 0%, #1a68d0 100%);
    margin-right: 6px;
  }
`;
export const PropertiesWrap2 = styled(PropertiesWrap1)`
  div {
    width: 124px;
  }
  &::before {
    background: linear-gradient(135deg, #50eaa5 0%, #08ce76 100%);
  }
`;
export const PropertiesWrap3 = styled(PropertiesWrap1)`
  div {
    width: 137px;
  }
  &::before {
    background: linear-gradient(313.96deg, #ff8c00 26.52%, #ffa500 98.74%);
  }
`;
export const PropertiesWrap4 = styled(PropertiesWrap1)`
  div {
    width: 141px;
  }
  &::before {
    background: #ef5da8;
  }
`;
export const Project = styled(Flex)`
  align-items: flex-start;
  & > div:nth-child(2) {
    padding-top: 5px;
  }
`;
export const Dependencies = styled(Flex)`
  margin-bottom: 20px;
`;
export const BlockBy = styled(Flex)`
  & > svg:nth-child(3) {
    margin-left: 8px;
  }
  & > svg:nth-child(1) {
    margin-right: 8px;
  }
`;
export const SelectAntdWrap = styled(StyledSelectWrap)`
  height: 30px;
`;
export const SelectAntd = styled(StyledSelect)`
  height: 30px;
  width: 256px;

  .ant-select-selection-placeholder {
    padding-top: 0px !important;
    margin-top: -1px !important;
    height: 30px;
  }
  .ant-select-selection-item {
    padding-top: 0px !important;
    margin-top: -1px !important;
  }
`;
const { Option } = StyledSelect;
export const OptionAntd = Option;

export const SubTasks = styled.div`
  padding-bottom: 20px;
  width: 100%;
`;
export const AddSubtasksBtn = styled(OutlinedButton)`
  font-weight: 400;
  svg {
    margin-right: 6px;
  }
`;
export const SubTasksWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const SubTasksItemWrap = styled.div`
  width: 100%;
  border-top: 1px solid rgba(156, 138, 193, 0.16);
  margin: 8px 0px 16px;
`;
export const SubTasksItem = styled.div`
  width: 100%;
  height: 48px;
  border-bottom: 1px solid rgba(156, 138, 193, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 400;
  font-size: 14px;
  line-height: 156%;

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 10.5px;
    div {
      color: rgba(41, 46, 115, 0.82);
    }
  }
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 12px;
    & > span {
      color: rgba(41, 46, 115, 0.42);
      display: flex;
      align-items: center;
      svg {
        cursor: pointer;
      }
    }
  }
  .replaced-menu-icon {
    width: 20px;
  }
`;
export const CheckboxAntd = styled(CheckboxPurple)`
  margin-left: 2.5px;
  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }
  .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(0.8) translate(0%, 0%);
    left: 3px;
    top: 0px;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #fff;
  }
`;
export const AvatarGroup = styled(ItemBody1)`
  height: max-content;

  & > div {
    div {
      img {
        width: 24px;
        height: 24px;
        border: 1.26254px solid #ffffff;
        margin-left: -6.75px;
      }
      span {
        width: 24px;
        height: 24px;
        border: 1.26254px solid #ffffff;
        margin-left: -6.75px;
        font-size: 10px;
        line-height: 16px;
      }
    }
  }
`;
export const DescriptionWrap = styled.div`
  padding: 0px 0px 20px;
`;
export const AttachWrap = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const AttachItem = styled(BlockBy)`
  & > span {
    margin-left: 6px;
    cursor: pointer;
    height: 12px;
    display: flex;
    align-items: center;
  }
  & > svg {
    margin-right: 6px;
  }
`;
export const ShowedCommentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;
export const ShowedCommentItem = styled.div`
  display: flex;
  width: 100%;

  & > a {
    width: max-content;
    height: max-content;
    /* overflow: hidden; */
    margin-right: 20px;
    & > span {
      width: 36px;
      height: 36px;
      border: 1.26254px solid #ffffff;
    }
  }
  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 56px);

    & > div:nth-child(1) {
      & > div:nth-child(1) {
        display: flex;
        align-items: flex-end;
        padding: 4px 0px 2px;

        div {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: -0.02em;
          color: #000000;
          margin-right: 4px;
        }
      }
    }
    .anchor {
      color: rgba(41, 46, 115, 0.82);
    }
  }
`;

export const ShowedCommentTime = styled.span`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(41, 46, 115, 0.42);
`;
export const ShowedCommentAttached = styled(ShowedCommentTime)`
  display: flex;
  align-items: center;
  margin-left: 20px;

  position: relative;

  svg {
    position: absolute;
    right: calc(100% + 4px);
  }
`;
export const ShowedCommentContent = styled.div`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 25.84px;
  color: rgba(41, 46, 115, 0.82);
  width: 420px;

  span {
    color: #7e00fd;
  }
  a {
    color: #7e00fd;
  }
`;
export const ImageAttach = styled.div`
  margin-top: 10px;
  img {
    height: 73px;
    max-width: 100%;
    width: auto;
    object-fit: cover;
  }
  & > div {
    margin-top: 12px;
    font-weight: 400;
    font-size: 11px;
    line-height: 156%;
    color: rgba(41, 46, 115, 0.42);
    & > span {
      color: rgba(41, 46, 115, 1);
      cursor: pointer;
    }
  }
  .ant-image {
    margin-top: 0;
  }
`;
export const ShowedCommentActions = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  span {
    height: 20px;
  }
  svg {
    cursor: pointer;
  }
`;
export const BadgeAntd = styled(Badge)<{ color?: string }>`
  color: ${(p) => p.color || "#fff"};
  .ant-badge-count {
    background: #ff4d4f !important;
  }
  /* color: #7e00fd;#F57F20 */
`;

export const PopoverAntd = styled(Popover)``;

export const GlobalStyle = createGlobalStyle`
  .popover-bottom-left {
    .ant-popover-content{
      transform: translate(-12px ,-11px);
      /* border: 1px solid rgba(41, 46, 115, 0.12); */
    }
    .ant-popover-inner{
      border-radius: 4px;
    }
    .ant-popover-inner-content{
      padding: 6px 0px;
    }
    .ant-popover-arrow{
      left: 8px;
    }
    .ant-popover-arrow-content{
      /* border: 1px solid rgba(41, 46, 115, 0.12); */
    }
  }
  .popover-bottom-right {
    .ant-popover-content{
      transform: translate(14px ,-14px);
    }
    .ant-popover-inner{
      border-radius: 4px;
    }
    .ant-popover-inner-content{
      padding: 6px 0px;
    }
    .ant-popover-arrow{
      right: 12px;
    }
  }
  .popover-right {
    .ant-popover-content{
      transform: translate(0px ,0px);
    }
    .ant-popover-inner{
      border-radius: 4px;
    }
    .ant-popover-inner-content{
      padding: 12px 0px;
    }
    .ant-popover-arrow{
      right: 12px;
    }
  }
  .popover-tag-select {
      padding: 0px ;
      padding-left: 12px !important;
    .ant-popover-content{
      transform: translate(0px ,0px);
    }
    .ant-popover-inner{
      border-radius: 4px;
    }
    .ant-popover-inner-content{
      padding: 0px;
    }
    .ant-popover-arrow{
      display: none;
    }
  }
`;

export const PopoverSelectItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 177px;
  height: 34px;
  cursor: pointer;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(41, 46, 115, 0.82);

  svg {
    margin-right: 8px;
  }

  &:hover {
    background: #e9460015;
    color: #e94600;
    a {
      color: #e94600;
    }
  }
  a {
    color: rgba(41, 46, 115, 0.82);
  }
`;

export const CommentWrap = styled.div`
  padding: 0px 0px 62px;
`;
export const CommentBoxWrap = styled.div`
  width: 100%;
  margin: 10px 0px 10px;
  padding: 8px 12px 12px;
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 6px;
  height: 146px;
`;
export const CommentActionsWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 16px;

    label {
      height: 16px;
      cursor: pointer;
      position: relative;
      & > input {
        display: none;
      }
    }
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: rgba(41, 46, 115, 0.82);
  }
`;

export const UploadWrap = styled.span`
  height: 16px;
  .ant-upload.ant-upload-select-picture-card {
    height: 16px;
    width: 16px;
    margin: 0;
    border: none;
    background-color: transparent;
  }
`;
export const CommentDeleteCard = styled(Flex)`
  padding-left: 2px;
  gap: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: rgba(41, 46, 115, 0.42);
  cursor: pointer;
  width: max-content;
`;
// const { TextArea } = Input
export const CommentTextarea = styled.div`
  height: calc(100% - 32px) !important;
  position: relative;
  textarea {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 10px);
    border: none;
    padding: 0;
    padding-bottom: 10px;
    width: 100%;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    color: rgba(8, 15, 108, 0.6);

    &::placeholder {
      color: rgba(8, 15, 108, 0.6);
      font-weight: 400;
      font-size: 14px;
      line-height: 156%;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(8, 15, 108, 0.05);
      height: 100%;
    }
    &::-webkit-scrollbar {
      width: 3px;
      background-color: rgba(8, 15, 108, 0.08);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(8, 15, 108, 0.08);
      height: 30px;
    }
  }
`;
export const TagSelectWrap = styled.div`
  background-color: #fff;
  padding: 0px 12px 12px;
  width: 280px;

  .selected {
    background: rgba(156, 138, 193, 0.16);
    border-radius: 4px;
  }

  & > div:nth-child(1) {
    height: 40px;
    border-bottom: 1px solid rgba(41, 46, 115, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 400;
    color: rgba(41, 46, 115, 1);

    position: relative;

    span {
      cursor: pointer;
      right: 0px;
      position: absolute;
      height: max-content;
    }
    svg {
      transform: scale(0.8);
    }
  }

  & > div:nth-child(2) {
    input {
      margin: 12px 0px;
      width: 100%;
    }
    & > div {
      max-height: 300px;
      overflow: auto;

      &::-webkit-scrollbar-track {
        background-color: rgba(8, 15, 108, 0.05);
        height: 100%;
      }
      &::-webkit-scrollbar {
        width: 3px;
        background-color: rgba(8, 15, 108, 0.08);
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(8, 15, 108, 0.08);
        height: 30px;
      }
    }
  }
`;
export const SearchInput = styled(Input)`
  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 46, 115, 1);
  border-radius: 4px;
  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: rgba(41, 46, 115, 0.5);
  }
`;

export const TagSelectItem = styled.div`
  height: 40px;
  padding: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 46, 115, 1);

  span {
    height: 32px;
    width: 32px;
    margin-right: 6px;
  }
  label {
    height: max-content !important;
  }
`;
export const TagSelectionBySearchWrap = styled.div`
  position: absolute;
  top: -15px;
  left: calc(100% + 12px);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  /* border-radius: 4px;
  overflow: hidden; */

  .max-height {
    max-height: 100px !important;
    margin-top: 10px;
  }
`;

export const TagSelectionWrap = styled.div`
  position: absolute;
  top: -115px;
  left: calc(100% + 12px);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);

  .max-height {
    max-height: 150px !important;
  }
`;
export const EmojiSelectionWrap = styled.div`
  position: absolute;
  top: -175px;
  left: calc(100% + 12px);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  /* border-radius: 4px;
  overflow: hidden; */
  z-index: 83570;
`;
export const AddSubtaskWrap = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 12px;
  /* transform: scale(0.9); */
  label {
    font-size: 0.8rem;
  }
  .ant-select {
    height: unset;
    min-height: 2.25rem;
  }
`;
export const AddSubtaskBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
`;
export const WrapDropdownIcon = styled.span`
  width: 20px;
`;
