import OutlinedButton from "components/base/outlinedButton";
import styled from "styled-components";
import FlexBox from "components/base/Flexbox";
import CopyToClipboard from "react-copy-to-clipboard";
import projectBg from "assets/images/profile/project-bg.png";
import { CommonGradientContainer } from "pages/ProfileUser/profileUserInner.style";

export const ProjectHistoryContainer = styled(CommonGradientContainer)`
  margin: 2.5rem auto;
  max-width: 52.5rem;
`;

export const Heading = styled(FlexBox)`
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 6px;
`;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-left: 1rem;
`;

export const SecondaryButton = styled(OutlinedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: auto;
  height: 32px;
  background: rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  color: rgba(41, 46, 115, 0.42);
`;

export const EditButton = styled(SecondaryButton)`
  color: #7e00fd;
`;

export const DeleteButton = styled(SecondaryButton)`
  color: #ff5722;
  background: rgba(255, 87, 34, 0.1);
  margin-left: 0.5rem;
`;

export const CopyText = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #7e00fd;
`;

export const StyledCopy = styled(CopyToClipboard)`
  width: 200px;
  margin: 30px auto 0;
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.3rem;
  }
`;

export const PortfolioCard = styled.div`
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  width: 100%;
  height: 74px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: white;
  &:hover {
    background-image: url(${projectBg});
    background-size: cover;
  }
  > div {
    min-width: 100px;
  }
  > div:first-child {
    min-width: 310px;
  }
`;

export const ProjectAvatar = styled.div`
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    /* width: 28px; */
  }
`;

export const ProjectName = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-top: 3px;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
export const ProjectDate = styled(ProjectName)`
  color: rgba(41, 46, 115, 0.42);
  font-size: 14px;
  margin-bottom: 3px;
  cursor: auto;

  span {
    color: rgba(41, 46, 115, 0.82);
  }
`;

export const PaymentText = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 115%;
  /* or 18px */

  text-decoration-line: underline;

  /* text/body */

  color: rgba(41, 46, 115, 0.82);
`;
export const Review = styled(ProjectName)`
  cursor: auto;

  span {
    text-decoration-line: underline;
    color: rgba(41, 46, 115, 0.42);
    margin-left: 0.3rem;
  }
`;

export const Tool = styled.div<{ borderColor: string }>`
  padding: 4px 8px;
  height: 19px;
  border: 1px solid #ff5722;
  border-color: ${(p) => p.borderColor};
  border-radius: 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 95%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${(p) => p.borderColor};
  margin-right: 0.5rem;
`;

export const BoxButton = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;
