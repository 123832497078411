import { ICompletedProps } from "pages/Bounty/Bounty.props";
import { FC } from "react";
import CompletionImage from "assets/images/Bounty/completion.png";
import PrimaryButton from "components/base/primaryButton";
import { AnchorWithIcon, CompletedInformWrap } from "pages/Bounty/Bounty.style";
import { ArrowrightIcon } from "assets/images/Bounty";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Link } from "react-router-dom";
import { setReset } from "redux/slices";

const Completed: FC<ICompletedProps> = ({
  projectId,
  portfolioEdit = false,
}) => {
  const dispatch = useAppDispatch();
  const { editMode, packageId, name, importName } = useAppSelector(
    (state) => state.addWorktask
  );

  return (
    <>
      <CompletedInformWrap>
        <img src={CompletionImage} alt="worktask-complete" />
        <div>
          <div>
            {packageId
              ? `Your WorkTask was successfully ${
                  editMode ? "updated" : "created"
                }!`
              : `Your portfolio was successfully ${
                  portfolioEdit ? "updated" : "added"
                }!`}
          </div>
          {packageId ? (
            <>
              <p>
                Thanks for using BUIDL Finance to power your project.{" "}
                {!editMode ? (
                  <>
                    <b>{name || importName}</b> is now live and accepting
                    registrations.
                  </>
                ) : (
                  ""
                )}
              </p>
            </>
          ) : (
            <>
              <p>
                Anyway, so here's what I'm thinking. -- HoneyPot.Money; A site
                where people can get paid to create sybil resistent identities,
                hardening sybil resistent identity systems by creating a known
                price of forgery.
              </p>
            </>
          )}
          <PrimaryButton
            width={packageId ? "141px" : "160px"}
            height="48px"
            onClick={() => dispatch(setReset())}
          >
            {packageId ? (
              <Link
                to={`/deliver-work/${projectId}/${packageId}?type=initiator`}
              >
                See the WorkTask
              </Link>
            ) : (
              <Link to={"/profile-preview"}>See the Project task</Link>
            )}
          </PrimaryButton>
        </div>
      </CompletedInformWrap>
      <AnchorWithIcon to="/" onClick={() => dispatch(setReset())}>
        <ArrowrightIcon />
        Go Back Home
      </AnchorWithIcon>
    </>
  );
};

export default Completed;
