import { FC } from "react";
import { IStepsProps } from "./steps.props";
import { BaseSteps } from "./steps.style";

const Steps: FC<IStepsProps> = ({ items, ...props }) => {
  return (
    <BaseSteps {...props}>
      {items.map((step, index) => (
        <BaseSteps.Step key={index} icon={step.icon} title={step.title} />
      ))}
    </BaseSteps>
  );
};

export default Steps;
