import styled from "styled-components";
import { Button, DatePicker, Input, Switch } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FlexBox from "components/base/Flexbox";
import { CommonGradientContainer } from "pages/ProfileUser/profileUserInner.style";

export const ExperiencesContainer = styled(CommonGradientContainer)`
  padding: 26px;
`;

export const ExperiencesContainerTop = styled(FlexBox)`
  padding: 0 24px 0 0;
  margin-bottom: 1.5rem;
  align-items: flex-start;
  .ant-form {
    width: 100%;
    padding-left: 2rem;
  }
`;

export const Heading = styled(FlexBox)`
  justify-content: space-between;
  margin-top: 72px;
`;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: #080f6c;
  margin-left: 1rem;
`;
export const Description = styled.div<{ color?: string }>`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: ${(p) => p.color || "rgba(41, 46, 115, 0.42)"};
  margin-top: 0.35rem;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledDatepicker = styled(DatePicker)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: rgba(41, 46, 115, 0.42);
  height: 2.25rem;
  padding: 0.625rem;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;
export const StyledTextArea = styled(Input.TextArea)`
  width: 100%;
  border: 0.0625rem solid rgba(41, 46, 115, 0.12);
  border-radius: 0.25rem;
  height: 5.875rem !important;
  padding: 0.625rem;

  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(41, 46, 115, 0.42);
  }
`;

export const StyledFormItem = styled(FormItem)`
  label {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080f6c;
  }
  .ant-form-item-label {
    padding-bottom: 0.35rem;
  }
  margin-bottom: 18px;
`;

export const StyledSwitch = styled(Switch)`
  height: 26px;
  margin-right: 12px;
  .ant-switch-handle {
    width: 24px;
    top: 1px;
    height: 24px;
    &:before {
      border-radius: 50%;
    }
  }

  &.ant-switch-checked {
    background: #06c755;
    .ant-switch-handle {
      top: 1px;
      left: calc(100% - 25px);
    }
  }
`;

export const SwitchGroup = styled.div`
  display: flex;
  align-items: self-end;
`;
export const UpdateGroup = styled(SwitchGroup)``;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const SecondaryButton = styled(Button)<{
  $borderColor?: string;
  $color?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  width: auto;
  height: auto;
  background: rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  border: ${({ $borderColor }) =>
    $borderColor ? `1px solid ${$borderColor}` : "none"};
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  color: ${({ $color }) => $color || "rgba(41, 46, 115, 0.42)"};

  &.ant-btn:active,
  &.ant-btn:focus,
  &.ant-btn:hover {
    background: rgba(156, 138, 193, 0.16);
  }
`;

export const ActionButton = styled(SecondaryButton)`
  background: #7e00fd;
  color: white;
  margin-left: 1rem;
`;

export const EditButton = styled(SecondaryButton)`
  color: #7e00fd;
  svg {
    margin-right: 0.3rem;
  }
`;

export const DeleteButton = styled(SecondaryButton)`
  color: #ff5722;
  background: rgba(255, 87, 34, 0.1);
  margin-left: 0.5rem;
`;

export const ExperienceCard = styled.div`
  width: 100%;
  height: 72px;
  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  margin-top: 0.5rem;
`;

export const BoxImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0.5px solid rgba(41, 46, 115, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 28px;
    border-radius: 16px;
  }
`;

export const BoxText = styled.div`
  margin-left: 1rem;
`;

export const BoxName = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #080f6c;
`;

export const BoxDesc = styled(BoxName)`
  color: rgba(41, 46, 115, 0.42);
`;

export const ExperienceInfoGroup = styled.div`
  display: flex;
`;
