import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { getListPortfoliosAPI } from "api/users";
import { PlusIcon } from "assets/icons/common/plus.icon";
import { PortfolioIcon } from "assets/icons/common/portfolio.icon";
import { AxiosError } from "axios";
import PrimaryButton from "components/base/primaryButton";
import { FilterGroup } from "components/modules/filterGroup";
import { PortfolioCardContainer } from "components/modules/portfolioCardContainer";
import { FormSectionWrapper } from "pages/ProfileUser/profileUserInner.style";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { ROUTER_CONSTANTS } from "utils/constant";
import SectionHeader from "../SectionHeader";
import { PortfolioContainer, Heading } from "./portfolio.style";

export const Portfolio = ({ mt, profileId }: any) => {
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [chunks, setchunks] = useState<any>([]);
  const [myPortfolio, setMyPortfolio] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(4);
  const { search } = useAppSelector((state) => state.filter);
  const userId: string = useAppSelector((state) => state.auth.user?._id);

  const { isFetching } = useQuery(
    ["getListPortfolios", search, skip],
    () =>
      getListPortfoliosAPI({
        id: userId,
        search: search || "",
        skip: skip,
        limit: limit,
      }),
    {
      onSuccess: async (res) => {
        if (res.responseCode === 200) {
          const newChunks = [...chunks];
          newChunks[skip] = res.responseData.records;
          const newPortfolios: any = [];
          newChunks.forEach((chunk: any) =>
            chunk?.forEach((port: any) => newPortfolios.push(port))
          );
          setchunks(newChunks);
          setMyPortfolio(newPortfolios);
          setIsLoadMore(true);
          if (
            res.responseData.records.length < limit ||
            res.responseData.recordsTotal === newPortfolios.length
          )
            setIsLoadMore(false);
        } else message.error(res.responseMessage);
      },
      onError: (err: AxiosError) => {
        message.error(err?.message);
      },
      refetchOnWindowFocus: false,
      // enabled: !userId,
    }
  );

  function loadMore() {
    setSkip(skip + 1);
  }

  return (
    <FormSectionWrapper id="Portfolio & Project Showcase">
      <Heading>
        <SectionHeader
          icon={<PortfolioIcon />}
          title="Portfolio & Project Showcase"
        />
        <Link to={ROUTER_CONSTANTS.USER.ADD_PORTFOLIO}>
          <PrimaryButton>
            <PlusIcon />
            Add Project
          </PrimaryButton>
        </Link>
      </Heading>
      <PortfolioContainer>
        <FilterGroup
          hiddenFilter={true}
          padding="0 24px"
          mt={"24px !important"}
        />
        <PortfolioCardContainer
          itemsShow={2}
          portfolios={myPortfolio}
          mt={mt}
          isFetching={isFetching}
          loadMore={loadMore}
          isLoadMore={isLoadMore}
        />
      </PortfolioContainer>
    </FormSectionWrapper>
  );
};
