import styled from "styled-components";
import BountyBackground from "assets/images/bounty-background.png";
import FormItem from "antd/lib/form/FormItem";
import { DatePicker, Input, InputNumber, Select } from "antd";
import { PopupWrap } from "pages/WorktasksInside/WorktasksInside.style";
import {
  StyledInput,
  StyledTextArea,
} from "pages/CreateProject/createProject.style";
import { Link } from "react-router-dom";

const { Option } = Select;

export const ObserverPreviewWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;

export const Container = styled.div`
  font-family: "DM Sans";
  position: relative;
  padding: 0px 3.125rem;
  overflow: hidden;

  &::after {
    content: "";
    background-image: url(${BountyBackground});
    background-position: center;
    background-size: cover;
    height: 132px;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
  }
`;
export const Frame = styled.div`
  padding-top: 16px;
  max-width: 1170px;
  margin: auto;

  .first-step {
    justify-content: flex-end;
  }
`;

export const HeaderWrap = styled.div`
  width: 100%;
  height: 149px;
  background: transparent;

  border: solid 1px transparent; /* !importanté */
  border-style: solid;
  border-radius: 24px 24px 0px 0px;
  background-clip: padding-box; /* !importanté */
  position: relative;
  /* margin-bottom: 24px; */

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(
      to bottom,
      rgba(8, 10, 37, 0.1),
      rgba(8, 10, 37, 0)
    );
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 149px;
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  border-bottom: 1px solid rgba(115, 137, 149, 0.8);
  padding-top: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .active {
    background: #7e00fd;
    color: #fff;
    /* .step-menu {
      color: #080a25;
      font-weight: 600;
    } */
  }
  .complete-active {
    background: #00de8c;
    color: #fff;
  }
  .line-active {
    &::before {
      background: #7e00fd;
    }
  }
`;

export const MenuStep = styled.div<{ paddingX?: number }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c3c6cf;
  color: rgba(41, 46, 115, 0.82);
  width: 48px;
  height: 48px;
  border-radius: 48px;
  position: relative;
  user-select: none;

  .step-title {
    width: 140px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 12px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 115%;
    text-align: center;
    color: rgba(8, 10, 37, 0.5);
    padding: ${(p) => (p.paddingX ? `12px ${p.paddingX}px` : "12px 0px")};
  }
`;

export const Line = styled.div`
  width: 78px;
  height: 48px;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    background: #c3c6cf;
    width: 100%;
    height: 2px;
  }
`;

export const FooterWrap = styled.div`
  border-top: 1px solid rgba(115, 137, 149, 0.8);
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  margin-top: 24px;
`;
export const ButtonsWrap = styled.div`
  display: flex;
  gap: 24px;
`;
export const MainTitleWrap = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 17px;
  padding: 32px 0px;
  & > div {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #080a25;
  }
  & > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: rgba(16, 37, 72, 0.5);
    display: flex;
    gap: 4px;
    p {
      color: #7e00fd;
      margin: 0px;
    }
  }
`;

export const Title16 = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #080a25;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Subtile = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  margin: 8px 0px 16px;

  color: rgba(8, 10, 37, 0.6);
`;

export const FlexWrap = styled.div<{
  gap?: number;
  columns?: number;
  margin?: string;
}>`
  display: flex;
  gap: ${(p) => (p.gap ? p.gap + "px" : "0px")};
  margin: ${(p) => (p.margin ? p.margin : undefined)};
  position: relative;

  & > * {
    width: ${(p) =>
      p.columns
        ? `calc((100% - ${p.gap ? p.gap * (p.columns - 1) : 0}px) / ${
            p.columns
          }) !important`
        : undefined};
  }

  .active-bounty-info-item {
    background: rgba(126, 0, 253, 0.1);
    border: 1px solid #7e00fd;
    & > span {
      color: #7e00fd;
    }
  }

  .padding-bottom {
    padding-bottom: 52px;
  }
`;

export const StyledFormItem = styled(FormItem)<{
  width?: string;
}>`
  margin: 0;
  width: ${(p) => (p.width ? p.width : "100%")};

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #080a25;
  }
  .ant-row {
    display: block;
  }
  .ant-form-item-label {
    padding-bottom: 0.625rem;
  }
  .ant-form-item-control-input {
    min-height: unset;
  }
`;

export const StyledFormItemHorizontal = styled(FormItem)<{
  width?: string;
}>`
  margin: 0;
  width: ${(p) => (p.width ? p.width : "100%")};

  .ant-row {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .ant-form-item-label {
    width: max-content !important;
    padding: 10px 0px 0px;

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #080f6c;
    }
  }
  .ant-form-item-control {
    /* width: max-content !important; */
    max-width: max-content !important;
    .ant-form-item-control-input-content {
    }

    .ant-form-item-explain {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 147px;
      & > div {
        text-align: right;
        width: max-content;
        margin: 0;
      }
    }
  }
`;

export const LabelWithIcon = styled(FlexWrap)`
  align-items: center;

  // label {
  //   display: inline-block;
  //   margin-left: -2px;
  //   color: #ff4d4f;
  //   font-size: 14px;
  //   font-family: SimSun, sans-serif;
  //   line-height: 1;
  // }
`;

export const StyledSelect = styled(Select)`
  max-width: 100%;
  width: 100%;
  border: none;
  border-radius: 0.25rem;
  /* height: 2.25rem; */
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.3rem;
  }

  .ant-select-selector {
    width: 100%;
    padding-left: 11px;
    height: 34px !important;
    min-height: 36px !important;
    border: 1px solid rgba(115, 137, 149, 0.2) !important;
  }

  .ant-select-selection-placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(16, 37, 72, 0.5) !important;
    width: max-content;
    overflow: visible;
    user-select: none;
    padding-top: 1px !important;
  }

  .ant-select-selection-item {
    font-size: 14px;
    line-height: 100% !important;
    color: #080f6c;
    /* margin-top: 0 !important; */
    margin-bottom: 0 !important;
    height: 36px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    .ant-select-selection-item-content {
      overflow: visible;
    }
  }

  .ant-select-arrow {
    svg {
      margin-right: 0;
    }
    z-index: 2;
    pointer-events: initial;
  }

  &.ant-select-open {
    border: 1px solid #7e00fd;
    border-radius: 4px 4px 0px 0px;
    .ant-select-arrow {
      transform: rotate(180deg);
      path {
        stroke: #7e00fd;
      }
    }
  }
`;

export const StyledSelectTags = styled(Select)`
  max-width: 100%;
  width: 100%;
  border: none !important;
  border-radius: 0.25rem;
  .ant-select-selection-item {
    height: 24px !important;
    background: rgba(41, 46, 115, 0.12);
  }
  .ant-select-selector {
    width: 100%;
    padding-left: 11px;
    min-height: 36px !important;
    border: 1px solid rgba(115, 137, 149, 0.2) !important;
  }
  .ant-select-selection-item {
    margin: 0 !important;
  }
  .ant-select-selection-placeholder {
    left: 11px !important;
    padding-top: 3px !important;
  }
  .ant-select-selection-overflow {
    gap: 6px;
    padding: 0px;
  }
`;

export const StyledSelectNorotate = styled(StyledSelect)`
  .ant-select-arrow {
    transform: rotate(0deg) !important;
  }
`;

export const StyledTimeSelect = styled(Select)`
  max-width: 100%;
  width: 100%;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.3rem;
  }

  .ant-select-selector {
    width: 100%;
    padding-left: 11px;
    padding: 0 !important;
    height: 36px !important;
    border: none !important;
    background: transparent !important;
  }

  .ant-select-selection-placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: rgba(16, 37, 72, 0.5) !important;
    width: max-content;
    overflow: visible;
    user-select: none;
  }

  .ant-select-selection-item {
    font-size: 14px;
    line-height: 100% !important;
    color: #080f6c;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 36px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    .ant-select-selection-item-content {
      overflow: visible;
    }
  }

  .ant-select-arrow {
    display: none;
  }

  &.ant-select-open {
    border: none;
  }
`;

export const StyledCenterTimeSelect = styled(StyledTimeSelect)`
  .ant-select-selection-item {
    justify-content: center;
  }
`;
export const StyledFlexEndTimeSelect = styled(StyledTimeSelect)`
  .ant-select-selection-item {
    justify-content: flex-end;
  }
`;

export const StyledOption = styled(Option)`
  /* p {
    margin: 0;
    margin-right: 6px;
  } */
`;

export const DatePickerAtnd = styled(DatePicker)`
  max-width: 46.625rem;
  border: 1px solid rgba(115, 137, 149, 0.2);
  border-radius: 0.25rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080f6c;
  height: 2.25rem;
  padding: 0.625rem;
  width: 100%;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    /* color: rgba(41, 46, 115, 0.42); */
    color: red;
  }
  .ant-picker-input {
    input {
      line-height: 100%;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 18px;
      color: #080f6c;
      padding-top: 1px;
      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #080f6c;
      }
    }
  }
  .ant-picker-suffix {
    color: rgba(8, 15, 108, 1);
    svg {
      transform: scale(1.2);
    }
  }
`;

export const Divider = styled.div`
  height: 14px;
  border-left: 1px solid rgba(16, 37, 72, 0.5); ;
`;

export const TimeWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(115, 137, 149, 0.2);

  border-radius: 4px;
  padding: 0px 12px;
  height: 36px;
`;

export const SelectItem = styled.div`
  height: 14px;
  width: max-content;
  display: flex;
  gap: 5px;
  font-size: 14px;
  p {
    margin: 0;
    font-weight: 600;
    color: #080a25;
  }
`;

export const ObserverItemWrap = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;
export const ObserverItem = styled.div`
  height: 26px;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 4px;
  font-size: 14px;
  color: rgba(8, 10, 37, 0.4);
  background: rgba(41, 46, 115, 0.05);
  border-radius: 20px;
  & > span:first-child {
    width: 20px;
    height: 20px;
  }
  & > span:last-child {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

export const BountyInformationItem = styled.div`
  padding: 18px 20px 18px 0px;
  display: flex;
  border: 1px solid rgba(115, 137, 149, 0.2);
  border-radius: 4px;
  cursor: pointer;
  width: 335px;

  & > span {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #080a25;
  }
  & > div {
    font-size: 16px;
    & > span {
      color: #080a25;
      margin-bottom: 6px;
    }
    & > div {
      color: rgba(8, 10, 37, 0.4);
    }
  }
`;

export const CompletedInformWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  gap: 81px;
  background: #f7f7f7;
  img {
    width: 287px;
    height: 222px;
    object-fit: cover;
  }
  & > div {
    width: max-content;
    & > div {
      font-weight: 600;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #080a25;
    }
    & > p {
      width: 518px;
      font-size: 16px;
      line-height: 156%;
      word-break: break-work;

      color: rgba(8, 10, 37, 0.6);
      margin: 16px 0px 24px;
    }
  }
`;

export const AnchorWithIcon = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  line-height: 100%;
  color: #080a25;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  margin-top: 36px;
  margin-bottom: 81px;
  opacity: 0.7;
  &:hover {
    color: #080a25;
  }
`;

export const SessionBudgetWrap = styled.div`
  width: 591px;
  position: relative;
  padding: 0px 24px 24px;
  color: #080f6c;
  line-height: 100%;
  letter-spacing: -0.01em;

  &::after {
    content: "";
    width: 591px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background: linear-gradient(
      90deg,
      #7e00fd 0%,
      #c143fc 61.44%,
      #00bffb 100%
    );
    opacity: 0.08;
    border-radius: 8px;
    z-index: -1;
  }
`;

export const TotalBalance = styled.div`
  padding: 30px 0px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  div {
    font-size: 20px;
  }
  span {
    font-size: 18px;
  }
  border-bottom: 1px solid rgba(115, 137, 149, 0.2);
`;

export const SessionBudgetDetails = styled.div`
  padding: 24px 0px 32px;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const TotalBudget = styled.div`
  padding: 24px 16px;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background: linear-gradient(
      90deg,
      #7e00fd 0%,
      #c143fc 61.44%,
      #00bffb 100%
    );
    opacity: 0.08;
    border-radius: 8px;
  }
`;

export const BudgetInput = styled(Input)`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 4px;
  height: 36px;
  width: 147px;

  .ant-input-group-addon {
    border: none;
    background: transparent;
    color: rgba(8, 10, 37, 0.5);
    padding: 4px 14px 4px 0px;
    font-size: 16px;
  }
  .ant-input-group .ant-input {
    border: none;
    background: transparent;
    text-align: right;
    padding: 5px 5px 5px 14px;
    color: #080f6c;
    font-size: 16px;
  }
`;

export const BudgetInputNumber = styled(InputNumber)<{
  disabled?: boolean;
}>`
  background: ${(p) =>
    p.disabled ? "rgba(115, 137, 149, 0.1)" : "rgba(255, 255, 255, 0.5)"};
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 4px;
  height: 36px;
  width: 147px;

  .ant-input-number {
    background: transparent;
    border: none;

    &-focused,
    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-number-group-addon {
    border: none;
    background: transparent;
    color: rgba(8, 10, 37, 0.5);
    padding: 0px 14px 0px 0px;
    font-size: 16px;
  }

  .ant-input-number-handler-wrap {
    right: unset;
    left: 0;
    opacity: 1;
    display: none;
  }
  /* .ant-input-number-input {
    height: 36px;
    border: none;
    background: transparent;
    text-align: right;
    padding: 10px 14px 10px 5px;
    color: #080f6c;
    font-size: 16px;
    pointer-events: none;
  } */
  .ant-input-number-input-wrap {
    input {
      height: 36px;
      border: none;
      background: transparent;
      text-align: right;
      padding: 10px 14px 11px 5px;
      color: #080f6c;
      font-size: 16px;
      pointer-events: none;
    }
  }
`;

export const HideNumberHandlerInputNumber = styled(BudgetInputNumber)`
  .ant-input-number-input-wrap {
    input {
      padding: 10px 5px 11px 14px;
      pointer-events: initial;
    }
  }
`;

export const NoneEventWrap = styled.div`
  pointer-events: none;
`;

export const PegToUSD = styled.div`
  width: 500px;
  padding: 16px;
  background: rgba(115, 137, 149, 0.04);
  border-radius: 4px;
  margin-top: 28px;

  & > div {
    display: flex;
    align-items: flex-end;
    gap: 18px;
    & > div {
      width: 210px;
      input {
        background: #ffffff;
        border: 1px solid rgba(115, 137, 149, 0.2);
        border-radius: 4px;
        padding: 16px;
        height: 48px;
      }
    }
  }

  & > span {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      background: #fff;
      border: 1px solid rgba(115, 137, 149, 0.3);
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;

      color: rgba(8, 10, 37, 0.8);
    }
  }
`;

export const StyledBountyInput = styled(StyledInput)`
  border: 1px solid rgba(115, 137, 149, 0.2);
`;
export const StyledBountyTextArea = styled(StyledTextArea)`
  border: 1px solid rgba(115, 137, 149, 0.2);
`;

export const ResourcesFieldWrap = styled(FlexWrap)`
  flex-direction: column;
`;

export const FilesWrap = styled.div`
  background: #fff;
  border: 1px solid rgba(115, 137, 149, 0.5);
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  margin-top: -52px;
  z-index: 237;
  padding: 4px;
`;

export const UploadBtn = styled.div`
  width: 100%;
  padding-top: 4px;
  background-color: #fff;
  & > div {
    background: rgba(156, 138, 193, 0.16);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    cursor: pointer;
  }
`;

export const AttachPopupWrap = styled(PopupWrap)`
  & > div {
    padding: 40px 24px 24px;
    gap: 24px;

    & > div {
      margin: 0px !important;
    }
  }
  .upload-antd-wrap {
    width: inherit;
    padding: 0px 24px;
  }
`;

export const DragAndDropFileWrap = styled.div`
  padding: 38px 0px !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border: 1px dashed rgba(41, 46, 115, 0.42);
  border-radius: 4px;
  user-select: none;
  span {
    font-size: 14px !important;
    letter-spacing: -0.01em;
    font-weight: 400;
    line-height: 100%;
  }
  strong {
    color: #080f6c;
  }
`;

export const FileAttachWrap = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
  cursor: pointer;

  & > span {
    width: 24px;
    height: 24px;
    position: absolute;
    transform: scale(0.8);
    top: -12px;
    right: -12px;
    background: #fff;
    border-radius: 50%;
    padding: 2px;
    /* border: 1px solid red; */
    box-shadow: rgba(115, 137, 149, 0.2) 0px 0px 0px 2px,
      rgba(115, 137, 149, 0.2) 0px 4px 6px -1px,
      rgba(115, 137, 149, 0.2) 0px 1px 0px inset;

    color: #7e00fd;

    &:hover {
      color: #ff4d4f;
      box-shadow: #ff4d4f 0px 0px 0px 2px, #ff4d4f 0px 4px 6px -1px,
        #ff4d4f 0px 1px 0px inset;
    }
  }
`;
