import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IConversation {
  isNewMessage?: boolean;
}

const initialState: IConversation = {
  isNewMessage: false,
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setConversation: (state, action: PayloadAction<IConversation>) => {
      if (typeof action.payload?.isNewMessage === "boolean")
        state.isNewMessage = action.payload?.isNewMessage;
    },
  },
});

export const { setConversation } = conversationSlice.actions;

export default conversationSlice.reducer;
