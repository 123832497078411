import { StarIcon } from "assets/icons/star.icon";
import { useEffect, useMemo, useState } from "react";
import {
  BlogList,
  CheckableTag,
  Container,
  HeaderContent,
  Heading,
  PaginationStyled,
  TagSection,
} from "./blog.style";
import {
  IBlog,
  IGetBlogListReq,
  IGetBlogListRes,
  getBlogListAPI,
} from "api/blog";
import { useQuery } from "@tanstack/react-query";
import { IGetDataMastersRes, getDataMastersAPI } from "api/users";
import EmptyData from "components/base/emptyData";
import BlogCard from "./blogCard";
import FirstBlogCard from "./firstBlogCard";

const Blog = () => {
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const initParams: IGetBlogListReq = useMemo(
    () => ({
      categories: [],
      page: 1,
      pageSize: 6,
      search: "",
      sort: -1,
      pageSizeAtFirstPage: 6,
    }),
    []
  );
  const [params, setParams] = useState<IGetBlogListReq>(initParams);

  const { data: categories } = useQuery(
    [`get-data-master-by-type`],
    () => getDataMastersAPI({ type: "Blog Category" }),
    {
      select: (data) =>
        data && data.responseCode === 200
          ? (data.responseData as IGetDataMastersRes)
          : undefined,
      refetchOnWindowFocus: false,
    }
  );

  const [dataTotal, setDataTotal] = useState<number>();

  const { data, isLoading } = useQuery(
    ["get-blog-list", params],
    () => getBlogListAPI(params),
    {
      select: (data) =>
        data && data.responseCode === 200
          ? (data.responseData as IGetBlogListRes)
          : undefined,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data && data.total !== dataTotal) setDataTotal(data.total);
      },
    }
  );

  const handleChange = (tagId: string, checked: boolean) => {
    const tags = checked
      ? [...selectedTag, tagId]
      : selectedTag.filter((item) => item !== tagId);
    setSelectedTag(tags);
  };
  useEffect(() => {
    setParams({
      ...initParams,
      categories: selectedTag,
    });
  }, [initParams, selectedTag]);

  const [firstItem, setFirstItem] = useState<IBlog>();

  useEffect(() => {
    if (
      params.page === 1 &&
      params.categories.length === 0 &&
      data?.data.length! > 0
    )
      setFirstItem(data?.data[0]);
  }, [params, data]);

  return (
    <Container>
      <Heading>
        <HeaderContent>
          <span style={{ display: "contents" }}>
            Blog <StarIcon />
          </span>
          <h1>News and insights</h1>
          <span>
            Learn about cryptocurrency, NFTs, and blockchain, discover our
            latest product updates, partnership announcements, user stories, and
            more.
          </span>
        </HeaderContent>
      </Heading>
      <TagSection>
        <CheckableTag
          key="all"
          checked={selectedTag.length === 0}
          onChange={() => setSelectedTag([])}
        >
          View all
        </CheckableTag>
        {categories?.values.map((tag) => (
          <CheckableTag
            key={tag._id}
            checked={selectedTag.includes(tag._id)}
            onChange={(checked) => handleChange(tag._id, checked)}
          >
            {tag.name}
          </CheckableTag>
        ))}
      </TagSection>
      <FirstBlogCard loading={!firstItem && isLoading} blog={firstItem} />
      <BlogList>
        {isLoading &&
          [...Array(6)].map((_, index) => (
            <BlogCard key={index} loading={true} blog={undefined} />
          ))}
        {data &&
          data?.data?.map((blog: IBlog, index) => (
            <BlogCard key={index} loading={false} blog={blog} />
          ))}
      </BlogList>
      {!isLoading && !data && (
        <div style={{ marginBottom: "60px" }}>
          <EmptyData entity="blogs"></EmptyData>
        </div>
      )}
      <PaginationStyled
        current={params.page}
        pageSize={initParams.pageSize}
        total={dataTotal}
        onChange={(page) => setParams((prev) => ({ ...prev, page }))}
      />
    </Container>
  );
};

export default Blog;
