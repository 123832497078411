import { CommonFlexColumn } from "pages/OrganizationPage/Transaction/transaction.style";
import styled from "styled-components";

export const StatHint = styled.span`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const StatNumber = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #080f6c;
`;

export const ProjDescription = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 172%;
  color: rgba(41, 46, 115, 0.82);
  margin-bottom: 0;
  word-break: break-word;
  width: 100%;
  white-space: pre-wrap;

  .text-bold {
    font-weight: 600;
  }
  .child-text {
    margin: 0px 0px 0px 12px;
  }

  // @supports (-webkit-line-clamp: 4) {
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: initial;
  //   display: -webkit-box;
  //   -webkit-line-clamp: 4;
  //   -webkit-box-orient: vertical;
  // }
`;

export const ProjDescTitle = styled.h3`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #080f6c;
`;

export const ProjDescWrapper = styled.section`
  margin-top: 16px;
  display: flex;
  padding: 40px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
    linear-gradient(
      90deg,
      rgba(126, 0, 253, 0.1) 0%,
      rgba(193, 67, 252, 0.1) 46.88%,
      rgba(0, 191, 251, 0.1) 100%
    );
  gap: 40px;

  & > ${CommonFlexColumn}:first-child {
    flex-basis: 70%;
  }
`;
